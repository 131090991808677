import React, { useState, useRef } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import useUser from "../../../hooks/useUser";
import {
  businessAddCurrency,
  businessAddWalletCurrency,
  AddCurrencyUSD_GBP_EUR
} from "../../../redux/addCurrency/addCurrencyActions";
import GladeLogo from "../../logos/GladeLogo";
import { getSuppliedFieldValue } from "../../../helpers/helper";
import SuccessModal from "../SuccessModal";
import AddCurrencyForm from "./AddCurrencyForm";
import GetStarted from "./GetStarted";
import Loading from "./Loading";
import SelectCurrency from "./SelectCurrency";
import ConfirmCharge from "./ConfirmCharge";
import { IoIosArrowBack } from "react-icons/io";
import { getPersonalAndBusinessAccounts } from "../../../redux/auth/authActions";
import ConfirmPinModal from "../../../pages/fund-transfer-new/ConfirmPinModal";
import CreateWalletCurrencyForm from "./CreateWalletCurrencyForm";
import AddCreateWalletCurrencyReason from "./AddCreateCurrencyReason";
import NewAddCurrencyForm from "./NewAddCurrencyForm";
import AccountRequestWaitList from "./AccountRequestWaitilist";
import GladefinanceBlueLogo from "../../logos/GladeFinanceBlueLogo";
const AddCurrencyModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const modalRef = useRef();


  // ====================CHANGE isIFLive to true when IF goes live =============== //
  const isIFLive = false;
  //*=====================End of IF Integration GO LIVE =============*//


  const [input, setInput] = useState({
    street: "",
    zip: "",
    city: "",
    monthly_volume: "",
    payment_flow_description: "",
    additional_info: "",
    incorporation_date: "",
    attachments: [],
    reason: "",
    business_registration_number: "",
    currency: "",

    business_legal_name: "",
    business_incorporation_date: "",
    business_country: "",
    business_state: "",
    business_city: "",
    postal_code: "",
    address_number: "",
    address_street: "",
    business_phone_number: "",
    director_first_name: "",
    director_last_name: "",
    director_document: [],
    director_dob: "",
    director_country: "",
    director_state: "",
    director_city: "",
    director_postal_code: "",
    director_phone_number: "",

    waitlist_first_name: "",
    waitlist_last_name: "",
    waitlist_email: "",
    waitlist_business_name: "",
    waitlist_business_description: "",
    waitlist_business_website: "",
    waitlist_business_industry: "",
    waitlist_country_corporation: "",
    waitlist_business_account_reason: "",
    waitlist_volume_monthly: ""

  });
  const [walletInputs, setWalletInputs] = useState({
    reason: "",
    business_registration_number: "",
    business_registration: "",
    operating_licence: "",
    currency: "",
  });
  const [content, setContent] = useState(0);
  const [activeStepBusDetails, setActiveStepBusDetails] = useState(true);

  const handleClick = (value) => {
    setInput({
      ...input,
      currency: value.currency,
      is_wallet: value.is_wallet,
    });
    setWalletInputs({
      ...input,
      currency: value.currency,
      is_wallet: value.is_wallet,
    });
    setContent(2);
  };
  const handleInput = (data) => {
    setInput({ ...data });
    setContent(3);
  };
  const handleInputWallets = (data) => {
    setWalletInputs({ ...data });
  };
  const handlePaymentWithExistingBalance = () => {
    let formdata = new FormData();
    Object.keys(input).forEach((field) => {
      if (field === "attachments") {
        input[field].forEach((element, index) => {
          formdata.append(`${field}[${index}]`, element);
        });
      } else if (field === "monthly_volume") {
        formdata.append(field, Number(input[`${field}`].replaceAll(",", "")));
      } else {
        formdata.append(field, input[`${field}`]);
      }
    });
    formdata.append(
      "source_currency",
      getSuppliedFieldValue("currency", user)
        ? getSuppliedFieldValue("currency", user)
        : "NGN"
    );
    formdata.append("payment_type", "existing_balance");
    setContent(4);
    dispatch(businessAddCurrency(formdata, onSuccess, onFailure));
  };

  const handleCreateCurrencyAccount = () => {
    let data = {
      currency: input?.currency,
      legal_name: input?.business_legal_name,
      incorporation_date: input?.business_incorporation_date,
      business_country: input?.business_country,
      address_country: input?.business_country,
      address_street: input?.address_street,
      address_city: input?.business_city,
      address_number: input?.address_number,
      address_post_code: input?.postal_code,
      address_phone_number: input?.business_phone_number,
      director_first_name: input?.director_first_name,
      director_last_name: input?.director_last_name,
      director_birth_date: input?.director_dob,
      director_country: input?.director_country,
      director_address_city: input?.director_city,
      director_address_state: input?.director_state,
      director_address_country: input?.director_country,
      director_address: input?.director_address,
      director_address_post_code: input?.director_postal_code,
      director_phone_number: input?.director_phone_number
    }
    setContent(4);
    dispatch(AddCurrencyUSD_GBP_EUR(data, onSuccess, () => setContent(2)));
  }

  const handleCreateWalletAccount = () => {
    let formdata = new FormData();

    formdata.append("currency", walletInputs.currency);
    formdata.append("reason", walletInputs.reason);
    formdata.append(
      "business_registration",
      walletInputs.business_registration[0]
    );
    if (walletInputs?.operating_licence?.length > 0) {
      formdata.append("operating_licence", walletInputs.operating_licence[0]);
    }
    setContent(4);
    dispatch(businessAddWalletCurrency(formdata, onSuccess, onFailure));
  };
  const onSuccess = () => {
    setContent(5);
  };
  const onFailure = () => {
    setContent(3);
  };

  const next = () => {
    setActiveStepBusDetails(!activeStepBusDetails);
  };

  function openConfirmPinModal() {
    modalRef.current.openModal();
  }
  return (
    <div className="flex glade-font-inter flex-col items-center px-6 py-7 w-full bg-white h-full">
      <div className="border-b pb-6 flex justify-between w-10/12">
        {content >= 2 ? (
          <div>
            <div className="flex cursor-pointer" onClick={() => {
              if (input?.is_wallet === 0 && !activeStepBusDetails) {
                setActiveStepBusDetails(true)
              } else if (input?.is_wallet === 0 && !isIFLive && content === 4) { setContent(2) }
              else { setContent(content - 1) }
            }}>
              <GladefinanceBlueLogo />
            </div>
          </div>
        ) : (
          <GladefinanceBlueLogo />
        )}
        <IoCloseSharp
          className="glade-blue cursor-pointer"
          size={35}
          onClick={() => {
            if (content === 0) {
              onClose();
            }
            else {
              setContent(content - 1);
            }
          }}
        />
      </div>
      <div className="w-full flex overflow-auto justify-center h-screen">
        {content === 0 ? (
          <GetStarted next={() => setContent(1)} />
        ) : content === 1 ? (
          <SelectCurrency handleClick={handleClick} />
        ) : content === 2 ? (
          <>
            {input?.is_wallet === 0 ? (
              isIFLive ?
                <NewAddCurrencyForm
                  input={input}
                  setInput={setInput}
                  setContent={setContent}
                  next={next}
                  activeStepBusDetails={activeStepBusDetails}
                  onProceed={openConfirmPinModal}
                />
                : <AccountRequestWaitList
                  input={input}
                  setInput={setInput}
                  setContent={setContent}
                />

            ) : (
              <AddCreateWalletCurrencyReason
                onAddReason={(e) => {
                  setWalletInputs({ ...walletInputs, reason: e });
                  setContent(3);
                }}
                incomingInput={walletInputs}
              />
            )}
            <ConfirmPinModal
              title="Enter your PIN"
              body=""
              onCorrectPin={
                input?.is_wallet === 0
                  ? handleCreateCurrencyAccount //handlePaymentWithExistingBalance
                  : handleCreateWalletAccount
              }
              ref={modalRef}
            />
          </>
        ) : content === 3 ? (
          <>
            {input?.is_wallet === 0 ? (
              <ConfirmCharge
                currency={input?.currency}
                onProceed={openConfirmPinModal}
              />
            ) : (
              <CreateWalletCurrencyForm
                incomingInput={walletInputs}
                handleInput={handleInputWallets}
                onProceed={openConfirmPinModal}
              />
            )}
            <ConfirmPinModal
              title="Enter your PIN"
              body=""
              onCorrectPin={
                input?.is_wallet === 0
                  ? handleCreateCurrencyAccount //handlePaymentWithExistingBalance
                  : handleCreateWalletAccount
              }
              ref={modalRef}
            />
          </>
        ) : content === 4 ? (
          <Loading title={input?.is_wallet === 0 && !isIFLive ? "Joining Waitlist" : "Creating Currency"} />
        ) : content === 5 ? (
          <SuccessModal
            onClose={() => {
              dispatch(getPersonalAndBusinessAccounts());
              onClose();
            }}
            title={
              input?.is_wallet === 0
                ? "Account request successful"
                : "Wallet request successful"
            }
            showTimeLeft={false}
            buttonText="Go Home"
            body={`Your request for a ${input?.is_wallet === 0 ? "" : "wallet"
              } currency account was successful and is currently being reviewed`}
          />
        ) : content === 6 ? (
          <SuccessModal
            onClose={() => {
              dispatch(getPersonalAndBusinessAccounts());
              onClose();
            }}
            title={"Registration Successful"}
            showTimeLeft={false}
            buttonText="Close"
            body={``}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddCurrencyModal;
