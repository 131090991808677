import React, { Fragment } from "react";
import { LinkText } from "../../pages/fund-transfer-new/TransferInternationalForm2";
import CircleRadioGroup, { CircleRadio } from "../radios/CircleRadioGroup";
import Dropdown from "../transfer/Dropdown";
import InputGroup from "../transfer/InputGroup";

const FormBuilder = ({
    formList,
    setFormStage,
    accountTypes,
    recipientAccountType,
    onAccountTypeSelect,
    transferForm,
    listOfCountries,
    listOfStates,
    onCountrySelect,
    onStateSelect,
    transferMethod,
    onTransferMethodSelect,
    transferProvider,
    onProviderSelect,
    transferBranches,
    onBranchSelect,
    selectedTransferMethod
}) => {

    return (
        <div>
            {formList.map((field, key) =>
                <div key={key}>
                    {
                        (field.type === 'text' || field.type === 'number') &&
                        <InputGroup
                            label={field?.title}
                            type={field?.type}
                            placeholder={field?.placeholder}
                            value={transferForm[field?.id]}
                            name={field?.id}
                            tooltipContent={field?.hasInstructionIcon ? field?.instructionText : ''}
                        >
                            {field?.hasBeneficiary && <LinkText className='mt-2' onClick={() => setFormStage('beneficiaries')}>Select Beneficiary</LinkText>}
                        </InputGroup>
                    }

                    {
                        (field.type === 'radio') &&
                        <CircleRadioGroup label={field?.title}>
                            {accountTypes.map(accountType =>
                                <CircleRadio
                                    key={accountType}
                                    text={accountType}
                                    selected={recipientAccountType}
                                    value={accountType}
                                    onChange={() => onAccountTypeSelect(accountType)}
                                />
                            )}
                        </CircleRadioGroup>
                    }
                    {(field.type === 'radio') && recipientAccountType === 'corporate' &&
                        <InputGroup
                            label={`Recipient's Account Name`}
                            type={'text'}
                            placeholder={'Enter Account Name'}
                            value={transferForm['beneficiary_name']}
                            name={'beneficiary_name'}
                        >
                        </InputGroup>
                    }
                    {
                        (field.type === 'radio') && recipientAccountType === 'individual' &&
                        <Fragment>
                            <InputGroup
                                label={`Recipient's First Name`}
                                type={'text'}
                                placeholder={'E.g John'}
                                value={transferForm['beneficiary_first_name']}
                                name={'beneficiary_first_name'}
                            >
                            </InputGroup>
                            <InputGroup
                                label={`Recipient's Last Name`}
                                type={'text'}
                                placeholder={'E.g Doe'}
                                value={transferForm['beneficiary_last_name']}
                                name={'beneficiary_last_name'}
                            >
                            </InputGroup>
                        </Fragment>}

                    {(field?.type === 'dropdown' && field?.id === 'beneficiary_country') &&
                        <Dropdown
                            placeholder={"Select Country"}
                            emptyMessage={"List is empty"}
                            label={"Recipient's Country"}
                            selected={{ name: transferForm?.beneficiary_country }}
                            accessor={"country_name"}
                            items={listOfCountries}
                            onSelect={onCountrySelect}
                        />}

                    {(field?.type === 'dropdown' && field?.id === 'beneficiary_state') &&
                        <Dropdown
                            placeholder={"Select State"}
                            emptyMessage={"List is empty"}
                            label={"State"}
                            selected={{ name: transferForm?.beneficiary_state }}
                            accessor={"state_name"}
                            items={listOfStates}
                            onSelect={onStateSelect}
                        />}

                    {field?.id === 'transfer_method' &&
                        <Fragment>
                            <Dropdown
                                placeholder={"Transfer Method"}
                                label={"Transfer Method"}
                                items={transferMethod}
                                accessor={"name"}
                                selected={selectedTransferMethod}
                                onSelect={onTransferMethodSelect}
                                emptyMessage={"No transfer methods"}
                            />

                            {(selectedTransferMethod) && (
                                <Dropdown
                                    placeholder={"Select Provider"}
                                    label={"Select Provider"}
                                    accessor={"name"}
                                    selected={transferProvider?.selected}
                                    items={transferProvider?.providers}
                                    onSelect={onProviderSelect}
                                    emptyMessage={"No providers"}
                                />
                            )}

                            {(selectedTransferMethod?.code === "account") && (
                                <Fragment>
                                    <Dropdown
                                        placeholder={"Select Branch"}
                                        emptyMessage={"No branches"}
                                        label={"Select Branch"}
                                        selected={transferBranches?.selected}
                                        accessor={"branch_name"}
                                        items={transferBranches?.branches}
                                        onSelect={onBranchSelect}
                                    />

                                    <InputGroup
                                        label={`Recipient's Account Number`}
                                        type={'number'}
                                        placeholder={'Enter account number'}
                                        value={transferForm['beneficiary_account_number']}
                                        name={'beneficiary_account_number'}
                                    >
                                    </InputGroup>

                                </Fragment>
                            )}
                            {
                                selectedTransferMethod?.code === "mobilemoney" &&
                                <InputGroup
                                    label={`Recipient's Phone Number`}
                                    type={'number'}
                                    placeholder={'Enter phone number'}
                                    value={transferForm['beneficiary_phone']}
                                    name={'beneficiary_phone'}
                                >
                                </InputGroup>
                            }
                        </Fragment>}
                </div>
            )}
        </div>
    )
}

export default FormBuilder;