import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import Button from "../../components/buttons/Button";
import { submitBusinessCacOfficerInformtion } from "../../redux/business/businessActions";
import { useDispatch } from "react-redux";
import useBusiness from "../../hooks/useBusiness";
import { useHistory } from "react-router-dom";

export const ProprietorInformation = ({
  proprietorList,
  setProprietorList,
  directorList,
  setDirectorList,
  partnerList,
  setPartnerList,
  trusteeList,
  setTrusteeList,
  setFormLevel,
  businessType,
  setProprietorFormType,
  proprietorFormType,
  input,
}) => {
  const dispatch = useDispatch();
  const business = useBusiness();
  const history = useHistory();

  const onSubmitDirectors = () => {
    let dataPayload = {};

    if (businessType === "LLP") {
      partnerList.map((partner, i) => {
        dataPayload[`partner[${i}][full_name]`] = partner.full_name;
        dataPayload[`partner[${i}][city]`] = partner.city;
        dataPayload[`partner[${i}][email]`] = partner.email;
        dataPayload[`partner[${i}][address]`] = partner?.address
          ? partner.address
          : partner.residential_address;
        dataPayload[`partner[${i}][postal]`] = partner.postal;
        dataPayload[`partner[${i}][state]`] = partner.state;
        dataPayload[`partner[${i}][country]`] = partner.country;
        dataPayload[`partner[${i}][phone]`] = partner.phone;
        if (partner?.lga?.length) {
          dataPayload[`partner[${i}][lga]`] = partner.lga;
        }
        dataPayload[`partner[${i}][date]`] = partner?.date
          ? partner?.date
          : `${partner.year}/${partner.month}/${partner.day}`;
        if (
          partner.passport_photograph &&
          typeof partner.passport_photograph === "object"
        ) {
          dataPayload[`partner[${i}][passportphoto]`] =
            partner.passport_photograph[0];
        }
        if (partner.passportphoto && (typeof partner.passportphoto === "number" || typeof partner.passportphoto === "string")) {
          dataPayload[`partner[${i}][passportphoto]`] = partner.passportphoto;
        }
        if (partner.e_signature && typeof partner.e_signature === "object") {
          dataPayload[`partner[${i}][esign]`] = partner.e_signature[0];
        }
        if (partner.esign && (typeof partner.esign === "number" || typeof partner.esign === "string")) {
          dataPayload[`partner[${i}][esign]`] = partner.esign;
        }
        if (
          partner.statement &&
          typeof partner.statement === "object"
        ) {
          dataPayload[`partner[${i}][statement]`] = partner.statement[0];
        }
        if (
          partner.statement &&
          (typeof partner.statement === "number" || typeof partner.statement === "string")
        ) {
          dataPayload[`partner[${i}][statement]`] = partner.statement;
        }
        if (
          partner.government_id &&
          typeof partner.government_id === "object"
        ) {
          dataPayload[`partner[${i}][govtid]`] = partner.government_id[0];
        }
        if (
          partner.govtid &&
          (typeof partner.govtid === "number" || typeof partner.govtid === "string")
        ) {
          dataPayload[`partner[${i}][govtid]`] = partner.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    if (proprietorFormType === "trustee" || businessType === "NGO") {
      trusteeList.map((trustee, i) => {
        dataPayload[`trustee[${i}][full_name]`] = trustee.full_name;
        dataPayload[`trustee[${i}][city]`] = trustee.city;
        dataPayload[`trustee[${i}][email]`] = trustee.email;
        dataPayload[`trustee[${i}][address]`] = trustee?.address
          ? trustee.address
          : trustee.residential_address;
        dataPayload[`trustee[${i}][postal]`] = trustee.postal;
        dataPayload[`trustee[${i}][state]`] = trustee.state;
        dataPayload[`trustee[${i}][share_capital]`] = trustee.share_capital;
        dataPayload[`trustee[${i}][occupation]`] = trustee.occupation;
        dataPayload[`trustee[${i}][country]`] = trustee.country;
        dataPayload[`trustee[${i}][phone]`] = trustee.phone;
        if (trustee?.lga?.length) {
          dataPayload[`trustee[${i}][lga]`] = trustee.lga;
        }
        dataPayload[`trustee[${i}][date]`] = trustee?.date
          ? trustee?.date
          : `${trustee.year}/${trustee.month}/${trustee.day}`;
        if (
          trustee.passport_photograph &&
          typeof trustee.passport_photograph === "object"
        ) {
          dataPayload[`trustee[${i}][passportphoto]`] =
            trustee.passport_photograph[0];
        }
        if (trustee.passportphoto && (typeof trustee.passportphoto === "number" || typeof trustee.passportphoto === "string")) {
          dataPayload[`trustee[${i}][passportphoto]`] = trustee.passportphoto;
        }
        if (trustee.e_signature && typeof trustee.e_signature === "object") {
          dataPayload[`trustee[${i}][esign]`] = trustee.e_signature[0];
        }
        if (trustee.esign && (typeof trustee.esign === "number" || typeof trustee.esign === "string")) {
          dataPayload[`trustee[${i}][esign]`] = trustee.esign;
        }
        if (
          trustee.government_id &&
          typeof trustee.government_id === "object"
        ) {
          dataPayload[`trustee[${i}][govtid]`] =
            trustee.government_id[0];
        }
        if (trustee.govtid && (typeof trustee.govtid === "number" || typeof trustee.govtid === "string")) {
          dataPayload[`trustee[${i}][govtid]`] = trustee.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    if (proprietorFormType === "proprietor" || businessType === "SP") {
      proprietorList.map((proprietor, i) => {
        dataPayload[`proprietor[${i}][full_name]`] = proprietor.full_name;
        dataPayload[`proprietor[${i}][city]`] = proprietor.city;
        dataPayload[`proprietor[${i}][email]`] = proprietor.email;
        dataPayload[`proprietor[${i}][address]`] = proprietor?.address
          ? proprietor.address
          : proprietor.residential_address;
        dataPayload[`proprietor[${i}][postal]`] = proprietor.postal;
        dataPayload[`proprietor[${i}][state]`] = proprietor.state;
        dataPayload[`proprietor[${i}][share_capital]`] =
          proprietor.share_capital;
        dataPayload[`proprietor[${i}][occupation]`] = proprietor.occupation;
        dataPayload[`proprietor[${i}][country]`] = proprietor.country;
        dataPayload[`proprietor[${i}][phone]`] = proprietor.phone;
        if (proprietor?.lga?.length) {
          dataPayload[`proprietor[${i}][lga]`] = proprietor.lga;
        }
        dataPayload[`proprietor[${i}][date]`] = proprietor?.date
          ? proprietor?.date
          : `${proprietor.year}/${proprietor.month}/${proprietor.day}`;
        if (
          proprietor.passport_photograph &&
          typeof proprietor.passport_photograph === "object"
        ) {
          dataPayload[`proprietor[${i}][passportphoto]`] =
            proprietor.passport_photograph[0];
        }
        if (proprietor.passportphoto && (typeof proprietor.passportphoto === "number" || typeof proprietor.passportphoto === "string")) {
          dataPayload[`proprietor[${i}][passportphoto]`] = proprietor.passportphoto;
        }
        if (
          proprietor.e_signature &&
          typeof proprietor.e_signature === "object"
        ) {
          dataPayload[`proprietor[${i}][esign]`] = proprietor.e_signature[0];
        }
        if (proprietor.esign && (typeof proprietor.esign === "number" || typeof proprietor.esign === "string")) {
          dataPayload[`proprietor[${i}][esign]`] = proprietor.esign;
        }
        if (
          proprietor.government_id &&
          typeof proprietor.government_id === "object"
        ) {
          dataPayload[`proprietor[${i}][govtid]`] =
            proprietor.government_id[0];
        }
        if (proprietor.govtid && (typeof proprietor.govtid === "number" || typeof proprietor.govtid === "string")) {
          dataPayload[`proprietor[${i}][govtid]`] = proprietor.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    if (proprietorFormType === "director" || businessType === "LLC") {
      directorList.map((director, i) => {
        dataPayload[`director[${i}][full_name]`] = director.full_name;
        dataPayload[`director[${i}][city]`] = director.city;
        dataPayload[`director[${i}][email]`] = director.email;
        dataPayload[`director[${i}][address]`] = director?.address
          ? director.address
          : director.residential_address;
        dataPayload[`director[${i}][postal]`] = director.postal;
        dataPayload[`director[${i}][state]`] = director.state;
        dataPayload[`director[${i}][share_capital]`] = director.share_ownership;
        dataPayload[`director[${i}][occupation]`] = director.occupation;
        dataPayload[`director[${i}][country]`] = director.country;
        dataPayload[`director[${i}][phone]`] = director.phone;
        dataPayload[`director[${i}][nationality]`] = director.nationality;

        if (director?.lga?.length) {
          dataPayload[`director[${i}][lga]`] = director.lga;
        }
        dataPayload[`director[${i}][date]`] = director?.date
          ? director?.date
          : `${director.year}/${director.month}/${director.day}`;
        if (
          director.passport_photograph &&
          typeof director.passport_photograph === "object"
        ) {
          dataPayload[`director[${i}][passportphoto]`] =
            director.passport_photograph[0];
        }
        if (director.passportphoto && (typeof director.passportphoto === "number" || typeof director.passportphoto === "string")) {
          dataPayload[`director[${i}][passportphoto]`] = director.passportphoto;
        }
        if (director.e_signature && typeof director.e_signature === "object") {
          dataPayload[`director[${i}][esign]`] = director.e_signature[0];
        }
        if (director.esign && (typeof director.esign === "number" || typeof director.esign === "string")) {
          dataPayload[`director[${i}][esign]`] = director.esign;
        }
        if (
          director.government_id &&
          typeof director.government_id === "object"
        ) {
          dataPayload[`director[${i}][govtid]`] =
            director.government_id[0];
        }
        if (director.govtid && (typeof director.govtid === "number" || typeof director.govtid === "string")) {
          dataPayload[`director[${i}][govtid]`] = director.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    dispatch(
      submitBusinessCacOfficerInformtion(
        dataPayload,
        { business_category: input.business_category?.toLowerCase() },
        () => {
          setFormLevel("fee payment");
        }
      )
    );
  };
  const onSubmitDirectorsContinueLater = () => {
    let dataPayload = {};

    if (businessType === "LLP") {
      partnerList.map((partner, i) => {
        dataPayload[`partner[${i}][full_name]`] = partner.full_name;
        dataPayload[`partner[${i}][city]`] = partner.city;
        dataPayload[`partner[${i}][email]`] = partner.email;
        dataPayload[`partner[${i}][address]`] = partner?.address
          ? partner.address
          : partner.residential_address;
        dataPayload[`partner[${i}][postal]`] = partner.postal;
        dataPayload[`partner[${i}][state]`] = partner.state;
        dataPayload[`partner[${i}][country]`] = partner.country;
        dataPayload[`partner[${i}][phone]`] = partner.phone;
        if (partner?.lga?.length) {
          dataPayload[`partner[${i}][lga]`] = partner.lga;
        }
        dataPayload[`partner[${i}][date]`] = partner?.date
          ? partner?.date
          : `${partner.year}/${partner.month}/${partner.day}`;
        if (
          partner.passport_photograph &&
          typeof partner.passport_photograph === "object"
        ) {
          dataPayload[`partner[${i}][passportphoto]`] =
            partner.passport_photograph[0];
        }
        if (partner.passportphoto && (typeof partner.passportphoto === "number" || typeof partner.passportphoto === "string")) {
          dataPayload[`partner[${i}][passportphoto]`] = partner.passportphoto;
        }
        if (
          partner.statement &&
          typeof partner.statement === "object"
        ) {
          dataPayload[`partner[${i}][statement]`] =
            partner.statement[0];
        }
        if (partner.statement && (typeof partner.statement === "number" || typeof partner.statement === "string")) {
          dataPayload[`partner[${i}][statement]`] = partner.statement;
        }
        if (partner.e_signature && typeof partner.e_signature === "object") {
          dataPayload[`partner[${i}][esign]`] = partner.e_signature[0];
        }
        if (partner.esign && (typeof partner.esign === "number" || typeof partner.esign === "string")) {
          dataPayload[`partner[${i}][esign]`] = partner.esign;
        }
        if (
          partner.government_id &&
          typeof partner.government_id === "object"
        ) {
          dataPayload[`partner[${i}][govtid]`] = partner.government_id[0];
        }
        if (
          partner.govtid &&
          (typeof partner.govtid === "number" || typeof partner.govtid === "string")
        ) {
          dataPayload[`partner[${i}][govtid]`] = partner.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    if (proprietorFormType === "trustee") {
      trusteeList.map((trustee, i) => {
        dataPayload[`trustee[${i}][full_name]`] = trustee.full_name;
        dataPayload[`trustee[${i}][city]`] = trustee.city;
        dataPayload[`trustee[${i}][email]`] = trustee.email;
        dataPayload[`trustee[${i}][address]`] = trustee?.address
          ? trustee.address
          : trustee.residential_address;
        dataPayload[`trustee[${i}][postal]`] = trustee.postal;
        dataPayload[`trustee[${i}][state]`] = trustee.state;
        dataPayload[`trustee[${i}][share_capital]`] = trustee.share_capital;
        dataPayload[`trustee[${i}][occupation]`] = trustee.occupation;
        dataPayload[`trustee[${i}][country]`] = trustee.country;
        dataPayload[`trustee[${i}][phone]`] = trustee.phone;
        if (trustee?.lga?.length) {
          dataPayload[`trustee[${i}][lga]`] = trustee.lga;
        }
        dataPayload[`trustee[${i}][date]`] = trustee?.date
          ? trustee?.date
          : `${trustee.year}/${trustee.month}/${trustee.day}`;
        if (
          trustee.passport_photograph &&
          typeof trustee.passport_photograph === "object"
        ) {
          dataPayload[`trustee[${i}][passportphoto]`] =
            trustee.passport_photograph[0];
        }
        if (trustee.passportphoto && (typeof trustee.passportphoto === "number" || typeof trustee.passportphoto === "string")) {
          dataPayload[`trustee[${i}][passportphoto]`] = trustee.passportphoto;
        }
        if (trustee.e_signature && typeof trustee.e_signature === "object") {
          dataPayload[`trustee[${i}][esign]`] = trustee.e_signature[0];
        }
        if (trustee.esign && (typeof trustee.esign === "number" || typeof trustee.esign === "string")) {
          dataPayload[`trustee[${i}][esign]`] = trustee.esign;
        }
        if (
          trustee.government_id && typeof trustee.government_id === "object"
        ) {
          dataPayload[`trustee[${i}][govtid]`] =
            trustee.government_id[0];
        }
        if (trustee.govtid && (typeof trustee.govtid === "number" || typeof trustee.govtid === "string")) {
          dataPayload[`trustee[${i}][govtid]`] = trustee.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    if (proprietorFormType === "proprietor") {
      proprietorList.map((proprietor, i) => {
        dataPayload[`proprietor[${i}][full_name]`] = proprietor.full_name;
        dataPayload[`proprietor[${i}][city]`] = proprietor.city;
        dataPayload[`proprietor[${i}][email]`] = proprietor.email;
        dataPayload[`proprietor[${i}][address]`] = proprietor?.address
          ? proprietor.address
          : proprietor.residential_address;
        dataPayload[`proprietor[${i}][postal]`] = proprietor.postal;
        dataPayload[`proprietor[${i}][state]`] = proprietor.state;
        dataPayload[`proprietor[${i}][share_capital]`] =
          proprietor.share_capital;
        dataPayload[`proprietor[${i}][occupation]`] = proprietor.occupation;
        dataPayload[`proprietor[${i}][country]`] = proprietor.country;
        dataPayload[`proprietor[${i}][phone]`] = proprietor.phone;
        if (proprietor?.lga?.length) {
          dataPayload[`proprietor[${i}][lga]`] = proprietor.lga;
        }
        dataPayload[`proprietor[${i}][date]`] = proprietor?.date
          ? proprietor?.date
          : `${proprietor.year}/${proprietor.month}/${proprietor.day}`;
        if (
          proprietor.passport_photograph &&
          typeof proprietor.passport_photograph === "object"
        ) {
          dataPayload[`proprietor[${i}][passportphoto]`] =
            proprietor.passport_photograph[0];
        }
        if (proprietor.passportphoto && (typeof proprietor.passportphoto === "number" || typeof proprietor.passportphoto === "string")) {
          dataPayload[`proprietor[${i}][passportphoto]`] = proprietor.passportphoto;
        }
        if (
          proprietor.e_signature &&
          typeof proprietor.e_signature === "object"
        ) {
          dataPayload[`proprietor[${i}][esign]`] = proprietor.e_signature[0];
        }
        if (proprietor.esign && (typeof proprietor.esign === "number" || typeof proprietor.esign === "string")) {
          dataPayload[`proprietor[${i}][esign]`] = proprietor.esign;
        }
        if (
          proprietor.government_id &&
          typeof proprietor.government_id === "object"
        ) {
          dataPayload[`proprietor[${i}][govtid]`] =
            proprietor.government_id[0];
        }
        if (proprietor.govtid && (typeof proprietor.govtid === "number" || typeof proprietor.govtid === "string")) {
          dataPayload[`proprietor[${i}][govtid]`] = proprietor.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    if (proprietorFormType === "director") {
      directorList.map((director, i) => {
        dataPayload[`director[${i}][full_name]`] = director.full_name;
        dataPayload[`director[${i}][city]`] = director.city;
        dataPayload[`director[${i}][email]`] = director.email;
        dataPayload[`director[${i}][address]`] = director?.address
          ? director.address
          : director.residential_address;
        dataPayload[`director[${i}][postal]`] = director.postal;
        dataPayload[`director[${i}][state]`] = director.state;
        dataPayload[`director[${i}][share_capital]`] = director.share_ownership;
        dataPayload[`director[${i}][occupation]`] = director.occupation;
        dataPayload[`director[${i}][country]`] = director.country;
        dataPayload[`director[${i}][phone]`] = director.phone;
        dataPayload[`director[${i}][nationality]`] = director.nationality;

        if (director?.lga?.length) {
          dataPayload[`director[${i}][lga]`] = director.lga;
        }
        dataPayload[`director[${i}][date]`] = director?.date
          ? director?.date
          : `${director.year}/${director.month}/${director.day}`;
        if (
          director.passport_photograph &&
          typeof director.passport_photograph === "object"
        ) {
          dataPayload[`director[${i}][passportphoto]`] =
            director.passport_photograph[0];
        }
        if (director.passportphoto && (typeof director.passportphoto === "number" || typeof director.passportphoto === "string")) {
          dataPayload[`director[${i}][passportphoto]`] = director.passportphoto;
        }
        if (director.e_signature && typeof director.e_signature === "object") {
          dataPayload[`director[${i}][esign]`] = director.e_signature[0];
        }
        if (director.esign && (typeof director.esign === "number" || typeof director.esign === "string")) {
          dataPayload[`director[${i}][esign]`] = director.esign;
        }
        if (
          director.government_id &&
          typeof director.government_id === "object"
        ) {
          dataPayload[`director[${i}][govtid]`] =
            director.government_id[0];
        }
        if (director.govtid && (typeof director.govtid === "number" || typeof director.govtid === "string")) {
          dataPayload[`director[${i}][govtid]`] = director.govtid;
        }
      });
      dataPayload = {
        ...dataPayload,
        registration_uuid: input.registration_uuid,
      };
    }
    dispatch(
      submitBusinessCacOfficerInformtion(
        dataPayload,
        { business_category: input.business_category?.toLowerCase() },
        () => {
          history.push("/dashboard/home");
          setTrusteeList([])
          setProprietorList([])
          setDirectorList([])
          setPartnerList([])

        }
      )
    );
  };
  const removeFromDirectorOrTrusteeList = (type, idx, listObject) => {
    let list = [];
    if (type === "partner") {
      list = partnerList;
      let newList = list.filter((item, i) => i !== idx);
      setPartnerList(newList);
    }
    if (type === "trustee") {
      list = trusteeList;
      let newList = list.filter((item, i) => i !== idx);
      setTrusteeList(newList);
    }
    if (type === "proprietor") {
      list = proprietorList;
      let newList = list.filter((item, i) => i !== idx);
      setProprietorList(newList);
    }
    if (type === "director") {
      list = directorList;
      let newList = list.filter((item, i) => i !== idx);
      setDirectorList(newList);
    }
  };

  return (
    <div className="space-y-3">
      <h3 className="font-bold">
        {businessType === "NGO" && "Trustees"}
        {businessType === "LLP" && "Partners"}
        {businessType === "LLC" && "Directors"}
        {businessType === "SP" && "Proprietors"}
      </h3>
      <p className="text-glade-gray-400">
        Please add at least one {businessType === "NGO" && "Trustee"}
        {businessType === "LLP" && "Partner"}
        {businessType === "LLC" && "Director"}
        {businessType === "SP" && "Proprietor"} of this business
      </p>

      <div>
        {businessType === "LLP" ? (
          <div className="space-y-3">
            {partnerList &&
              partnerList?.map((partner, index) => (
                <div
                  className="bg-glade-off-white flex items-center justify-between text-sm py-3"
                  key={index}
                >
                  <p className="">{partner?.full_name}</p>
                  <IoCloseOutline
                    className="cursor-pointer"
                    onClick={() =>
                      removeFromDirectorOrTrusteeList("partner", index, partner)
                    }
                  />
                </div>
              ))}
          </div>
        ) : (
          <></>
        )}
        {businessType === "LLC" ? (
          <div className="space-y-3">
            {directorList &&
              directorList?.map((director, index) => (
                <div
                  className="bg-glade-off-white flex items-center justify-between text-sm py-3"
                  key={index}
                >
                  <p className="">{director?.full_name}</p>
                  <IoCloseOutline
                    className="cursor-pointer"
                    onClick={() =>
                      removeFromDirectorOrTrusteeList(
                        "director",
                        index,
                        director
                      )
                    }
                  />
                </div>
              ))}
          </div>
        ) : (
          <></>
        )}
        {businessType === "SP" ? (
          <div className="space-y-3">
            {proprietorList &&
              proprietorList?.map((proprietor, index) => (
                <div
                  className="bg-glade-off-white flex items-center justify-between text-sm py-3"
                  key={index}
                >
                  <p className="">{proprietor?.full_name}</p>
                  <IoCloseOutline
                    className="cursor-pointer"
                    onClick={() =>
                      removeFromDirectorOrTrusteeList(
                        "proprietor",
                        index,
                        proprietor
                      )
                    }
                  />
                </div>
              ))}
          </div>
        ) : (
          <></>
        )}
        {businessType === "NGO" ? (
          <div className="space-y-3">
            {trusteeList &&
              trusteeList?.map((trustee, index) => (
                <div
                  className="bg-glade-off-white flex items-center justify-between text-sm py-3"
                  key={index}
                >
                  <p className="">{trustee?.full_name}</p>
                  <IoCloseOutline
                    className="cursor-pointer"
                    onClick={() =>
                      removeFromDirectorOrTrusteeList("trustee", index, trustee)
                    }
                  />
                </div>
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>

      {partnerList.length < 6 &&
        proprietorList.length < 6 &&
        directorList.length < 6 &&
        trusteeList.length < 6 ? (
        <div>
          <button
            className="text-glade-blue-500 font-medium text-sm mb-4"
            onClick={() => {
              setFormLevel("add proprietor information");
              if (businessType === "NGO") {
                setProprietorFormType("trustee");
              } else if (businessType === "LLC") {
                setProprietorFormType("director");
              } else if (businessType === "LLP") {
                setProprietorFormType("partner");
              } else if (businessType === "SP") {
                setProprietorFormType("proprietor");
              }
            }}
          >
            + Add a {businessType === "NGO" && "Trustee"}
            {businessType === "LLP" && "Partner"}
            {businessType === "LLC" && "Director"}
            {businessType === "SP" && "Proprietor"}
          </button>
        </div>
      ) : (
        <></>
      )}

      <Button.Blue
        title={"Submit"}
        loading={business?.isSubmittingBusiness}
        onClick={onSubmitDirectors}
        className={'w-full'}
        disabled={
          (partnerList.length === 0 &&
            proprietorList.length === 0 &&
            directorList.length === 0 &&
            trusteeList.length === 0) ||
            business.isSubmittingBusiness
            ? true
            : false
        }
      />
      <Button.Transparent
        title={"Save & Continue Later"}
        loading={business?.isSubmittingBusiness}
        onClick={onSubmitDirectorsContinueLater}
        className={'w-full'}
        disabled={
          (partnerList.length === 0 &&
            proprietorList.length === 0 &&
            directorList.length === 0 &&
            trusteeList.length === 0) ||
            business.isSubmittingBusiness
            ? true
            : false
        }
        style={{ color: "#1634A1" }}
      />
    </div>
  );
};
