import React from 'react';
import PlainModal from '../../components/modals/PlainModal';
import IndividualInput from '../../components/inputs/IndividualInput';
import Button from '../../components/buttons/Button';

export default React.forwardRef(function TwoFactorAuthenticationModal({type, email},ref) {

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    const maskEmail = (email) => {
        if(!email) return null;
        const emailParts = email.split('@');
        const emailPart1 = emailParts[0].substring(0,3);
        const emailPart2 = emailParts[1];
        return `${emailPart1}...@${emailPart2}`;
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            modalTitle={type==='active'?'Disable Two Factor Authentication':'Activate Two Factor Authentication'}
            primaryButton={<React.Fragment></React.Fragment>}
            modelContentSection={
            <div className="flex flex-col p-9">
                <h3 className="glade-small-text-two glade-ash my-3">
                    A confirmation code has been sent to {maskEmail(email)}. Enter the code to disable two factor authentication.
                </h3>
                <div className="flex justify-center my-3">
                    <IndividualInput onChange={()=>{}} />
                </div>
                <div className="flex justify-center my-6">
                    <Button.Blue title={'Submit'} onClick={()=>plainModalRef.current.close()} />
                </div>
                <h3 className="glade-normal-text-two glade-black my-3">
                    Didn’t get OTP? <span onClick={()=>{}} className="glade-normal-text-three glade-orange cursor-pointer">Resend</span>
                </h3>
            </div>}
        />
    )
});
