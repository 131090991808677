import React from "react";
import { useState } from "react";
import { VirtualCardTemplate } from "../../assets/icons/Icons";
import Button from "../../components/buttons/Button";
import { CloseIcon } from "../../assets/icons/Icons";
import { BsCircleFill } from "react-icons/bs";

const CardSkinColor = ({
  setCardSkinColor,
  setFormData,
  openConfirmPinModal,
  formData,
}) => {
  const [color, setColor] = useState("#2C6DEA");
  return (
    <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-8 lg:px-32 py-32 flex flex-col justify-center">
      <div className="flex justify-center items-center">
        <div className="self-center bg-white rounded md:w-1/3 p-5 h-full">
          <div className="text-center mb-4">
            <span>Select Card Skin</span>
            <button
              onClick={() => setCardSkinColor(false)}
              className="float-right"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="mx-auto my-9 flex flex-col items-center justify-center space-y-3">
            <VirtualCardTemplate
              color={color}
              card_type={
                formData?.card_type === "Verve card" ? "Verve" : "master"
              }
            />
            <div className="mt-4 flex justify-center items-center space-x-6">
              <BsCircleFill
                className="p-2"
                size={60}
                color={"#2C6DEA"}
                style={{
                  border: color === "#2C6DEA" ? "2px solid #2C6DEA" : "",
                  borderRadius: "50%",
                }}
                onClick={() => setColor("#2C6DEA")}
              />
              <BsCircleFill
                size={60}
                className="p-2"
                color={"#303030"}
                style={{
                  border: color === "#303030" ? "2px solid #303030" : "",
                  borderRadius: "50%",
                }}
                onClick={() => setColor("#303030")}
              />
              <BsCircleFill
                className="p-2"
                size={60}
                color={"#FF6F00"}
                style={{
                  border: color === "#FF6F00" ? "2px solid #FF6F00" : "",
                  borderRadius: "50%",
                }}
                onClick={() => setColor("#FF6F00")}
              />
            </div>
          </div>

          <div className="flex justify-center mt-3">
            <Button.Blue
              title={"Continue"}
              type={"button"}
              onClick={() => {
                setCardSkinColor(false);
                setFormData((formData) => ({ ...formData, color: color }));
                openConfirmPinModal();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSkinColor;
