import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import { getAllSettlements } from "../../redux/settlements/settlementActions";
import useSettlements from "../../hooks/useSettlements";
import {
  simpleDate,
  statusEnum,
  isArray,
  isEmpty,
  pagination,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import TextIndicator from "../../components/indicators/TextIndicator";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import Sieve from "../../components/filter/Sieve";
import { downloadSettlementAsCSV } from "./DownloadSettlementAsCSV";
import Button from "../../components/buttons/Button";
import { IoIosArrowForward } from "react-icons/io";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import useTeam from "../../hooks/useTeam";

export default function Settlements() {
  const history = useHistory();
  const dispatch = useDispatch();
  const settlement = useSettlements();
  const dataForCSV = settlement?.settlementIndex;
  const { permissions } = useTeam();

  React.useEffect(() => {
    dispatch(getAllSettlements());
    // eslint-disable-next-line
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(settlement.settlementIndex, 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, settlement.settlementIndex]);

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getAllSettlements({
        status: data.settlement_type,
        date_from: data.date_from ?? data.date,
        date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
      })
    );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getAllSettlements());
  };

  const renderSettlementsTable = (data) => {
    return data.map((item, key) => {
      return (
        <CardTableBodyRow
          className={"cursor-pointer"}
          key={key}
          onClick={() =>
            history.push({
              pathname: !permissions?.includes("settlements")
                ? "/dashboard/settlements/settlement-detail"
                : "/teams/settlements/settlement-detail",
              state: item,
            })
          }
        >
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <TextIndicator
              type={statusEnum[item?.settled ? "success" : "pending"]}
              title={item?.settled ? "settled" : "unsettled"}
            />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              {simpleDate(item?.txn_date, "numeric", "short")}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one whitespace-nowrap">
              NGN {Number(item?.total_amount)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one whitespace-nowrap">
              NGN {Number(item?.amount_paid)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderSettlementsDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() =>
            history.push({
              pathname: !permissions?.includes("settlements")
                ? "/dashboard/settlements/settlement-detail"
                : "/teams/settlements/settlement-detail",
              state: item,
            })
          }
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator
                type={statusEnum[item?.settled ? "success" : "pending"]}
              />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  NGN {Number(item?.total_amount)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.txn_date, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <CardTableHeaderComplex
        title={`Showing ${tableData.totalItems} records`}
        filter={
          <Sieve
            types={["settlement type", "date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={settlement.isLoading}
            loading={settlement.isLoading}
          />
        }
        rightButton={
          <div>
            <Button.Blue
              title={"Download As CSV"}
              onClick={() => downloadSettlementAsCSV(dataForCSV)}
              disabled={
                settlement.isLoading || isEmpty(settlement.settlementIndex)
              }
            />
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={settlement.isLoading}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["settlement type", "date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={settlement.isLoading}
                    loading={settlement.isLoading}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              {!isEmpty(settlement.settlementIndex) ? (
                <KebabDropdownItem
                  activeChild={
                    <span
                      onClick={() => downloadSettlementAsCSV(dataForCSV)}
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Download as CSV
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Download as CSV
                    </span>
                  }
                />
              ) : null}
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || settlement.isLoading ? (
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable
              column={[
                "",
                "Status",
                "Date",
                "Total Amount",
                "Settled Amount",
                "",
              ]}
              loading={settlement.isLoading}
            />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2]} loading={settlement.isLoading} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="hidden md:block">
            <CardTableBody
              column={[
                "",
                "Status",
                "Date",
                "Total Amount",
                "Settled Amount",
                "",
              ]}
            >
              {isArray(tableData.data) &&
                renderSettlementsTable(tableData.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderSettlementsDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={settlement.isLoading}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
    </div>
  );
}
