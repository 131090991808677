import React from 'react';
import { EmptyFolder } from '../../assets/icons/Icons';
import { TextSkeleton } from "../skeletons";
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';

export default function EmptyTable({ title, subtitle, column = [], loading = false, button }) {
  const renderTableRows = () => {
    return [1, 2, 3, 4, 5, 6].map((item, key) => {
      return (
        <CardTableBodyRow key={key}>
          {column.map((col, key) => {
            return (
              <CardTableBodyCell key={key}>
                <TextSkeleton />
              </CardTableBodyCell>
            )
          })}
        </CardTableBodyRow>
      )
    });
  }

  return (
    <React.Fragment>
      {loading ?
        <CardTableBody column={column}>
          {renderTableRows()}
        </CardTableBody>
        :
        <div className="relative flex justify-center items-center bg-white h-96">
          <div className="flex flex-col">
            <div className="inline-flex justify-center my-3">
              <EmptyFolder color="#2C6DEA" />
            </div>
            <h3 className="glade-heading-three glade-black text-center my-1">
              {title ?? 'No Data'}
            </h3>
            <h6 className="glade-normal-text-two glade-ash text-center my-1">
              {subtitle ?? 'We don\'t have any information for this category'}
            </h6>
            {button &&
              button
            }
          </div>
        </div>
      }
    </React.Fragment>
  )
}
