import React from 'react';

export default function BasicIndicator({title, type, style={}}) {
    return (
        <React.Fragment>
            {type === 'success'?
                <div className="px-7 py-3 h-4 inline-flex items-center rounded-md glade-normal-text-two glade-bg-green-100 glade-green capitalize " style={style}>
                    <span className="glade-green-light">{title}</span>
                </div>
            :null}
            {type === 'warning'?
                <div className="px-7 py-3 h-4 inline-flex items-center rounded-md glade-normal-text-two glade-bg-yellow-100 glade-yellow capitalize " style={style}>
                    <span className="glade-yellow">{title}</span>
                </div>
            :null}
            {type === 'neutral'?
                <div className="px-7 py-3 h-4 inline-flex items-center rounded-md glade-normal-text-two glade-bg-black-100 glade-black capitalize " style={style}>
                    <span className="glade-black">{title}</span>
                </div>
            :null}
            {type === 'danger'?
                <div className="px-7 py-3 h-4 inline-flex items-center rounded-md glade-normal-text-two glade-bg-red-100 glade-red capitalize " style={style}>
                    <span className="glade-red">{title}</span>
                </div>
            :null}
        </React.Fragment>
    )
}
