import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TransferContextProvider from "./context/TransferFormContext";
import SideNavContextProvider from './context/SideNavContext';
import TwoFactorContextProvider from './context/TwoFactorModalContext';

ReactDOM.render(
  <React.StrictMode>
    <TwoFactorContextProvider>
      <SideNavContextProvider>
        <TransferContextProvider>
          <App />
        </TransferContextProvider>
      </SideNavContextProvider>
    </TwoFactorContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
