import React from "react";
import { ImSpinner2 } from "react-icons/im";

const Loading = ({title}) => {
    return (
        <div>
            <div className='mt-6'>
          <span className='glade-heading-three glade-home-dark'>{title}</span>
            </div>
            <div className='flex justify-center'><ImSpinner2 className='mt-6 spinner text-center' size={70} color='#0B7AE0' /></div>
        </div>
    );
};

export default Loading;