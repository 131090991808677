import { csvWorkbookDownload } from "../../helpers/XlsxUtils";
import * as xlsx from "xlsx";

export function downloadPaymentInflowAsCSV(transactions, selectedCurrency, onChange = () => {}) {
    const fileName = `Payment-Inflow-transactions-${selectedCurrency}`;
    const filterTransactions = transactions.filter(
      transaction => transaction.currency === selectedCurrency
      );
    const getWorksheets = ({ xlsx }) => {
        return {
            data: xlsx.utils.json_to_sheet(
                filterTransactions?.map((item, i) => ({
                    Status: item.txn_status,
                    Date: item.created_at,
                    "Customer Email": item.user_email,
                    "Payment Type": item.payment_type,
                    "Txn Ref": item.txn_ref,
                    "Payment Amount": item.value,
                }))
                ),
            };
        };
       
    (async () => {
        if (getWorksheets) {
            try {
                onChange({ isLoading: true, isError: false });
                const workbook = xlsx.utils.book_new();
                const worksheets = await getWorksheets({ workbook, xlsx });
                if (worksheets) {
                    for (const key in worksheets) {
                        xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
                    }
                    if (workbook.SheetNames.length) {
                        csvWorkbookDownload(workbook, fileName);
                    }
                }
            } catch (error) {
                onChange({ isLoading: false, isError: true });
            }
            onChange({ isLoading: false, isError: false });
        }
    })();
}