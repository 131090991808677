import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";

// Available Reducers
import authReducer from "./auth/authReducer";
import alertReducer from "./alert/alertReducer";
import transactionsReducer from "./transactions/transactionReducer";
import settlementReducer from "./settlements/settlementReducer";
import paymentInFlowReducer from "./payment-inflow/paymentInFlowReducer";
import customersReducer from "./customers/customersReducer";
import fundTransferReducer from "./fund-transfer/fundTransferReducer";
import virtualCardsReducer from "./virtual-cards/virtualCardReducer";
import billsReducer from "./bills/billsReducer";
import airtimeReducer from "./airtime/airtimeReducer";
import paymentPagesReducer from "./payment-pages/paymentPagesReducer";
import transactionSplitReducer from "./transaction-split/transactionSplitReducer";
import chargeBackReducer from "./charge-backs/chargeBackReducer";
import settingsReducer from "./settings/settingsReducer";
import posReducer from "./pos/posReducer";
import invoiceReducer from "./invoice/invoiceReducer";
import homeReducer from "./home/homeReducer";
import businessReducer from "./business/businessReducer";
import fundAccountReducer from "./fund-account/fundAccountReducer";
import payrollReducer from "./payroll/payrollReducer";
import exchangeRateReducer from "./exchange-rates/exchangeRateReducer";
import accountLimitReducer from "./accountLimit/accountLimitReducer";
import notificationReducer from "./notifications/notificationsReducer";
import convertReducer from "./convert/convertReducer";
import balancesReducer from "./balances/balancesReducer";
import addCurrencyReducer from "./addCurrency/addCurrencyReducer";
import countriesReducer from "./countries/countriesReducer";
import teamReducer from "./Team/TeamReducer";
import recurringReducer from "./recurringTransfer/recurringTransferReducer";

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  paymentFlows: paymentInFlowReducer,
  settlements: settlementReducer,
  transactions: transactionsReducer,
  customers: customersReducer,
  fundTransfer: fundTransferReducer,
  virtualCards: virtualCardsReducer,
  bills: billsReducer,
  airtime: airtimeReducer,
  paymentPages: paymentPagesReducer,
  transactionSplit: transactionSplitReducer,
  currencies: addCurrencyReducer,
  chargeBack: chargeBackReducer,
  settings: settingsReducer,
  pos: posReducer,
  invoice: invoiceReducer,
  home: homeReducer,
  business: businessReducer,
  fundAccount: fundAccountReducer,
  payroll: payrollReducer,
  exchangeRate: exchangeRateReducer,
  accountLimit: accountLimitReducer,
  notification: notificationReducer,
  convert: convertReducer,
  balances: balancesReducer,
  countries: countriesReducer,
  team: teamReducer,
  recurring: recurringReducer,
});

const _reducerName = process.env.REACT_APP_SLUG+'_reducer';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(_reducerName);
    return serializedState === null? {} : JSON.parse(serializedState);
  } catch (err){
    return {};
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    return localStorage.setItem(_reducerName, serializedState);
  } catch (err){
    // Ignore write errors
  }
}

// const initialState = {};
const initialState = loadState();
const middleware = [thunk];
const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

store.subscribe(()=>saveState(store.getState()));

export default store;
