// Application types
export const LOADING_TRANSACTION = 'LOADING_TRANSACTION';
export const RELOAD_TRANSACTION = 'RELOAD_TRANSACTION';

export const GET_STATEMENTS = 'GET_STATEMENTS';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION_LIST = 'GET_TRANSACTION_LIST';
export const GET_TRANSACTION_BY_ID = 'GET_TRANSACTION_BY_ID';

export const LOADING_REPORT_TRANSACTION = "LOADING_REPORT_TRANSACTION";
export const LOADING_REPORT_TRANSACTION_FAILURE = "REPORT_TRANSACTION_FAILURE";
export const REPORT_TRANSACTION_SUCCESS = "REPORT_TRANSACTION_SUCCESS";