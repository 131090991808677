import { PRIMARY_OWNER_FAILURE, PRIMARY_OWNER_START, PRIMARY_OWNER_SUCCESS } from "./types";
import Axios from "../../../connection/defaultClient";
import errorHandler from "../../../handlers/errorHandler";
import successHandler from "../../../handlers/successHandler";

/**
 * Create Or Primary owner for business
 * 
 * @param {object} 
 * compliance_id: '150',
 * payLoad{
 *      first_name: 'mac',
 *      last_name: 'brill',
 *      email: string;
        phone: string;
        state: string;
        lga: string;
 * }
 * @returns {void}
*/
export const addPrimaryOwner = (compliance_id, payLoad, onSuccess, onFailure) => {
    return (dispatch) => {
        dispatch({ type: PRIMARY_OWNER_START, payLoad: true });
        Axios.post(`/businesses/compliance/${compliance_id}/primary-owner`, payLoad, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => {
                dispatch({ type: PRIMARY_OWNER_SUCCESS, payLoad: res });
                successHandler(res, true);
                onSuccess();
            })
            .catch((error) => {
                dispatch({ type: PRIMARY_OWNER_FAILURE, payLoad: false });
                errorHandler(error, true);
                onFailure();
            });
    }
}

/**
 * Create/Add Business director or trustee
 * 
 * @param {object} 
 * compliance_id: '150',
 * payLoad{
 *      first_name: 'mac',
 *      last_name: 'brill',
 *      email: string;
        phone: string;
        state: string;
        lga: string;
 * }
 * @returns {void}
*/
export const addBusinessDirector = (compliance_id, payLoad, onSuccess, onFailure) => {
    return (dispatch) => {
        dispatch({ type: PRIMARY_OWNER_START, payLoad: true });
        Axios.post(`/businesses/compliance/${compliance_id}/director`, payLoad, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => {
                dispatch({ type: PRIMARY_OWNER_SUCCESS, payLoad: res });
                successHandler(res, true);
                onSuccess(res.data);
            })
            .catch((error) => {
                dispatch({ type: PRIMARY_OWNER_FAILURE, payLoad: false });
                errorHandler(error, true);
                onFailure();
            });
    }
}

/**
 * Create Or Primary owner for business
 * 
 * @param {object} 
 * compliance_id: '150',
 * payLoad{
 *      first_name: 'mac',
 *      last_name: 'brill',
 *      email: string;
        phone: string;
        state: string;
        lga: string;
 * }
 * @returns {void}
*/
export const deleteBusinessDirector = (compliance_id, director_compliance_id, type, onSuccess) => {
    return (dispatch) => {
        dispatch({ type: PRIMARY_OWNER_START, payLoad: true });
        Axios.delete(`/businesses/compliance/${compliance_id}/delete-${type}${type !== 'primary-owner' ? `/${director_compliance_id}` : ''}`)
            .then((res) => {
                dispatch({ type: PRIMARY_OWNER_SUCCESS, payLoad: res });
                successHandler(res, true);
                onSuccess();
            })
            .catch((error) => {
                dispatch({ type: PRIMARY_OWNER_FAILURE, payLoad: false });
                errorHandler(error, true);
            });
    }
}
