import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_VIRTUAL_CARDS,
  RELOAD_VIRTUAL_CARDS,
  GET_VIRTUAL_CARD_TITLES,
  GET_VIRTUAL_CARD_CURRENCIES,
  GET_VIRTUAL_CARD_DESIGNS,
  CREATE_VIRTUAL_CARD,
  GET_VIRTUAL_CARDS,
  GET_VIRTUAL_CARD_DETAILS,
  GET_VIRTUAL_CARD_TRANSACTIONS,
  FUND_VIRTUAL_CARD,
  WITHDRAW_VIRTUAL_CARD_FUND,
  FREEZE_VIRTUAL_CARD,
  UNFREEZE_VIRTUAL_CARD,
  TERMINATE_VIRTUAL_CARD,
  SEARCH_VIRTUAL_CARDS,
  FILTER_VIRTUAL_CARDS,
  LOADING_EDIT_VIRTUAL_CARDS,
  EDIT_VIRTUAL_CARDS_SUCCESS,
  EDIT_VIRTUAL_CARDS_FAILURE,
  GET_VIRTUAL_CARD_CHARGES
} from "./types";

/**
 * Get virtual card titles
 * @returns {void}
 */
export const getVirtualCardTitle = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/lookup/titles`)
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARD_TITLES, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};



/**
 * edit virtual card 
 * @returns {void}
 */
export const editVirtualCard = (putData, payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type:  LOADING_EDIT_VIRTUAL_CARDS});
    Axios.put(`/virtual-cards/${payLoad.reference}/personalize`,putData)
    .then((res) => {
      dispatch({ type: EDIT_VIRTUAL_CARDS_SUCCESS, payLoad:res });
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type:  EDIT_VIRTUAL_CARDS_FAILURE });
      errorHandler(error, true);
    });
  }
}



/**
 * Get virtual card currencies
 * @returns {void}
 */
export const getVirtualCardCurrencies = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/lookup/currencies`)
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARD_CURRENCIES, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Get virtual card currencies
 * @returns {void}
 */
export const getVirtualCardDesigns = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/lookup/designs`)
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARD_DESIGNS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * create virtual card
 * 
 * @param {formData} payLoad {
    "amount": "1000",
    "gender": "male",
    "currency": "NGN",
    "country": "NG",
    "card_title": "Shopping",
    "color": "#FFFFFF",
    address[0][country],
    address[0][state],
    address[0][city],
    address[0][street],
    address[0][postal_code],
    type: 'naira',
    place_of_birth: 'home',
    passport_photo

 }
 * @returns {void}
 */
export const createVirtualCard = (formdata,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.post(`/virtual-cards`, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: CREATE_VIRTUAL_CARD, payLoad: res });
      dispatch({ type: RELOAD_VIRTUAL_CARDS });
      successHandler(res, true);
      callBack(res.data.data);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Get virtual cards
 * 
 * @param {object} {search:'', page:1, limit:10}
 * @returns {void}
 */
export const getVirtualCards = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARDS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Get virtual card details
 * 
 * @param {object} {reference:''}
 * @returns {void}
 */
export const getVirtualCardDetails = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/${payLoad.reference}/details`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARD_DETAILS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARD_DETAILS, payLoad: {data:{data:{}}} });
      errorHandler(error, true);
    });
  };
};

/**
 * Get virtual card transactions
 * 
 * @param {object} {reference:''}
 * @returns {void}
 */
export const getVirtualCardTransactions = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/${payLoad.reference}/transactions`,{params: payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARD_TRANSACTIONS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * fund virtual card
 * 
 * @param {object} {reference:''}
 * @returns {void}
 */
export const fundVirtualCard = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.post(`/virtual-cards/${payLoad.reference}/fund`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: FUND_VIRTUAL_CARD, payLoad: res });
      dispatch({ type: RELOAD_VIRTUAL_CARDS });
      successHandler(res);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * withdraw virtual card
 * 
 * @param {object} {reference:''}
 * @returns {void}
 */
export const withdrawVirtualCard = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.post(`/virtual-cards/${payLoad.reference}/withdraw`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: WITHDRAW_VIRTUAL_CARD_FUND, payLoad: res });
      dispatch({ type: RELOAD_VIRTUAL_CARDS });
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * freeze virtual card
 * 
 * @param {object} payLoad {  
  "reference":'fjnwefu9329u23|r32932'
 }
 * @returns {void}
 */
export const freezeVirtualCard = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.put(`/virtual-cards/${payLoad?.reference}/freeze`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: FREEZE_VIRTUAL_CARD, payLoad: res });
      dispatch({ type: RELOAD_VIRTUAL_CARDS });
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * unfreeze virtual card
 * 
 * @param {object} payLoad {  
  "card_id":''
  "amount": "1"
 }
 * @returns {void}
 */
export const unfreezeVirtualCard = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.put(`/virtual-cards/${payLoad.reference}/unfreeze`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: UNFREEZE_VIRTUAL_CARD, payLoad: res });
      dispatch({ type: RELOAD_VIRTUAL_CARDS });
      successHandler(res, true);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * terminate virtual card
 * 
 * @param {object} payLoad {  
  "reference":''
  "amount": "1"
 }
 * @returns {void}
 */
export const terminateVirtualCard = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.post(`/virtual-cards/${payLoad.reference}/delete-request`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: TERMINATE_VIRTUAL_CARD, payLoad: res });
      // dispatch({ type: RELOAD_VIRTUAL_CARDS });
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * search virtual cards
 * 
 * @param {object} {search:'', page:1, limit:10}
 * @returns {void}
 */
export const searchVirtualCards = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/search`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: SEARCH_VIRTUAL_CARDS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * filter virtual cards
 * 
 * @param {object} {status:'', date_from:'', date_to:'', currency:'', page:1, limit:10}
 * @returns {void}
 */
export const filterVirtualCards = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/filter`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: FILTER_VIRTUAL_CARDS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

export const getVirtualCardsFees = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: true });
    Axios.get(`/virtual-cards/fees`)
    .then((res) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      dispatch({ type: GET_VIRTUAL_CARD_CHARGES, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_VIRTUAL_CARDS, payLoad: false });
      errorHandler(error, true);
    });
  };
};
