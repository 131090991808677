import React from "react";
import { useDispatch } from "react-redux";
import PlainModal from "../../components/modals/PlainModal";
import Button from "../../components/buttons/Button";
import {
  createPayday,
  getPaydayById,
} from "../../redux/payroll/payrollActions";
import SelectInput from "../../components/inputs/SelectInput";
import DateInput from "../../components/inputs/DateInput";
import TextInput from "../../components/inputs/TextInput";
import useUser from "../../hooks/useUser";

export default React.forwardRef(function PaydayCreateModal(props, ref) {
  const dispatch = useDispatch();
  const user = useUser();

  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: (value) => {
      staffList(value);
      plainModalRef.current.open();
    },
    close: () => {
      setInput({ ...input, employee_id_list: [] });
      plainModalRef.current.close();
    },
    toggle: (value) => {
      staffList(value);
      plainModalRef.current.toggle();
    },
  }));

  const [input, setInput] = React.useState({
    employee_id_list: [],
    business_uuid: user?.isBusiness
      ? user?.selectedBusinessOrPersonalAccount?.business_uuid
      : user?.userInfo?.uuid,
    pay_day_type: "",
    payday: new Date().toISOString().substring(0, 10),
    description: "",
  });

  const staffList = (value) => {
    setInput({ ...input, employee_id_list: [] });
    dispatch(
      getPaydayById({ payday_id: value.payroll_id }, (data) => {
        let employee_ids = data?.payroll_details?.map((item) => {
          return item.staff_id;
        });

        setInput({ ...input, employee_id_list: employee_ids ?? [] });
      })
    );
  };

  const onsubmit = () => {
    dispatch(createPayday(input));
    plainModalRef.current.close();
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <PlainModal
      ref={plainModalRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitle={"Confirm Payment"}
      modelContentSection={
        <div className="flex flex-col space-y-9">
          <div className="flex flex-col px-9 pt-9 text-left space-y-9">
            <SelectInput
              label={"Select Payday Type"}
              id={"pay_day_type"}
              name={"pay_day_type"}
              value={input.pay_day_type}
              onChange={onChange}
              autoComplete={"currency"}
              className={"appearance-none"}
            >
              <option value={""} disabled={true}>
                Select Occurrence
              </option>
              <option value={"instant"}>{"Instant"}</option>
              <option value={"fixed"}>{"Scheduled"}</option>
            </SelectInput>

            {input.pay_day_type === "fixed" && (
              <DateInput
                label={"Select Date"}
                id={"payday"}
                name={"payday"}
                type={"date"}
                value={input.payday}
                min={new Date().toISOString().substring(0, 10)}
                onChange={onChange}
                placeholder={"Select payment date"}
                autoComplete={"payday"}
                className={"appearance-none"}
              />
            )}

            <TextInput
              label={"Note"}
              id={"description"}
              name={"description"}
              type={"text"}
              value={input.description}
              onChange={onChange}
              placeholder={"Enter description"}
              autoComplete={"description"}
              className={"appearance-none"}
            />
          </div>

          <div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
            <div>
              <Button.Transparent
                onClick={() => plainModalRef.current.close()}
                title="Cancel"
              />
            </div>
            <div>
              <Button.Blue
                disabled={
                  input.employee_id_list.length < 1 || !input.pay_day_type
                }
                onClick={() => onsubmit()}
                title={`Pay (${input.employee_id_list.length ?? 0}) Staff(s)`}
              />
            </div>
          </div>
        </div>
      }
    />
  );
});
