// Application types
export const LOADING_POS = 'LOADING_POS';
export const RELOAD_POS = 'RELOAD_POS';

export const GET_REVENUE = 'GET_REVENUE';
export const GET_SALES = 'GET_SALES';
export const GET_STATES = 'GET_STATES';
export const GET_LGA = 'GET_LGA';
export const REQUEST_PERSONAL_POS = 'REQUEST_PERSONAL_POS';
export const REQUEST_BUSINESS_POS = 'REQUEST_BUSINESS_POS';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';
export const GET_PENDING_REQUESTS = 'GET_PENDING_REQUESTS';
export const GET_APPROVED_REQUESTS = 'GET_APPROVED_REQUESTS';
export const GET_POS_TRANSACTIONS = 'GET_POS_TRANSACTIONS';
export const GET_POS_TERMINALS = 'GET_POS_TERMINALS';
export const GET_POS_TERMINAL_DETAILS = 'GET_POS_TERMINAL_DETAILS';
export const GET_POS_TERMINAL_REQUESTS = 'GET_POS_TERMINAL_REQUESTS';
export const GET_POS_TERMINAL_REQUESTS_BY_ID = 'GET_POS_TERMINAL_REQUESTS_BY_ID';