import React, { useContext } from 'react';
import styled from 'styled-components';
import { TransferFormContext } from '../../context/TransferFormContext';
import useFundTransfer from '../../hooks/useFundTransfer';
import InfoIcon from './InfoIcon';
import { calculateBalance, commmaToInput, removeCommas } from '../../helpers/helper';


const CurrencyConvert = ({ fee }) => {
  const { transferForm, currencies } = useContext(TransferFormContext);
  const { internationalTransferRate } = useFundTransfer();

  return (
    <Div>
      <section className='flex items-center gap-2'>
        <InfoIcon />
        <span>Rate</span>
      </section>
      
      <section>
        <span>1 {currencies?.activeCurrency?.currency}</span>
        <span> = </span>
        <span>{internationalTransferRate?.rate || 0} {currencies?.selectedCurrency?.currency}</span>
      </section>
      <section>
        <span>Transaction fee</span>
        <span> = </span>
        <span>{(Number(internationalTransferRate?.fee) || 0).toFixed(2)} {currencies?.activeCurrency?.currency}</span>
      </section>
      <section>
        <span className='font-bold'>
          Your total =  {((Number(parseFloat(removeCommas(transferForm?.amount))) + (Number(parseFloat(internationalTransferRate?.fee || 0)))) || 0).toFixed(2)} {currencies?.activeCurrency?.currency}
        </span>
      </section>
    </Div>
  );
};

export default CurrencyConvert;

const Div = styled.div`
  background: #FAFAFA;
  padding: 1.5rem 2rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  margin: 0 0 1rem;
  overflow-x: auto;
`;