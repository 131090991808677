import { createContext, useState } from "react";

const contextType = {
  navCollapsed: false,
  setNavCollapsed: () => { },
  toggleNav: () => { },
  closeNav: () => { },
  openNav: () => { },
};

export const SideNavContext = createContext(contextType);

const SideNavContextProvider = ({ children }) => {
  const [navCollapsed, setNavCollapsed] = useState(false);

  const toggleNav = () => setNavCollapsed(open => !open)

  const openNav = () => {
    document.body.style.overflowY = 'hidden'
    setNavCollapsed(false)
  };

  const closeNav = () => {
    document.body.style.overflowY = 'auto'
    setNavCollapsed(true)
  };

  return (
    <SideNavContext.Provider
      value={{
        toggleNav,
        navCollapsed,
        setNavCollapsed,
        openNav,
        closeNav
      }}>
      {children}
    </SideNavContext.Provider>
  );
};

export default SideNavContextProvider;