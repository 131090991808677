import Axios from "../../connection/defaultClient";
import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import {
  LOADING_TRANSACTION,
  // RELOAD_TRANSACTION,
  GET_STATEMENTS,
  GET_TRANSACTIONS,
  GET_TRANSACTION_LIST,
  GET_TRANSACTION_BY_ID,
  LOADING_REPORT_TRANSACTION
} from "./types";
import { objectToFormData } from "../../helpers/helper";

/**
 * Get all statements
 * @returns {void}
 */
 export const getAllStatements = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION, payLoad:true});
    Axios.get(`/statement`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      dispatch({ type: GET_STATEMENTS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      dispatch({ type: GET_STATEMENTS, payLoad: {data: {data: []}}});
    });
  }
}

/**
 * Get all transactions
 * @returns {void}
 */
export const getAllTransactions = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION, payLoad:true});
    Axios.get(`/businesses/transactions`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      dispatch({ type: GET_TRANSACTIONS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get transaction list
 * @param {object} payLoad {currency:'NGN', type:'all', status:''}
 * @returns {void}
 */
 export const getTransactionList = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION, payLoad:true});
    Axios.get(`/businesses/transactions/list`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      dispatch({ type: GET_TRANSACTION_LIST, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get transaction by id
 * @param {object} payLoad {id:''}
 * @returns {void}
 */
 export const getTransactionByID = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION, payLoad:true});
    Axios.get(`/businesses/transactions/details/${payLoad.id}`)
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      dispatch({ type: GET_TRANSACTION_BY_ID, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      errorHandler(error, true);
    });
  }
}

export const reportTransaction = (payLoad, onSuccess=()=>{}, onLoad=()=>{}) => {
  return (dispatch) => {
    onLoad(true);
    dispatch({ type: LOADING_REPORT_TRANSACTION});
    Axios.post(`transfers/report-transaction`, objectToFormData(payLoad))
    .then((res) => {
      onSuccess()
      successHandler(res);
      onLoad(false);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION, payLoad:false});
      errorHandler(error, true);
      onLoad(false);
    });
  }
}