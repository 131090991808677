import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const dashboardFundingValidation = (payLoad, silently=false) => {

    let data = {
        amount:payLoad.amount
    };
    let rules = {
        amount:'required|min:9|max:999999999999999'
    }
    let messages = {
        amount:{
            required:'An amount is required',
            min:'Minimum amount is NGN 10',
            max:'Amount is not valid'
        }
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}