import { useSelector } from 'react-redux';

const useInvoice = () => {
    const invoice = useSelector(state => state.invoice);

    let {
        isLoading,
        shouldReload,
        invoiceIndex,
        invoiceById,
        invoiceCounter,
        createInvoice,
        createInvoiceDraft,
        updateInvoice,
        updateInvoiceStatus,
        deleteInvoice,
        invoiceCurrencies,
        sendInvoiceReminder,
        createInvoiceCustomer,
        whitelistedInvoiceCustomers,
        blacklistedInvoiceCustomers,
    } = invoice;

    return {
        isLoading,
        shouldReload,
        invoiceIndex,
        invoiceById,
        invoiceCounter,
        createInvoice,
        createInvoiceDraft,
        updateInvoice,
        updateInvoiceStatus,
        deleteInvoice,
        invoiceCurrencies,
        sendInvoiceReminder,
        createInvoiceCustomer,
        whitelistedInvoiceCustomers,
        blacklistedInvoiceCustomers,
    }
};

export default useInvoice;
