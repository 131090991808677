import { useState, useEffect, useCallback } from "react";
import { STransferForm } from "./TransferInternationalForm";
import SendInput from "../../components/transfer/SendInput";
import { useContext } from "react";
import { TransferFormContext } from "../../context/TransferFormContext";
import InputGroup from "../../components/transfer/InputGroup";
import Button from "../../components/buttons/Button";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import ChargeInfo from "../../components/transfer/ChargeInfo";
import { useDispatch } from "react-redux";
import { getBanks, getLocalTransferRate, resolveAccountDetails } from "../../redux/fund-transfer/fundTransferActions";
import useFundTransfer from "../../hooks/useFundTransfer";
import InputDropdown from "../../components/dropdowns/InputDropdown";
import { LinkText } from "./TransferInternationalForm";
import { removeCommas } from "../../helpers/helper";

const LocalNGNTransfer = () => {
  const {
    availableBalance,
    transferForm,
    setTransferForm,
    setFormStage,
    setTransferCharge,
    transferCharge,
    repeatTransferDetails,
    repeatTransaction
  } = useContext(TransferFormContext);
  const [toSaveBeneficiary, setToSaveBeneficiary] = useState(false);
  const dispatch = useDispatch();
  const [providers, setProviders] = useState({
    selectedBank: {},
    allBanks: []
  });
  const { banks, isLoadingResolveDetails, localTransferRate } = useFundTransfer();
  

  const onSaveBeneficiary = () => {
    setToSaveBeneficiary(saveBeneficiary => !saveBeneficiary);
    setTransferForm(form => ({ ...form, save_beneficiary: !toSaveBeneficiary }));
  };

  useEffect(() => {
    dispatch(getBanks({ mode: "external" }));
    setProviders(providers => ({ ...providers, allBanks: banks }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTransferCharge(localTransferRate?.fee ?? localTransferRate?.rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTransferRate]);

  useEffect(() => {
    if (transferForm?.beneficiary_account_number?.length === 10 && transferForm?.beneficiary_bank) {
      dispatch(
        resolveAccountDetails(
          {
            account_number: transferForm.beneficiary_account_number,
            bank_code: transferForm.beneficiary_bank,
            mode: 'external_local'
          },
          (resolveAccountDetails) => setTransferForm(form => ({ ...form, beneficiary_account_name: resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id }))
        )
      );
    } else if (transferForm?.beneficiary_account_number?.toLowerCase().slice(0, 2) === 'gf' && transferForm?.beneficiary_account_number?.length >= 12) {
      dispatch(
        resolveAccountDetails(
          {
            account_number: transferForm.beneficiary_account_number,
            mode: 'internal_wallet'
          },
          (resolveAccountDetails) => setTransferForm(form => ({ ...form, beneficiary_account_name: resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id }))
        )
      );
    }
    else {
      setTransferForm(form => ({
        ...form,
        beneficiary_account_name: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferForm?.beneficiary_account_number, transferForm?.beneficiary_bank, providers?.selectedBank]);

  function onSelectProvider(provider, callback) {
    setProviders(providers => ({ ...providers, selectedBank: provider }));
    setTransferForm(form => ({
      ...form,
      beneficiary_bank: provider.code,
      beneficiary_bank_name: provider.name
    }));
    callback();
  }

  useEffect(() => {
    if (repeatTransaction && repeatTransferDetails ) {
    setTransferForm(form => (
      { ...form,
        // beneficiary_account_name:repeatTransferDetails?.beneficiary_name,
        beneficiary_account_number:repeatTransferDetails?.beneficiary_account,
        beneficiary_bank_name:repeatTransferDetails?.bank_name
        }))};
  },[repeatTransferDetails, repeatTransaction ])

  useEffect(() => {
    if (repeatTransaction && repeatTransferDetails&& transferForm?.beneficiary_bank_name ) {
    setTransferForm(form => (
      { ...form,
        beneficiary_account_name:repeatTransferDetails?.beneficiary_name,
        }))};
  },[repeatTransferDetails, repeatTransaction ,transferForm?.beneficiary_bank_name])


  const fetchRates = useCallback(() => {
    dispatch(getLocalTransferRate({
      amount: removeCommas(transferForm.amount) || 1000,
      source_currency: "NGN"
    }));
  }, [transferForm.amount, dispatch]);

  const formNotComplete = (transferForm?.beneficiary_account_number?.toLowerCase().slice(0, 2) !== 'gf') ? (!transferForm.beneficiary_account_name || !transferForm.beneficiary_bank_name ||
    !transferForm.beneficiary_account_number || !transferForm.amount) :  (!transferForm.beneficiary_account_name ||
    !transferForm.beneficiary_account_number || !transferForm.amount);

  const enoughFunds = Number(parseFloat(removeCommas(transferForm?.amount))) + Number(parseFloat(transferCharge)) < parseFloat(availableBalance);

  function onSubmit() {
    setFormStage('review');
  }

  return (
    <STransferForm>


      <InputGroup
        label={repeatTransaction? "Account Number": "Account Number/Wallet ID"}
        placeholder={"Enter account number"}
        value={ transferForm?.beneficiary_account_number}
        // max={10}
        name={"beneficiary_account_number"}
        disabled={repeatTransaction ? true : false}
      />


      {(('beneficiary_account_number' in transferForm && transferForm?.beneficiary_account_number?.length === 10) && (transferForm?.beneficiary_account_number?.toLowerCase().slice(0, 2) !== 'gf')) && (!repeatTransaction) &&
        <InputDropdown
          label={"Select Bank"}
          items={banks}
          selected={transferForm?.beneficiary_bank_name}
          onSelect={onSelectProvider}
         
        >
        </InputDropdown>
      }
      <LinkText className='mt-2' onClick={() => setFormStage('beneficiaries')}>Select Beneficiary</LinkText>

      <InputGroup
        label={"Account Name"}
        placeholder={""}
        loading={isLoadingResolveDetails}
        disabled
        value={transferForm?.beneficiary_account_name}
        name={"beneficiary_account_name"}
      />

  {repeatTransaction && <InputGroup
        label={"Bank Name"}
        placeholder={""}
        disabled
        value={repeatTransferDetails?.bank_name}
        name={"beneficiary_account_name"}
      />}

      <SendInput
        icon
        currency={"NGN"}
        placeholder={"Send Amount"}
        availableBalance={availableBalance}
        onBlur={fetchRates}
        label={"Amount"}
      />

{repeatTransaction &&<InputGroup
        value={transferForm?.narration}
        label={"Narration"}
        name={"narration"}
        placeholder={"Optional"}
      />}

{!repeatTransaction &&<InputGroup
        label={"Description"}
        placeholder={"Optional"}
        value={transferForm?.narration}
        name={"narration"}
      />}

      <CheckboxInput
        id={"save_beneficiary"}
        name={"save_beneficiary"}
        checked={toSaveBeneficiary}
        onChange={onSaveBeneficiary}
        label={"Save as beneficiary"}
      />

      {(transferForm.amount && Number(transferForm.amount) !== 0) && <ChargeInfo transferCharge={transferCharge} />}
      <div className="flex justify-center">
        <Button.Blue
          title={"Confirm"}
          disabled={repeatTransaction ? false : formNotComplete || !enoughFunds}
          onClick={onSubmit}
          className={"mt-10"}
        />
      </div>
    </STransferForm>
  );
};

export default LocalNGNTransfer;

