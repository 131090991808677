import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/buttons/Button";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import InputGroup from "../../components/transfer/InputGroup";
import SendInput from "../../components/transfer/SendInput";
import { useDispatch } from "react-redux";
import { TransferFormContext } from "../../context/TransferFormContext";
import { getSuppliedFieldValue, removeCommas } from "../../helpers/helper";
import { LinkText, STransferForm } from "./TransferInternationalForm";
import {
  resolveAccountDetails,
  resolveAccountDetailsWithUserTag,
} from "../../redux/fund-transfer/fundTransferActions";
import useFundTransfer from "../../hooks/useFundTransfer";
import useUser from "../../hooks/useUser";
import TextInput from "../../components/inputs/TextInput";
import SenderFlag from "../../components/transfer/SenderFlag";

const GladeInternalTransfer = () => {
  const user = useUser();
  const {
    transferForm,
    availableBalance,
    setFormStage,
    setTransferForm,
    gladeTransferType,
    repeatTransaction,
    repeatTransferDetails
  } = useContext(TransferFormContext);
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const dispatch = useDispatch();
  const { isLoadingResolveDetails } = useFundTransfer();
  const [input, setInput] = useState({
    beneficiary_account_name: "",
    beneficiary_account_number: "",
    user_tag: "",
  });
  const toggleSaveBeneficiary = () => {
    setSaveBeneficiary((toSave) => !toSave);
    setTransferForm((form) => ({
      ...form,
      save_beneficiary: !saveBeneficiary,
    }));
  };

  const formNotComplete =
    (gladeTransferType === "usertag"
      ? !input?.user_tag
      : !input?.beneficiary_account_number) ||
    !transferForm?.beneficiary_account_name;
  const enoughFunds =
    Number(parseFloat(removeCommas(transferForm?.amount))) <
    parseFloat(availableBalance);
  const invalidAmount = !Number(parseFloat(removeCommas(transferForm?.amount)));
  const disabled = formNotComplete || !enoughFunds || invalidAmount;

  const onConfirm = () => {
    setFormStage("review");
  };

  const [userTagAvailability, setUserTagAvailability] = useState("");

  useEffect(() => {
    if (
      (String(input?.beneficiary_account_number).length === 10 &&
        getSuppliedFieldValue("currency", user) === "NGN") ||
      String(input?.beneficiary_account_number).length === 22
    ) {
      dispatch(
        resolveAccountDetails(
          {
            account_number: input?.beneficiary_account_number,
            mode: "internal",
          },
          (details) =>
            setTransferForm((form) => ({
              ...form,
              beneficiary_account_name: details.account_name,
              session_id: details?.session_id
            })),
          () =>
            setTransferForm((form) => ({
              ...form,
              beneficiary_account_name: "",
            }))
        )
      );
    } else if (
      input?.beneficiary_account_number?.toLowerCase().slice(0, 2) === "gf" &&
      input?.beneficiary_account_number?.length >= 12
    ) {
      dispatch(
        resolveAccountDetails(
          {
            account_number: input?.beneficiary_account_number,
            mode: "internal_wallet",
          },
          (details) =>
            setTransferForm((form) => ({
              ...form,
              beneficiary_account_name: details.account_name,
              session_id: details?.session_id
            })),
          () =>
            setTransferForm((form) => ({
              ...form,
              beneficiary_account_name: "",
            }))
        )
      );
    } else if (input?.user_tag?.length >= 6) {
      dispatch(
        resolveAccountDetailsWithUserTag(
          {
            user_tag: input?.user_tag,
          },
          (details) => {
          setUserTagAvailability("");
            setTransferForm((form) => ({
              ...form,
              beneficiary_account_name: details.account_name,
            }))
          },
            
         (error) =>{
          setUserTagAvailability(error?.data?.message);
          setTransferForm((form) => ({
              ...form,
             beneficiary_account_name: "",
           }))
          }
          )
      );
    } else {
      if (transferForm?.beneficiary_bank_code) {
        setInput({
          beneficiary_account_number: transferForm?.beneficiary_account_number,
        });
        setTransferForm((form) => ({ ...form, beneficiary_bank_code: "" }));
      } else {
        setTransferForm((form) => ({ ...form, beneficiary_account_name: "" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    input?.beneficiary_account_number,
    transferForm?.beneficiary_account_number,
    input?.user_tag,
  ]);
  useEffect(()=>{
    if(transferForm?.user_tag) {
      setTransferForm((form) => ({ ...form, user_tag: "" }))
      onChange({target: {name: "user_tag", value: transferForm?.user_tag}})
    } 
  }, [transferForm?.user_tag])
  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    gladeTransferType === "usertag"
      ? setTransferForm((form) => ({ ...form, user_tag: e.target.value }))
      : setTransferForm((form) => ({
          ...form,
          beneficiary_account_number: e.target.value,
        }));
  };

  useEffect(() => {
    if (repeatTransaction && repeatTransferDetails ) {
    setTransferForm(form => (
      { ...form,
        beneficiary_account_name:repeatTransferDetails?.beneficiary_name,
        beneficiary_account_number:repeatTransferDetails?.beneficiary_account,

        }))};
  },[repeatTransferDetails, repeatTransaction])


  return (
    <STransferForm>
      <section className="page-title mb-9 items-center flex flex-col">
        <h2 className='text-3xl font-bold'>   {repeatTransaction ? "Repeat Transaction" :"Glade Account Transfer"}</h2>
      </section>

      {gladeTransferType === "usertag" ? (
        <>
          <p className="font-bold">User Tag</p>
          <TextInput
            // label={'Account Number'}
            id={"user_tag"}
            name={"user_tag"}
            type={"text"}
            value={input?.user_tag}
            onChange={onChange}
            placeholder={"@UserTag"}
            autoComplete={"user_tag"}
            className={"appearance-none"}
          />

          {userTagAvailability && input?.user_tag?.length >= 6 && (
            <p className="pt-2 glade-form-fail">{userTagAvailability}</p>
          )}
        </>
      ) : (
        <>
          <SenderFlag />

            <p className='font-bold'>   {repeatTransaction ? "Account Number" :"Account Number/Wallet ID"}</p>
            <TextInput
              // label={'Account Number'}
              id={'beneficiary_account_number'}
              name={'beneficiary_account_number'}
              type={getSuppliedFieldValue('currency', user) === 'NGN' ? 'text' : 'text'}
              value={ input?.beneficiary_account_number}
              onChange={onChange}
              placeholder={'Enter Account Number'}
              autoComplete={'beneficiary_account_number'}
              className={'appearance-none'}
            />
          </>
        )
      }

{!repeatTransaction && <LinkText
        className='mt-2 mb-4'
        onClick={() => setFormStage('beneficiaries')}>
        Select Beneficiary
      </LinkText>}

      <InputGroup
        label={"Account Name"}
        placeholder={""}
        disabled
        loading={isLoadingResolveDetails}
        value={ transferForm?.beneficiary_account_name}
        name={"beneficiary_account_name"}
      />

{repeatTransaction && <InputGroup
        label={"Bank Name"}
        placeholder={""}
        disabled
        value={repeatTransferDetails?.bank_name}
        name={"beneficiary_account_name"}
      />}

      <SendInput icon label={'Amount'} />

      <InputGroup
        value={transferForm?.narration}
        label={"Narration"}
        name={"narration"}
        placeholder={"Optional"}
      />

      <CheckboxInput
        label={"Save as beneficiary"}
        checked={saveBeneficiary}
        onChange={toggleSaveBeneficiary}
      />

      <Button.Blue
        disabled={repeatTransaction? false : disabled}
        onClick={onConfirm}
        title={"Confirm"}
        className={"mt-6"}
      />
    </STransferForm>
  );
};

export default GladeInternalTransfer;
