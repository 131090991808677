import { useSelector } from "react-redux";

const useRecurring = () => {
  const recurring = useSelector((state) => state.recurring);

  let {
    isLoadingRecurringList,
    isLoadingRecurringEdit,
    isLoadingCreateRecurring,
    recurringList,
  } = recurring;

  return {
    isLoadingRecurringList,
    isLoadingRecurringEdit,
    isLoadingCreateRecurring,
    recurringList,
  };
};

export default useRecurring;
