import React, { useState } from "react";
import Button from "../../buttons/Button";
import TextAreaInput from "../../inputs/TextAreaInput";

const AddCreateWalletCurrencyReason = ({ onAddReason, incomingInput }) => {
  const [input, setInput] = useState({
    ...incomingInput,
  });
  const [reasonError, setReasonError] = useState(false);
  const handleClick = (data) => {
    if (!data.reason?.length) {
      setReasonError(true);
    } else {
      onAddReason(data.reason);
    }
  };
  return (
    <div className="w-10/12 sm:w-8/12 lg:w-4/12 flex flex-col items-center px-8 mt-6">
      <div className="text-center">
        <h3 className="glade-heading-three glade-home-dark">
          Why do you want to add this currency?
        </h3>
        <p className="text-sm glade-gray-dark">
          Please provide information on why you want to add this currency
        </p>
      </div>
      <div className="mt-5 w-full">
        <TextAreaInput
          id={"reason"}
          name={"reason"}
          type={"text"}
          value={input?.reason}
          row={"6"}
          onChange={(e) => {
            setInput({ ...input, reason: e.target.value });
            setReasonError(false);
          }}
          placeholder={"Enter reason"}
          autoComplete={"reason"}
          className={"appearance-none"}
          errorState={reasonError}
        />
        {reasonError && (
          <p className="text-red-500 text-sm">Reason is required</p>
        )}
      </div>
      <div className="w-full">
        <Button.Blue
          title={"Next"}
          type={"button"}
          className={"py-3 mt-10"}
          disabled={!input.reason}
          onClick={() => handleClick(input)}
        />
      </div>
    </div>
  );
};

export default AddCreateWalletCurrencyReason;
