import React, { useRef, useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { BsPlusCircleFill } from 'react-icons/bs';
import useUser from "../../hooks/useUser";
import { toggleAccountAction } from "../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AddNewBusinessModal from "../../pages/home/AddNewBusinessModal";
import { getOneBusinessDetails } from "../../redux/home/homeActions";
import { getAllWalletBalances } from "../../redux/balances/balancesActions";

const AccountSelection = ({ restoreCurrencyIndexToDefault }) => {
  const [showAccounts, setShowAccounts] = useState(false);
  const user = useUser();
  const AddNewBusinessModalRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [openNewBusiness, setOpenNewBusiness] = useState(false);


  const getBusinessNameOrPersonalName = () => {
    let name = '';
    if (user && user.selectedBusinessOrPersonalAccount?.is_business) {
      name = user?.selectedBusinessOrPersonalAccount?.business_name;
      return name;
    } else {
      name = `${user?.userInfo?.first_name} ${user?.userInfo?.last_name}`
      return name;
    }
  };
  return (
    <>
      {openNewBusiness ? <AddNewBusinessModal ref={AddNewBusinessModalRef} setOpenNewBusiness={setOpenNewBusiness} /> :
        <div className={`flex w-64 flex-col rounded-xl mb-2 glade-bg-grey py-2 space-y-1 relative ${!showAccounts ? 'h-20' : 'h-20'}`}>
          <div className="flex justify-between pl-2 pr-2 pb-0 pt-2 flex-row cursor-pointer" onClick={() => setShowAccounts(!showAccounts)}>
            <h3 className="glade-heading-four glade-black">{getBusinessNameOrPersonalName(user)}</h3>
            <span>
              {!showAccounts ? <RiArrowDropDownLine size={'28px'} color="#000000" /> : <RiArrowDropUpLine size={'28px'} color="#000000" />}
            </span>
          </div>
          <p className="glade-black pl-2 pr-2">{user?.selectedBusinessOrPersonalAccount?.is_business ? 'Business Account' : 'Personal Account'}</p>
          {showAccounts &&
            <div className="p-2 w-full absolute top-16 overflow-auto max-h-60 flex flex-col justify-between" style={{ background: '#DDE1EF', borderRadius: '0 0 16px 16px' }}>
              {/* Personal Account */}
              {user?.selectedBusinessOrPersonalAccount?.is_business && <div className="flex flex-col gap-0 cursor-pointer border-b pt-2 pb-2" onClick={() => dispatch(toggleAccountAction({ ...user?.personalAccounts[0], is_business: false }, () => history.push("/dashboard/home"), setShowAccounts(false))) & restoreCurrencyIndexToDefault()}>
                <span className="glade-grey-dark font-bold">{`${user?.userInfo?.first_name} ${user?.userInfo?.last_name}`}</span>
                <span className="glade-grey-dark glade-normal-three">Personal Account</span>
              </div>}
              {/* Business Account */}
              {user?.businessAccounts?.length > 0 ?
                user?.businessAccounts?.map((account, index) =>
                  <div key={index} className="flex flex-col gap-0 cursor-pointer pt-2 border-b pb-2" onClick={() => restoreCurrencyIndexToDefault() & dispatch(getOneBusinessDetails(), dispatch(getAllWalletBalances())) & dispatch(toggleAccountAction({ ...account, is_business: true }, () => history.push("/dashboard/home"), setShowAccounts(false))) & restoreCurrencyIndexToDefault()}>
                    <span className="glade-grey-dark font-bold">{account?.business_name}</span>
                    <span className="glade-grey-dark glade-normal-three">Business Account</span>
                  </div>
                )?.reverse()
                :
                <div className="glade-black"></div>}
              {user.hasCompliance &&
                <div className="flex items-center gap-2 rounded-xl mt-2 cursor-pointer p-2 border-lg" style={{ background: '#E8EBF6' }}>
                  <BsPlusCircleFill color="#1634A1" />
                  <span className="glade-home-dark"
                    onClick={() =>
                      // AddNewBusinessModalRef?.current?.open()
                      setOpenNewBusiness(true)
                      & setShowAccounts(false)}>Open a business account</span>
                </div>
              }

            </div>
          }

        </div>
      }
    </>

  );
};

export default AccountSelection;