import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { CardTableHeaderPlain } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import useSettlements from "../../hooks/useSettlements";
import { getSettlementTransactions } from "../../redux/settlements/settlementActions";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { simpleDate, isArray, isEmpty, pagination } from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import { IoIosArrowForward } from "react-icons/io";
import useTeam from "../../hooks/useTeam";

export default function SettlementDetail() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const settlement = useSettlements();
  const { permissions } = useTeam();

  React.useEffect(() => {
    if (location?.state?.reference) {
      dispatch(getSettlementTransactions({ id: location.state.reference }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(
      pagination(
        settlement.settlementTransactions?.transactions ?? [],
        10,
        currentPage
      )
    );
    // eslint-disable-next-line
  }, [currentPage, settlement.settlementTransactions?.transactions]);

  const renderTransactionsTable = (data) => {
    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              {simpleDate(item?.created_at, "numeric", "short")}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              {item?.user_name}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              <CopyTextButton text={item?.txn_ref} />
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one whitespace-nowrap">
              {item?.currency} {Number(item?.fee)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one whitespace-nowrap">
              {item?.currency} {Number(item?.value)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one glade-red whitespace-nowrap">
              {item?.deductions
                ? `${item?.currency} ${Number(
                    item?.deductions
                  )?.toLocaleString()}`
                : null}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderTransactionsDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() =>
            history.push({
              pathname: !permissions?.includes("settlements")
                ? "/dashboard/settlements/settlement-detail/transaction-detail"
                : "/teams/settlements/settlement-detail/transaction-detail",
              state: item,
            })
          }
        >
          <div className="flex flex-row">
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  NGN {Number(item?.value)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.created_at, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs
            title={"Settlements"}
            path={
              !permissions?.includes("settlements")
                ? "/dashboard/settlements"
                : "/teams/settlements"
            }
          />
        </div>

        <div className="flex flex-col bg-white rounded-sm p-3 my-6">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">
              Settlement Details
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-3 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Settlement Date
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {simpleDate(location.state.txn_date)}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                NGN {Number(location.state.total_amount)?.toLocaleString()}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Settlement Summary
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {settlement.settlementTransactions?.transactions?.length ?? 0}{" "}
                Transaction(s)
              </h6>
            </div>
          </div>
        </div>

        <div className="">
          <CardTableHeaderPlain
            title={`Transactions - ${tableData.totalItems}`}
          />

          {isEmpty(tableData.data) || settlement.isLoadingTransactions ? (
            <React.Fragment>
              <div className="hidden md:block">
                <EmptyTable
                  column={[
                    "",
                    "Date",
                    "Customer",
                    "Transaction Ref",
                    "Fees",
                    "Amount",
                    "Deductions",
                    "",
                  ]}
                  loading={settlement.isLoadingTransactions}
                />
              </div>

              <div className="block md:hidden">
                <EmptyDesk
                  row={[1, 2]}
                  loading={settlement.isLoadingTransactions}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="hidden md:block">
                <CardTableBody
                  column={[
                    "",
                    "Date",
                    "Customer",
                    "Transaction Ref",
                    "Fees",
                    "Amount",
                    "Deductions",
                    "",
                  ]}
                >
                  {isArray(tableData.data) &&
                    renderTransactionsTable(tableData.data)}
                </CardTableBody>
              </div>

              <div className="block md:hidden">
                <div className="bg-white px-6">
                  {isArray(tableData.data) &&
                    renderTransactionsDesk(tableData.data)}
                </div>
              </div>
            </React.Fragment>
          )}

          <CardTableFooter
            empty={isEmpty(tableData.data)}
            pageNumber={currentPage}
            totalPages={tableData.totalPages}
            prevOnClick={() => setCurrentPage(tableData.previousPage)}
            nextOnClick={() => setCurrentPage(tableData.nextPage)}
            thereIsPreviousPage={tableData.thereIsPreviousPage}
            thereIsNextPage={tableData.thereIsNextPage}
          />
        </div>
      </div>
    </div>
  );
}
