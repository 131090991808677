import React, { useEffect, useState } from "react";
import TextInput from "../../components/inputs/TextInput";
import nigeria_flag from "../../assets/images/nigeria_flag_rectangle.png";
import usdollar_flag from "../../assets/images/usdollar_flag_rectangle.png";
import british_flag from "../../assets/images/british_flag_rectangle.png";
import euro_flag from "../../assets/images/euro_flag_rectangle.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import useUser from "../../hooks/useUser";
import Button from "../../components/buttons/Button";

const CountrySelectionField = ({
  onSelectionChange,
  defaultValue,
  handleClick,
  displayButton,
  title,
  placeholder,
  optionsList,
}) => {
  const user = useUser();
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [countriesArray, setCountriesArray] = useState([]);
  const [defaultCurrencyArray, setDefaultCurrencyArray] = useState([]);
  const [input, setInput] = useState({
    country: defaultValue,
    filter: "",
  });
  useEffect(() => {
    setCountriesArray(optionsList);
    setDefaultCurrencyArray(optionsList);
  }, [optionsList]);
  const getSelectedFlag = () => {
    if (input?.country === "NGN") {
      return nigeria_flag;
    } else if (input?.country === "GBP") {
      return british_flag;
    } else if (input?.country === "EUR") {
      return euro_flag;
    }
  };
  const getSelectedCurrencyTitle = () => {
    if (input?.country === "NGN") {
      return "Naira";
    } else if (input?.country === "GBP") {
      return "British Pounds";
    } else if (input?.country === "EUR") {
      return "Euro";
    }
  };
  const getFlag = (field) => {
    if (field === "NGN") {
      return nigeria_flag;
    } else if (field === "GBP") {
      return british_flag;
    } else if (field === "EUR") {
      return euro_flag;
    }
  };
  const getCurrencyTitle = (field) => {
    if (field === "NGN") {
      return "Naira";
    } else if (field === "GBP") {
      return "British Pounds";
    } else if (field === "EUR") {
      return "Euro";
    }
  };
  return (
    <div className="w-full ">
      {!searchDropDown ? (
        <button
          className="flex items-center rounded p-2 border justify-between relative w-full"
          onClick={() => setSearchDropDown(!searchDropDown)}
        >
          {!input?.country ? (
            <div className="glade-grey-dark">{title}</div>
          ) : (
            <div className="flex gap-3 glade-grey-dark">
              <img src={getSelectedFlag()} /> {input?.country}{" "}
            </div>
          )}
          <RiArrowDropDownLine
            size={"28px"}
            className="cursor-pointer"
            color="#000000"
          />
        </button>
      ) : (
        <div>
          <div className="items-center relative">
            <TextInput
              value={input?.filter}
              placeholder={placeholder}
              onChange={(e) => {
                setInput({ ...input, filter: e.target.value });
                let filteredArray = [];
                if (e.target.value) {
                  countriesArray.forEach((filter, i) => {
                    if (
                      e.target.value &&
                      filter?.country_name
                        ?.toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    ) {
                      filteredArray.push(countriesArray[i]);
                    } else if (
                      e.target.value &&
                      !filter?.country_name
                        ?.toUpperCase()
                        .indexOf(e.target.value.toUpperCase() < 0) > -1
                    ) {
                      // return setCountriesArray([]);
                    }
                    setCountriesArray(filteredArray);
                  });
                } else {
                  return setCountriesArray(defaultCurrencyArray);
                }
              }}
            />
            <IoIosClose
              size={30}
              className="top-1 right-2 absolute"
              onClick={() => {
                setInput({ ...input, filter: "" });
                setCountriesArray(defaultCurrencyArray);
                setSearchDropDown(!searchDropDown);
              }}
            />
          </div>
          <div>
            <div
              className="flex flex-col gap-2 p-2 top-2 bg-white glade-grey-dark border relative overflow-auto"
              style={{ height: "100px" }}
            >
              {countriesArray.map((field, index) => (
                <div
                  className="flex gap-3 cursor-pointer"
                  key={index}
                  onClick={() => {
                    setInput({ ...input, country: field?.country_name });
                    onSelectionChange(field);
                    setSearchDropDown(!searchDropDown);
                  }}
                >
                  {getFlag(field) && <img src={getFlag(field)} />}
                  {field?.country_name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div>
        {input.country && displayButton && (
          <Button.Blue
            title={"Confirm"}
            type={"button"}
            className={"py-3 mt-10"}
            disabled={!input.country}
            onClick={() => handleClick(input.country)}
          />
        )}
      </div>
    </div>
  );
};

export default CountrySelectionField;
