import React from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import {verifyUserPin} from "../../redux/auth/authActions";
import useUser from "../../hooks/useUser";
import IndividualInput from '../../components/inputs/IndividualInput';
import Button from '../../components/buttons/Button';

export default React.forwardRef(function ConfirmPinModal({onSuccess=()=>{}, onFailure=()=>{}},ref) {

    const dispatch = useDispatch();
    const user = useUser();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    const [input, setInput] = React.useState({
        pin: '',
    });

    const onSubmit = () => {
        dispatch(verifyUserPin({pin:input.pin},()=> { onSuccess(input.pin)},onFailure));
    };

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Transfer Confirmation'}
            modelContentSection={
                <div className="mt-6 p-6">
                    <div className="flex justify-center mb-6">
                        <span className="glade-small-text-one glade-grey-cool">Enter pin to proceed</span>
                    </div>
                    <div className="flex justify-center mb-12">
                        <IndividualInput 
                            numberOfInputTags={4}
                            label={''}
                            onChange={(value)=>setInput({...input,pin:value})} 
                        />
                    </div>
                    <div className='flex justify-center px-12'>
                        <Button.Blue 
                            title={'Submit Pin'}
                            type={'button'}
                            className={'py-3'}
                            onClick={onSubmit}
                            disabled={user.isLoadingVerifyPin}
                            loading={user.isLoadingVerifyPin}
                        />
                    </div>
                </div>
            }
        />
    )
})
