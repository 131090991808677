import React from "react";
import { Dialog, Transition } from "@headlessui/react";

export default React.forwardRef(function BasicModal({
  visibility = false,
  primaryButton,
  primaryButtonStyle,
  primaryButtonClassName,
  primaryButtonTitle,
  secondaryButton,
  secondaryButtonStyle,
  secondaryButtonClassName,
  secondaryButtonTitle,
  modalTitle,
  modalTitleSection,
  modelContent,
  modelContentSection,
  disableClose = false,
  disableOpen = false,
  onOpen=()=>{},
  onClose=()=>{}
},ref) {

  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    toggle: () => toggleModal(),
    // anotherMethodOfChoice : () => {}
  }));

  let [isOpen, setIsOpen] = React.useState(visibility);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function toggleModal() {
    if (isOpen) {
      !disableClose && setIsOpen(false);
      !disableClose && onClose();
    }

    if (!isOpen) {
      !disableOpen && setIsOpen(true);
      !disableOpen && onOpen();
    }
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ?
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        :
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={"glade-normal-text-three glade-button-blue w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-md "+primaryButtonClassName}>
            {primaryButtonTitle ?? "Title"}
          </button>
        }
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                {modalTitleSection ?? (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {modalTitle ?? "Modal Title"}
                  </Dialog.Title>
                )}

                {modelContentSection ?? (
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">
                      <Dialog.Description>
                        {modelContent ?? "Modal Content"}
                      </Dialog.Description>
                    </div>
                  </div>
                )}

                {secondaryButton ?? (
                  <div className="mt-4">
                    <button
                      type="button"
                      style={secondaryButtonStyle}
                      onClick={closeModal}
                      className={
                        "glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
                        secondaryButtonClassName
                      }
                    >
                      {secondaryButtonTitle ?? "Got it, thanks!"}
                    </button>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
