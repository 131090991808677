import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";

const InputDropdown = ({
  selected,
  label,
  items = [],
  onSelect,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const onSearchInput = e => setSearchInput(e.target.value);

  const toggleDropdown = () => {
    setIsOpen(open => !open);
    setSearchInput('');
  };

  const closeDropdown = () => setIsOpen(false);

  const dropdownVariants = {
    open: {
      opacity: 1,
      height: "auto"
    },
    closed: {
      opacity: 0,
      height: 0,
    }
  };

  return (
    <Div className="mb-10">
      {label && <label className="font-bold mb-4">{label}</label>}
      <DropdownContainer>
        <DropdownHeader onClick={toggleDropdown}>
          {selected || <span className="unselected">Select bank</span>}
          <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
        </DropdownHeader>
        <AnimatePresence>
          {isOpen && (
            <Container
              animate="open"
              initial="closed"
              exit="closed"
              variants={dropdownVariants}
            >
              <SearchBox>
                <input
                  autoFocus
                  type="text"
                  className="focus:outline-0"
                  placeholder="Search for a bank"
                  onChange={onSearchInput}
                  value={searchInput}
                />
              </SearchBox>
              <ListContainer>
                {items.filter(item =>
                  item.name.toLowerCase()
                    .includes(searchInput.toLowerCase()))
                  .map((item, key) =>
                    <ListItem onClick={() => onSelect(item, closeDropdown)} key={key}>
                      {item.name}
                    </ListItem>)
                }
              </ListContainer>
            </Container>
          )}
        </AnimatePresence>
      </DropdownContainer>
      {children}
    </Div>
  );
};

export default InputDropdown;

const Div = styled.div`  
`;

const Container = styled(motion.div)`
  // width: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 100;
`;

const DropdownContainer = styled.div`
  width: 90%;
  margin-top: .5rem;
  position: relative;
`;

const SearchBox = styled.div`
  // outline: 2px solid #1634A1;
  // width: 100%;
  input {
    border-radius: 5px;
    border: none;
    outline: 2px solid gainsboro;
    width: 445px;
    margin: 0;

    &:focus {
      border: none;
      outline-color: #1634A1;
    }

    &::placeholder {
      color: gainsboro;
    }
  }
`;

const DropdownHeader = styled.div`
  margin-bottom: .8rem;
  padding: 1rem;
  // box-shadow: 0 2px 3px rgba(0, 0, 0, .15);
  color: #333;
  height: 50px;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 45px);
  // width: 100%;
  align-items: center;
  border: 1px solid #E4E4F3;
  border-radius: 5px;
  gap: .5rem;
  cursor: pointer;
  background: #fff;

  .unselected {
    color: #9CA3AF;
  }
`;

const ListContainer = styled.ul`
  margin-top: 2rem;
  padding-top: .5rem;
  position: absolute;
  top: 41%;
  width: 445px;
  left: 0;
  right: 0;
  min-height: auto;
  max-height: 150px;
  background: #fff;
  border: 1px solid gainsboro;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(3, 32, 134, 0.1);
  overflow-y: scroll;
  &:first-child {
      padding-top: .8rem;
    }
  `;

const ListItem = styled.li`
  // outline: 2px solid blue;
  list-style: none;
  padding: .5rem .8rem;
  display: flex;
  align-itens: center;
  gap: .5rem;
  margin-bottom: .8rem;
  cursor: pointer;
  transition: 200ms ease-in color;

  &:hover {
    color: #1634A1;
    background: whitesmoke;
  }
`;