import React from 'react';
import { RiLoader5Fill } from "react-icons/ri";

export function CardPlainWithButtonSection({
    children,
    type='single',
    title,
    titleSection,
    className='',
    loading,
    buttonStyle, 
    buttonClassName, 
    buttonDisabled, 
    buttonTitle, 
    buttonOnClick=()=>{}, 
    buttonSection,
}) {
    return (
        <div className={type==='single'? "md:col-start-3 md:col-span-4 flex flex-col "+className:type==='dual'? "flex flex-col "+className:null}>
            <div className="bg-white py-4 overflow-hidden rounded-sm shadow-sm">
                {titleSection??
                <div className="flex justify-center py-2 border-b-2  border-solid border-gray-200">
                    <h4 className="glade-subtitle-one glade-black">{title??'Title'}</h4>
                </div>}
                <div className="px-6 my-3">
                    {children}
                </div>
            </div>
            <div className="flex justify-center">
                {buttonSection??
                <div className="mt-6">
                    <button
                        style={buttonStyle}
                        onClick={buttonOnClick}
                        disabled={loading || buttonDisabled}
                        className={"glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm mb-4 "+buttonClassName}>
                        {buttonTitle ?? "Title"}
                        {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
                    </button>
                </div>}
            </div>
        </div>
    )
}

export function CardPlainWithButton({children, type='single'}) {
    return (
        <div className={type==='single'?"grid grid-cols-1 md:grid-cols-8 gap-1 md:gap-4":type==='dual'?"grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4":null}>
            {children}
        </div>
    )
}