import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { CgClose } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import Button from '../../components/buttons/Button';
import IndividualInput from '../../components/inputs/IndividualInput';
import useUser from '../../hooks/useUser';
import { verifyUserPin } from '../../redux/auth/authActions';
import { useContext } from "react";
import { TransferFormContext } from "../../context/TransferFormContext";


const ConfirmPinModal = forwardRef(({ onCorrectPin, title="Transfer Confirmation", body="Enter transfer pin to proceed"}, ref) => {
 
  const {
    repeatTransaction,
    setRepeatTransaction,
    setRepeatTransferDetails
  } = useContext(TransferFormContext);
  const dispatch = useDispatch();
  const user = useUser();

  const closeModal = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);


  const [input, setInput] = useState({ 
    pin: '' ,
     
  });
  useImperativeHandle(ref, () => ({
    openModal: () => setShowModal(true),
    closeModal: () => setShowModal(false)
  }));

  
  function onSubmit() {
    dispatch(verifyUserPin({pin:input.pin}, onSuccess, onFailure));

    setInput({ ...input, pin: '' });
  }

  function onSuccess() {
    if(repeatTransaction){
      setInput({ ...input, pin: '' });
      onCorrectPin(input.pin);
      setRepeatTransaction(false)
      setRepeatTransferDetails({})
    }else{
    setInput({ ...input, pin: '' });
    onCorrectPin(input.pin);
  }
  }

  function onFailure() {
   
    setInput({ ...input, pin: '' });
  
  }

  if (!showModal) return null;

  return (
    <React.Fragment>
      <div className='toastr-container  flex justify-center items-center'>
        <div className='inline-block w-full max-w-lg pb-3 pt-5 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-md'>
          <div className="flex justify-center items-center px-6 pb-3 border-b-2 border-solid border-gray-100">
            <div className="glade-subtitle-two font-bold glade-black text-left">
              <span className='glade-heading-two'>{title}</span>
            </div>
            <button className="glade-black absolute right-10" onClick={closeModal}>
              <CgClose />
            </button>
          </div>
          <div className="p-6">
            <div className="flex justify-center mb-6">
              <span className="glade-small-text-one glade-grey-cool">{body}</span>
            </div>
            <div className="flex justify-center mb-12">
              <IndividualInput
                // type={"text"}
                numberOfInputTags={4}
                label={''}
                onChange={(value) => setInput({ ...input, pin: value })}

              />
            </div>
            <div className='flex justify-center px-12'>
              <Button.Blue
                title={'Submit Pin'}
                type={'button'}
                className={'py-3'}
                onClick={onSubmit}
                // onClick={() => setFormStage('confirmation')}
                disabled={user.isLoadingVerifyPin || !input?.pin}

                loading={user.isLoadingVerifyPin}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default ConfirmPinModal;