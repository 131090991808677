import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";
import useAirtime from "../../hooks/useAirtime";
import useUser from "../../hooks/useUser";
import {
  getAllBillTypes,
  buyAirtime,
} from "../../redux/airtime/airtimeActions";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import NumericInput from "../../components/inputs/NumericInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import SelectBeneficiaryModal from "./SelectBeneficiaryModal";
import ConfirmPinModal from "./ConfirmPinModal";
import { buyAirtimeValidation } from "../../validation/airtime/airtimeValidation";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import { useLocation } from "react-router-dom";
import { repeatTransfer } from '../../redux/fund-transfer/fundTransferActions';
import useTeam from "../../hooks/useTeam";


export default function NewAirtime() {
  const SelectBeneficiaryModalRef = React.useRef();
  const ConfirmPinModalRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const airtime = useAirtime();
  const user = useUser();
  const {permissions} = useTeam();

  // Check if the URL contains "/repeat"
  const isRepeat = location?.pathname?.includes("/repeat");

  useEffect(() => {
    dispatch(getAllBillTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState(false);
  const [billerItems, setBillerItems] = useState([]);
  const [billers, setBillers] = useState([]);

  const [input, setInput] = useState({
    category_id: "",
    category_code: "",
    biller_id: "",
    biller_reference: "",
    biller_item_paycode: "",
    biller_item_keycode: "",
    biller_item_base_amount: 0,
    amount: 0,
    save_beneficiary: false,
    show_save_beneficiary: true,
    phone_number: "",
    currentBalance: user.selectedBusinessOrPersonalAccount.balance,
    txn_ref: location?.state?.txn_ref,
  });

  const onSubmitWithPin = () => {
    if (buyAirtimeValidation(input)) {
      ConfirmPinModalRef.current.open();
    }
  };

  const onSubmit = (pin_code) => {
    if (input.category_code === "airtime") {
      if (isRepeat === true) {
        dispatch(
          repeatTransfer(
            { txn_ref: location?.state?.txn_ref, passcode: pin_code },
            () => {
              history.goBack();
              dispatch(getPersonalAndBusinessAccounts());
            }
          )
        );
      } else {
        dispatch(
          buyAirtime(
            {
              paycode: input.biller_item_paycode,
              reference: input.phone_number,
              amount: input.amount.split(",").join(""),
              save_beneficiary: input.save_beneficiary,
            },
            () => {
              history.goBack();
              dispatch(getPersonalAndBusinessAccounts());
            }
          )
        );
      }
    }
    ConfirmPinModalRef.current.close();
  };

  const onChange = (e) => {
    if (e.target.name === "amount") {
      setInput({
        ...input,
        [e.target.name]: Number(e.target.value).toLocaleString("en-US"),
      });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (isRepeat && location.state) {
      setInput({
        ...input,
        category_id: location?.state?.category_id,
        category_code: location?.state?.category,
       
      });

      let billers = airtime?.billTypes?.bills?.filter(
        (item) => item.category_code === location?.state?.category
      );
      setBillers(billers ?? []);
      setBillerItems([]);
    }
  }, [isRepeat, location.state]);

  useEffect(() => {
    if (isRepeat && location.state && input.category_id ) {
      // onSelectOfBiller({target: {value:`${location?.state?.bill_id}-${location?.state?.bill_reference}`}, });
      setInput({
        ...input,
        biller_id: location?.state?.bill_id,
        biller_reference: "Phone Number",
       
      });

      let billerItems = airtime?.billTypes?.items?.filter(
        (item) =>
          item.bill_id.toString() === location?.state?.bill_id.toString()
      );
      setBillerItems(billerItems ?? []);
    }
  }, [isRepeat, location.state, input.category_id, billers ]);

  useEffect(() => {
    if (isRepeat && location.state && billerItems && input.biller_id) {
      let billItem = billerItems.find(
        (item) =>
          item?.bill_id.toString() === location?.state?.bill_id.toString()
      );

      setInput({
        ...input,
        biller_item_paycode: billItem?.paycode,
        biller_item_keycode: billItem?.keycode,
        biller_item_base_amount: parseInt(billItem?.base_amount),
        phone_number: location?.state?.bill_reference,
        amount: Number(location?.state?.amount_charged).toLocaleString("en-US"),
      });
    }
  }, [isRepeat, location.state, billerItems, input.biller_id]);

  const onSelectOfBillerItem = (e) => {
    let paycode = e.target.value.split("-")[0];
    let keycode = e.target.value.split("-")[1];
    let data = { paycode, keycode };
    setInput({
      ...input,
      biller_item_paycode: data.paycode,
      biller_item_keycode: data.keycode,
      biller_item_base_amount: parseInt(data.base_amount ?? 0),
      amount: parseInt(data.base_amount ?? 0),
    });
  };

  const onSelectOfBiller = (e) => {
    let id = e.target.value.split("-")[0];
    let reference = e.target.value.split("-")[1];
    let data = { id, reference };
    setInput({
      ...input,
      biller_id: data.id,
      biller_reference: data.reference,
      biller_item_paycode: "",
      amount: 0,
      biller_item_base_amount: 0,
    });

    let billerItems = airtime?.billTypes?.items?.filter(
      (item) => item.bill_id.toString() === data.id.toString()
    );
    setBillerItems(billerItems ?? []);
  };

  const onSelectOfCategory = (e) => {
    let id = e.target.value.split("-")[0];
    let category_code = e.target.value.split("-")[1];
    let data = { id, category_code };
    setInput({
      ...input,
      category_id: data.id,
      category_code: data.category_code,
      biller_id: "",
      biller_item_paycode: "",
      amount: 0,
      biller_item_base_amount: 0,
    });

    let billers = airtime?.billTypes?.bills?.filter(
      (item) => item.category_id.toString() === data.id.toString()
    );
    setBillers(billers ?? []);
    setBillerItems([]);
  };

  const onSelectOfBeneficiary = (value) => {
    let beneficiaryCategory = airtime?.billTypes?.categories?.filter(
      (item) => item.id.toString() === value.category_id.toString()
    );
    let beneficiaryBiller = airtime?.billTypes?.bills?.filter(
      (item) => item.id.toString() === value.bill_id.toString()
    );
    let beneficiaryBillerItem = airtime?.billTypes?.items?.filter(
      (item) => item.bill_id.toString() === value.bill_item_id.toString()
    );

    setBillers(beneficiaryBiller ?? []);
    setBillerItems(beneficiaryBillerItem ?? []);
    setInput({
      ...input,
      category_id: value.category_id,
      category_code: beneficiaryCategory[0].category_code,
      biller_id: value.bill_id,
      biller_reference: beneficiaryBiller[0].reference,
      biller_item_paycode: beneficiaryBillerItem[0].paycode,
      biller_item_keycode: beneficiaryBillerItem[0].keycode,
      biller_item_base_amount: parseInt(beneficiaryBillerItem[0].base_amount),
      amount: parseInt(beneficiaryBillerItem[0].base_amount),
      save_beneficiary: false,
      show_save_beneficiary: false,
      phone_number: value.reference,
    });
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Airtime"} path={!permissions?.includes("airtime") ? "/dashboard/airtime" : "/teams/airtime"} />
        </div>

        {showBeneficiaryModal && (
          <SelectBeneficiaryModal
            ref={SelectBeneficiaryModalRef}
            showBeneficiaryModal={showBeneficiaryModal}
            setShowBeneficiaryModal={() => setShowBeneficiaryModal(false)}
            beneficiary={""}
            onSelectOfBeneficiary={onSelectOfBeneficiary}
          />
        )}

        <CardPlainWithButton type={"single"}>
          <CardPlainWithButtonSection
            type={"single"}
            title={"Buy Airtime"}
            loading={airtime?.isLoading}
            buttonTitle={"Buy Airtime"}
            buttonOnClick={onSubmitWithPin}
            buttonDisabled={
              airtime.isLoading ||
              !user.hasCompliance ||
              !input.category_code ||
              !buyAirtimeValidation(input, true)
            }
          >
            <div className="flex flex-col space-y-6">
              <SelectInput
                label={
                  <div className="flex flex-row justify-between">
                    <span>Select Category</span>
                    <span
                      onClick={() => {
                        setShowBeneficiaryModal(true);
                        SelectBeneficiaryModalRef?.current?.open();
                      }}
                      className="glade-blue cursor-pointer"
                    >
                      Select Beneficiary
                    </span>
                  </div>
                }
                id={"category"}
                name={"category"}
                value={`${input.category_id}-${input.category_code}`}
                onChange={onSelectOfCategory}
                autoComplete={"category"}
                disabled={isRepeat ? true : false}
                className={"appearance-none"}
              >
                <option value={""}>Select A Category</option>
                {airtime?.billTypes?.categories?.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={`${item.id}-${item.category_code}`}
                    >
                      {item.category_title}
                    </option>
                  );
                })}
              </SelectInput>

              <SelectInput
                label={"Select Biller"}
                id={"biller"}
                name={"biller"}
                value={`${input.biller_id}-${input.biller_reference}`}
                onChange={onSelectOfBiller}
                autoComplete={"biller"}
                disabled={isRepeat ? true : false}
                className={"appearance-none"}
              >
                <option value={""}>Select A Biller</option>
                {billers?.map((item, key) => {
                  return (
                    <option key={key} value={`${item.id}-${item.reference}`}>
                      {item.name}
                    </option>
                  );
                })}
              </SelectInput>

              <SelectInput
                label={"Select Bill"}
                id={"biller_item"}
                name={"biller_item"}
                value={`${input.biller_item_paycode}-${input.biller_item_keycode}`}
                onChange={onSelectOfBillerItem}
                autoComplete={"biller_item"}
                disabled={isRepeat ? true : false}
                className={"appearance-none"}
              >
                <option value={""}>Select A Bill</option>
                {billerItems?.map((item, key) => {
                  return (
                    <option key={key} value={`${item.paycode}-${item.keycode}`}>
                      {item.title + " " + item.keycode}
                    </option>
                  );
                })}
              </SelectInput>

              {input.category_code === "airtime" && (
                <React.Fragment>
                  <TextInput
                    label={"Phone Number"}
                    id={"phone_number"}
                    name={"phone_number"}
                    type={"tel"}
                    value={input.phone_number}
                    onChange={onChange}
                    disabled={isRepeat ? true : false}
                    placeholder={"Enter phone number"}
                    autoComplete={"phone_number"}
                    className={"appearance-none"}
                  />

                  <NumericInput
                    leftSection={
                      <span className="self-center border border-gray-300 rounded-sm p-2">
                        NGN
                      </span>
                    }
                    label={"Amount"}
                    id={"amount"}
                    name={"amount"}
                    type={"number"}
                    min={"0"}
                    value={input.amount ?? ""}
                    onChange={onChange}
                    placeholder={"Enter amount"}
                    autoComplete={"amount"}
                    disabled={isRepeat ? true : false}
                    className={"appearance-none"}
                  />
                </React.Fragment>
              )}

              {input.show_save_beneficiary && (
                <CheckboxInput
                  label={"Save as beneficiary"}
                  id={"save_beneficiary"}
                  name={"save_beneficiary"}
                  type={"checkbox"}
                  checked={input.save_beneficiary}
                  onChange={(e) =>
                    setInput({ ...input, [e.target.name]: e.target.checked })
                  }
                  placeholder={""}
                  autoComplete={"save_beneficiary"}
                  className={"appearance-none"}
                />
              )}
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>

        <ConfirmPinModal
          ref={ConfirmPinModalRef}
          onSuccess={onSubmit}
          onFailure={() => {}}
        />
      </div>
    </div>
  );
}
