import {
    CURRENCY_LOADING,
  GET_AVAILABLE_CURRENCIES_SUCCESS,
  GET_BUSINESS_CURRENCY_INFO_SUCCESS
  } from "./types";
  
  const initialState = {
    isLoading: false,
    shouldReload: false,
    availableCurrencies: [],
    businessCurrencyInfo: {},
  };
  
  export default function addCurrencyReducer(state = initialState, action) {
    switch (action.type) {
  
      case CURRENCY_LOADING:
        return {
          ...state,
          isLoading: action.payLoad,
        };
  
      case GET_AVAILABLE_CURRENCIES_SUCCESS:
        return {
          ...state,
          availableCurrencies: action.payLoad.data.data,
        };
      
        case GET_BUSINESS_CURRENCY_INFO_SUCCESS:
          return {
            ...state,
            businessCurrencyInfo: action.payLoad.data.data.data,
          };
    
  
      default:
        return state;
    }
  }
  