import { csvWorkbookDownload } from "../../helpers/XlsxUtils";
import * as xlsx from "xlsx";

export function downloadCardAsCSV(specificCardTransactions, onChange=()=>{}){
    
    const fileName = "Transactions";
    const getWorksheets=({ xlsx }) => {
        return {
            data: xlsx.utils.json_to_sheet(
                specificCardTransactions?.map((item, i) => ({
                    "Status": item.txn_status,
                    "Date": item.txn_date,
                    "Transaction Ref": item.txn_ref,
                    "Description": item.description,
                    "Amount": item.amount,
                })),
            ),
        }
    }

    (async () => {
        if (getWorksheets) {
            try {
                onChange({ isLoading: true, isError: false });
                const workbook = xlsx.utils.book_new();
                const worksheets = await getWorksheets({ workbook, xlsx });
                if (worksheets) {
                    for (const key in worksheets) {
                        xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
                    }
                    if (workbook.SheetNames.length) {
                        csvWorkbookDownload(workbook, fileName);
                    }
                }
            } catch (error) {
                onChange({ isLoading: false, isError: true });
            }
            onChange({ isLoading: false, isError: false });
        }
    })();
}