import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import GladeLogo from "../../components/logos/GladeLogo";
import { IoCloseSharp } from "react-icons/io5";
import SelectRecurringTransferType from "./SelectRecurringTransferType";
import RecipientDetails from "./RecipientDetails";
import { IoIosArrowBack } from "react-icons/io";
import RecurringDetails from "./RecurringDetails";
import ReviewDetails from "./ReviewDetails";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { postRecurringData } from "../../redux/recurringTransfer/recurringTransferActions";
import LoadingLayout from "./Loading";
import VirtualCardSuccess from "../virtual-cards/VirtualCardSuccess";
import SelectRecurringBillForm from "./SelectRecurringBillForm";
import StartRecurringBulkTransfer from "./StartRecurringBulkTransfer";

const PopModal = ({ isOpen, setIsOpen, state }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [input, setInput] = useState({ transfer_type: "", ...location.state, payload: [] });
  const [showSuccess, setShowSuccess] = useState(false);
  const handleType = (type) => {
    setInput({ ...input, transfer_type: type });
    setStep(2);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleApiCall = () => {
    let postData = {...input}
    if(postData?.end_date){
      postData['end_date'] = `${input?.end_date} ${input?.start_time}:00`
    }else{
      delete postData?.end_date;
    }
    dispatch(
      postRecurringData(
        {
          ...postData,
          start_date: `${input?.start_date} ${input?.start_time}:00`,
        },
        state,
        () => {
          return setStep(6);
        },
        () => {
          return setStep(step - 1);
        }
      )
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            // as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="min-h-full bg-black text-center">
              <div className="fixed inset-0 overflow-x-auto bg-white flex flex-col items-center pt-6">
                {/* Headers */}
                {step <= 4 && (
                  <div className="border-b p-4 flex justify-between items-center w-10/12 mb-4 ">
                    <div>
                      {step !== 1 ? (
                        <span
                          className="cursor-pointer flex items-center"
                          onClick={() =>
                            state?.recurringType !== "single" &&
                            state?.recurringType !== "bulk"
                              ? setStep(1)
                              : setStep(step - 1)
                          }
                        >
                          <IoIosArrowBack size={25} /> Back
                        </span>
                      ) : (
                        <GladeLogo />
                      )}
                    </div>
                    <div>
                      <IoCloseSharp
                        className="glade-blue cursor-pointer"
                        size={35}
                        onClick={() => history?.goBack()}
                      />
                    </div>
                  </div>
                )}
                {/* Body */}

                {step === 1 && (
                  <Fragment>
                    {state?.recurringType !== "single" &&
                    state?.recurringType !== "bulk" ? (
                      <SelectRecurringBillForm
                        input={input}
                        setInput={setInput}
                        setStep={setStep}
                        type={state?.recurringType}
                      />
                    ) : state?.recurringType === "bulk" ? (
                      <StartRecurringBulkTransfer
                        input={input}
                        setInput={setInput}
                        step={step}
                        setStep={setStep}
                      />
                    ) : (
                      <SelectRecurringTransferType handleType={handleType} />
                    )}
                  </Fragment>
                )}
                {step === 2 && (
                  <RecipientDetails
                    input={input}
                    setInput={setInput}
                    setStep={setStep}
                    step={step}
                  />
                )}
                {step === 3 && (
                  <RecurringDetails
                    input={input}
                    setInput={setInput}
                    setStep={setStep}
                    step={step}
                  />
                )}
                {step === 4 && (
                  <ReviewDetails
                    input={input}
                    setInput={setInput}
                    step={step}
                    setStep={setStep}
                    handleApiCall={handleApiCall}
                    type={state?.recurringType}
                  />
                )}
                {step === 5 && <LoadingLayout />}
                {step === 6 && (
                  <VirtualCardSuccess
                    showSuccess={step === 6}
                    setShowSuccess={() => setShowSuccess & history.goBack()}
                    title={"Successful"}
                    subtext={
                      "Your recurring transfer details has been saved successfully and will take place at the scheduled time"
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default PopModal;
