import {
  FAILURE_CREATE_RECURRING,
  FAILURE_RECURRING_EDIT,
  FAILURE_RECURRING_LIST,
  LOADING_CREATE_RECURRING,
  LOADING_RECURRING_EDIT,
  LOADING_RECURRING_LIST,
  SUCCESS_CREATE_RECURRING,
  SUCCESS_RECURRING_EDIT,
  SUCCESS_RECURRING_LIST,
} from "./types";

const initialState = {
  isLoadingRecurringList: false,
  isLoadingRecurringEdit: false,
  isLoadingCreateRecurring: false,
  recurringList: {},
};

export default function recurringReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_RECURRING_LIST:
      return {
        ...state,
        isLoadingRecurringList: true,
        recurringList: [],
      };
    case SUCCESS_RECURRING_LIST:
      return {
        ...state,
        isLoadingRecurringList: false,
        recurringList: action.payload,
      };
    case FAILURE_RECURRING_LIST:
      return {
        ...state,
        isLoadingRecurringList: false,
        recurringList: [],
      };
    case LOADING_RECURRING_EDIT:
      return {
        ...state,
        isLoadingRecurringEdit: true,
      };
    case SUCCESS_RECURRING_EDIT:
      return {
        ...state,
        isLoadingRecurringEdit: false,
      };
    case FAILURE_RECURRING_EDIT:
      return {
        ...state,
        isLoadingRecurringEdit: false,
      };
    case LOADING_CREATE_RECURRING:
      return {
        ...state,
        isLoadingCreateRecurring: true,
      };
    case SUCCESS_CREATE_RECURRING:
      return {
        ...state,
        isLoadingCreateRecurring: false,
      };
    case FAILURE_CREATE_RECURRING:
      return {
        ...state,
        isLoadingCreateRecurring: false,
      };
    default:
      return state;
  }
}
