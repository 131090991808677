export const CURRENCY_LOADING = 'CURRENCY_LOADING';
export const ADD_BUSINESS_CURRENCY_SUCCESS = 'ADD_BUSINESS_CURRENCY_SUCCESS';
export const ADD_BUSINESS_CURRENCY_FAILURE = 'ADD_BUSINESS_CURRENCY_FAILURE';
export const GET_AVAILABLE_CURRENCIES_SUCCESS = 'GET_AVAILABLE_CURRENCIES_SUCCESS';
export const GET_AVAILABLE_CURRENCIES_FAILURE = 'GET_AVAILABLE_CURRENCIES_FAILURE';
export const ADD_BUSINESS_WALLET_CURRENCY_SUCCESS = 'ADD_BUSINESS_WALLET_CURRENCY_SUCCESS';
export const ADD_BUSINESS_WALLET_CURRENCY_FAILURE = 'ADD_BUSINESS_WALLET_CURRENCY_FAILURE';
export const ADD_CURRENCY_USD_GBP_EUR_SUCCESS = 'ADD_CURRENCY_USD_GBP_EUR_SUCCESS';
export const ADD_CURRENCY_USD_GBP_EUR_FAILURE = 'ADD_CURRENCY_USD_GBP_EUR_FAILURE';
export const GET_BUSINESS_CURRENCY_INFO_FAILURE = 'GET_BUSINESS_CURRENCY_INFO_FAILURE';
export const GET_BUSINESS_CURRENCY_INFO_SUCCESS = 'GET_BUSINESS_CURRENCY_INFO_SUCCESS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const ADD_CURRENCY_WAITLIST_SUCCESS = 'ADD_CURRENCY_WAITLIST_SUCCESS';
export const ADD_CURRENCY_WAITLIST_FAILURE = 'ADD_CURRENCY_WAITLIST_FAILURE';
