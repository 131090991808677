import React from "react";
import { useDispatch } from 'react-redux';
import PlainModal from "../../components/modals/PlainModal";
import Button from "../../components/buttons/Button";
import NumericInput from '../../components/inputs/NumericInput';
import useVirtualCards from "../../hooks/useVirtualCards";
import { withdrawVirtualCard } from '../../redux/virtual-cards/virtualCardActions';
import {virtualCardWithdrawalValidation} from "../../validation/virtual-card/virtualCardValidation";

export default React.forwardRef(function VirtualCardWithdrawModal({currency = "NGN"}, ref) {

  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: (value) => {setInput(value); plainModalRef.current.open()},
    close: () => {setInput({}); plainModalRef.current.close()},
    toggle: (value) => {setInput(value); plainModalRef.current.toggle()},
  }));

  const dispatch = useDispatch();
  const virtualCards = useVirtualCards();
  const [input, setInput] = React.useState({});

  const onSubmit = () => {
    if (virtualCardWithdrawalValidation(input)) {
      dispatch(
        withdrawVirtualCard({
          reference:input.reference,
          amount: input.amount
        },()=>plainModalRef.current.close())
      );
    }
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <PlainModal
      ref={plainModalRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitle="Withdraw"
      modelContentSection={
        <div className="flex flex-col">
          <div className="flex flex-col px-6 py-9 text-left">
            <NumericInput 
              leftSection={<span className="self-center border border-gray-300 rounded-sm p-2">{currency}</span>}
              label={'Enter Amount'}
              id={'amount'}
              name={'amount'}
              type={'number'}
              value={input.amount}
              onChange={onChange}
              placeholder={'Enter amount'}
              autoComplete={'amount'}
              className={'appearance-none'}
            />
          </div>

          <div className="flex flex-row justify-between p-3 border-t-2 border-solid border-gray-100">
            <div>
              <Button.Transparent
                title={'Cancel'}
                onClick={()=>plainModalRef.current.close()}
              />
            </div>
            <div>
              <Button.Blue
                title={'Withdraw'}
                disabled={!virtualCardWithdrawalValidation(input,true) || virtualCards.isLoading}
                onClick={()=>onSubmit()}
              />
            </div>
          </div>
        </div>
      }
    />
  );
});