export const FormLevelIndicator = ({ formStep, input }) => {
    return (
      <>
        
          <div className="flex flex-row mb-5 md:block">
          
              <div className="flex items-center mb-5 w-full ">
                <div className={` font-bold h-7 w-7 rounded-full text-center flex items-center justify-center text-xs md:text-sm bg-glade-blue-500 text-white`}>
                  01
                </div>
                <div className={`text-xs text-glade-blue-500 ml-5`}>
                  {input.business_category === "SP" ? "Business Information" : (input.business_category === "LLC" || input.business_category === "LLP") ? "Company Information" : input.business_category === "NGO" ? "NGO Information" : ""}
                </div>
              </div>                                      
              <div className="flex items-center mb-5 w-full ">
                <div className={`font-bold h-7 w-7 rounded-full text-center flex items-center justify-center text-xs md:text-sm ${formStep === 'proprietor information' ? 'bg-glade-blue-500 text-white' : 'glade-bg-accent-light text-glade-gray-400'}`}>
                  02
                </div>
                <div className={`text-xs text-glade-gray-400 ml-5 ${formStep === 'proprietor information' && 'text-glade-blue-500'}`}>
                {input.business_category === "SP" ? "Proprietor Information" : input.business_category === "LLC" ? "Directors Information" : input.business_category === "LLP" ? "Partner Information" : input.business_category === "NGO" ? "Trustee Information" : ""}
                </div>
              </div>    
            </div>
        
      </>
    )
  }