import React from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import IndividualInput from '../../components/inputs/IndividualInput';
import useUser from "../../hooks/useUser";
import { createPassCode } from '../../redux/settings/settingsActions';

export default React.forwardRef(function CreateUserPinModal(props,ref) {

    const dispatch = useDispatch();
    const user = useUser();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    const [input, setInput] = React.useState({
        new_pin:'',
    });

    const createPin = () => {
        dispatch(createPassCode({
            user_uuid:user.userInfo.uuid,
            passcode:input.new_pin,
        }));
        plainModalRef.current.close();
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Create PIN'}
            modelContentSection={
                <div className="flex flex-col space-y-9">

                    <div className="flex flex-col px-9 pt-9 space-y-9 items-center ">
                        <IndividualInput 
                            label={'Enter New Pin'}
                            numberOfInputTags={4} 
                            onChange={(value)=>setInput({...input,new_pin:value})} 
                        />
                    </div>

                    <div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
                        <div>
                            <Button.Blue onClick={()=>plainModalRef.current.close()} className="glade-button-off-white glade-grey-dark" title="Cancel" />
                        </div>
                        <div>
                            <Button.Blue onClick={()=>createPin()} title="Create PIN" />
                        </div>
                    </div>
                </div>
            }
        />
    )
})
