import React, { useState, useEffect } from 'react';
import Button from '../../buttons/Button';
import FileInput from '../../inputs/FileInput';
import TextInput from '../../inputs/TextInput';
import { addCurrencyDirectorInfoValidation } from '../../../validation/add-currency/AddCurrencyValidation';
import SelectInput from '../../inputs/SelectInput';
import { isArray } from '../../../helpers/helper';
import { useDispatch } from 'react-redux';
import { getCountries } from '../../../redux/addCurrency/addCurrencyActions';



const AddCurrencyForm2 = ({ input, setInput, onChange, openConfirmPinModal, setContent }) => {
  const dispatch = useDispatch();

  const [inputError, setInputError] = useState({
    director_country: false
  })
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    countries?.length < 1 && dispatch(getCountries((res) => setCountries(res)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.director_country]);


  const handleChange = (e) => {
    onChange(e);
    setInputError({ ...inputError, director_country: false })
  }

  const disable = () => {
    if (!input.director_first_name || !input.director_last_name || !input.director_dob || !input.director_country || !input.director_state || !input.director_city || !input.director_postal_code || !input.director_phone_number || !input.director_address) {
      return true
    } else { return false }
  }

  const handleSubmit = () => {
    if (addCurrencyDirectorInfoValidation(input)) {
      // setContent(3)
      openConfirmPinModal()
    } else {
      return false
    }
  }

  return (
    <div className="my-6 md:my-16">
      <div className="space-y-6">
        <TextInput
          label={'First Name'}
          id={'director_first_name'}
          name={'director_first_name'}
          type={'text'}
          value={input.director_first_name}
          onChange={onChange}
          placeholder={'Enter First Name'}
          autoComplete={'director_first_name'}
          className={'appearance-none'}
        />

        <TextInput
          label={'Last Name'}
          id={'director_last_name'}
          name={'director_last_name'}
          type={'text'}
          value={input.director_last_name}
          onChange={onChange}
          placeholder={'Enter Last Name'}
          autoComplete={'director_last_name'}
          className={'appearance-none'}
        />

        <div>
          <FileInput
            label={"Director's document (Optional)"}
            id={"director_document"}
            name={"director_document"}
            multiple={false}
            accept=".png, .jpeg, .pdf, .jpg"
            maximumSize={3}
            returnInput={(value) =>
              setInput({ ...input, director_document: value })
            }
          />
          <span className="glade-small-text-one glade-grey-cool">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is
            3MB.
          </span>
        </div>

        <TextInput
          label={'Date of Birth'}
          id={'director_dob'}
          name={'director_dob'}
          type={'date'}
          value={input.director_dob}
          onChange={onChange}
          placeholder={'Enter Date of Birth'}
          autoComplete={'director_dob'}
          className={'appearance-none'}
        />

        <SelectInput
          label={'Country'}
          id={"director_country"}
          name={'director_country'}
          value={input.director_country ?? ''}
          onChange={(e) => handleChange(e)}
          autoComplete={"director_country"}
          className={"appearance-none"}
          errorState={inputError?.director_country}
        >
          <option value={''} disabled={true}>Select A Country</option>
          {isArray(countries) && countries?.map((country, index) => (
            <option key={index} value={country.country_code}>{country.country_name}</option>
          ))}
        </SelectInput>


        <TextInput
          label={'State'}
          id={'director_state'}
          name={'director_state'}
          type={'text'}
          value={input.director_state}
          onChange={onChange}
          placeholder={'Enter State'}
          autoComplete={'director_state'}
          className={'appearance-none'}
        />

        <TextInput
          label={'City'}
          id={'director_city'}
          name={'director_city'}
          type={'text'}
          value={input.director_city}
          onChange={onChange}
          placeholder={'Enter City'}
          autoComplete={'director_city'}
          className={'appearance-none'}
        />


        <TextInput
          label={'Postal/Zip Code'}
          id={'director_postal_code'}
          name={'director_postal_code'}
          type={'text'}
          value={input.director_postal_code}
          onChange={onChange}
          placeholder={'Enter postal/zip codesite'}
          autoComplete={'director_postal_code'}
          className={'appearance-none'}
        />

        <TextInput
          label={'Business Phone Number'}
          id={'director_phone_number'}
          name={'director_phone_number'}
          type={'tel'}
          value={input.director_phone_number}
          onChange={onChange}
          placeholder={'Enter Phone Number'}
          autoComplete={'director_phone_number'}
          className={'appearance-none'}
        />

        <TextInput
          label={'Address'}
          id={'director_address'}
          name={'director_address'}
          type={'text'}
          value={input.director_address}
          onChange={onChange}
          placeholder={'Enter Address'}
          autoComplete={'director_address'}
          className={'appearance-none'}
        />

        <Button.Blue
          title={"Confirm"}
          type={"button"}
          className={"py-3 mb-3"}
          disabled={disable()}
          onClick={handleSubmit}
        />

      </div>
    </div>
  )
}

export default AddCurrencyForm2