import { Fragment, useEffect } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { getConvertRate } from "../../redux/convert/convertActions";
import { useDispatch } from "react-redux";
import useConvert from "../../hooks/useConvert";

const RateRatio = ({
  baseCurrency,
  recieveCurrency,
  send_amount,
}) => {
  const { rateDetails } = useConvert();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getConvertRate({
        source_currency: baseCurrency,
        destination_currency: recieveCurrency,
        action: "conversion",
      })
    );
  }, [dispatch, baseCurrency, recieveCurrency]);

  return (
    <div className="rounded flex flex-col gap-1 p-2 glade-bg-grey mt-6 glade-bg-light-grey">
      <div className="flex gap-2 items-center">
        <RiErrorWarningFill /> Rate
      </div>
      {rateDetails?.data?.rate && (
        <Fragment>
          <div>
            1 {baseCurrency} = {`${Number(rateDetails?.data?.rate)} ${recieveCurrency}`}
          </div>
          <div className="font-bold">
            Your Total{" "}
            {`${Number(send_amount.replaceAll(",", "")).toLocaleString(
              "en-US",
              { minimumFractionDigits: 2 }
            )} ${baseCurrency} `}
             = {`${Number(rateDetails?.data?.rate * send_amount).toLocaleString("en-US") } ${recieveCurrency}`}

          </div>
        </Fragment>
      )}
      {!rateDetails?.data?.rate && <p>Charge is unavailable</p>}
    </div>
  );
};

export default RateRatio;
