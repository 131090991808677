import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { BORDERLESS_CARD_ONBOARDING, BOTTOM_LEFT_ELLIPSE, DESKTOP_ONBOARDING, DOT_PROGRESS, EXPENSE_ONBOARDING, TOP_RIGHT_ELLIPSE, TRANSFER_ONBOARDING } from '../../assets/images/images'


const OnboardingRight = () => {

  const [currentSlide, setCurrentSlide] = useState(0);

  // Array of pages in the carousel
  const carouselPages = [
    {
      page: 1,
      heading: 'GLOBAL ACCOUNTS',
      paragraph: <p className='glade-paragraph-text-one text-center'>Open local accounts in USD, GBP, EUR, NGN, <br/> KES instantly to collect payments immediately.</p>,
      image: DESKTOP_ONBOARDING,
    },
    {
      page: 2,
      heading: 'Transfer & FX',
      paragraph: <p className='glade-paragraph-text-one text-center'>Make domestic and international payments to your suppliers <br /> and vendors with low fees and great exchange rates.</p>,
      image: TRANSFER_ONBOARDING,
    },
    {
      page: 3,
      heading: 'Borderless Cards',
      paragraph: <p className='glade-paragraph-text-one text-center'>Create a virtual and physical card to manage one-time or <br /> recurring payments with or specific vendors or subscriptions.</p>,
      image: BORDERLESS_CARD_ONBOARDING,
    },
    {
      page: 4,
      heading: 'Expense Management',
      paragraph: <p className='glade-paragraph-text-one text-center'>Stay on top of your company expenses with budgets, spend <br /> limits, employee cards, and reconciliation.</p>,
      image: EXPENSE_ONBOARDING,
    },

  ]

  // Auto slide duration in milliseconds
  const autoSlideDuration = 5000;

  // Function to handle auto sliding
  const handleAutoSlide = () => {
    const nextSlide = (currentSlide + 1) % carouselPages.length;
    setCurrentSlide(nextSlide);
  };

  useEffect(() => {
    // Start auto sliding when component mounts
    const intervalId = setInterval(handleAutoSlide, autoSlideDuration);

    // Clean up the interval when component unmounts
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  const handlePageSelect = (pageIndex) => {
    setCurrentSlide(pageIndex);
  };

  return (
    <div className='min-h-screen glade-bg-blue relative col-span-2 hidden md:flex items-center justify-center flex-col'>
      <img src={TOP_RIGHT_ELLIPSE} alt='' className='absolute top-0 right-0' />
      <img src={BOTTOM_LEFT_ELLIPSE} alt='' className='absolute bottom-0 left-0' />

      {carouselPages.map((page, index) => (
        <div
          key={index}
          className={`absolute flex flex-col justify-center w-full ${index === currentSlide ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-500`}
        >
          <img src={page.image} alt='' className='px-12' />
          <div className='flex flex-col space-y-6 justify-center items-center  text-white mt-8'>
            <h1 className='glade-heading-one'>{page.heading}</h1>
            {page.paragraph}
          </div>
        </div>
      ))}

      <div className="absolute bottom-16 w-full flex justify-center mt-4">
        {carouselPages.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full mx-1 ${index === currentSlide ? 'glade-bg-blue-white' : 'glade-bg-dark-blue'
              }`}
            onClick={() => handlePageSelect(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default OnboardingRight