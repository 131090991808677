import React, { useEffect } from 'react'
import Button from "../../buttons/Button";
import { useDispatch } from "react-redux";
import useConvert from "../../../hooks/useConvert";
import { toastWarning } from "../../../helpers/utils.toast";
import useUser from "../../../hooks/useUser";
import { getSuppliedFieldValue } from "../../../helpers/helper";
import { getConvertRate } from '../../../redux/convert/convertActions';
import { ImSpinner2 } from "react-icons/im";


const ConfirmCharge = ({ openingCharge, currency, onProceed }) => {
  const dispatch = useDispatch();
  const { rateDetails } = useConvert();
  const user = useUser();

  useEffect(() => {
    dispatch(getConvertRate({
      source_currency: getSuppliedFieldValue('currency', user) ? getSuppliedFieldValue('currency', user) : 'NGN',
      destination_currency: currency
    }))
  }, [])

  return (
    <div className="flex flex-col items-center justify-center w-full sm:w-6/12 lg:w-4/12">
      <h2 className="text-2xl font-bold mb-4">Confirmation</h2>
      {rateDetails?.isLoadingRate &&
        <div className='flex justify-center'><ImSpinner2 className='mt-6 spinner text-center' size={70} color='#0B7AE0' /></div>
      }
      {!rateDetails?.isLoadingRate &&
        <>
          <p className="mb-8">
            You will be charged <span className="font-bold">
              {rateDetails?.data?.charge?.toLocaleString('en-US') || "0"} {rateDetails?.data?.source_currency.toUpperCase() || "N/A"}
            </span> for account opening as stated in our account opening terms and conditions
          </p>
          <Button.Blue
            title={"Proceed"}
            onClick={onProceed}
          />
        </>
      }

    </div>
  );
};

export default ConfirmCharge;