import React from 'react';
import { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import NumericInput from '../../components/inputs/NumericInput';
import GladeLogo from '../../components/logos/GladeLogo';
import { toastSuccess, toastWarning } from '../../helpers/utils.toast';
import useUser from '../../hooks/useUser';
import { dashboardFundingValidation } from '../../validation/fund-account/fundAccountValidation';
import { AccountTab } from '../home/MainAccountBox';
import useHome from "../../hooks/useHome";
import useTeam from '../../hooks/useTeam';


const FundAccountModal = ({ onClose }) => {
  const user = useUser();
  const [input, setInput] = useState({ amount: 0 });
  const home = useHome();
  const {team_user} =useTeam();

  const [showAmountField, setShowAmountField] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState("bank-name");
  const [selectedAccountDetails, setSelectedAccountDetails] = useState("account_1");

  const onChange = (e) => {
    if (e.target.name === 'amount') {
      setInput({ ...input, [e.target.name]: Number(e.target.value).toLocaleString('en-US') });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  }
  const onSubmit = () => {
    if (dashboardFundingValidation({ amount: String(input.amount).replaceAll(',', '') })) {
      if (typeof window.payWithGlade !== "function") {
        return toastWarning("Glade Pay is currently unavailable, Please try again later");
      } else {
        window?.payWithGlade({
          MID: user?.isBusiness ? user?.selectedBusinessOrPersonalAccount?.business_uuid : user?.userInfo?.uuid,
          email: user?.userInfo?.email,
          firstname: user?.userInfo?.first_name,
          lastname: user?.userInfo?.last_name,
          amount: input.amount.split(',').join(''),
          meta_data: JSON.stringify({ mid: user?.isBusiness ? user?.selectedBusinessOrPersonalAccount?.business_uuid : user?.userInfo?.uuid, amount: input.amount, currency: 'NGN' }),
          description: "Account Funding",
          title: "Glade Transfers",
          bearer: "Customer",
          dashboard: "funding",
          currency: "NGN",
          // is_recurring:true,
          // recurrent: JSON.stringify({frequency: "weekly", value: "1"}),
          onclose: (err) => { },
          callback: (res) => {
            toastSuccess("Transaction was successful");
            // dispatch(getPersonalAndBusinessAccounts());
          },
        });
      }
    }
  };
  const fundAccountModalRef = React.useRef();
  return (
    <div className="flex flex-col items-center px-6 py-12 w-full bg-white h-full">
      <div className='border-b pb-6 flex justify-between w-10/12 '>
        <GladeLogo />
        <IoCloseSharp className='glade-blue cursor-pointer' size={35} onClick={() => { onClose(); }} />
      </div>
      <div className='text-center mt-20'>
        <h3 className='text-center glade-black glade-heading-two-bold'>Add funds</h3>
      </div>
      {
        !showAmountField ?
          <div className="px-4 justify-center items-center w-full md:w-3/4 lg:w-1/2 mt-24">

            <h3 className='glade-heading-four text-center'>Fund your account by paying into any of the account numbers below</h3>
            {/* Tabs for account details */}
            <div className='rounded bg-glade-gray-50 p-2'>
              <div className='bg-white m-6 p-4'>
              <div className="grid grid-cols-2 bg-glade-gray-50 p-1 rounded text-xs text-glade-black-700 mt-1">
                <div
                  className={`text-center py-1 cursor-pointer ${selectedAccountDetails === "account_1" ? "bg-white rounded" : ""
                    } `}
                  onClick={() => setSelectedAccountDetails("account_1")}
                >
                  Account 1
                </div>
                <div
                  className={`text-center py-1 cursor-pointer ${selectedAccountDetails === "account_2" ? "bg-white rounded" : ""
                    } `}
                  onClick={() => setSelectedAccountDetails("account_2")}
                >
                  Account 2
                </div>
              </div>

              {selectedAccountDetails === "account_1" && (
                <AccountTab
                  selectedInfo={selectedInfo}
                  user={user}
                  details={
                    user?.isBusiness
                      ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                      home.oneBusinessAccount?.naira_accounts?.accounts[0]
                      : team_user?.employee?.is_teams ? team_user?.ngn_accounts[0] : 
                      user?.accountInfo?.accounts &&
                      user?.accountInfo?.accounts[0]
                  }
                />
              )}

              {selectedAccountDetails === "account_2" && (
                <AccountTab
                  selectedInfo={selectedInfo}
                  user={user}
                  details={
                    user?.isBusiness
                      ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                      home.oneBusinessAccount?.naira_accounts?.accounts[1]
                      : team_user?.employee?.is_teams ? team_user?.ngn_accounts[1] :
                      user?.accountInfo?.accounts &&
                      user?.accountInfo?.accounts[1]
                  }
                />
                )}
                </div>
            </div>
          </div>
          :
          <div className="p-6 pb-0 flex h-full w-10/12 justify-center items-center flex-col">
            <div className="text-left w-4/12  border-l-2 border-r-2 border-t-2 border-solid border-gray-100 pt-4 pr-4 pl-4">
              <NumericInput
                leftSection={<span className="self-center bg-white border border-gray-300 rounded-sm p-2">{'NGN'}</span>}
                label={'Amount'}
                id={"amount"}
                name={'amount'}
                type={'number'}
                min={'0'}
                value={input?.amount}
                onChange={onChange}
                placeholder={'Enter amount'}
                autoComplete={"amount"}
                className={"appearance-none border"}
              />
            </div>
            <div className="flex items-center w-4/12 p-4 justify-between gap-4 pt-4 border-b-2 border-l-2 border-r-2 border-solid border-gray-100">
              <div>
                <button
                  type="button"
                  onClick={() => setShowAmountField(false)}
                  className="glade-normal-text-three glade-grey-cool glade-button-off-white w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm cursor-pointer">
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={onSubmit}
                  disabled={!dashboardFundingValidation({ amount: String(input.amount).replaceAll(',', '') }, true)}
                  className="glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm cursor-pointer">
                  Fund Account
                </button>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default FundAccountModal;