import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { 
  LOADING_PAYROLL,
  LOADING_PAYDAY,
  RELOAD_PAYROLL,
  RELOAD_PAYDAY,
  GET_PAYROLL_STAFF,
  CREATE_PAYROLL_STAFF,
  CREATE_PAYROLL_STAFF_BULK,
  REMEMBER_BULK_CREATE_STAFF_LIST,
  UPDATE_PAYROLL_STAFF,
  DELETE_PAYROLL_STAFF,
  GET_PAYDAY,
  GET_PAYDAY_BY_ID,
  CREATE_PAYDAY,
  UPDATE_PAYDAY,
  DELETE_PAYDAY,
  LOADING_SALARY_ADVANCEMENT,
  SUCCESS_SALARY_ADVANCEMENT,
  FAILURE_SALARY_ADVANCEMENT,
  LOADING_PAYROLL_STAFF,
  PAYROLL_STAFF_SUCCESS,
  PAYROLL_STAFF_FAILURE,
  LOADING_DELETE_PAYDAY,
} from "./types";

/**
 * Get all payroll staff
 * @param {object} payload
 * @returns {void}
 */
export const getPayrollStaff = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYROLL, payLoad:true});
    Axios.get(`/payroll/staff`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      dispatch({ type: GET_PAYROLL_STAFF, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Create payroll staff
 * @param {object} payLoad {
    "name": "Bars Frund",
    "email": "bawee@gmail.com",
    "staff_position": "Engineer",
    "staff_number": "0003",
    "phone": "09090909334",
    "account_number": "0245939738",
    "account_name": "Bars Frund",
    "value": "50000",
    "bank_code": "057",
    "currency": "NGN",
    "individual_type": "staff",
    "business_uuid": "26926f44-46e7-4835-9bf1-c52855f74821"
  }
 * @returns {void}
 */
export const createPayrollStaff = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYROLL, payLoad:true});
    Axios.post(`/payroll/staff/single`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      dispatch({ type: RELOAD_PAYROLL });
      dispatch({ type: CREATE_PAYROLL_STAFF, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      errorHandler(error, true);
    });
  }
}
export const salaryAdvancementRequest = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SALARY_ADVANCEMENT, payLoad:true});
    callBack('loading')
    Axios.post(`/payroll/salaryadvance/request`,{...payLoad})
    .then((res) => {
      dispatch({ type: SUCCESS_SALARY_ADVANCEMENT, payLoad: res});
      successHandler(res, true);
      callBack('success');
    })
    .catch((error) => {
      dispatch({ type: FAILURE_SALARY_ADVANCEMENT, payLoad:false});
      errorHandler(error, true);
      callBack('failed')
    });
  }
}
/**
 * create payroll staff bulk
 * @param {object} payLoad 
  [
    {
      "name": "Bars Frund",
      "email": "barswee@gmail.com",
      "staff_position": "Engineer",
      "staff_number": "0003",
      "phone": "09090909334",
      "account_number": "0288939738",
      "account_name": "Bars Frund",
      "value": "50000",
      "bank_code": "057",
      "currency": "NGN",
      "individual_type": "staff"
    },
    {
      "name": "Udele Odiri",
      "email": "bles@gmail.com",
      "staff_position": "Engineer",
      "staff_number": "0054",
      "phone": "09090209334",
      "account_number": "0278939738",
      "account_name": "Udele Odiri",
      "value": "50000",
      "bank_code": "057",
      "currency": "NGN",
      "individual_type": "staff"
    }
 ]
 * @returns {void}
 */
export const createPayrollStaffBulk = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYROLL, payLoad:true});
    Axios.post(`/payroll/staff/bulk`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      dispatch({ type: RELOAD_PAYROLL });
      dispatch({ type: CREATE_PAYROLL_STAFF_BULK, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Update payroll staff
 * @params {object} payLoad {
    "name": "Moses Phenoma",
    "email": "phenom@gmail.com",
    "staff_position": "Manager",
    "staff_number": "0001",
    "phone": "0909090909090",
    "account_number": "0288939287",
    "account_name": "Moses Phenoma",
    "value": "50000",
    "bank_code": "057",
    "currency": "NGN",
    "individual_type": "staff",
    "business_uuid": "26926f44-46e7-4835-9bf1-c52855f74821"
 }
 * @returns {void}
 */
export const updatePayrollStaff = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYROLL, payLoad:true});
    Axios.put(`/payroll/staff/${payLoad.id}`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      dispatch({ type: RELOAD_PAYROLL });
      dispatch({ type: UPDATE_PAYROLL_STAFF, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Remember bulk create staff list
 * @param {object} payLoad
 * @returns {void}
 */
export const rememberBulkCreateStaffList = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: REMEMBER_BULK_CREATE_STAFF_LIST, payLoad:{data:{data:payLoad}}});
    successHandler({message:'Bulk list processed', code: 200, status: true, data:payLoad});
    callBack();
  }
}

/**
 * Delete payroll staff
 * @params {object} payLoad {staff_id:'43'}
 * @returns {void}
 */
export const deletePayrollStaff = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYROLL, payLoad:true});
    Axios.delete(`/payroll/staff/${payLoad.staff_id}`)
    .then((res) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      dispatch({ type: RELOAD_PAYROLL });
      dispatch({ type: DELETE_PAYROLL_STAFF, payLoad:res});
      successHandler(res,true);
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYROLL, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get all payroll payday
 * @params {object} payLoad
 * @returns {void}
 */
export const getPayday = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYDAY, payLoad:true});
    Axios.get(`/payroll/payday`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_PAYDAY, payLoad:false});
      dispatch({ type: GET_PAYDAY, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYDAY, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get a payroll payday details
 * @params {object} payLoad
 * @returns {void}
 */
export const getPaydayById = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYDAY, payLoad:true});
    Axios.get(`/payroll/payday/${payLoad.payday_id}`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_PAYDAY_BY_ID, payLoad:res});
      successHandler(res);
      callBack(res.data.data);
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYDAY, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Create payroll payday
 * @param {object} payLoad {
    "employee_id_list": [1,2],
    "business_uuid": "26926f44-46e7-4835-9bf1-c52855f74821",
    "payday": "2021-04-30",
    "pay_day_type": "fixed"
 }
 * @returns {void}
 */
export const createPayday = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYDAY, payLoad:true});
    Axios.post(`/payroll/payday`,{...payLoad})
    .then((res) => {
      dispatch({ type: LOADING_PAYDAY, payLoad:false});
      dispatch({ type: RELOAD_PAYDAY });
      dispatch({ type: CREATE_PAYDAY, payLoad:res});
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYDAY, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Update payroll payday
 * @params {object} payLoad {
    "business_uuid": "03db7273-b683-404c-8b10-6a0b4e0625da",
    "payday": "2021-04-27"
 }
 * @returns {void}
 */
export const updatePayday = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYDAY, payLoad:true});
    Axios.put(`/payroll/payday/${payLoad.payday_id}`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_PAYDAY, payLoad:false});
      dispatch({ type: RELOAD_PAYDAY });
      dispatch({ type: UPDATE_PAYDAY, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_PAYDAY, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Delete payroll payday
 * @params {object} payLoad {payday_id:'43'}
 * @returns {void}
 */
export const deletePayday = (payLoad, last_request, onSuccess=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_DELETE_PAYDAY, payLoad:true});
    Axios.delete(`/payroll/payday/${payLoad.payday_id}`)
    .then((res) => {
      dispatch({ type: RELOAD_PAYDAY });
      dispatch({ type: DELETE_PAYDAY, payLoad:res});
      successHandler(res,last_request);
      if(last_request){
        onSuccess();
      }
    })
    .catch((error) => {
      dispatch({ type: DELETE_PAYDAY});
      errorHandler(error, true);
    });
  }
}

export const getPayrollStaffById =(staff_id)=>{
  return (dispatch) => {
    dispatch({ type: LOADING_PAYROLL_STAFF});
    Axios.get(`/payroll/staff/${staff_id}`)
    .then((res) => {
      dispatch({ type: PAYROLL_STAFF_SUCCESS, payLoad: res?.data?.data});
      successHandler(res, true);
    })
    .catch((error) => {
      dispatch({ type: PAYROLL_STAFF_FAILURE, payLoad:false});
      errorHandler(error, true);
    });
  }
}