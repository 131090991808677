import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  FAILURE_CREATE_RECURRING,
  FAILURE_RECURRING_EDIT,
  FAILURE_RECURRING_LIST,
  LOADING_CREATE_RECURRING,
  LOADING_RECURRING_EDIT,
  LOADING_RECURRING_LIST,
  SUCCESS_CREATE_RECURRING,
  SUCCESS_RECURRING_EDIT,
  SUCCESS_RECURRING_LIST,
} from "./types";

export const getRecurringList = (payLoad, type) => {
  return (dispatch) => {
    dispatch({ type: LOADING_RECURRING_LIST });
    Axios.get(`/${type.base}/recurring/${type.recurringType}`, {
      params: payLoad,
    })
      .then((res) => {
        dispatch({ type: SUCCESS_RECURRING_LIST, payload: res?.data?.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: FAILURE_RECURRING_LIST, payLoad: false });
        errorHandler(error, true);
      });
  };
};
export const updateRecurringData = (payLoad, type, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_RECURRING_EDIT });
    Axios.put(
      `/${type.base}/recurring/${type.recurringType}/${type?.reference}`,
      { ...payLoad }
    )
      .then((res) => {
        dispatch({ type: SUCCESS_RECURRING_EDIT, payload: res?.data });
        successHandler(res, true);
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: FAILURE_RECURRING_EDIT, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const pauseOrDeleteRecurringData = (type, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_RECURRING_EDIT });
    if (type.action !== "delete") {
      Axios.put(
        `/${type.base}/recurring/${type.recurringType}/${type?.reference}/${type.action}`
      )
        .then((res) => {
          dispatch({ type: SUCCESS_RECURRING_EDIT, payload: res?.data });
          successHandler(res, true);
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: FAILURE_RECURRING_EDIT, payLoad: false });
          errorHandler(error, true);
        });
    }else{
      Axios.delete(
        `/${type.base}/recurring/${type.recurringType}/${type?.reference}`
      )
        .then((res) => {
          dispatch({ type: SUCCESS_RECURRING_EDIT, payload: res?.data });
          successHandler(res, true);
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: FAILURE_RECURRING_EDIT, payLoad: false });
          errorHandler(error, true);
        });
    }
  };
};

export const postRecurringData = (payLoad, type, onSuccess=()=>{}, onFailure=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_CREATE_RECURRING });
    Axios.post(`/${type.base}/recurring/${type.recurringType}`, {
      ...payLoad,
    })
      .then((res) => {
        dispatch({ type: SUCCESS_CREATE_RECURRING, payload: res?.data?.data });
        onSuccess(res);
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: FAILURE_CREATE_RECURRING, payLoad: false });
        onFailure();
        errorHandler(error, true);
      });
  };
};