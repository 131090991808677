import jsPDF from "jspdf";
import "jspdf-autotable";
import "rasterizehtml";
import * as html2canvas from "html2canvas";
import {GLADE_LOGO} from "../../assets/images/images";

window.html2canvas = html2canvas;
var LogoImage = new Image();
LogoImage.src = GLADE_LOGO;

export function downloadPaymentInflowReceiptAsPDF(details) {

    const fileName = "Glade Receipt";
    const body = `
    <div style="margin: 0; font-size: 16pt; width: 420pt; height: 450pt">
        <main
        style="
            background-color: #fff;
            padding: 50pt 40pt 30pt;
            font-family: Arial, Helvetica, sans-serif;
        "
        >
        <div style="display: flex; justify-content: center; width: 525pt;">
            <img
            src=${GLADE_LOGO}
            alt=""
            style="
                margin-left: auto;
                margin-right: auto;
                width: 130pt;
                height: 60pt;
                object-fit: cover;
            "
            />
        </div>
        <div style="display: flex; justify-content: center; width: 525pt;">
            <p style="text-align: center; margin-top: 20pt; margin-bottom: 30pt">
                Receipt from <b>${details?.merchant_name}</b>
            </p>
        </div>
        <hr style="margin-bottom: 20pt; width: 520pt; margin-left: -10pt" />
        <table style="width: 520pt; margin-left: -15pt">
            <tr style="padding-bottom: 30pt">
            <td style="padding: 10pt 0">
                <p style="color: #717485">Amount</p>
            </td>
            <td>
                <p style="text-align: right">
                <b>${details?.amount}</b>
                </p>
            </td>
            </tr>
            <tr style="padding-bottom: 30pt">
            <td style="padding: 10pt 0">
                <p style="color: #717485">Payment Method</p>
            </td>
            <td>
                <p style="text-align: right; text-transform: capitalize">
                <b>${details?.payment_method}</b>
                </p>
            </td>
            </tr>
            <tr style="padding-bottom: 30pt">
            <td style="padding: 10pt 0">
                <p style="color: #717485; white-space: nowrap">Transaction Reference</p>
            </td>
            <td>
                <p style="text-align: right">
                <b>${details?.txn_ref}</b>
                </p>
            </td>
            </tr>
            <tr style="padding-bottom: 30pt">
            <td style="padding: 10pt 0">
                <p style="color: #717485">Transaction Date
            </td>
            <td>
                <p style="text-align: right">
                <b>${details?.date}</b>
                </p>
            </td>
            </tr>
        </table>
        <hr style="margin-top: 20pt; width: 520pt; margin-left: -10pt" />
        <p style="color: #717485; width: 440pt; margin-top: 20pt; margin-left: -10pt">
            If you have any enquiries about this payment, contact us at <wbr />
            <a
            style="text-decoration: none; color: #00afef"
            href="mailto:${details?.merchant_email}"
            >${details?.merchant_email}</a
            >
            or call us at <wbr />
            <a style="text-decoration: none; color: #00afef" href="tel:${details?.merchant_phone}"
            >${details?.merchant_phone}</a
            >
        </p>
        </main>
        <footer
        style="
            background-color: #00afef;
            padding: 14pt;
            font-family: Arial, Helvetica, sans-serif;
            width: 575pt;
        "
        >
        <p style="color: #fff; text-align: center">
            Business banking built for Africa
        </p>
        <p style="color: #fff; text-align: center">Lagos, Nigeria</p>
        <p style="color: #fff; text-align: center; margin-top: 40pt">
            © Glade 2021. All Rights Reserved
        </p>
        </footer>
    </div>`;

    const exportToPDF = () => {
        const unit = "pt";
        const size = [420, 469]; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        let doc = new jsPDF(orientation, unit, size);
        doc.setTextColor("#4D4D4D");
        doc.setFontSize(12);

        doc.html(body, {
        callback: function (doc) {
            doc.save(`${fileName}.pdf`);
        },
        html2canvas: {
            scale: 0.55,
        },
            width: 560,
        });
    };

  return (!details || !details.merchant_name) ? null : exportToPDF();
}