import React from 'react';
import {PopoverMain, PopoverButton} from '../../components/popovers/Popover';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import { BsCaretDownFill } from "react-icons/bs";
import { RiLoader5Fill } from "react-icons/ri";

export default function CashFlowFilter({
    types=[],
    button,
    disabled=false,
    loading=false,
    onFilter=()=>{}, 
    onCancel=()=>{},
    onReset=()=>{},
    returnInput=()=>{}
}) {

    const timeOptions = {
        today: new Date().toISOString().substring(0, 10),
        yesterday: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substring(0, 10),
        last7days: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substring(0, 10),
        last30days: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substring(0, 10),
        last90days: new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substring(0, 10),
        thisMonth: new Date(new Date().setDate(1)).toISOString().substring(0, 10),
        lastMonth: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().substring(0, 10),
        thisYear: new Date(new Date().setMonth(0)).toISOString().substring(0, 10),
        lastYear: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().substring(0, 10),
    }

    const [input, setInput] = React.useState({});
    const [preInput, setPreInput] = React.useState({});
    const [filterOptions, setFilterOptions] = React.useState({});
    const [buttonDisplay, setButtonDisplay] = React.useState({});
    React.useEffect(() => {

        // list default filter options
        let initialInput = {};
        types.includes('status') && (initialInput.status = 'all');
        types.includes('invoice status') && (initialInput.invoice_status = 'draft');
        types.includes('date') && (initialInput.date = timeOptions.last7days);

        // Set default filter options
        setInput(initialInput);
        returnInput(initialInput);
        setPreInput(initialInput);

        // eslint-disable-next-line 
    }, []);

    const onChange = (e) => {
        setInput({...input,[e.target.name]:e.target.value});
        returnInput({...input,[e.target.name]:e.target.value});

        if (e.target.name === 'date') {
            setButtonDisplay(e.target.options[e.target.selectedIndex].text);
        }
    }

    return (
        <PopoverMain
            button={
                button ?? 
                <button type="button" className={"py-2 glade-blue"}>
                    {Object.keys(buttonDisplay).length > 0 ?
                        <div className="inline-flex items-start justify-items-center mb-7">
                            <span className="glade-subtitle-two glade-blue mr-1">{buttonDisplay}</span>
                            <span className="text-2xl mr-3"><BsCaretDownFill size={15} /></span>
                        </div>
                    :
                        <div className="inline-flex items-center justify-items-center mb-7">
                            <span className="glade-subtitle-two glade-blue mr-1">Last 7 Days</span>
                            <span className="text-2xl mr-3"><BsCaretDownFill size={15} /></span>
                        </div>
                    }
                </button>
            }>
            <div className="bg-white w-60 p-6 border border-gray-200">
                <div className="flex flex-col space-y-6">
                    {types.includes("status") &&
                    <SelectInput 
                        label={'Status'}
                        id={'status'}
                        name={'status'}
                        value={input.status}
                        onChange={onChange}
                        autoComplete={'status'}
                        className={'appearance-none'}>
                        <option value="all">All</option>
                        <option value="successful">Successful</option>
                        <option value="failed">Failed</option>
                        <option value="pending">Pending</option>
                    </SelectInput>}

                    {types.includes("invoice status") &&
                    <SelectInput 
                        label={'Status'}
                        id={'invoice_status'}
                        name={'invoice_status'}
                        value={input.invoice_status}
                        onChange={onChange}
                        autoComplete={'invoice_status'}
                        className={'appearance-none'}>
                        <option value="draft">Draft</option>
                        <option value="paid">Paid</option>
                        <option value="cancelled">Cancelled</option>
                        <option value="pending">Pending</option>
                    </SelectInput>}

                    {types.includes("date") &&
                    <SelectInput 
                        label={'Date'}
                        id={'date'}
                        name={'date'}
                        value={input.date}
                        onChange={onChange}
                        autoComplete={'date'}
                        className={'appearance-none'}>
                        {/* <option value={timeOptions.today}>Today</option> */}
                        <option value={timeOptions.last7days + '.Last 7 days'}>Last 7 days</option>
                        <option value={timeOptions.last30days + '.Last 30 days'}>Last 30 days</option>
                        <option value={timeOptions.last90days + '.Last 90 days'}>Last 90 days</option>
                        <option value={timeOptions.thisYear + '.This Year'}>This Year</option>
                        <option value={timeOptions.lastYear + '.All Time'}>All Time</option>
                        {/* <option value="custom-date">Custom Date</option> */}
                    </SelectInput>}

                    {types.includes("date") && input.date === 'custom-date' &&
                    <DateInput 
                        label={'Date From'}
                        id={'date_from'}
                        name={'date_from'}
                        type={'date'}
                        value={input.date_from}
                        min={timeOptions.lastYear}
                        max={timeOptions.today}
                        onChange={onChange}
                        placeholder={'Select Date'}
                        autoComplete={'date_from'}
                        className={'appearance-none'}
                    />}

                    {types.includes("date") && input.date === 'custom-date' &&
                    <DateInput 
                        label={'Date To'}
                        id={'date_to'}
                        name={'date_to'}
                        type={'date'}
                        value={input.date_to}
                        min={timeOptions.lastYear}
                        max={timeOptions.today}
                        onChange={onChange}
                        placeholder={'Select Date'}
                        autoComplete={'date_to'}
                        className={'appearance-none'}
                    />}
                </div>
                <div className="flex flex-row items-center justify-between mt-9">
                    <div>
                        {Object.keys(filterOptions).length > 0 ? 
                            <PopoverButton
                                type={'button'}
                                onClick={()=>{onReset(); setFilterOptions({}); setInput(preInput); returnInput({});}}
                                disabled={disabled || loading}
                                className={"glade-normal-text-one glade-button-outline-red w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm cursor-pointer"}>
                                {!loading && <span>Reset</span>}
                                {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
                            </PopoverButton>
                        :
                            <PopoverButton
                                type={'button'}
                                onClick={()=>{onCancel()}}
                                disabled={disabled || loading}
                                className={"glade-normal-text-one glade-button-outline-red w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm cursor-pointer"}>
                                {!loading && <span>Cancel</span>}
                                {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
                            </PopoverButton>
                        }
                    </div>
                    <div>
                        <PopoverButton
                            type={'button'}
                            onClick={()=>{ onFilter(input); setFilterOptions(input);}}
                            disabled={disabled || loading}
                            className={"glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm cursor-pointer"}>
                            Filter
                            {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
                        </PopoverButton>
                    </div>
                </div>
            </div>
        </PopoverMain>
    )
}
