import React from 'react'
import { GLADEFINANCE_BLUE_LOGO } from '../../../assets/images/images'

const TeamsLayout = ({children}) => {
  return (
    <div className="min-h-screen" >
      <div className="w-full min-h-screen grid " style={{ minHeight: "112.7vh" }}>
        <div className="min-h-full w-full  flex flex-col items-center">
          <img src={GLADEFINANCE_BLUE_LOGO} alt="" className='py-16' />
          {children}
          <p className='absolute bottom-5'>© Gladefinance Inc. {new Date().getFullYear()}. All rights reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default TeamsLayout