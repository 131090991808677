import { CONVERT_CURRENCY_FAILURE, CONVERT_CURRENCY_LOADING, CONVERT_CURRENCY_SUCCESS, CONVERT_RATE_FAILURE, CONVERT_RATE_LOADING, CONVERT_RATE_SUCCESS } from "./types";

const initialState = {
    isLoadingRate: false,
    isLoadingConversion: false,
    rateData: null,
    rateDetails: null
}
export default function convertReducer(state = initialState, action) {
    switch (action.type) {
        case CONVERT_RATE_LOADING:
            return {
                ...state,
                isLoadingRate: true
            }
        case CONVERT_RATE_SUCCESS:
            return {
                ...state,
                isLoadingRate: false,
                rateDetails: action.payLoad
            };
        case CONVERT_RATE_FAILURE:
            return {
                ...state,
                isLoadingRate: false,
                rateDetails: false
            };

        case CONVERT_CURRENCY_LOADING:
            return {
                ...state,
                isLoadingConversion: true
            }
        case CONVERT_CURRENCY_SUCCESS:
            return {
                ...state,
                isLoadingConversion: false,
                rateData: false
            }
        case CONVERT_CURRENCY_FAILURE:
            return {
                ...state,
                isLoadingConversion: false,
                rateData: false
            }

        default: return state;
    }
}
