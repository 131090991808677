import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormWizard, FormWizardSection } from '../../components/form/FormWizard';
import {CardPlainWithButton,CardPlainWithButtonSection} from "../../components/cards/CardPlainWithButton";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import useBusiness from "../../hooks/useBusiness";
import useSettings from '../../hooks/useSettings';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import DateInput from '../../components/inputs/DateInput';
import ImageInput from '../../components/inputs/ImageInput';
import Button from "../../components/buttons/Button";
import {getBusinessCategories, submitUnRegisteredCompany} from '../../redux/business/businessActions';
import {getComplianceCountries, getComplianceStates, getComplianceLga} from '../../redux/settings/settingsActions';
import { isArray } from "../../helpers/helper";

export default function RegisterBusinessCompany() {

  const FormWizardRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const business = useBusiness();
  const settings = useSettings();

  const [input, setInput] = React.useState({
    company_category: "",
    company_name: "",
    company_name_two: "",
    company_name_three: "",
    company_email: "",
    company_description: "",
    company_objective: "",
    company_address: "",
    company_website: "",
    director_full_name: "",
    director_email:"",
    director_phone: "",
    director_d_of_b:"",
    director_id_card_type:"",
    director_id_card_number:"",
    director_id_card:"",
    director_address: "",
    director_country:'',
    director_state:'',
    director_lga:'',
    share_capital: "",
    per_capital_share: "",
  });

  React.useEffect(() => {
    dispatch(getBusinessCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const onSubmit = () => {
    let country = settings.complianceCountries?.find(country => country.country_id?.toString() === input.director_country);
    let state = settings.complianceStates?.find(state => state.state_id?.toString() === input.director_state);
    let lga = settings.complianceLga?.find(lga => lga.city_id?.toString() === input.director_lga);
    dispatch(submitUnRegisteredCompany({
      ...input,
      director_country:country?.country_name,
      director_state:state?.state_name,
      director_lga:lga?.city_name,
      director_id_card:input.director_id_card[0]
    },()=>history.push('/dashboard/home')));
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    !input.director_country && dispatch(getComplianceCountries());
    input.director_country && dispatch(getComplianceStates({country_id:input.director_country}));
    input.director_state && dispatch(getComplianceLga({state_id:input.director_state}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.director_country, input.director_state]);

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Home"} path={"/dashboard/home"} />
        </div>

        <FormWizard ref={FormWizardRef}>
          <FormWizardSection position={0}>
            <div>
              <CardPlainWithButton>
                <CardPlainWithButtonSection 
                  title={"Register Your Company"}
                  loading={business.isSubmittingBusiness} 
                  buttonTitle={"Next"} 
                  buttonOnClick={() => FormWizardRef.current.next()} 
                  buttonDisabled={business.isSubmittingBusiness}>
                  <div className="my-6 px-14">
                    <div className="space-y-6">

                      <TextInput 
                        label={'Business Name 1st Option'}
                        id={'company_name'}
                        name={'company_name'}
                        type={'text'}
                        value={input.company_name}
                        onChange={onChange}
                        placeholder={'Enter business name 1st option'}
                        autoComplete={'company_name'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Business Name 2nd Option '}
                        id={'company_name_two'}
                        name={'company_name_two'}
                        type={'text'}
                        value={input.company_name_two}
                        onChange={onChange}
                        placeholder={'Enter business name 2nd option'}
                        autoComplete={'company_name_two'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Business Name 3rd Option'}
                        id={'company_name_three'}
                        name={'company_name_three'}
                        type={'text'}
                        value={input.company_name_three}
                        onChange={onChange}
                        placeholder={'Enter business name 3rd option'}
                        autoComplete={'company_name_three'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Business Email'}
                        id={'company_email'}
                        name={'company_email'}
                        type={'text'}
                        value={input.company_email}
                        onChange={onChange}
                        placeholder={'Enter registered business email'}
                        autoComplete={'company_email'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Business Address'}
                        id={'company_address'}
                        name={'company_address'}
                        type={'text'}
                        value={input.company_address}
                        onChange={onChange}
                        placeholder={'Enter business address'}
                        autoComplete={'company_address'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Share Capital'}
                        id={'share_capital'}
                        name={'share_capital'}
                        type={'text'}
                        value={input.share_capital}
                        onChange={onChange}
                        placeholder={'Enter business share capital'}
                        autoComplete={'share_capital'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Per Capital Share'}
                        id={'per_capital_share'}
                        name={'per_capital_share'}
                        type={'text'}
                        value={input.per_capital_share}
                        onChange={onChange}
                        placeholder={'Enter business per capital share'}
                        autoComplete={'per_capital_share'}
                        className={'appearance-none'}
                      />

                      <SelectInput 
                        label={'Select Business Industry'}
                        id={'company_category'}
                        name={'company_category'}
                        value={input.company_category ?? ''}
                        onChange={onChange}
                        autoComplete={'company_category'}
                        className={'appearance-none'}>
                        <option value={''} disabled={true}>Select Industry</option>
                        {isArray(business?.businessCategories) && business?.businessCategories.map((item, key)=>{
                          return (
                            <option key={key} value={item}>{item}</option>
                          );
                        })}
                      </SelectInput>

                      <TextAreaInput 
                        label={'Business Description'}
                        id={'company_description'}
                        name={'company_description'}
                        rows={3}
                        type={'text'}
                        value={input.company_description}
                        onChange={onChange}
                        placeholder={'What does your business do?'}
                        autoComplete={'company_description'}
                        className={'appearance-none'}
                      />

                      <TextAreaInput 
                        label={'Business Objective'}
                        id={'company_objective'}
                        name={'company_objective'}
                        rows={2}
                        type={'text'}
                        value={input.company_objective}
                        onChange={onChange}
                        placeholder={'What is the objective of this business?'}
                        autoComplete={'company_objective'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Business Website'}
                        id={'company_website'}
                        name={'company_website'}
                        type={'text'}
                        value={input.company_website}
                        onChange={onChange}
                        placeholder={'Enter business website'}
                        autoComplete={'company_website'}
                        className={'appearance-none'}
                      />

                    </div>
                  </div>
                </CardPlainWithButtonSection>
              </CardPlainWithButton>
            </div>
          </FormWizardSection>

          <FormWizardSection position={1}>
            <div>
              <CardPlainWithButton>
                <CardPlainWithButtonSection 
                  title={"Director’s Information"}
                  loading={business.isSubmittingBusiness} 
                  buttonSection={
                    <div className="flex flex-row space-x-3 mt-6">
                      <Button.Blue
                        title={"Back"}
                        onClick={() => FormWizardRef.current.prev()}
                        disabled={business.isSubmittingBusiness}
                      />
                      <Button.Blue
                        title={"Submit"}
                        onClick={() => onSubmit()}
                        disabled={business.isSubmittingBusiness}
                      />
                    </div>
                  }>
                  <div className="my-6 px-14">
                    <div className="space-y-6">

                      <TextInput 
                        label={'Director’s Full Name'}
                        id={'director_full_name'}
                        name={'director_full_name'}
                        type={'text'}
                        value={input.director_full_name}
                        onChange={onChange}
                        placeholder={'Enter director’s full name'}
                        autoComplete={'director_full_name'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Email Address'}
                        id={'director_email'}
                        name={'director_email'}
                        type={'text'}
                        value={input.director_email}
                        onChange={onChange}
                        placeholder={'Enter email address'}
                        autoComplete={'director_email'}
                        className={'appearance-none'}
                      />

                      <TextInput 
                        label={'Phone Number'}
                        id={'director_phone'}
                        name={'director_phone'}
                        type={'text'}
                        value={input.director_phone}
                        onChange={onChange}
                        placeholder={'Enter phone number'}
                        autoComplete={'director_phone'}
                        className={'appearance-none'}
                      />

                      <DateInput 
                        label={'Date of Birth'}
                        id={'director_d_of_b'}
                        name={'director_d_of_b'}
                        type={'date'}
                        value={input.director_d_of_b}
                        onChange={onChange}
                        placeholder={'Select Date of Birth'}
                        autoComplete={'director_d_of_b'}
                        className={'appearance-none'}
                      />

                      <SelectInput 
                        label={'Identity Card Type'}
                        id={"director_id_card_type"}
                        name={'director_id_card_type'}
                        value={input.director_id_card_type ?? ''}
                        onChange={onChange}
                        autoComplete={"director_id_card_type"}
                        className={"appearance-none"}
                      >
                          <option value={''} disabled={true}>Select ID Type</option>
                          <option value={'drivers license'}>Drivers License</option>
                          <option value={'international passport'}>International Passport</option>
                          <option value={'voters card'}>Voters Card</option>
                          <option value={'national identity card'}>National Identity Card</option>
                      </SelectInput>

                      <TextInput 
                        label={'Identity Card Number'}
                        id={"director_id_card_number"}
                        name={'director_id_card_number'}
                        value={input.director_id_card_number ?? ''}
                        onChange={onChange}
                        placeholder={'Enter identity card number'}
                        autoComplete={"director_id_card_number"}
                        className={"appearance-none"}
                      />

                      <ImageInput 
                        label={'Upload Identity Card'} 
                        id={'director_id_card'} 
                        name={'director_id_card'} 
                        multiple={false} 
                        accept={".png, .jpeg, .jpg"}
                        returnInput={(value)=>setInput({...input,director_id_card:value})}
                      />

                      <TextInput 
                        label={'Residential Address'}
                        id={'director_address'}
                        name={'director_address'}
                        type={'text'}
                        value={input.director_address}
                        onChange={onChange}
                        placeholder={'Enter residential address'}
                        autoComplete={'director_address'}
                        className={'appearance-none'}
                      />

                      <SelectInput 
                        label={'Country'}
                        id={"director_country"}
                        name={'director_country'}
                        value={input.director_country ?? ''}
                        onChange={onChange}
                        autoComplete={"director_country"}
                        className={"appearance-none"}
                      >
                        <option value={''} disabled={true}>Select A Country</option>
                        {isArray(settings?.complianceCountries) && settings.complianceCountries?.map((country,index)=>(
                          <option key={index} value={country.country_id}>{country.country_name}</option>
                        ))}
                      </SelectInput>

                      <SelectInput 
                        label={'State'}
                        id={"director_state"}
                        name={'director_state'}
                        value={input.director_state ?? ''}
                        onChange={onChange}
                        autoComplete={"director_state"}
                        className={"appearance-none"}
                      >
                        <option value={''} disabled={true}>Select A State</option>
                        {isArray(settings?.complianceStates) && settings.complianceStates?.map((state,index)=>(
                          <option key={index} value={state.state_id}>{state.state_name}</option>
                        ))}
                      </SelectInput>

                      <SelectInput 
                        label={'Local Government Area'}
                        id={"director_lga"}
                        name={'director_lga'}
                        value={input.director_lga ?? ''}
                        onChange={onChange}
                        autoComplete={"director_lga"}
                        className={"appearance-none"}
                      >
                        <option value={''} disabled={true}>Select A Local Government Area</option>
                        {isArray(settings?.complianceLga) && settings.complianceLga?.map((lga,index)=>(
                          <option key={index} value={lga.city_id}>{lga.city_name}</option>
                        ))}
                      </SelectInput>

                    </div>
                  </div>
                </CardPlainWithButtonSection>
              </CardPlainWithButton>
            </div>
          </FormWizardSection>
        </FormWizard>

      </div>
    </div>
  );
}