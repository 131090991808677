// Application types
export const LOADING_BILLS = 'LOADING_BILLS';
export const RELOAD_BILLS = 'RELOAD_BILLS';

export const GET_ALL_BILLS = "GET_ALL_BILLS";
export const GET_ALL_BILL_TYPES = "GET_ALL_BILL_TYPES";
export const GET_BILLERS = "GET_BILLER";
export const GET_BILLER_ITEMS = "GET_BILLER_ITEMS";
export const RESOLVE_METER_DETAILS = "RESOLVE_METER_DETAILS";
export const RESOLVE_INTERNET_ACCOUNT_DETAILS = "RESOLVE_INTERNET_ACCOUNT_DETAILS";
export const RESOLVE_CABLE_TV_DETAILS = "RESOLVE_CABLE_TV_DETAILS";
export const BUY_AIRTIME = "BUY_AIRTIME";
export const BUY_ELECTRICITY = "BUY_ELECTRICITY";
export const BUY_CABLE_TV = "BUY_CABLE_TV";
export const BUY_INTERNET = "BUY_INTERNET";
export const GET_BILLS_BENEFACTRICES = "GET_BILLS_BENEFACTRICES";