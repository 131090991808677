import React, { useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import CreateCards from "./CreateCards";
import { filterVirtualCards } from "../../redux/virtual-cards/virtualCardActions";
import { useDispatch } from "react-redux";
import { isEmptyArray } from "../../helpers/helper";
import useVirtualCards from "../../hooks/useVirtualCards";
import VirtualCardList from "./VirtualCardList";

export default function VirtualCards() {
  const dispatch = useDispatch();
  const { virtualCardFilterResults, isLoading } = useVirtualCards();
  const [selectedCard, setSelectedCard] = useState("");

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      filterVirtualCards({
        currency: selectedCard,
        paginate: 1,
        current_page: currentPage,
        per_page: 10,
      })
    );
  }, [selectedCard, currentPage]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [currentPage, virtualCardFilterResults]);

  let query = useQuery();

  return (
    <div className="p-3">
      {/* LOADING STATE */}
      {isLoading && (
        <VirtualCardList
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />
      )}
      {/* NO EXISTING CARDS STATE */}
      {!isLoading && isEmptyArray(virtualCardFilterResults?.data) && (
        <CreateCards />
      )}
      {/* EXISTING CARDS STATE */}
      {!isLoading && !isEmptyArray(virtualCardFilterResults?.data) && (
        <VirtualCardList
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}
