import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ADD_FILE, ADD_PERSON } from '../../assets/images/images';
import PlainModal from '../../components/modals/PlainModal';
import useTeam from '../../hooks/useTeam';

export default React.forwardRef(function PayrollStaffAddModal(props,ref) {

    const history = useHistory();
    const {permissions} = useTeam();
    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));



    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={
              <h3 className='text-center glade-heading-three glade-home-dark'>Add Staff</h3>
            }
            modelContentSection={
                <div className="flex flex-col mb-6">
                <div className="flex flex-col px-9 pt-9 space-y-9">
                  <div className='rounded-md border p-6 flex cursor-pointer' onClick={()=>history.push(!permissions?.includes("payroll") ? '/dashboard/payroll/staff/create/single' : '/teams/payroll/staff/create/single') }>
                    <div className='flex space-x-6 items-center'>
                      <img src={ADD_PERSON} alt="add person" />
                      <div className='flex flex-col '>
                        <h3 className='glade-heading-two-bold glade-home-dark text-left'>Single Staff</h3>
                        <h5 className='glade-grey-cool'>Add a single staff to the payroll</h5>
                      </div>
                    </div>
                  </div>
                  <div className='rounded-md border p-6 flex cursor-pointer' onClick={()=>history.push(!permissions?.includes("payroll") ? '/dashboard/payroll/staff/create/bulk/upload' : '/teams/payroll/staff/create/bulk/upload')}>
                    <div className='flex space-x-6 items-center'>
                      <img src={ADD_FILE} alt="add person" />
                      <div className='flex flex-col '>
                        <h3 className='glade-heading-two-bold glade-home-dark text-left'>Bulk Staff</h3>
                        <h5 className='glade-grey-cool'>Upload a .csv or .xlsx file</h5>
                      </div>
                    </div>
                  </div>     
                </div>
                </div>
            }
        />
    )
})
