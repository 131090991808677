import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CgClose } from "react-icons/cg";

export default React.forwardRef(function PlainModal({
  visibility = false,
  primaryButtonTitle,
  primaryButton,
  primaryButtonStyle,
  primaryButtonClassName,
  modalTitle,
  modalTitleSection,
  modelContent,
  modelContentSection,
  disableClose = false,
  disableOpen = false,
  onOpen=()=>{},
  onClose=()=>{}
},ref) {

  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    toggle: () => toggleModal(),
    // anotherMethodOfChoice : () => {}
  }));

  let [isOpen, setIsOpen] = React.useState(visibility);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function toggleModal() {
    if (isOpen) {
      !disableClose && setIsOpen(false);
      !disableClose && onClose();
    }

    if (!isOpen) {
      !disableOpen && setIsOpen(true);
      !disableOpen && onOpen();
    }
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ?
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        :
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={"glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm "+primaryButtonClassName}>
            {primaryButtonTitle ?? "Title"}
          </button>
        }
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay
                className="fixed inset-0"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-lg py-3 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-md">
                {modalTitleSection ?? (
                  <div className="flex flex-row px-6 pb-3 border-b-2 border-solid border-gray-100">
                    <Dialog.Title
                      as="h3"
                      className="w-full glade-subtitle-two glade-black text-left"
                    >
                      {modalTitle ?? "Modal Title"}
                    </Dialog.Title>
                    <button
                      className="glade-black"
                      onClick={closeModal}
                    >
                      <CgClose />
                    </button>
                  </div>
                )}

                {modelContentSection ?? (
                  <div className="mt-2 px-6">
                    <p className="text-sm text-gray-500">
                      <Dialog.Description>
                        {modelContent ?? "Modal Content"}
                      </Dialog.Description>
                    </p>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
})
