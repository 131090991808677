import React from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import { createInvoiceCustomer } from '../../redux/invoice/invoiceActions';
import TextInput from '../../components/inputs/TextInput';

export default React.forwardRef(function AddNewCustomerModal(props,ref) {

    const dispatch = useDispatch();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    const [input, setInput] = React.useState({
        name:'',
        email:'',
        phone:'',
        address:''
    });

    const createCustomer = () => {
        dispatch(createInvoiceCustomer({
            name:input.name,
            email:input.email,
            phone:input.phone,
            address:input.address,
        }));
        plainModalRef.current.close();
    }

    const onChange = (e) => {
        setInput({...input,[e.target.name]:e.target.value});
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Add New Customer'}
            modelContentSection={
                <div className="flex flex-col space-y-9">

                    <div className="flex flex-col px-9 pt-9 text-left space-y-9">
                        <TextInput 
                            label={'Name'}
                            id={"name"}
                            name={'name'}
                            type={'text'}
                            value={input.name ?? ''}
                            onChange={onChange}
                            placeholder={'Enter customer name'}
                            autoComplete={"name"}
                            className={"appearance-none"}
                        />

                        <TextInput 
                            label={'Email'}
                            id={"email"}
                            name={'email'}
                            type={'email'}
                            value={input.email ?? ''}
                            onChange={onChange}
                            placeholder={'Enter customer email'}
                            autoComplete={"email"}
                            className={"appearance-none"}
                        />

                        <TextInput 
                            label={'Phone number'}
                            id={"phone"}
                            name={'phone'}
                            type={'tel'}
                            value={input.phone ?? ''}
                            onChange={onChange}
                            placeholder={'Enter customer phone number'}
                            autoComplete={"phone"}
                            className={"appearance-none"}
                        />
                    </div>

                    <div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
                        <div>
                            <Button.Transparent onClick={()=>plainModalRef.current.close()} title="Cancel" />
                        </div>
                        <div>
                            <Button.Blue disabled={!input.name || !input.email || !input.phone} onClick={()=>createCustomer()} title="Add Customer" />
                        </div>
                    </div>
                </div>
            }
        />
    )
})
