import React from 'react';
import Button from '../../components/buttons/Button';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import bankcard from '../../assets/images/bank-card-line.png'

export default function CreateCards() {
  const history = useHistory()
  return (
    <React.Fragment>
      <div className='grid grid-cols-5 w-full gap-6 mt-10 ml-8'>
        <div className="flex justify-center items-center  text-center flex-col border col-span-3 py-40">
          <div className='pb-3 pt-10'>
            <img src={bankcard} className="w-10" alt="create_card" />
          </div>
          <div className='pb-10'>
            <p className='text-glade-gray-500'>Own virtual cards and make online transactions faster</p>
          </div>
          <div className='pb-20'>
            <Button.Blue
              title={'Create Card'}
              onClick={() => history.push('/dashboard/virtual-cards/create')}
            />
          </div>
        </div>
        <div className='ml-16 whitespace-nowrap col-span-2'>
          <h1 className='text-2xl font-bold pb-6'>Helpful Links</h1>
          <div className='text-lg'>
            <Link className="  glade-blue underline " to={'/dashboard/cards'}>
              <p className='pb-2'> How virtual cards works</p>
            </Link>
            <Link className="  glade-blue underline" to={'/dashboard/cards'}>
              <p className=''>Need help? Contact us</p>
            </Link>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}