import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import Button from '../../components/buttons/Button';
import PipedButton from '../../components/buttons/PipedButton';
import {isArray, isEmpty, pagination, statusEnum} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import TextIndicator from '../../components/indicators/TextIndicator';
import PayrollStaffCreateBulkFileUploadReviewModal from './PayrollStaffCreateBulkFileUploadReviewModal';
import {rememberBulkCreateStaffList, createPayrollStaffBulk} from '../../redux/payroll/payrollActions';
import {processUploadedBulkCreateStaffCSV} from './UploadBulkCreateStaffAsCSV';
import usePayroll from '../../hooks/usePayroll';
import useUser from '../../hooks/useUser';
import useTeam from '../../hooks/useTeam';

export default function PayrollStaffCreateBulkFileUploadReview() {

  const hiddenFileUploadButtonRef = React.useRef(null);
  const fileUploadBulkCreateStaffReviewModalRef = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const payroll = usePayroll();
  const {permissions} = useTeam();

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(payroll.bulkCreateStaffList ?? [], 10, currentPage));
    // eslint-disable-next-line 
  }, [currentPage, payroll.bulkCreateStaffList]);

  const onsubmit = () => {
    let data = payroll.bulkCreateStaffList.map((item) => {
      return {
        name: item.staff_name,
        email: item.staff_email,
        staff_position: item.staff_position,
        staff_number: item.staff_number,
        phone: item.staff_phone_number,
        account_number: item.account_number,
        account_name: item.staff_name, // recommend to fetch from bank account details
        bank_name: item.bank_name,
        value: item.amount,
        bank_code: item.bank_code,
        currency: "NGN",
        individual_type: "staff",
        business_uuid: user?.isBusiness ? user?.selectedBusinessOrPersonalAccount?.business_uuid : user?.userInfo?.uuid,
      }
    });
    dispatch(createPayrollStaffBulk(data,()=>history.push(!permissions?.includes("payroll") ? '/dashboard/payroll/staff' : "/teams/payroll/staff")));
  }

  const onReplaceFile = async (e) => {
    if (e.target.files[0]) {
      let data = await processUploadedBulkCreateStaffCSV(e.target.files[0]);
      if (data?.length > 0) {
        dispatch(rememberBulkCreateStaffList(data));
      }
    }
  }

  const onDelete = (data) => {
    let newBulkTransferList = payroll.bulkCreateStaffList.filter(item => item.id !== data.id);
    dispatch(rememberBulkCreateStaffList(newBulkTransferList));
  }

  const renderBulkTransferList = (data) => {
    if (!data || !isArray(data)) {
      return null;
    }

    return data.map((item,key)=>{
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">{item.staff_name}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">{item.staff_number}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">{item.staff_position}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">{item.amount}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two lowercase">{item.account_number}<br/>{item.bank_name}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <TextIndicator type={statusEnum[item.status??'pending']} title={item.status??'pending'} />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <PipedButton 
              primaryButtonTitle="Edit" 
              primaryButtonOnClick={()=>{fileUploadBulkCreateStaffReviewModalRef?.current?.open(item)}}
              secondaryButtonTitle="Delete" 
              secondaryButtonOnClick={()=>onDelete(item)}
              secondaryButtonClassName="glade-red"
            />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      )
    });
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={'Payroll Staff'} path={!permissions?.includes("payroll") ? '/dashboard/payroll/staff' : "/teams/payroll/staff"}/>
        </div>

        <CardTableHeaderComplex 
          title={`Showing ${tableData.totalItems} records`}
          rightButton={
            <div className="flex flex-row">
              <Button.Blue title={'Add Staff'} className="whitespace-nowrap mx-3" onClick={()=>onsubmit()} loading={payroll.isLoadingPayroll} disabled={payroll.bulkCreateStaffList.length < 1 ? true : false} />
              <Button.Transparent title={'Replace CSV file'} className="whitespace-nowrap mx-3" onClick={()=>hiddenFileUploadButtonRef.current.click()}  disabled={payroll.bulkCreateStaffList.length < 1 ? true : false} />
              <input ref={hiddenFileUploadButtonRef} type="file" id="file" className="hidden" onChange={onReplaceFile} />
            </div>
          }
        />

        {isEmpty(tableData.data) || payroll.isLoadingPayroll?
          <React.Fragment>
            <EmptyTable column={['','Staff Name','Staff Number','Staff Position','Staff Salary','Staff Bank','Status','']} loading={payroll.isLoadingPayroll} />
          </React.Fragment>
        :
          <React.Fragment>
            <CardTableBody column={['','Staff Name','Staff Number','Staff Position','Staff Salary','Staff Bank','Status','']}>
              {renderBulkTransferList(tableData.data)}
            </CardTableBody>
          </React.Fragment>
        }

        <CardTableFooter 
          empty={isEmpty(tableData.data)}
          loading={payroll.isLoadingPayroll}
          pageNumber={currentPage} 
          totalPages={tableData.totalPages}
          prevOnClick={()=>setCurrentPage(tableData.previousPage)}
          nextOnClick={()=>setCurrentPage(tableData.nextPage)}
          thereIsPreviousPage={tableData.thereIsPreviousPage}
          thereIsNextPage={tableData.thereIsNextPage}
        />

        <PayrollStaffCreateBulkFileUploadReviewModal ref={fileUploadBulkCreateStaffReviewModalRef} />
      </div>
    </div>
  )
}