import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const userComplianceValidation = (payLoad, silently = false) => {

  let data = {
    personal_id_type: payLoad.personal_id_type,
    personal_id_number: payLoad.personal_id_number,
    personal_id_card: payLoad.personal_id_card[0],
    personal_address: payLoad.personal_address,
    compliance_state: payLoad.compliance_state,
    compliance_lga: payLoad.compliance_lga,
    personal_proof_of_address: payLoad.personal_proof_of_address[0],
  }
  let rules = {
    personal_id_type: 'required',
    personal_id_number: 'required|minSize:5',
    personal_id_card: (payLoad) => { return payLoad ? true : false; },
    personal_address: 'required|minSize:8|maxSize:300',
    compliance_state: 'required',
    compliance_lga: 'required',
    personal_proof_of_address: (payLoad) => { return payLoad ? true : false; },
  }
  let messages = {
    personal_id_type: {
      required: 'Id card type is required',
    },
    personal_id_number: {
      required: 'Id card number is required',
      minSize: 'Id card number is too short',
    },
    personal_id_card: {
      custom: 'An id card is required'
    },
    personal_address: {
      required: 'Address is required',
      minSize: 'Address is too short',
      maxSize: 'Address is too long'
    },
    compliance_state: {
      required: 'Select a state',
    },
    compliance_lga: {
      required: 'Select a local government area',
    },
    personal_proof_of_address: {
      custom: 'A proof of address is required'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}

export const personalComplianceValidation = (payLoad, silently = false) => {
  let data = {
    personal_address: payLoad.address,
    compliance_state: payLoad.compliance_state,
    compliance_lga: payLoad.compliance_lga,
    personal_proof_of_address: payLoad.personal_proof_of_address[0],
    id_type: payLoad.id_type,
    id_number: payLoad.id_number,
    id_upload: payLoad.id_upload[0]
  }
  let rules = {
    personal_address: 'required|minSize:8|maxSize:300',
    compliance_state: 'required',
    compliance_lga: 'required',
    id_type: 'required',
    id_number: 'required|minSize:8|maxSize:300',
    personal_proof_of_address: (payLoad) => { return payLoad ? true : false; },
    id_upload: (payLoad) => { return payLoad ? true : false; },
  }
  let messages = {
    personal_address: {
      required: 'Address is required',
      minSize: 'Address is too short',
      maxSize: 'Address is too long'
    },
    compliance_state: {
      required: 'State is a required field',
    },
    compliance_lga: {
      required: 'LGA is a required field',
    },
    id_type: {
      required: 'ID Type is a required field',
    },
    id_number: {
      required: 'ID Number is a required field',
      minSize: 'ID Number is too short',
      maxSize: 'ID Number is too long'
    },
    id_upload: {
      custom: 'Id upload is required',
    },
    personal_proof_of_address: {
      custom: 'A proof of address is required'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}

export const businessComplianceValidation = (payLoad, silently = false) => {

  let data = {
    business_name: payLoad.business_name,
    business_id_type: payLoad.business_id_type,
    business_id_number: payLoad.business_id_number,
    business_id_card: payLoad.business_id_card[0],
    business_address: payLoad.business_address,
    compliance_state: payLoad.compliance_state,
    compliance_lga: payLoad.compliance_lga,
    business_proof_of_address: payLoad.business_proof_of_address[0],
    business_tin_number: payLoad.business_tin_number,
    business_tin_certificate: payLoad.business_tin_certificate[0],
    business_rc_or_bn_number: payLoad.business_rc_or_bn_number,
    business_cac_certificate: payLoad.business_cac_certificate[0],
    business_director_form: payLoad.business_director_form[0],
    business_memorandum: payLoad.business_memorandum[0],
  }
  let rules = {
    business_name: 'required|minSize:3|maxSize:300',
    business_id_type: 'required',
    business_id_number: 'required|minSize:5',
    business_id_card: (payLoad) => { return payLoad ? true : false; },
    business_address: 'required|minSize:9|maxSize:300',
    compliance_state: 'required',
    compliance_lga: 'required',
    business_proof_of_address: (payLoad) => { return payLoad ? true : false; },
    business_tin_number: 'required|minSize:6|maxSize:50',
    business_tin_certificate: (payLoad) => { return payLoad ? true : false; },
    business_rc_or_bn_number: 'required|minSize:6|maxSize:50',
    business_cac_certificate: (payLoad) => { return payLoad ? true : false; },
    business_director_form: (payLoad) => { return payLoad ? true : false; },
    business_memorandum: (payLoad) => { return payLoad ? true : false; },
  }
  let messages = {
    business_name: {
      required: 'Business name is required',
      minSize: 'Business name is too short',
      maxSize: 'Business name is too long'
    },
    business_id_type: {
      required: 'Id card type is required',
    },
    business_id_number: {
      required: 'Id card number is required',
      minSize: 'Id card number is too short',
    },
    business_id_card: {
      custom: 'An id card is required'
    },
    business_address: {
      required: 'Address is required',
      minSize: 'Address is too short',
      maxSize: 'Address is too long'
    },
    compliance_state: {
      required: 'Select a state',
    },
    compliance_lga: {
      required: 'Select a local government area',
    },
    business_proof_of_address: {
      custom: 'A proof of address is required'
    },
    business_tin_number: {
      required: 'Tin number is required',
      minSize: 'Tin number is too short',
      maxSize: 'Tin number is too long'
    },
    business_tin_certificate: {
      custom: 'A tin certificate is required'
    },
    business_rc_or_bn_number: {
      required: 'Rc number is required',
      minSize: 'Rc number is too short',
      maxSize: 'Rc number is too long'
    },
    business_cac_certificate: {
      custom: 'A CAC certificate is required'
    },
    business_director_form: {
      custom: 'A director\'s form is required'
    },
    business_memorandum: {
      custom: 'A memorandum is required'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}

export const businessInformationValidation = (payLoad, silently = false) => {

  let data = {
    business_type: payLoad.business_type,
    business_description: payLoad.business_description,
    industry: payLoad.industry,
    secondary_phone: String(Number(payLoad.secondary_phone)),
    business_address: payLoad.business_address,
    business_state: payLoad.business_state,
    business_lga: payLoad.business_lga,
    business_proof_of_address: payLoad.business_proof_of_address[0],
  }
  let rules = {
    business_type: 'required',
    industry: 'required',
    business_description: 'required|minSize:9|maxSize:300',
    business_address: 'required|minSize:9|maxSize:300',
    business_state: 'required',
    business_lga: 'required',
    business_proof_of_address: (payLoad) => { return payLoad ? true : false; },
    secondary_phone: 'required|minSize:9|maxSize:11',

  }
  let messages = {
    business_type: {
      required: 'Business type is required',
    },
    business_address: {
      required: 'Address is required',
      minSize: 'Address is too short',
      maxSize: 'Address is too long'
    },
    business_description: {
      required: 'Add business description',
      minSize: 'Description is too short',
      // maxSize:'Desc is too long'
    },
    business_state: {
      required: 'Select a state',
    },
    business_lga: {
      required: 'Select a local government area',
    },
    business_proof_of_address: {
      custom: 'A proof of address is required'
    },
    industry: {
      required: "Industry is required"
    },
    secondary_phone: {
      required: 'A phone number is required',
      phone: 'Phone number is not valid',
      minSize: 'Phone number is too short',
      maxSize: 'Phone number is too long'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    return !silently;
  });

  return Object.keys(result);
}

export const businessInformationNonNigeriaValidation = (payLoad, silently = false) => {

  let data = {
    business_type: payLoad.business_type,
    // business_description: payLoad.business_description,
    industry: payLoad.industry,
    secondary_phone: String(Number(payLoad.secondary_phone)),
    // company_website: payLoad.company_website,
    business_address: payLoad.business_address,
    business_state: payLoad.business_state,
    // address_line2: payLoad.address_line2,
    id_number: payLoad.id_number,
    postal_code: payLoad.postal_code
  }
  let rules = {
    business_type: 'required',
    industry: 'required',
    // business_description: 'required|minSize:9|maxSize:300',
    business_address: 'required|minSize:9|maxSize:300',
    business_state: 'required',
    postal_code: 'required|minSize:5|maxSize:15',
    id_number: 'required|minSize:5',
    // business_proof_of_address: (payLoad) => { return payLoad ? true : false; },
    secondary_phone: 'required|minSize:7|maxSize:15',

  }
  let messages = {
    business_type: {
      required: 'Company type is required',
    },
    business_address: {
      required: 'Business Address is required',
      minSize: 'Business Address is too short',
      maxSize: 'Business Address is too long'
    },
    // business_description: {
    //   required: 'Add business description',
    //   minSize: 'Description is too short',
    //   // maxSize:'Desc is too long'
    // },
    business_state: {
      required: 'Select a state',
    },
    // business_lga: {
    //   required: 'Select a local government area',
    // },
    // business_proof_of_address: {
    //   custom: 'A proof of address is required'
    // },
    industry: {
      required: "Industry is required"
    },
    secondary_phone: {
      required: 'A phone number is required',
      phone: 'Phone number is not valid',
      minSize: 'Phone number is too short',
      maxSize: 'Phone number is too long'
    },
    postal_code: {
      required: 'Postal Code is required',
      minSize: 'Postal Code is too short',
      maxSize: 'Postal Code is too long'
    },
    id_number: {
      required: 'ID/Ein Number is required',
      minSize: 'ID/Ein Number cannot be less than 6 digits'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }


  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    return !silently;
  });

  // return Object.keys(result);
  return Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && msg.toString();
  });

}

export const businessDocumentValidation = (payLoad, silently = false) => {

  let data
  let rules
  if (payLoad?.business_type?.toLowerCase() === "sole propreitorship") {
    data = {
      tin: payLoad?.tin,
      bn_number: payLoad?.bn_number,
      tin_certificate: payLoad?.tin_certificate[0],
      business_name_certificate: payLoad?.business_name_certificate[0],
      business_directors_document: payLoad?.business_directors_document[0],
    }
    rules = {
      tin: 'required',
      tin_certificate: (payLoad) => { return payLoad ? true : false; },
      bn_number: 'required|minSize:8|maxSize:12',
      business_name_certificate: (payLoad) => { return payLoad ? true : false; },
      business_directors_document: (payLoad) => { return payLoad ? true : false; },
    }


  }

  if (payLoad?.business_type?.toLowerCase() === "ngo") {
    data = {
      tin: payLoad?.tin,
      rc_number: payLoad?.rc_number,
      tin_certificate: payLoad?.tin_certificate[0],
      certificate_of_incorporation: payLoad?.certificate_of_incorporation[0],
      business_directors_document: payLoad?.business_directors_document[0],
      scuml: payLoad?.scuml[0],
      cac_it_form: payLoad?.cac_it_form[0],
    }
    rules = {
      tin: 'required',
      tin_certificate: (payLoad) => { return payLoad ? true : false; },
      scuml: (payLoad) => { return payLoad ? true : false; },
      cac_it_form: (payLoad) => { return payLoad ? true : false; },
      business_directors_document: (payLoad) => { return payLoad ? true : false; },
      certificate_of_incorporation: (payLoad) => { return payLoad ? true : false; },
      rc_number: 'required|minSize:4|maxSize:14',
    }
  }

  if (payLoad?.business_type?.toLowerCase() === "partnership") {
    data = {
      tin: payLoad?.tin,
      rc_number: payLoad?.rc_number,
      tin_certificate: payLoad?.tin_certificate[0],
      certificate_of_incorporation: payLoad?.certificate_of_incorporation[0],
      business_directors_document: payLoad?.business_directors_document[0],
    }
    rules = {
      tin: 'required',
      tin_certificate: (payLoad) => { return payLoad ? true : false; },
      business_directors_document: (payLoad) => { return payLoad ? true : false; },
      certificate_of_incorporation: (payLoad) => { return payLoad ? true : false; },
      rc_number: 'required|minSize:4|maxSize:14'
    }
  }

  if (payLoad?.business_type?.toLowerCase() === "llc") {
    data = {
      tin: payLoad?.tin,
      rc_number: payLoad?.rc_number,
      tin_certificate: payLoad?.tin_certificate[0],
      certificate_of_incorporation: payLoad?.certificate_of_incorporation[0],
      business_directors_document: payLoad?.business_directors_document[0],
    }
    rules = {
      tin: 'required',
      tin_certificate: (payLoad) => { return payLoad ? true : false; },
      rc_number: 'required|minSize:4|maxSize:14',
      business_directors_document: (payLoad) => { return payLoad ? true : false; },
      certificate_of_incorporation: (payLoad) => { return payLoad ? true : false; },

    }
  }
  let messages = {
    rc_number: {
      required: 'RC Number is required',
      minSize: 'RC Number is too short',
      maxSize: 'RC Number is too long'
    },
    bn_number: {
      required: 'BN Number is required',
      minSize: 'BN Number is too short',
      maxSize: 'BN Number is too long'
    },
    tin: {
      required: 'TIN is required',
      minSize: 'TIN is too short',
      maxSize: 'TIN is too long'
    },
    tin_certificate: {
      custom: 'This field is required'
    },
    business_name_certificate: {
      custom: 'This field is required'
    },
    certificate_of_incorporation: {
      custom: 'This field is required'
    },
    business_directors_document: {
      custom: 'This field is required'
    },
    scuml: {
      custom: 'This field is required'
    },
    cac_it_form: {
      custom: 'This field is required'
    },
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    return !silently;
  });

  return Object.keys(result);
}

export const businessDocumentNonNigeriaValidation = (payLoad, silently = false) => {

  let data
  let rules
  if (payLoad) {
    data = {
      proof_of_address: payLoad?.business_proof_of_address[0],
      certificate_of_incorporation: payLoad?.certificate_of_incorporation[0],
      // other_supporting_documents: payLoad?.other_supporting_documents[0],
      business_description: payLoad?.business_description,
      have_political_foreign_office: payLoad?.have_political_foreign_office,
      hear_about_us: payLoad?.hear_about_us,
      reg_number: payLoad?.reg_number
    }
    rules = {
      business_description: 'required|minSize:10',
      // other_supporting_documents: (payLoad) => { return payLoad ? true : false; },
      hear_about_us: 'required',
      have_political_foreign_office: 'required',
      proof_of_address: (payLoad) => { return payLoad ? true : false; },
      certificate_of_incorporation: (payLoad) => { return payLoad ? true : false; },
      reg_number: 'required|minSize:3'
    }
  }

  let messages = {
    business_description: {
      required: 'Business description is required',
      minSize: 'Business description is too short'
    },
    // other_supporting_documents: {
    //   custom: 'Other Supporting documents is required'
    // },
    proof_of_address: {
      custom: 'Business proof of address is required'
    },
    certificate_of_incorporation: {
      custom: 'Certificate of incorporation is required'
    },
    hear_about_us: {
      required: 'How did you hear about us is required'
    },
    reg_number: {
      required: 'Registration Number is required',
      minSize: 'Registration Number cannot be less than 4 digits'
    }
    // have_political_foreign_office: {
    //   required: ''
    // }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    return !silently;
  });

  return Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && msg.toString();
  });
}


export const addOwnerInformationValidation = (type, payLoad, silently = false) => {

  let data = {
    first_name: payLoad.first_name,
    last_name: payLoad.last_name,
    phone: String(Number(payLoad.phone)),
    address: payLoad.address,
    email: payLoad.email,
    id_card_type: payLoad.id_card_type,
    id_card_number: payLoad.id_card_number,
    id_card: payLoad.id_card[0],
  }
  if (type === 'owner') {
    let additional = {
      lga: payLoad.lga,
      state: payLoad.state,
    }
    data = { ...data, ...additional }
  } else {
    let bvn = { bvn: payLoad.bvn }
    data = { ...data, ...bvn }
  }
  let rules = {
    first_name: 'required|minSize:1|maxSize:30',
    last_name: 'required|minSize:1|maxSize:30',
    phone: 'required|minSize:9|maxSize:11',
    address: 'required|minSize:9|maxSize:300',
    email: 'required|email',
    bvn: 'required|minSize:10|maxSize:12',
    state: 'required',
    lga: 'required',
    id_card_type: 'required',
    id_card_number: 'required|minSize:5|maxSize:30',
    id_card: (payLoad) => { return payLoad ? true : false; },
  }
  let messages = {
    first_name: {
      required: 'First name is required',
      minSize: 'First name is too short',
      maxSize: 'First name is too long'
    },
    last_name: {
      required: 'Last name is required',
      minSize: 'Last name is too short',
      maxSize: 'Last name is too long'
    },
    email: {
      required: 'Add business description',
      email: 'Enter a valid email',
    },
    address: {
      required: 'A proof of address is required',
      minSize: 'Address is too short',
      maxSize: 'Address is too long' //HFCM; Header Footer Code Manager        
    },
    id_card: {
      custom: 'Upload Id'
    },
    id_card_type: {
      required: "select an ID type"
    },
    id_card_number: {
      required: "Enter a valid ID Number"
    },
    state: {
      required: 'Select a state',
    },
    lga: {
      required: 'Select a local government area',
    },
    bvn: {
      required: "BVN is required",
      minSize: "BVN is too short",
      maxSize: "BVN is too long"
    },
    phone: {
      required: 'A phone number is required',
      phone: 'Phone number is not valid',
      minSize: 'Phone number is too short',
      maxSize: 'Phone number is too long'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently;
  });

  return Object.keys(result);
}

export const addProprietorInformationValidation = (
  type,
  payLoad,
  countrySelected,
  silently = false
) => {
  let data = {
    full_name: payLoad.full_name,
    phone: String(Number(payLoad.phone)),
    residential_address: payLoad.residential_address,
    email: payLoad.email,
    state: payLoad.state,
    country: payLoad.country,
    postal: payLoad.postal,
    city: payLoad.city,
    day: payLoad.day,
    month: payLoad.month,
    year: payLoad.year,
  };
  if (countrySelected?.country_code?.toLowerCase() === "nga") {
    data = { ...data, lga: payLoad.lga };
  }
  if (type === "proprietor") {
    let additional = {
      occupation: payLoad.occupation,
      government_id: payLoad?.government_id[0],
      passport_photograph: payLoad?.passport_photograph[0],
      e_signature: payLoad?.e_signature[0],
    };
    data = { ...data, ...additional };
  } else if (type === "director") {
    let additional = {
      occupation: payLoad.occupation,
      share_ownership: payLoad.share_ownership,
      government_id: payLoad.government_id[0],
      passport_photograph: payLoad.passport_photograph[0],
      e_signature: payLoad.e_signature[0],
    };
    data = { ...data, ...additional };
  } else if (type === "trustee") {
    let additional = {
      occupation: payLoad.occupation,
      government_id: payLoad.government_id[0],
      passport_photograph: payLoad.passport_photograph[0],
      e_signature: payLoad.e_signature[0],
    };
    data = { ...data, ...additional };
  } else if (type === "partner") {
    let additional = {
      government_id: payLoad.government_id[0],
      statement: payLoad.statement[0],
      e_signature: payLoad.e_signature[0],
    };
    data = { ...data, ...additional };
  }
  let rules = {
    full_name: "required|minSize:1|maxSize:30",
    postal: "required|minSize:1|maxSize:15",
    country: "required",
    city: "required",
    occupation: "required",
    residential_address: "required",
    phone: "required|phone|minSize:9|maxSize:11",
    address: "required|minSize:9|maxSize:300",
    email: "required|email",
    state: "required",
    lga: "required",
    share_ownership: "required",
    share_capital: "required",
    day: "required",
    month: "required",
    year: "required",

    e_signature: (payLoad) => {
      return payLoad ? true : false;
    },
    passport_photograph: (payLoad) => {
      return payLoad ? true : false;
    },
    international_passport: (payLoad) => {
      return payLoad ? true : false;
    },
    government_id: (payLoad) => {
      return payLoad ? true : false;
    },
    statement: (payLoad) => {
      return payLoad ? true : false;
    },
  };
  let messages = {
    first_name: {
      required: "First name is required",
      minSize: "First name is too short",
      maxSize: "First name is too long",
    },
    last_name: {
      required: "Last name is required",
      minSize: "Last name is too short",
      maxSize: "Last name is too long",
    },
    email: {
      required: "Add business description",
      email: "Enter a valid email",
    },
    address: {
      required: "A proof of address is required",
      minSize: "Address is too short",
      maxSize: "Address is too long", //HFCM; Header Footer Code Manager
    },
    id_card: {
      custom: "Upload Id",
    },
    id_card_type: {
      required: "select an ID type",
    },
    id_card_number: {
      required: "Enter a valid ID Number",
    },
    state: {
      required: "Select a state",
    },
    lga: {
      required: "Select a local government area",
    },
    bvn: {
      required: "BVN is required",
      minSize: "BVN is too short",
      maxSize: "BVN is too long",
    },
    phone: {
      required: "A phone number is required",
      phone: "Phone number is not valid",
      minSize: "Phone number is too short",
      maxSize: "Phone number is too long",
    },
  };

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently;
  });

  return Object.keys(result);
};

export const addBusinessInformationValidation = (payLoad, silently = false) => {
  let data = {
    business_name: payLoad.business_name,
    business_name_two: payLoad.business_name_two,

  };
  if (payLoad.business_category !== "LLP") {
    let additional = {
      business_name_three: payLoad.business_name_three,
    };
    data = { ...data, ...additional };
  }
  if (payLoad.business_category === "NGO") {
    let additional = {
      business_name_three: payLoad.business_name_three,
      organization_objective: payLoad.organization_objective,
      chairman_of_trustees: payLoad.chairman_of_trustees,
      trustee_tenureship: payLoad.trustee_tenureship,
      max_number_trustees: payLoad.max_number_trustees,
    };
    data = { ...data, ...additional };
  }
  if (
    payLoad.business_category !== "LLP" &&
    payLoad.business_category !== "NGO"
  ) {
    let additional = {
      phone_number: String(Number(payLoad.phone_number)),
      business_email: payLoad.business_email,
      business_description: payLoad.business_description,
    };
    data = { ...data, ...additional };
  }
  if (payLoad.business_category !== "NGO") {
    let additional = {
      business_address: payLoad.business_address,
    };
    data = { ...data, ...additional };
  }
  if (payLoad.business_category === "LLC") {
    let additional = {
      share_capital: payLoad.share_capital,
      business_objective: payLoad.business_objective,
      business_industry: payLoad.business_industry,
    };
    data = { ...data, ...additional };
  }
  if (payLoad.business_category === "LLP") {
    let additional = {
      partnership_agreement: payLoad?.partnership_agreement[0],
    };
    data = { ...data, ...additional };
  }

  let rules = {
    business_name: "required|minSize:1|maxSize:30",
    business_name_two: "required|minSize:1|maxSize:30",
    business_name_three: "required|minSize:1|maxSize:30",
    postal: "required|minSize:1|maxSize:15",
    country: "required",
    city: "required",
    occupation: "required",
    business_industry: "required",
    phone_number: "required|minSize:9|maxSize:11",
    business_address: "required|minSize:9|maxSize:300",
    business_description: "required|minSize:9|maxSize:300",
    business_objective: "required|minSize:9|maxSize:300",
    organization_objective: "required|minSize:9|maxSize:300",
    chairman_of_trustees: "required",
    trustee_tenureship: "required",
    max_number_trustees: "required",
    share_capital: "required",
    business_email: "required|email",
    state: "required",
    lga: "required",
    partnership_agreement: (payLoad) => {
      return payLoad ? true : false;
    },
  };
  let messages = {
    first_name: {
      required: "First name is required",
      minSize: "First name is too short",
      maxSize: "First name is too long",
    },
    last_name: {
      required: "Last name is required",
      minSize: "Last name is too short",
      maxSize: "Last name is too long",
    },
    email: {
      required: "Add business description",
      email: "Enter a valid email",
    },
    address: {
      required: "A proof of address is required",
      minSize: "Address is too short",
      maxSize: "Address is too long", //HFCM; Header Footer Code Manager
    },
    id_card: {
      custom: "Upload Id",
    },
    id_card_type: {
      required: "select an ID type",
    },
    id_card_number: {
      required: "Enter a valid ID Number",
    },
    state: {
      required: "Select a state",
    },
    lga: {
      required: "Select a local government area",
    },
    bvn: {
      required: "BVN is required",
      minSize: "BVN is too short",
      maxSize: "BVN is too long",
    },
    phone: {
      required: "A phone number is required",
      phone: "Phone number is not valid",
      minSize: "Phone number is too short",
      maxSize: "Phone number is too long",
    },
  };

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently;
  });
  return Object.keys(result);
};

export const becomeAnAgentValidation = (payLoad, silently = false) => {

  let data = {
    address: payLoad.address,
    compliance_state: payLoad.compliance_state,
    compliance_lga: payLoad.compliance_lga,
    guarantor_form: payLoad.guarantor_form[0],
  }
  let rules = {
    address: 'required|minSize:9|maxSize:300',
    compliance_state: 'required',
    compliance_lga: 'required',
    guarantor_form: (payLoad) => { return payLoad ? true : false; },
  }
  let messages = {
    address: {
      required: 'Address is required',
      minSize: 'Address is too short',
      maxSize: 'Address is too long'
    },
    compliance_state: {
      required: 'Select a state',
    },
    compliance_lga: {
      required: 'Select a local government area',
    },
    guarantor_form: {
      custom: 'A guarantor document is required'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}