import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormLevelIndicator } from "./FormLevelIndicator";
import { RegisterBusinessInformation } from "./RegisterBusinessInformation";
import { isEmptyObject } from "../../helpers/helper";
import { ProprietorInformation } from "./ProprietorInformation";
import SelectCompanyType, { RegisterNewBusiness } from "./SelectCompanyType";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import BackButton from "../../components/transfer/BackButton";
import { CgClose } from "react-icons/cg";
import { AddProprietorInformation } from "./AddProprietorInformation";
import {
  cacFeePayment,
  cancelBusinessCacRegistration,
  checkDraftDetails,
  checkDraftStatus,
  selectBusinessCacType,
} from "../../redux/business/businessActions";
import useBusiness from "../../hooks/useBusiness";
import FeePayment from "./Feepayment";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ConfirmPinModal from "../fund-transfer-new/ConfirmPinModal";
import Button from "../../components/buttons/Button";

export default function RegisterBusinessNew() {
  const dispatch = useDispatch();
  const history = useHistory();
  const business = useBusiness();
  const modalRef = useRef();

  const [currentFormState, setCurrentFormState] = useState(
    "business information"
  );
  const [proprietorFormType, setProprietorFormType] = useState("");
  const [proprietorForm, setProprietorForm] = useState({});
  const [input, setInput] = useState({
    business_name: "",
    business_name_two: "",
    business_type: "",
    business_category: "",
    business_industry: "",
    business_description: "",
    business_proof_of_address: {},
    tin: "",
    rc_number: "",
    bn_number: "",
    postal_code: "",
    registration_uuid: "",
    partnership_agreement: ""
  });
  const [proprietorList, setProprietorList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [trusteeList, setTrusteeList] = useState([]);
  const [proceed, setProceed] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [failed, setFailed] = useState(false);
  const [feeError, setFeeError] = useState("");

  const onDraftSuccess = (business, type, reg_uuid) => {
    if (type === "LLC") {
      setInput({
        ...input,
        business_name: business?.registration_name,
        business_name_two: business?.registration_name_two,
        business_name_three: business?.registration_name_three,
        phone_number: business?.registration_phone,
        business_email: business?.registration_email,
        business_address: business?.registration_address,
        business_description: business?.registration_description,
        share_capital: business?.share_capital,
        business_objective: business?.registration_objective,
        business_industry: business?.registration_industry,
        registration_uuid: reg_uuid,
        business_category:
          business?.registration_type?.toUpperCase(),
      });
      setDirectorList(business?.directors);
    }
    if (type === "LLP") {
      setInput({
        ...input,
        business_name: business?.registration_name,
        business_name_two: business?.registration_name_two,
        business_address: business?.registration_address,
        partnership_agreement: business?.file_id,
        registration_uuid: reg_uuid,
        business_category: type,
      });
      setPartnerList(business?.directors);
    }
    if (type === "SP") {
      setInput({
        ...input,
        business_name: business?.registration_name,
        business_name_two: business?.registration_name_two,
        business_name_three: business?.registration_name_three,
        phone_number: business?.registration_phone,
        business_email: business?.registration_email,
        business_address: business?.registration_address,
        business_description: business?.registration_description,
        registration_uuid: reg_uuid,
        business_category: type,
      });
      setProprietorList(business?.directors);
    }
    if (type === "NGO") {
      setInput({
        ...input,
        business_name: business?.registration_name,
        business_name_two: business?.registration_name_two,
        business_name_three: business?.registration_name_three,
        chairman_of_trustees: business?.chairman_trustee,
        organization_objective: business?.organization_objective,
        trustee_tenureship: business?.trustee_tenureship,
        max_number_trustees: business?.max_trustee,
        registration_uuid: reg_uuid,
        business_category: type,
      });
      setTrusteeList(business?.directors);
    }
  };

  useEffect(() => {
    if (proprietorFormType === "proprietor") {
      setProprietorForm({
        type: "proprietor",
        data: [
          {
            name: "occupation",
            type: "text",
            label: "Occupation",
            placeholder: "Enter Occupation",
            errorText: "Enter a valid Occupation",
          },
          {
            name: "government_id",
            type: "file",
            label: "Government Issued ID",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "passport_photograph",
            type: "file",
            label: "Passport Photograph",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "e_signature",
            type: "file",
            label: "E-Signature",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
        ],
      });
    } else if (proprietorFormType === "director") {
      setProprietorForm({
        type: "director",
        data: [
          {
            name: "occupation",
            type: "text",
            label: "Occupation",
            placeholder: "Enter Occupation",
            errorText: "Enter a valid Occupation",
          },
          {
            name: "share_ownership",
            type: "number",
            label: "Share Ownership (Percentage)",
            placeholder: "Enter Share Percentage",
            errorText: "Share Percentage Invalid",
          },
          {
            name: "government_id",
            type: "file",
            label: "Government Issued ID",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "passport_photograph",
            type: "file",
            label: "Passport Photograph",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "e_signature",
            type: "file",
            label: "E-Signature",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
        ],
      });
    } else if (proprietorFormType === "trustee") {
      setProprietorForm({
        type: "trustee",
        data: [
          {
            name: "occupation",
            type: "text",
            label: "Occupation",
            placeholder: "Enter Occupation",
            errorText: "Enter a valid Occupation",
          },
          {
            name: "government_id",
            type: "file",
            label: "Government Issued ID",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "passport_photograph",
            type: "file",
            label: "Passport Photograph",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "e_signature",
            type: "file",
            label: "E-Signature",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
        ],
      });
    } else if (proprietorFormType === "partner") {
      setProprietorForm({
        type: "partner",
        data: [
          {
            name: "government_id",
            type: "file",
            label: "Government-Issued ID",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "e_signature",
            type: "file",
            label: "E-Signature",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
          {
            name: "statement",
            type: "file",
            label: "Statement of Amount",
            showSupport: true,
            supportText:
              "Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.",
          },
        ],
      });
    }
  }, [proprietorFormType]);

  const ProceedToSelectCompanyType = (type) => {
    if (type) {
      dispatch(
        selectBusinessCacType(
          {
            type: type.toLowerCase(),
          },
          (data) => {
            if (data?.registration_uuid) {
              setProceed(!proceed);
              setInput({
                ...input,
                registration_uuid:
                 data?.registration_uuid,
              });
            }
          }
        )
      );
    }
  };

  const handleBackButton = () => {
    if (currentFormState === "business information") {
      setProceed(!proceed);
    } else if (currentFormState === "proprietor information") {
      setCurrentFormState("business information");
    } else {
      setCurrentFormState("proprietor information");
    }
  };

  const handleCloseButton = () => {
    dispatch(
      cancelBusinessCacRegistration(
        { registration_uuid: input.registration_uuid },
        () => {
          history.push({pathname: "/dashboard/home",    state: {
          }});
          setInput({});
        }
      )
    );
  };

  const onFailure = (e) => {
    setFeeError(e?.response?.data?.message);
    setFailed(true);
  };
  const proceedToMakePayment = () => {
    modalRef.current.closeModal();
    if (input.business_category) {
      dispatch(
        cacFeePayment(
          { registration_uuid: input.registration_uuid },
          { type: input.business_category },
          () => setSuccessful(true),
          onFailure
        )
      );
    }
  };

  useEffect(() => {
    dispatch(checkDraftStatus({}, (data)=>{
      if(data?.status){
        setInput({ ...input, registration_uuid: data.registration_uuid, business_type: data.registration_type });
        setProceed(true);
        if (data.next_registration_level === "registration-details") {
          setCurrentFormState("business information");
        } else if (data.next_registration_level === "officers-details") {
          setCurrentFormState("proprietor information");
        } else if (data.next_registration_level === "fee-payment") {
          setCurrentFormState("fee payment");
        }
        dispatch(
          checkDraftDetails(
            { registration_uuid: data?.registration_uuid },
            { type: data?.registration_type?.toLowerCase() },
           (res) => onDraftSuccess(res,data?.registration_type?.toUpperCase(), data?.registration_uuid)
          )
        );
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {proceed ? (
        <Fragment>
          <RegisterNewBusiness className="py-0 px-8 md:px-20">
            <div className="flex justify-between header">
              <BackButton onClick={handleBackButton} />
              <span onClick={handleCloseButton} className="icon close">
                <CgClose size={31} />
              </span>
            </div>
            <div className="px-3 py-0">
              <div className="mb-9 md:hidden">
                <BreadCrumbs title={"Home"} path={"/dashboard/home"} />
              </div>
              <div
                className={`py-8 md:flex ${
                  (proprietorFormType.length > 0 ||
                    currentFormState === "fee payment") &&
                  "mx-auto justify-center"
                } mx-auto`}
              >
                {(currentFormState === "business information" ||
                  currentFormState === "proprietor information") && (
                  <div className="px-3 md:w-4/12">
                    <FormLevelIndicator
                      formStep={currentFormState}
                      input={input}
                    />
                  </div>
                )}
                <div className="w-full md:w-5/12">
                  {currentFormState === "business information" && (
                    <RegisterBusinessInformation
                      setFormLevel={setCurrentFormState}
                      input={input}
                      setInput={setInput}
                      back={ProceedToSelectCompanyType}
                    />
                  )}
                  {currentFormState === "proprietor information" && (
                    <ProprietorInformation
                      setFormLevel={setCurrentFormState}
                      businessType={input?.business_category}
                      proprietorList={proprietorList}
                      setProprietorList={setProprietorList}
                      directorList={directorList}
                      setDirectorList={setDirectorList}
                      trusteeList={trusteeList}
                      setTrusteeList={setTrusteeList}
                      partnerList={partnerList}
                      setPartnerList={setPartnerList}
                      setProprietorFormType={setProprietorFormType}
                      proprietorFormType={proprietorFormType}
                      input={input}
                    />
                  )}
                  {currentFormState === "add proprietor information" &&
                    !isEmptyObject(proprietorForm) && (
                      <AddProprietorInformation
                        formFields={proprietorForm}
                        setFormLevel={setCurrentFormState}
                        businessType={input?.business_category}
                        proprietorList={proprietorList}
                        setProprietorList={setProprietorList}
                        directorList={directorList}
                        setDirectorList={setDirectorList}
                        trusteeList={trusteeList}
                        setTrusteeList={setTrusteeList}
                        partnerList={partnerList}
                        setPartnerList={setPartnerList}
                        proprietorFormType={proprietorFormType}
                        input={input}
                      />
                    )}
                  {currentFormState === "fee payment" && (
                    <FeePayment
                      onClick={() => {
                        modalRef.current.openModal();
                      }}
                      input={input}
                      setInput={setInput}
                    />
                  )}
                </div>
              </div>
            </div>
            <ConfirmPinModal
              ref={modalRef}
              onCorrectPin={proceedToMakePayment}
              onFailure={() => {}}
              title=''
              body='Enter PIN to confirm'
            />

            {successful && (
              <div className="h-full bg-white fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-8">
                <div className="flex justify-between header">
                  <div></div>
                  <span
                    onClick={() => history.push("/dashboard/home")}
                    className="icon close"
                  >
                    <CgClose size={31} />
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <div className="self-center bg-white rounded md:w-3/5 h-full">
                    <div className="mx-auto w-20">
                      <BsCheckCircleFill color={"#5CDB5C"} size={"6em"} />
                    </div>
                    <div className="text-center mt-8 p-5">
                      <h4 className="font-bold">Payment successful</h4>
                      <p className="text-glade-gray-400">
                        Your payment was successful and is currently being
                        processed. Your registration will be ready in 3 - 5
                        days.
                      </p>
                      <Button.Blue title="Go Home" onClick={() => history.push('/dashboard/home')} loading={business.isSubmittingBusiness} disabled={business.isSubmittingBusiness}/>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {failed && (
              <div className="h-full bg-white fixed top-0 w-full left-0 z-50 px-5 md:px-32 ">
                <div className="flex justify-between header">
                  <div></div>
                  <span onClick={() => setFailed(false)} className="icon close">
                    <CgClose size={31} />
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <div className="self-center bg-white rounded md:w-2/5 h-full">
                    <div className="text-right mb-12 p-5"></div>
                    <div className="mx-auto w-20">
                      <IoIosCloseCircleOutline
                        className="mt-6 mb-4"
                        color="#EA0202"
                        size={100}
                      />
                    </div>
                    <div className="text-center mt-8 p-5">
                      <h4 className="font-bold">{feeError}</h4>
                      <Button.Blue title="Fund Account" onClick={() => history.push('/dashboard/home')} loading={business.isSubmittingBusiness} disabled={business.isSubmittingBusiness}/>

                    </div>
                  </div>
                </div>
              </div>
            )}
          </RegisterNewBusiness>
        </Fragment>
      ) : (
        <SelectCompanyType
          onClick={ProceedToSelectCompanyType}
          input={input}
          setInput={setInput}
        />
      )}
    </>
  );
}
