import {
  LOADING_BILLS,
  RELOAD_BILLS,
  GET_ALL_BILLS,
  GET_ALL_BILL_TYPES,
  GET_BILLERS,
  GET_BILLER_ITEMS,
  RESOLVE_METER_DETAILS,
  RESOLVE_INTERNET_ACCOUNT_DETAILS,
  RESOLVE_CABLE_TV_DETAILS,
  BUY_AIRTIME,
  BUY_ELECTRICITY,
  BUY_CABLE_TV,
  BUY_INTERNET,
  GET_BILLS_BENEFACTRICES
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload: false,
  billsIndex: [],
  billTypes: {},
  billers: [],
  billerItems: [],
  meterDetails: {},
  internetAccountDetails: {},
  cableTvDetails: {},
  buyAirtime: {},
  buyElectricity: {},
  buyCableTv: {},
  buyInternet: {},
  beneficiary: {},
};

export default function billsReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_BILLS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_BILLS:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case GET_ALL_BILLS:
      return {
        ...state,
        billsIndex: action.payLoad.data.data,
      };

    case GET_ALL_BILL_TYPES:
      return {
        ...state,
        billTypes: action.payLoad.data.data,
      };

    case GET_BILLERS:
      return {
        ...state,
        billers: action.payLoad.data.data,
      };

    case GET_BILLER_ITEMS:
      return {
        ...state,
        billerItems: action.payLoad.data.data,
      };

    case RESOLVE_METER_DETAILS:
      return {
        ...state,
        meterDetails: action.payLoad.data.data,
      };

    case RESOLVE_INTERNET_ACCOUNT_DETAILS:
      return {
        ...state,
        internetAccountDetails: action.payLoad.data.data,
      };

    case RESOLVE_CABLE_TV_DETAILS:
      return {
        ...state,
        cableTvDetails: action.payLoad.data.data,
      };

    case BUY_AIRTIME:
      return {
        ...state,
        buyAirtime: action.payLoad.data.data,
      };

    case BUY_ELECTRICITY:
      return {
        ...state,
        buyElectricity: action.payLoad.data.data,
      };

    case BUY_CABLE_TV:
      return {
        ...state,
        buyCableTv: action.payLoad.data.data,
      };

    case BUY_INTERNET:
      return {
        ...state,
        buyInternet: action.payLoad.data.data,
      };

    case GET_BILLS_BENEFACTRICES:
      return {
        ...state,
        beneficiary: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
