import React from 'react'
import { GLADEFINANCE_BLUE_LOGO } from '../../assets/images/images'
import OnboardingRight from './OnboardingRight'

const OnboardingLayout = ({children}) => {
  return (
    <div className="min-h-screen" >
      <div className="w-full min-h-screen grid md:grid-cols-4" style={{ minHeight: "112.7vh" }}>
        <div className="min-h-full w-full col-span-2 flex flex-col items-center">
          <img src={GLADEFINANCE_BLUE_LOGO} alt="" className='py-16' />
          {children}
          <p className='absolute bottom-5'>© Gladefinance Inc. {new Date().getFullYear()}. All rights reserved.</p>
        </div>
        <OnboardingRight />
      </div>
    </div>
  )
}

export default OnboardingLayout