import React, { Fragment } from "react";
import HomeBusiness from "../../home/HomeBusiness";

const Dashboard = () => {

  return (
    <Fragment>
      
      <HomeBusiness />
      
    </Fragment>
  );
};

export default Dashboard;
