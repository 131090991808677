import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { TransferFormContext } from '../../context/TransferFormContext';
import { calculateBalance, commmaToInput, removeCommas } from '../../helpers/helper';
import useFundTransfer from '../../hooks/useFundTransfer';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import ReceiverFlag from './ReceiverFlag';
import { useDispatch } from "react-redux";
import { getInternationalTransferMethods, getInternationalTransferRate } from '../../redux/fund-transfer/fundTransferActions';

const RecieverInput = ({ countries }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { internationalTransferRate } = useFundTransfer();
  const {
    transferForm,
    setTransferForm,
    availableBalance,
    setBalanceAfterTransfer,
    transferCharge,
    currencies,
    setCurrencies,
    setTransferMethod,
    transferMethod,
    setTransferProvider
  } = useContext(TransferFormContext);
  const dispatch = useDispatch();

  const dropdownRef = useOnClickOutside(closeDropdown);

  const dropdownVariants = {
    open: {
      opacity: 1,
      height: "auto"
    },
    closed: {
      opacity: 0,
      height: 0,
    }
  };

  function onChange(e) {
    let newValues = {
      amount: 0
    };

    if (newValues.amount === 'NaN') {
      return;
    }
    const convertedAmount = commmaToInput(parseFloat(commmaToInput(e.target.value, true) / Number(internationalTransferRate?.rate)).toFixed(2));
    setTransferForm(form => ({
      ...form,
      [e.target.name]: commmaToInput(e.target.value),
      amount: convertedAmount
    }));
    dispatch(getInternationalTransferRate({
      amount: removeCommas(convertedAmount, true) || 0,
      source_currency: currencies?.activeCurrency?.currency,
      destination_currency: currencies?.selectedCurrency?.currency ?? "USD",
      destination_country: currencies?.selectedCurrency?.currency?.slice(0, -1) ?? "US",
      transfer_method: transferMethod?.selected?.code || "bank"
    },
      (data) => {
        setBalanceAfterTransfer(calculateBalance(availableBalance, removeCommas(convertedAmount), data?.fee));
      }
    ));
  }

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function closeDropdown() {
    setIsOpen(false);
  };

  function onOptionClicked(value) {
    return function () {
      const { amount } = transferForm;

      // receiver_amount
      //amount

      setCurrencies(currencies => ({
        ...currencies,
        selectedCurrency: value
      }));

      dispatch(getInternationalTransferMethods(
        { country_code: value.code },
        (data) => {
          setTransferProvider({});
          setTransferForm({});
          if (data.length === 1) {
            setTransferForm(form => ({ ...form, transfer_method: data[0].code }));
          }
          setTransferMethod(methods => ({ ...methods, methods: data, selected: null }));
          setTransferForm(form => ({
            ...form,
            beneficiary_country_code: value.code,
            beneficiary_currency_code: value.currency,
          }));
        }));

      dispatch(getInternationalTransferRate({
        amount: removeCommas(amount) || 1,
        source_currency: currencies?.activeCurrency?.currency,
        destination_country: value?.code,
        destination_currency: value?.currency,
        transfer_method: transferForm?.transfer_method
      },
        (data) => {
          const newReceiverAmount = (removeCommas(amount, true) / Number(data.rate)).toFixed(2);
          setTransferForm(form => ({
            ...form,
            receiver_amount: '',
            amount: ''
          }));
        }
      ));
      setSearchInput('');
      setIsOpen(false);
    };
  }

  const handleSearchInput = e => setSearchInput(e.target.value);

  return (
    <Div>
      <label
        htmlFor="receiveAmount"
        className='font-bold mb-2'
      >
        Recipient Gets
      </label>
      <section>
        <div className="input-area">
          <DropdownContainer>
            <div className='flex justify-between items-center border w-full p-1 cursor-pointer' onClick={toggleDropdown}>
              <div className='flex space-x-2 items-center'>
                <ReceiverFlag flagCode={currencies?.selectedCurrency?.code} />
                <span>{currencies?.selectedCurrency?.currency}</span>
              </div>
              <span onClick={toggleDropdown}>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            <AnimatePresence>
              {isOpen && (
                <Container
                  animate="open"
                  initial="closed"
                  exit="closed"
                  variants={dropdownVariants}
                >
                  <SearchBox>
                    <input
                      type="text"
                      placeholder='Search available currencies'
                      value={searchInput}
                      onChange={handleSearchInput}
                      autoFocus
                    />
                  </SearchBox>
                  <DropdownListContainer>
                    {countries.filter(item => item.currency.toLowerCase().includes(searchInput)).map(country =>
                    (
                      <ListItem
                        onClick={onOptionClicked(country)}
                        key={country.currency}
                      >
                        <ReceiverFlag flagCode={country.code} />
                        <span>{country.currency}</span>
                      </ListItem>
                    )
                    )}
                  </DropdownListContainer>
                </Container>
              )}
            </AnimatePresence>
          </DropdownContainer>
          <input
            type="text"
            placeholder='0.00'
            name="receiver_amount"
            className='receiver-amount'
            value={transferForm?.receiver_amount || ''}
            onChange={onChange}
          />
        </div>
      </section>
    </Div>
  );
};

export default RecieverInput;

const SearchBox = styled.div`
  outline: 3px solid #1634A1;
  width: 378px;
  margin-top: 10px;
  border-radius: 3px;
  input {
    border: none;
    outline: none;
    width: 100%;
    margin: 0;
  }
`;

const Container = styled(motion.div)`

`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .input-area {
    display: flex;
    grid-template-columns: 35% 65%;
    gap: 0;

    .receiver-amount {
    border: 1px solid #E4E4F3;
    border-radius: 5px;
    height: 50px;

    &::placeholder {
      color: #9CA3AF
    }
  }

`;

const DropdownContainer = styled.div`
  width: 8rem;
  // margin-top: 1rem;
  position: relative;
`;

const DropdownHeader = styled.div`
  margin-bottom: .8rem;
  padding: 1rem;
  // box-shadow: 0 2px 3px rgba(0, 0, 0, .15);
  color: #333;
  height: 50px;
  display: flex;
  width: calc(100% + 45px);
  // width: 100%;
  align-items: center;
  border: 1px solid #E4E4F3;
  border-radius: 5px;
  gap: .5rem;
  cursor: pointer;
  background: #fff;
`;

const DropdownListContainer = styled.ul`
  margin-top: 2rem;
  position: absolute;
  top: 90%;
  width: 378px;
  left: 0;
  right: 0;
  height: 150px;
  background: #fff;
  border: 1px solid gainsboro;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(3, 32, 134, 0.1);
  overflow-y: scroll;
  &:first-child {
      padding-top: .8rem;
    }
  `;

const ListItem = styled.li`
  // outline: 2px solid blue;
  list-style: none;
  padding: .5rem .8rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: .8rem;
  cursor: pointer;
  transition: 200ms ease-in color;

  &:hover {
    color: #1634A1;
  }
`;
