import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Bank, BankLine, CloseIcon, Globe, Tick, Usertag } from '../../assets/icons/Icons';
import { TransferFormContext } from '../../context/TransferFormContext';
import { useDispatch } from "react-redux";
import TransferScreen from './TransferScreen';
import { getInternationalCountries } from '../../redux/fund-transfer/fundTransferActions';
import { useLocation } from 'react-router-dom';

const SelectTransferType = ({ closeBtn, onClosePage }) => {
  const { setFormStage, setFormType, setCurrencies, setGladeTransferType} = useContext(TransferFormContext);
  const [gladeTransfer, setGladeTransfer] = useState(false)
  const location = useLocation();
  const typesOfTransfer = [
    {
      title: "Glade",
      description: "Send money to another Glade account",
      icon: <Bank color="#2C6DEA" />
    },
    {
      title: "Local",
      description: "Send money in your local currency",
      icon: <Bank color="#2C6DEA" />
    },
    {
      title: "International",
      description: "Send money in foreign currency",
      icon: <Globe color="#2C6DEA" />
    }
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    if (location?.transfertype) {
      setFormStage(location?.transfertype);
      setFormType(location?.transfertype);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.transfertype])

  const initForm = (type, gladeType) => {
    setFormStage(type);
    setFormType(type);
    gladeType && setGladeTransferType(gladeType)
  }

  useEffect(() => {
    dispatch(getInternationalCountries(res => setCurrencies(currencies => ({
      ...currencies,
      selectedCurrency: res[0]
    }))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TransferScreen>
      <section className="page-title mb-12 items-center flex flex-col">
        <h2 className='text-3xl font-bold'>Transfer Type</h2>
        <p>Select a transfer type</p>
      </section>
      <div className='flex flex-col gap-12 md:flex-row '>
        {typesOfTransfer.map((type, i) => (
          <SelectContainer
            selected={true}
            onClick={() => { type.title?.toLowerCase() === 'glade' ? setGladeTransfer(true) : initForm(type.title.toLowerCase()) }}
            key={i}>
            <span className="icon-container">
              {type.icon}
            </span>
            <h2 className="text-2xl">{type.title}</h2>
            <p>{type.description}</p>
            <span className="tick">
              <span className="tick-icon">
                <Tick size={27} />
              </span>
            </span>
          </SelectContainer>
        ))}
      </div>
      {gladeTransfer &&
        <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-8 lg:px-32 py-32 flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <div className="self-center bg-white rounded md:w-1/2 p-5 h-full">
              <div className="text-center mb-4 border-b pb-3">
                <span>Glade Transfer</span>
                <button onClick={() => setGladeTransfer(false)} className="float-right">
                  <CloseIcon />
                </button>
              </div>
              <div className="mx-auto my-9 flex flex-col items-stretch md:w-3/4 justify-center space-y-6">
                <div className='border rounded-md p-6 flex items-center space-x-6 cursor-pointer' onClick={()=>initForm('glade', 'usertag')}>
                  <Usertag />
                  <div className='flex flex-col'>
                    <h3 className='text-glade-black-800 glade-heading-four'>Send to User Tag</h3>
                    <p className='text-glade-gray-400'>Make transfers using recipient’s user tag</p>
                  </div>
                </div>
                <div className='border rounded-md p-6 flex items-center space-x-6 cursor-pointer' onClick={()=>initForm('glade', 'bank')}>
                  <BankLine />
                  <div className='flex flex-col'>
                    <h3 className='text-glade-black-800 glade-heading-four'>Send to Bank Account</h3>
                    <p className='text-glade-gray-400'>Make transfers using recipient’s bank account</p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      }
    </TransferScreen>
  );
};

export default SelectTransferType;

const SelectContainer = styled.section`
  height: 250px;
  width: 250px;
  ${'' /* border: 3px solid ${({ selected }) => selected ? "#1634A1" : "#A1AFCD"}; */}
  border: 1px solid #E8EBF6;
  border-radius: 5px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  ${'' /* background: ${({ selected }) => selected ? "#F6F8FF" : "transparent"}; */}
  background: "transparent";
  align-items: center;
  gap: .5rem;
  padding: 1rem;
  cursor: pointer;
  text-align: center;
  ${'' /* color: ${({ selected }) => selected ? "#1634A1" : "#A1AFCD"}; */}
  color: #303030;
  font-family: inherit;
  h2 {
    font-weight: 600;
  }

  .icon-container {
    height: 2rem;
    width: 2rem;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ${'' /* background:  ${({ selected }) => selected ? "#1634A1" : "#A1AFCD"}; */}
    background: #F7F8FF;
    border-radius: 100px;
  }
`;
