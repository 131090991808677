import React from 'react';
import { useHistory } from "react-router-dom";
import { TabGroup, TabPanel } from "../../components/tabs/Tab";
import SettingsProfile from './SettingsProfile';
import SettingsSettlement from './SettingsSettlement';
import SettingsApiAndWebhooks from './SettingsApiAndWebhooks';
import SettingsManageServices from './SettingsManageServices';

import useQuery from '../../hooks/useQuery';
import useUser from '../../hooks/useUser';
import {IoIosArrowForward} from "react-icons/io";
// import SettingsAccountLimits from './SettingsAccountLimits';
import AccountLimits from './AccountLimits';
import OldSettingsCompliance from './OldBusinessCompliance';


export default function Settings() {

    const history = useHistory();
    const user = useUser();
    let query = useQuery();

    const businessColumn = (user.hasCompliance || user.compliance === 'pending') ? ["Profile", "Settlement", "API & Webhooks", "Manage Services", "Account Limits"] : ["Profile", "Settlement", "API & Webhooks", "Manage Services", "Account Limits", "Compliance"];
    const personalColumn = (user.hasCompliance || user.compliance === 'pending') ? ["Profile", "Manage Services", "Account Limits"] : ["Profile", "Manage Services", "Account Limits", "Compliance"];

    return (
        <React.Fragment>
            {/* Pc Screens */}
            <div className="hidden md:block">
                {user.isBusiness ? 
                    <TabGroup 
                        defaultIndex={query.get('tab')??0} 
                        className="w-full md:w-2/12 whitespace-nowrap" 
                        column={businessColumn}>

                        {/* Settings Profile */}
                        <TabPanel>
                            <SettingsProfile />
                        </TabPanel>

                        {/* Settings Settlement */}
                        <TabPanel>
                            <SettingsSettlement />
                        </TabPanel>

                        {/* Settings Api And Webhooks */}
                        <TabPanel>
                            <SettingsApiAndWebhooks />
                        </TabPanel>

                        {/* Settings Manage Services */}
                        <TabPanel>
                            <SettingsManageServices />
                        </TabPanel>
                    
                        {/* Settings Account Limits */}
                        <TabPanel>
                            <AccountLimits />
                        </TabPanel>
                        {/* Ongoing Account Limits */}
                        {/* <TabPanel>
                            <AccountTier />
                        </TabPanel> */}
                        {/* Settings Compliance */}
                        {(!user.hasCompliance || user.compliance === 'not-submitted' || user.compliance === 'rejected') &&
                        <TabPanel>
                            <OldSettingsCompliance />
                        </TabPanel>}
                    </TabGroup>
                :
                    <TabGroup 
                        defaultIndex={query.get('tab')??0} 
                        className="w-full md:w-2/12 whitespace-nowrap" 
                        column={personalColumn}>

                        {/* Settings Profile */}
                        <TabPanel>
                            <SettingsProfile />
                        </TabPanel>

                        {/* Settings Manage Services */}
                        <TabPanel>
                            <SettingsManageServices />
                        </TabPanel>

                        {/* Settings Account Limits */}
                        <TabPanel>
                            <AccountLimits />
                        </TabPanel>
                        {/* <TabPanel>
                            <AccountTier />
                        </TabPanel> */}
                        {/* Settings Compliance */}
                        {(!user.hasCompliance || user.compliance === 'not-submitted' || user.compliance === 'rejected') &&
                        <TabPanel>
                            <OldSettingsCompliance />
                        </TabPanel>}
                    </TabGroup>
                }
            </div>

            {/* Mobile Screens */}
            <div className="block md:hidden">
                {user.isBusiness ? 
                    <div className='bg-white'>
                        {/* Settings Profile */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/profile')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Profile</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                        {/* Settings Settlement */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/settlement')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Settlements</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                        {/* Settings Account Limits */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/account-limits')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Account Limits</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                        {/* Settings Api And Webhooks */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/api-and-webhooks')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">API & Webhooks</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                        {/* Settings Manage Services */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/manage-services')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Manage Service</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                        {/* Settings Compliance */}
                        {(!user.hasCompliance || user.compliance === 'not-submitted' || user.compliance === 'rejected') &&
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/compliance')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Compliance</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>}
                    </div>

                :

                    <div className='bg-white'>
                        {/* Settings Profile */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/profile')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Profile</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                        {/* Settings Manage Services */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/manage-services')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Manage Service</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                        {/* Settings Account Limits */}
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/account-limits')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Account Limits</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>

                         {/* Settings Ongoing */}
                         {/* <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/ongoing')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Ongoing</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div> */}

                        {/* Settings Compliance */}
                        {(!user.hasCompliance || user.compliance === 'not-submitted' || user.compliance === 'rejected') &&
                        <div className="flex flex-row justify-between p-6 border-b" onClick={()=>history.push('/dashboard/settings/compliance')}>
                            <div className="flex items-center">
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">Compliance</span>
                            </div>
                            <div className="flex items-center">
                                <IoIosArrowForward color={'#9CA3AF'} size={30} />
                            </div>
                        </div>}
                    </div>
                }
            </div>
        </React.Fragment>
    );
}
