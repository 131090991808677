export const  GET_ACCOUNT_LIMIT_LOADING = "GET_ACCOUNT_LIMIT_LOADING"
export const  GET_ACCOUNT_LIMIT_SUCCESS = "GET_ACCOUNT_LIMIT_SUCCESS"
export const  GET_ACCOUNT_LIMIT_FAILURE = "GET_ACCOUNT_LIMIT_FAILURE"


export const  REQUEST_ACCOUNT_LIMIT_LOADING = "REQUEST_ACCOUNT_LIMIT_LOADING"
export const  REQUEST_ACCOUNT_LIMIT_SUCCESS = "REQUEST_ACCOUNT_LIMIT_SUCCESS"
export const  REQUEST_ACCOUNT_LIMIT_FAILURE = "REQUEST_ACCOUNT_LIMIT_FAILURE"

export const  REQUEST_UPGRADE_TIER_LOADING = "REQUEST_UPGRADE_TIER_LOADING"
export const  REQUEST_UPGRADE_TIER_SUCCESS = "REQUEST_UPGRADE_TIER_SUCCESS"
export const  REQUEST_UPGRADE_TIER_FAILURE = "REQUEST_UPGRADE_TIER_FAILURE"


export const  GET_SYSTEM_ACCOUNT_TIER_LOADING = "GET_SYSTEM_ACCOUNT_TIER_LOADING"
export const  GET_SYSTEM_ACCOUNT_TIER_SUCCESS = "GET_SYSTEM_ACCOUNT_TIER_SUCCESS"
export const  GET_SYSTEM_ACCOUNT_TIER_FAILURE = "GET_SYSTEM_ACCOUNT_TIER_FAILURE"