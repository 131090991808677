import { csvWorkbookDownload } from "../../helpers/XlsxUtils";
import * as xlsx from "xlsx";

export function downloadSampleBulkCreateStaffCSV(onChange=()=>{}){

    const fileName = "Glade-Bulk-Create-Staff-Sample-File";
    const getWorksheets=({ xlsx }) => {
        return {
            data:xlsx.utils.json_to_sheet([
                {
                    "Staff Name": "John Doe",
                    "Staff Email": "john@glade.sample",
                    "Staff Phone Number": "08023000000",
                    "Staff ID Number": "001",
                    "Staff Position": "Supervisor",
                    "Staff Salary Amount": "100000",
                    "Staff Bank Code": "044",
                    "Staff Bank Name": "Access Bank",
                    "Staff Account Number": "0724323320",
                    "" : "Numbers should be formatted a text to accomodate numbers starting with zero"
                },
            ]),
        }
    }

    (async () => {
        if (getWorksheets) {
            try {
                onChange({ isLoading: true, isError: false });
                const workbook = xlsx.utils.book_new();
                const worksheets = await getWorksheets({ workbook, xlsx });
                if (worksheets) {
                    for (const key in worksheets) {
                        xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
                    }
                    if (workbook.SheetNames.length) {
                        csvWorkbookDownload(workbook, fileName);
                    }
                }
            } catch (error) {
                onChange({ isLoading: false, isError: true });
            }
            onChange({ isLoading: false, isError: false });
        }
    })();
}

export function downloadBankCodesCSV(data=[],onChange=()=>{}){

    const fileName = "Glade-Bulk-Fund-Transfer-Bank-Code-File";
    const bankCodes = data.map(({bank_code,bank_name})=>({
        "Bank Code": bank_code,
        "Bank Name": bank_name
    }));

    const getWorksheets=({ xlsx }) => {
        return {
            data:xlsx.utils.json_to_sheet([
                {
                    "Bank Name": '',
                    "Bank Code": '',
                },
                ...bankCodes
            ]),
        }
    }

    (async () => {
        if (getWorksheets) {
            try {
                onChange({ isLoading: true, isError: false });
                const workbook = xlsx.utils.book_new();
                const worksheets = await getWorksheets({ workbook, xlsx });
                if (worksheets) {
                    for (const key in worksheets) {
                        xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
                    }
                    if (workbook.SheetNames.length) {
                        csvWorkbookDownload(workbook, fileName);
                    }
                }
            } catch (error) {
                onChange({ isLoading: false, isError: true });
            }
            onChange({ isLoading: false, isError: false });
        }
    })();
}

export async function processUploadedBulkCreateStaffCSV(file){
    if (file) {

        const reader = new FileReader();
        return new Promise((resolve, reject) => {

            reader.readAsBinaryString(file);
            reader.onload = async function (e) {

                // Read workbook
                const data = e.target.result;
                const workbook = xlsx.read(data, {type: 'binary'});
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const csv = xlsx.utils.sheet_to_csv(sheet);
                const bulkTransferData = csv.split("\n").map(row => row.split(","));

                // Validate data
                let validBulkTransferData = bulkTransferData.filter((item,key)=>{
                    return key > 0 && item[0].length > 0 && item[1].length > 0 && item[2].length > 0 && item[3].length > 0 && item[4].length > 0
                    && item[4].length > 0 && item[5].length > 0 && item[6].length > 0 && item[7].length > 0 && item[8].length > 0;

                }).map((item, key)=>{
                    return {
                        "id":key,
                        "staff_name":item[0],
                        "staff_email":item[1],
                        "staff_phone_number":item[2],
                        "staff_number":item[3],
                        "staff_position":item[4],
                        "amount":item[5],
                        "bank_code":item[6],
                        "bank_name":item[7],
                        "account_number":item[8],
                    };
                });

                // Return valid data
                resolve(validBulkTransferData);
            };
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException("Problem parsing input file."));
            };
        });
    }
}
