// Application types
export const LOADING_BUSINESS = 'LOADING_BUSINESS';
export const LOADING_SUBMITTING_BUSINESS = 'LOADING_SUBMITTING_BUSINESS';
export const RELOAD_BUSINESS = 'RELOAD_BUSINESS';

export const SUBMIT_REGISTERED_BUSINESS = 'SUBMIT_REGISTERED_BUSINESS';
export const SUBMIT_UNREGISTERED_BUSINESS = 'SUBMIT_UNREGISTERED_BUSINESS';
export const LOADING_TEAM_ROLES = 'LOADING_TEAM_ROLES';
export const EDIT_TEAM_MEMBER = 'EDIT_TEAM_MEMBER';
export const   GET_TEAM_DETAILS = '  GET_TEAM_DETAILS';
export const GET_TEAM_ROLES = 'GET_TEAM_ROLES';
export const LOADING_ADD_TEAM_MEMBER = 'LOADING_ADD_TEAM_MEMBER';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_FAILURE = 'ADD_TEAM_MEMBER_FAILURE'
export const SUBMIT_UNREGISTERED_COMPANY = 'SUBMIT_UNREGISTERED_COMPANY';
export const GET_BUSINESS_REGISTRATIONS = 'GET_BUSINESS_REGISTRATIONS';
export const GET_BUSINESS_REGISTRATION_CHARGE = 'GET_BUSINESS_REGISTRATION_CHARGE';
export const GET_BUSINESS_CATEGORIES = 'GET_BUSINESS_CATEGORIES';
export const GET_BUSINESS_TYPES = 'GET_BUSINESS_TYPES';
export const GET_BUSINESS_CAC_TYPES = 'GET_BUSINESS_CAC_TYPES';
export const GET_BUSINESS_TYPE_CAC_FEE = 'GET_BUSINESS_TYPE_CAC_FEE';
export const SELECT_BUSINESS_CAC_TYPE = 'SELECT_BUSINESS_CAC_TYPE';
export const CHECK_DRAFT_STATUS = 'CHECK_DRAFT_STATUS';
export const SUBMIT_BUSINESS_CAC_INFORMATION = 'SUBMIT_BUSINESS_CAC_INFORMATION';
export const CHECK_DRAFT_DETAILS = 'CHECK_DRAFT_DETAILS';
export const CANCEL_BUSINESS_CAC_REGISTRATION = 'CANCEL_BUSINESS_CAC_REGISTRATION';
export const SUBMIT_BUSINESS_CAC_OFFICER_INFORMATION = 'SUBMIT_BUSINESS_CAC_OFFICER_INFORMATION';
export const CAC_FEE_PAYMENT = 'CAC_FEE_PAYMENT';
export const GET_TEAM_LIST = 'GET_TEAM_LIST';
export const DELETE_TEAM_LIST = 'DELETE_TEAM_LIST';

export const LOADING_EDIT_TEAM_MEMBER = 'LOADING_EDIT_TEAM_MEMBER';
export const EDIT_TEAM_MEMBER_SUCCESS = "EDIT_TEAM_MEMBER_SUCCESS";
export const EDIT_TEAM_MEMBER_FAILURE = "EDIT_TEAM_MEMBER_FAILURE";

export const LOADING_TEAM_MEMBER_DETAILS = 'LOADING_TEAM_MEMBER_DETAILS';
export const TEAM_MEMBER_DETAILS_SUCCESS = "TEAM_MEMBER_DETAILS_SUCCESS";
export const TEAM_MEMBER_DETAILS_FAILURE = "TEAM_MEMBER_DETAILS_FAILURE";

export const LOADING_ACTIVATION_TOGGLE = 'LOADING_ACTIVATION_TOGGLE';
export const ACTIVATION_TOGGLE_SUCCESS = "ACTIVATION_TOGGLE_SUCCESS";
export const ACTIVATION_TOGGLE_FAILURE = "ACTIVATION_TOGGLE_FAILURE";

