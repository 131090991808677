import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import { pagination } from "../../helpers/helper";

import SearchInput from "../../components/inputs/SearchInput";

import { ButtonWithDropDown, ButtonWithDropDownItem } from "../../components/buttons/ButtonWithDropDown";
import { KebabDropdown } from "../../components/dropdowns/KebabDropdown";
import { markMultipleNotificationsAsRead, getInAppNotifications, markInAppNotificationAsRead, deleteSingleInAppNotifications, deleteAllInAppNotifications, markInAppNotificationAsUnRead } from '../../redux/notifications/notificationsActions';
import useNotifications from "../../hooks/useNotifications";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import NotificationsList from "./NotificationsList";
import NotificationDetails from "./NotificationDetails";
import Button from "../../components/buttons/Button";
import { useLocation } from "react-router-dom";



export default function Notifications() {

  const dispatch = useDispatch();
  const location = useLocation();
  const { markedAsRead, isInAppNotificationLoading, inAppNotificationsData } = useNotifications();
  const notification = useNotifications()
  const [, setReadNote] = useState([]);
  const [, setUnreadNote] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const [notificationDetails, setNotificationDetails] = useState(false);

  // Refresh notification list after mark as read request
  useEffect(() => {
    dispatch(getInAppNotifications());
    setSelectedCheckbox([]);
    setSelectAllCheckbox(false);
    // setNotificationDetails(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedAsRead]);
  useEffect(() => {
    if (inAppNotificationsData) {
      // Filter for read or unread notifications
      let unread = inAppNotificationsData?.filter((note) => {
        return note.is_read === 0;
      });
      let read = inAppNotificationsData?.filter((note) => {
        return note.is_read !== 0;
      });
      setUnreadNote(unread);
      setReadNote(read);

    }
  }, [inAppNotificationsData]);



  // Pagination Function And Control

  useEffect(() => {
    setTableData(pagination(inAppNotificationsData, 10, currentPage));

    // eslint-disable-next-line
  }, [currentPage, inAppNotificationsData, isInAppNotificationLoading]);
  useEffect(() => {
    if (location?.state?.notification && !location?.state?.viewAll){
      setNotificationDetails(true)
    }
    if ((location?.state?.notification && location?.state?.viewAll)){
      setNotificationDetails(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  // Search Function And Control
  const onSearch = (search) => {
    const filteredData = inAppNotificationsData?.filter((item) => {
      return item?.title?.toLowerCase().includes(search.toLowerCase()) || item?.body?.toLowerCase().includes(search.toLowerCase());
    });
    setTableData(pagination(filteredData ?? [], 10, currentPage));
  };


  const handleSelectAllCheckbox = (event) => {

    if (event.target.checked) {
      setSelectedCheckbox(inAppNotificationsData?.map((note) => note?.id));
      setSelectAllCheckbox(true);
    } else {
      setSelectedCheckbox([]);
      setSelectAllCheckbox(false);
    }
  };

  return (
    <div className="p-3">
      <CardTableHeaderComplex
        title={<span className="font-bold glade-black glade-heading-two">All Notifications</span>}
        searchBar={
          <SearchInput
            disabled={notification.isInAppNotificationLoading}
            onSearch={onSearch}
            placeholder={"Search Notifications"}
          />
        }
        selectBox={
          <CheckboxInput
            label={""}
            name={"all"}
            type={"checkbox"}
            checked={selectAllCheckbox}
            onChange={handleSelectAllCheckbox}
            placeholder={""}
            autoComplete={""}
            className={"appearance-none"}
            disabled={notificationDetails}
          />
        }
        rightButton={
          <div>
            <ButtonWithDropDown className='z-10' title="Actions" disabled={!selectedCheckbox?.length || notificationDetails}>
              <ButtonWithDropDownItem
                activeChild={<span className="glade-normal-text-two p-4 bg-white hover:bg-gray-200 glade-black rounded-md cursor-pointer text-center" onClick={() => (selectedCheckbox?.length === 1 && selectedItem?.is_read === 1) ? dispatch(markInAppNotificationAsUnRead(selectedItem?.id)) : (!selectedCheckbox?.length > 1  && selectedItem?.is_read === 0) ? dispatch(markInAppNotificationAsRead(selectedItem?.id)) : dispatch(markMultipleNotificationsAsRead({ ids: selectedCheckbox }))
                }
                  disabled={!selectedCheckbox?.length || notificationDetails}
                >
                  {selectedCheckbox?.length > 1 && selectedCheckbox?.length === inAppNotificationsData?.length ? 'Mark all as read' : selectedCheckbox?.length === 1 && selectedItem?.is_read === 1 ? 'Mark as unread' : 'Mark as read'}
                </span>}
              />
              <ButtonWithDropDownItem
                activeChild={<span className="glade-normal-text-three bg-white hover:bg-red-200 glade-red  py-2 px-4 flex justify-center items-center text-center rounded-lg whitespace-nowrap glade-normal-text-two p-4 rounded-md cursor-pointer"
                  disabled={!selectedCheckbox?.length || notificationDetails}
                  onClick={() => {
                    if (selectedCheckbox?.length > 1) {
                      dispatch(deleteAllInAppNotifications(selectedCheckbox));
                    } else {
                      dispatch(deleteSingleInAppNotifications(selectedItem?.id));
                    }
                  }}

                >
                  {selectedCheckbox?.length > 1 && selectedCheckbox?.length === inAppNotificationsData?.length ? 'Delete all' : 'Delete'}
                </span>}

              />
            </ButtonWithDropDown>
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className='z-10' disabled={isInAppNotificationLoading}>
              <Button.Base
                onClick={() => !selectedCheckbox?.length > 1 ? dispatch(markInAppNotificationAsRead(selectedItem?.id)) : dispatch(markMultipleNotificationsAsRead({ ids: selectedCheckbox }))
                } disabled={!selectedCheckbox?.length || notificationDetails}
                title={selectedCheckbox?.length === inAppNotificationsData?.length ? 'Mark all as read' : 'Mark as read'}
                className="glade-normal-text-two p-4 bg-white hover:bg-gray-200 glade-black rounded-md cursor-pointer"
              />
              <Button.Danger
                onClick={() => {
                  if (selectedCheckbox?.length > 1) {
                    dispatch(deleteAllInAppNotifications(selectedCheckbox));
                  } else if (selectedItem) {
                    dispatch(deleteSingleInAppNotifications(selectedItem?.id));
                  }
                }}
                disabled={!selectedCheckbox?.length || notificationDetails}
                title={selectedCheckbox?.length > 1 ? 'Delete all' : 'Delete'}
                className="glade-normal-text-two p-4 rounded-md cursor-pointer"
              />


            </KebabDropdown>
          </div>
        }
      />

      {!notificationDetails && <NotificationsList setNotificationDetails={setNotificationDetails} tableData={tableData} setSelectedItem={setSelectedItem} setSelectedCheckbox={setSelectedCheckbox} setSelectAllCheckbox={setSelectAllCheckbox} selectedCheckbox={selectedCheckbox} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
      {notificationDetails && <NotificationDetails item={location.pathname === '/dashboard/notifications' && selectedItem ? selectedItem : location?.state?.notification ? location?.state?.notification :selectedItem} setNotificationDetails={setNotificationDetails} />}

    </div>
  );
}
