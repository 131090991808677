import React from "react";
import { isEmpty } from "../../helpers/helper";
import { useHistory } from "react-router-dom";
import useHome from "../../hooks/useHome";
import { AiOutlineDownload } from "react-icons/ai";
import { FaChevronRight } from "react-icons/fa";
import RecentTransactionCard from "./RecentTransactionCard";
import useTeam from "../../hooks/useTeam";
import { Fragment } from "react";

export default function HomeTransaction({ transactions }) {
  const history = useHistory();
  const home = useHome();
  const { team_user, permissions } = useTeam();

  return (
    <div className="p-3 flex flex-col">
      <div className="mt-3 glade-bg-light-grey flex justify-between items-center p-2 mx-2">
        <h3 className="glade-heading-three glade-black">Recent Transactions</h3>
        <div className="flex space-x-4">
          {(!team_user?.employee?.is_teams ||
            permissions?.includes("transactions page")) && (
            <Fragment>
              <button
                type="button"
                onClick={() =>
                  !permissions?.includes("transactions page")
                    ? history.push("/dashboard/transactions")
                    : history.push("/teams/transactions")
                }
                disabled={isEmpty(transactions) || home.isLoading}
              >
                <div
                  className={`flex items-center ${
                    isEmpty(transactions) || home.isLoading
                      ? "glade-grey-text"
                      : "glade-blue"
                  }`}
                >
                  <span className="mr-1">
                    <AiOutlineDownload size={25} />
                  </span>
                  <span>Statement</span>
                </div>
              </button>
              <button
                type="button"
                onClick={() =>
                  !permissions?.includes("transactions page")
                    ? history.push("/dashboard/transactions")
                    : history.push("/teams/transactions")
                }
                disabled={isEmpty(transactions) || home.isLoading}
              >
                <div
                  className={`flex items-center ${
                    isEmpty(transactions) || home.isLoading
                      ? "glade-grey-text"
                      : "glade-blue"
                  }`}
                >
                  <span className="ml-3 glade-normal-text-two">View All</span>
                  <span className="ml-3">
                    <FaChevronRight />
                  </span>
                </div>
              </button>
            </Fragment>
          )}
        </div>
      </div>
      <RecentTransactionCard transactions={transactions} />
    </div>
  );
}
