import React, { useContext } from 'react';
import styled from 'styled-components';
import { TransferFormContext } from '../../context/TransferFormContext';
import ForeignTransferForm from './ForeignTransferForm';
import LocalNGNTransfer from './LocalNGNTransfer';
import SenderFlag from "../../components/transfer/SenderFlag";


const ForeignLocalTransfer = () => {
  const { currencies, repeatTransaction } = useContext(TransferFormContext);
  return (
    <Div>
      <section className="page-title mb-9 items-center flex flex-col">
      <h2 className='text-3xl font-bold'>
  {repeatTransaction ? "Repeat Transaction" : "Make Transfer"}
</h2>
{!repeatTransaction && <p>Local money transfer</p>}

      </section>
      {!repeatTransaction && <SenderFlag />}
      {currencies?.activeCurrency?.currency === "NGN" ? <LocalNGNTransfer /> : <ForeignTransferForm />}
    </Div>
  );
};

export default ForeignLocalTransfer;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 4rem;
  width: 445px;
`;