import React from "react";
import { useDispatch } from "react-redux";
import PlainModal from "../../components/modals/PlainModal";
import Button from "../../components/buttons/Button";
import { deletePayday } from "../../redux/payroll/payrollActions";
import { isArray } from "lodash";
import usePayroll from "../../hooks/usePayroll";

export default React.forwardRef(function PaydayDeleteModal(props, ref) {
  // Ref Handler
  const plainModalRef = React.useRef();
  const {isLoadingDeletePayday} = usePayroll();
  React.useImperativeHandle(ref, () => ({
    open: (value) => {
      setInput(value);
      plainModalRef.current.open();
    },
    close: () => {
      setInput({});
      plainModalRef.current.close();
    },
    toggle: (value) => {
      setInput(value);
      plainModalRef.current.toggle();
    },
  }));

  const dispatch = useDispatch();
  const [input, setInput] = React.useState({});

  const onsubmit = () => {
    const onSuccess=()=>{
        plainModalRef.current.close();
    }
    if (input?.payroll_id) {
      dispatch(deletePayday({ payday_id: input.payroll_id }, true, onSuccess));
    }
    if (isArray(input)) {
      input.forEach((item, key) => {
        dispatch(deletePayday(
            { payday_id: item?.payroll_id },  
            key === (input?.length -1),
            onSuccess
            ));
      });
    }
  };

  return (
    <PlainModal
      ref={plainModalRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitleSection={<React.Fragment></React.Fragment>}
      modelContentSection={
        <div className="p-6">
          <div className="flex flex-col space-y-3">
            <h4>Are you sure you want to delete this payroll?</h4>
            <div className="flex justify-between gap-x-6">
              <div className="w-1/2">
                <Button.Transparent
                  title={"Cancel"}
                  onClick={() => plainModalRef.current.close()}
                  className="w-full py-3"
                />
              </div>
              <div className="w-1/2">
                <Button.Danger
                  title={"Delete"}
                  onClick={() => onsubmit()}
                  className="w-full py-3"
                  style={{ backgroundColor: "#FF0021", color: "#fff" }}
                  disabled={isLoadingDeletePayday}
                  loading={isLoadingDeletePayday}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
});
