import React from "react";
import Button from "../../../components/buttons/Button";
import useSettings from "../../../hooks/useSettings";
import TextInput from "../../../components/inputs/TextInput";
import SelectInput from "../../../components/inputs/SelectInput";
import { isArray } from "../../../helpers/helper";
import { businessInformationNonNigeriaValidation
} from "../../../validation/settings/settingsValidation";
import useUser from "../../..//hooks/useUser";
import useBusiness from "../../../hooks/useBusiness";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBusinessCategories, getBusinessTypes } from "../../../redux/business/businessActions";
import {submitBusinessInformationNonNigeria
} from "../../../redux/settings/settingsActions";
import {
  getApprovedStates,
  getApprovedLGAs
} from "../../../redux/countries/countriesActions"
import useCountries from "../../../hooks/useCountry";

const BusinessInformationNonNigeria = ({ setFormLevel, input, setInput }) => {
  const settings = useSettings();
  const [inputErrors, setInputErrors] = React.useState({
    business_type: '',
    business_type_error: false,
    industry: '',
    industry_error: false,
    secondary_phone: '',
    secondary_phone_error: false,
    id_number: '',
    id_number_error: false,
    business_address: '',
    business_address_error: false,
    business_state: '',
    business_state_error: false,
    postal_code: '',
    postal_code_error: false
  });
  const business = useBusiness();
  const dispatch = useDispatch();
  const user = useUser();
  const countries = useCountries();


  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputErrors({ ...inputErrors, [`${e.target.name}_error`]: false });
  };
  const onSubmit = () => {
    let inputValid = businessInformationNonNigeriaValidation(input);

    if (isArray(inputValid)) {
      inputValid.map((field) => 
        setErrorField(field)
      );
    } else {
      let payload = {
                business_type: input.business_type,
            industry: input.industry,
            // business_description: input.business_description,
            business_phone: input.secondary_phone,
            company_website: input?.company_website,
            address: input.business_address,
            state: input.business_state,
            country_code: input.country_code,
            ein_number: input.id_number,
            postal_code: input.postal_code
      }
      if(input?.address_line2){
            payload = {...payload, secondary_address: input.address_line2, }
      }
      // Handle Submission
      dispatch(
        submitBusinessInformationNonNigeria(
         payload, () => { setFormLevel("business documents"); }
        )
      )

    }
  };

  const setErrorField = (field) => {
    if (field.toLowerCase().includes('company type')) {
      inputErrors.business_type_error = true;
      inputErrors.business_type = field;
    } if (field.toLowerCase().includes('business address')) {
      inputErrors.business_address_error = true;
      inputErrors.business_address = field;
    } if (field.toLowerCase().includes('state')) {
      inputErrors.business_state_error = true;
      inputErrors.business_state = field;
    } if (field.toLowerCase().includes('industry')) {
      inputErrors.industry_error = true;
      inputErrors.industry = field;
    } if (field.toLowerCase().includes('phone number')) {
      inputErrors.secondary_phone_error = true;
      inputErrors.secondary_phone = field;
    } if (field.toLowerCase().includes('postal code')) {
      inputErrors.postal_code_error = true;
      inputErrors.postal_code = field;
    } if (field.toLowerCase().includes('id/ein number')) {
      inputErrors.id_number_error = true;
      inputErrors.id_number = field;
    }
    setInputErrors({...inputErrors});
  } 
    
  useEffect(() => {
    dispatch(getBusinessCategories());
    dispatch(getBusinessTypes());


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    input.compliance_country && dispatch(getApprovedStates({country_id:input.compliance_country}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.compliance_country]);


  return (
    <>
      <div className="px-12">
        <h3 className="font-bold mb-5 text-glade-black-800">
          Provide information about your business
        </h3>
      </div>
      <div className="w-full space-y-6 border-b border-gray-300 px-12 pb-8">
        {input.country_code.toLowerCase() !== 'nga' &&
          <div>
            <TextInput
              label={"Business Name"}
              id={"business_name"}
              name={"business_name"}
              value={user?.selectedBusinessOrPersonalAccount?.business_name ?? ""}
              className={"glade-bg-input-grey glade-grey-cool "}
              disabled={true}              
            />
          </div>
        }
        <div>
          <SelectInput
            label={"Company Type"}
            id={"business_type"}
            name={"business_type"}
            value={input.business_type ?? ""}
            onChange={onChange}
            autoComplete={"business_type"}
            className={"appearance-none"}
            errorState={inputErrors.business_type_error}
          >
            <option value={""} disabled={true}>Select Type</option>
            {isArray(business?.businessTypes) && business?.businessTypes.map((item, key) => {
              return (
                <option key={key} value={item}>{item}</option>
              );
            })}
          </SelectInput>
          {inputErrors?.business_type_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors?.business_type}
            </div>
          )}
        </div>
        <div>
          <SelectInput
            label={"Industry"}
            id={"industry"}
            name={"industry"}
            value={input.industry ?? ""}
            onChange={onChange}
            autoComplete={"industry"}
            className={"appearance-none"}
            errorState={inputErrors.industry_error}
          >
            <option value={''} disabled={true}>Select Industry</option>
            {isArray(business?.businessCategories) && business?.businessCategories.map((item, key) => {
              return (
                <option key={key} value={item}>{item}</option>
              );
            })}
          </SelectInput>
          {inputErrors.industry_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors.industry}
            </div>
          )}
        </div>

        <div>
          <TextInput
            leftSection={
              <span className="glade-button-gray glade-home-dark self-center border border-gray-300 rounded-sm p-2">
                {input.secondary_phone_code}
              </span>
            }
            label={"Business Phone Number"}
            id={"secondary_phone"}
            name={"secondary_phone"}
            value={input.secondary_phone ?? ""}
            onChange={onChange}
            placeholder={"7029987736"}
            autoComplete={"secondary_phone"}
            className={"appearance-none"}
            errorState={inputErrors.secondary_phone_error}
          />
          {inputErrors.secondary_phone_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors.secondary_phone}
            </div>
          )}
        </div>
        <div>
          <TextInput
            label={"Company Website"}
            id={"company_website"}
            name={"company_website"}
            value={input.company_website ?? ""}
            onChange={onChange}
            placeholder={"Optional"}
            autoComplete={"company_website"}
            className={"appearance-none"}
            // errorState={inputErrors.company_website}
          />
        </div>
        <div>
          <TextInput
            label={"ID Number/EIN"}
            id={"id_number"}
            name={"id_number"}
            value={input.id_number ?? ""}
            onChange={onChange}
            placeholder={"Enter ID Number"}
            autoComplete={"id_number"}
            className={"appearance-none"}
            errorState={inputErrors.id_number_error}
          />
          {inputErrors.id_number_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors.id_number}
            </div>
          )}
        </div>
       
        <div>
          <TextInput
            label={"Business Address"}
            id={"business_address"}
            name={"business_address"}
            value={input.business_address ?? ""}
            onChange={onChange}
            placeholder={"Enter Business Address "}
            autoComplete={"business_address"}
            className={"appearance-none"}
            errorState={inputErrors.business_address_error}
          />
          {inputErrors.business_address_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors.business_address}
            </div>
          )}
        </div>
        <div>
          <TextInput
            label={"Address Line 2"}
            id={"address_line2"}
            name={"address_line2"}
            value={input.address_line2 ?? ""}
            onChange={onChange}
            placeholder={"Optional "}
            autoComplete={"address_line2"}
            className={"appearance-none"}
          />
          
        </div>
        <div>
          <SelectInput
            label={"State/Province"}
            id={"business_state"}
            name={"business_state"}
            value={input.business_state ?? ""}
            onChange={onChange}
            autoComplete={"business_state"}
            className={"appearance-none"}
            errorState={inputErrors.business_state_error}
          >
            <option value={""} disabled={true}>
              Select A State
            </option>
            {isArray(countries.getStates) &&
              countries.getStates?.map((state, index) => (
                <option key={index} value={state.state_name}>
                  {state.state_name}
                </option>
              )) 
            }
            
          </SelectInput>
          {inputErrors.business_state_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors.business_state}
            </div>
          )}
        </div>


          <div>
            <TextInput
              label={"Postal/ZIP Code"}
              id={"postal_code"}
              name={"postal_code"}
              value={input.postal_code ?? ""}
              onChange={onChange}
              placeholder={"Enter Postal Code"}
              autoComplete={"postal_code"}
              className={"appearance-none"}
              errorState={inputErrors.postal_code_error}
            />
            {inputErrors.postal_code_error && (
              <div className="text-glade-red-400 text-sm mt-2">
                {inputErrors.postal_code}
              </div>
            )}

          </div>
      </div>
      <div className="p-6">
        <div className="float-right">
          <Button.Blue
            onClick={onSubmit}
            className="w-full mr-3"
            title={"Next"}
            disabled={settings.isLoadingCompliance}
            loading={settings.isLoadingCompliance}
          />
        </div>
      </div>
    </>
  );
};

export default BusinessInformationNonNigeria