import React, { useState, forwardRef, useRef } from "react";
import { useDispatch } from "react-redux";
import PlainModal from "../../components/modals/PlainModal";
import useUser from "../../hooks/useUser";
import Button from "../../components/buttons/Button";
import SelectInput from "../../components/inputs/SelectInput";
import ReceiverFlag from "../../components/transfer/ReceiverFlag";
import NumericInput from "../../components/inputs/NumericInput";
import { currencySymbolEnum } from "../../helpers/helper";
import {
  getRecurringList,
  updateRecurringData,
} from "../../redux/recurringTransfer/recurringTransferActions";
import useRecurring from "../../hooks/useRecurring";

export default forwardRef(function EditModal(props, ref) {
  const dispatch = useDispatch();
  const user = useUser();
  const { isLoadingRecurringEdit } = useRecurring();
  // Ref Handler
  const plainModalRef = useRef();
  React.useImperativeHandle(ref, () => ({
    open: (value) => {
      setItem(value);      
      setInput({
        frequency: value?.frequency,
        transfer_type: value?.transfer_type,
        start_date: value?.start_date?.split(" ")[0],
        amount: value?.amount,
      });
      plainModalRef.current.open();
    },
    close: () => plainModalRef.current.close(),
    toggle: (value) => {
      setItem(value);
      plainModalRef.current.toggle();
    },
  }));

  const [input, setInput] = useState({
    pin: "",
  });
  const [item, setItem] = useState({});
  const availableBalance =
    user &&
    user.selectedBusinessOrPersonalAccount.hasOwnProperty("selectedCurrency")
      ? user?.selectedBusinessOrPersonalAccount?.selectedCurrency
      : user?.selectedBusinessOrPersonalAccount?.accounts[0];

  const handleChange = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  const onSubmit = () => {
    let postData = { ...input };
    if (item.transaction_type === "airtime") {
      postData["receipient_data"] = item?.receipient_data;
      postData["paycode"] = item?.paycode;
    } else if (item?.recurring_type === "bulk") {
      postData["payload"] = item?.recipients;
      postData["group_name"] = item?.group_name;
    }
    dispatch(
      updateRecurringData(
        {
          ...postData,
          start_date: input?.start_date + " 12:00:00",
        },
        {
          ...item?.selectedType,
          reference: item.reference ?? item?.group_reference,
        },

        //on success function.
        () => {
          dispatch(
            getRecurringList(
              { current_page: 1, per_page: 10, paginate: 1 },
              { ...item?.selectedType }
            )
          );
          plainModalRef.current.close();
        }
      )
    );
  };

  return (
    <PlainModal
      ref={plainModalRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitle={
        <div className="text-center mt-2">
          <span className="font-bold">
            Edit {item.transaction_type} to{" "}
            {item?.beneficiary_account_name ??
              item?.group_name ??
              item?.receipient_data}
          </span>
        </div>
      }
      modelContentSection={
        <div className="mt-4 px-12 ">
          <div className="text-left py-2">
            <label>Select Frequency</label>
            <SelectInput
              id={"frequency"}
              name={"frequency"}
              value={input?.frequency ?? ""}
              onChange={(e) => handleChange(e)}
              className={"appearance-none"}
              errorState={""}
            >
              <option value={"daily"}>Daily</option>
              <option value={"weekly"}>Weekly</option>
              <option value={"monthly"}>Monthly</option>
            </SelectInput>
          </div>
          <div className="text-left py-2">
            <label htmlFor="start_date">Next Payment date</label>
            <input
              type="date"
              id="start_date"
              name="start_date"
              className="focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"
              value={input?.start_date}
              onChange={handleChange}
            />
          </div>
          {item?.recurring_type !== "bulk" ? (
            <div className="text-left py-2">
              <label htmlFor="amount">Amount</label>
              <NumericInput
                id="amount"
                type={"number"}
                placeholder={"Enter amount"}
                name={"amount"}
                value={input?.amount}
                onChange={handleChange}
                leftSection={
                  <div className="border rounded-sm px-4 flex gap-2 items-center">
                    <span className="flag">
                      <ReceiverFlag flagCode={"NG"} />
                    </span>
                    <span>NGN</span>
                  </div>
                }
              />
              <div className="current-balance border glade-bg-grey p-2 rounded-sm glade-green">
                <span>
                  {"NGN"} Balance - {currencySymbolEnum["NGN"]}{" "}
                  {Number(availableBalance?.balance).toLocaleString("en-US")}
                </span>
                {/* {transferForm?.amount && fundsInsufficient && <span className='insufficient'>Insufficient funds</span>} */}
              </div>
            </div>
          ) : (
            <div className="text-left">
              <label htmlFor="amount">Recipients</label>
              {item?.recipients.map((receiver, key) => {
                return (
                  <div className="text-left py-2" key={key}>
                    <h5 className="border p-2 rounded-sm">
                      {receiver?.beneficiary_account_name} |{" "}
                      {receiver?.beneficiary_account_number} |{" "}
                      {receiver?.beneficiary_bank_name ?? "Gladefinance"}
                    </h5>
                  </div>
                );
              })}
              <div className="text-left py-2">
                <label htmlFor="amount">Amount</label>
                <NumericInput
                  id="amount"
                  type={"number"}
                  placeholder={"Enter amount"}
                  name={"amount"}
                  value={input?.amount}
                  onChange={handleChange}
                  leftSection={
                    <div className="border rounded-sm px-4 flex gap-2 items-center">
                      <span className="flag">
                        <ReceiverFlag flagCode={"NG"} />
                      </span>
                      <span>NGN</span>
                    </div>
                  }
                />
                <div className="current-balance border glade-bg-grey p-2 rounded-sm glade-green">
                  <span>
                    {"NGN"} Balance - {currencySymbolEnum["NGN"]}{" "}
                    {Number(availableBalance?.balance).toLocaleString("en-US")}
                  </span>
                  {/* {transferForm?.amount && fundsInsufficient && <span className='insufficient'>Insufficient funds</span>} */}
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-center px-12 py-4">
            <Button.Blue
              title={"Continue"}
              type={"button"}
              className={"py-3 px-4"}
              onClick={onSubmit}
              disabled={isLoadingRecurringEdit}
              loading={isLoadingRecurringEdit}
            />
          </div>
        </div>
      }
    />
  );
});
