import {
  LOADING_TRANSACTION,
  RELOAD_TRANSACTION,
  GET_STATEMENTS,
  GET_TRANSACTIONS,
  GET_TRANSACTION_LIST,
  GET_TRANSACTION_BY_ID
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload:false,
  statementIndex:[],
  transactionIndex:[],
  transactionById:{},
  transactionList:{},
};

export default function transactionsReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_TRANSACTION:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_TRANSACTION:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case GET_STATEMENTS:
      return {
        ...state,
        statementIndex: action.payLoad.data.data,
      };

    case GET_TRANSACTIONS:
      return {
        ...state,
        transactionIndex: action.payLoad.data.data,
      };

    case GET_TRANSACTION_LIST:
      return {
        ...state,
        transactionList: action.payLoad.data.data,
      };

    case GET_TRANSACTION_BY_ID:
      return {
        ...state,
        transactionById: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
