import { useState, useEffect, Fragment } from "react";
import Button from "../../components/buttons/Button";
import SelectInput from "../../components/inputs/SelectInput";
import useBills from "../../hooks/useBills";
import {
  getAllBillTypes,
  resolveCableTvDetails,
  resolveInternetAccountDetails,
  resolveMeterDetails,
} from "../../redux/bills/billsActions";
import { useDispatch } from "react-redux";
import NumericInput from "../../components/inputs/NumericInput";
import TextInput from "../../components/inputs/TextInput";
import ErrorMessage from "../../validation/fund-transfer/ErrorMessage";
import useAirtime from "../../hooks/useAirtime";

const SelectRecurringBillForm = ({ input, setInput, setStep, type }) => {
  const bills = useBills();
  const airtime = useAirtime();
  const dispatch = useDispatch();

  const [billerItems, setBillerItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [billers, setBillers] = useState([]);

  useEffect(() => {
    dispatch(getAllBillTypes());
  }, []);

  useEffect(() => {
    if (
      input?.category_code === "electricity" &&
      input?.receipient_data?.length === 11 &&
      input?.paycode
    ) {
      dispatch(
        resolveMeterDetails(
          {
            receipient_data: input.receipient_data,
            paycode: input.paycode,
          },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              receipient_name: resolveAccountDetails?.custName ?? "",
            }),
          (loading) => setLoading(loading)
        )
      );
    } else if (
      input?.category_code === "internet" &&
      input?.phone_number?.length === 11 &&
      input?.paycode
    ) {
      dispatch(
        resolveInternetAccountDetails(
          {
            account_number: input?.phone_number,
            paycode: input?.paycode,
          },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              receipient_name:
                resolveAccountDetails.customer_name ??
                resolveAccountDetails.custName,
            })
        )
      );
    } else if (
      input?.category_code === "tv" &&
      input?.card_number?.length === 10 &&
      input?.paycode &&
      input?.amount > 0
    ) {
      dispatch(
        resolveCableTvDetails(
          {
            card_iuc_number: input.card_number,
            paycode: input.paycode,
            amount: input.amount,
          },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              receipient_name: resolveAccountDetails.custName,
            }),
          (loading) => setLoading(loading)
        )
      );
    } else {
      setInput({ ...input, receipient_name: "" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    input?.category_code,
    input?.card_number,
    input?.receipient_data,
    input?.phone_number,
    input?.paycode,
  ]);

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const onSelectOfCategory = (e) => {
    let data = JSON.parse(
      e.target[e.target.selectedIndex].getAttribute("data")
    );

    setInput({
      ...input,
      category_id: data.id,
      category_code: data.category_code,
      biller_id: "",
      paycode: "",
      amount: 0,
      biller_item_base_amount: 0,
    });

    let billers = bills?.billTypes?.bills?.filter(
      (item) => item.category_id.toString() === data.id.toString()
    );
    setBillers(billers ?? []);
    setBillerItems([]);
  };
  const onSelectOfBillerItem = (e) => {
    let data = JSON.parse(
      e.target[e.target.selectedIndex].getAttribute("data")
    );

    setInput({
      ...input,
      paycode: data.paycode,
      biller_item_keycode: data.keycode,
      biller_item_base_amount: parseInt(data.base_amount),
      amount: parseInt(data.base_amount),
    });

    if (input.category_code === "internet") {
      dispatch(
        resolveInternetAccountDetails(
          { account_number: "000000000000", paycode: data.paycode, amount: 0 },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              paycode: data.paycode,
              biller_item_keycode: data.keycode,
              receipient_name: resolveAccountDetails.custName,
              biller_item_base_amount: parseInt(
                resolveAccountDetails.amount ?? data.base_amount
              ),
              amount: parseInt(
                resolveAccountDetails.amount ?? data.base_amount
              ),
            })
        )
      );
    }
  };
  const onSelectOfBiller = (e) => {
    let data = JSON.parse(
      e.target[e.target.selectedIndex].getAttribute("data")
    );

    setInput({
      ...input,
      biller_id: data.id,
      biller_reference: data.reference,
      paycode: "",
      amount: 0,
      biller_item_base_amount: 0,
    });

    let billerItems = bills?.billTypes?.items?.filter(
      (item) => item.bill_id.toString() === data.id.toString()
    );
    setBillerItems(billerItems ?? []);
  };

  return (
    <div className="md:w-3.5/12">
      <p className="mt-12 glade-heading-two glade-home-dark">
        <span className="font-bold ">Enter Recurring Payment Details</span>
      </p>
      <div className="text-left flex flex-col gap-4 py-12">
        <div className="text-left py-2">
          <label>Select Category</label>
          <SelectInput
            id={"category_id"}
            name={"category_id"}
            value={input?.category_id ?? ""}
            onChange={onSelectOfCategory}
            className={"appearance-none"}
          >
            <option value={""} disabled>Select Category</option>
            {type !== "airtime" && bills?.billTypes?.categories?.map((item, key) => {
              return (
                <option key={key} data={JSON.stringify(item)} value={item.id}>
                  {item.category_title}
                </option>
              );
            })}
            {type === "airtime" && airtime?.billTypes?.categories?.map((item, key) => {
              return (
                <option
                  key={`key-${key}`}
                  data={JSON.stringify(item)}
                  value={item.id}
                >
                  {item.category_title}
                </option>
              );
            })}
          </SelectInput>
        </div>
        <SelectInput
          label={"Select Biller"}
          id={"biller"}
          name={"biller"}
          value={input.biller_id}
          onChange={onSelectOfBiller}
          autoComplete={"biller"}
          className={"appearance-none"}
        >
          <option value={""} disabled={true}>
            Select A Biller
          </option>
          {billers?.map((item, key) => {
            return (
              <option key={key} data={JSON.stringify(item)} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </SelectInput>

        <SelectInput
          label={"Select Bill"}
          id={"biller_item"}
          name={"biller_item"}
          value={input.paycode}
          onChange={onSelectOfBillerItem}
          autoComplete={"biller_item"}
          className={"appearance-none"}
        >
          <option value={""} disabled={true}>
            Select A Bill
          </option>
          {billerItems?.map((item, key) => {
            return (
              <option
                key={key}
                data={JSON.stringify(item)}
                value={item.paycode}
              >
                {item.title + " " + item.keycode}
              </option>
            );
          })}
        </SelectInput>

        {input.category_code === "electricity" && (
          <Fragment>
            <TextInput
              label={"Meter Number"}
              id={"receipient_data"}
              name={"receipient_data"}
              type={"tel"}
              value={input.receipient_data}
              onChange={onChange}
              placeholder={"Enter meter number"}
              autoComplete={"receipient_data"}
              className={"appearance-none"}
            />

            <TextInput
              label={"Customer Name"}
              id={"receipient_name"}
              name={"receipient_name"}
              disabled={true}
              type={"text"}
              value={input.receipient_name}
              onChange={() => {}}
              placeholder={loading ? "please wait ..." : ""}
              autoComplete={"receipient_name"}
              className={"appearance-none"}
            />

            <NumericInput
              leftSection={
                <span className="self-center border border-gray-300 rounded-sm p-2">
                  NGN
                </span>
              }
              label={"Amount"}
              id={"amount"}
              name={"amount"}
              type={"number"}
              min={"1000"}
              value={input.amount}
              onChange={onChange}
              placeholder={"Enter amount"}
              autoComplete={"amount"}
              className={"appearance-none"}
            />
            {input.amount > 0 && input.amount < 1000 && (
              <ErrorMessage msg={"Minimum amount is NGN1000"} />
            )}
          </Fragment>
        )}

        {input.category_code === "internet" && (
          <Fragment>
            <TextInput
              label={"Phone Number"}
              id={"phone_number"}
              name={"phone_number"}
              type={"tel"}
              value={input.phone_number}
              onChange={onChange}
              placeholder={"Enter phone number"}
              autoComplete={"phone_number"}
              className={"appearance-none"}
            />

            <TextInput
              label={"Customer Name"}
              id={"receipient_name"}
              name={"receipient_name"}
              disabled={true}
              type={"text"}
              value={input.receipient_name}
              onChange={() => {}}
              placeholder={loading ? "please wait ..." : ""}
              autoComplete={"receipient_name"}
              className={"appearance-none"}
            />

            <NumericInput
              label={"Amount"}
              id={"amount"}
              name={"amount"}
              type={"number"}
              min={"0"}
              value={input.amount}
              onChange={onChange}
              placeholder={"Enter amount"}
              autoComplete={"amount"}
              className={"appearance-none"}
            />
          </Fragment>
        )}

        {input.category_code === "tv" && (
          <Fragment>
            <TextInput
              label={"Card Number"}
              id={"card_number"}
              name={"card_number"}
              type={"tel"}
              value={input.card_number}
              onChange={onChange}
              placeholder={"Enter card number"}
              autoComplete={"card_number"}
              className={"appearance-none"}
            />

            <TextInput
              label={"Customer Name"}
              id={"receipient_name"}
              name={"receipient_name"}
              disabled={true}
              type={"text"}
              value={input.receipient_name}
              onChange={() => {}}
              placeholder={loading ? "please wait ..." : ""}
              autoComplete={"receipient_name"}
              className={"appearance-none"}
            />

            <NumericInput
              label={"Amount"}
              id={"amount"}
              name={"amount"}
              type={"number"}
              min={"0"}
              value={input.biller_item_base_amount}
              onChange={() => {}}
              disabled={true}
              placeholder={"Enter amount"}
              autoComplete={"amount"}
              className={"appearance-none"}
            />
          </Fragment>
        )}
        {input.category_code === "airtime" && (
          <Fragment>
            <TextInput
              label={"Phone Number"}
              id={"receipient_data"}
              name={"receipient_data"}
              type={"tel"}
              value={input.receipient_data}
              onChange={onChange}
              placeholder={"Enter phone number"}
              autoComplete={"receipient_data"}
              className={"appearance-none"}
            />

            <NumericInput
              leftSection={
                <span className="self-center border border-gray-300 rounded-sm p-2">
                  NGN
                </span>
              }
              label={"Amount"}
              id={"amount"}
              name={"amount"}
              type={"number"}
              min={"0"}
              value={input.amount ?? ""}
              onChange={onChange}
              placeholder={"Enter amount"}
              autoComplete={"amount"}
              className={"appearance-none"}
            />
          </Fragment>
        )}
        <div className="text-left py-2">
          <label>Select Frequency</label>
          <SelectInput
            id={"frequency"}
            name={"frequency"}
            value={input?.frequency ?? ""}
            onChange={(e) => onChange(e)}
            className={"appearance-none"}
          >
            <option value={""}>Select Frequency</option>
            <option value={"daily"}>Daily</option>
            <option value={"weekly"}>Weekly</option>
            <option value={"monthly"}>Monthly</option>
          </SelectInput>
        </div>
        <div className="text-left py-2">
          <label htmlFor="start_date">Start date</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            className="focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"
            value={input?.start_date}
            onChange={onChange}
          />
        </div>
        <div className="text-left py-2">
          <label htmlFor="start_date">End date (Optional)</label>
          <input
            type="date"
            id="end_date"
            name="end_date"
            className="focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"
            value={input?.end_date}
            placeholder="Optional"
            onChange={onChange}
          />
        </div>
        <div className="text-left py-2">
          <label htmlFor="start_time">Time</label>
          <input
            type="time"
            id="start_time"
            name="start_time"
            className="focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"
            value={input?.start_time}
            onChange={onChange}
          />
        </div>
        <div className="flex justify-center mt-2">
          <Button.Blue
            className={"px-3"}
            title={"Continue"}
            onClick={() => setStep(4)}
            disabled={
              !input?.start_time ||
              !input?.start_date ||
              !input?.frequency ||
              !input?.amount ||
              (input?.category_code === "airtime" &&
                input?.receipient_data?.length < 6) ||
              (input?.category_code !== "airtime" &&
                !input?.receipient_name)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SelectRecurringBillForm;
