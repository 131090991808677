import { useState } from "react";
import styled from "styled-components";

const Tooltip = ({ children }) => {
  const [isHover, setIsHover] = useState(false);

  const open = () => {
    // console.log('Mouse Enters', children);
    setIsHover(true);
  };

  const close = () => setIsHover(false);

  return (
    <Wrapper onMouseEnter={open} onMouseLeave={close}>
      {children[0]}
      {isHover && <Content>{children[1]}</Content>}
    </Wrapper>
  );
};

export default Tooltip;

const Wrapper = styled.section`
  display: inline-block;
`;

const Content = styled.section`
  border: 2px solid black;
  position: absolute;
  display: flex;
  background-color: #fff;
  width: 400px;
  border-radius: 5px;
  padding: 1rem;
  height: 220px;
  z-index: 200;
  top: -15rem;
  right: 4rem;

  &:after {
    content: "",
    position: absolute;
    left: 100%;
    margin-left: -5px;
    top: 50%;
    transform: translateY(-50%);
    border: 10px solid #000;
    border-color: transparent black transparent transparent;
  }
`;

