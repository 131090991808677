import {
  LOADING_TRANSACTION_SPLIT,
  RELOAD_TRANSACTION_SPLIT,
  GET_ALL_SPLIT_ACCOUNT,
  GET_SPLIT_ACCOUNT_DETAILS,
  STORE_SPLIT_PAYOUT_ACCOUNT,
  UPDATE_SPLIT_PAYOUT_ACCOUNT,
  REMOVE_SPLIT_PAYOUT_ACCOUNT,
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload:false,
  splitAccountIndex: [],
  splitAccount: {},
  storeSplitAccount:{},
  updateSplitAccount:{},
  removeSplitAccount:{},
};

export default function splitAccountReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_TRANSACTION_SPLIT:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_TRANSACTION_SPLIT:
      return {
          ...state,
          shouldReload: !state.shouldReload,
      };

    case GET_ALL_SPLIT_ACCOUNT:
      return {
        ...state,
        splitAccountIndex: action.payLoad.data.data,
      };

    case GET_SPLIT_ACCOUNT_DETAILS:
      return {
        ...state,
        splitAccount: action.payLoad.data.data,
      };

    case STORE_SPLIT_PAYOUT_ACCOUNT:
      return {
        ...state,
        storeSplitAccount: action.payLoad.data.data,
      };

    case UPDATE_SPLIT_PAYOUT_ACCOUNT:
      return {
        ...state,
        updateSplitAccount: action.payLoad.data.data,
      };

    case REMOVE_SPLIT_PAYOUT_ACCOUNT:
      return {
        ...state,
        removeSplitAccount: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
