import React, { Fragment, useRef } from "react";
import { InfoIcon } from "../../assets/icons/Icons";
import Button from "../../components/buttons/Button";
import ConfirmPinModal from "../fund-transfer-new/ConfirmPinModal";

const ReviewDetails = ({ input, setStep, step, handleApiCall, type }) => {
  const modalRef = useRef();
  const initiateTransfer = () => {
    setStep(step + 1);
    modalRef?.current?.closeModal();
    handleApiCall();
  };
  return (
    <div className="md:w-4/12">
      <p className="mt-12 glade-heading-two glade-home-dark">
        <span className="font-bold ">
          Review details of this Recurring Transfer
        </span>
      </p>
      <div className="text-left flex flex-col gap-2 py-6">
        <p className="glade-grey-dark text-sm font-bold mb-2">
          You are sending
        </p>
        <div className="font-bold text-2xl glade-home-dark mb-2">
          NGN{" "}
          {Number(input?.amount)?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
        {type !== "bulk" && (
          <Fragment>
            <p className="glade-grey-dark text-sm font-bold mb-2">To</p>
            <div>
              <p className="font-bold text-2xl glade-home-dark">
                {input?.beneficiary_account_name ||
                  input?.receipient_data ||
                  ""}
              </p>
              <p className="text-base glade-grey-dark pt-2 capitalize">
                {`${input?.beneficiary_account_number ?? ""} (${
                  input?.beneficiary_bank_name || input?.category_code
                    ? input?.beneficiary_bank_name ||
                      `${input?.category_code} ${input?.biller_item_keycode}`
                    : input?.receipient_data ?? "Gladefinance"
                })`}
              </p>
            </div>
          </Fragment>
        )}
        <div className="my-4">
          <div className="flex justify-between capitalize my-3">
            <span>Frequency </span>
            <span className="font-bold">{input?.frequency}</span>
          </div>
          <div className="flex justify-between capitalize my-3">
            <span>Start date / Time </span>
            <span className="font-bold">{`${input?.start_date} ${input?.start_time}`}</span>
          </div>
          {type !== "bulk" ? (
            <div className="flex justify-between capitalize border-b pb-3 my-3">
              <span>Narration</span>
              <span className="font-bold">{`${input?.narration ?? "-"}`}</span>
            </div>
          ) : (
            <div className="flex justify-between capitalize border-b pb-3 my-3">
              <span>Group name</span>
              <span className="font-bold">{`${input?.group_name ?? "-"}`}</span>
            </div>
          )}
          <div className="flex item-center mt-8 px-12 py-6 glade-bg-blue-100 p-3 rounded-md">
            <div className="mr-3">
              <InfoIcon />
            </div>
            <div className="glade-blue text-sm">
              By clicking ‘Complete Transaction’ you confirm that the
              recepient’s details provided are correct.
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-2">
          <Button.Blue
            className={"px-3"}
            title={"Confirm Transaction"}
            onClick={() => modalRef?.current?.openModal()}
            disabled={""}
          />
        </div>
        <ConfirmPinModal
          title={"Pin Confirmation"}
          body={"Enter your 4-digit PIN"}
          onCorrectPin={initiateTransfer}
          ref={modalRef}
        />
      </div>
    </div>
  );
};

export default ReviewDetails;
