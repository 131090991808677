import { useSelector } from "react-redux";

const useTransactions = () => {
    const transactions = useSelector(state => state.transactions);

    const {
        isLoading,
        shouldReload,
        statementIndex,
        transactionIndex,
        transactionById,
        transactionList
    } = transactions;

    return {
        isLoading,
        shouldReload,
        statementIndex,
        transactionIndex,
        transactionById,
        transactionList
    }
}

export default useTransactions;
