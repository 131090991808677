import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { objectToFormData } from "../../helpers/helper";
import {
  persistLastLogin,
  updatePersistedLastLogin,
  destroyLastLogin,
  retrievePersistedLastLogin,
} from "../../helpers/session";
import {
  LOADING_AUTH,
  LOADING_NEWTEAM_PASSWORD,
  RELOAD_AUTH,
  LOGIN_USER,
  LOGOUT_USER,
  LOADING_USERTAG ,
  USERTAG_SUCCESS,
  USERTAG_FAILURE,
  LOADING_USERTAG_AVAILABLE,
  USERTAG_AVAILABLE_SUCCESS,
  USERTAG_AVAILABLE_FAILURE,
  REFRESHED_TOKEN,
  REGISTER_USER,
  NEWTEAM_PASSWORD_FAILURE,
  NEWTEAM_PASSWORD_SUCCESS,
  TOGGLE_ACCOUNT,
  TOGGLE_CURRENCY,
  STORE_USER_INFO,
  VERIFY_USER_EMAIL,
  RESEND_EMAIL_VERIFICATION,
  CREATE_PASS_CODE,
  INIT_PASSWORD_RESET,
  COMPLETE_PASSWORD_RESET,
  GET_BUSINESS_PERSONAL_ACCOUNTS,
  VERIFY_USER_PIN,
  LOADING_VERIFY_USER_PIN,
  RESOLVE_BVN,
  LOADING_RESOLVE_BVN,
  UPDATE_SELECTED_ACCOUNT_BALANCE,
  VERIFY_TWO_FACTOR_QR_CODE_START,
  VERIFY_TWO_FACTOR_QR_CODE_SUCCESS,
  VERIFY_TWO_FACTOR_QR_CODE_FAILURE,
  GENERATE_QR_CODE_START,
  GENERATE_QR_CODE_SUCCESS,
  GENERATE_QR_CODE_FAILURE,
  CREATE_TEAM_PASSWORD,
  VERIFY_USER_START,
  VERIFY_USER_FAILURE,
  VERIFY_USER_SUCCESS,
  REFRESH_2FA_STATUS_START,
  REFRESH_2FA_STATUS_SUCCESS,
  REFRESH_2FA_STATUS_FAILURE,
  SUBMIT_VERIFICATION_ID,
  LOADING_VERIFICATION_ID,
  LOADING_METAMAP_STATUS,
  GET_BUSINESS_PERSONAL_ACCOUNTS_LOADING,
  GET_BUSINESS_PERSONAL_ACCOUNTS_FAILURE,
} from "./types";

/**
 * Login user
 *
 * @param {object} payLoad {"email": "engineering+maintest@glade.ng","password": "MainEngAccount@9$"}
 * @returns void
 */
export const loginUserAction = (
  payLoad,
  onIncompleteDetails = () => {},
  onFail = () => {},
  onSuccess = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/login`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        if (
          res.data.data.is_email_verified === false ||
          res.data.data.is_bvn_matched === false ||
          res.data.data.has_passcode === false
        ) {
          return onIncompleteDetails(res);
        }

        dispatch({ type: LOGIN_USER, payLoad: res });

        if (payLoad.type === "confirm") {
          onSuccess();
          return;
        }

        persistLastLogin(res);
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        if(error?.response?.status === 423){
          errorHandler(error, false);
        }else{
        errorHandler(error, true);
        }
        onFail(error)
      });
  };
};

export const logoutUserAction = () => {
  return (dispatch) => {
    destroyLastLogin();
    dispatch({ type: LOGOUT_USER, payLoad: {} });
  };
};

export const toggleAccountAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    if (payLoad.is_business === true || payLoad.is_business === false) {
      dispatch({ type: TOGGLE_ACCOUNT, payLoad: payLoad });
      dispatch({ type: RELOAD_AUTH });
      updatePersistedLastLogin({
        data: {
          data: {
            compliance_status: payLoad.compliance_status,
            is_business: payLoad.is_business,
            business_uuid: payLoad.business_uuid,
            selectedBusinessOrPersonalAccount: payLoad,
            singleBusinessDetails: payLoad,
          },
        },
      });
      callBack();
    } else {
      console.warn(
        "Toggle account is not implemented correctly, requires is_business to be boolean"
      );
    }
  };
};

export const toggleSelectedCurrency = (payLoad, selectedAccount) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_CURRENCY, payLoad: { payLoad, selectedAccount } });
  };
};

export const updateSelectedAccountAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    if (payLoad.is_business === true || payLoad.is_business === false) {
      dispatch({ type: TOGGLE_ACCOUNT, payLoad: payLoad });
      updatePersistedLastLogin({
        data: {
          data: {
            compliance_status: payLoad.compliance_status,
            is_business: payLoad.is_business,
            business_uuid: payLoad.business_uuid,
            selectedBusinessOrPersonalAccount: payLoad,
          },
        },
      });
      callBack();
    } else {
      console.warn(
        "Update selected account is not implemented correctly, requires is_business to be boolean"
      );
    }
  };
};

/**
 * Register user
 *
 * @param {object} payLoad {"email": "nowjqdeji@gmail.com", "password": "qwerty"}
 * @param {func} callBack
 * @returns void
 */
export const registerUserAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/new-signup/register`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: REGISTER_USER, payLoad: res });
        successHandler(res, true);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const refreshUserTokenAction = (payLoad) => {
  return (dispatch) => {
    updatePersistedLastLogin(payLoad);
    dispatch({ type: REFRESHED_TOKEN, payLoad: payLoad });
  };
};

export const setAuthFromCache = () => {
  return async (dispatch) => {
    const data = await retrievePersistedLastLogin();
    if (data) {
      dispatch({ type: LOGIN_USER, payLoad: data });
    }
  };
};

/**
 * Store user information
 * 
 * @param {object} payLoad {
    "user_uuid": "942cbd5d-fd7e-4fa9-9b6f-e71b407bfa9",
    "bvn": 20342050441,
    "first_name": "Now",
    "last_name": "Deji",
    "phone_number": "08198765432",
    "preferred_phone_number": "08198765432",
    "passcode": 1234
  }
 * @param {func} callBack 
 * @returns void
 */
export const storeUserInformationAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/new-signup/store-profile-information`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: STORE_USER_INFO, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Verify user email
 *
 * @param {object} payLoad {"verification_code": "212924", "email": "nowjqdeji@gmail.com"}
 * @param {func} callBack
 * @returns void
 */
export const emailVerificationAction = (
  payLoad,
  callBack,
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/new-signup/verify/email`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: VERIFY_USER_EMAIL, payLoad: res });
        successHandler(res, true);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error);
        onFailure();
      });
  };
};

/**
 * Resend email verification
 *
 * @param {object} payLoad {"email": "nowjqdeji@gmail.com"}
 * @param {func} callBack
 * @returns void
 */
export const resendEmailVerificationAction = (
  payLoad,
  callBack = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/new-signup/resend/verification/email`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: RESEND_EMAIL_VERIFICATION, payLoad: res });
        successHandler(res, true);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error);
        onFailure();
      });
  };
};

/**
 * Create a pass code for a new user
 *
 * @param {object} payLoad {"user_uuid" : "","passcode" : ""}
 * @param {func} callBack
 * @returns void
 */
export const createPassCodeAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/passcode`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: CREATE_PASS_CODE, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Initialize user password reset
 *
 * @param {object} payLoad {"email" : ""}
 * @param {func} callBack
 * @returns
 */
export const initPasswordResetAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/reset/password`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: INIT_PASSWORD_RESET, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Initialize team password reset
 *
 * @param {object} payLoad {"email" : ""}
 * @param {func} callBack
 * @returns
 */
export const initTeamPasswordResetAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`auth/employee/reset/password`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: INIT_PASSWORD_RESET, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error, true);
      });
  };
};
/**
 * Complete user password reset
 *
 * @param {object} payLoad {"email" : "", "verification_code" : "","new_password" : ""}
 * @param {func} callBack
 * @returns
 */
export const completePasswordResetAction = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.put(`auth/reset/password`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: COMPLETE_PASSWORD_RESET, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const createNewTeamsPassword = (postData, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_NEWTEAM_PASSWORD });
    Axios.post(`auth/employee/set-password`, postData)
      .then((res) => {
        dispatch({ type: NEWTEAM_PASSWORD_SUCCESS, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: NEWTEAM_PASSWORD_FAILURE });
        errorHandler(error, true);
      });
  };
};

/**
 * Complete team password reset
 *
 * @param {object} payLoad {"email" : "", "verification_code" : "","new_password" : ""}
 * @param {func} callBack
 * @returns
 */
export const completeTeamPasswordResetAction = (
  payLoad,
  callBack = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.put(`auth/employee/reset/password`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: COMPLETE_PASSWORD_RESET, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        errorHandler(error, true);
      });
  };
};


export const createUserTag = (postData, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_USERTAG });
    Axios.post(`/auth/update-user-tag`, postData)
      .then((res) => {
        dispatch({ type: USERTAG_SUCCESS, payLoad: res });
        successHandler(res, true);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: USERTAG_FAILURE });
        errorHandler(error, true);
      });
  };
};


// 
export const CheckUserTagAvailability = (postData,onSuccess = () => {},onFailure = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_USERTAG_AVAILABLE });
    Axios.post(`/auth/check-user-tag`, postData)
      .then((res) => {
        dispatch({ type: USERTAG_AVAILABLE_SUCCESS, payLoad: res });
        onSuccess({res})
      })
      .catch((error) => {
        dispatch({ type: USERTAG_AVAILABLE_FAILURE });
        onFailure({error})
      });
  };
};
/**
 * Get personal and business accounts
 * @param {void}
 * @returns {void}
 */
export const getPersonalAndBusinessAccounts = (
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: GET_BUSINESS_PERSONAL_ACCOUNTS_LOADING });
    Axios.get(`auth/businesses-with-balance`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_PERSONAL_ACCOUNTS, payLoad: res });
        successHandler(res);
        onSuccess(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: GET_BUSINESS_PERSONAL_ACCOUNTS_FAILURE });
        errorHandler(error, true);
        onFailure();
      });
  };
};

export const updateSelectedAccountBalance = (new_balance) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SELECTED_ACCOUNT_BALANCE, payLoad: new_balance });
  };
};

/**
 * Verify user pin
 * @param {object} payLoad {"pin" : ""}
 * @returns {void}
 */
export const verifyUserPin = (
  payLoad,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VERIFY_USER_PIN, payLoad: true });
    Axios.post(`auth/passcode/verify`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_VERIFY_USER_PIN, payLoad: false });
        dispatch({ type: VERIFY_USER_PIN, payLoad: res });
        successHandler(res);
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: LOADING_VERIFY_USER_PIN, payLoad: false });
        errorHandler(error, true);
        onFailure();
      });
  };
};

/**
 * Resolve bvn
 * @param {object} payLoad {"bvn" : "", "first_name":"", "last_name":""}
 * @returns {void}
 */
export const resolveBvn = (
  payLoad,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_RESOLVE_BVN, payLoad: true });
    Axios.post(`auth/lookup/bvn/match`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_RESOLVE_BVN, payLoad: false });
        dispatch({ type: RESOLVE_BVN, payLoad: res });
        successHandler(res, true);
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: LOADING_RESOLVE_BVN, payLoad: false });
        errorHandler(error, true);
        onFailure(error);
      });
  };
};

/**
 * Get list of countries
 * @param {void}
 * @returns {void}
 */
export const getSignupCountries = (callBack) => {
  return (dispatch) => {
    Axios.get(`/auth/new-signup/countries`)
      .then((res) => {
        callBack(res.data.data);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

export const toggleTwoFactorAuth = (credentials, callback) => {
  return (dispatch) => {
    dispatch({ type: VERIFY_TWO_FACTOR_QR_CODE_START });
    Axios.patch(`auth/2fa/toggle-2fa`, credentials)
      .then((res) => {
        dispatch({
          type: VERIFY_TWO_FACTOR_QR_CODE_SUCCESS,
          payLoad: res.data.data,
        });
        callback();
      })
      .catch((error) => {
        dispatch({ type: VERIFY_TWO_FACTOR_QR_CODE_FAILURE });
        errorHandler(error, true);
      });
  };
};

export const generateQRCode = (data) => {
  return (dispatch) => {
    dispatch({ type: GENERATE_QR_CODE_START });
    Axios.post("auth/2fa/generate-qrcode", data)
      .then((res) => {
        dispatch({
          type: GENERATE_QR_CODE_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GENERATE_QR_CODE_FAILURE });
        errorHandler(error, true);
      });
  };
};

export const generateTwoFactorSecret = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post("auth/2fa/generate-secret")
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
      });
  };
};

export const verifyUser = (userObj, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: VERIFY_USER_START });
    Axios.post(`auth/login`, { ...userObj })
      .then((res) => {
        dispatch({ type: VERIFY_USER_SUCCESS });
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: VERIFY_USER_FAILURE });
        errorHandler(error, true);
      });
  };
};

export const refresh2FAStatus = (callback = () => {}) => {
  return (dispatch) => {
    dispatch({ type: REFRESH_2FA_STATUS_START });
    Axios.get(`auth/me`)
      .then((res) => {
        dispatch({ type: REFRESH_2FA_STATUS_SUCCESS, payLoad: res.data.data });
        // callback()
      })
      .catch((error) => {
        dispatch({ type: REFRESH_2FA_STATUS_FAILURE });
        errorHandler(error, true);
      });
  };
};

export const postMetamapVerificationID = (payLoad, callBack = () => {}) => {
  return () => {
    Axios.put(`auth/new-signup/metamap-verification`, { ...payLoad })
      .then((res) => {
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};



/**
 * Signup VerificationID
 *
 * @param {object} payLoad {"email":"string","country_code":"string","document_type":"","id_card_front":"file","id_card_back":"file",'selfie': 'file', //nullable, "liveness_video":""}
 * @returns {void}
 */
export const signupVerificationID = (payLoad, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch({ type: LOADING_VERIFICATION_ID, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`auth/new-signup/kyc/upload-documents`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_VERIFICATION_ID, payLoad: false });
        dispatch({ type: SUBMIT_VERIFICATION_ID, payLoad: res });
        successHandler(res);
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: LOADING_VERIFICATION_ID, payLoad: false });
        errorHandler(error, true);
        onFailure();
      });
  };
};
export const MetamapStatusVerification = (
  payLoad,
  onSuccess,
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_METAMAP_STATUS, payLoad: true });
    Axios.get(`auth/new-signup/kyc/check-status/${payLoad}`)
      .then((res) => {
        dispatch({ type: LOADING_METAMAP_STATUS, payLoad: false });
        successHandler(res);
        onSuccess(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_METAMAP_STATUS, payLoad: false });
        errorHandler(error, false);
        onFailure();
      });
  };
};
