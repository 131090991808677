import { GrFormClose } from "react-icons/gr"
import BasicModal from "../../components/modals/BasicModal"
import { useState } from "react"
import { copyTextToClipboard } from "../../helpers/helper";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import useHome from "../../hooks/useHome";
import useUser from "../../hooks/useUser";
import useTeam from "../../hooks/useTeam";

const AccountDetailModal = ({ showAccountDetails, setShowAccountDetails, accountDetailsToShow }) => {
    const [selectedAccountDetails, setSelectedAccountDetails] = useState("account_1");
    const home = useHome();
    const {ngn_accounts} = useTeam()
    const user = useUser();
    const AccountDetails = () => {
        return (
            <div>
                {accountDetailsToShow?.currency && accountDetailsToShow?.currency?.toLowerCase() !== "ngn" ?<></>: ( <div className=" mt-4 grid grid-cols-2 bg-glade-gray-80 p-1 rounded text-xs text-glade-black-700">
                    <div
                        className={`text-center py-1 cursor-pointer ${selectedAccountDetails === "account_1"
                            ? "bg-white rounded"
                            : ""
                            } `}
                        onClick={() => setSelectedAccountDetails("account_1")}
                    >
                        Account 1
                    </div>
                   
                        <div
                            className={`text-center py-1 cursor-pointer ${selectedAccountDetails === "account_2"
                                ? "bg-white rounded"
                                : ""
                                } `}
                            onClick={() => setSelectedAccountDetails("account_2")}
                        >
                            Account 2
                        </div>
                </div>   
                )}
                {accountDetailsToShow?.currency?.toLowerCase() !== "ngn" ? (
                      <>
                      {selectedAccountDetails === "account_1" && (
                          <AccountTab details={accountDetailsToShow} />
                      )}
                  </>
                 
                ) : (   <>
                        {selectedAccountDetails === "account_1" && (
                            <AccountTab
                                details={
                                    user?.isBusiness
                                        ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                                        home.oneBusinessAccount?.naira_accounts?.accounts[0]
                                        : user?.accountInfo?.accounts &&
                                        user?.accountInfo?.accounts[0] || ngn_accounts[0]
                                }
                            />
                        )}{" "}
                        {selectedAccountDetails === "account_2" && (
                            <AccountTab
                                details={
                                    user?.isBusiness
                                        ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                                        home.oneBusinessAccount?.naira_accounts?.accounts[1]
                                        : user?.accountInfo?.accounts &&
                                        user?.accountInfo?.accounts[1] || ngn_accounts[1]
                                }
                            />
                        )}
                    </>
                  
                )}
            </div>
        )
    }
    return (
        <>
            <BasicModal
                visibility={showAccountDetails}
                onClose={() => { setShowAccountDetails(false) }}
                primaryButton={true} // Pass true to or valid component to avoid default display
                secondaryButton={true} // Pass true to or valid component to avoid default display
                modalTitleSection={
                    <div className='capitalize pb-2 border-b flex justify-between items-center '>
                     {accountDetailsToShow?.currency && accountDetailsToShow?.currency?.toLowerCase() !== "ngn" ?  <p className="text-center w-11/12">Your Wallet Details</p> : <p className="text-center w-11/12">Your Account Details</p>  }
                        <GrFormClose color='#9CA3AF' onClick={() => setShowAccountDetails(false)} />
                    </div>
                }
                modelContentSection={<AccountDetails />}

            />
        </>
    )
}
const AccountTab = ({ details }) => {
    return (
        <>
        {details?.currency && details?.currency?.toLowerCase() !== "ngn" ? 
        <>
        {/* For non NGN account details */}
        <div className="w-6/12 text-sm pt-4">
                <div>
                    <div className={`cursor-pointer font-normal pb-2 glade-grey-cool text-xs`}>
                    WALLET NAME
                </div>
                <div className="flex  pb-3">
                            <p className="font-bold glade-home-dark capitalize">
                                {details?.account_name
                                    ? details?.account_name
                                    : "Not Available"}
                            </p>
                            <p>
                                <FiCopy
                                    size={20}
                                    className="ml-3 cursor-pointer glade-grey-dark"
                                    onClick={() => {
                                        copyTextToClipboard(details?.account_name);
                                        toast.success("Copied to clipboard!", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            theme: "colored",
                                        });
                                    }}
                                />
                            </p>
                        </div>
                </div>

                <div>
                <div className={`cursor-pointer font-normal pb-2 glade-grey-cool text-xs`}>
                WALLET ACCOUNT ID
                </div>
                <div className="flex pb-3">
                            <p className="font-bold glade-home-dark">
                                {details?.account_number
                                    ? details?.account_number
                                    : "Not Available"}
                            </p>
                            <p>
                                <FiCopy
                                    size={20}
                                    className="ml-3 cursor-pointer glade-grey-dark"
                                    onClick={() => {
                                        copyTextToClipboard(details?.account_number);
                                        toast.success("Copied to clipboard!", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            theme: "colored",
                                        });
                                    }}
                                />
                            </p>
                        </div>
                </div>
                
            </div>
        </>
           :  
           <div className="flex pt-4">
        <div className=" pr-3 border-dashed border-r-2 w-5/12 text-sm">
            <div className={`cursor-pointer font-normal pb-3 glade-grey-cool`}>
                Bank Name
            </div>
            <div className={`cursor-pointer font-normal pb-3 glade-grey-cool`}>
                Account Name
            </div>
            <div className={`cursor-pointer font-normal pb-3 glade-grey-cool`}>
                Account Number
            </div>
        </div>

        <div className="p-4 pt-0 w-7/12">
            {details?.bank_name === "pending" ? (
                <div
                    className="flex items-center justify-center p-2 rounded-3xl"
                    style={{ backgroundColor: "rgba(204, 183, 0, 0.1)" }}
                >
                    <span className="glade-yellow">Pending Approval</span>
                </div>
            ) : (
                <div>
                    <div className="flex justify-between pb-2">
                        <p
                            className={`${details?.bank_name === "pending"
                                ? "rounded-3xl glade-yellow h-4/12 glade-bg-blue"
                                : "font-bold glade-home-dark capitalize"
                                }`}
                        >
                            {details?.bank_name?.length
                                ? details?.bank_name
                                : "Not Available"}
                        </p>
                        <p>
                            <FiCopy
                                size={20}
                                className="ml-3 cursor-pointer glade-grey-dark"
                                onClick={() => {
                                    copyTextToClipboard(details?.bank_name);
                                    toast.success("Copied to clipboard!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        theme: "colored",
                                    });
                                }}
                            />
                        </p>
                    </div>
                    <div className="flex justify-between pb-3">
                        <p className="font-bold glade-home-dark capitalize">
                            {details?.account_name
                                ? details?.account_name
                                : "Not Available"}
                        </p>
                        <p>
                            <FiCopy
                                size={20}
                                className="ml-3 cursor-pointer glade-grey-dark"
                                onClick={() => {
                                    copyTextToClipboard(details?.account_name);
                                    toast.success("Copied to clipboard!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        theme: "colored",
                                    });
                                }}
                            />
                        </p>
                    </div>
                    <div className="flex justify-between pb-3">
                        <p className="font-bold glade-home-dark">
                            {details?.account_number
                                ? details?.account_number
                                : "Not Available"}
                        </p>
                        <p>
                            <FiCopy
                                size={20}
                                className="ml-3 cursor-pointer glade-grey-dark"
                                onClick={() => {
                                    copyTextToClipboard(details?.account_number);
                                    toast.success("Copied to clipboard!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        theme: "colored",
                                    });
                                }}
                            />
                        </p>
                    </div>
                </div>
            )}
        </div>
    </div>
 
        }
        </>
    );
};

export default AccountDetailModal;