import React, {useEffect} from 'react';
import { useDispatch } from "react-redux";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { CardTableHeaderPlain } from '../../components/cards/CardTableHeader';
import Button from '../../components/buttons/Button';
import { FiCopy } from 'react-icons/fi';
import useSettings from '../../hooks/useSettings';
import { getMerchantCredentials, getMerchantWebhook, createOrUpdateMerchantWebhook, getDeveloperWebPageLink, generateNewKey } from '../../redux/settings/settingsActions';
import { copyTextToClipboard, isArray } from '../../helpers/helper';
import TextInput from '../../components/inputs/TextInput';
import { toast } from "react-toastify";

export default function SettingsApiAndWebhooks() {

  const dispatch = useDispatch();
  const settings = useSettings();
  const [input, setInput] = React.useState({
    webhook_url: settings?.merchantWebhook?.webhook_url ?? '',
  });
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    dispatch(getMerchantCredentials());
    dispatch(getMerchantWebhook());
    dispatch(getDeveloperWebPageLink());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getMerchantCredentials());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.generateNewKey])

  const generateNewMerchantKey = () => {
    dispatch(generateNewKey());
  }

  const onSubmit = () => {
    dispatch(createOrUpdateMerchantWebhook({
      webhook_url: input.webhook_url,
      status: 1
    }));
  }

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setDisabled(false);
  }

  return (
    <div className="px-3 py-0">
      <div className="mb-9 md:hidden">
        <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
      </div>

      <CardTableHeaderPlain
        title={'API Configuration'}
        rightButton={<Button.Blue onClick={() => onSubmit()} title={'Save Changes'} disabled={settings.isLoading || disabled || input.webhook_url === '' } />}
      />

      <div className="relative flex flex-col min-w-0 w-full bg-white border-t-2 border-gray-100">
        <div className="p-9">
          <div className="w-full md:w-5/12 space-y-6">

            <div className="space-y-1">
              <h4 className="glade-small-text-one glade-black">
                These keys are for production, they should not be used live. Test keys can be found on the <a href={isArray(settings?.developerWebPageLink) && settings?.developerWebPageLink[0] ? settings?.developerWebPageLink[0]?.value : 'https://developer.glade.ng/docs'} className="glade-blue" target="_blank" rel="noreferrer">developers page</a>
              </h4>
            </div>

            <TextInput
              label={'Merchant ID'}
              id={'merchant_id'}
              name={'merchant_id'}
              disabled={true}
              value={settings?.merchantCredentials?.mid ?? ''}
              placeholder={''}
              rightSection={
                <span
                onClick={() => {copyTextToClipboard(settings?.merchantCredentials?.mid ?? ''); toast.success("Copied to clipboard!", { position: toast.POSITION.TOP_RIGHT, theme: "colored" });}}
                  className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm cursor-pointer">
                  Copy <span className="ml-2"><FiCopy /></span>
                </span>
              }
            />

            <TextInput
              label={'Merchant Key'}
              id={'merchant_key'}
              name={'merchant_key'}
              disabled={true}
              value={settings?.merchantCredentials?.key ?? ''}
              placeholder={''}
              rightSection={
                <span
                  onClick={() => {copyTextToClipboard(settings?.merchantCredentials?.key ?? ''); toast.success("Copied to clipboard!", { position: toast.POSITION.TOP_RIGHT, theme: "colored" });}}
                  className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm cursor-pointer">
                  Copy <span className="ml-2"><FiCopy /></span>
                </span>
              }
            />
            
            <div className='w-5/5 md:w-4/5 lg:w-3/5 xl:w-2/5'>
              <Button.Base
                title={'Generate New Key'}
                type={'button'}
                className={'glade-bkg-light-blue glade-blue px-6'}
                onClick={()=>generateNewMerchantKey()}
              />
            </div>



            <div className="space-y-1">
              <h4 className="glade-small-text-one glade-black">Enter a URL to be notified whenever a payment is successful on your dashboard.</h4>
            </div>

            <TextInput
              label={'Webhook URL'}
              id={'webhook_url'}
              name={'webhook_url'}
              value={input.webhook_url ?? ''}
              onChange={onChange}
              placeholder={''}
              autoComplete={"webhook_url"}
              rightSection={
                <span
                  onClick={() => copyTextToClipboard(settings?.merchantCredentials?.key ?? '')}
                  className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm cursor-pointer">
                  Copy <span className="ml-2"><FiCopy /></span>
                </span>
              }
            />

          </div>
        </div>
      </div>
    </div>
  )
}
