import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import useVirtualCards from "../../hooks/useVirtualCards";
import {
  freezeVirtualCard,
  fundVirtualCard,
  getVirtualCardDetails,
  unfreezeVirtualCard,
} from "../../redux/virtual-cards/virtualCardActions";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import {
  ButtonWithDropDown,
  ButtonWithDropDownItem,
} from "../../components/buttons/ButtonWithDropDown";
import { TextSkeleton } from "../../components/skeletons";
import VirtualCardDeleteModal from "./VirtualCardDeleteModal";
import VirtualCardFreezeModal from "./VirtualCardFreezeModal";
import VirtualCardUnFreezeModal from "./VirtualCardUnFreezeModal";
import VirtualCardFundModal from "./VirtualCardFundModal";
import VirtualCardWithdrawModal from "./VirtualCardWithdrawModal";
import useUser from "../../hooks/useUser";
import { useEffect } from "react";

import CardTransactions from "./CardTransactions";
import { statusEnum } from "../../helpers/helper";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PinModal from "./PinModal";
import VirtualCardSuccess from "./VirtualCardSuccess";
import { RiAlertLine } from "react-icons/ri";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { VirtualCardTemplate } from "../../assets/icons/Icons";
import useQuery from "../../hooks/useQuery";

export default function VirtualCardDetail() {
  const VirtualCardDeleteModalRef = useRef();
  const VirtualCardWithdrawModalRef = useRef();

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const virtualCards = useVirtualCards();
  const user = useUser();
  const [input, setInput] = useState({ currency: "NGN" });
  const [showFundModal, setShowFundModal] = useState(false);
  const [showFreezeModal, setShowFreezeModal] = useState(false);
  const [showUnfreezeModal, setShowUnfreezeModal] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [freezeType, setFreezeType] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [accountBalance, setAccountBalance] = useState(null);
  const [title, setTitle] = useState("");
  const [subtext, setSubtext] = useState("");
  const [payload, setPayload] = useState({});

  useEffect(() => {
    dispatch(getVirtualCardDetails({ reference: query?.get("reference") }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.reference, virtualCards.shouldReload]);
  useEffect(() => {setInput({...virtualCards?.virtualCardDetails[0]})}, [virtualCards?.virtualCardDetails])
  useEffect(() => {
    let accountbalance =
      user?.selectedBusinessOrPersonalAccount?.accounts?.find(
        (account, index) => account.currency === "NGN"
      );
    setAccountBalance(accountbalance);
  }, [user]);
  const handleFundChange = (data, action) => {
    if (action === "pin") {
      setPayload({ ...data });
      setShowPinModal(true);
      setFreezeType("");
    }
    setShowFundModal(false);
  };
  const handleFreezeChange = (action) => {
    setShowFreezeModal(false);
    setShowUnfreezeModal(false);
    if (action === "freeze" || action === "unfreeze") {
      setFreezeType(action);
      setShowPinModal(true);
    }

  };
  const handlePinChange = (action) => {
    if (action === "success" && freezeType === "freeze") {
      if (input?.reference) {
        dispatch(
          freezeVirtualCard({ reference: input?.reference, status_action: "block" }, () => {
            setTitle("Successful");
            setSubtext(`Your card has been frozen successfully`);
            setShowSuccess(true);
          })
        );
      }
    }
    if (action === "success" && freezeType === "unfreeze") {
      if (input?.reference) {
        dispatch(
          unfreezeVirtualCard({ reference: input?.reference, status_action: "unblock" }, () => {
            setTitle("Successful");
            setSubtext(`Your card has been unfrozen successfully`);
            setShowSuccess(true);
          })
        );
      }
    }
    if (action === "success" && !freezeType) {
      dispatch(
        fundVirtualCard(
          {
            amount: payload?.amount,
            charge_from_currency: payload?.debitCurrency
              ? payload?.debitCurrency
              : input?.currency,
            reference: input?.reference,
          },
          () => {
            setTitle("Successful");
            setSubtext(
              `Your ${
                input?.currency !== "NGN" ? "dollar" : "naira"
              } card has been funded successfully`
            );
            setShowSuccess(true);
          }
        )
      );
    }
    setShowPinModal(false);
  };

  return (
    <div className="p-3">
      <span className="flex items-center cursor-pointer gap-2" onClick={()=> history?.goBack()}><MdOutlineArrowBackIosNew /> Back</span>
      <div className="flex flex-col">
        <div className="flex justify-between mt-10">
          <BasicIndicator
            className=""
            type={statusEnum[input?.freezed ? false : true]}
            title={input?.freezed ? "Frozen" : "Active"}
          />

          <div className="flex items-center space-x-3">
            <ButtonWithDropDown className="z-10" title="Actions">
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() =>
                      history.push({
                        pathname: "/dashboard/personalized-card",
                        state: { ...input },
                      })
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Personalize Card
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Personalize Card
                  </span>
                }
              />
              {input.freezed === false ? (
                <ButtonWithDropDownItem
                  activeChild={
                    <span
                      onClick={() => setShowFundModal(true)}
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Fund Card
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Fund Card
                    </span>
                  }
                />
              ) : null}
              {input.freezed === true &&
              parseInt(accountBalance?.balance) > 0 ? (
                <ButtonWithDropDownItem
                  activeChild={
                    <span
                      onClick={() => setShowUnfreezeModal(true)}
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Unfreeze Card
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Unfreeze Card
                    </span>
                  }
                />
              ) : null}
              {input.freezed === false &&
              parseInt(accountBalance?.balance) > 0 ? (
                <ButtonWithDropDownItem
                  activeChild={
                    <span
                      onClick={() => setShowFreezeModal(true)}
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Freeze Card
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Freeze Card
                    </span>
                  }
                />
              ) : null}
              {parseInt(accountBalance?.balance) > 0 && (
                <ButtonWithDropDownItem
                  activeChild={
                    <span
                      onClick={() =>
                        VirtualCardDeleteModalRef.current.open({
                          reference: input.reference,
                        })
                      }
                      className="glade-normal-text-two glade-red p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Delete Card
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-red p-4">
                      Delete Card
                    </span>
                  }
                />
              )}
            </ButtonWithDropDown>
          </div>
        </div>

        <div className="flex flex-col bg-white rounded-sm p-3 my-6 border-2 border-solid border-gray-100">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">
              {input?.card_title}
            </h3>
          </div>
          <div>
            {input.freezed && !virtualCards?.isLoading && (
              <div className="mt-4 mb-4 glade-bg-danger glade-red py-3 px-4 space-x-3 flex glade-warning items-center">
                <RiAlertLine className="text-3xl lg:text-xl" />
                <h3>
                  This card is blocked. <u className="cursor-pointer" onClick={() => setShowUnfreezeModal(true)}>Unfreeze this card to continue using it</u>
                </h3>
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 md:flex  gap-4 md:gap-6 py-3">
            <div className="col-span-1">
              <VirtualCardTemplate card_type={input?.card_type} color={input?.design_code} title={input?.card_title} />
            </div>
            <div className=" ">
              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Card Number
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input.card_number ? (
                    <CopyTextButton maskAsCardNumber={true} text={input.card_number}  />
                  ) : (
                    <TextSkeleton width="200px" />
                  )}
                </h6>
              </div>
              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">CVV</h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input.cvv ?? <TextSkeleton width="200px" />}
                </h6>
              </div>
              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">Expiry</h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input.expiry ? input.expiry : <TextSkeleton width="200px" />}
                </h6>
              </div>
            </div>
            <div className="">
              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Card Currency Type
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input?.currency ?? <TextSkeleton width="200px" />}
                </h6>
              </div>
              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Balance
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input?.balance ? Number(input?.balance)?.toLocaleString("en-US") : <TextSkeleton width="200px" />} {input?.currency}
                </h6>
              </div>
              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Card Type
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input?.card_type ?? (
                    <TextSkeleton width="200px" />
                  )}
                </h6>
              </div>
            </div>
            <div className="">
              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Billing Address
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input?.address?.street ?? <TextSkeleton width="200px" />}
                </h6>
              </div>

              <div>
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Postal Code
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {input?.address?.postal_code ?? (
                    <TextSkeleton width="200px" />
                  )}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <CardTransactions />

        <VirtualCardDeleteModal ref={VirtualCardDeleteModalRef} />
        <VirtualCardWithdrawModal
          ref={VirtualCardWithdrawModalRef}
          currency={input.currency}
        />
        {showFreezeModal && (
          <VirtualCardFreezeModal
            showFreezeModal={showFreezeModal}
            onClose={handleFreezeChange}
          />
        )}
        {showUnfreezeModal && (
          <VirtualCardUnFreezeModal
            showUnfreezeModal={showUnfreezeModal}
            onClose={handleFreezeChange}
          />
        )}

        {showFundModal && (
          <VirtualCardFundModal
            currency={input.currency}
            data={input}
            showModal={showFundModal}
            onClose={handleFundChange}
          />
        )}
        {<PinModal showPinModal={showPinModal} onClose={handlePinChange} />}
        {showSuccess && (
          <VirtualCardSuccess
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
            title={title}
            subtext={subtext}
          />
        )}
      </div>
    </div>
  );
}
