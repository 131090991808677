import React, { useState, useEffect } from 'react'
import SenderFlag from "../../components/transfer/SenderFlag";
import SendInput from '../../components/transfer/SendInput';
import RecieverInput from '../../components/transfer/RecieverInput';
import useFundTransfer from '../../hooks/useFundTransfer';
import CurrencyConvert from '../../components/transfer/CurrencyConvert';


const TransferInternationalForm1 = () => {

  const { internationalTransferRate, internationalCountries } = useFundTransfer();
  const [exchangeRate, setExchangeRate] = useState(0);

  useEffect(() => {
    setExchangeRate(internationalTransferRate?.rate);
  }, [internationalTransferRate,]);

  return (
    <div className=''>
      <SenderFlag />

      <SendInput
        label={"Send Amount"}
      />

      <RecieverInput countries={internationalCountries} />

      <CurrencyConvert
        rate={exchangeRate}
      />

    </div>
  )
}

export default TransferInternationalForm1