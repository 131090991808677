import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import PlainModal from "../../components/modals/PlainModal";
import Button from "../../components/buttons/Button";
import NumericInput from "../../components/inputs/NumericInput";
import useVirtualCards from "../../hooks/useVirtualCards";
import { virtualCardFundingValidation } from "../../validation/virtual-card/virtualCardValidation";
import useUser from "../../hooks/useUser";
import SelectInput from "../../components/inputs/SelectInput";
import RateRatio from "../../components/modals/RateRatio";
import useConvert from "../../hooks/useConvert";

const VirtualCardFundModal = ({ data, showModal, onClose }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const { rateDetails } = useConvert();
  const virtualCards = useVirtualCards();

  const [input, setInput] = useState({});
  const [accountBalance, setAccountBalance] = useState("0.00");

  const onSubmit = () => {
    if (
      virtualCardFundingValidation({ ...input, cardCurrency: data?.currency })
    ) {
      onClose({ ...input, rate: { ...rateDetails } }, "pin");
    }
  };

  const onChange = (e) => {
    if (e.target.name === "debitCurrency") {
      let debit = e?.target?.value.split("-")[0];
      let balance = e?.target?.value.split("-")[1];
      setInput({ ...input, [e.target.name]: debit });
      setAccountBalance(balance);
    } else setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      {/* FUND MODAL */}
      {
        <PlainModal
          visibility={showModal}
          primaryButton={<React.Fragment></React.Fragment>}
          modalTitle={"Fund Card"}
          onClose={onClose}
          modelContentSection={
            <div className="flex flex-col">
              <div className="flex flex-col px-6 py-6 text-left">
                <NumericInput
                  leftSection={
                    <span className="glade-button-gray pr-6 self-center border border-gray-300 rounded-sm p-2">
                      {data?.currency}
                    </span>
                  }
                  label={"Enter Amount"}
                  id={"amount"}
                  name={"amount"}
                  type={"number"}
                  value={input.amount}
                  onChange={onChange}
                  placeholder={"Enter amount"}
                  autoComplete={"amount"}
                  className={"appearance-none"}
                />
              </div>
              <div className="flex flex-col px-6 mb-3 text-left">
                <SelectInput
                  label={"Select balance to fund from"}
                  name={"debitCurrency"}
                  id={"debitCurrency"}
                  value={input?.debitCurrency + "-" + accountBalance}
                  onChange={onChange}
                >
                  <option value={""}>Select Balance</option>
                  {!user?.singleBusinessDetails?.hasOwnProperty("currency") &&
                  user.singleBusinessDetails?.accounts?.length > 0 ? (
                    user.singleBusinessDetails?.accounts.map((account, key) => (
                      <option
                        value={`${account?.currency}-${account?.balance}`}
                        key={key}
                      >
                        {`${account?.currency} Balance - ${
                          account?.balance?.toLocaleString() ?? "0.00"
                        } ${account?.currency}`}
                      </option>
                    ))
                  ) : (
                    <option
                      value={`${user?.accountInfo?.available_balance}-${user?.accountInfo?.currency}`}
                    >
                      {`${user?.accountInfo?.currency} Balance - ${
                        user?.accountInfo?.available_balance ?? "0.00"
                      } ${user?.accountInfo?.currency}`}
                    </option>
                  )}
                </SelectInput>

                {/* RATE RATIO BOX */}
                {input?.debitCurrency &&
                  input?.debitCurrency !== data?.currency && (
                    <RateRatio
                      baseCurrency={data?.currency}
                      recieveCurrency={input?.debitCurrency}
                      send_amount={input?.amount ?? "0.00"}
                    />
                  )}
              </div>
              <div className="flex flex-row justify-center p-3 border-t-2 border-solid border-gray-100">
                <div>
                  <Button.Blue
                    title={"Fund Card"}
                    disabled={
                      !virtualCardFundingValidation(
                        { ...input, cardCurrency: data?.currency },
                        true
                      ) ||
                      input?.amount > Number(accountBalance) ||
                      rateDetails?.data?.rate * input?.amount <= 0 ||
                      (data?.currency === "USD" &&
                        rateDetails?.data?.rate * input?.amount >
                          Number(accountBalance))
                    }
                    onClick={() => onSubmit()}
                  />
                </div>
              </div>
            </div>
          }
        />
      }
    </Fragment>
  );
};
export default VirtualCardFundModal;
