import { removeCommas } from "./helper";

export function validateNonNairaTransferForm(values) {
    let errors = {};
    if (!values.amount)
        errors.amount = "What is the point of sending nothing?";
    if (!values.beneficiary_sort_code)
        errors.beneficiary_sort_code = "Beneficiary sort code is required";

    return errors;
}

const validator = {
    required: (data, value) => { return (typeof value === 'string' || typeof value === 'number') && value !== ''; },
    email: (data, value) => { return /^[^\s@]+@[^\s@]+$/.test(value); },
    max: (data, value, maximum) => { return parseInt(value) < parseInt(maximum); },
    min: (data, value, minimum) => { return parseInt(value) > parseInt(minimum); },
    maxSize: (data, value, maximum) => { value = value ? value : ''; return value.length < maximum ? true : false; },
    minSize: (data, value, minimum) => { value = value ? value : ''; return value.length > minimum ? true : false; },
    phone: (data, value) => { return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/i.test(value); },
    match: (data, value, matchingField) => { value = value ? value : ''; return value === data[matchingField] ? true : false; },
};

const onValidationFailMessage = {
    required: (fieldName) => { return `${fieldName} field is required`; },
    email: (fieldName) => { return `${fieldName} field is not valid`; },
    max: (fieldName, maximum) => { return `${fieldName} field can not be greater than ${maximum}`; },
    min: (fieldName, minimum) => { return `${fieldName} field can not be less than ${minimum}`; },
    maxSize: (fieldName, maximum) => { return `${fieldName} field size can not be greater than ${maximum}`; },
    minSize: (fieldName, minimum) => { return `${fieldName} field size can not be less than ${minimum}`; },
    phone: (fieldName) => { return `${fieldName} field is not valid`; },
    match: (fieldName, matchingField) => { return `${fieldName} field does not match ${matchingField}`; },
};

/**
 * Ensures that give data and rule objects confirms to requirements
 * @param {object} data 
 * @param {object} rules 
 * @returns {void}
 */
const shouldValidationRun = (data, rules) => {
    for (const key in data) {

        // Ensure matching validation rule for current data
        if (rules[key] === undefined) {
            throw new Error(`There is no matching rule for ${key}`);
        }

        // If rule is a custom function skip rest of check
        if (Object.prototype.toString.call(rules[key]) === '[object Function]') {
            continue;
        }

        // Looping through all available rules for current key
        let availableRules = rules[key].split('|');
        for (const rule of availableRules) {

            // Checking if current rule has additional arguments
            let actualRule = rule.includes(':') ? rule.split(':')[0] : rule;

            // Ensure that specified rule exists
            if (validator[actualRule] === undefined) {
                throw new Error(`The rule ${actualRule} does not exist`);
            }
        }
    }
};

/**
 * Performs validation upon values based on supplied rules
 * @param {object} data {emailInput:'blue@gmail.com', ageInput:50}
 * @param {object} rules {emailInput:'required|email', ageInput:'required|max:25'}
 * @param {object} messages {emailInput:{required:'email is required', email:'email is not valid'}}
 * @returns {object}
 */
const validate = (data, rules, messages = {}) => {

    // Enure that every give data has a corresponding valid rule set
    shouldValidationRun(data, rules);

    // Defining an error bag
    let errorBag = {};

    // Looping through all available data
    for (const key in data) {

        // Stating user input and ensuring object has proper structure
        let userInputData = data;
        let userInputValue = data[key];
        if (Object.hasOwnProperty.call(data, key)) {

            // Check if rule is a custom function, execute it then jump to next iteration
            if (Object.prototype.toString.call(rules[key]) === '[object Function]') {

                // Perform validation
                if (!rules[key](userInputValue)) {

                    // Extracting default message for failed validation
                    let defaultMessage = `there was an issue with ${key}`;

                    // Sending failure message to error bag
                    errorBag = {
                        ...errorBag, [key]: {
                            ...errorBag[key], custom: ((messages) => {
                                // If they are no custom defined error message then use the default message
                                return messages[key] && messages[key]['custom'] ? messages[key]['custom'] : defaultMessage;
                            })(messages)
                        }
                    };
                }

                continue;
            }

            // Looping through all available rules for current key
            let availableRules = rules[key].split('|');
            for (const rule of availableRules) {

                // Checking if current rule has additional arguments
                if (!rule.includes(':')) {

                    // Perform validation
                    if (!validator[rule](userInputData, userInputValue)) {

                        // Extracting default message for failed validation
                        let defaultMessage = onValidationFailMessage[rule](key);

                        // Sending failure message to error bag
                        errorBag = {
                            ...errorBag, [key]: {
                                ...errorBag[key], [rule]: ((messages) => {
                                    // If they are no custom defined error message then use the default message
                                    return messages[key] && messages[key][rule] ? messages[key][rule] : defaultMessage;
                                })(messages)
                            }
                        };
                    }

                } else {

                    let actualRule = rule.split(':')[0];
                    let ruleArguments = rule.split(':')[1];

                    // Perform validation
                    if (!validator[actualRule](userInputData, userInputValue, ...ruleArguments.split(','))) {

                        // Extracting default message for failed validation
                        let defaultMessage = onValidationFailMessage[actualRule](key, ...ruleArguments.split(','));

                        // Sending failure message to error bag
                        errorBag = {
                            ...errorBag, [key]: {
                                ...errorBag[key], [actualRule]: ((messages) => {
                                    // If they are no custom defined error message then use the default message
                                    return messages[key] && messages[key][actualRule] ? messages[key][actualRule] : defaultMessage;
                                })(messages)
                            }
                        };
                    }
                }
            }
        }
    }

    return errorBag;
};

/**
 * Working example of required data format for validate method
 */
// let data = {
//     emailInput:'test@gmail.com', 
//     ageInput:50,
//     tagNumberInput:'t-a-g-n-u-m-b-e-r'
// };
// let rules = {
//     emailInput:'required|email', 
//     ageInput:'required|max:25',
//     tagNumberInput:(value)=>{
//         if (value === 't-a-g-n-u-m-b-e-r') { return true; }
//         return false;
//     }
// }
// let messages = {
//     emailInput:{
//         required:'email is required',
//         email:'email is not valid'
//     },
//     ageInput:{
//         required:'age is required',
//         max:'age is above the required limit'
//     },
//     tagNumberInput:{
//         custom:'tag number is incorrect'
//     }
// }

export const validateNairaInputObject = (input, setInputError) => {
    let instance = {};
    let isinvalid = [];
    Object.keys(input).forEach((field) => {
        if (!input[`${field}`] || input[`${field}`] < 3) {
            instance[`${field}`] = true;
            setInputError(instance);
            isinvalid.push(field);
        }
        if ((field === 'business_country' && input[`${field}`]) || (field === 'country' && input[`${field}`])) {
            instance[`${field}`] = false;
            setInputError(instance);
            isinvalid = isinvalid.filter(e => e !== field);
        }
        if ((field === 'business_state' && input[`${field}`]) || (field === 'state' && input[`${field}`])) {
            instance[`${field}`] = false;
            setInputError(instance);
            isinvalid = isinvalid.filter(e => e !== field);
        }
        if (field === 'business_lga' && input[`${field}`]) {
            instance[`${field}`] = false;
            setInputError(instance);
            isinvalid = isinvalid.filter(e => e !== field);

        }
        if (field === 'business_website') {
            instance[`${field}`] = false;
            var res = input['business_website' || 'attachments'].match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (!res && input[`${field}`]?.trim()?.length > 0) {
                instance[`${field}`] = true;
                setInputError(instance);
                isinvalid.push(field);
            } else {
                isinvalid = isinvalid.filter((keys) => keys !== 'business_website');
            }
        }
        if (field === 'business_email') {
            let res = input['business_email'].match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (!res) {
                instance[`${field}`] = true;
                setInputError(instance);
                isinvalid.push(field);
            }
        }
        if (field === 'contact_email') {
            let res = input['contact_email'].match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (!res) {
                instance[`${field}`] = true;
                setInputError(instance);
                isinvalid.push(field);
            }
        }
        if (field === 'contact_phone') {

            if (input['contact_phone'].length < 6 || input['contact_phone'].length > 12) {
                instance[`${field}`] = true;
                setInputError(instance);
                isinvalid.push(field);
            }
        }
    });
    return isinvalid.length > 0 ? true : false;
};
export const isObjectFieldsEmpty = (input) => {
    let invalidFields = [];
    Object.keys(input).forEach((field) => {
        if (!input[`${field}`] || input[`${field}`] === '') {
            invalidFields.push(field);
        }
    });
    return invalidFields.length > 0 ? true : false;
};

export const validateLocalTransferForm = (data, transferForm) => {
    let isInvalid = [];
    Object.keys(data).forEach((field) => {
        // Flag any field that is not filled.
        if (!data[`${field}`]) {
            isInvalid.push(field);
        }
        // Validate fields individually with extra checks.
        if(field==='beneficiary_account_name' && data['beneficiary_account_name'] && data['beneficiary_account_name']?.length < 5){
            isInvalid.push('beneficiary_account_name');
        }
        if(field==='beneficiary_account_number' && data['beneficiary_account_number'] && data['beneficiary_account_number']?.length < 4){
            isInvalid.push('beneficiary_account_number');
        }
        if(field==='beneficiary_swift_code' && data['beneficiary_swift_code'] && data['beneficiary_swift_code']?.length < 4){
            isInvalid.push('beneficiary_swift_code');
        }
        if( field === 'amount' && Number(parseFloat(removeCommas(data?.amount))) < 1){
            isInvalid.push('amount');
        }
        if(field==='beneficiary_account_number' && data['beneficiary_bank_name'] && data['beneficiary_bank_name']?.length < 4){
            isInvalid.push('beneficiary_bank_name');
        }
        if(field==='beneficiary_account_type' && 
        data['beneficiary_account_type'] && data['beneficiary_account_type']==='individual' && (!transferForm['beneficiary_first_name'] || transferForm['beneficiary_first_name']?.length < 3)){
            isInvalid.push('beneficiary_first_name');
        }
        if(field==='beneficiary_account_type' && 
        data['beneficiary_account_type'] && data['beneficiary_account_type']==='individual' && (!transferForm['beneficiary_last_name'] || transferForm['beneficiary_last_name']?.length < 3)){
            isInvalid.push('beneficiary_last_name');
        }
        if(field==='beneficiary_account_type' && 
        data['beneficiary_account_type'] && data['beneficiary_account_type'] ==="corporate" && (!transferForm['beneficiary_name'] || transferForm['beneficiary_name']?.length < 3)){
            isInvalid.push('beneficiary_last_name');
        }
        if (field === 'beneficiary_email') {
            let res = data['beneficiary_email'] && data['beneficiary_email']?.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (!res) {
                isInvalid.push('beneficiary_email');
            }
        }
    })
    return isInvalid;
}

export { validator, onValidationFailMessage, shouldValidationRun, validate };
