import { useSelector } from "react-redux";

const useBalances = () => {
  const balances = useSelector((state) => state.balances);

  const {
    isLoadingBalances,
    balancesData,
    isLoadingAccountDetails,
    accountDetails,
    isLoadingAllIFAccount,
    allIFAccountData
  } = balances

  return {
    isLoadingBalances,
    balancesData,
    isLoadingAccountDetails,
    accountDetails,
    isLoadingAllIFAccount,
    allIFAccountData
  };
};

export default useBalances;