import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import {CardPlainWithButton, CardPlainWithButtonSection} from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import Button from '../../components/buttons/Button';
import {isEmpty} from '../../helpers/helper';
import {getBanks, resolveAccountDetails} from '../../redux/fund-transfer/fundTransferActions';
import {createPayrollStaffBulk} from "../../redux/payroll/payrollActions";
import {CgClose} from 'react-icons/cg';
import useFundTransfer from '../../hooks/useFundTransfer';
import usePayroll from "../../hooks/usePayroll";
import useUser from "../../hooks/useUser";
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import SearchSelectInput from '../../components/inputs/SearchSelectInput';
import EmptyCard from '../../components/empty-states/EmptyCard';
import useTeam from '../../hooks/useTeam';

export default function PayrollStaffCreateBulkManual() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const payroll = usePayroll();
  const fundTransfer = useFundTransfer();
  const {permissions} = useTeam();
  
  const [input, setInput] = React.useState({
    staffs:[],
    staff_name:'',
    staff_email:'',
    staff_position:'',
    staff_number:'',
    staff_phone_number:'',
    account_number:'',
    account_name:'',
    bank_name:'',
    amount:'',
    bank_code:'',
  });

  React.useEffect(() => {
    dispatch(getBanks({mode:'internal'}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (input.account_number.length === 10 && input.bank_code) {
      dispatch(
        resolveAccountDetails(
          {account_number:input.account_number,bank_code:input.bank_code,mode:'external_local'},
          (resolveAccountDetails)=>setInput({...input,account_name:resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id})
        )
      );
    } else {
      setInput({...input,account_name:''});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.account_number,input.bank_code]);

  const onSubmit = () => {
    dispatch(createPayrollStaffBulk(input.staffs,()=>history.goBack()));
  }

  const onAddStaff = () => {
    if (isEmpty(input.bank_code) || isEmpty(input.account_number) || isEmpty(input.amount)) {
      alert('Please fill all the required fields');
      return;
    }

    setInput({
      ...input,
      staffs:[
        ...input.staffs,
        {
          name: input.staff_name,
          email: input.staff_email,
          staff_position: input.staff_position,
          staff_number: input.staff_number,
          phone: input.staff_phone_number,
          account_number: input.account_number,
          account_name: input.account_name,
          bank_name: input.bank_name,
          value: input.amount,
          bank_code: input.bank_code,
          currency: "NGN",
          individual_type: "staff",
          business_uuid: user?.isBusiness ? user?.selectedBusinessOrPersonalAccount?.business_uuid : user?.userInfo?.uuid,
          id:Math.random()
        }
      ],
      staff_name:'',
      staff_email:'',
      staff_position:'',
      staff_number:'',
      staff_phone_number:'',
      account_number:'',
      account_name:'',
      bank_name:'',
      amount:'',
      bank_code:'',
    });
  }

  const onRemoveStaff = (value) => {
    let staffs = input.staffs.filter(item => item.id !== value.id);
    setInput({...input,staffs:staffs});
  }

  const onChange = (e) => {
    setInput({...input,[e.target.name]:e.target.value});
  }

  const onSearchSelectOfBank = (e) => {
    if (e.target.data) {
      let data = JSON.parse(e.target.data);
      setInput({...input, bank_code:data.bank_code, bank_name:data.bank_name});
    } else {
      setInput({...input, bank_code:'', bank_name:e.target.value});
    }
  }

  const renderRecipients = (data) => {
    if (isEmpty(data)) {
      return (
        <EmptyCard title={'No Staff'} subtitle={'You have not added any staff'} />
      )
    }

    return data.map((item,key)=>{
      return (
        <div key={key} className="flex flex-row justify-between border-2 my-2 p-3">
          <h4 className="glade-normal-text-two glade-black">
            {item.name+' '+item.account_number+' '+item.bank_name+' NGN '+item.value}
          </h4>
          <button className="px-2 glade-normal-text-two glade-black outline-none" onClick={()=>onRemoveStaff(item)}>
            <CgClose />
          </button>
        </div>
      );
    });
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">

        <div className="mb-6">
          <BreadCrumbs title={'Payroll Staff'} path={!permissions?.includes("payroll") ? '/dashboard/payroll/staff' : '/teams/payroll/staff'}/>
        </div>

        <CardPlainWithButton type={'dual'}>
          <CardPlainWithButtonSection 
            title={'Add Staff'} 
            type={'dual'}
            loading={payroll.isLoadingPayroll}
            buttonDisabled={payroll.isLoadingPayroll}
            buttonSection={
                <div className="flex flex-row space-x-4 mt-6">
                    <Button.Blue title={"Add Staff"} className="whitespace-nowrap" onClick={onAddStaff} />
                    <Button.Blue title={"Continue"} onClick={onSubmit} disabled={isEmpty(input.staffs) || payroll.isLoadingPayroll} />
                </div>
            }>
            <div className="mt-6 px-14">
              <div className="space-y-6">
                <TextInput 
                  label={'Staff Name'}
                  id={'staff_name'}
                  name={'staff_name'}
                  type={'text'}
                  value={input.staff_name}
                  onChange={onChange}
                  placeholder={'Enter staff name'}
                  autoComplete={'staff_name'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Staff Email'}
                  id={'staff_email'}
                  name={'staff_email'}
                  type={'email'}
                  value={input.staff_email}
                  onChange={onChange}
                  placeholder={'Enter staff email address'}
                  autoComplete={'staff_email'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Staff Phone Number'}
                  id={'staff_phone_number'}
                  name={'staff_phone_number'}
                  type={'tel'}
                  value={input.staff_phone_number}
                  onChange={onChange}
                  placeholder={'Enter staff phone number'}
                  autoComplete={'staff_phone_number'}
                  className={'appearance-none'}
                />

                <NumericInput 
                  label={'Staff ID Number'}
                  id={'staff_number'}
                  name={'staff_number'}
                  type={'number'}
                  value={input.staff_number}
                  onChange={onChange}
                  placeholder={'Enter staff ID number'}
                  autoComplete={'staff_number'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Staff Position'}
                  id={'staff_position'}
                  name={'staff_position'}
                  type={'text'}
                  value={input.staff_position}
                  onChange={onChange}
                  placeholder={'Enter staff position'}
                  autoComplete={'staff_position'}
                  className={'appearance-none'}
                />

                <SearchSelectInput
                  label={'Select Staff Bank'}
                  id={'bank_code'}
                  name={'bank_code'}
                  value={input.bank_name ?? ''}
                  onChange={onSearchSelectOfBank}
                  autoComplete={'bank_code'}
                  placeholder={'Select a staff bank'}
                  className={'appearance-none'}>
                  {fundTransfer.banks && fundTransfer.banks.map((item, key)=>{
                    return (
                      <option key={key} name={item.bank_name} data={JSON.stringify(item)}>{item.bank_name}</option>
                    );
                  })}
                </SearchSelectInput>

                <NumericInput 
                  label={'Staff Account Number'}
                  id={'account_number'}
                  name={'account_number'}
                  type={'number'}
                  value={input.account_number}
                  onChange={(e)=>setInput({...input,[e.target.name]:e.target.value.substring(0,10)})}
                  placeholder={'Enter staff account number'}
                  autoComplete={'account_number'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Staff Account Name'}
                  id={'account_name'}
                  name={'account_name'}
                  disabled={true}
                  type={'text'}
                  value={input.account_name}
                  onChange={onChange}
                  placeholder={''}
                  autoComplete={'account_name'}
                  className={'appearance-none'}
                />

                <NumericInput 
                  leftSection={<span className="self-center border border-gray-300 rounded-sm p-2">NGN</span>}
                  label={'Staff Salary'}
                  id={'amount'}
                  name={'amount'}
                  type={'number'}
                  value={input.amount}
                  onChange={onChange}
                  placeholder={'Enter staff salary'}
                  autoComplete={'amount'}
                  className={'appearance-none'}
                />
              </div>
            </div>
          </CardPlainWithButtonSection>
          <CardPlainWithButtonSection 
            className={'order-first md:order-last'}
            title={'Staff'} 
            type={'dual'}
            loading={payroll.isLoadingPayroll}
            buttonDisabled={payroll.isLoadingPayroll}
            buttonSection={<React.Fragment></React.Fragment>}>
            <div className="mt-6" style={{minHeight:'44.8rem'}}>
              {renderRecipients(input.staffs)}
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  )
}
