import { useSelector } from 'react-redux';

const useFundTransfer = () => {
    const fundTransfer = useSelector(state => state.fundTransfer);

    let {
        isLoading,
        transactionStatus,
        isLoadingResolveDetails,
        isLoadingBeneficiaries,
        fundTransferIndex,
        fundTransferModes,
        fundTransferBeneficiaries,
        resolveAccountDetails,
        banks,
        countries,
        isLoadingUsertagBeneficiaries,
        userTagBeneficiaries,
        internationalCountries,
        internationalTransferMethods,
        internationalTransferBanks,
        internationalTransferBanksBranches,
        internationalTransferRate,
        localTransferRate,
        internalTransfer,
        externalTransfer,
        externalBulkTransfer,
        internationalTransfer,
        searchFundTransferIndex,
        repeatTransaction,
        bulkTransferList
    } = fundTransfer;

    fundTransferBeneficiaries = fundTransferBeneficiaries.map((item) => {
        return { ...item, title: item.account_name, description: item.account_number + ' - ' + item.bank_name };
    });

    return {
        isLoading,
        transactionStatus,
        isLoadingResolveDetails,
        isLoadingBeneficiaries,
        fundTransferIndex,
        fundTransferModes,
        fundTransferBeneficiaries,
        isLoadingUsertagBeneficiaries,
        userTagBeneficiaries,
        resolveAccountDetails,
        banks,
        countries,
        repeatTransaction,
        internationalCountries,
        internationalTransferMethods,
        internationalTransferBanks,
        internationalTransferBanksBranches,
        internationalTransferRate,
        localTransferRate,
        internalTransfer,
        externalTransfer,
        externalBulkTransfer,
        internationalTransfer,
        searchFundTransferIndex,
        bulkTransferList
    };
};

export default useFundTransfer;
