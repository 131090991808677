import React, { createContext, useState } from "react"

const TwoFactorContextType = {
  isOpen: false,
  setIsOpen: () => { },
  closeModal: () => { },
}

export const TwoFactorContext = createContext(TwoFactorContextType)

const TwoFactorContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => setIsOpen(false)

  const values = {
    isOpen,
    setIsOpen,
    closeModal,
  }

  return (
    <TwoFactorContext.Provider value={values}>
      {children}
    </TwoFactorContext.Provider>
  )
}

export default TwoFactorContextProvider