import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { getPaydayById, getPayrollStaff, getPayrollStaffById } from "../../redux/payroll/payrollActions";
import Button from "../../components/buttons/Button";
import PayrollStaffDeleteModal from "./PayrollStaffDeleteModal";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import usePayroll from "../../hooks/usePayroll";
import useTeam from "../../hooks/useTeam";

export default function PayrollStaffDetails() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {payrollStaffDetails} = usePayroll();
  const {permissions} = useTeam();

  const PayrollStaffDeleteModalRef = React.useRef();

  React.useEffect(() => {
      dispatch(getPayrollStaffById(location?.state?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.id]);

  return (
    <div className="p-3">
        <div className="mt-8">
          <BreadCrumbs title={"Payroll Staff"} path={!permissions?.includes("payroll") ? "/dashboard/payroll/staff" : "/teams/payroll/staff"} />
        </div>
      <div className="flex justify-end gap-x-5 mt-6">
        <div>
          <Button.Danger
            title={"Delete Staff"}
            style={{ backgroundColor: "#FF4F4F", color: "#fff" }}
            onClick={() =>
              PayrollStaffDeleteModalRef.current.open(location?.state)
            }
            className={"py-3 px-5"}
          />
        </div>
        <div>
          <Button.Blue
            title={"Edit Staff"}
            onClick={() =>
              history.push({
                pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/edit" : "/teams/payroll/staff/edit",
                state: location?.state,
              })
            }
            className={"py-3 px-5"}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col bg-white rounded-sm p-3 mt-4 mb-6 border border-gray-100 rounded-md">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-five glade-black text-base">
              Staff {payrollStaffDetails?.staff_number}
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-3 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Staff Name
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.name}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Staff Email
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.email}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Staff Phone Number
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.phone}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Staff Position
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.staff_position}
              </h6>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white rounded-sm p-3 my-6 border border-gray-100 rounded-md">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">Salary Details</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-3 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Bank Name
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.bank_name}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Account Number
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.account_number}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Account Name
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.account_name}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Salary</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {payrollStaffDetails?.currency}{" "}
                {Number(payrollStaffDetails?.value)?.toLocaleString()}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <PayrollStaffDeleteModal ref={PayrollStaffDeleteModalRef} />
    </div>
  );
}
