import React from "react";
import { TabGroup, TabPanel } from "../../components/tabs/Tab";
import useQuery from "../../hooks/useQuery";

import EditNairaCard from "./EditNairaCard";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

function VirtualCardPersonalizedModal() {
  let query = useQuery();
  let location = useLocation();
  let history = useHistory();

  return (
    <div className="py-7">
      <span className="p-6 flex items-center cursor-pointer gap-2" onClick={()=> history?.goBack()}><MdOutlineArrowBackIosNew /> Back</span>
      <TabGroup
        defaultIndex={query.get("tab") ?? 0}
        className="whitespace-nowrap w-2/12"
        column={[`${location?.state?.currency === "NGN" ? "Naira Card" : "Dollar Card"}`]}
      >
        <TabPanel>
          <EditNairaCard />
        </TabPanel>
      </TabGroup>
    </div>
  );
}

export default VirtualCardPersonalizedModal;
