import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import BasicIndicator from '../../components/indicators/BasicIndicator';
import { CardTableHeaderPlain } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import TextIndicator from '../../components/indicators/TextIndicator';
import CircleIndicator from "../../components/indicators/CircleIndicator";
import { simpleDate, isArray, isEmpty, pagination, statusEnum } from '../../helpers/helper';
import { getPosTransactions } from '../../redux/pos/posActions';
import usePos from '../../hooks/usePos';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import { IoIosArrowForward } from "react-icons/io";
import useTeam from '../../hooks/useTeam';

export default function DetailsForPos() {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pos = usePos();
  const {permissions} = useTeam();
  
  React.useEffect(() => {
    if (location.state.terminal_id) {
      dispatch(getPosTransactions({ pos_id: location.state.terminal_id }));
    }
    // eslint-disable-next-line 
  }, [location.state.terminal_id]);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(pos.posTransactions, 10, currentPage));
  }, [currentPage, pos.posTransactions]);

  const renderPosTransactionsTable = (data) => {
    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <TextIndicator type={statusEnum[item.txn_status]} title={item.txn_status} />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two glade-black">{simpleDate(item.created_at)}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two glade-black">{item.rrn}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two glade-black">{item.value}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      )
    });
  }

  const renderPosTransactionsDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div key={key} className="flex flex-row justify-between p-1 border-b" onClick={() => history.push({ pathname: !permissions?.includes("pos") ? '/dashboard/pos/pos-detail/transaction-detail' : '/teams/pos/pos-detail/transaction-detail', state: item })}>
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.txn_status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  NGN {Number(item?.actual_value ?? item?.value)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.created_at, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={'POS'} path={`/${!permissions?.includes('pos') ? 'dashboard' : 'teams'}/pos?tab=${location.state.status === 'pending' ? 1 : 0}`} />
        </div>

        <div className="flex justify-between mt-10">
          <BasicIndicator
            className="px-5 py-1 capitalize"
            type={statusEnum[location?.state?.status]}
            title={location?.state?.status}
          />
        </div>

        <div className="flex flex-col bg-white rounded-sm p-6 my-6">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">POS Details</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-4 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Terminal ID</h5>
              <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.terminal_id ?? 'N/A'}</h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Revenue</h5>
              <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.monthly_revenue}</h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Sales</h5>
              <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.daily_sales}</h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Date</h5>
              <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.created_at && simpleDate(location.state.created_at)}</h6>
            </div>
          </div>
        </div>
        {location?.state?.terminal_id &&
          <div className="">
            <CardTableHeaderPlain
              title={'POS Transactions'}
              searchBar={<React.Fragment></React.Fragment>}
            />

            {isEmpty(tableData.data) ?
              <React.Fragment>
                <div className="hidden md:block">
                  <EmptyTable column={['', 'Status', 'Date', 'RRN', 'Amount', '']} loading={pos.posTransactions} />
                </div>

                <div className="block md:hidden">
                  <EmptyDesk row={[1, 2]} loading={pos.posTransactions} />
                </div>
              </React.Fragment>
              :
              <React.Fragment>
                <div className="hidden md:block">
                  <CardTableBody column={['', 'Status', 'Date', 'RRN', 'Amount', '']}>
                    {isArray(tableData.data) && renderPosTransactionsTable(tableData.data)}
                  </CardTableBody>
                </div>

                <div className="block md:hidden">
                  <div className="bg-white px-6">
                    {isArray(tableData.data) && renderPosTransactionsDesk(tableData.data)}
                  </div>
                </div>
              </React.Fragment>
            }

            <CardTableFooter
              empty={isEmpty(tableData.data)}
              pageNumber={currentPage}
              totalPages={tableData.totalPages}
              prevOnClick={() => setCurrentPage(tableData.previousPage)}
              nextOnClick={() => setCurrentPage(tableData.nextPage)}
              thereIsPreviousPage={tableData.thereIsPreviousPage}
              thereIsNextPage={tableData.thereIsNextPage}
            />
          </div>
        }

      </div>
    </div>
  );
}
