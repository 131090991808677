import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import Button from '../../components/buttons/Button';
import SearchInput from '../../components/inputs/SearchInput';
import PipedButton from '../../components/buttons/PipedButton';
import {getSplitAccounts,deleteSplitAccount} from '../../redux/transaction-split/transactionSplitActions';
import useTransactionSplit from '../../hooks/useTransactionSplit';
import {isArray, isEmpty, pagination} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import {IoIosArrowForward} from "react-icons/io";
import {KebabDropdown, KebabDropdownItem} from "../../components/dropdowns/KebabDropdown";
import useTeam from '../../hooks/useTeam';

export default function TransactionSplit() {

    const history = useHistory();
    const dispatch = useDispatch();
    const transactionSplit = useTransactionSplit();
    const {permissions} = useTeam();

    React.useEffect(() => {
        dispatch(getSplitAccounts());
        // eslint-disable-next-line 
    }, [transactionSplit.shouldReload]);

    // Pagination Function And Control
    const [currentPage, setCurrentPage] = React.useState(1);
    const [tableData, setTableData] = React.useState([]);
    React.useEffect(() => {
        setTableData(pagination(transactionSplit.splitAccountIndex?.accounts ?? [], 10, currentPage));
        // eslint-disable-next-line 
    }, [currentPage, transactionSplit.splitAccountIndex?.accounts]);

    // Search Function And Control
    const onSearch = (search) => {
        const filteredData = transactionSplit.splitAccountIndex?.accounts?.filter(item => {
            return item.reference.toLowerCase().includes(search.toLowerCase());
        });
        setTableData(pagination(filteredData ?? [], 10, currentPage));
    }

    const removeSplitAccount = (id) => {
        dispatch(deleteSplitAccount({id}));
    }

    const renderTransactionSplitTable = (data) => {
        return data.map((item,key)=>{
            return (
                <CardTableBodyRow key={key}>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.split_name}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two lowercase">
                            {transactionSplit?.splitAccountIndex?.banks[item.bank_code]}
                            {transactionSplit?.splitAccountIndex?.banks[item.bank_code] && <br/>}
                            {item.account_number}
                        </span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.split_type}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.reference}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                  <span className="glade-normal-text-one">{item.split_type === "fixed" ? "#" : "" }{item.share}{item.split_type === "percentage" ? "%" : "" }</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <PipedButton 
                            primaryButtonTitle="Edit" 
                            primaryButtonOnClick={()=>history.push({pathname:!permissions?.includes("transaction split") ? "/dashboard/transaction-split/edit-split-account" : "/teams/transaction-split/edit-split-account", state:item})}
                            secondaryButtonTitle="Delete" 
                            secondaryButtonOnClick={()=>removeSplitAccount(item.id)}
                            secondaryButtonClassName="glade-red"
                        />
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                </CardTableBodyRow>
            )
        });
    }

    const renderTransactionSplitDesk = (data) => {
        return data.map((item, key) => {
          return (
            <div key={key} className="flex flex-row justify-between p-1 border-b" onClick={()=>history.push({pathname: !permissions?.includes("transaction split") ? '/dashboard/transaction-split/transaction-split-detail' : '/teams/transaction-split/transaction-split-detail',state:item})}>
              <div className="flex flex-row">
                <div className="flex flex-col space-y-1 py-1">
                  <div>
                    <span className="glade-subtitle-one glade-black whitespace-nowrap">
                        {item.split_name}
                    </span>
                  </div>
                  <div>
                    <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                        {item.split_type}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <IoIosArrowForward />
              </div>
            </div>
          );
        });
    };

    return (
        <div className="p-3">
            <CardTableHeaderComplex 
                title={`Showing ${tableData.totalItems} records`}
                searchBar={<SearchInput disabled={transactionSplit.isLoading} onSearch={onSearch} placeholder={'Reference'} />}
                rightButton={
                    <div>
                        <Button.Blue title={'+ New Split Account'} className="whitespace-nowrap mx-3" onClick={()=>history.push(!permissions?.includes("transaction split") ? '/dashboard/transaction-split/new-split-account' : '/teams/transaction-split/new-split-account')} />
                    </div>
                }
                kebabMenuButton={
                    <div>
                      <KebabDropdown className="z-10" disabled={transactionSplit.isLoading}>
                        <KebabDropdownItem
                          activeChild={<span onClick={()=>history.push(!permissions?.includes("transaction split") ? '/dashboard/transaction-split/new-split-account' : '/teams/transaction-split/new-split-account')} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">New Split Account</span>}
                          inactiveChild={<span className="glade-normal-text-two glade-black p-4"> New Split Account</span>}
                        />
                      </KebabDropdown>
                    </div>
                }
            />

            {isEmpty(tableData.data) || transactionSplit.isLoading?
                <React.Fragment>
                    <div className="hidden md:block">
                        <EmptyTable column={['','Name','Bank Account','Split Type','Split Reference','Split Share',' ','']} loading={transactionSplit.isLoading} />
                    </div>

                    <div className="block md:hidden">
                        <EmptyDesk row={[1,2]} loading={transactionSplit.isLoading} />
                    </div>
                </React.Fragment>
            :
                <React.Fragment>
                    <div className="hidden md:block">
                        <CardTableBody column={['','Name','Bank Account','Split Type','Split Reference','Split Share',' ','']}>
                            {isArray(tableData.data) && renderTransactionSplitTable(tableData.data)}
                        </CardTableBody>
                    </div>

                    <div className="block md:hidden">
                        <div className="bg-white px-6">
                            {isArray(tableData.data) && renderTransactionSplitDesk(tableData.data)}
                        </div>
                    </div>
                </React.Fragment>
            }

            <CardTableFooter 
                empty={isEmpty(tableData.data)}
                loading={transactionSplit.isLoading}
                pageNumber={currentPage} 
                totalPages={tableData.totalPages}
                prevOnClick={()=>setCurrentPage(tableData.previousPage)}
                nextOnClick={()=>setCurrentPage(tableData.nextPage)}
                thereIsPreviousPage={tableData.thereIsPreviousPage}
                thereIsNextPage={tableData.thereIsNextPage}
            />
        </div>
    )
}
