// Application types
export const LOADING_FUND_TRANSFER = 'LOADING_FUND_TRANSFER';
export const UPDATE_TRANSFER_STATUS = 'UPDATE_TRANSFER_STATUS';
export const LOADING_RESOLVE_DETAILS = "LOADING_ACCOUNT_DETAILS";
export const GET_ALL_FUND_TRANSFER = 'GET_ALL_FUND_TRANSFER';
export const GET_FUND_TRANSFER_MODE = 'GET_FUND_TRANSFER_MODE';
export const LOADING_FUND_TRANSFER_BENEFICIARIES = 'LOADING_FUND_TRANSFER_BENEFICIARIES';
export const GET_FUND_TRANSFER_BENEFICIARIES = 'GET_FUND_TRANSFER_BENEFICIARIES';
export const LOADING_USER_TAG_BENEFICIARIES = 'LOADING_USER_TAG_BENEFICIARIES';
export const GET_USER_TAG_BENEFICIARIES = 'GET_USER_TAG_BENEFICIARIES';
export const RESOLVE_ACCOUNT_DETAILS = 'RESOLVE_ACCOUNT_DETAILS';
export const GET_BANKS = 'GET_BANKS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_INTERNATIONAL_COUNTRIES = 'GET_INTERNATIONAL_COUNTRIES';
export const GET_INTERNATIONAL_TRANSFER_METHODS = 'GET_INTERNATIONAL_TRANSFER_METHODS';
export const GET_INTERNATIONAL_TRANSFER_BANKS = 'GET_INTERNATIONAL_TRANSFER_BANKS';
export const GET_INTERNATIONAL_TRANSFER_BANKS_BRANCHES = 'GET_INTERNATIONAL_TRANSFER_BANKS_BRANCHES';
export const GET_INTERNATIONAL_TRANSFER_RATE = 'GET_INTERNATIONAL_TRANSFER_RATE';
export const GET_INTERNATIONAL_TRANSFER_RATE_FAIL = 'GET_INTERNATIONAL_TRANSFER_RATE_FAIL';
export const GET_LOCAL_TRANSFER_RATE = 'GET_LOCAL_TRANSFER_RATE';
export const MAKE_INTERNAL_TRANSFER = 'MAKE_INTERNAL_TRANSFER';
export const MAKE_EXTERNAL_TRANSFER = 'MAKE_EXTERNAL_TRANSFER';
export const MAKE_EXTERNAL_BULK_TRANSFER = 'MAKE_EXTERNAL_BULK_TRANSFER';
export const MAKE_INTERNATIONAL_TRANSFER = 'MAKE_INTERNATIONAL_TRANSFER';
export const SEARCH_FUND_TRANSFER = 'SEARCH_FUND_TRANSFER';
export const  FUND_TRANSFER_SUCCESS = ' FUND_TRANSFER_SUCCESS';
export const  FUND_TRANSFER_FAILURE = ' FUND_TRANSFER_FAILURE';
export const REMEMBER_BULK_FUND_TRANSFER_LIST = 'REMEMBER_BULK_FUND_TRANSFER_LIST';


export const GET_LOCAL_INTERNATION_RATE_START = "GET_LOCAL_INTERNATION_RATE_START";
export const GET_LOCAL_INTERNATION_RATE_SUCCESS = "GET_LOCAL_INTERNATION_RATE_SUCCESS";
export const GET_LOCAL_INTERNATION_RATE_FAILURE = "GET_LOCAL_INTERNATION_RATE_FAILURE";
