import React, { useState, useEffect } from "react";
import nigeria_flag from "../../assets/images/nigeria_flag.svg";
import britain_flag from "../../assets/images/britain_flag.svg";
import usa_flag from "../../assets/images/usa_flag.svg";
import euro_flag from "../../assets/images/euro_flag.svg";
import {
  currencySymbolEnum,
  getSuppliedFieldValue,
} from "../../helpers/helper";
import useUser from "../../hooks/useUser";
import useTeam from "../../hooks/useTeam";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { getAllIFAccountCreatedAccount, getAllWalletBalances } from "../../redux/balances/balancesActions";
import useBalances from "../../hooks/useBalances";
import { getCurrencyName } from "../../assets/icons/BalancesFlagIcons";
import ReceiverFlag from "../../components/transfer/ReceiverFlag";
import { useHistory } from "react-router-dom";
import AccountDetailModal from "./AccountDetailModal";
import { Fragment } from "react";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const NewAccountBalance = () => {
  const user = useUser();
  const {team_user, permissions} = useTeam();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {isLoadingBusinessPersonalAccounts} = useUser();
  const { balancesData, isLoadingBalances } = useBalances();
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [accountDetailsToShow, setAccountDetailsToShow] = useState({});

  useEffect(() => {
    dispatch(getPersonalAndBusinessAccounts());
    if (user?.isBusiness || team_user?.employee?.is_teams) {
      dispatch(getAllWalletBalances());
      dispatch(getAllIFAccountCreatedAccount())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isBusiness]);

  return (
    <>
      <div className="border border-glade-gray-80 p-5 rounded-lg " style={{ height: '450px' }}>
        <div>
          {isLoadingBalances || isLoadingBusinessPersonalAccounts ? (
            <div className="flex justify-center items-center">
              <RiLoader5Fill size={24} className="animate-spin ml-4" />
            </div>
          ) : (
            <Fragment>
              <div className="text-left glade-heading-two glade-home-dark">
                <span className="font-bold">
                  {currencySymbolEnum[
                    user?.isBusiness || team_user?.employee?.is_teams
                      ? balancesData?.currency
                      : getSuppliedFieldValue("currency", user)
                  ] ?? "" + " "}
                  {user.isBusiness || team_user?.employee?.is_teams
                    ? (user.isBusiness ? balancesData?.balance : team_user?.employee?.is_teams && permissions?.includes("balances") ? balancesData?.balance : "*******")
                    :getSuppliedFieldValue("balance", user)
                      ? Number(
                        getSuppliedFieldValue("balance", user)
                      ).toLocaleString()
                      : "0.00"
                  }
                </span>
              </div>
              <div className="flex justify-start items-center ">
                <span>
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "NGN") ||
                    (balancesData?.data?.currency === "NGN" && (
                      <img src={nigeria_flag} />
                    ))}
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "USD") ||
                    (balancesData?.data?.currency === "USD" && (
                      <img src={usa_flag} />
                    ))}
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "GBP") ||
                    (balancesData?.data?.currency === "GBP" && (
                      <img src={britain_flag} />
                    ))}
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "EUR") ||
                    (balancesData?.data?.currency === "EUR" && (
                      <img src={euro_flag} />
                    ))}
                </span>
                <span className="glade-normal-text-four text-glade-gray-600 ">
                  Total Balance{" "}
                  {getSuppliedFieldValue("currency", user) && !user?.isBusiness
                    ? `in ${getSuppliedFieldValue("currency", user) ?? ""}`
                    : `in ${balancesData?.currency  ?? ""}`}
                </span>
              </div>
              <div className="bg-glade-off-white rounded-lg px-6 mt-6">
                {/* Business/Personal accounts */}
                {!user?.singleBusinessDetails?.hasOwnProperty(
                  "currency"
                ) && !location?.pathname?.includes('teams') &&
                  user.singleBusinessDetails?.accounts?.length > 0
                  ? user.singleBusinessDetails?.accounts.map(
                    (account, index) =>
                      index <= 2 && (
                        <div
                          className={` py-5 flex items-center justify-between ${index < 2 && "border-b border-glade-gray-80"
                            }`}
                          key={index}
                        >
                          <div className="flex items-center gap-x-4">
                            <div>
                              <ReceiverFlag
                                flagCode={account?.currency?.slice(0, -1)}
                                size={"25"}
                              />
                            </div>
                            <div>
                              <p className="glade-off-black text-base font-bold">
                                {Number(account?.balance).toLocaleString(
                                  "en-us"
                                )}{" "}
                                {account?.currency}
                              </p>
                              <h6 className="text-glade-gray-400 text-xs ">
                                {getCurrencyName(account?.currency)}
                              </h6>
                            </div>
                          </div>
                          <button
                            className="text-right text-xs"
                            onClick={() => {
                              setShowAccountDetails(true);
                              setAccountDetailsToShow(account);
                            }}
                          >
                            <p className="glade-blue">
                              View Account Details
                            </p>
                            {account.account_status === "pending" && (
                              <p className="glade-yellow">Pending</p>
                            )}
                          </button>
                        </div>
                      )
                  )
                  : null}
                {/* Teams accounts */}
                {
                  team_user?.business?.accounts && location?.pathname?.includes('teams')
                  ?
                  team_user?.business?.accounts.map(
                    (account, index) =>
                      index <= 2 && (
                        <div
                          className={` py-5 flex items-center justify-between ${index < 2 && "border-b border-glade-gray-80"
                            }`}
                          key={index}
                        >
                          <div className="flex items-center gap-x-4">
                            <div>
                              <ReceiverFlag
                                flagCode={account?.currency?.slice(0, -1)}
                                size={"25"}
                              />
                            </div>
                            <div>
                              <p className="glade-off-black text-base font-bold">
                                {team_user?.employee?.is_teams && permissions?.includes("balances") ? Number(account?.balance).toLocaleString(
                                  "en-us"
                                ) : "***"}{" "}
                                {account?.currency}
                              </p>
                              <h6 className="text-glade-gray-400 text-xs ">
                                {getCurrencyName(account?.currency)}
                              </h6>
                            </div>
                          </div>
                          {((team_user?.employee?.is_teams && permissions?.includes("balances")) || !team_user?.employee?.is_teams) &&
                          <button
                            className="text-right text-xs"
                            onClick={() => {
                              setShowAccountDetails(true);
                              setAccountDetailsToShow(account);
                            }}
                          >
                            <p className="glade-blue">
                              View Account Details
                            </p>
                            {account.account_status === "pending" && (
                              <p className="glade-yellow">Pending</p>
                            )}
                          </button>}
                        </div>
                      )
                  ): null
                }
              </div>
              {user?.isBusiness || team_user?.employee?.is_teams && 
              <div className="text-center pt-6 pb-4">
                <button
                  className=" text-xs glade-blue"
                  onClick={() => location?.pathname?.includes('teams') ? history.push("/teams/balances") : history.push("/dashboard/balances")}
                >
                  View all balances
                </button>
              </div>}
            </Fragment>
          )}
        </div>
      </div>
      {showAccountDetails &&
        <AccountDetailModal
          showAccountDetails={showAccountDetails}
          setShowAccountDetails={setShowAccountDetails}
          accountDetailsToShow={accountDetailsToShow}
        />
      }

    </>
  );
};

export default NewAccountBalance;
