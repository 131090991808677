import {
  LOADING_CUSTOMERS,
  LOADING_CUSTOMER_DETAILS,
  RELOAD_CUSTOMERS,
  CREATE_CUSTOMER,
  BLACKLIST_CUSTOMER,
  WHITELIST_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_BY_EMAIL,
  GET_BLACKLISTED_CUSTOMERS,
  GET_WHITELISTED_CUSTOMERS
} from "./types";

const initialState = {
  isLoading:false,
  isLoadingDetails:false,
  isBlacklisting:false,
  isWhitelisting:false,
  shouldReload:false,
  createdCustomer:{},
  blacklistedCustomer:{},
  whitelistedCustomer:{},
  customerIndex:[],
  customerById:{},
  customerByEmail:{},
  blacklistedCustomerIndex:[],
  whitelistedCustomerIndex:[],
};

export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_CUSTOMERS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOADING_CUSTOMER_DETAILS:
      return {
        ...state,
        isLoadingDetails: action.payLoad,
      };

    case RELOAD_CUSTOMERS:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case CREATE_CUSTOMER:
      return {
        ...state,
        createdCustomer: action.payLoad.data.data,
      };

    case BLACKLIST_CUSTOMER:
      return {
        ...state,
        blacklistedCustomer: action.payLoad.data.data,
      };

    case WHITELIST_CUSTOMER:
      return {
        ...state,
        whitelistedCustomer: action.payLoad.data.data,
      };

    case GET_CUSTOMERS:
      return {
        ...state,
        customerIndex: action.payLoad.data.data,
      };

    case GET_CUSTOMER_BY_ID:
      return {
        ...state,
        customerById: action.payLoad.data.data,
      };

    case GET_CUSTOMER_BY_EMAIL:
      return {
        ...state,
        customerByEmail: action.payLoad.data.data,
      };

    case GET_BLACKLISTED_CUSTOMERS:
      return {
        ...state,
        blacklistedCustomerIndex: action.payLoad.data.data,
      };

    case GET_WHITELISTED_CUSTOMERS:
      return {
        ...state,
        whitelistedCustomerIndex: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
