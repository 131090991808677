import { GrFormClose } from "react-icons/gr";
import { FaCheckCircle } from "react-icons/fa";
import BasicModal from "../../components/modals/BasicModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/buttons/Button";

const VirtualCardSuccess = ({
  setShowSuccess,
  showSuccess,
  title,
  subtext,
}) => {
  const history = useHistory();

  return (
    <BasicModal
      visibility={showSuccess}
      onClose={() => {
        setShowSuccess(false);
      }}
      primaryButton={true} // Pass true to or valid component to avoid default display
      secondaryButton={true} // Pass true to or valid component to avoid default display
      modalTitleSection={
        <div className="capitalize pb-2 self-right w-full relative">
          <span className="absolute right-0">
            <GrFormClose
              size={"20"}
              color="#9CA3AF"
              onClick={() => setShowSuccess(false) & history.goBack()}
              className="cursor-pointer"
            />
          </span>
        </div>
      }
      modelContentSection={
        <div className="mt-6 flex flex-col items-center justify-center gap-4">
          <FaCheckCircle size={"60"} color="#1DD11D" className="mt-8 mb-4" />
          <p className="glade-heading-two">{title}</p>
          <p className="text-center mb-6">
            {subtext}
          </p>
          <Button.Blue title={"Continue"} onClick={()=> setShowSuccess(false)} />
        </div>
      }
    />
  );
};
export default VirtualCardSuccess;
