import React from 'react';

export default function Store() {
    return (
        <div className="inline-flex flex-col space-y-8 items-start justify-start" style={{width: 1186, height: 681,}}>
            <div className="flex flex-col space-y-0.5 items-start justify-start" style={{width: 1186, height: 681,}}>
                <div className="inline-flex items-center justify-start pl-5" style={{width: 1184, height: 34,}}>
                    <div className="flex space-x-96 items-center justify-start" style={{width: 576.50, height: 34,}}>
                        <div className="inline-flex flex-col space-y-3 items-start justify-end" style={{width: 576.50, height: 34,}}>
                            <div className="inline-flex space-x-14 items-center justify-center px-7" style={{width: 576, height: 21,}}>
                                <p className="text-base font-bold leading-tight text-blue-500">Orders</p>
                                <p className="text-base leading-tight text-gray-500">Products</p>
                                <p className="text-base leading-tight text-gray-500">Categories</p>
                                <p className="text-base leading-tight text-gray-500">Storefronts</p>
                                <p className="text-base leading-tight text-gray-500">Delivery</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shadow" style={{width: 1186, height: 646,}}>
                    <div className="flex flex-col items-start justify-start" style={{width: 1186, height: 646,}}>
                        <div className="flex flex-col items-start justify-start rounded-tl-sm rounded-tr-sm" style={{width: 1186, height: 131,}}>
                            <div className="flex flex-col space-y-8 items-center justify-center py-5 bg-white rounded-tl rounded-tr" style={{width: 1186, height: 131,}}>
                                <div className="inline-flex items-start justify-start" style={{width: 1100, height: 23,}}>
                                    <p className="text-lg font-medium leading-snug text-gray-600">Orders - 5</p>
                                </div>
                                <div className="inline-flex space-x-96 items-center justify-between" style={{width: 1100, height: 36,}}>
                                    <div className="flex space-x-6 items-center justify-start" style={{width: 404, height: 36,}}>
                                        <div className="flex space-x-1 items-center justify-start bg-white">
                                            <div className="flex items-center justify-center w-4 h-4 px-0.5 py-1">
                                                <div className="flex-1 h-full bg-gray-400 rounded-full"/>
                                            </div>
                                            <p className="text-base font-bold leading-tight text-gray-400">Filter</p>
                                        </div>
                                        <div className="flex items-center justify-start w-80 py-2 pl-4 pr-24 bg-white border border-gray-200">
                                            <div className="flex space-x-2.5 items-center justify-start">
                                                <div className="flex items-center justify-center w-4 h-4">
                                                    <div className="flex-1 h-full bg-gray-400 border rounded-full border-gray-400"/>
                                                </div>
                                                <p className="text-sm leading-tight text-gray-400">Order ID or Customer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-blue-500 border rounded-sm">
                                        <div className="flex space-x-2 items-center justify-center px-4 py-2">
                                            <p className="text-sm font-medium leading-tight text-white">Download CSV</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-gray-200" style={{width: 1186, height: 1,}}/>
                        </div>
                        <div className="flex flex-col items-center justify-start" style={{width: 1186, height: 70,}}>
                            <div className="inline-flex space-x-5 items-center justify-between px-16 py-6 bg-white" style={{width: 1186, height: 70,}}>
                                <div className="flex items-start justify-start w-32">
                                    <p className="text-sm font-bold leading-tight text-gray-600">Status</p>
                                </div>
                                <div className="flex items-start justify-start w-28">
                                    <p className="text-sm font-bold leading-tight text-gray-600">Order ID</p>
                                </div>
                                <div className="flex items-start justify-start w-1/6">
                                    <p className="text-sm font-bold leading-tight text-gray-600">Customer</p>
                                </div>
                                <div className="flex items-start justify-start w-32">
                                    <p className="text-sm font-bold leading-tight text-gray-600">Amount</p>
                                </div>
                                <div className="flex items-start justify-start w-28">
                                    <p className="text-sm font-bold leading-tight text-gray-600">Date</p>
                                </div>
                            </div>
                            <div className="border-gray-200" style={{width: 1186, height: 1,}}/>
                        </div>
                        <div className="flex flex-col space-y-6 items-center justify-center px-11 pb-5 bg-white rounded-bl rounded-br" style={{width: 1186, height: 445,}}>
                            <div className="flex flex-col items-start justify-start" style={{width: 1100, height: 365,}}>
                                <div className="flex flex-col items-center justify-start" style={{width: 1100, height: 73,}}>
                                    <div className="inline-flex space-x-5 items-center justify-between p-6 bg-white" style={{width: 1100, height: 73,}}>
                                        <div className="flex items-center justify-start w-32">
                                            <div className="flex items-center justify-center h-full p-5 bg-yellow-400 bg-opacity-10 border rounded-full">
                                                <p className="flex-1 text-sm leading-tight text-center text-yellow-400">Pending</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">000027</p>
                                        </div>
                                        <div className="flex items-start justify-start w-1/6">
                                            <p className="text-sm leading-tight text-blue-500">hansolo@gmail.com</p>
                                        </div>
                                        <div className="flex items-start justify-start w-32">
                                            <p className="text-sm font-bold leading-tight text-gray-600">NGN 11,750.00</p>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">Aug 27, 2021</p>
                                        </div>
                                    </div>
                                    <div className="border-gray-200" style={{width: 1100, height: 1,}}/>
                                </div>
                                <div className="flex flex-col items-center justify-start" style={{width: 1100, height: 73,}}>
                                    <div className="inline-flex space-x-5 items-center justify-between p-6 bg-white" style={{width: 1100, height: 73,}}>
                                        <div className="flex items-center justify-start w-32">
                                            <div className="flex items-center justify-center h-full p-5 bg-green-600 bg-opacity-10 border rounded-full">
                                                <p className="text-sm leading-tight text-green-600">Delivered</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">000026</p>
                                        </div>
                                        <div className="flex items-start justify-start w-1/6">
                                            <p className="text-sm leading-tight text-blue-500">hansolo@gmail.com</p>
                                        </div>
                                        <div className="flex items-start justify-start w-32">
                                            <p className="text-sm font-bold leading-tight text-gray-600">NGN 14,000.00</p>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">Aug 27, 2021</p>
                                        </div>
                                    </div>
                                    <div className="border-gray-200" style={{width: 1100, height: 1,}}/>
                                </div>
                                <div className="flex flex-col items-center justify-start" style={{width: 1100, height: 73,}}>
                                    <div className="inline-flex space-x-5 items-center justify-between p-6 bg-white" style={{width: 1100, height: 73,}}>
                                        <div className="flex items-center justify-start w-32">
                                            <div className="flex items-center justify-center h-full p-5 bg-green-600 bg-opacity-10 border rounded-full">
                                                <p className="text-sm leading-tight text-green-600">Delivered</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">000025</p>
                                        </div>
                                        <div className="flex items-start justify-start w-1/6">
                                            <p className="text-sm leading-tight text-blue-500">hansolo@gmail.com</p>
                                        </div>
                                        <div className="flex items-start justify-start w-32">
                                            <p className="text-sm font-bold leading-tight text-gray-600">NGN 4,000.00</p>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">Aug 27, 2021</p>
                                        </div>
                                    </div>
                                    <div className="border-gray-200" style={{width: 1100, height: 1,}}/>
                                </div>
                                <div className="flex flex-col items-center justify-start" style={{width: 1100, height: 73,}}>
                                    <div className="inline-flex space-x-5 items-center justify-between p-6 bg-white" style={{width: 1100, height: 73,}}>
                                        <div className="flex items-center justify-start w-32">
                                            <div className="flex items-center justify-center h-full p-5 bg-green-600 bg-opacity-10 border rounded-full">
                                                <p className="text-sm leading-tight text-green-600">Delivered</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">000024</p>
                                        </div>
                                        <div className="flex items-start justify-start w-1/6">
                                            <p className="text-sm leading-tight text-blue-500">hansolo@gmail.com</p>
                                        </div>
                                        <div className="flex items-start justify-start w-32">
                                            <p className="text-sm font-bold leading-tight text-gray-600">NGN 23,000.00</p>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">Aug 27, 2021</p>
                                        </div>
                                    </div>
                                    <div className="border-gray-200" style={{width: 1100, height: 1,}}/>
                                </div>
                                <div className="flex flex-col items-center justify-start" style={{width: 1100, height: 73,}}>
                                    <div className="inline-flex space-x-5 items-center justify-between p-6 bg-white" style={{width: 1100, height: 73,}}>
                                        <div className="flex items-center justify-start w-32">
                                            <div className="flex items-center justify-center h-full p-5 bg-green-600 bg-opacity-10 border rounded-full">
                                                <p className="text-sm leading-tight text-green-600">Delivered</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">000023</p>
                                        </div>
                                        <div className="flex items-start justify-start w-1/6">
                                            <p className="text-sm leading-tight text-blue-500">hansolo@gmail.com</p>
                                        </div>
                                        <div className="flex items-start justify-start w-32">
                                            <p className="text-sm font-bold leading-tight text-gray-600">NGN 41,000.00</p>
                                        </div>
                                        <div className="flex items-start justify-start w-28">
                                            <p className="text-sm leading-tight text-gray-600">Aug 27, 2021</p>
                                        </div>
                                    </div>
                                    <div className="border-gray-200" style={{width: 1100, height: 1,}}/>
                                </div>
                            </div>
                            <div className="inline-flex items-center justify-between" style={{width: 1100, height: 36,}}>
                                <p className="text-base font-bold leading-loose text-gray-400">Page 1 of 1</p>
                                <div className="flex space-x-6 items-start justify-start">
                                    <div className="inline-flex flex-col items-start justify-start px-1 py-0.5 rounded">
                                        <p className="text-base font-bold leading-loose text-gray-400">Prev</p>
                                    </div>
                                    <div className="inline-flex flex-col items-start justify-start px-1 py-0.5 rounded">
                                        <p className="text-base font-bold leading-loose text-gray-400">Next</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
