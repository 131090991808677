// Application types
export const LOADING_PAYMENT_LINKS = 'LOADING_PAYMENT_LINKS';
export const GET_ALL_CURRENCIES = 'GET_ALL_CURRENCIES';
export const GET_ALL_PAYMENT_TYPE = 'GET_ALL_PAYMENT_TYPE';
export const GET_ALL_PAYMENT_LINKS = 'GET_ALL_PAYMENT_LINKS';
export const STORE_PAYMENT_LINK = 'STORE_PAYMENT_LINK';
export const UPDATE_PAYMENT_LINK = 'UPDATE_PAYMENT_LINK';
export const GET_PAYMENT_LINK_TRANSACTIONS = 'GET_PAYMENT_LINK_TRANSACTIONS';
export const GET_PAYMENT_LINK_DETAIL = 'GET_PAYMENT_LINK_DETAIL';
export const DELETE_PAYMENT_LINK = 'DELETE_PAYMENT_LINK';
export const DEACTIVATE_PAYMENT_LINK = 'DEACTIVATE_PAYMENT_LINK';
export const ACTIVATE_PAYMENT_LINK = 'ACTIVATE_PAYMENT_LINK';
export const GET_SPLIT_ACCOUNTS = 'GET_SPLIT_ACCOUNTS';
export const LOADING_SPLIT_ACCOUNTS = 'LOADING_SPLIT_ACCOUNTS';
