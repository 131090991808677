import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import NavbarAuth from "../components/navbars/NavbarAuth";
import FooterAuth from "../components/footers/FooterAuth";

// pages
import GettingStarted from "../pages/auth/GettingStarted";
 import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register-old";
import RestPassword from "../pages/auth/RestPassword-old";
import Logout from "../pages/auth/Logout";
import OtploginAccess from "../pages/auth/OtploginAccess";

export default function AuthLayout() {
  return (
    <React.Fragment>
      <main className="relative bg-white min-h-screen">
        {/* Navbar */}
        <NavbarAuth />

        {/* Body */}
        <section>
          <Switch>
            <Route path="/auth/getstarted" exact component={GettingStarted} />
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/reset-password" exact component={RestPassword} />
            <Route path="/auth/logout" exact component={Logout} />
            <Route path="/auth/otplogin" exact component={OtploginAccess}  />
            <Redirect from="/auth" to="/auth/getstarted" />
          </Switch>

          {/* Footer */}
          <FooterAuth />
        </section>
      </main>
    </React.Fragment>
  );
}
