

export const LocalNonNairaTransferFormEnum = {
    'GBP': {
        formList: [
            { title: 'Bank Account Type', type: 'radio', id: 'beneficiary_account_type' },
            { title: 'Recipient’s Phone Number', placeholder: 'Enter Phone number', id: 'beneficiary_phone', type: 'text', },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            // { title: 'SWIFT/BIC Code', placeholder: 'Enter SWIFT/BIC Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'SWIFT/BIC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Email', placeholder: 'Enter email', id: 'beneficiary_email', type: 'text' },
            // { title: 'Bank Sort Code', placeholder: 'Enter sort code', id: 'beneficiary_sort_code', type: 'number', hasInstructionIcon: true, instructionTitle: ' A sort code is a 6 digit number that identifies the beneficiary’s bank' },
        ],
        reviewList: [
            'beneficiary_phone', 'beneficiary_email', 'beneficiary_account_type',
        ]
    },
    'EUR': {
        formList: [
            { title: 'Bank Account Type', type: 'radio', id: 'beneficiary_account_type' },
            { title: 'Recipient’s Phone Number', placeholder: 'Enter Phone number', id: 'beneficiary_phone', type: 'text', },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Email', placeholder: 'Enter email', id: 'beneficiary_email', type: 'text' },
        ],
        reviewList: [
            'beneficiary_phone', 'beneficiary_email', 'beneficiary_account_type',
        ]
    },
    'USD': {
        formList: [
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Bank Swift Code', placeholder: 'Enter swift code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: ' A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Bank Sort Code', placeholder: 'Enter sort code', id: 'beneficiary_sort_code', type: 'text', hasInstructionIcon: true, instructionTitle: ' A sort code is a 6 digit number that identifies the beneficiary’s bank' },
            { title: 'Routing Number', placeholder: 'Enter email', id: 'beneficiary_routing_number', type: 'text' },
            { title: 'Recipient’s Email', placeholder: 'Enter email', id: 'beneficiary_email', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: [
            'bank_institution_number', 'beneficiary_swift_code', 'beneficiary_routing_number'
        ]
    },
    'CAD': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Bank Institution Number', placeholder: 'Enter bank institution number', id: 'bank_institution_number', type: 'number', hasInstructionIcon: true, instructionTitle: 'Bank Institution Number', instructionText: 'The Bank Institution Number is a unique three-digit code assigned to a certain bank or financial institution to identify them.' },
            { title: 'Transit Number', placeholder: 'Enter transit number', id: 'transit_number', type: 'number', hasInstructionIcon: true, instructionTitle: 'Transit Number', instructionText: 'This is a standard 5-digit number used by banks and financial agents in Canada to identify an account' },
        ],
        reviewList: [
            'bank_institution_number', 'transit_number',
        ]
    },
    'AUD': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'BSB', placeholder: 'Enter BSB', id: 'bank_state_branch', type: 'text', hasInstructionIcon: true, instructionTitle: 'Bank State Branch', instructionText: 'A Bank State Branch (BSB) is the name used in Australia for a bank code, which is a branch identifier. The BSB is normally used in association with the account number system used by each financial institution.' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
        ],
        reviewList: [
            'bank_state_branch'
        ]
    },
    'JPY': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'SWIFT/BIC Code', placeholder: 'Enter SWIFT/BIC Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'SWIFT/BIC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
        ],
        reviewList: ['beneficiary_swift_code']
    },
    'NZD': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'SWIFT/BIC Code', placeholder: 'Enter SWIFT/BIC Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'SWIFT/BIC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
        ],
        reviewList: ['beneficiary_swift_code']
    },
    'INR': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'IFSC Code', placeholder: 'Enter IFSC Code', id: 'ifsc', type: 'text', hasInstructionIcon: true, instructionTitle: 'IFSC Code', instructionText: 'The Indian Financial System Code (also known as IFSC) is a 11 character code for identifying the bank and branch which an account is held' },
        ],
        reviewList: [
            'ifsc'
        ]
    },
    'CNY': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'SWIFT/BIC Code', placeholder: 'Enter SWIFT/BIC Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'IFSC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
        ],
        reviewList: ['beneficiary_swift_code']
    },
    'QAR': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'IBAN', placeholder: 'Enter IBAN', id: 'beneficiary_swift_code', hasBeneficiary: true, type: 'text', hasInstructionIcon: true, instructionTitle: 'IFSC Code', instructionText: ' IBAN stands for International Bank Account Number. It’s an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world.' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
        ],
        reviewList: ['beneficiary_swift_code']
    },
    'RWF': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
        ],
        reviewList: ['transfer_method']
    },
    'GHS': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
        ],
        reviewList: ['transfer_method']
    },
    'KES': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
        ],
        reviewList: ['transfer_method']
    },
    'ZAR': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
        ],
        reviewList: ['transfer_method']
    },
    'TZS': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
        ],
        reviewList: ['transfer_method']
    },
    'UGX': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
        ],
        reviewList: ['transfer_method']
    },
    'ZMW': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
        ],
        reviewList: ['transfer_method']
    }
}

export const InternationalNonNairaTransferFormEnum = {
    'GBP': {
        formList: [
            { title: 'Bank Account Type', type: 'radio', id: 'beneficiary_account_type' },
            { title: 'Recipient’s Phone Number', placeholder: 'Enter Phone number', id: 'beneficiary_phone', type: 'text', },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Email', placeholder: 'Enter email', id: 'beneficiary_email', type: 'text' },
            { title: 'Bank Swift Code', placeholder: 'Enter SWIFT Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'SWIFT/BIC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Bank Sort Code', placeholder: 'Enter Sort Code', id: 'beneficiary_sort_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'Sort Code', instructionText: 'A sort code is a 6 digit number that identifies the beneficiary’s bank' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: [
            'beneficiary_phone', 'beneficiary_email', 'beneficiary_account_type', 'beneficiary_swift_code', 'beneficiary_sort_code', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code'
        ]
    },
    'EUR': {
        formList: [
            { title: 'Bank Account Type', type: 'radio', id: 'beneficiary_account_type' },
            { title: 'Recipient’s Phone Number', placeholder: 'Enter Phone number', id: 'beneficiary_phone', type: 'text', },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Email', placeholder: 'Enter email', id: 'beneficiary_email', type: 'text' },
            { title: 'Bank Swift Code', placeholder: 'Enter swift code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: ' A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Bank Sort Code', placeholder: 'Enter sort code', id: 'beneficiary_sort_code', type: 'text', hasInstructionIcon: true, instructionTitle: ' A sort code is a 6 digit number that identifies the beneficiary’s bank' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: [
            'beneficiary_phone', 'beneficiary_email', 'beneficiary_account_type', 'beneficiary_swift_code', 'beneficiary_sort_code', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code'
        ]
    },
    'USD': {
        formList: [
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Bank Swift Code', placeholder: 'Enter swift code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: ' A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Bank Sort Code', placeholder: 'Enter sort code', id: 'beneficiary_sort_code', type: 'text', hasInstructionIcon: true, instructionTitle: ' A sort code is a 6 digit number that identifies the beneficiary’s bank' },
            { title: 'Routing Number', placeholder: 'Enter email', id: 'beneficiary_routing_number', type: 'text' },
            { title: 'Recipient’s Email', placeholder: 'Enter email', id: 'beneficiary_email', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: [
            'beneficiary_sort_code', 'beneficiary_swift_code', 'beneficiary_routing_number', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code'
        ]
    },
    'CAD': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Bank Institution Number', placeholder: 'Enter bank institution number', id: 'bank_institution_number', type: 'number', hasInstructionIcon: true, instructionTitle: 'Bank Institution Number', instructionText: 'The Bank Institution Number is a unique three-digit code assigned to a certain bank or financial institution to identify them.' },
            { title: 'Transit Number', placeholder: 'Enter transit number', id: 'transit_number', type: 'number', hasInstructionIcon: true, instructionTitle: 'Transit Number', instructionText: 'This is a standard 5-digit number used by banks and financial agents in Canada to identify an account' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: [
            'bank_institution_number', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code', 'transit_number',
        ]
    },
    'AUD': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'BSB', placeholder: 'Enter BSB', id: 'bank_state_branch', type: 'text', hasInstructionIcon: true, instructionTitle: 'Bank State Branch', instructionText: 'A Bank State Branch (BSB) is the name used in Australia for a bank code, which is a branch identifier. The BSB is normally used in association with the account number system used by each financial institution.' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: [
            'bank_state_branch', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code', 
        ]
    },
    'JPY': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'SWIFT/BIC Code', placeholder: 'Enter SWIFT/BIC Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'SWIFT/BIC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['beneficiary_swift_code', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'NZD': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'SWIFT/BIC Code', placeholder: 'Enter SWIFT/BIC Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'SWIFT/BIC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['beneficiary_swift_code', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'INR': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'IFSC Code', placeholder: 'Enter IFSC Code', id: 'ifsc', type: 'text', hasInstructionIcon: true, instructionTitle: 'IFSC Code', instructionText: 'The Indian Financial System Code (also known as IFSC) is a 11 character code for identifying the bank and branch which an account is held' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: [
            'ifsc', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code'
        ]
    },
    'CNY': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Account Number', placeholder: 'Enter account number', id: 'beneficiary_account_number', type: 'text', hasBeneficiary: true },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'SWIFT/BIC Code', placeholder: 'Enter SWIFT/BIC Code', id: 'beneficiary_swift_code', type: 'text', hasInstructionIcon: true, instructionTitle: 'IFSC Code', instructionText: 'A SWIFT/BIC is an 8-11 character code that identifies your country, city, bank, and branch' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['beneficiary_swift_code', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'QAR': {
        formList: [
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'IBAN', placeholder: 'Enter IBAN', id: 'beneficiary_swift_code', hasBeneficiary: true, type: 'text', hasInstructionIcon: true, instructionTitle: 'IFSC Code', instructionText: ' IBAN stands for International Bank Account Number. It’s an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world.' },
            { title: 'Recipient’s Bank', placeholder: 'Enter bank name', id: 'beneficiary_bank_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['beneficiary_swift_code', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'RWF': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['transfer_method', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'GHS': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['transfer_method', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'ZAR': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['transfer_method', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'TZS': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['transfer_method', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'UGX': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['transfer_method', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    },
    'ZMW': {
        formList: [
            { title: 'Transfer Method', id: 'transfer_method', type: 'dropdown' },
            { title: 'Recipient’s Account Name', placeholder: 'Enter account name', id: 'beneficiary_account_name', type: 'text' },
            { title: 'Recipient’s Country', id: 'beneficiary_country', type: 'dropdown' },
            { title: 'Recipient’s Address', placeholder: 'Enter Address', id: 'beneficiary_address', type: 'text' },
            { title: 'Recipient’s State', id: 'beneficiary_state', type: 'dropdown' },
            { title: 'Recipient’s City', placeholder: 'Enter city', id: 'beneficiary_city', type: 'text' },
            { title: 'Postal/Zip Code', placeholder: 'Enter postal/zip code', id: 'beneficiary_zip_code', type: 'text' },
        ],
        reviewList: ['transfer_method', 'beneficiary_country', 'beneficiary_address', 'beneficiary_state', 'beneficiary_city', 'beneficiary_zip_code']
    }
}