import React from 'react';
import { TabGroup, TabPanel } from "../../components/tabs/Tab";
import PosActive from './PosActive';
import PosRequest from './PosRequest';
import useQuery from '../../hooks/useQuery';

export default function Pos() {

    let query = useQuery();

    return (
        <div className="p-3">
          <TabGroup defaultIndex={query.get('tab')??0} className="w-3/6 md:w-2/12 whitespace-nowrap" column={["Active POS", "POS Requests"]}>
            {/* Pos Active */}
            <TabPanel>
                <PosActive />
            </TabPanel>

            {/* Pos Requests */}
            <TabPanel>
                <PosRequest />
            </TabPanel>

          </TabGroup>
        </div>
    );
}
