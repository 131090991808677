import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FiBell } from 'react-icons/fi';
import { HamburgerNew } from '../../assets/icons/Icons';
import useUser from '../../hooks/useUser';
import Notifications from "../notifications/Notifications";
import { useDispatch } from "react-redux";
import { getInAppNotifications } from '../../redux/notifications/notificationsActions';
import useNotifications from "../../hooks/useNotifications";
import { RiArrowDropUpLine, RiLogoutBoxRLine } from "react-icons/ri";
import { BiUser } from "react-icons/bi";
import { IoSettingsOutline, IoChevronBackCircle } from "react-icons/io5";
import { PopoverMain } from "../popovers/Popover";
import { SideNavContext } from "../../context/SideNavContext";
import NavAvatar from "./NavAvatar";
import { logoutUserLocallyAction } from "../../redux/auth/unAuthActions";

export default function   NavbarDashboard() {
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [totalNotifications, settotalNotifications] = useState([]);
  const [, setReadNote] = useState([]);
  const [unreadNote, setUnreadNote] = useState([]);
  const [currentLocation, setCurrentLocation] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const popOptionsRef = useRef();
  const {  markedAsRead,  inAppNotificationsData } = useNotifications();
  const navigate = useHistory();

  const { navCollapsed, toggleNav } = useContext(SideNavContext);
  const logoutFxn = ()=> {
    dispatch(logoutUserLocallyAction())
  }
  //Get notifications on page load and call every five seconds
  useEffect(() => {
    setCurrentLocation(location.pathname);
    if (currentLocation !== location.pathname) setShowNotificationModal(false);

    dispatch(getInAppNotifications());
    
    const intervalCall = setInterval(() => {
    }, 100000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  // Refresh notification list after mark as read request
  useEffect(() => {
    if (markedAsRead) {
      dispatch(getInAppNotifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedAsRead]);
  useEffect(() => {
    if (inAppNotificationsData) {
      // Filter for read or unread notifications
      let unread = inAppNotificationsData?.filter((note) => {
        return note.is_read === 0;
      });
      let read = inAppNotificationsData?.filter((note) => {
        return note.is_read !== 0;
      });
      let modifiedNotifications = read.concat(unread);
      setUnreadNote(unread);
      setReadNote(read);

      settotalNotifications(modifiedNotifications);
    }
  }, [inAppNotificationsData]);
  

  const navigateToCompliance = () => {
    user.isBusiness && history.push('/dashboard/settings?tab=6');
    !user.isBusiness && history.push('/dashboard/settings?tab=3');
  };
  const navigateToHome = () => {
    history.push('/dashboard/home');
  };
  const navigateToBalances = () => {
    history.push('/dashboard/balances')
  }
  const getInitials = () => {
    let initials = `${user?.userInfo?.first_name ? user?.userInfo?.first_name[0] : ""} ${user?.userInfo?.last_name? user?.userInfo?.last_name[0] : ''}`.toLocaleUpperCase();
    return initials;
  };
  const getFullName = () => {
    let name = `${user?.userInfo?.first_name} ${user?.userInfo?.last_name}`;
    return name;
  };
  return (
    <div className="relative bg-white">
      <div className="mx-auto">
        <div className="flex justify-between py-6 border-b border-gray sm:px-6">
          <div className="flex justify-between flex-1">
            <div className="flex flex-row cursor-pointer items-center gap-4 justify-between">
              {(location.pathname === '/dashboard/account-details' || location.pathname === '/dashboard/wallet-details')
                ?
                <p
                  onClick={navigateToBalances}
                >
                  <IoChevronBackCircle color={''} size={30} />
                </p>
                :
                <p
                  onClick={toggleNav}
                >
                  {navCollapsed ? <HamburgerNew color={'#0912ee'} size={30} /> : <IoChevronBackCircle color={''} size={30} />}
                </p>
              }
              {(location.pathname === '/dashboard/account-details' || location.pathname === '/dashboard/wallet-details')
                ?
                <p onClick={navigateToBalances} style={{ color: '#303030' }}>Back</p>
                :
                <p onClick={navigateToHome} style={{ color: '#303030' }}>Home</p>
              }
            </div>

            <div className="flex flex-row justify-end gap-2">
              <div className="static p-2 rounded-lg mr-3" style={{ backgroundColor: '' }}>
                <div className="cursor-pointer">
                  <FiBell title={"notifications"} size={25} color={'#1634A1'} onClick={()=> setShowNotificationModal(!showNotificationModal)} />
                </div>
                {unreadNote?.length > 0 &&
                  <div className="relative">
                    <span className="absolute glade-bg-red glade-small-text-one text-white rounded -top-10 p-1 -right-2">
                      {unreadNote?.length}
                    </span>
                  </div>
                }
                {showNotificationModal ?
                  <div>
                    <div
                      onClick={() => setShowNotificationModal(!showNotificationModal)}
                      className="absolute"
                      style={{ width: '100%', position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', zIndex: '19', backgroundColor: 'rgba(0,0,0,0)' }}>
                    </div>
                    <Notifications totalNotifications={inAppNotificationsData} setShowNotificationModal={setShowNotificationModal} />
                  </div>
                  : null
                }
              </div>

              <NavAvatar
                onClick={() => popOptionsRef.current.click()}
                getInitials={getInitials}
              />
              <div className="p-2 w-max hidden md:flex items-center rounded-lg" >
                <div onClick={() => popOptionsRef.current.click()}>
                  <p className="font-bold flex cursor-pointer w-full glade-home-dark">{getFullName()}</p>
                </div>
                <PopoverMain
                  button={
                    <span ref={popOptionsRef}>
                      <RiArrowDropUpLine size={'28px'} className="cursor-pointer" color={'#1634A1'} />
                    </span>
                  }
                  popoverClassName="w-12 cursor-pointer z-50"
                >
                  <div className="flex absolute p-2 -right-8 shadow-lg bg-white top-9 pr-10 flex-col border">
                    <Link className="flex justify-around items-center gap-4 px-4 py-2 glade-grey-dark" onClick={() => popOptionsRef.current.click()} to={'/dashboard/settings/profile'}><BiUser className="glade-blue " /> <p className="text-left pl-0">Profile</p></Link>
                    <Link className="flex justify-around items-center ml-1 px-4 py-2 gap-4 glade-grey-dark" onClick={() => popOptionsRef.current.click()} to={'/dashboard/settings'}><IoSettingsOutline className="glade-blue" /> <p className="text-left pl-1">Settings</p></Link>
                    <button className="flex justify-around items-center gap-4 px-4 py-2 glade-grey-dark" onClick={() => logoutFxn()}><RiLogoutBoxRLine className="glade-blue" /> <p className="text-left">Logout </p></button>
                  </div>
                </PopoverMain>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
