import React from "react";
import { BusinessDocumentNonNigeria } from "./BusinessDocumentNonNigeria";
import { BusinessDocumentNigeria } from "./BusinessDocumentNigeria";
export const BusinessDocument = ({ setFormLevel, input, setInput }) => {
  
  return (
    <>
      {input.country_code.toLowerCase() !== 'nga' ? 
        <BusinessDocumentNonNigeria setFormLevel={setFormLevel} input={input} setInput={setInput} /> : <BusinessDocumentNigeria setFormLevel={setFormLevel} input={input} setInput={setInput} />
      }
    </>
  );
};
