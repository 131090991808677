import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_CUSTOMERS,
  LOADING_CUSTOMER_DETAILS,
  RELOAD_CUSTOMERS,
  CREATE_CUSTOMER,
  BLACKLIST_CUSTOMER,
  WHITELIST_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_BY_EMAIL,
  GET_BLACKLISTED_CUSTOMERS,
  GET_WHITELISTED_CUSTOMERS
} from "./types";

/**
 * Get all customers
 * @returns {void}
 */
export const getAllCustomers = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS, payLoad:true});
    Axios.get(`/businesses/customers`)
    .then((res) => {
      dispatch({ type: LOADING_CUSTOMERS, payLoad:false});
      dispatch({ type: GET_CUSTOMERS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_CUSTOMERS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get all black listed customers
 * @returns {void}
 */
export const getAllBlackListedCustomers = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS, payLoad:true});
    Axios.get(`/businesses/customers`,{params:{status:'blacklisted'}})
    .then((res) => {
      dispatch({ type: LOADING_CUSTOMERS, payLoad:false});
      dispatch({ type: GET_BLACKLISTED_CUSTOMERS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_CUSTOMERS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get all white listed customers
 * @returns {void}
 */
export const getAllWhiteListedCustomers = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS, payLoad:true});
    Axios.get(`/businesses/customers`,{params:{status:'whitelisted'}})
    .then((res) => {
      dispatch({ type: LOADING_CUSTOMERS, payLoad:false});
      dispatch({ type: GET_WHITELISTED_CUSTOMERS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_CUSTOMERS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get customer by id
 * @param {object} {id:""}
 * @returns {void}
 */
export const getCustomerById = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_CUSTOMER_DETAILS, payLoad:true});
    Axios.get(`/businesses/customers/customer/${payLoad.id}`)
    .then((res) => {
      dispatch({ type: LOADING_CUSTOMER_DETAILS, payLoad:false});
      dispatch({ type: GET_CUSTOMER_BY_ID, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_CUSTOMER_DETAILS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get customer by email
 * @param {object} {email:""}
 * @returns {void}
 */
export const getCustomerByEmail = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_CUSTOMER_DETAILS, payLoad:true});
    Axios.get(`/businesses/customers/customer`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_CUSTOMER_DETAILS, payLoad:false});
      dispatch({ type: GET_CUSTOMER_BY_EMAIL, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_CUSTOMER_DETAILS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Blacklist customer
 * 
 * @param {object} {comment:"", customer_email:""}
 * @returns {void}
 */
export const blacklistCustomer = (payLoad) => {
  return (dispatch) => {
    Axios.post(`/businesses/customers/blacklist`,payLoad)
    .then((res) => {
      dispatch({ type: RELOAD_CUSTOMERS});
      dispatch({ type: BLACKLIST_CUSTOMER, payLoad:res});
      successHandler(res,true);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Whitelist customer
 * 
 * @param {object} {customer_email:""}
 * @returns {void}
 */
export const whitelistCustomer = (payLoad) => {
  return (dispatch) => {
    Axios.post(`/businesses/customers/remove-blacklist`,payLoad)
    .then((res) => {
      dispatch({ type: RELOAD_CUSTOMERS});
      dispatch({ type: WHITELIST_CUSTOMER, payLoad:res});
      successHandler(res,true);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Create customer
 * 
 * @param {object} {
  "name": "John Doe",
  "email": "ile@glade2.ng",
  "phone": "07064624515"
 }
 * @returns {void}
 */
 export const createCustomer = (payLoad) => {
  return (dispatch) => {
    Axios.post(`/businesses/customers`,payLoad)
    .then((res) => {
      dispatch({ type: RELOAD_CUSTOMERS});
      dispatch({ type: CREATE_CUSTOMER, payLoad:res});
      successHandler(res,true);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}