import React from 'react'
import { Bank } from '../../assets/icons/Icons'
import Button from '../../components/buttons/Button'
import styled from 'styled-components'

const Loans = () => {

  const proceedToLoan = () => {
    window.open("https://airtable.com/shrW4s6vwvLhLcYkt");
  }

  return (
    <Div>
      <div className='flex flex-col justify-center mt-34'>
        {/* Landing Page Header */}
        <div className='flex items-center flex-col mb-8'>
          <IconContainer>
            <Bank size={34} title={"bank"} color="#153BC6" />
          </IconContainer>
          <h2 className='text-2xl mt-4'>Cashflow Financing</h2>
          <p className="glade-grey-dark">Get instant capital to grow your business at 6% interest rate</p>
        </div>

        {/* List Contents */}
        <div className='mt-4 mb-8'>
          <h3 className='mb-2 font-bold glade-grey-dark'>What you will need</h3>
          <ol className='flex gap-2 flex-col'>
            <li>1. Passport Photograph</li>
            <li>2. A Government-Issued ID</li>
            <li>3. Proof of Residence</li>
            <li>4. Bank Statements</li>
          </ol>
        </div>
        <Button.Blue title={"Proceed to application"} onClick={proceedToLoan} />
      </div>
    </Div>
  )
}

export default Loans

export const Div = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconContainer = styled.section`
    height: 2rem;
    width: 2rem;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(22, 52, 162, 0.05);
    border-radius: 100px;
`