import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [size, setSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (size.innerWidth < 763) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    function onWindowResize() {
      setSize(getWindowSize());
    }

    document.addEventListener('resize', onWindowResize);

    return () => document.removeEventListener('resize', onWindowResize);
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  return {
    size,
    isMobile
  };
};

export default useScreenSize;