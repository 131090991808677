import { useSelector } from 'react-redux';

const usePayroll = () => {
    const payroll = useSelector(state => state.payroll);

    let {
        isLoadingPayroll,
        isLoadingPayday,
        isLoadingDeletePayday,
        shouldReloadPayroll,
        shouldReloadPayday,
        payrollStaffIndex,
        createPayrollStaff,
        createPayrollStaffBulk,
        updatePayrollStaff,
        deletePayrollStaff,
        paydayIndex,
        createPayday,
        paydayById,
        updatePayday,
        deletePayday,
        bulkCreateStaffList,
        payrollStaffDetails
    } = payroll;

    return {
        isLoadingPayroll,
        isLoadingPayday,
        isLoadingDeletePayday,
        shouldReloadPayroll,
        shouldReloadPayday,
        payrollStaffIndex,
        createPayrollStaff,
        createPayrollStaffBulk,
        updatePayrollStaff,
        deletePayrollStaff,
        paydayIndex,
        paydayById,
        createPayday,
        updatePayday,
        deletePayday,
        bulkCreateStaffList,
        payrollStaffDetails
    }
};

export default usePayroll;