import React from 'react';
import styled from 'styled-components';
import { RiDeleteBin5Line } from "react-icons/ri";
import { IoChevronForwardSharp } from "react-icons/io5";

const Beneficiary = ({ beneficiary, onClick }) => {
  return (
    <SBeneficiary className='flex items-center gap-8 py-4 justify-between'>
      <div className='flex items-center gap-8'>
        <span className='remove-beneficary'><RiDeleteBin5Line size={30} /></span>
      </div>
      {/* add onclick function here to populate form with beneficiary details */}
      <div onClick={() => onClick(beneficiary)} className='w-full flex items-center justify-between'>
        <div className='flex flex-col'>
          <h3 className='font-bold text-2xl'>{beneficiary?.account_name}</h3>
          {beneficiary?.account_number &&<span className='account-info'>{beneficiary?.account_number} - {beneficiary?.bank_name}</span>}
          <span className='account-info'>{beneficiary?.user_tag}</span>
        </div>
        <span className='select-icon'><IoChevronForwardSharp size={35} /></span>
      </div>
    </SBeneficiary>
  );
};

export default Beneficiary;

const SBeneficiary = styled.div`
  display: flex;
  border-bottom: solid #C2C9D1 2px; 
  margin-right: 1rem;

  .remove-beneficary {
    transition: 150ms ease-in transform;
    
    &:hover {
      transform: scale(1.2);
      color: #ea0202;
    }
  }
  
  h3, .select-icon, .account-info {
    transition: 200ms ease-in color;
  }
  
  .account-info {
    color: #717485;
  }

  &:hover {
    cursor: pointer;
    h3, 
    .select-icon,
    .account-info {
      color: #1634A1;
    }
  }
`;