import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_AIRTIME,
  RELOAD_AIRTIME,
  GET_ALL_AIRTIME,
  GET_ALL_BILL_TYPES,
  GET_BILLERS,
  GET_BILLER_ITEMS,
  BUY_AIRTIME,
  GET_AIRTIME_BENEFACTRICES
} from "./types";

/**
 * Get airtime
 * @returns {void}
 */
export const getAirtime = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AIRTIME, payLoad: true });
    Axios.get(`/bills/history`,{params:payLoad})
    .then((res) => {
    dispatch({ type: LOADING_AIRTIME, payLoad: false });
      dispatch({ type: GET_ALL_AIRTIME, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_AIRTIME, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Get bill types
 * @returns {void}
 */
 export const getAllBillTypes = () => {
  return (dispatch) => {
    Axios.get(`/bills/lookup/all`)
    .then((res) => {
      dispatch({ type: GET_ALL_BILL_TYPES, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get billers
 * 
 * @returns {void}
 */
export const getBillers = () => {
  return (dispatch) => {
    Axios.get(`/bills/lookup/billers`)
    .then((res) => {
      dispatch({ type: GET_BILLERS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get biller items
 * 
 * @param {object} payLoad {biller_code:''}
 * @returns {void}
 */
export const getBillerItems = (payLoad) => {
  return (dispatch) => {
    Axios.get(`bills/lookup/billers/${payLoad.biller_code}/items`)
    .then((res) => {
      dispatch({ type: GET_BILLER_ITEMS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Buy Airtime
 * 
 * @param {object} payLoad {
    "paycode": "cHwBhPniYesycir",
    "reference": "0908007996",
    "amount": "200",
    "save_beneficiary": 1
 }
 * @returns {void}
 */
export const buyAirtime = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AIRTIME, payLoad: true });
    Axios.post(`bills/airtime`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_AIRTIME, payLoad: false });
      dispatch({ type: RELOAD_AIRTIME});
      dispatch({ type: BUY_AIRTIME, payLoad: res });
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_AIRTIME, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Get Beneficiaries
 * 
 * @param {object} payLoad {type:''}
 * @returns {void}
 */
export const getBeneficiaries = (payLoad) => {
  return (dispatch) => {
    Axios.get(`bills/${payLoad.type}/beneficiaries`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_AIRTIME_BENEFACTRICES, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};