import React from 'react';
import { EmptyFolder } from '../../assets/icons/Icons';
import { TextSkeleton } from "../skeletons";

export default function EmptyDesk({title, subtitle, row=[], loading=false}) {

    const renderDeskRow = () => {
        return [1,2,3,4,5,6,7,8,9].map((item,key)=>{
            return (
                <div key={key} className="flex flex-row justify-between p-1 border-b">
                    <div className="flex flex-row space-x-3">
                        <div className="flex items-center">
                            <TextSkeleton width={'10px'} />
                        </div>
                        <div className="flex flex-col space-y-1 py-1">
                            {row.map((row,key)=>{
                                return (
                                    <div key={key}>
                                        <TextSkeleton width={'240px'} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <TextSkeleton width={'10px'} />
                    </div>
                </div>
            )
        });
    }

    return (
        <React.Fragment>
            {loading ?
                <div className="bg-white px-6">
                    {renderDeskRow()}
                </div>
            :
                <div className="relative flex justify-center items-center bg-white h-96">
                    <div className="flex flex-col">
                        <div className="inline-flex justify-center my-3">
                            <EmptyFolder color="#1634A1" />
                        </div>
                        <h3 className="glade-heading-three glade-black text-center my-1">
                            {title??'No Data'}
                        </h3>
                        <h6 className="glade-normal-text-two glade-ash text-center my-1">
                            {subtitle??'We don\'t have any information for this category'}
                        </h6>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}
