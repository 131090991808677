import React from "react";
import { Popover } from "@headlessui/react";

export function PopoverButton({ children, onClick, type, disabled, className}) {
  return (
    <button onClick={onClick} type={type ?? 'button'} disabled={disabled} className={className ?? ''}>
      <Popover.Button as={"span"}>
        {children}
      </Popover.Button>
    </button>
  );
}

export function PopoverMain({ button, children, overlay=false, popoverClassName=''}) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as={"span"}>{button}</Popover.Button>
          {overlay && (<Popover.Overlay className={`${open ? 'opacity-60 fixed inset-0 z-10' : 'opacity-0'} bg-black`}/>)}
          <Popover.Panel className={"absolute z-10 " + popoverClassName}>
            {children}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
