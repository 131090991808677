import React from 'react';
import { useDispatch } from 'react-redux';
import { FormWizard, FormWizardSection } from '../../components/form/FormWizard';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import IndividualInput from '../../components/inputs/IndividualInput';
import useUser from "../../hooks/useUser";
import { resetPassCode, completePassCodeReset } from '../../redux/settings/settingsActions';

export default React.forwardRef(function ResetUserPinModal(props,ref) {

	const formWizardRef = React.useRef(null);
    const dispatch = useDispatch();
    const user = useUser();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => {plainModalRef.current.open(); dispatch(resetPassCode({user_uuid:user.userInfo.uuid}));},
        close: () => plainModalRef.current.close(),
        toggle: () => {plainModalRef.current.toggle(); dispatch(resetPassCode({user_uuid:user.userInfo.uuid}));},
    }));

    const [input, setInput] = React.useState({
        verification_code:'',
        new_pin:'',
        confirm_pin:''
    });

    const resetPin = () => {
        dispatch(completePassCodeReset({
            user_uuid:user.userInfo.uuid,
            verification_code:input.verification_code,
            new_passcode:input.new_pin
        },()=>plainModalRef.current.close()));
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Reset PIN'}
            modelContentSection={
                <div className="flex flex-col">
                    <FormWizard ref={formWizardRef}>
                        {/* OTP Section */}
                        <FormWizardSection position={0}>
                            <div className="flex flex-col space-y-9">
                                <div className="flex flex-row justify-center px-9 pt-9 space-y-9">
                                    <IndividualInput 
                                        label={'Enter the OTP sent to your mail'}
                                        numberOfInputTags={6}
                                        onChange={(value)=>setInput({...input,verification_code:value})}
                                    />
                                </div>

                                <div className="flex flex-row px-6 py-3">
                                    <Button.Blue onClick={()=>formWizardRef.current.next()} title="Continue" />
                                </div>
                            </div>
                        </FormWizardSection>

                        {/* Reset Section */}
                        <FormWizardSection position={1}>
                            <div className="flex flex-col justify-center space-y-9">
                                <div className="flex flex-row justify-center pt-6">
                                    <IndividualInput
                                        label={'Enter New Pin'}
                                        numberOfInputTags={4}
                                        onChange={(value)=>setInput({...input,new_pin:value})}
                                    />
                                </div>
                                <div className="flex flex-row justify-center pb-3">
                                    <IndividualInput
                                        label={'Confirm New Pin'}
                                        numberOfInputTags={4}
                                        onChange={(value)=>setInput({...input,confirm_pin:value})}
                                    />
                                </div>

                                <div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
                                    <div>
                                        <Button.Blue onClick={()=>plainModalRef.current.close()} className="glade-button-off-white glade-grey-dark" title="Cancel" />
                                    </div>
                                    <div>
                                        <Button.Blue onClick={()=>resetPin()} title="Reset PIN" />
                                    </div>
                                </div>
                            </div>
                        </FormWizardSection>
                    </FormWizard>
                </div>
            }
        />
    )
})
