import React from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {simpleDate} from "../../helpers/helper";
import useTeam from "../../hooks/useTeam";

export default function PosDetailTransaction() {

  const location = useLocation();
  const {permissions} = useTeam();

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Pos"} path={!permissions?.includes("pos") ? "/dashboard/pos" : "/teams/pos"} />
        </div>

        <div>
            <div className="flex flex-col bg-white rounded-sm p-3 my-6">
                <div className="py-3 border-b-2 border-solid border-gray-100">
                    <h3 className="glade-heading-four glade-black">Transaction Details</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-4 py-3">
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">Transaction Date</h5>
                        <h6 className="glade-normal-text-three glade-black py-1">{simpleDate(location?.state?.created_at, "numeric", "short", "2-digit")}</h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">RRN</h5>
                        <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.rrn}</h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
                        <h6 className="glade-normal-text-three glade-black py-1">NGN {Number(location?.state?.actual_value ?? location?.state?.value)?.toLocaleString()}</h6>
                    </div>
                </div>
            </div>
        </div>

      </div>
    </div>
  );
}
