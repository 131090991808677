import { useContext, useState } from "react";
import Button from "../../components/buttons/Button";
import { TransferFormContext } from "../../context/TransferFormContext";
import { isEmail, removeCommas } from "../../helpers/helper";
import {
  InternationalNonNairaTransferFormEnum,
  LocalNonNairaTransferFormEnum,
} from "../../helpers/TransferFormFieldsHelper";
import MultipleEmailInput from "../../components/inputs/MultipleEmailInput";
import { InfoIcon } from "../../assets/icons/Icons";

const LocalNGNReview = ({ onSubmit }) => {
  const [emailError, setEmailError] = useState(false);
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  const {
    transferForm,
    currencies,
    transferCharge,
    formType,
    setTransferForm,
  } = useContext(TransferFormContext);

  const handleKeyDown = (event) => {
    if (["Enter", "Tab", ",", " "].includes(event.key)) {
      event.preventDefault();
      let value = email.trim();
      if (value && isValid(value)) {
        setEmails([...emails, value]);
        setEmail("");
        setTransferForm((form) => ({
          ...form,
          beneficiary_email: [...emails, value],
        }));
      }
    }
  };
  const handleChange = (evt) => {
    setEmail(evt.target.value);
    setTransferForm((form) => ({
      ...form,
      beneficiary_email: [...emails, evt.target.value],
    }));
    setError(null);
  };
  const handleDelete = (item) => {
    setEmails([...emails.filter((i) => i !== item)]);
  };
  const handlePaste = (evt) => {
    evt.preventDefault();
    let clipdata = evt.clipboardData || window.clipboardData;
    let paste = clipdata.getData("text/plain");
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails && isMaxEmails <= 4) {
      let toBeAdded = emails.filter((email) => !isInList(email));
      setEmails([...emails, ...toBeAdded]);
    }
  };
  const isInList = (email) => {
    return emails.includes(email);
  };
  const isMaxEmails = () => {
    return emails.length;
  };
  const isValid = (email) => {
    let error = null;
    if (isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (isMaxEmails() >= 5) {
      error = `${email} can't be added as max number of emails is 5.`;
    }
    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  return (
    <div className="w-2/3 md:w-1/3 mx-auto">
      <div className="mb-8 space-y-9">
        <div>
          <p className="glade-grey-dark text-sm font-bold mb-4">
            You are sending
          </p>
          <div className="font-bold text-2xl glade-home-dark mb-4">
            {currencies.activeCurrency.currency}{" "}
            {Number(removeCommas(transferForm?.amount))?.toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </div>
        </div>

        <div className="mb-5">
          <p className="glade-grey-dark text-sm font-bold mb-4">To</p>
          <p className="font-bold text-2xl glade-home-dark">
            {transferForm?.beneficiary_account_name}
            {formType === "international" &&
              (transferForm?.beneficiary_account_type === "individual" ? (
                <>
                  {transferForm?.beneficiary_first_name &&
                    transferForm?.beneficiary_first_name + " "}
                  {transferForm?.beneficiary_last_name &&
                    transferForm?.beneficiary_last_name}
                </>
              ) : (
                <>{transferForm?.beneficiary_name}</>
              ))}
          </p>
          <p className="text-base glade-grey-dark pt-2">
            {transferForm?.beneficiary_account_number?.length
              ? transferForm?.beneficiary_account_number
              : transferForm?.beneficiary_phone}
            {formType !== "glade" ? (
              <span>
                {transferForm?.beneficiary_bank_name?.length
                  ? `( ${transferForm?.beneficiary_bank_name})`
                  : ""}
              </span>
            ) : (
              <span>(Gladefinance)</span>
            )}
          </p>
        </div>
        {/* Extra fields for International details */}
        {formType === "international" &&
          InternationalNonNairaTransferFormEnum[
            `${currencies?.selectedCurrency?.currency}`
          ]?.reviewList.map((item, key) => (
            <div key={key} className="flex justify-between capitalize my-3">
              <span>
                {item?.replaceAll("beneficiary", "").replaceAll("_", " ")}
              </span>
              <span className="font-bold"> {transferForm[item] ?? "-"}</span>
            </div>
          ))}
        {/* Extra fields for Local non-naira details */}
        {formType === "local" &&
          LocalNonNairaTransferFormEnum[
            `${currencies?.activeCurrency?.currency}`
          ]?.reviewList.map((item, key) => (
            <div key={key} className="flex justify-between capitalize my-3">
              <span>
                {item?.replaceAll("beneficiary", "").replaceAll("_", " ")}
              </span>
              <span className="font-bold"> {transferForm[item] ?? "-"}</span>
            </div>
          ))}
        <div className="flex justify-between my-3">
          <span>Narration</span>
          <span className="font-bold"> {transferForm.narration ?? "-"}</span>
        </div>
        {(formType !== "glade" && transferCharge) ? (
          <div className="flex justify-between my-3">
            <span>Fees</span>
            <span className="font-bold">
              {currencies?.activeCurrency?.currency}
              {transferCharge?.toLocaleString("en-US")}
            </span>
          </div>)
          : ''
         }
        <hr className="my-8" />

        <div>
          <label
            htmlFor="beneficiary-email"
            className="glade-home-dark font-medium text-sm"
          >
            Send email receipt to (Optional)
          </label>
          <MultipleEmailInput
            emails={emails}
            email={email}
            handleKeyDown={handleKeyDown}
            handleChange={handleChange}
            handlePaste={handlePaste}
            error={error}
            handleDelete={handleDelete}
          />
        </div>
        <div className="flex glade-bg-blue-100 p-3 rounded-md">
          <div className="mr-3">
            <InfoIcon />
          </div>
          <div className="glade-blue text-sm">
            By clicking ‘Complete Transaction’ you confirm that the recepient’s
            details provided are correct.
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <Button.Blue
          title={"Complete transaction"}
          onClick={onSubmit}
          disabled={emailError ? true : false}
          className={"mb-9"}
        />
      </div>
    </div>
  );
};

export default LocalNGNReview;
