import { BsThreeDotsVertical } from "react-icons/bs";
import { TextSkeleton } from "../../components/skeletons";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import { useHistory } from "react-router-dom";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import { isEmptyArray, toTitleCase } from "../../helpers/helper";
import {
  getTeamDetails,
  getTeamRoles,
  toggleActivation,
} from "../../redux/business/businessActions";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import useBusiness from "../../hooks/useBusiness";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";

const TeamDetails = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { teamDetails, isLoadingTeamDetails } = useBusiness();
  const business = useBusiness();

  useEffect(() => {
    dispatch(getTeamRoles());
    dispatch(getTeamDetails(id));
  }, [id, dispatch]);

  const [featurePermissions, setFeaturePermissions] = useState({});
  // Group permissions by feature
  const groupedPermissions = {};
  business?.teamRoles?.forEach((teamRole) => {
    if (!groupedPermissions[teamRole?.feature]) {
      groupedPermissions[teamRole?.feature] = [];
    }
    groupedPermissions[teamRole?.feature].push(teamRole);
  });

  // Set initial checked state for permissions checkboxes
  useEffect(() => {
    const initialPermissionsState = {};
    if (!isEmptyArray(business?.teamDetails?.permissions)) {
      business?.teamDetails?.permissions?.forEach((permissionId) => {
        business?.teamRoles?.forEach((teamRole) => {
          if (teamRole.id === permissionId) {
            if (!initialPermissionsState[teamRole.feature]) {
              initialPermissionsState[teamRole.feature] = [];
            }
            initialPermissionsState[teamRole.feature].push(permissionId);
          }
        });
      });
      setFeaturePermissions(initialPermissionsState);
    }
  }, [business?.teamDetails]);

  return (
    <div className="py-6 px-6 ">
      <div className="mb-6">
        <BreadCrumbs title={"Back"} path={"/dashboard/teams"} />
      </div>
      <div className="border mt-6 px-6 rounded-md shadow">
        <div className="border-b mb-6 mt-6 flex justify-between">
          <p className="glade-heading-three pb-3 glade-black-800">
            Team Member Details
          </p>
          <p>
            <PopoverMain
              button={
                <BsThreeDotsVertical
                  className="inline-block mr-6 cursor-pointer"
                  size="20px"
                />
              }
              className="w-"
            >
              <div className="absolute -right-12 shadow-lg bg-white top-2 border p-3 pr-8">
                <div className="p-3">
                  <PopoverButton
                    className={"hover:bg-sky-700 "}
                    onClick={() => history.push(`/dashboard/team/edit/${id}`)}
                  >
                    <p className="inline-flex">Edit User</p>
                  </PopoverButton>
                  <PopoverButton
                    className={"inline-size-[150px]"}
                    onClick={() =>
                      dispatch(
                        toggleActivation(
                          id,
                          teamDetails?.status === "deactivated"
                            ? "activate"
                            : "deactivate",
                            () => dispatch(getTeamDetails(id))
                        )
                      )
                    }
                  >
                    <p className="inline-flex gap-1 mt-2">
                      <span>
                        {teamDetails?.status === "deactivated"
                          ? "Activate"
                          : "Deactivate"}
                      </span>
                      <span>User</span>
                    </p>
                  </PopoverButton>
                </div>
              </div>
            </PopoverMain>
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 border-b gap-4 py-3">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Name</h5>
            {!isLoadingTeamDetails ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {`${teamDetails?.first_name} ${teamDetails?.last_name}`}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Email</h5>
            {!isLoadingTeamDetails ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {`${teamDetails?.email}`}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Phone</h5>
            {!isLoadingTeamDetails ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {`${teamDetails?.phone}`}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Position</h5>
            {!isLoadingTeamDetails ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {`${teamDetails?.position}`}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-3 mb-4">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Date Created
            </h5>
            {!isLoadingTeamDetails ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {`${teamDetails?.created_at}`}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Status
            </h5>
            {!isLoadingTeamDetails ? (
              <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                <span
                  className={`py-1 px-4 rounded-md ${
                    teamDetails?.status === "active"
                      ? "bg-green-100 text-green-500"
                      : teamDetails?.status === "deactivated"? "bg-red-100 text-red-500"
                      : "bg-yellow-100 text-yellow-500"
                  }`}
                >
                  {teamDetails?.status === "active"
                    ? "Activated"
                    : teamDetails?.status === "deactivated"? "Deactivated" : "Pending"}
                </span>
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
        </div>
      </div>
      <div className="border mt-6 px-6 py-6 rounded-md shadow">
        <div className="mt-6 flex justify-between">
          <p className="glade-heading-three pb-3 glade-black-800">
            Permissions
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 py-3">
          {!isLoadingTeamDetails ? (
            Object.keys(groupedPermissions).map((feature) => (
              <div key={feature} className="  ">
                <div className=" font-medium mb-2 ">
                  <CheckboxInput
                    label={feature ?? ""}
                    id={feature}
                    name={feature}
                    type={"checkbox"}
                    checked={
                      featurePermissions[feature]?.length ===
                      groupedPermissions[feature]?.length
                    }
                  />
                </div>
                {groupedPermissions[feature].map((teamRole) => (
                  <div key={teamRole?.id} className="mb-1">
                    <CheckboxInput
                      label={toTitleCase(teamRole?.permission_title ?? "")}
                      id={teamRole.id}
                      name={teamRole.permission_title}
                      type={"checkbox"}
                      checked={featurePermissions[feature]?.includes(
                        teamRole.id
                      )}
                      className={"appearance-none ml-4 "}
                    />
                  </div>
                ))}
              </div>
            ))
          ) : (
            <TextSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
