import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import { deletePayrollStaff } from '../../redux/payroll/payrollActions';
import useTeam from '../../hooks/useTeam';

export default React.forwardRef(function PayrollStaffDeleteModal(props,ref) {
let history = useHistory();
const {permissions} = useTeam();
    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: (value) => {setInput(value); plainModalRef.current.open()},
        close: () => {setInput({}); plainModalRef.current.close()},
        toggle: (value) => {setInput(value); plainModalRef.current.toggle()},
    }));

    const dispatch = useDispatch();
    const [input, setInput] = React.useState({});

    const onsubmit = () => {
        if (input.id) {
            dispatch(deletePayrollStaff({staff_id:input.id}));
            plainModalRef.current.close();   history.push({
                pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff" : "/teams/payroll/staff"
              })
        }
      
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitleSection={<React.Fragment></React.Fragment>}
            modelContentSection={
                <div className='p-6'>
                    <div className="flex flex-col space-y-6">
                        <h4>Are you sure you want to delete this staff?</h4>
                        
                       
                        <div className="flex justify-between gap-x-6">
                             <div className='w-1/2'>
                            <Button.Transparent title={'Cancel'} onClick={()=>plainModalRef.current.close()}  className='w-full py-3'/>
                        </div>
                              <div className='w-1/2'>
                            <Button.Danger2 title={'Delete'} onClick={()=>onsubmit()} className='w-full py-3' style={{backgroundColor: '#FF0021', color: '#fff'}} />
                        </div>
                        </div>
                      
                    </div>
                </div>
            }
        />
    )
})
