import {
  LOADING_PAYMENT_LINKS,
  GET_ALL_CURRENCIES,
  GET_ALL_PAYMENT_TYPE,
  GET_ALL_PAYMENT_LINKS,
  STORE_PAYMENT_LINK,
  UPDATE_PAYMENT_LINK,
  GET_PAYMENT_LINK_TRANSACTIONS,
  GET_PAYMENT_LINK_DETAIL,
  DELETE_PAYMENT_LINK,
  DEACTIVATE_PAYMENT_LINK,
  ACTIVATE_PAYMENT_LINK,
  GET_SPLIT_ACCOUNTS,
  LOADING_SPLIT_ACCOUNTS
} from "./types";

const initialState = {
  isLoading:false,
  currencies: [],
  paymentTypes: [],
  paymentLinkIndex: {},
  storePaymentLink: {},
  updatePaymentLink: {},
  paymentLinkTransactions:[],
  paymentLinkDetail: {},
  deletePaymentLink: {},
  deactivatePaymentLink: {},
  activatePaymentLink: {},
  splitAccounts: []
};

export default function fundTransferReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_PAYMENT_LINKS:
      return {
        ...state,
        isLoading: action.payLoad,
      };
      case LOADING_SPLIT_ACCOUNTS:
        return {
          ...state,
          isLoading: action.payLoad,
        };

    case GET_ALL_CURRENCIES:
      return {
        ...state,
        currencies: action.payLoad.data.data,
      };

    case GET_ALL_PAYMENT_TYPE:
      return {
        ...state,
        paymentTypes: action.payLoad.data.data,
      };

    case GET_ALL_PAYMENT_LINKS:
      return {
        ...state,
        paymentLinkIndex: action.payLoad.data.data,
      };

    case STORE_PAYMENT_LINK:
      return {
        ...state,
        storePaymentLink: action.payLoad.data.data,
      };

    case UPDATE_PAYMENT_LINK:
      return {
        ...state,
        updatePaymentLink: action.payLoad.data.data,
      };

    case GET_PAYMENT_LINK_TRANSACTIONS:
      return {
        ...state,
        paymentLinkTransactions: action.payLoad.data.data,
      };

    case GET_PAYMENT_LINK_DETAIL:
      return {
        ...state,
        paymentLinkDetail: action.payLoad.data.data,
      };

    case DELETE_PAYMENT_LINK:
      return {
        ...state,
        deletePaymentLink: action.payLoad.data.data,
      };

    case DEACTIVATE_PAYMENT_LINK:
      return {
        ...state,
        deactivatePaymentLink: action.payLoad.data.data,
      };

    case ACTIVATE_PAYMENT_LINK:
      return {
        ...state,
        activatePaymentLink: action.payLoad.data.data,
      };
      case GET_SPLIT_ACCOUNTS:
        return {
          ...state,
          splitAccounts: action.payLoad.data.data,
        };

    default:
      return state;
  }
}
