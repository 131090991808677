import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {
  ButtonWithDropDown,
  ButtonWithDropDownItem,
} from "../../components/buttons/ButtonWithDropDown";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { CardTableHeaderPlain } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import TextIndicator from "../../components/indicators/TextIndicator";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import {
  simpleDate,
  isArray,
  isEmpty,
  pagination,
  paginationByBackend,
  statusEnum,
  toTitleCase,
} from "../../helpers/helper";
import {
  getPaymentLinkDetails,
  deletePaymentLink,
  deactivatePaymentLink,
  activatePaymentLink,
} from "../../redux/payment-pages/paymentPagesActions";
import usePaymentPages from "../../hooks/usePaymentPages";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import { IoIosArrowForward } from "react-icons/io";
import useTeam from "../../hooks/useTeam";

export default function PaymentPageDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {permissions} = useTeam();
  const paymentPages = usePaymentPages();
  let id = location?.state?.id;

  React.useEffect(() => {
    dispatch(
      getPaymentLinkDetails(id, { paginate: 1, per_page: 10, current_page: 1 })
    );
    // eslint-disable-next-line
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    setTableData(
      paginationByBackend(paymentPages.paymentLinkDetail?.transactions)
    );
  }, [paymentPages.paymentLinkDetail?.transactions]);

  const renderPageDetailTable = (data) => {
    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <TextIndicator
              type={statusEnum[item?.txn_status]}
              title={item?.txn_status}
            />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two glade-black">
              {simpleDate(item?.txn_date, "numeric", "short", "2-digit")}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two glade-black">
              {item?.txn_ref}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two glade-black">
            {toTitleCase(item?.payment_type)}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one glade-black">
              {item?.currency} {Number(item?.value)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderPageDetailDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() => {}}
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.txn_status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item?.currency} {Number(item?.value)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.txn_date, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs
            title={"Payment Pages"}
            path={!permissions?.includes("payment requests") ? "/dashboard/payment-pages" : "/teams/payment-pages"}
          />
        </div>

        <div className="flex justify-between mt-10">
          <BasicIndicator
            className="px-5 py-1 capitalize"
            type={statusEnum[location?.state?.status]}
            title={location?.state?.status ? "Active" : "Inactive"}
          />
          <div>
            <ButtonWithDropDown title="Actions">
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() => {
                      return location?.state?.meta_data?.is_ticket === 1
                        ? history.push({
                            pathname: !permissions?.includes("payment requests") ? "/dashboard/payment-pages/edit/ticket" : "/teams/payment-pages/edit/ticket",
                            state: location.state,
                          })
                        : history.push({
                            pathname: !permissions?.includes("payment requests") ? "/dashboard/payment-pages/edit/page" : "/teams/payment-pages/edit/page",
                            state: location.state,
                          });
                    }}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md"
                  >
                    Edit Link
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Edit Link
                  </span>
                }
              />
              {location?.state?.status === 1 ? (
                <ButtonWithDropDownItem
                  activeChild={
                    <span
                      onClick={() =>
                        dispatch(
                          deactivatePaymentLink(
                            { link_id: location.state.id },
                            () => history.goBack()
                          )
                        )
                      }
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md"
                    >
                      Deactivate Link
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Deactivate Link
                    </span>
                  }
                />
              ) : null}
              {location?.state?.status === 0 ? (
                <ButtonWithDropDownItem
                  activeChild={
                    <span
                      onClick={() =>
                        dispatch(
                          activatePaymentLink(
                            { link_id: location.state.id },
                            () => history.goBack()
                          )
                        )
                      }
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md"
                    >
                      Activate Link
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Activate Link
                    </span>
                  }
                />
              ) : null}
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() =>
                      dispatch(
                        deletePaymentLink({ link_id: location.state.id }, () =>
                          history.goBack()
                        )
                      )
                    }
                    className="glade-normal-text-two glade-red p-4 glade-bg-grey rounded-md"
                  >
                    Delete Link
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-red p-4">
                    Delete Link
                  </span>
                }
              />
            </ButtonWithDropDown>
          </div>
        </div>

        <div className="flex flex-col bg-white rounded-sm p-6 my-6">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">Page Details</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-4 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Page Name
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.title}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Type</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.meta_data?.is_ticket === 1
                  ? "Ticket"
                  : "One-Time"}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Currency</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.meta_data?.currency}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.meta_data?.currency} {location?.state?.amount}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1 mt-3">
                Description
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.description}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1 mt-3">
                Page Link
              </h5>
              <CopyTextButton
                type={"link"}
                text={`${location?.state?.url}`}
                textClassName={"glade-blue"}
              />
            </div>
          </div>
        </div>

        <div className="">
          <CardTableHeaderPlain
            title={"Recent Transactions"}
            searchBar={<React.Fragment></React.Fragment>}
          />

          {isEmpty(tableData.data) || paymentPages.isLoading ? (
            <React.Fragment>
              <div className="hidden md:block">
                <EmptyTable
                  column={[
                    "",
                    "Status",
                    "Date",
                    "Transaction Ref",
                    "Description",
                    "Amount",
                    "",
                  ]}
                  loading={paymentPages.isLoading}
                />
              </div>

              <div className="block md:hidden">
                <EmptyDesk row={[1, 2]} loading={paymentPages.isLoading} />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="hidden md:block">
                <CardTableBody
                  column={[
                    "",
                    "Status",
                    "Date",
                    "Transaction Ref",
                    "Description",
                    "Amount",
                    "",
                  ]}
                >
                  {isArray(tableData.data) &&
                    renderPageDetailTable(tableData.data)}
                </CardTableBody>
              </div>

              <div className="block md:hidden">
                <div className="bg-white px-6">
                  {isArray(tableData.data) &&
                    renderPageDetailDesk(tableData.data)}
                </div>
              </div>
            </React.Fragment>
          )}

          <CardTableFooter
            empty={isEmpty(tableData.data)}
            loading={paymentPages.isLoading}
            pageNumber={tableData.pageNumber}
            totalPages={tableData.totalPages}
            prevOnClick={() => setCurrentPage(tableData.previousPage)}
            // nextOnClick={()=>setCurrentPage(tableData.nextPage)}
            nextOnClick={() =>
              dispatch(
                getPaymentLinkDetails(id, {
                  paginate: 1,
                  per_page: 10,
                  current_page: tableData.nextPage,
                })
              )
            }
            thereIsPreviousPage={tableData.thereIsPreviousPage}
            thereIsNextPage={tableData.thereIsNextPage}
          />
        </div>
      </div>
    </div>
  );
}
