import { useSelector } from 'react-redux';

const usePos = () => {

    const pos = useSelector(state => state.pos);

    let {
        isLoading,
        shouldReload,
        posRevenue,
        posSales,
        posStates,
        posLga,
        requestPersonalPos,
        requestBusinessPos,
        cancelPosRequest,
        pendingPosRequests,
        approvedPosRequests,
        posTransactions,
        posTerminals,
        posTerminalDetails,
        posTerminalRequests,
        posTerminalRequestById
    } = pos;

    return {
        isLoading,
        shouldReload,
        posRevenue,
        posSales,
        posStates,
        posLga,
        requestPersonalPos,
        requestBusinessPos,
        cancelPosRequest,
        pendingPosRequests,
        approvedPosRequests,
        posTransactions,
        posTerminals,
        posTerminalDetails,
        posTerminalRequests,
        posTerminalRequestById
    }
};

export default usePos;
