import { FiChevronLeft } from "react-icons/fi";

const BackButton = ({ onClick }) => {
  return (
    <span
      className='flex items-center cursor-pointer'
      onClick={onClick}
    >
      <span className="icon"><FiChevronLeft size={28} /></span>
      Back
    </span>
  );
};

export default BackButton;