import React, { useState, useEffect } from 'react';
import Button from '../../buttons/Button';
import TextInput from '../../inputs/TextInput'
import { addCurrencyBusinessDetailsValidation, submitWaitingListMultiCurrencyValidation } from '../../../validation/add-currency/AddCurrencyValidation';
// import countries from './countries';
import SelectInput from '../../inputs/SelectInput';
import { getCountries, submitWaitingListMultiCurrency } from '../../../redux/addCurrency/addCurrencyActions';
import { getBusinessCategories } from '../../../redux/business/businessActions';
import useBusiness from "../../../hooks/useBusiness";

import { useDispatch } from 'react-redux';
import { isArray } from '../../../helpers/helper';

const AccountRequestWaitList = ({ input, setInput, setContent }) => {
  const dispatch = useDispatch();
  const business = useBusiness();

  const [inputError, setInputError] = useState({
    waitlist_first_name: false,
    waitlist_last_name: false,
    waitlist_email: false,
    waitlist_business_name: false,
    waitlist_business_description: false,
    waitlist_business_website: false,
    waitlist_business_industry: false,
    waitlist_country_corporation: false,
    waitlist_business_account_reason: false,
    waitlist_volume_monthly: false
  })
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    dispatch(getBusinessCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    countries?.length < 1 && dispatch(getCountries((res) => setCountries(res)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.business_country]);

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value});
    setInputError({ ...inputError, [e.target.name]: false })
  }

  const disable = () => {
    if (!input.waitlist_first_name || !input.waitlist_last_name || !input.waitlist_email || !input.waitlist_business_name || !input.waitlist_business_description || !input.waitlist_business_industry || !input.waitlist_country_corporation || !input.waitlist_business_account_reason || !input.waitlist_volume_monthly) {
      return true
    } else { return false }
  }



  const handleSubmit = () => {
    setContent(4)
    let validator = submitWaitingListMultiCurrencyValidation(input, setInputError);
    if (validator) {
      let payLoad = {
        first_name: input.waitlist_first_name,
        last_name: input.waitlist_last_name,
        business_email: input.waitlist_email,
        business_name: input.waitlist_business_name,
        description: input.waitlist_business_description,
        industry: input.waitlist_business_industry,
        country: input.waitlist_country_corporation,
        reason_for_account: input.waitlist_business_account_reason,
        business_website: input.waitlist_business_website,
        volume_processed: input.waitlist_volume_monthly
      }
      dispatch(submitWaitingListMultiCurrency(payLoad, ()=>setContent(6), ()=>setContent(2)));
    } else {
      setContent(2);
    }
  }

  const businessAccountReasons = [
    "Receive Payment from my customers",
    "Receive startup funding from investors.",
    "Send and Receive payment for business services from the US, UK, EU, CA, CN, KE, GH, and NG",
    "Pay staff located in the US, UK, EU, CA, CN, KE, GH, NG, and TZ  ",
    "Hold FX in different currencies to prevent business profits from currency fluctuations"
  ]

  const volumeProcessedMonthly = [
    '0 - 5,000 USD',
    '5,000 - 20,000 USD',
    '20,000 - 100,000 USD',
    '100,000 and above'
  ]

  return (
    <div className='mt-9 md:mt-24 w-full flex justify-center'>
      <div className='md:w-3/5 lg:w-1/3 xl:w-1/4 flex flex-col'>
        <div className="mb-9 items-center flex flex-col text-center">
          <h3 className='text-xl font-bold' >Get Started with GladeFinance</h3>
          <p>Fill and submit the form below and a member of our sales team will get in-touch with you for next steps.</p>
        </div>
        <div className="my-6">
          <div className="space-y-6">
            <TextInput
              label={'First Name'}
              id={'waitlist_first_name'}
              name={'waitlist_first_name'}
              type={'text'}
              value={input.waitlist_first_name}
              onChange={(e) => handleChange(e)}
              placeholder={'Enter your legal first name'}
              autoComplete={'waitlist_first_name'}
              className={'appearance-none'}
              errorState={inputError?.waitlist_first_name}  
            />

            <TextInput
              label={'Last Name'}
              id={'waitlist_last_name'}
              name={'waitlist_last_name'}
              type={'text'}
              value={input.waitlist_last_name}
              onChange={(e) => handleChange(e)}
              placeholder={'Enter your legal last name'}
              autoComplete={'waitlist_last_name'}
              className={'appearance-none'}
              errorState={inputError?.waitlist_last_name}  
            />

            <TextInput
              label={'Business Email Address'}
              id={'waitlist_email'}
              name={'waitlist_email'}
              type={'email'}
              value={input.waitlist_email}
              onChange={(e) => handleChange(e)}
              placeholder={'Enter your business email address'}
              autoComplete={'waitlist_email'}
              className={'appearance-none'}
              errorState={inputError?.waitlist_email}  
            />

            <SelectInput
              label={'Volume Processed Monthly ($)'}
              id={"waitlist_volume_monthly"}
              name={'waitlist_volume_monthly'}
              value={input.waitlist_volume_monthly ?? ''}
              onChange={(e) => handleChange(e)}
              autoComplete={"waitlist_volume_monthly"}
              className={"appearance-none"}
              errorState={inputError?.waitlist_volume_monthly}
            >
              <option value={''} disabled={true}>Select volume processed monthly ($)</option>
              {isArray(volumeProcessedMonthly) && volumeProcessedMonthly?.map((item, key) => {
                return (
                  <option key={key} value={item}>{item}</option>
                );
              })}
            </SelectInput>

            <TextInput
              label={'Business Name'}
              id={'waitlist_business_name'}
              name={'waitlist_business_name'}
              type={'text'}
              value={input.waitlist_business_name}
              onChange={(e) => handleChange(e)}
              placeholder={'Enter your business name'}
              autoComplete={'waitlist_business_name'}
              className={'appearance-none'}
              errorState={inputError?.waitlist_business_name}  
            />

            <TextInput
              label={'Business Description'}
              id={'waitlist_business_description'}
              name={'waitlist_business_description'}
              type={'text'}
              value={input.waitlist_business_description}
              onChange={(e) => handleChange(e)}
              placeholder={'Enter your business description'}
              autoComplete={'waitlist_business_description'}
              className={'appearance-none'}
              errorState={inputError?.waitlist_business_description} 
            />

            <TextInput
              label={'Business Website (Optional)'}
              id={'waitlist_business_website'}
              name={'waitlist_business_website'}
              type={'text'}
              value={input.waitlist_business_website}
              onChange={(e) => handleChange(e)}
              placeholder={'Enter your business website'}
              autoComplete={'waitlist_business_website'}
              className={'appearance-none'}
              errorState={inputError?.waitlist_business_website}  
            />

            <SelectInput
              label={'Business Industry'}
              id={"waitlist_business_industry"}
              name={'waitlist_business_industry'}
              value={input.waitlist_business_industry ?? ''}
              onChange={(e) => handleChange(e)}
              autoComplete={"waitlist_business_industry"}
              className={"appearance-none"}
              errorState={inputError?.waitlist_business_industry}
            >
              <option value={''} disabled={true}>Select business industry</option>
              {isArray(business?.businessCategories) && business?.businessCategories.map((item, key) => {
                return (
                  <option key={key} value={item}>{item}</option>
                );
              })}
            </SelectInput>

            <SelectInput
              label={'Country of Corporation'}
              id={"waitlist_country_corporation"}
              name={'waitlist_country_corporation'}
              value={input.waitlist_country_corporation ?? ''}
              onChange={(e) => handleChange(e)}
              autoComplete={"waitlist_country_corporation"}
              className={"appearance-none"}
              errorState={inputError?.waitlist_country_corporation}
            >
              <option value={''} disabled={true}>Select country of corporation </option>
              {isArray(countries) && countries?.map((country, index) => (
                <option key={index} value={country.country_name}>{country.country_name}</option>
              ))}
            </SelectInput>

            <SelectInput
              label={'What will you need a business account for ?'}
              id={"waitlist_business_account_reason"}
              name={'waitlist_business_account_reason'}
              value={input.waitlist_business_account_reason ?? ''}
              onChange={(e) => handleChange(e)}
              autoComplete={"waitlist_business_account_reason"}
              className={"appearance-none"}
              errorState={inputError?.waitlist_business_account_reason}
            >
              <option value={''} disabled={true}>Select reason for requesting a business account. </option>
              {isArray(businessAccountReasons) && businessAccountReasons?.map((reason, index) => (
                <option key={index} value={reason}>{reason}</option>
              ))}
            </SelectInput>


            <Button.Blue
              title={"Submit"}
              type={"button"}
              className={"py-3"}
              disabled={disable()}
              onClick={handleSubmit}
            />

          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountRequestWaitList