import { useLocation } from "react-router";

/**
 * A custom hook that builds on useLocation to parse the query string for you.
 * 
 * @returns {URLSearchParams}
 */
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default useQuery;
