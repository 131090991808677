import React from 'react';

export default function PillIndicator({type, title, className, style={}, onClick}) {
    return (
        <div onClick={onClick}>
            {type === 'success'?
                <span 
                    className={className+" px-2 inline-flex justify-center rounded-full glade-normal-text-two glade-bg-green-100 glade-green capitalize"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
            {type === 'warning'?
                <span 
                    className={className+" px-2 inline-flex justify-center rounded-full glade-normal-text-two glade-bg-yellow-100 glade-yellow capitalize"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
            {type === 'neutral'?
                <span 
                    className={className+" px-2 inline-flex justify-center rounded-full glade-normal-text-two glade-bg-black-100 glade-black capitalize"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
            {type === 'danger'?
                <span 
                    className={className+" px-2 inline-flex justify-center rounded-full glade-normal-text-two glade-bg-red-100 glade-red capitalize"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
            {type === 'coming-soon'?
                <span 
                    className={className+" px-2 inline-flex justify-center rounded glade-blue glade-coming-soon-bg capitalize"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
        </div>
    )
}
