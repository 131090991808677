import PlainModal from "../../components/modals/PlainModal";
import IndividualInput from "../../components/inputs/IndividualInput";
import { useState } from "react";
import { Fragment } from "react";
import { CgClose } from "react-icons/cg";
import Button from "../../components/buttons/Button";
import { useDispatch } from "react-redux";
import { verifyUserPin } from "../../redux/auth/authActions";

const PinModal = ({ onClose=()=>{}, showPinModal }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState({});
  const onSubmit =()=> {
    dispatch(verifyUserPin({ pin: input.pin }, onSuccess, onFailure));
    setInput({ ...input, pin: '' });
  }

  function onSuccess() {
    setInput({ ...input, pin: '' });
    onClose("success");
  }

  function onFailure() {
    setInput({ ...input, pin: '' });
  }


  return (
    <PlainModal
      visibility={showPinModal}
      onClose={onClose}
      primaryButton={<Fragment></Fragment>}
      modalTitleSection={
        <p className="flex justify-center items-center glade-heading-two mt-4 relative">
          PIN Confirmation
          <button
            className="glade-black absolute right-10"
            onClick={()=>onClose()}
          >
            <CgClose />
          </button>
        </p>
      }
      modelContentSection={
        <div className="flex flex-col">
          <div className="flex flex-col space-y-9">
            <div className="flex flex-col px-9 pt-9 space-y-9 items-center mb-12">
              <IndividualInput
                label={"Enter your 4-digit PIN"}
                numberOfInputTags={4}
                onChange={(value) => setInput({ pin: value })}
              />
              <Button.Blue
              title={"Submit PIN"}
              onClick={()=>onSubmit()}
              disabled={input?.pin?.length !== 4}
               />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default PinModal;
