import React, { useState, forwardRef, useRef } from "react";
import { useDispatch } from "react-redux";
import PlainModal from "../../components/modals/PlainModal";
import Button from "../../components/buttons/Button";
import {
  getRecurringList,
  pauseOrDeleteRecurringData,
} from "../../redux/recurringTransfer/recurringTransferActions";
import useRecurring from "../../hooks/useRecurring";

export default forwardRef(function PauseorDeleteModal(props, ref) {
  const dispatch = useDispatch();
  const { isLoadingRecurringEdit } = useRecurring();
  // Ref Handler
  const plainModalRef = useRef();
  React.useImperativeHandle(ref, () => ({
    open: (value) => {
      setItem(value);
      let airtimeData = {};
      if (value?.transaction_type === "airtime") {
        airtimeData = {
          receipient_data: item?.receipient_data,
          paycode: item?.paycode,
        };
      }
      plainModalRef.current.open();
    },
    close: () => plainModalRef.current.close(),
    toggle: (value) => {
      setItem(value);
      plainModalRef.current.toggle();
    },
  }));

  const [item, setItem] = useState({});
  const onSubmit = () => {
    dispatch(
      pauseOrDeleteRecurringData(
        {
          ...item?.selectedType,
          action: item?.action?.toLocaleLowerCase(),
          reference: item.reference ?? item?.group_reference,
        },
        //on success function.
        () => {
          dispatch(getRecurringList({ current_page: 1, per_page: 10, paginate: 1 }, { ...item?.selectedType }));
          plainModalRef.current.close();
        }
      )
    );
  };

  return (
    <PlainModal
      ref={plainModalRef}
      modalTitleSection={<p></p>}
      primaryButton={<p></p>}
      modelContentSection={
        <div className="mt-4 px-12 ">
          <div className="py-6">
            <div className="mb-4">
              {item?.action}{" "}
              <span className="font-bold">
                {item?.transaction_type} to{" "}
                {item?.beneficiary_account_name ??
                  item?.group_name ??
                  item?.receipient_data}
                ?
              </span>
            </div>
            <div className="px-4">
              Your payment will {item?.action === "Continue" ? "" : "not"} be
              made on your scheduled date when you{" "}
              {item?.action?.toLocaleLowerCase()}.
            </div>
          </div>
          <div className="flex justify-center px-12 py-4 gap-6">
            <Button.Transparent
              type="button"
              onClick={() => plainModalRef.current.close()}
              title={"No, Go back"}
            />
            {item?.action !== "Delete" ? (
              <Button.Blue
                title={`Yes, ${item?.action}`}
                type={"button"}
                className={"py-3 px-4"}
                onClick={onSubmit}
                disabled={isLoadingRecurringEdit}
                loading={isLoadingRecurringEdit}
              />
            ) : (
              <Button.Danger2
                title={`Yes, ${item?.action}`}
                type={"button"}
                className={"py-3 px-4"}
                onClick={onSubmit}
                disabled={isLoadingRecurringEdit}
                loading={isLoadingRecurringEdit}
              />
            )}
          </div>
        </div>
      }
    />
  );
});
