import React from 'react';
import { useDispatch } from "react-redux";
import SelectInput from '../../components/inputs/SelectInput';
import { getReferralRewardsByYear, getReferralDetails } from '../../redux/settings/settingsActions';


export default React.forwardRef(function ReferralRewardsModal(props, ref) {

  const dispatch = useDispatch();

  const [years, setYears] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState('')

  React.useEffect(() => {
    setYears([...new Set(props?.referralYears.map(x => x).sort().reverse())])
    setSelectedYear(years[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.referralYears]);

  React.useEffect(() => {
    setSelectedYear(years[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years]);

  const onChange = (e) => {
    setSelectedYear(e.target.value);
    dispatch(getReferralDetails());
    dispatch(getReferralRewardsByYear({ year: e.target.value }))
  }

  const sum = props?.referralRewardsBreakdown.reduce((accumulator, object) => {
    return accumulator + parseInt(object.totalRewardAmount);
  }, 0);

  return (

    <div className="flex flex-col space-y-9 mx-4">
      <div className="flex flex-col mx-5 pt-9 text-center space-y-9">
        <SelectInput
          label={''}
          id={'year'}
          name={'year'}
          value={selectedYear ?? ''}
          onChange={onChange}
          autoComplete={'year'}
          className={'appearance-none rounded-md glade-blue text-center glade-bg-light-purple glade-select'}>
          {/* <option value={''} disabled={true}>Select Year</option> */}
          {
            years?.map((item, key) => {
              return <option key={key} value={item}>{item ?? ''}</option>
            })
          }
        </SelectInput>
      </div>

      <div className="flex flex-col mx-5 text-center space-y-3">
        <h4 className='glade-grey-cool'>Total Rewards - {selectedYear}</h4>
        <h3 className='glade-normal-text-one glade-black'>{props.referral?.total_rewards ? '\u20A6' + sum : `No rewards for ${selectedYear}`}</h3>
      </div>

      <div className='border-2 rounded-md mx-5'>
        {props?.referralRewardsBreakdown.map((item, key) => (
          <div className='px-9 pt-3 border-b-2' key={key}>
            <div className="flex flex-row justify-between py-5 px-2 mx-4 glade-grey-dark">
              <div className="flex flex-col">
                <h4>{item.MonthName}</h4>
              </div>
              <div className="flex flex-col">
                <h4 className="glade-normal-text-one glade-black">
                  {'\u20A6' + item.totalRewardAmount}
                </h4>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

  )
})
