import React, { useState, useEffect } from 'react';
import PlainModal from './PlainModal';
import { BsCheckCircleFill } from 'react-icons/bs';

export default React.forwardRef(function NoticeModal({
    icon,
    title,
    body,
    onOpen = () => { },
    onClose = () => { },
}, ref) {
    const [seconds, setSeconds] = useState(20);
    const [isCounting, setIsCounting] = useState(false);

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    useEffect(() => {
        if (isCounting && seconds > 0) {
            setTimeout(() => setSeconds(secs => secs - 1), 1000);
        } else {
            plainModalRef.current.close();
            setTimeout(() => setSeconds(20), 300);
        }
    }, [seconds, isCounting]);


    const handleOnOpen = () => {
        setIsCounting(true);
        return onOpen;
    };
    const handleOnClose = () => {
        setIsCounting(false);
        return onClose;
    };

    return (
        <PlainModal
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            onOpen={handleOnOpen}
            onClose={handleOnClose}
            modalTitle={''}
            modelContentSection={
                <div className="flex flex-col">
                    <div className="py-6 px-9">
                        <div className="my-3 flex justify-center">
                            {icon ?? <BsCheckCircleFill
                                color={'#5CDB5C'}
                                size={'6em'}
                            />}
                        </div>
                        <div className='my-9'>
                            <div>
                                {title ?? <b className='glade-subtitle-one glade-black my-3'>Compliance submitted successfully</b>}
                            </div>
                            <div>
                                {body ?? (
                                    <p className='glade-normal-text-two glade-grey-cool'>
                                        Your Compliance has been submitted and is under review.
                                        Your account will be active in within an hour.
                                    </p>
                                )}
                            </div>
                        </div>
                        <p className={"glade-normal-text-two glade-grey-cool mt-9"}>
                            This modal will automatically close in {seconds} seconds
                        </p>
                    </div>
                </div>
            }
        />
    );
});
