import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  persistLastLogin,
  retrievePersistedLastLogin,
} from "../../helpers/session";
import { LOADING_TEAM, LOGIN_TEAM_FAILURE, LOGIN_TEAM_SUCCESS, SAVE_TEAM_PERMISSIONS } from "./Types";

export const loginTeamMember = (
  postData,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TEAM });
    Axios.post(`/auth/employee/login`, postData)
      .then((res) => {
        dispatch({ type: LOGIN_TEAM_SUCCESS, payLoad: res });
        onSuccess();
        persistLastLogin(res);
        successHandler(res);
      })

      .catch((error) => {
        dispatch({ type: LOGIN_TEAM_FAILURE });
        errorHandler(error, true);
        onFailure({ error });
      });
  };
};
export const setTeamAuthFromCache = () => {
  return async (dispatch) => {
    const data = await retrievePersistedLastLogin();
    if (data) {
      dispatch({ type: LOGIN_TEAM_SUCCESS, payLoad: data });
    }
  };
};
export const saveAllowedPermissions=(data)=>{
  return (dispatch) => {
    dispatch({ type: SAVE_TEAM_PERMISSIONS, payload: data});
  }
}
