import React from "react";

export function CardTableHeaderPlain({ title, rightButton, kebabMenuButton }) {
  return (
    <div className="w-full inline-flex justify-between px-3 py-5 bg-white rounded-t-md border-b-2 border-solid border-gray-100">
      <div className="flex flex-wrap justify-between2">
        <span className="flex glade-heading-four glade-black">{title ?? "Title"}</span>
        <span className="flex sm:hidden">
          {kebabMenuButton ?? null}
        </span>
      </div>
      <div>{rightButton ?? null}</div>
    </div>
  );
}

export function CardTableHeaderComplex({ title, filter, searchBar, rightButton, kebabMenuButton, selectBox}) {
  return (
    <div className="p-9 bg-white rounded-t-md shadow-lg border">
      <div className="flex flex-wrap justify-between mb-6">
        <span className="flex glade-subtitle-one glade-grey-cool">{title ?? "Title"}</span>
        <span className="flex md:hidden">
          {kebabMenuButton ?? null}
        </span>
      </div>
      <div className="flex flex-row justify-between">
        <div className="inline-flex gap-9">
          <span className="hidden md:flex">{filter ?? (<React.Fragment></React.Fragment>)}</span>
          <span className="flex mt-4">{selectBox ?? (<React.Fragment></React.Fragment>)}</span>
          <span className="flex">{searchBar ?? (<React.Fragment></React.Fragment>)}</span>
        </div>
        <div className="hidden md:flex gap-9">
          {rightButton ?? (<React.Fragment></React.Fragment>)}
        </div>
      </div>
    </div>
  );
}
