import React from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import {rememberBulkCreateStaffList} from '../../redux/payroll/payrollActions';
import {getBanks, resolveAccountDetails} from "../../redux/fund-transfer/fundTransferActions";
import useFundTransfer from "../../hooks/useFundTransfer";
import usePayroll from "../../hooks/usePayroll";
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import SearchSelectInput from '../../components/inputs/SearchSelectInput';

export default React.forwardRef(function PayrollStaffCreateBulkFileUploadReviewModal(props,ref) {

	// Ref Handler
	const plainModalRef = React.useRef();
	React.useImperativeHandle(ref, () => ({
		open: (value) => { setInput({...input,...value}); plainModalRef.current.open() },
		close: () => plainModalRef.current.close(),
		toggle: (value) => { setInput({...input,...value}); plainModalRef.current.toggle() },
	}));

	const dispatch = useDispatch();
	const payroll = usePayroll();
	const fundTransfer = useFundTransfer();
	const [input, setInput] = React.useState({
		bank_code: "",
		bank_name: "",
		account_number: "",
		account_name: "",
		amount: "",
		staff_name: "",
		staff_email: "",
		staff_phone_number: "",
		staff_number: "",
		staff_position: "",
	});

	React.useEffect(() => {
		dispatch(getBanks({ mode: "internal" }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (input.account_number.length === 10 && input.bank_code) {
			dispatch(
				resolveAccountDetails(
				  {account_number:input.account_number,bank_code:input.bank_code,mode:'external_local'},
				  (resolveAccountDetails)=>setInput({...input,account_name:resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id})
				)
			);
		} else {
			setInput({...input,account_name:''});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [input.account_number,input.bank_code]);

	const onSubmit = () => {
		let newBulkTransferList = payroll.bulkCreateStaffList.filter((item) => item.id !== input.id);
		newBulkTransferList.push(input);
		dispatch(rememberBulkCreateStaffList(newBulkTransferList));
		plainModalRef.current.close();
	}

	const onChange = (e) => {
		setInput({ ...input, [e.target.name]: e.target.value });
	};

	const onSearchSelectOfBank = (e) => {
		if (e.target.data) {
		  let data = JSON.parse(e.target.data);
		  setInput({...input, bank_code:data.bank_code, bank_name:data.bank_name});
		} else {
		  setInput({...input, bank_code:'', bank_name:e.target.value});
		}
	}

	return (
		<PlainModal 
			ref={plainModalRef}
			primaryButton={<React.Fragment></React.Fragment>}
			modalTitle={'Edit Staff Details'}
			modelContentSection={
				<div className="flex flex-col space-y-6">
					<div className="flex flex-col px-9 pt-9 text-left space-y-3">

						<TextInput 
							label={'Staff Name'}
							id={'staff_name'}
							name={'staff_name'}
							type={'text'}
							value={input.staff_name}
							onChange={onChange}
							placeholder={'Enter staff name'}
							autoComplete={'staff_name'}
							className={'appearance-none'}
						/>

						<TextInput 
							label={'Staff Email'}
							id={'staff_email'}
							name={'staff_email'}
							type={'email'}
							value={input.staff_email}
							onChange={onChange}
							placeholder={'Enter staff email address'}
							autoComplete={'staff_email'}
							className={'appearance-none'}
						/>

						<TextInput 
							label={'Staff Phone Number'}
							id={'staff_phone_number'}
							name={'staff_phone_number'}
							type={'tel'}
							value={input.staff_phone_number}
							onChange={onChange}
							placeholder={'Enter staff phone number'}
							autoComplete={'staff_phone_number'}
							className={'appearance-none'}
						/>

						<NumericInput 
							label={'Staff ID Number'}
							id={'staff_number'}
							name={'staff_number'}
							type={'number'}
							value={input.staff_number}
							onChange={onChange}
							placeholder={'Enter staff ID number'}
							autoComplete={'staff_number'}
							className={'appearance-none'}
						/>

						<TextInput 
							label={'Staff Position'}
							id={'staff_position'}
							name={'staff_position'}
							type={'text'}
							value={input.staff_position}
							onChange={onChange}
							placeholder={'Enter staff position'}
							autoComplete={'staff_position'}
							className={'appearance-none'}
						/>

						<SearchSelectInput
							label={'Select Staff Bank'}
							id={'bank_code'}
							name={'bank_code'}
							value={input.bank_name ?? ''}
							onChange={onSearchSelectOfBank}
							autoComplete={'bank_code'}
							placeholder={'Select a staff bank'}
							className={'appearance-none'}>
							{fundTransfer.banks && fundTransfer.banks.map((item, key)=>{
								return (
								<option key={key} name={item.bank_name} data={JSON.stringify(item)}>{item.bank_name}</option>
								);
							})}
						</SearchSelectInput>

						<NumericInput 
							label={'Staff Account Number'}
							id={'account_number'}
							name={'account_number'}
							type={'number'}
							value={input.account_number}
							onChange={(e)=>setInput({...input,[e.target.name]:e.target.value.substring(0,10)})}
							placeholder={'Enter staff account number'}
							autoComplete={'account_number'}
							className={'appearance-none'}
						/>

						<TextInput 
							label={'Staff Account Name'}
							id={'account_name'}
							name={'account_name'}
							disabled={true}
							type={'text'}
							value={input.account_name}
							onChange={onChange}
							placeholder={''}
							autoComplete={'account_name'}
							className={'appearance-none'}
						/>

						<NumericInput 
							leftSection={<span className="self-center border border-gray-300 rounded-sm p-2">NGN</span>}
							label={'Staff Salary'}
							id={'amount'}
							name={'amount'}
							type={'number'}
							value={input.amount}
							onChange={onChange}
							placeholder={'Enter staff salary'}
							autoComplete={'amount'}
							className={'appearance-none'}
						/>
					</div>

					<div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
						<div>
							<Button.Transparent onClick={()=>plainModalRef.current.close()} title="Cancel" />
						</div>
						<div>
							<Button.Blue onClick={()=>onSubmit()} title="Save" />
						</div>
					</div>
				</div>
			}
		/>
	)
})
