import { GrFormClose } from "react-icons/gr";
import { FaCheckCircle } from "react-icons/fa";
import BasicModal from "../../components/modals/BasicModal";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";

const ReportSuccess = ({ setShowReportSuccess, showReportSuccess }) => {
    const [seconds, setSeconds] = useState(20);
    const [successful, setSuccessful] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (successful) {
          seconds > 0 && setTimeout(() => setSeconds(seconds - 1), 1000);
          if (seconds <= 0) {
            setSuccessful(false);
            setShowReportSuccess(false);
            history.goBack();
          }
        }
        clearTimeout(seconds);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [successful, seconds]);
  return (
    <BasicModal
      visibility={showReportSuccess}
      onClose={() => {
        setShowReportSuccess(false);
        history.goBack();
      }}
      primaryButton={true} // Pass true to or valid component to avoid default display
      secondaryButton={true} // Pass true to or valid component to avoid default display
      modalTitleSection={
        <div className="capitalize pb-2 self-right w-full relative">
          <span className="absolute right-0">
            <GrFormClose
            size={"20"}
            color="#9CA3AF"
            onClick={() => setShowReportSuccess(false)&history.goBack()}
            className="cursor-pointer"
          />
          </span>
        </div>
      }
      modelContentSection={<div className="mt-6 flex flex-col items-center justify-center gap-4">
        <FaCheckCircle size={"60"} color="#1DD11D" className="mt-12 mb-4" />
        <p className="glade-heading-two">Report Submitted</p>
        <p className="text-center glade-grey-cool mb-6">Your Report has been submitted and is under review. we'll get in touch with you shortly.</p>
        <p className="glade-grey-cool mb-3">This modal will automatically close in <strong>{seconds}</strong> seconds</p>
      </div>}
    />
  );
};
export default ReportSuccess;
