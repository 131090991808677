import React from "react";
import { BsSearch } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { debounce } from "../../helpers/helper";

export default function SearchInput({ 
  label, 
  id,
  name, 
  type,
  value='', 
  onSearch=()=>{}, 
  placeholder, 
  autoComplete,
  disabled=false, 
  className='', 
  leftSection, 
  rightSection
}) {

  const [searchInput, setSearchInput] = React.useState(value);

  return (
    <div className="space-y-1 col-span-6 sm:col-span-3">
      <label htmlFor={name??'name'} className="block glade-small-text-two glade-black">
        {label??''}
      </label>
      <div className="mt-1 relative rounded-sm shadow-sm">
        {leftSection??<React.Fragment></React.Fragment>}
        <input
          type={type??"text"}
          name={name??'search'}
          id={id??name??'id'}
          value={searchInput??''}
          onInput={(e)=>setSearchInput(e.target.value)}
          onChange={debounce((e)=>onSearch(e.target.value), 2000)}
          disabled={disabled}
          className={className+" focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two pr-7 flex-1 block w-80 rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"}
          placeholder={placeholder??'Search'}
          autoComplete={autoComplete??''}
        />
        {rightSection??
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {searchInput && searchInput.length > 0 ?
              <span className="text-sm cursor-pointer" onClick={()=>{ onSearch(''); setSearchInput('');}}>
                <CgClose color={"9ca3af"} />
              </span>
            : 
              <span className="text-sm">
                <BsSearch color={"9ca3af"} />
              </span>
            }
          </div>
        }
      </div>
    </div>
  );
}
