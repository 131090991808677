import {
  LOADING_FUND_TRANSFER,
  LOADING_RESOLVE_DETAILS,
  LOADING_FUND_TRANSFER_BENEFICIARIES,
  GET_ALL_FUND_TRANSFER,
  GET_FUND_TRANSFER_MODE,
  GET_FUND_TRANSFER_BENEFICIARIES,
  RESOLVE_ACCOUNT_DETAILS,
  FUND_TRANSFER_SUCCESS,
  FUND_TRANSFER_FAILURE,
  GET_BANKS,
  GET_USER_TAG_BENEFICIARIES,
  LOADING_USER_TAG_BENEFICIARIES,
  GET_COUNTRIES,
  GET_INTERNATIONAL_COUNTRIES,
  GET_INTERNATIONAL_TRANSFER_METHODS,
  GET_INTERNATIONAL_TRANSFER_BANKS,
  GET_INTERNATIONAL_TRANSFER_BANKS_BRANCHES,
  GET_INTERNATIONAL_TRANSFER_RATE,
  GET_LOCAL_TRANSFER_RATE,
  MAKE_INTERNAL_TRANSFER,
  MAKE_EXTERNAL_TRANSFER,
  MAKE_EXTERNAL_BULK_TRANSFER,
  MAKE_INTERNATIONAL_TRANSFER,
  SEARCH_FUND_TRANSFER,
  REMEMBER_BULK_FUND_TRANSFER_LIST,
  GET_INTERNATIONAL_TRANSFER_RATE_FAIL,
  UPDATE_TRANSFER_STATUS
} from "./types";

const initialState = {
  isLoading: true,
  transactionStatus: false,
  isLoadingUsertag:false,
  isLoadingResolveDetails: false,
  isLoadingBeneficiaries: false,
  isLoadingUsertagBeneficiaries:false,
  fundTransferIndex: [],
  fundTransferModes: [],
  fundTransferBeneficiaries: [],
  userTagBeneficiaries:[],
  resolveAccountDetails: {},
 repeatTransaction:{},
  banks: [],
  countries: [],
  internationalCountries: [],
  internationalTransferMethods: [],
  internationalTransferBanks: [],
  internationalTransferBanksBranches: [],
  internationalTransferRate: {},
  localTransferRate: {},
  internalTransfer: {},
  externalTransfer: {},
  externalBulkTransfer: {},
  internationalTransfer: {},
  searchFundTransferIndex: [],
  bulkTransferList: []
};

export default function fundTransferReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TRANSFER_STATUS:
      return {
        ...state,
        transactionStatus: action.payLoad,
      };

    case LOADING_FUND_TRANSFER:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOADING_RESOLVE_DETAILS:
      return {
        ...state,
        isLoadingResolveDetails: action.payLoad
      };

    case GET_ALL_FUND_TRANSFER:
      return {
        ...state,
        fundTransferIndex: action.payLoad.data.data,
      };

    case GET_FUND_TRANSFER_MODE:
      return {
        ...state,
        fundTransferModes: action.payLoad.data.data,
      };

      case FUND_TRANSFER_SUCCESS:
        return {
          ...state,
          isLoading: false,
          repeatTransaction: action?.payLoad?.data?.data,
          transactionStatus: true
        };
      case FUND_TRANSFER_FAILURE:
        return {
          ...state,
          isLoading: false,
         repeatTransaction: {},
         transactionStatus: false
        };

    case LOADING_FUND_TRANSFER_BENEFICIARIES:
      return {
        ...state,
        fundTransferBeneficiaries: [],
        isLoadingBeneficiaries: true
      };

    case GET_FUND_TRANSFER_BENEFICIARIES:
      return {
        ...state,
        isLoadingBeneficiaries: false,
        fundTransferBeneficiaries: action.payLoad.data.data,
      };

      case LOADING_USER_TAG_BENEFICIARIES:
        return {
          ...state,
          isLoadingUsertagBeneficiaries: true
        };

      case GET_USER_TAG_BENEFICIARIES:
        return {
          ...state,
          isLoadingUsertagBeneficiaries: false,
          fundTransferBeneficiaries: action.payLoad.data.data,
        };
      

    case RESOLVE_ACCOUNT_DETAILS:
      return {
        ...state,
        resolveAccountDetails: action.payLoad.data.data,
      };

    case GET_BANKS:
      return {
        ...state,
        banks: action.payLoad.data.data,
      };

    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payLoad.data.data,
      };

    case GET_INTERNATIONAL_COUNTRIES:
      return {
        ...state,
        internationalCountries: action.payLoad.data.data,
      };

    case GET_INTERNATIONAL_TRANSFER_METHODS:
      return {
        ...state,
        internationalTransferMethods: action.payLoad.data.data,
      };

    case GET_INTERNATIONAL_TRANSFER_BANKS:
      return {
        ...state,
        internationalTransferBanks: action.payLoad.data.data,
      };

    case GET_INTERNATIONAL_TRANSFER_BANKS_BRANCHES:
      return {
        ...state,
        internationalTransferBanksBranches: action.payLoad.data.data,
      };

    case GET_INTERNATIONAL_TRANSFER_RATE:
      return {
        ...state,
        internationalTransferRate: action.payLoad.data.data,
      };
    case GET_INTERNATIONAL_TRANSFER_RATE_FAIL:
      return {
        ...state,
        internationalTransferRate: { fee: '', }
      };

    case GET_LOCAL_TRANSFER_RATE:
      return {
        ...state,
        localTransferRate: action.payLoad.data.data,
      };

    case MAKE_INTERNAL_TRANSFER:
      return {
        ...state,
        internalTransfer: action.payLoad.data.data,
        transactionStatus: true
      };

    case MAKE_EXTERNAL_TRANSFER:
      return {
        ...state,
        externalTransfer: action.payLoad.data.data,
        transactionStatus: true
      };

    case MAKE_EXTERNAL_BULK_TRANSFER:
      return {
        ...state,
        externalBulkTransfer: action.payLoad.data.data,
        transactionStatus: true
      };

    case MAKE_INTERNATIONAL_TRANSFER:
      return {
        ...state,
        internationalTransfer: action.payLoad.data.data,
        transactionStatus: true
      };

    case SEARCH_FUND_TRANSFER:
      return {
        ...state,
        searchFundTransferIndex: action.payLoad.data.data,
      };

     


    case REMEMBER_BULK_FUND_TRANSFER_LIST:
      return {
        ...state,
        bulkTransferList: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
