import React from "react";

export function CardTableBodyCell({children, className='', style={}, onClick=()=>{}}) {
  return (
    <td onClick={onClick} className={"px-6 py-6 glade-black "+className} style={style}>
      {children}
    </td>
  )
}

export function CardTableBodyRow({children, className='', style={}, onClick=()=>{} }) {
  return (
    <tr onClick={onClick} className={"px-6 align-middle border-b text-xs whitespace-normal "+className} style={style}>
      {children}
    </tr>
  )
}

export function CardTableBody({column=[], children, className='', style={}}) {
  return (
    <div className={"relative flex flex-col min-w-0 break-words w-full bg-white"}>
      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">

          <thead>
            <tr>
              {column.map((value,key)=>{
                return (
                  <th key={key} scope="col" className={"glade-normal-text-one glade-black px-6 py-6 align-middle border border-solid border-l-0 border-r-0 whitespace-nowrap text-left "+className} style={style}>
                    {value}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {children}
          </tbody>

        </table>
      </div>
    </div>
  );
}