import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const buyElectricityValidation = (payLoad, silently=false) => {

    let data = {
        amount:payLoad.amount, 
        meter_number:payLoad.meter_number,
        can_transact:payLoad.currentBalance,
    };
    let rules = {
        amount:'required|min:0|max:999999999999999',
        meter_number:'required|minSize:6|maxSize:100',
        can_transact:()=>{return parseInt(payLoad.amount) >= parseInt(payLoad.currentBalance) ? false : true;}
    }
    let messages = {
        amount:{
            required:'An amount is required',
            min:'Amount is not valid',
            max:'Amount is not valid'
        },
        meter_number:{
            required:'A meter number is required',
            minSize:'Meter number is not valid',
            maxSize:'Meter number is not valid'
        },
        can_transact:{
            custom:'Insufficient balance'
        }
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}

export const buyInternetValidation = (payLoad, silently=false) => {

    let data = {
        amount:payLoad.amount, 
        phone_number:payLoad.phone_number,
        can_transact:payLoad.currentBalance
    };
    let rules = {
        amount:'required|min:0|max:999999999999999',
        phone_number:'required|phone',
        can_transact:()=>{return parseInt(payLoad.amount) >= parseInt(payLoad.currentBalance) ? false : true;}
    }
    let messages = {
        amount:{
            required:'An amount is required',
            min:'Amount is not valid',
            max:'Amount is not valid'
        },
        phone_number:{
            required:'A phone number is required',
            phone:'Phone number is not valid',
        },
        can_transact:{
            custom:'Insufficient balance'
        }
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}

export const buyCableTvValidation = (payLoad, silently=false) => {

    let data = {
        amount:payLoad.amount, 
        card_number:payLoad.card_number,
        can_transact:payLoad.currentBalance
    };
    let rules = {
        amount:'required|min:0|max:999999999999999',
        card_number:'required|minSize:6|maxSize:100',
        can_transact:()=>{return parseInt(payLoad.amount) >= parseInt(payLoad.currentBalance) ? false : true;}
    }
    let messages = {
        amount:{
            required:'An amount is required',
            min:'Amount is not valid',
            max:'Amount is not valid'
        },
        card_number:{
            required:'A card number is required',
            minSize:'Card number is not valid',
            maxSize:'Card number is not valid'
        },
        can_transact:{
            custom:'Insufficient balance'
        }
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}