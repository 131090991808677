import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import IndividualInput from '../../components/inputs/IndividualInput';
import useUser from "../../hooks/useUser";
import useSettings from "../../hooks/useSettings";
import { createPassCode } from '../../redux/settings/settingsActions';

export default React.forwardRef(function CreateUserPinModal(props,ref) {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useUser();
    const settings = useSettings();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    const [input, setInput] = React.useState({
        new_pin:'',
        confirm_pin:''
    });

    const createPin = () => {
        if (input.new_pin !== input.confirm_pin) { return; }
        dispatch(createPassCode({user_uuid:user.userInfo.uuid,passcode:input.new_pin},()=>{
            plainModalRef.current.close();
            history.push('/auth/logout')
        }));
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            disableClose={true}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitleSection={<span className='flex justify-center glade-subtitle-one glade-black py-6 border-b border-gray-300'>Create PIN</span>}
            modelContentSection={
                <div className="flex flex-col space-y-9">
                    <div className="flex flex-col px-9 pt-9 space-y-9 items-center">

                        <span className="glade-grey-cool glade-small-text-one">Please choose your preferred 4 digit transaction pin</span>

                        <IndividualInput
                            label={'Enter Pin'}
                            numberOfInputTags={4} 
                            onChange={(value)=>setInput({...input,new_pin:value})} 
                        />

                        <IndividualInput
                            label={'Confirm PIN'}
                            numberOfInputTags={4} 
                            onChange={(value)=>setInput({...input,confirm_pin:value})} 
                        />
                    </div>

                    <div className="flex flex-row justify-between px-6 py-3">
                        <Button.Blue disabled={settings.isLoading} onClick={()=>createPin()} title="Create PIN" />
                    </div>
                </div>
            }
        />
    )
})
