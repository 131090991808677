import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const addBankAccountsValidation = (payLoad, silently = false) => {

  let data = {
    currency: payLoad.currency,
    street: payLoad.street,
    zip: payLoad.zip,
    city: payLoad.city,
    monthly_volume: payLoad.monthly_volume,
    payment_flow_description: payLoad.payment_flow_description,
    additional_info: payLoad.additional_info,
    incorporation_date: payLoad.incorporation_date,
    attachments: payLoad.attachments,
    reason: payLoad.reason,
    business_registration_number: payLoad.business_registration_number
  };
  let rules = {
    currency: 'required',
    street: 'required',
    zip: 'required',
    city: 'required',
    monthly_volume: 'required',
    payment_flow_description: 'required',
    additional_info: 'required',
    incorporation_date: 'required',
    reason: 'required',
    business_registration_number: 'required',
    attachments: () => { return payLoad?.attachments?.length > 2 },
    // attachments:'required'
  };
  let messages = {
    currency: {
      required: 'Currency field is required',
    },
    street: {
      required: 'Street field is required',
    },
    city: {
      required: 'City field is required'
    },
    monthly_volume: {
      required: 'Monthly volume is required'
    },
    payment_flow_description: {
      required: 'Payment flow description is required'
    },
    additional_info: {
      required: 'Additional information is required'
    },
    incorporation_date: {
      required: 'Incorporation date is required'
    },
    business_registration_number: {
      required: 'Business registration number is required'
    },
    attachments: {
      custom: 'Attachments are required'
    },
    reason: {
      required: 'Reason is a required field'
    }
  };

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}


export const addWalletAccountsValidation = (payLoad, silently = false) => {

  let data = {
    reason: payLoad.reason,
    business_registration: payLoad.business_registration,
    // operating_licence: payLoad.operating_licence,
    currency: payLoad.currency
  };
  let rules = {
    business_registration: (payLoad) => { return payLoad ? true : false; },
    // operating_licence: (payLoad) => { return payLoad ? true : false; },
    currency: 'required',
    reason: 'required'
  };
  let messages = {
    currency: {
      required: 'Currency field is required',
    },
    reason: {
      required: 'Reason field is required',
    },
    business_registration: {
      custom: 'Business registration field is required'
    },
    // operating_licence: {
    //   custom: 'Operating licence field is required'
    // },



  };

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}

export const addCurrencyDirectorInfoValidation = (payLoad, silently = false) => {

  let data = {
    director_first_name: payLoad.director_first_name,
    director_last_name: payLoad.director_last_name,
    director_dob: payLoad.director_dob,
    director_country: payLoad.director_country,
    director_state: payLoad.director_state,
    director_city: payLoad.director_city,
    director_postal_code: payLoad.director_postal_code,
    director_phone_number: payLoad.director_phone_number,
    director_document: payLoad.director_document
  };
  let rules = {
    director_document: (payLoad) => { return payLoad ? true : false; },
    director_first_name: 'required|minSize:1',
    director_last_name: 'required|minSize:1',
    director_country: 'required',
    director_state: 'required',
    director_city: 'required',
    director_postal_code: 'required|minSize:3|maxSize:14',
    director_phone_number: 'required|minSize:5|maxSize:14',
    director_dob: 'required'
  };
  let messages = {
    director_first_name: {
      required: 'Director first name is required'
    },
    director_last_name: {
      required: 'Director last name is required'
    },
    director_country: {
      required: 'Director country is required'
    },
    director_state: {
      required: 'Director state is required'
    },
    director_city: {
      required: 'Director city is required'
    },
    director_postal_code: {
      required: 'Director postal code is required',
      minSize: 'Postal code must be at least 4 characters',
      maxSize: 'Postal code should not exceed 15 characters'
    },
    director_phone_number: {
      required: 'Director phone number is required',
      minSize: 'Business phone number must be at least 6 characters',
      maxSize: 'Business phone number should not exceed 15 characters'
    },
    director_dob: {
      required: 'Director DOB is required'
    },

  };

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}


export const addCurrencyBusinessDetailsValidation = (payLoad, silently = false) => {

  let data = {
    business_legal_name: payLoad.business_legal_name,
    business_incorporation_date: payLoad.business_incorporation_date,
    business_country: payLoad.business_country,
    business_state: payLoad.business_state,
    business_city: payLoad.business_city,
    postal_code: payLoad.postal_code,
    business_phone_number: payLoad.business_phone_number,
    address_street: payLoad.address_street,
  };
  let rules = {
    director_document: (payLoad) => { return payLoad ? true : false; },
    business_legal_name: 'required|minSize:3',
    business_incorporation_date: 'required',
    business_country: 'required',
    business_state: 'required',
    postal_code: 'required|minSize:3|maxSize:14',
    business_city: 'required',
    business_phone_number: 'required|minSize:5|maxSize:14',
    address_street: 'required|minSize:2'
  };
  let messages = {
    business_legal_name: {
      required: 'Business legal name is required',
      minSize: 'Business legal name must be at least 4 characters'
    },
    business_incorporation_date: {
      required: 'Business incorporation date is required',
    },
    business_country: {
      required: 'Business country is required'
    },
    business_state: {
      required: 'Business state is required'
    },
    business_city: {
      required: 'Business city is required'
    },
    address_street: {
      required: 'Address street is required',
      minSize: 'Address street must be at least 3 characters'
    },
    postal_code: {
      required: 'Business postal code is required',
      minSize: 'Postal code must be at least 4 characters',
      maxSize: 'Postal code should not exceed 15 characters'
    },
    business_phone_number: {
      required: 'Business phone number is required',
      minSize: 'Business phone number must be at least 6 characters',
      maxSize: 'Business phone number should not exceed 15 characters'
    },
  };

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}

export const submitWaitingListMultiCurrencyValidation = (input, setInputError, silently = false) => {
  let data = {
    waitlist_first_name: input.waitlist_first_name,
    waitlist_last_name: input.waitlist_last_name,
    waitlist_email: input.waitlist_email,
    waitlist_business_name: input.waitlist_business_name,
    waitlist_business_description: input.waitlist_business_description,
    waitlist_business_website: input.waitlist_business_website,
  }

  let rules = {
    waitlist_first_name: 'required|minSize:2',
    waitlist_last_name: 'required|minSize:2',
    waitlist_business_name: 'required|minSize:2',
    waitlist_business_description: 'required|minSize:7',
    waitlist_email: (email) => { const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; return emailRegex.test(email); },
    waitlist_business_website: (website_url) => {if (website_url.length > 0) {return isValidURL(website_url)} return true}
  };
  let messages = {
    waitlist_first_name: {
      required: 'First name is required',
      minSize: 'First name must be at least 3 characters'
    },
    waitlist_last_name: {
      required: 'Last name is required',
      minSize: 'Last name must be at least 3 characters'

    },
    waitlist_business_name: {
      required: 'Business name is required',
      minSize: 'Business name must be at least 3 characters'

    },
    waitlist_business_description: {
      required: 'Business description is required',
      minSize: 'Business description must be at least 8 characters'
    },
    waitlist_email: {
      custom: "Email is not valid"
    },
    waitlist_business_website: {
      custom: "Website URL is not valid"
    },
  };

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  Object.keys(result).forEach((val) => {  
      setInputError((prev) => ({ ...prev, [val]: true }));
  })

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}

function isValidURL(url) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(url);
}
