import { Menu, Transition } from '@headlessui/react'
import { Fragment} from 'react'
import { FaCaretDown } from "react-icons/fa";

export function DropdownItem({activeChild, inactiveChild}) {
  return (
    <Menu.Item>
      {({ active }) => 
        active? 
        activeChild??inactiveChild: 
        inactiveChild??activeChild
      }
    </Menu.Item>
  )
}

export function Dropdown({children, menuButtonClassName, menuButtonTitle, menuButtonStyle, disabled}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button 
          disabled={disabled}
          style={menuButtonStyle}
          className={"glade-normal-text-three glade-button-blue w-full py-2 px-4 inline-flex justify-center items-center text-white text-center rounded-sm "+menuButtonClassName}>
          {menuButtonTitle??'Title'}
          <FaCaretDown
            className="w-5 h-5 ml-2 -mr-1"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col px-1 py-1">
            {children}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}