import React from 'react';

export default function PipedButton({
    primaryButtonTitle,
    primaryButtonStyle, 
    primaryButtonClassName, 
    primaryButtonOnClick=()=>{},
    primaryButtonSection,
    secondaryButtonTitle, 
    secondaryButtonStyle, 
    secondaryButtonClassName, 
    secondaryButtonOnClick=()=>{},
    secondaryButtonSection,
    pipeClassName
}) {
    return (
        <div className="flex flex-row">
            {primaryButtonSection??
            <button 
                type="button" 
                style={primaryButtonStyle}
                onClick={()=>primaryButtonOnClick()}
                className={"glade-normal-text-two glade-blue mx-2 "+primaryButtonClassName}>
                {primaryButtonTitle??'Primary Title'}
            </button>}
            <h1 className={"glade-grey-dark "+pipeClassName}>|</h1>
            {secondaryButtonSection??
            <button 
                type="button" 
                style={secondaryButtonStyle}
                onClick={()=>secondaryButtonOnClick()}
                className={"glade-normal-text-two glade-blue mx-2 "+secondaryButtonClassName}>
                {secondaryButtonTitle??'Secondary Title'}
            </button>}
        </div>
    )
}