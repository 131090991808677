import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FiBell } from 'react-icons/fi';
import { HamburgerNew } from '../../../assets/icons/Icons';
import useUser from '../../../hooks/useUser';
import { useDispatch } from "react-redux";
import { RiArrowDropUpLine, RiLogoutBoxRLine } from "react-icons/ri";
import { BiUser } from "react-icons/bi";
import { IoSettingsOutline, IoChevronBackCircle } from "react-icons/io5";

import { SideNavContext } from "../../../context/SideNavContext";

import useTeam from "../../../hooks/useTeam";
import { logoutUserLocallyAction } from "../../../redux/auth/unAuthActions";
import NavAvatar from "../../../components/navbars/NavAvatar";
import { PopoverMain } from "../../../components/popovers/Popover";

export default function Navbar() {
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [, setReadNote] = useState([]);
  const [unreadNote, setUnreadNote] = useState([]);
  const [currentLocation, setCurrentLocation] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const {business} = useTeam();
  const popOptionsRef = useRef();
 
  const { navCollapsed, toggleNav } = useContext(SideNavContext);
  const logoutFxn = ()=> {
    dispatch(logoutUserLocallyAction(()=>history.push("/team/login")))
  }
  const navigateToHome = () => {
    history.push('/teams/home');
  };
  const navigateToBalances = () => {
    history.push('/dashboard/balances')
  }
  const getInitials = () => {
    let initials = `${business?.business_name ? business?.business_name[0] : "N/A"}`.toLocaleUpperCase();
    return initials;
  };
  const getFullName = () => {
    let name = `${business?.business_name}`;
    return name;
  };
  return (
    <div className="relative bg-white">
      <div className="mx-auto">
        <div className="flex justify-between py-6 border-b border-gray sm:px-6">
          <div className="flex justify-between flex-1">
            <div className="flex flex-row cursor-pointer items-center gap-4 justify-between">
              {(location.pathname === '/dashboard/account-details' || location.pathname === '/dashboard/wallet-details')
                ?
                <p
                  onClick={navigateToBalances}
                >
                  <IoChevronBackCircle color={''} size={30} />
                </p>
                :
                <p
                  onClick={toggleNav}
                >
                  {navCollapsed ? <HamburgerNew color={'#0912ee'} size={30} /> : <IoChevronBackCircle color={''} size={30} />}
                </p>
              }
              {(location.pathname === '/dashboard/account-details' || location.pathname === '/dashboard/wallet-details')
                ?
                <p onClick={navigateToBalances} style={{ color: '#303030' }}>Back</p>
                :
                <p onClick={navigateToHome} style={{ color: '#303030' }}>Home</p>
              }
            </div>

            <div className="flex flex-row justify-end gap-2">
              <NavAvatar
                onClick={() => popOptionsRef.current.click()}
                getInitials={getInitials}
              />
              <div className="p-2 w-max hidden md:flex items-center rounded-lg" >
                <div onClick={() => popOptionsRef.current.click()}>
                  <p className="font-bold flex cursor-pointer w-full glade-home-dark">{getFullName()}</p>
                </div>
                <PopoverMain
                  button={
                    <span ref={popOptionsRef}>
                      <RiArrowDropUpLine size={'28px'} className="cursor-pointer" color={'#1634A1'} />
                    </span>
                  }
                  popoverClassName="w-12 cursor-pointer z-50"
                >
                  <div className="flex absolute p-2 -right-8 shadow-lg bg-white top-9 pr-10 flex-col border">
                    <button className="flex justify-around items-center gap-4 px-4 py-2 glade-grey-dark" onClick={() => logoutFxn()}><RiLogoutBoxRLine className="glade-blue" /> <p className="text-left">Logout </p></button>
                  </div>
                </PopoverMain>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
