import React from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import { cancelPosRequest } from '../../redux/pos/posActions';
import TextAreaInput from '../../components/inputs/TextAreaInput';

export default React.forwardRef(function CancelPosRequestModal(props,ref) {

    const dispatch = useDispatch();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
        setStateAndOpen: (value) => {setInput({...input,id:value.id}); plainModalRef.current.open()},
    }));

    const [input, setInput] = React.useState({
        id:'',
        comment:'',
    });

    const cancel = () => {
        dispatch(cancelPosRequest({id:input.id,comment:input.comment}));
        plainModalRef.current.close();
    }

    const onChange = (e) => {
        setInput({...input,[e.target.name]:e.target.value});
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Cancel Pending POS Request'}
            modelContentSection={
                <div className="flex flex-col space-y-9">

                    <div className="flex flex-col px-9 pt-9 text-left space-y-9">
                        <TextAreaInput 
                            label={'Please let us know your reason for canceling'}
                            id={'comment'}
                            name={'comment'}
                            rows={3}
                            type={'text'}
                            value={input.comment ?? ''}
                            onChange={onChange}
                            placeholder={''}
                            autoComplete={'comment'}
                            className={'appearance-none'}
                        />
                    </div>

                    <div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
                        <div>
                            <Button.Blue onClick={()=>plainModalRef.current.close()} className="glade-button-off-white glade-grey-dark" title="Cancel" />
                        </div>
                        <div>
                            <Button.Blue onClick={()=>cancel()} title="Cancel Request" />
                        </div>
                    </div>
                </div>
            }
        />
    )
})
