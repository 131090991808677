import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { toTitleCase, simpleDate, isEmpty } from "../../helpers/helper";
import useInvoice from "../../hooks/useInvoice";
import useUser from "../../hooks/useUser";
import {
  createInvoice,
  createInvoiceDraft,
  getInvoiceCustomersOnWhiteList,
} from "../../redux/invoice/invoiceActions";
import GLADE_LOGO from "../../components/logos/GladeLogo";
import Button from "../../components/buttons/Button";
import { createInvoiceValidation } from "../../validation/invoice/invoiceValidation";
import useHome from "../../hooks/useHome";
import useTeam from "../../hooks/useTeam";

export default function InvoicePreview() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const invoice = useInvoice();
  const user = useUser();
  const home = useHome();
  const {permissions, team_user} = useTeam();

  React.useEffect(() => {
    if (isEmpty(invoice?.whitelistedInvoiceCustomers?.customers)) {
      dispatch(getInvoiceCustomersOnWhiteList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendInvoice = () => {
    if (createInvoiceValidation(location.state)) {
      dispatch(
        createInvoice(
          {
            ...location.state,
            is_draft: 0,
            items: location.state.items.map((data) => {
              return {
                ...data,
                cost: data.cost.replaceAll(",", ""),
                total: data.total.replaceAll(",", ""),
              };
            }),
          },
          () => history.push(!permissions?.includes("invoicing") ? "/dashboard/invoices" : "/teams/invoices")
        )
      );
    }
  };

  const markAsDraft = () => {
    if (createInvoiceValidation(location.state)) {
      dispatch(
        createInvoiceDraft({ ...location.state, is_draft: 1, items: location.state.items.map((data) => {
          return {
            ...data,
            cost: data.cost.replaceAll(",", ""),
            total: data.total.replaceAll(",", ""),
          };
        }), }, () =>
          history.push(!permissions.includes("invoicing") ? "/dashboard/invoices" : "/teams/invoices")
        )
      );
    }
  };

  return (
    <div className="p-3 pb-52">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Invoice"} path={!permissions?.includes("invoicing") ? "/dashboard/invoices" : "/teams/invoices"} />
        </div>

        <CardPlainWithButton type={"single"}>
          <CardPlainWithButtonSection
            titleSection={<React.Fragment></React.Fragment>}
            type={"single"}
            loading={invoice.isLoading}
            buttonDisabled={invoice.isLoading}
            buttonSection={
              <div className="flex flex-row space-x-4 mt-6">
                <Button.Blue
                  title={"Send Invoice"}
                  className="whitespace-nowrap"
                  onClick={() => sendInvoice()}
                  disabled={invoice.isLoading}
                />
                <Button.Base
                  title={"Edit Invoice"}
                  className="whitespace-nowrap glade-button-gray"
                  onClick={() =>
                    history.replace({
                      pathname: !permissions?.includes("invoicing") ? "/dashboard/invoice/create-invoice" : "/teams/invoice/create-invoice",
                      state: {
                        ...location.state,
                        createInvoiceInput: location.state.createInvoiceInput,
                      },
                    })
                  }
                  disabled={invoice.isLoading}
                />
                <Button.Base
                  title={"Save Draft"}
                  className="whitespace-nowrap glade-button-gray"
                  onClick={() => markAsDraft()}
                  disabled={invoice.isLoading}
                />
              </div>
            }
          >
            <div className="mt-6 px-2">
              <div className="flex flex-col space-y-6">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <GLADE_LOGO alternate={true} />
                  </div>
                  <div className="flex flex-col space-y-2 text-right">
                    <h4 className="glade-normal-text-one glade-black">
                      Invoice:{" "}
                      <span className="glade-normal-text-two">
                        {location.state?.invoice_id}
                      </span>
                    </h4>
                    <h4 className="glade-normal-text-one glade-black">
                      Due on:{" "}
                      <span className="glade-normal-text-two">
                        {simpleDate(location.state?.date_due)}
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="flex flex-row justify-between pb-8 border-b-2">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-two glade-ash">From</h4>
                    <h4 className="glade-normal-text-one glade-black">
                      {user?.selectedBusinessOrPersonalAccount?.business_name}
                    </h4>
                    <h4 className="glade-normal-text-two glade-black">
                      {user?.selectedBusinessOrPersonalAccount?.contact_phone ??
                        user?.selectedBusinessOrPersonalAccount?.business_email}
                    </h4>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-two glade-ash">To</h4>
                    <h4 className="glade-normal-text-one glade-black">
                      {location.state?.customer_id
                        ? invoice.whitelistedInvoiceCustomers?.customers?.find(
                            (c) =>
                              c.id.toString() === location.state?.customer_id
                          )?.user_name
                        : ""}
                    </h4>
                    <h4 className="glade-normal-text-two glade-black">
                      {location.state?.customer_id
                        ? invoice.whitelistedInvoiceCustomers?.customers?.find(
                            (c) =>
                              c.id.toString() === location.state?.customer_id
                          )?.user_phone
                        : ""}
                    </h4>
                  </div>
                </div>

                <div className="flex flex-row justify-between pb-8 border-b-2">
                  <div className="flex flex-col">
                    {location.state?.items?.map((item, index) => (
                      <h4
                        key={index}
                        className="glade-normal-text-two glade-black"
                      >
                        {toTitleCase(item.description)}
                      </h4>
                    ))}
                  </div>
                  <div className="flex flex-col">
                    {location.state?.items?.map((item, index) => (
                      <h4
                        key={index}
                        className="glade-normal-text-two glade-black text-right"
                      >
                        {location.state?.currency + " " + item.cost}
                        <span className="glade-normal-text-two glade-ash">
                          {item.qty ? " x " + item.qty : " x 1"}
                        </span>
                      </h4>
                    ))}
                  </div>
                </div>

                <div className="flex flex-row justify-between pb-8 border-b-2">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-one glade-black">
                      Subtotal
                    </h4>
                    <h4 className="glade-normal-text-two glade-ash">
                      Discount
                    </h4>
                    <h4 className="glade-normal-text-two glade-ash">VAT</h4>
                    <h4 className="glade-normal-text-two glade-ash">
                      Shipping
                    </h4>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="h-full glade-normal-text-one glade-black text-right">
                      {location.state?.currency +
                        " " +
                        location.state?.calculatedInvoiceSubTotal.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                    </h4>
                    <h4 className="h-full glade-normal-text-two glade-ash text-right">
                      {location.state?.currency +
                        " " +
                        location.state?.calculatedInvoiceDiscount.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                    </h4>
                    <h4 className="h-full glade-normal-text-two glade-ash text-right">
                      {location.state?.currency +
                        " " +
                        (
                          (location.state.vat / 100) *
                          (location.state?.calculatedInvoiceSubTotal -
                            location.state.calculatedInvoiceDiscount)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </h4>
                    <h4 className="h-full glade-normal-text-two glade-ash text-right">
                      {location.state?.currency +
                        " " +
                        location.state?.shipping?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </h4>
                  </div>
                </div>

                <div className="flex flex-row justify-between pb-8 border-b-2">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-one glade-black">Total</h4>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-one glade-black">
                      {location.state?.currency +
                        " " +
                        location.state?.calculatedInvoiceTotal?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                    </h4>
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-two glade-ash">Notes</h4>
                  </div>
                  <div className="flex flex-col pt-3">
                    <h6 className="glade-normal-text-two glade-black">
                      {location.state?.note}
                    </h6>
                  </div>
                </div>

                {location?.state?.currency === 'NGN' &&
                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-two glade-ash">
                      Bank Transfer
                    </h4>
                  </div>

                  <div className="flex flex-col pt-3 space-y-2">
                    <h1 className="font-semibold">Account 1</h1>
                    <h6 className="glade-normal-text-two glade-off-black">
                      Account Name:{" "}
                      <span className="glade-normal-text-two glade-blue">
                        {toTitleCase(
                          !team_user?.employee?.is_teams ?
                          home?.oneBusinessAccount?.naira_accounts
                          ?.accounts[0]
                            ?.account_name : team_user?.ngn_accounts[0]?.account_name
                        )}
                      </span>
                    </h6>
                    <h6 className="glade-normal-text-two glade-off-black">
                      Account Number:{" "}
                      <span className="glade-normal-text-two glade-blue">
                        {toTitleCase(
                          !team_user?.employee?.is_teams ?
                          home?.oneBusinessAccount?.naira_accounts
                          ?.accounts[0]
                            ?.account_number : team_user?.ngn_accounts[0]?.account_number
                        )}
                      </span>
                    </h6>
                    <h6 className="glade-normal-text-two glade-off-black">
                      Bank Name:{" "}
                      <span className="glade-normal-text-two glade-blue">
                        {toTitleCase(
                          !team_user?.employee?.is_teams ?
                          home?.oneBusinessAccount?.naira_accounts
                          ?.accounts[0]
                            ?.account_provider : team_user?.ngn_accounts[0]?.bank_name
                        )}
                      </span>
                    </h6>
                  </div>

                  <div className="flex flex-col pt-3 space-y-2">
                    <h1 className="font-semibold">Account 2</h1>
                    <h6 className="glade-normal-text-two glade-off-black">
                      Account Name:{" "}
                      <span className="glade-normal-text-two glade-blue">
                        {toTitleCase(
                          !team_user?.employee?.is_teams ?
                          home?.oneBusinessAccount?.naira_accounts
                          ?.accounts[1]
                            ?.account_name : team_user?.ngn_accounts[1]?.account_name
                        )}
                      </span>
                    </h6>
                    <h6 className="glade-normal-text-two glade-off-black">
                      Account Number:{" "}
                      <span className="glade-normal-text-two glade-blue">
                        {toTitleCase(
                          !team_user?.employee?.is_teams ?
                          home?.oneBusinessAccount?.naira_accounts
                          ?.accounts[1]
                            ?.account_number : team_user?.ngn_accounts[1]?.account_number
                        )}
                      </span>
                    </h6>
                    <h6 className="glade-normal-text-two glade-off-black">
                      Bank Name:{" "}
                      <span className="glade-normal-text-two glade-blue">
                        {toTitleCase(
                          !team_user?.employee?.is_teams ?
                          home?.oneBusinessAccount?.naira_accounts
                          ?.accounts[1]
                            ?.account_provider : team_user?.ngn_accounts[1]?.bank_name
                        )}
                      </span>
                    </h6>
                  </div>
                </div>}
              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  );
}
