const GLADEFINANCE_LOGO = require('./gladefinance_logo.svg').default;
const GLADEFINANCE_BLUE_LOGO = require('./gladefinanceblue_logo.svg').default;
const GLADE_LOGO = require('./gladefinance_logo.png').default;
const GLADE_LOGO_SVG = require('./glade_logo.svg').default;
const GLADE_LOGO_ALT = require('./glade_logo_alt.png').default;
const GLADE_LOGO_ALT_SVG = require('./glade_logo_alt.svg').default;
const GLADE_PDF_LOGO = require('./glade_pdf_logo.png').default;
const GLADE_PDF_BACKGROUND = require('./pdf_background.jpeg').default;
const GLADE_PDF_STAMP = require('./pdf_stamp.jpeg').default;
const GLADE_INFO_GRAPHICS_ONE = require('./1of4.png').default;
const GLADE_INFO_GRAPHICS_TWO = require('./2of4.png').default;
const GLADE_INFO_GRAPHICS_THREE = require('./3of4.png').default;
const GLADE_INFO_GRAPHICS_FOUR = require('./4of4.png').default;
const GLADE_VIRTUAL_CARD = require('../images/glade_virtual_card.png').default;
const GLADEFINANCE_CARD = require('../images/gladefinance-card.png').default;
const MASTER_CARD_LOGO = require('../images/master_card_logo.svg').default;
const VISA_CARD_LOGO = require('../images/visa_card_logo.svg').default;
const VERVE_CARD_LOGO = require('../images/verve_card_logo.svg').default;
const GLADE_INFO_GRAPHICS_FIVE = require('../images/onboard1of2Gladefinance.svg').default;
const GLADE_INFO_GRAPHICS_SIX = require('../images/create_card.svg').default;
const CONFIRM_ICON = require('./confirm.svg').default;
const SIGNUP_ICON = require('./bank-line.svg').default;
const LOGIN_ICON = require('./sign-in.svg').default;
const ORG_ICON = require('./org-icon.svg').default;

const CARD_DESIGN = require('../images/card-design.png').default;
const USD_CARD_DESIGN = require('../images/usd-card-design.png').default;
const NGN_CARD_DESIGN = require('../images/ngn-card-design.png').default;
const WALLET_CUR = require('../images/wallet-cur.svg').default;
const GLADE_FINANCE_BLUE_PNG = require('../images/gladefin-blue.png').default;
const DESKTOP_ONBOARDING = require('../images/desktopOnboarding.svg').default;
const TOP_RIGHT_ELLIPSE = require('../images/topRightEllipse.svg').default;
const BOTTOM_LEFT_ELLIPSE = require('../images/bottomLeftEllipse.svg').default;
const DOT_PROGRESS = require('../images/dotProgress.svg').default;
const EXPENSE_ONBOARDING = require('../images/expenseOnboarding.svg').default;
const TRANSFER_ONBOARDING = require('../images/transferOnboarding.svg').default;
const BORDERLESS_CARD_ONBOARDING = require('../images/borderlessCardOnboarding.svg').default;
const SELFIE_EMOJI = require('../images/Man_head_shaking.gif').default;
const RECORD_BUTTON = require('../images/record-button.svg').default;
const RECORDING_BUTTON = require('../images/recording-button.svg').default;
const ADD_PERSON = require('../images/add-person.svg').default;
const ADD_FILE = require('../images/add-file.svg').default;
const ID_HERO = require('../images/Id_Hero.svg').default;
const MASTER_CARD = require('../images/mastercard.svg').default;
const VERVE = require('../images/verve.svg').default;
const FRONT_ID_SAMPLE = require('../images/Front_ID_Sample.svg').default;
const BACK_ID_SAMPLE = require('../images/Back_ID_Sample.svg').default;
const VISA_LOGO = require('../images/visalogo.svg').default;
const MASTER_LOGO = require('../images/mastercardlogo.svg').default;



export {
  GLADEFINANCE_LOGO,
  GLADEFINANCE_BLUE_LOGO,
  GLADE_LOGO,
  GLADE_LOGO_SVG,
  GLADE_LOGO_ALT,
  GLADE_LOGO_ALT_SVG,
  GLADE_PDF_LOGO,
  GLADE_PDF_BACKGROUND,
  GLADE_PDF_STAMP,
  GLADE_INFO_GRAPHICS_ONE,
  GLADE_INFO_GRAPHICS_TWO,
  GLADE_INFO_GRAPHICS_THREE,
  GLADE_INFO_GRAPHICS_FOUR,
  GLADE_VIRTUAL_CARD,
  GLADEFINANCE_CARD,
  MASTER_CARD_LOGO,
  VISA_CARD_LOGO,
  VERVE_CARD_LOGO,
  GLADE_INFO_GRAPHICS_FIVE,
  GLADE_INFO_GRAPHICS_SIX,
  CARD_DESIGN,
  USD_CARD_DESIGN,
  NGN_CARD_DESIGN,
  CONFIRM_ICON,
  WALLET_CUR,
  ORG_ICON,
  SIGNUP_ICON,
  LOGIN_ICON,
  GLADE_FINANCE_BLUE_PNG,
  DESKTOP_ONBOARDING,
  TOP_RIGHT_ELLIPSE,
  BOTTOM_LEFT_ELLIPSE,
  DOT_PROGRESS,
  EXPENSE_ONBOARDING,
  TRANSFER_ONBOARDING,
  BORDERLESS_CARD_ONBOARDING,
  SELFIE_EMOJI,
  RECORD_BUTTON,
  RECORDING_BUTTON,
  ADD_PERSON,
  ADD_FILE,
  ID_HERO,
  MASTER_CARD,
  VERVE,
  FRONT_ID_SAMPLE,
  BACK_ID_SAMPLE,
  VISA_LOGO,
  MASTER_LOGO
}