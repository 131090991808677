import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Switch, Redirect } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import Dashboard from "../pages/teams/home/Dashboard";
import useTeam from "../hooks/useTeam";
import styled from "styled-components";
import TeamSidebar from "../pages/teams/home/TeamSidebar";
import InactiveMonitor from "../components/activity-monitor/InactiveMonitor";
import AccountMonitor from "../components/activity-monitor/AccountMonitor";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useContext } from "react";
import { SideNavContext } from "../context/SideNavContext";
import { useDispatch } from "react-redux";
import Navbar from "../pages/teams/home/Navbar";
import {
  saveAllowedPermissions,
  setTeamAuthFromCache,
} from "../redux/Team/TeamActions";
import Balances from "../pages/balances/Balances";
import FundTransfer from "../pages/fund-transfer/FundTransfer";
import AccountDetails from "../pages/balances/AccountDetails";
import { getTeamRoles } from "../redux/business/businessActions";
import useBusiness from "../hooks/useBusiness";
import AccountActivationModal from "../components/modals/AccountActivationModal";
import Transactions from "../pages/transactions/Transactions";
import PaymentInflow from "../pages/payment-inflow/PaymentInflow";
import Settlements from "../pages/settlements/Settlements";
import Customers from "../pages/customers/Customers";
import CustomerDetail from "../pages/customers/CustomerDetail";
import CustomerDetailTransaction from "../pages/customers/CustomerDetailTransaction";
import PaymentPages from "../pages/payment-pages/PaymentPages";
import TransactionSplit from "../pages/transaction-split/TransactionSplit";
import VirtualCards from "../pages/virtual-cards/VirtualCards";
import Airtime from "../pages/airtime/Airtime";
import PayBills from "../pages/pay-bills/PayBills";
import Invoice from "../pages/invoice/Invoice";
import Pos from "../pages/pos/Pos";
import Payday from "../pages/payroll/Payday";
import FundAccount from "../pages/fund-account/FundAccount";
import CreateInvoice from "../pages/invoice/CreateInvoice";
import EditInvoice from "../pages/invoice/EditInvoice";
import NewBill from "../pages/pay-bills/NewBill";
import NewAirtime from "../pages/airtime/NewAirtime";
import PayBillsDetail from "../pages/pay-bills/PayBillsDetail";
import FundTransferDetail from "../pages/fund-transfer/FundTransferDetail";
import ReportTransaction from "../pages/transactions/ReportTransactions";
import PaymentPageDetail from "../pages/payment-pages/PaymentPageDetail";
import PaymentPageCreatePage from "../pages/payment-pages/PaymentPageCreatePage";
import PaymentPageEditPage from "../pages/payment-pages/PaymentPageEditPage";
import PaymentPageCreateTicket from "../pages/payment-pages/PaymentPageCreateTicket";
import PaymentPageEditTicket from "../pages/payment-pages/PaymentPageEditTicket";
import PaymentInflowDetail from "../pages/payment-inflow/PaymentInflowDetail";
import InvoiceDetails from "../pages/invoice/InvoiceDetails";
import InvoicePreview from "../pages/invoice/InvoicePreview";
import PosDetailTransaction from "../pages/pos/PosDetailTransaction";
import PosDetail from "../pages/pos/PosDetail";
import RequestForPos from "../pages/pos/RequestForPos";
import PaydayDetail from "../pages/payroll/PaydayDetail";
import PayrollStaff from "../pages/payroll/PayrollStaff";
import PayrollStaffCreate from "../pages/payroll/PayrollStaffCreate";
import PayrollStaffCreateBulkManual from "../pages/payroll/PayrollStaffCreateBulkManual";
import PayrollStaffCreateBulkFileUpload from "../pages/payroll/PayrollStaffCreateBulkFileUpload";
import PayrollStaffCreateBulkFileUploadReview from "../pages/payroll/PayrollStaffCreateBulkFileUploadReview";
import PayrollStaffEdit from "../pages/payroll/PayrollStaffEdit";
import PayrollStaffDetails from "../pages/payroll/PayrollStaffDetails";
import FundTransferBulkManual from "../pages/fund-transfer/FundTransferBulkManual";
import FundTransferBulkFileUpload from "../pages/fund-transfer/FundTransferBulkFileUpload";
import FundTransferBulkFileUploadReview from "../pages/fund-transfer/FundTransferBulkFileUploadReview";
import FundTransferGlade from "../pages/fund-transfer-new/FundTransferGlade";
import FundTransferLocal from "../pages/fund-transfer-new/FundTransferLocal";
import FundTransferInternational from "../pages/fund-transfer-new/FundTransferInternational";
import TransferDetailsReview from "../pages/fund-transfer-new/TransferDetailsReview";
import TransactionSplitDetail from "../pages/transaction-split/TransactionSplitDetail";
import NewSplitAccount from "../pages/transaction-split/NewSplitAccount";
import EditSplitAccount from "../pages/transaction-split/EditSplitAccount";
import Loans from "../pages/loans/Loans";
import SettlementDetail from "../pages/settlements/SettlementDetail";
import SettlementTransactionDetail from "../pages/settlements/SettlementTransactionDetail";

export default function TeamsDashboardLayout() {
  const { permissions, token, team_user } = useTeam();
  const { teamRoles } = useBusiness();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openActivationModal, setActivationModal] = useState(false);

  const { navCollapsed } = useContext(SideNavContext);
  const requireCompliance = (to, from, next) => {
    if (to.meta.auth) {
      if (to.meta.hasPermission) {
        next();
      } else {
        next.redirect("/teams/home");
        setActivationModal(true);
      }
    } else {
      setActivationModal(false);
      next();
    }
  };
  // Get Team Roles
  useEffect(() => {
    dispatch(getTeamRoles());
  }, []);
  // Persist login
  useLayoutEffect(() => {
    dispatch(setTeamAuthFromCache());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Redirect to team login if user is not logged in
  useEffect(() => {
    if (!token) {
      history.push("/team/login");
    }
  }, []);

  // Determine the logged in team's permission
  useMemo(() => {
    let grantedPermissions = team_user?.employee?.permissions
      ? JSON?.parse(team_user?.employee?.permissions)
      : [1, 2];
    let permittedRoles = [];
    teamRoles?.filter((role) =>
      grantedPermissions.filter((permissionId) => {
        if (permissionId === role.id) permittedRoles.push(role);
      })
    );
    if (permittedRoles?.length > 0)
      dispatch(saveAllowedPermissions(permittedRoles));
  }, [teamRoles]);

  return (
    <Fragment>
      {/* Monitor user activity */}
      <InactiveMonitor
        onLogout={() => history.push("/team/login")}
        duration={300000}
      />
      <AccountMonitor />

      {/* Sidebar */}
      <AppWindow navCollapsed={navCollapsed} isMobile={true}>
        {token && <TeamSidebar />}
        <MainSC>
          {/* Navbar */}
          {token && <Navbar />}

          {/* Body */}
          <section className="w-full">
            <GuardProvider guards={[requireCompliance]}>
              <Switch>
                <GuardedRoute
                  meta={{ auth: token, hasPermission: true }}
                  exact
                  path="/teams/home"
                  component={Dashboard}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("balances"),
                  }}
                  exact
                  path="/teams/balances"
                  component={Balances}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("balances"),
                  }}
                  exact
                  path="/teams/account-details"
                  component={AccountDetails}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("balances"),
                  }}
                  exact
                  path="/teams/account-details"
                  component={AccountDetails}
                />
                <GuardedRoute
                  exact
                  path="/teams/transactions"
                  component={Transactions}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("transactions page"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("transactions page"),
                  }}
                  exact
                  path="/teams/:transaction_type/detail"
                  component={FundTransferDetail}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("transactions page"),
                  }}
                  exact
                  path="/teams/report-transfer"
                  component={ReportTransaction}
                />
                <GuardedRoute
                  exact
                  path="/teams/payment-inflow"
                  component={PaymentInflow}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment inflow"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment inflow"),
                  }}
                  exact
                  path="/teams/payment-inflow-detail"
                  component={PaymentInflowDetail}
                />
                <GuardedRoute
                  exact
                  path="/teams/settlements"
                  component={Settlements}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("settlements"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("settlements"),
                  }}
                  exact
                  path="/teams/settlements/settlement-detail/transaction-detail"
                  component={SettlementTransactionDetail}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("settlements"),
                  }}
                  exact
                  path="/teams/settlements/settlement-detail"
                  component={SettlementDetail}
                />
                <GuardedRoute
                  exact
                  path="/teams/customers"
                  component={Customers}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("customers"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("customers"),
                  }}
                  exact
                  path="/teams/customers/customer-detail/:email"
                  component={CustomerDetail}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("customers"),
                  }}
                  exact
                  path="/teams/customers/customer-detail/transaction-detail"
                  component={CustomerDetailTransaction}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer"
                  component={FundTransfer}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer/bulk-transfer/manual"
                  component={FundTransferBulkManual}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer/bulk-transfer/upload"
                  component={FundTransferBulkFileUpload}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer/bulk-transfer/review"
                  component={FundTransferBulkFileUploadReview}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer/single-transfer/glade"
                  component={FundTransferGlade}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer/single-transfer/local"
                  component={FundTransferLocal}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer/single-transfer/international"
                  component={FundTransferInternational}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("fund transfer"),
                  }}
                  exact
                  path="/teams/fund-transfer/single-transfer/international/review"
                  component={TransferDetailsReview}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("balances"),
                  }}
                  exact
                  path="/teams/fund-account"
                  component={FundAccount}
                />
                <GuardedRoute
                  exact
                  path="/teams/payment-pages"
                  component={PaymentPages}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment requests"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment requests"),
                  }}
                  exact
                  path="/teams/payment-page-detail"
                  component={PaymentPageDetail}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment requests"),
                  }}
                  exact
                  path="/teams/payment-pages/create/page"
                  component={PaymentPageCreatePage}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment requests"),
                  }}
                  exact
                  path="/teams/payment-pages/edit/page"
                  component={PaymentPageEditPage}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment requests"),
                  }}
                  exact
                  path="/teams/payment-pages/create/ticket"
                  component={PaymentPageCreateTicket}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payment requests"),
                  }}
                  exact
                  path="/teams/payment-pages/edit/ticket"
                  component={PaymentPageEditTicket}
                />
                <GuardedRoute
                  exact
                  path="/teams/transaction-split"
                  component={TransactionSplit}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("transaction split"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("transaction split"),
                  }}
                  exact
                  path="/teams/transaction-split/transaction-split-detail"
                  component={TransactionSplitDetail}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("transaction split"),
                  }}
                  exact
                  path="/teams/transaction-split/new-split-account"
                  component={NewSplitAccount}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("transaction split"),
                  }}
                  exact
                  path="/teams/transaction-split/edit-split-account"
                  component={EditSplitAccount}
                />
                <GuardedRoute
                  exact
                  path="/teams/cards"
                  component={VirtualCards}
                  meta={{
                    auth: token,
                    // hasPermission: permissions?.includes("cards"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("financing (loans)"),
                  }}
                  exact
                  path="/teams/loans"
                  component={Loans}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    // hasPermission: permissions?.includes("airtime"),
                  }}
                  exact
                  path="/teams/airtime/new-airtime"
                  component={NewAirtime}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("airtime"),
                  }}
                  exact
                  path="/teams/airtime/repeat-airtime"
                  component={NewAirtime}
                />
                <GuardedRoute
                  exact
                  path="/teams/airtime"
                  component={Airtime}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("airtime"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    // hasPermission: permissions?.includes("bill payment"),
                  }}
                  exact
                  path="/teams/pay-bills/new-bill"
                  component={NewBill}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    // hasPermission: permissions?.includes("bill payment"),
                  }}
                  exact
                  path="/dashboard/pay-bills/repeat-bill"
                  component={NewBill}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("bill payment"),
                  }}
                  exact
                  path="/teams/pay-bills/pay-bill-detail"
                  component={PayBillsDetail}
                />
                <GuardedRoute
                  exact
                  path="/teams/pay-bills"
                  component={PayBills}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("bill payment"),
                  }}
                />
                <GuardedRoute
                  exact
                  path="/teams/payroll/payday"
                  component={Payday}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payroll"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/payday-detail"
                  component={PaydayDetail}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    // hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/staff"
                  component={PayrollStaff}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/staff/create/single"
                  component={PayrollStaffCreate}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/staff/create/bulk/manual"
                  component={PayrollStaffCreateBulkManual}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    // hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/staff/create/bulk/upload"
                  component={PayrollStaffCreateBulkFileUpload}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/staff/create/bulk/review"
                  component={PayrollStaffCreateBulkFileUploadReview}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/staff/edit"
                  component={PayrollStaffEdit}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("payroll"),
                  }}
                  exact
                  path="/teams/payroll/staff/details"
                  component={PayrollStaffDetails}
                />
                <GuardedRoute
                  exact
                  path="/teams/pos"
                  component={Pos}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("pos"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("pos"),
                  }}
                  exact
                  path="/teams/pos/pos-detail"
                  component={PosDetail}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("pos"),
                  }}
                  exact
                  path="/teams/pos/pos-detail/transaction-detail"
                  component={PosDetailTransaction}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("pos"),
                  }}
                  exact
                  path="/teams/pos/request-pos"
                  component={RequestForPos}
                />
                <GuardedRoute
                  exact
                  path="/teams/invoices"
                  component={Invoice}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("invoicing"),
                  }}
                />
                <GuardedRoute
                  exact
                  path="/teams/invoice/create-invoice"
                  component={CreateInvoice}
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("invoicing"),
                  }}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("invoicing"),
                  }}
                  exact
                  path="/teams/invoice/edit-invoice"
                  component={EditInvoice}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("invoicing"),
                  }}
                  exact
                  path="/teams/invoice/invoice-detail"
                  component={InvoiceDetails}
                />
                <GuardedRoute
                  meta={{
                    auth: token,
                    hasPermission: permissions?.includes("invoicing"),
                  }}
                  exact
                  path="/teams/invoice/preview-invoice"
                  component={InvoicePreview}
                />
                <Redirect from="/teams" to="/teams/home" />
              </Switch>
            </GuardProvider>
          </section>
          {/* Account Actvation Modal */}
          {openActivationModal && (
            <AccountActivationModal
              openActivationModal={openActivationModal}
              setActivationModal={setActivationModal}
              complianceStatus={true}
              isBusiness={team_user?.isBusiness}
              isTeam={team_user?.employee?.is_teams}
            />
          )}
        </MainSC>
      </AppWindow>
    </Fragment>
  );
}

const MainSC = styled(motion.main)`
  width: 100%;
  min-height: 112vh;
  overflow-y: hidden;
  background: #fff;
  padding: 1rem;

  &:hover {
    overflow-y: auto;
  }
`;

const AppWindow = styled(motion.div)`
  overflow: hidden;
  display: grid;
  grid-template-columns: ${({ navCollapsed }) =>
      navCollapsed ? 0 : "18.75rem"} 4fr;

  @media screen and (max-width: 770px) {
    grid-template-columns: ${({ navCollapsed }) => (navCollapsed ? 0 : "100%")} 4fr;
  }
`;
