import React from 'react';

export default function CircleIndicator({ type, style = {} }) {
  return (
    <React.Fragment>
      {type === 'success' ?
        <span className="inline-block w-2 h-2 glade-bg-green rounded" style={style}></span>
        : null}
      {type === 'warning' ?
        <span className="inline-block w-2 h-2 glade-bg-yellow rounded" style={style}></span>
        : null}
      {type === 'neutral' ?
        <span className="inline-block w-2 h-2 glade-bg-ash rounded" style={style}></span>
        : null}
      {type === 'danger' ?
        <span className="inline-block w-2 h-2 glade-bg-red rounded" style={style}></span>
        : null}
      {type === 'blue' ?
        <span className="inline-block w-2 h-2 glade-bg-blue rounded" style={style}></span>
        : null}
    </React.Fragment>
  )
}
