import React, { useEffect, useState } from "react";
import {
  MedalIconBronze,
  InfoIcon,
  AngleDownSelect,
  MedalIconSilver,
  MedalIconGold,
} from "../../assets/icons/Icons";
import { useDispatch } from "react-redux";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { CardTableHeaderPlain } from "../../components/cards/CardTableHeader";
import Button from "../../components/buttons/Button";
import {
  getDashboardFeatures,
  getPaymentMethods,
  getStatusOfAgentRequest,
} from "../../redux/settings/settingsActions";
import {
  getAccountLimit,
  getSystemAccountTier,
  requestUpgradeTier,
} from "../../redux/accountLimit/accountLimitActions";
import useSettings from "../../hooks/useSettings";
import useUser from "../../hooks/useUser";
import useAccountLimit from "../../hooks/useAccountLimit";

export default function AccountLimits() {
  const dispatch = useDispatch();
  const user = useUser();
  const { isLoading, limit_data, system_data } = useAccountLimit();
  const settings = useSettings();
  useEffect(() => {
    user.isBusiness && dispatch(getDashboardFeatures());
    user.isBusiness && dispatch(getPaymentMethods());
    !user.isBusiness && dispatch(getStatusOfAgentRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.shouldReload]);

  const [showDropSel, setShowDropSel] = useState(false);
  const [accountLimitValue, setAccountLimitValue] = useState("");
  const [amount_remaining, setAmountRemaining] = useState("");
  const [currentExpense, setCurrentExpense] = useState("0");
  const [showIncreaseLimit, setShowIncreaseLimit] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const [activeTier, setActiveTier] = useState(null);
  const [systemTier, setSystemTier] = useState(null);
  const [lastData, setLastData] = useState(null);
  const [reason, setReason] = useState("");

  useEffect(() => {
    dispatch(getAccountLimit());
    dispatch(getSystemAccountTier());
  }, []);

  useEffect(() => {
    if (limit_data) {
      setAccountLimitValue(limit_data?.data?.transfer_limit);
      let usedPercentage =
        ((Number(limit_data?.data?.transfer_limit) -
          Number(limit_data?.data?.amount_remaining)) /
          Number(limit_data?.data?.transfer_limit)) *
        100;
      setAmountRemaining(limit_data?.data?.amount_remaining);
      setCurrentExpense(usedPercentage);
      setActiveTier(limit_data)
    }
    if (limit_data?.updated) {
      setShowIncreaseLimit(false);
      dispatch(getAccountLimit());
    }
  }, [ limit_data]);

useEffect(()=>{
if(system_data){
  setSystemTier(system_data)
  let lastData = system_data?.data && system_data?.data?.find(item => item.max === 1)
  setLastData(lastData)
}
}, [system_data])

  const handleAmountRequest = () => {
    dispatch(requestUpgradeTier({ selectedTier, reason }));
  };
  const DisplayMedal = ({ tierName }) => {
    let index = tierName?.split("Tier ")[1];
    if (parseInt(index, 0) < 4) {
      return <MedalIconBronze />;
    } else if (parseInt(index, 0) === 4) {
      return <MedalIconSilver />;
    } else if (parseInt(index, 0) > 4) {
      return <MedalIconGold />;
    } else {
      return <MedalIconGold />;
    }
  };

  return (
    <div className="px-3 py-0 font-inter">
      <div className="mb-9 md:hidden">
        <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
      </div>
      <CardTableHeaderPlain title={"Account Limits"} />
      <div className="relative min-w-0 w-full bg-white border-t-2 border-gray-100 font-inter">
        {!showIncreaseLimit ? (
          <div className="px-3 py-6 flex flex-col md:flex-row ">
            {/* List system tiers */}
            <div className="w-full md:w-7/12 lg:w-5/12 lg:px-10 px-5 border-r order-last md:order-first">
              <div>
                <div className=" flex mb-4 flex-col space-y-6">
                  <h4 className="text-glade-black-800 text-base font-semibold">Tiers</h4>
                </div>
                <div className="space-y-10">
                  {systemTier?.data &&
                    systemTier?.data?.map((tier) => (
                      <div className="text-glade-black-800 " key={tier.key}>
                        <div className="flex mb-2 text-base font-normal space-x-2">
                          <span>
                            <DisplayMedal tierName={tier.name} />
                          </span>
                          <span>{tier.name}</span>
                          {tier.id === activeTier?.data?.tier_id && (
                            <div className="text-xs bg-glade-gray-200 text-glade-gray-100 px-3 py-1 rounded-2xl">
                              Active
                            </div>
                          )}
                        </div>
                        <div className="flex text-sm font-normal space-x-2">
                          <div>
                            Daily Limit: NGN {formatNumber(tier.daily_amount)}
                          </div>
                          <span>{tier.no_of_transactions} Transactions</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* Progress bar and upgrade tier button */}
            <div className="w-full md:w-5/12 lg:w-7/12 lg:px-10 px-5 mb-10 md:mb-0">
              <div className="w-full">
                <div className=" flex mb-4 flex-col space-y-6">
                  <h4 className="text-glade-black-800 text-base">
                    Daily Transaction Limit
                  </h4>
                </div>
                <div className=" flex mb-4 flex-col space-y-6">
                  <h4 className="text-base text-glade-black-800">
                    NGN {accountLimitValue.toLocaleString()}
                  </h4>
                </div>
                <div className=" bg-gray-200 rounded-full h-3.5 dark:bg-gray-700">
                  <div
                    className="glade-bg-blue h-3.5 rounded-full"
                    style={{ width: `${currentExpense}%` }}
                  ></div>
                </div>
                <div className="mb-4 flex justify-end">
                  <h4 className="text-glade-black-800 mt-3 text-sm">
                    NGN {amount_remaining.toLocaleString()} remaining
                  </h4>
                </div>
                <div className="mt-5">
                  {activeTier?.data && activeTier?.data?.request_status !== "pending" ? (
                    user?.hasCompliance && activeTier.data.tier_key !== "custom_tier"? (
                      activeTier.data?.max === 0 ?
                      <div className=" w-1/2 lg:w-3/12">
                        <Button.Blue
                          title={"Upgrade Tier"}
                          onClick={() => setShowIncreaseLimit(true)}
                          className="rounded-sm"
                        />
                      </div> :<>
                      <div className="text-glade-black-800">
                        To upgrade your account above tier {lastData?.name}, please contact us
                        at <a href="mailto:support@glade.ng"> support@glade.ng</a>
                      </div>
                      </>
                    ) : (
                      <div className="text-glade-black-800">
                        To upgrade your account above tier {lastData?.name}, please contact us
                        at <a href="mailto:support@glade.ng"> support@glade.ng</a>
                      </div>
                    )
                  ) : (
                    <div className="flex glade-bg-blue-100 p-3 rounded-md">
                      <div className="mr-3">
                        <InfoIcon />
                      </div>
                      <div className="glade-blue text-sm">
                        Your request to upgrade your tier has been submitted and
                        is currently being reviewed
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          // Upgrade tier section
          <div className="px-8 py-8">
            <div className="w-full md:w-8/12 lg:w-6/12">
              <div className="space-y-8">
                <div className="w-full">
                  <div className="mb-6  space-y-6">
                    <h4 className="text-glade-black-800">
                      <button onClick={() => setShowIncreaseLimit(false)}>
                        Account Limits
                      </button>
                      &#62; Increase Limit
                    </h4>
                  </div>
                </div>
                {/* Select tier feature */}
                <div className="space-y-2">
                  <label htmlFor="" className="text-glade-black-800">
                    Select the tier you want
                  </label>
                  {/* Select field */}
                  <div className="w-full">
                    <div className="relative">
                      <div
                        className="w-full border border-glade-gray-300 flex p-3 content-center justify-between items-center rounded-md cursor-pointer"
                        onClick={() => setShowDropSel(!showDropSel)}
                      >
                        <div
                          className={`${
                            selectedTier ? "text-glade-black-800" : "text-glade-gray-400"
                          } text-sm`}
                        >
                          {selectedTier ? selectedTier?.name : "Select Tier"}
                        </div>
                        <div>
                          <span className="float-right">
                            <AngleDownSelect />
                          </span>
                        </div>
                      </div>
                      {showDropSel && (
                        <div className="z-1 absolute shadow-lg w-full transition delay-150 duration-1000 ease-in-out rounded-b-md bg-white">
                          {systemTier?.data &&
                            systemTier?.data?.map((tier) => (
                              // remove active tier and below from the select list
                              activeTier?.data && activeTier?.data.tier_grade < tier.tier_grade ?
                              <div
                                className="text-glade-black-800 bg-white px-2 py-3 hover:bg-gray-100 cursor-pointer border-b "
                                key={tier.key}
                                onClick={() => {
                                  setSelectedTier(tier);
                                  setShowDropSel(false);
                                }}
                              >
                                <div className="flex mb-2 text-base font-normal space-x-2">
                                  <span>
                                    <DisplayMedal tierName={tier?.name} />
                                  </span>
                                  <span>{tier.name}</span>
                                </div>
                                <div className="flex text-sm font-normal space-x-2">
                                  <div>
                                    Daily Limit: NGN
                                    {formatNumber(tier.daily_amount)},
                                  </div>
                                  <span>
                                    {tier.no_of_transactions} Transactions
                                  </span>
                                </div>
                              </div> : <></>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="space-y-2">
                  <label htmlFor="reason" className="text-glade-black-800">
                    Reason
                  </label>
                  <textarea
                    name="reason"
                    id="reason"
                    cols="30"
                    rows="7"
                    placeholder="I need to perform multiple transactions"
                    className="focus:border-glade-gray-300 border-glade-gray-300 w-full rounded-md text-glade-black-800 outline-0 border px-3 focus:ring-0"
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="w-full">
                  <Button.Blue
                    title={"Submit"}
                    disabled={!selectedTier || isLoading}
                    onClick={handleAmountRequest}
                    className="rounded-sm"
                  />
                </div>

                <div className="text-glade-gray-400">
                  To upgrade your account above tier {lastData?.name}, please contact us at 
                  <a href="mailto:support@glade.ng"> support@glade.ng</a> 
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const formatNumber = (num) => {
  return Number(num)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
