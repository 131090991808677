import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import TextIndicator from "../../components/indicators/TextIndicator";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import Button from "../../components/buttons/Button";
import PipedButton from "../../components/buttons/PipedButton";
import usePos from "../../hooks/usePos";
import { getPendingPosRequests } from "../../redux/pos/posActions";
import {
  statusEnum,
  simpleDate,
  isArray,
  isEmpty,
  pagination,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import CancelPosRequestModal from "./CancelPosRequestModal";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import useUser from "../../hooks/useUser";
import useTeam from "../../hooks/useTeam";

export default function PosRequest() {
  const cancelPosRequestModal = React.useRef();

  const history = useHistory();
  const dispatch = useDispatch();
  const pos = usePos();
  const user = useUser();
  const {permissions} = useTeam();

  React.useEffect(() => {
    dispatch(getPendingPosRequests());
    // eslint-disable-next-line
  }, [pos.shouldReload]);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(pos.pendingPosRequests, 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, pos.pendingPosRequests]);

  const renderPendingPOSTable = (data) => {
    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <TextIndicator type={statusEnum[item.status]} title={item.status} />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">
              {item.quantity_requested}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">
              {item.monthly_revenue}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">{item.daily_sales}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">
              {item.created_at && simpleDate(item.created_at)}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <PipedButton
              primaryButtonTitle="View"
              primaryButtonOnClick={() =>
                history.push({
                  pathname: !permissions?.includes("pos") ? "/dashboard/pos/pos-detail" : "/teams/pos/pos-detail",
                  state: item,
                })
              }
              secondaryButtonTitle="Cancel Request"
              secondaryButtonOnClick={() =>
                cancelPosRequestModal.current.setStateAndOpen(item)
              }
              secondaryButtonClassName="glade-normal-text-two glade-red"
            />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderPendingPOSDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() =>
            history.push({ pathname: !permissions?.includes("pos") ? "/dashboard/pos/pos-detail" : "/teams/pos/pos-detail", state: item })
          }
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item.quantity_requested} Terminal(s)
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.created_at, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <span
              className="glade-red"
              onClick={(e) => {
                if (!e) {
                  e = window.event;
                }
                e.cancelBubble = true;
                if (e.stopPropagation) e.stopPropagation();
                cancelPosRequestModal.current.setStateAndOpen(item);
              }}
            >
              Cancel Request
            </span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="px-3 py-0">
      <CardTableHeaderComplex
        title={`Showing ${tableData.totalItems} records`}
        rightButton={
          !user.isBusiness &&
          (pos?.pendingPosRequests?.length > 0 ||
            pos?.posTerminals?.length > 0) ? (
            <></>
          ) : (
            <div className="flex flex-row">
              <Button.Blue
                onClick={() => history.push(!permissions?.includes("pos") ? "/dashboard/pos/request-pos" : "/teams/pos/request-pos")}
                className="glade-button-blue"
                title={"Request New POS"}
              />
            </div>
          )
        }
        kebabMenuButton={
          !user.isBusiness &&
          (pos?.pendingPosRequests?.length > 0 ||
            pos?.posTerminals?.length > 0) ? (
            <></>
          ) : (
            <div>
              <KebabDropdown className="z-10" disabled={pos.isLoading}>
                <KebabDropdownItem
                  activeChild={
                    <span
                      onClick={() => history.push(!permissions?.includes("pos") ? "/dashboard/pos/request-pos" : "/teams/pos/request-pos")}
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Request New POS
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Request New POS
                    </span>
                  }
                />
              </KebabDropdown>
            </div>
          )
        }
      />

      {isEmpty(tableData.data) || pos.isLoading ? (
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable
              column={[
                "",
                "Status",
                "Quantity",
                "Monthly Revenue",
                "Daily Transactions",
                "Date Requested",
                "",
                "",
              ]}
              loading={pos.isLoading}
            />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2]} loading={pos.isLoading} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="hidden md:block">
            <CardTableBody
              column={[
                "",
                "Status",
                "Quantity",
                "Monthly Revenue",
                "Daily Transactions",
                "Date Requested",
                "",
                "",
              ]}
            >
              {isArray(tableData.data) && renderPendingPOSTable(tableData.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderPendingPOSDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={pos.isLoading}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />

      <CancelPosRequestModal ref={cancelPosRequestModal} />
    </div>
  );
}
