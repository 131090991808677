import React from "react";
import { useDispatch } from "react-redux";

import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import { isArray, isEmpty, arrayEquals, } from "../../helpers/helper";
import EmptyDesk from "../../components/empty-states/EmptyDesk";

import CircleIndicator from "../../components/indicators/CircleIndicator";
import { markInAppNotificationAsRead } from '../../redux/notifications/notificationsActions';
import useNotifications from "../../hooks/useNotifications";
import EmptyTable from '../../components/empty-states/EmptyTable';
import CheckboxInput from "../../components/inputs/CheckboxInput";
import { addHours, formatDistanceToNowStrict } from "date-fns";
const NotificationsList = ({ tableData, setNotificationDetails, setSelectedItem, setSelectedCheckbox, setSelectAllCheckbox, selectedCheckbox, setCurrentPage, currentPage }) => {
  const dispatch = useDispatch();
  const { isInAppNotificationLoading, inAppNotificationsData } = useNotifications();


  const updateSelectedCheckbox = (event, item) => {
    setSelectedItem(item);
    if (event.target.checked) {
      setSelectedCheckbox((prevSelectedItems) => [...prevSelectedItems, item?.id]);
      const oldList = [...selectedCheckbox];
      oldList.push(item?.id);
      oldList.sort(function (a, b) { return b - a })

      let notification_ids = inAppNotificationsData?.map((note) => note?.id).sort(function (a, b) { return b - a })

      if (arrayEquals(notification_ids, oldList)) {
        setSelectAllCheckbox(true)
      } else {
        setSelectAllCheckbox(false)
      }
    } else {
      setSelectedCheckbox((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item?.id)
      );
      setSelectAllCheckbox(false)
    }
  };



  const JsonDisplay = ({ jsonData, className }) => {
    const lines = Object.entries(jsonData).map(([key, value]) => (
      <div key={key} className={className}>
        <strong>{key}:</strong> {value}
      </div>
    ));
    return <div>{lines?.length > 1 ? `${jsonData[0]}...` : jsonData[0]}</div>
  };


  const renderNotifications = (data) => {
    const onClickOfRow = (item) => {
      setSelectedItem(item)
      setNotificationDetails(true)

      dispatch(markInAppNotificationAsRead(item?.id))
    };
    return data.map((item, key) => (
      <CardTableBodyRow key={key} onClick={() => { }} className={`${item?.is_read === 0 ? 'glade-bg-light-grey shadow-md rounded-lg' : ''}`}>

        <CardTableBodyCell>
          <CheckboxInput
            label={""}
            name={item?.id}

            type={"checkbox"}
            checked={selectedCheckbox.includes(item?.id)}
            onChange={(event) => updateSelectedCheckbox(event, item)}
            placeholder={"Charge user (optional)"}
            autoComplete={""}
            className={"appearance-none mt-4"}
          />
        </CardTableBodyCell>
        <CardTableBodyCell className="cursor-pointer" onClick={() => onClickOfRow(item)}>
          {item?.is_read === 0 ? <CircleIndicator type={'blue'} /> : <CircleIndicator type={'neutral'} />}
        </CardTableBodyCell>
        <CardTableBodyCell className="cursor-pointer" onClick={() => onClickOfRow(item)}>
          <div className="flex flex-col space-y-3">
            <h3 className="glade-heading-four">{item?.title}</h3>
            <JsonDisplay jsonData={JSON.parse(item?.body)} />
            <span className="glade-normal-text-two glade-grey-text md:hidden">
              {formatDistanceToNowStrict(addHours(new Date(item?.created_at), 1))} ago
            </span>
          </div>
        </CardTableBodyCell>
        <CardTableBodyCell className="cursor-pointer" onClick={() => onClickOfRow(item)}>
          <span className="glade-normal-text-two glade-grey-text hidden md:inline-block">
            {formatDistanceToNowStrict(addHours(new Date(item?.created_at), 1))} ago
          </span>
        </CardTableBodyCell>
        <CardTableBodyCell>
          <span></span>
        </CardTableBodyCell>
      </CardTableBodyRow>
    )
    );
  };


  return (
    <div>
      {isEmpty(tableData?.data) || isInAppNotificationLoading ?
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable column={['', '', '', '']} loading={isInAppNotificationLoading} />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2]} loading={isInAppNotificationLoading} />
          </div>

        </React.Fragment>
        :
        <React.Fragment>
          <div className="hidden md:block border-t-2">
            <CardTableBody>
              {isArray(tableData?.data) && renderNotifications(tableData?.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData?.data) && renderNotifications(tableData?.data)}

            </div>
          </div>
        </React.Fragment>
      }

      <CardTableFooter
        empty={isEmpty(tableData?.data)}
        loading={isInAppNotificationLoading}
        pageNumber={currentPage}
        totalPages={tableData?.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
    </div>
  )
}

export default NotificationsList