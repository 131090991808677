import React from "react";
import PlainModal from "../../components/modals/PlainModal";
import Button from "../../components/buttons/Button";

const VirtualCardFreezeModal = ({ showFreezeModal, onClose }) => {
  const onsubmit = () => {
    onClose("freeze");
  };

  return (
    <PlainModal
      visibility={showFreezeModal}
      onClose={onClose}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitleSection={<React.Fragment></React.Fragment>}
      modelContentSection={
        <div className="p-6">
          <div className="flex flex-col space-y-3">
            <h4 className="font-bold">
              Are you sure you want to freeze this card?
            </h4>
            <p className="p-6">
              This card will be temporarily disabled and you can unfreeze it
              anytime
            </p>
            <div className="flex items-center justify-center gap-4">
              <Button.Transparent
                title={"No, Go back"}
                onClick={() => onClose()}
              />
              <Button.Blue title={"Yes, Freeze"} onClick={() => onsubmit()} />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default VirtualCardFreezeModal;
