import React from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import styled from 'styled-components';
import Tooltip from '../tooltip/Tooltip';

export const CircleRadio = ({ selected, onChange, text, value }) => {
  return (
    <Radio onClick={() => onChange(value)}>
      <section className={`outer-circle ${value !== selected && 'unselected'}`}>
        <section className={`inner-circle ${value !== selected && 'unselected-circle'}`} />
      </section>
      <div className="helper-text">{text}</div>
    </Radio>
  );
};

const CircleRadioGroup = ({ children, label }) => {

  return (
    <div>
      <div className='flex relative gap-4 '>
        <label
          className='font-bold mb-2'
          htmlFor="sendAmount"
        >
          {label}
        </label>
        <Tooltip>
          <IoMdInformationCircle className='glade-grey-dark' size={20} />
          <section className='flex flex-col gap-4'>
            <div>
              <h3 className='font-bold'>Corporate</h3>
              <p>The corporate account is named and owned by registered business/organization.</p>
            </div>
            <div>
              <h3 className='font-bold'>Individual</h3>
              <p>The personal account is named and owned by just one person.</p>
            </div>
          </section>
        </Tooltip>
      </div>
      <section className="flex gap-6">
        {children}
      </section>
    </div>
  );
};

export default CircleRadioGroup;


const Radio = styled.div`
  margin: 24px 0;
  display: flex;
  cursor: pointer;
  user-select: none;

  .outer-circle {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border: 2px solid #1634A1;
    background-color: #1634A1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.15s linear;
  }

  .inner-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.15s linear;
  }

  .unselected {
    border: 2px solid #E4E4F3;
    background-color: #FAFAFA;
  }

  .unselected-circle {
    width: 0;
    height: 0;
  }

  .helper-text {
    color: #000;
    text-transform: capitalize;
  }
  
`;