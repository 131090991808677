import React from 'react';
import TextInput from '../../components/inputs/TextInput';
import {randomString} from '../../helpers/helper';

export default React.forwardRef(function CreateInvoiceItems({currency='NGN', calculate=()=>{}},ref) {

    const [input, setInput] = React.useState({
        0:{ 
            id:randomString(10),
            description:'',
            qty:1,
            cost:'',
            total:'',
            deleted:false
        }
    });

    React.useEffect(()=>{
        calculate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[input]);

    // Ref Handler
    React.useImperativeHandle(ref, () => ({
        getInput: () => getInput(),
        resetInput: () => resetInput(),
        replaceInput: (value) => setInput(value),
        // anotherMethodOfChoice : () => {}
    }));

    function getInput() {
        let items = [];
        for (const key in input) {
            if (input[key]['deleted'] === false) {
                items.push(input[key]);
            }
        }
        return items;
    }

    function resetInput() {
        setInput({
            0:{ 
                id:randomString(10),
                description:'',
                qty:1,
                cost:'',
                total:'',
                deleted:false
            }
        });
    }

    const onAddItem = () => {
        let key = Object.keys(input).length;
        setInput({...input, [key]:{
            id:randomString(10),
            description:'',
            qty:1,
            cost:'',
            total:'',
            deleted:false
        }});
    }

    const onDeleteItem = (key) => {
        setInput({...input,[key]:{...input[key],deleted:true}});
    }

    const onChange = (e,key) => {
        setInput({...input,[key]:{...input[key],[e.target.name]:e.target.value}});
    }

    const onChangeOfQuantity = (e,key) => {
        setInput({...input,[key]:{
            ...input[key],
            [e.target.name]:e.target.value,
            total: Number(e.target.value*input[key]['cost']).toLocaleString('en-US')
        }});
    }

    const onChangeOfUnitCost = (e,key) => {
        setInput({...input,[key]:{
            ...input[key],
            [e.target.name]: Number(e.target.value.replace(currency,'').replace(' ','').replaceAll(',', '')),
            total: Number(e.target.value.replace(currency,'').replace(' ','').replaceAll(',', '')*input[key]['qty'])
        }});
    }

    return (
        <div className="flex flex-col justify-between pb-8 border-b-2">
            <div className="flex flex-col space-y-3">
                {Object.keys(input).map((item, key)=>{
                    return input[key]['deleted']? null : (
                        <div key={key} className="w-full flex flex-row space-x-3">
                            <div className="w-4/12">
                                <TextInput 
                                    label={'Item'}
                                    id={"description"}
                                    name={'description'}
                                    type={'text'}
                                    value={input[key]['description']}
                                    onChange={(e)=>onChange(e,key)}
                                    placeholder={'Item Name'}
                                    autoComplete={"description"}
                                    className={"appearance-none"}
                                />
                            </div>
                            <div className="w-4/12">
                                <TextInput 
                                    label={'Unit Cost'}
                                    id={"cost"}
                                    name={'cost'}
                                    type={'text'}
                                    value={currency+' '+input[key]['cost']}
                                    onChange={(e)=>onChangeOfUnitCost(e,key)}
                                    placeholder={''}
                                    autoComplete={"cost"}
                                    className={"appearance-none"}
                                />
                            </div>
                            <div className="w-2/12">
                                <TextInput 
                                    label={'Quantity'}
                                    id={"qty"}
                                    name={'qty'}
                                    type={'number'}
                                    value={input[key]['qty']}
                                    onChange={(e)=>onChangeOfQuantity(e,key)}
                                    placeholder={'0'}
                                    autoComplete={"qty"}
                                    className={"appearance-none"}
                                />
                            </div>
                            <div className="w-4/12">
                                <TextInput 
                                    label={'Total'}
                                    id={"total"}
                                    name={'total'}
                                    type={'text'}
                                    disabled={true}
                                    value={currency+' '+input[key]['total']}
                                    onChange={()=>{}}
                                    placeholder={''}
                                    autoComplete={"total"}
                                    className={"appearance-none"}
                                />
                            </div>
                            <div className="w-1/12">
                                <div className="flex justify-center items-center cursor-pointer" onClick={()=>onDeleteItem(key)}>
                                    <span className="mt-6">X</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <h5 className="flex justify-center mt-6">
                <span className="glade-subtitle-two glade-blue cursor-pointer" onClick={onAddItem}> + Add Another Item </span>
            </h5>
        </div>
    )
});