import React from "react";
import BusinessInformationNonNigeria  from "./BusinessInformationNonNigeria";
import BusinessInformationNigeria  from "./BusinessInformationNigeria.js";

export const BusinessInformation = ({ setFormLevel, input, setInput }) => {

  return (
    <>
      {input?.country_code?.toLowerCase() !== 'nga' ? <BusinessInformationNonNigeria  setFormLevel={setFormLevel} input={input} setInput={setInput}   /> : <BusinessInformationNigeria setFormLevel={setFormLevel} input={input} setInput={setInput} />
      }
    </>
  );
};