import React from 'react';

export default function IndividualInput({
    label, 
    id,
    name, 
    type,
    onChange=()=>{}, 
    disabled=false, 
    className, 
    numberOfInputTags=6, 
}) {

    const [input, setInput] = React.useState('');

    /**
     * Handle user key inputs 
     * including copy and paste
     * @param {event} e 
     */
    const processInput = (e) => {
        // Ensure that the input length is not more than the available inputs tags
        if (input.length < numberOfInputTags) {

            // Check if input length is more than one, which means its either a copy and paste or a second entry
            if (e.target.value.length > 1) {

                // Check if input length is more than two, which confirm its a copy and paste
                if (e.target.value.length > 2) {
                    setInput(input+e.target.value);
                    onChange(input+e.target.value.substring(0,numberOfInputTags));
                } else {
                    setInput(input+e.target.value.substring(1));
                    onChange(input+e.target.value.substring(1).substring(0,numberOfInputTags));
                }

            } else {
                setInput(input+e.target.value);
                onChange(input+e.target.value.substring(0,numberOfInputTags));
            }
        }
    }

    /**
     * Handle user clear/delete inputs as onchange
     * can not track key down inputs
     * @param {event} e 
     */
    const clearInput = (e) => {
        let key = e.keyCode || e.charCode || e.key;

        if (["Backspace", "Delete", 8, 46].includes(key)) {
            setInput(input.substring(0,numberOfInputTags).slice(0,-1));
            onChange(input.substring(0,numberOfInputTags).slice(0,-1));
        }
    }

    const renderInputTags = () => {
        let inputs = [];
        for (let index = 0; index < numberOfInputTags; index++) {
            inputs.push(
                <input
                    key={index}
                    name={name??'single-input'}
                    type={type??'password'}
                    pattern={'[0-9]*'}
                    min={'0'}
                    disabled={disabled}
                    value={input.charAt(index)}
                    onChange={(e)=>{ e.target.value = e.target.value.replace(/[^\d]/g, ''); processInput(e); }}
                    onKeyUp={(e)=>clearInput(e)}
                    autoComplete={undefined}
                    className={className+" focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two block py-2 pl-3.5 w-12 h-12 rounded-lg sm:text-sm border border-gray-300 text-center placeholder-gray-400"}
                />
            );
        }
        return inputs;
    }

    return (
        <div className="space-y-1 col-span-6 sm:col-span-3">
            <label htmlFor={name??'name'} className="block glade-small-text-two glade-black mb-3">
                {label??''}
            </label>
            <div id={id??name??'id'} className="flex justify-between space-x-12">
                {renderInputTags()}
            </div>
        </div>
    )
}
