import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { TransferFormContext } from '../../context/TransferFormContext';
import ErrorMessage from '../../validation/fund-transfer/ErrorMessage';
import { ImSpinner2 } from "react-icons/im";
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { IoMdInformationCircle } from 'react-icons/io';

const InputGroup = ({
  max,
  type,
  label,
  value,
  name,
  placeholder,
  children,
  error,
  onBlur,
  disabled,
  loading,
  tooltipContent
}) => {
  const { setTransferForm, transferForm } = useContext(TransferFormContext);

  const {
    setTriggerRef,
    setTooltipRef,
    visible,
    getTooltipProps
  } = usePopperTooltip();

  function onInputChange(e) {
    setTransferForm(form => ({
      ...form,
      [e.target.name]: e.target.value
    }));
  }

  return (
    <Div>
      <div className="flex gap-4 relative">
        <label
          className='font-bold mb-2'
          htmlFor="sendAmount">
          {label}
        </label>
        {tooltipContent && (
          <div
            className='relative'>
            <span ref={setTriggerRef} className='absolute bottom-2'>
              <IoMdInformationCircle className='glade-grey-dark' size={20} />
            </span>
            {visible && (
              <SCTooltipContent
                className='absolute left-0 z-index-100 w4/8'
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip-container' })}
              >
                <h2>{tooltipContent}</h2>
              </SCTooltipContent>
            )}
          </div>
        )}
      </div>
      <section className='input-area'>
        <input
          maxLength={max}
          name={name}
          type={type || "text"}
          placeholder={placeholder}
          value={value || ''}
          disabled={disabled ?? false}
          onBlur={onBlur}
          onChange={onInputChange}
        />
        {loading && <span className={"icon"}><ImSpinner2 className={"spinner"} size={19} color='#1634A1' /></span>}
      </section>
      {children}
      {error && <ErrorMessage msg={error} />}
    </Div>
  );
};

export default InputGroup;

const SCTooltipContent = styled.div`
  width: 400px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 1rem;
  z-index: 100;
 `;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  color: black;
  
  .input-area {
    position: relative;
  }

  input {
    border: 1px solid #E4E4F3;
    border-radius: 5px;
    // width: 345px;
    width: 100%;
    // width: 500px;

    &::placeholder {
      color: #9CA3AF;
    }    
  }

  .icon {
    position: absolute;
    right: 2%;
    top: 30%;
  }
`;