import React from "react";
import Button from "../../../components/buttons/Button";
import useSettings from "../../../hooks/useSettings";
import FileInput from "../../../components/inputs/FileInput";
import TextInput from "../../../components/inputs/TextInput";
import SelectInput from "../../../components/inputs/SelectInput";
import { isArray } from "../../../helpers/helper";
import {
  businessInformationValidation,
} from "../../../validation/settings/settingsValidation";
import useUser from "../../../hooks/useUser";
import useBusiness from "../../../hooks/useBusiness";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBusinessCategories, getBusinessTypes } from "../../../redux/business/businessActions";
import { submitBusinessInformation
} from "../../../redux/settings/settingsActions";
import {
  getApprovedStates,
  getApprovedLGAs
} from "../../../redux/countries/countriesActions"
import useCountries from "../../../hooks/useCountry";

const BusinessInformationNigeria = ({ setFormLevel, input, setInput }) => {
  const settings = useSettings();
  const [inputError, setInputError] = React.useState({});
  const business = useBusiness();
  const dispatch = useDispatch();
  const user = useUser();
  const countries = useCountries();

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputError({ ...inputError, [e.target.name]: false });
  };
  const onSubmit = () => {
    let inputValid = businessInformationValidation(input);
    if (isArray(inputValid)) {
      inputValid.forEach((field) => {
        setInputError((prev) => ({ ...prev, [field]: true }));
      });
    } else {
      // Handle Submission
      dispatch(
        submitBusinessInformation(
          {
            business_type: input.business_type,
            industry: input.industry,
            business_description: input.business_description,
            secondary_phone: input.secondary_phone,
            address: input.business_address,
            lga: input.business_lga,
            state: input.business_state,
            proof_of_address: input.business_proof_of_address[0],
            registered_name: user?.selectedBusinessOrPersonalAccount?.account_name,
            country_code: input.country_code
          }, () => { setFormLevel("owner information"); }
        )
      )
    }
  };
  useEffect(() => {
    dispatch(getBusinessCategories());
    dispatch(getBusinessTypes());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    input.compliance_country && dispatch(getApprovedStates({country_id:input.compliance_country}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.compliance_country]);

  useEffect(() => {
    (input.country_code.toLowerCase() === 'nga' && input.business_state) && dispatch(getApprovedLGAs({ state_id: input.business_state }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.business_state, input.country_code]);

  return (
    <>
      <div className="px-12">
        <h3 className="font-bold mb-5 text-glade-black-800">
          Provide information about your business
        </h3>
      </div>
      <div className="w-full space-y-6 border-b border-gray-300 px-12 pb-8">
   
        <div>
          <SelectInput
            label={"Business Type"}
            id={"business_type"}
            name={"business_type"}
            value={input.business_type ?? ""}
            onChange={onChange}
            autoComplete={"business_type"}
            className={"appearance-none"}
            errorState={inputError.business_type}
          >
            <option value={""} disabled={true}>Select Type</option>
            {isArray(business?.businessTypes) && business?.businessTypes.map((item, key) => {
              return (
                <option key={key} value={item}>{item}</option>
              );
            })}
          </SelectInput>
          {inputError.business_type && (
            <div className="text-glade-red-400 text-sm mt-2">
              Business Type is a required field
            </div>
          )}
        </div>
        <div>
          <SelectInput
            label={"Industry"}
            id={"industry"}
            name={"industry"}
            value={input.industry ?? ""}
            onChange={onChange}
            autoComplete={"industry"}
            className={"appearance-none"}
            errorState={inputError.industry}
          >
            <option value={''} disabled={true}>Select Industry</option>
            {isArray(business?.businessCategories) && business?.businessCategories.map((item, key) => {
              return (
                <option key={key} value={item}>{item}</option>
              );
            })}
          </SelectInput>
          {inputError.industry && (
            <div className="text-glade-red-400 text-sm mt-2">
              Industry is a required field
            </div>
          )}
        </div>
        <div>
          <TextInput
            label={"Business Description"}
            id={"business_description"}
            name={"business_description"}
            value={input.business_description ?? ""}
            onChange={onChange}
            placeholder={"Sale of farm equipments"}
            autoComplete={"business_description"}
            className={"appearance-none"}
            errorState={inputError.business_description}
          />
          {inputError.business_description && (
            <div className="text-glade-red-400 text-sm mt-2">
              Business Description is a required field
            </div>
          )}
        </div>

        <div>
          <TextInput
            leftSection={
              <span className="glade-button-gray glade-home-dark self-center border border-gray-300 rounded-sm p-2">
                +234
              </span>
            }
            label={"Secondary Phone Number"}
            id={"secondary_phone"}
            name={"secondary_phone"}
            value={input.secondary_phone ?? ""}
            onChange={onChange}
            placeholder={"Enter Secondary Phone Number"}
            autoComplete={"secondary_phone"}
            className={"appearance-none"}
            errorState={inputError.secondary_phone}
          />
          {inputError.secondary_phone && (
            <div className="text-glade-red-400 text-sm mt-2">
              Secondary Phone Number is a required field
            </div>
          )}
        </div>
        <div>
          <TextInput
            label={"Business Address"}
            id={"business_address"}
            name={"business_address"}
            value={input.business_address ?? ""}
            onChange={onChange}
            placeholder={"Enter Business Address "}
            autoComplete={"business_address"}
            className={"appearance-none"}
            errorState={inputError.business_address}
          />
          {inputError.business_address && (
            <div className="text-glade-red-400 text-sm mt-2">
              Business Address is a required field
            </div>
          )}
        </div>
        <div>
          <SelectInput
            label={"State"}
            id={"business_state"}
            name={"business_state"}
            value={input.business_state ?? ""}
            onChange={onChange}
            autoComplete={"business_state"}
            className={"appearance-none"}
            errorState={inputError.business_state}
          >
            <option value={""} disabled={true}>
              Select A State
            </option>
            {isArray(countries.getStates) &&
              countries.getStates?.map((state, index) => (
                <option key={index} value={state.state_id}>
                  {state.state_name}
                </option>
              )) 
            }
            
          </SelectInput>
          {inputError.business_state && (
            <div className="text-glade-red-400 text-sm mt-2">
              State is a required field
            </div>
          )}
        </div>

        {input.country_code.toLowerCase() === "nga" &&
          <div>
            <SelectInput
              label={"LGA"}
              id={"business_lga"}
              name={"business_lga"}
              value={input.business_lga ?? ""}
              onChange={onChange}
              autoComplete={"business_lga"}
              className={"appearance-none"}
              errorState={inputError.business_lga}
            >
              <option value={''} disabled={true}>Select A Local Government Area</option>
              {isArray(countries.getLgas) && countries.getLgas?.map((lga, index) => (
                <option key={index} value={lga.id}>{lga.name}</option>
              ))}
            </SelectInput>
            {inputError.business_lga && (
              <div className="text-glade-red-400 text-sm mt-2">
                LGA is a required field
              </div>
            )}
          </div>
        }

        <div>
          <FileInput
            label={"Upload Proof of Address"}
            id={"business_proof_of_address"}
            name={"business_proof_of_address"}
            multiple={true}
            accept=".png, .jpeg, .pdf, .jpg"
            maximumSize={3}
            returnInput={(value) => {
              setInputError({ ...inputError, ['business_proof_of_address']: false });
              setInput({ ...input, business_proof_of_address: value })
            }
            }
            errorState={inputError.business_proof_of_address}
          />
          {inputError.business_proof_of_address && (
            <div className="text-glade-red-400 text-sm mt-2">
              Upload a valid format (JPG, PNG, PDF)
            </div>
          )}
          <span className="glade-small-text-one glade-grey-cool">
            Proof of address can be recognized documents that verify your a
            current address. Such as Utility bills, Bank statements or Cable
            bills not more than 3 months old.
          </span>
          <span className="glade-small-text-one glade-grey-cool block">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.
          </span>
        </div>
      </div>
      <div className="p-6">
        
        <div className="float-right">
          <Button.Blue
            onClick={onSubmit}
            className="w-full mr-3"
            title={"Next"}
            disabled={settings.isLoadingCompliance}
            loading={settings.isLoadingCompliance}
          />
        </div>
      </div>
    </>
  );
};

export default BusinessInformationNigeria