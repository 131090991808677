import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_PAYMENT_INFLOW,
  LOADING_NGN_PAYMENT_INFLOW,
  // RELOAD_PAYMENT_INFLOW,
  GET_PAYMENT_INFLOW_SUMMARY,
  GET_PAYMENT_OUTFLOW_SUMMARY,
  GET_SPECIFIC_PAYMENT_INFLOW,
  DOWNLOAD_ALL_NGN_PAYMENT_INFLOW,
  LOADING_ANY_PAYMENT_INFLOW,
  SUCCESS_ANY_PAYMENT_INFLOW,
} from "./types";

export const getPaymentInflowSummary = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: true });
    Axios.get(`dashboard/businesses/inflow-summary`, { params: payLoad })
      .then((res) => {
        dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: false });
        dispatch({ type: GET_PAYMENT_INFLOW_SUMMARY, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const getPaymentOutflowSummary = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: true });
    Axios.get(`dashboard/businesses/outflow-summary`, { params: payLoad })
      .then((res) => {
        dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: false });
        dispatch({ type: GET_PAYMENT_OUTFLOW_SUMMARY, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const getAllPaymentFlows = (payLoad, selectedCurrency) => {
  let url;
  if (selectedCurrency === "all") {
    url = `/businesses/transactions/list?currency=`;
  } else {
    url = `/businesses/transactions/list?currency=${selectedCurrency}`;
  }
  return (dispatch) => {
    dispatch({ type: LOADING_ANY_PAYMENT_INFLOW, payLoad: true });
    Axios.get(url, { params: payLoad })
      .then((res) => {
        dispatch({ type: LOADING_ANY_PAYMENT_INFLOW, payLoad: false });
        dispatch({ type: SUCCESS_ANY_PAYMENT_INFLOW, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_ANY_PAYMENT_INFLOW, payLoad: false });
        errorHandler(error, true);
      });
  };
};

//  Download All NGN Payment Inflows
export const downloadAllNGNPaymentFlows = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_NGN_PAYMENT_INFLOW, payLoad: true });
    Axios.get(
      `/businesses/transactions/list?currency=NGN`,
      { params: payLoad }
    )
      .then((res) => {
        dispatch({ type: LOADING_NGN_PAYMENT_INFLOW, payLoad: false });
        dispatch({ type: DOWNLOAD_ALL_NGN_PAYMENT_INFLOW, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_NGN_PAYMENT_INFLOW, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const getSpecificPaymentFlow = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: true });
    Axios.get(
      `/businesses/transactions/details/${payLoad.id}`
    )
      .then((res) => {
        dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: false });
        dispatch({ type: GET_SPECIFIC_PAYMENT_INFLOW, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_PAYMENT_INFLOW, payLoad: false });
        errorHandler(error, true);
      });
  };
};
