import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../components/buttons/Button';
import FormBuilder from '../../components/form/FormBuilder';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import ChargeInfo from '../../components/transfer/ChargeInfo';
import InputGroup from '../../components/transfer/InputGroup';
import SendInput from '../../components/transfer/SendInput';
import { TransferFormContext } from '../../context/TransferFormContext';
import { LocalNonNairaTransferFormEnum } from '../../helpers/TransferFormFieldsHelper';
import useFundTransfer from '../../hooks/useFundTransfer';
import { getBanksForTransfer, getCountriesAndStates, getInternationalTransferBankBranches, getInternationalTransferBanks, getInternationalTransferMethods, getInternationalTransferRate } from '../../redux/fund-transfer/fundTransferActions';
import { STransferForm } from './TransferInternationalForm';
import { validateLocalTransferForm } from '../../helpers/validator';
import { removeCommas } from '../../helpers/helper';

const ForeignTransferForm = () => {
  const {
    transferForm,
    transferCharge,
    availableBalance,
    setFormStage,
    setTransferForm,
    setTransferCharge,
    localFormCurrency,
    currencies,
    accountTypes,
  } = useContext(TransferFormContext);
  
  const [recipientAccountType, setRecipientAccountType] = useState(transferForm.beneficiary_account_type || '');
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const dispatch = useDispatch();
  const { internationalTransferRate } = useFundTransfer();
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfStates, setListOfStates] = useState([]);
  const [transferMethod, setTransferMethod] = useState([]);
  const [selectedTransferMethod, setSelectedTransferMethod] = useState('');
const [transferProvider, setTransferProvider] = useState([])
const [transferBranches, setTransferBranches] = useState([])


  function onAccountTypeSelect(type) {
    setRecipientAccountType(type);
    setTransferForm(form => ({ ...form, beneficiary_account_type: type }));
  }

  function toggleSaveBeneficiary() {
    setTransferForm(form => ({ ...form, save_beneficiary: !saveBeneficiary }));
    setSaveBeneficiary(!saveBeneficiary);
  }

  useEffect(() => {
    setTransferForm(form => ({
      ...form,
      beneficiary_country_code: currencies?.activeCurrency?.currency?.slice(0, -1),
      beneficiary_currency_code: currencies?.activeCurrency?.currency
    }));
  }, []);

  useEffect(() => {
    dispatch(getInternationalTransferMethods(
      { country_code: currencies?.activeCurrency?.code },
      (data) => {
        setTransferMethod(data);
        setTransferForm(form => (
          {
            ...form,
            transfer_method: data[0].code
          }));
      }
    ));
  }, [currencies?.activeCurrency?.code]);

  useEffect(() => {
    setTransferCharge(internationalTransferRate?.fee ?? internationalTransferRate?.rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internationalTransferRate?.fee]);


  useEffect(() => {
    return listOfCountries.length < 1 ? dispatch(getCountriesAndStates((data) => setListOfCountries(data)
    )) : null;
  }, [listOfCountries]);

  const onCountrySelect = (country, callback) => {
    setTransferForm(form => ({
      ...form,
      beneficiary_country: country.name,
      beneficiary_state: ''
    }));
    setListOfStates(country?.states);
    callback();
  };
  const onStateSelect = (state, callback) => {
    setTransferForm(form => ({
      ...form,
      beneficiary_state: state.name,
    }));
    callback();
  };

  const onTransferMethodSelect = (method, callback) => {
    setTransferForm(form => ({
      ...form,
      transfer_method: method.code,
    }));
    dispatch(getInternationalTransferRate({
      amount: removeCommas(transferForm?.amount, true) || 0,
      source_currency: currencies?.activeCurrency?.currency,
      destination_currency: currencies?.activeCurrency?.currency,
      destination_country: currencies?.activeCurrency?.currency?.slice(0, -1),
      transfer_method: method?.code || "bank"
    },
      (data) => {
        setTransferCharge(data?.fee ?? data?.rate);
      }
    ));
    setSelectedTransferMethod(method);
    if(method.code === 'mobilemoney'){
    dispatch(getInternationalTransferBanks({
      country_code: currencies?.activeCurrency?.currency?.slice(0, -1),
      method_code: method?.code
    }, providers => {
      setTransferProvider(_providers => ({ ..._providers, providers: providers, selected: null }));
      setTransferBranches({});
    }));
  }
  if(method.code === 'account'){ 
    dispatch(getBanksForTransfer(currencies?.activeCurrency?.currency?.slice(0, -1), providers => {
      setTransferProvider(_providers => ({ ..._providers, providers: providers, selected: null }));
      setTransferBranches({});
    }));
  }
    callback();
  }

  const onProviderSelect = (method, callback) => {
    setTransferForm(form => ({
      ...form,
      provider: method?.code,
      beneficiary_bank: method?.name
    }));
   
      setTransferProvider(_providers => ({ ..._providers, selected: method }));
      if(method?.id){
        dispatch(getInternationalTransferBankBranches({country_code: currencies?.activeCurrency?.currency?.slice(0, -1), 
          bank_id: method.id}, branches => {
          setTransferBranches(_branches => ({ ..._branches, branches: branches, selected: null }))
        }));
      }
    callback();
  }

  const onBranchSelect = (method, callback) => {
    setTransferForm(form => ({
      ...form,
      branch: method.branch_code,
    }));
    setTransferBranches(_branches => ({ ..._branches, selected: method }));
  callback();
}

  const disabled = () => {
    //Get data for validation
    let dynamicFormData = {};
    LocalNonNairaTransferFormEnum[`${currencies?.activeCurrency?.currency}`] &&
      LocalNonNairaTransferFormEnum[`${currencies?.activeCurrency?.currency}`]['formList']
        .map(name => dynamicFormData[name?.id] = transferForm[name?.id]);

    // Run validation
    let result = validateLocalTransferForm({ ...dynamicFormData, amount: transferForm?.amount, }, transferForm);

    // Enable or disable button
    return result?.length > 0
      // || (!transferCharge)
      || (Number(parseFloat(removeCommas(transferForm?.amount))) + Number(parseFloat(transferCharge))) > parseFloat(availableBalance)  // Check for if fund is enough
      ? true : false;
  };



  return (
    <STransferForm>
      {/* Default form field */}
      <SendInput
        availableBalance={availableBalance}
        label={"Send Amount"}
        currency={localFormCurrency}
        oneWay
      />

      {/* Dynamic form field */}

      {LocalNonNairaTransferFormEnum[`${currencies?.activeCurrency?.currency}`] &&
        <FormBuilder
          formList={LocalNonNairaTransferFormEnum[`${currencies?.activeCurrency?.currency}`]?.formList}
          setFormStage={setFormStage}
          recipientAccountType={recipientAccountType}
          accountTypes={accountTypes}
          onAccountTypeSelect={onAccountTypeSelect}
          transferForm={transferForm}
          listOfCountries={listOfCountries}
          listOfStates={listOfStates}
          onCountrySelect={onCountrySelect}
          onStateSelect={onStateSelect}
          transferMethod={transferMethod}
          onTransferMethodSelect={onTransferMethodSelect}
          selectedTransferMethod={selectedTransferMethod}
          transferProvider={transferProvider}
          onProviderSelect={onProviderSelect}
          transferBranches={transferBranches}
          onBranchSelect={onBranchSelect}
        />}

      {/* Default form fields */}
      <InputGroup
        label={"Narration"}
        placeholder={"Optional"}
        value={transferForm?.narration}
        name={"narration"}
      />

      <CheckboxInput
        label={"Save as beneficiary"}
        checked={saveBeneficiary}
        onChange={toggleSaveBeneficiary}
      />
      {(transferForm.amount && transferForm.amount > 0) && <ChargeInfo transferCharge={transferCharge} />}
      <div className='flex justify-center'>
        <Button.Blue
          disabled={disabled()}
          onClick={() => setFormStage('review')}
          title={"Confirm"}
          className={"mt-6"}
        />
      </div>
    </STransferForm>
  );
};

export default ForeignTransferForm;