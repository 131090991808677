import { useEffect, useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { isObject } from "../../helpers/helper";
import useCountries from "../../hooks/useCountry";
import { createVirtualCard } from "../../redux/virtual-cards/virtualCardActions";
import ConfirmPinModal from "../fund-transfer-new/ConfirmPinModal";
import CreateVirtualCard from "./CreateVirtualCard";
import VirtualCardCreateCardDetails from "./VirtualCardCreateCardDetails";
import VirtualCardCreatePersonalDetails from "./VirtualCardCreatePersonalDetails";

const VirtualCardCreateLayout = () => {
  const history = useHistory();
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [title, setTite] = useState("");
  const countries = useCountries();

  const [formData, setFormData] = useState({
    currency: "",
    card_type: null,
    card_name: "",
    charge_from_currency: "",
    fund_amount: "",
    card_spend_limit: "",
    color: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postal_code: "",
    passport: [],
    gender: "",
    place_of_birth: "",
  });

  const [cardSkinColor, setCardSkinColor] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [cardDetails, setCardDetails] = useState({});

  useEffect(() => {
    if (step === 1) {
      setTite(<h3 className="glade-subtitle-one">Create Card</h3>);
    }
    if (step === 2) {
      setTite(
        <div className="flex flex-col">
          <h3 className="glade-subtitle-one">Create Virtual Card</h3>
          <p className="glade-grey-text glade-small-text-one">
            Fill in card details
          </p>{" "}
        </div>
      );
    }
    if (step === 3) {
      setTite(
        <div className="flex flex-col">
          <h3 className="glade-subtitle-one">Create Virtual Card</h3>
          <p className="glade-grey-text glade-small-text-one">
            Fill in personal details
          </p>{" "}
        </div>
      );
    }
  }, [step]);

  const getCountryName = (country_id) => {
    let country = countries?.getCountries?.find(
      (country) => parseInt(country?.country_id) === parseInt(country_id)
    );
    if (isObject(country)) {
      return {
        country_name: country?.country_name,
        country_code: country?.country_code,
        country_id: country?.country_id,
      };
    }
  };

  function openConfirmPinModal() {
    modalRef.current.openModal();
  }
  function closeConfirmPinModal() {
    modalRef.current.closeModal();
  }

  const callBack = (res) => {
    closeConfirmPinModal();
    setCardDetails(res);
    setCardSkinColor(false);
    setSuccessful(true);
  };

  const handleCreateVirtualCard = () => {
    let formDatas = new FormData();
    formDatas.append("passport_photo", formData.passport[0]);
    formDatas.append("color", formData.color);
    formDatas.append("charge_from_currency", formData.charge_from_currency);
    formDatas.append("amount", formData.fund_amount?.replaceAll(",", ""));
    formDatas.append(
      "type",
      formData.card_type === "Mastercard" ? "mastercard" : "vervecard"
    );
    formDatas.append("gender", formData.gender.toLowerCase());
    formDatas.append("place_of_birth", formData.place_of_birth);
    formDatas.append(
      "address[0][country]",
      getCountryName(formData.country)?.country_name
    );
    formDatas.append("address[0][state]", formData.state);
    formDatas.append("address[0][city]", formData.city);
    formDatas.append("address[0][street]", formData.address);
    formDatas.append("address[0][postal_code]", formData.postal_code);
    formDatas.append("date_of_birth", `${formData?.year}-${formData?.month}-${formData?.day}`);
    dispatch(createVirtualCard(formDatas, callBack));
  };

  return (
    <Layout>
      <div className="flex justify-between border-b-2 border-[#E4E4F3] px-4 py-6 mx-6 lg:mx-12">
        <div>{title}</div>
        <span
          onClick={() => {
            step > 1 ? setStep(step - 1) : history.push("/dashboard/cards");
          }}
          className="cursor-pointer"
        >
          <CgClose size={31} />
        </span>
      </div>
      {step === 1 && (
        <CreateVirtualCard
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {step === 2 && (
        <VirtualCardCreateCardDetails
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {step === 3 && (
        <>
          <VirtualCardCreatePersonalDetails
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            openConfirmPinModal={openConfirmPinModal}
            cardSkinColor={cardSkinColor}
            setCardSkinColor={setCardSkinColor}
            successful={successful}
            cardDetails={cardDetails}
          />
          <ConfirmPinModal
            title="Enter your PIN"
            body=""
            onCorrectPin={handleCreateVirtualCard}
            ref={modalRef}
          />
        </>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: Inter, sans-serif;
  color: #151515;
  z-index: 300;
  // width: 100%;
  background: #fff;
  overflow-y: auto;
`;

export default VirtualCardCreateLayout;
