import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import NumericInput from "../inputs/NumericInput";
import TextInput from "../inputs/TextInput";
import ReceiverFlag from "../transfer/ReceiverFlag";

const InputWithFlagOptions = ({
    name = 'amount',
    value, id = "amount",
    onChange = () => { },
    placeholder,
    selectedFlag = 'GBP',
    defaultOptions = [],
    optionKey = 'currency',
    filterPlaceholder = 'Search currency',
    selectedFlagOption
}) => {
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState(defaultOptions);

    const emitFlagOption = (e, index) => {
        selectedFlagOption(e, index);
        setShowFilter(!showFilter);
    }
    const FilterData = (e) => {
        setSearch(e?.target?.value);
        let filteredArray = [];
        if (e.target.value) {
            defaultOptions.forEach((filter, i) => {
                if (
                    e.target.value &&
                    filter[`${optionKey}`]
                        ?.toLowerCase()
                        .includes(e.target.value.toLowerCase())
                ) {
                    return defaultOptions[i] ? filteredArray.push(defaultOptions[i]) : '';
                }
                setOptions(filteredArray);
            });
        } else {
            return setOptions(defaultOptions);
        }
    }
    return (
        <div className="relative">
            <div>
                <NumericInput
                    id={id}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e)}
                    placeholder={placeholder}
                    autoComplete={''}
                    className={'appearance-none h-full'}
                    leftSection={
                        <div className='border flex justify-center items-center pl-2 cursor-pointer' onClick={() => setShowFilter(!showFilter)}>
                            <span className='flex items-center justify-center gap-2 h-40'>
                                <ReceiverFlag flagCode={selectedFlag?.slice(0, -1)} />
                                {selectedFlag}
                            </span>
                            {!showFilter ?
                                <RiArrowDropDownLine size={'28px'} color="#000" />
                                :
                                <RiArrowDropUpLine size={'28px'} color="#000" />
                            }
                        </div>
                    }
                />
            </div>
            {showFilter &&
                <div className=" absolute z-10 w-full ">
                    <TextInput
                        value={search}
                        onChange={FilterData}
                        placeholder={filterPlaceholder}
                        rightSection={
                            <IoIosClose
                                className="absolute cursor-pointer top-2 right-2"
                                size={30}
                                onClick={() => setShowFilter(!showFilter)}
                            />
                        }
                    />
                    <div className="flex flex-col gap-2 p-2 top-2 bg-white glade-grey-dark border max-h-72 overflow-y-auto">
                        {
                            options.length > 0 ?
                                options.map((option, key) =>
                                    <div key={key}>

                                        < button onClick={() => emitFlagOption(option[`${optionKey}`], key)}>
                                            {
                                                optionKey === 'currency' ?
                                                    <p className="flex items-center gap-2">
                                                        <ReceiverFlag flagCode={option[`${optionKey}`]?.slice(0, -1)} />
                                                        {`(${option[`${optionKey}`]})`}
                                                    </p>
                                                    : option[`${optionKey}`]
                                            }
                                        </button>
                                    </div>)
                                : <p className="text-center">List is Empty</p>
                        }
                    </div>
                </div>}
        </div >
    )
}

export default InputWithFlagOptions;