import React from 'react';
import { Link } from "react-router-dom";
import {IoIosArrowBack} from 'react-icons/io';

export default function BreadCrumbs({title, icon, path}) {
    return (
        <Link className="inline-flex items-center justify-start glade-subtitle-two glade-black" to={path}>
            {icon??<IoIosArrowBack/>} <span className="ml-2">{title??'Title'}</span>
        </Link>
    )
}
