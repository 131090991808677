import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/buttons/Button";
import useSettings from "../../../hooks/useSettings";
import { useHistory } from "react-router-dom";
import FileInput from "../../../components/inputs/FileInput";
import TextInput from "../../../components/inputs/TextInput";
import { isArray } from "../../../helpers/helper";
import { businessDocumentValidation } from "../../../validation/settings/settingsValidation";
import {
  submitBusinessDocuments
} from "../../../redux/settings/settingsActions";
import { getPersonalAndBusinessAccounts } from "../../../redux/auth/authActions";
export const BusinessDocumentNigeria = ({ setFormLevel, input, setInput }) => {
  const dispatch = useDispatch();
  const settings = useSettings();
  const [inputError, setInputError] = React.useState({});
  const history = useHistory();

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputError({ ...inputError, [e.target.name]: false });
  };
  const onSubmit = () => {
    // alert(settings.submitBusinessInformation.data?.compliance_id)
    let inputValid = businessDocumentValidation(input);
    if (isArray(inputValid)) {
      inputValid.forEach((field) => {
        setInputError((prev) => ({ ...prev, [field]: true }));
      });
    } else {
      // Handle Submission
      // Payload 
      let reqPayLoad;
      // compliance id
      let id = isArray(settings.submitBusinessInformation) ?
        settings.submitBusinessInformation[0]['id']
        : settings.submitBusinessInformation.data?.compliance_id;

      if (input?.business_type?.toLowerCase() === "sole propreitorship") {
        reqPayLoad = {
          tin_number: input?.tin,
          bn_number: input?.bn_number,
          tin_certificate: input?.tin_certificate[0],
          bn_certificate: input?.business_name_certificate[0],
          director_page: input?.business_directors_document[0],
        }
      }

      if (input?.business_type?.toLowerCase() === "ngo") {
        reqPayLoad = {
          tin_number: input?.tin,
          rc_number: input?.rc_number,
          tin_certificate: input?.tin_certificate[0],
          cac_certificate: input?.certificate_of_incorporation[0],
          director_page: input?.business_directors_document[0],
          scuml: input?.scuml[0],
          cac_it_form_1: input?.cac_it_form[0],
        }
      }

      if (input?.business_type?.toLowerCase() === "partnership") {
        reqPayLoad = {
          tin_number: input?.tin,
          rc_number: input?.rc_number,
          tin_certificate: input?.tin_certificate[0],
          cac_certificate: input?.certificate_of_incorporation[0],
          director_page: input?.business_directors_document[0],
        }
      }

      if (input?.business_type?.toLowerCase() === "llc") {
        reqPayLoad = {
          tin_number: input?.tin,
          rc_number: input?.rc_number,
          tin_certificate: input?.tin_certificate[0],
          cac_certificate: input?.certificate_of_incorporation[0],
          director_page: input?.business_directors_document[0],
        }
      }

      dispatch(
        submitBusinessDocuments({ reqPayLoad, id }, () => { dispatch(getPersonalAndBusinessAccounts()); history.push('/dashboard/home'); })
      )

    }
  };
  return (
    <>
      <div className="px-12">
        <h3 className="font-bold mb-5 text-glade-black-800">
          Upload Documents
        </h3>
        <p>Please upload all documents</p>
      </div>
      <div className="w-full space-y-6 border-b border-gray-300 px-12 pb-8">
        <div>
          <TextInput
            label={"Tax Identification Number (TIN)"}
            id={"tin"}
            name={"tin"}
            value={input?.tin ?? ""}
            onChange={onChange}
            placeholder={"Enter TIN"}
            autoComplete={"tin"}
            className={"appearance-none"}
            errorState={inputError?.tin}
          />
          {inputError.tin && (
            <div className="text-glade-red-400 text-sm mt-2">
              TIN is a required field
            </div>
          )}
        </div>

        <div>
          <FileInput
            label={"Upload TIN Certificate"}
            id={"tin_certificate"}
            name={"tin_certificate"}
            multiple={true}
            accept=".png, .jpeg, .pdf, .jpg"
            maximumSize={3}
            returnInput={(value) =>
              setInput({ ...input, tin_certificate: value })
            }
            errorState={inputError?.tin_certificate}
          />
          {inputError.tin_certificate && (
            <div className="text-glade-red-400 text-sm mt-2">
              Upload a valid format (JPG, PNG, PDF)
            </div>
          )}
          <span className="glade-small-text-one glade-grey-cool block">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.
          </span>
        </div>
        {/* RC Number for only LCC, Partnership, NGO */}
        {input?.business_type?.toLowerCase() !== "sole propreitorship" ? (
          <div>
            <TextInput
              label={"RC Number"}
              id={"rc_number"}
              name={"rc_number"}
              value={input?.rc_number ?? ""}
              onChange={onChange}
              placeholder={"Enter RC Number"}
              autoComplete={"rc_number"}
              className={"appearance-none"}
              errorState={inputError?.rc_number}
            />
            {inputError?.rc_number && (
              <div className="text-glade-red-400 text-sm mt-2">
                RC Number is a required field
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {/* Fields for sole proprietorship only */}
        {input?.business_type?.toLowerCase() === "sole propreitorship" && (
          <>
            <div>
              <TextInput
                label={"BN Number"}
                id={"bn_number"}
                name={"bn_number"}
                value={input?.bn_number ?? ""}
                onChange={onChange}
                placeholder={"Enter BN Number"}
                autoComplete={"bn_number"}
                className={"appearance-none"}
                errorState={inputError?.bn_number}
              />
              {inputError?.bn_number && (
                <div className="text-glade-red-400 text-sm mt-2">
                  Invalid BN Number
                </div>
              )}
            </div>

            <div>
              <FileInput
                label={"Business Name Certificate"}
                id={"business_name_certificate"}
                name={"business_name_certificate"}
                multiple={true}
                accept=".png, .jpeg, .pdf, .jpg"
                maximumSize={3}
                returnInput={(value) =>
                  setInput({ ...input, business_name_certificate: value })
                }
                errorState={inputError?.business_name_certificate}
              />
              {inputError?.business_name_certificate && (
                <div className="text-glade-red-400 text-sm mt-2">
                  Upload a valid format (JPG, PNG, PDF)
                </div>
              )}
              <span className="glade-small-text-one glade-grey-cool block">
                Supported file types: PDF, PNG & JPEG. Max file size allowed is
                3MB.
              </span>
            </div>
          </>
        )}

        {input.business_type?.toLowerCase() !== "sole propreitorship" && (
          <div>
            <FileInput
              label={"Certificate of Incorporation"}
              id={"certificate_of_incorporation"}
              name={"certificate_of_incorporation"}
              multiple={true}
              accept=".png, .jpeg, .pdf, .jpg"
              maximumSize={3}
              returnInput={(value) =>
                setInput({ ...input, certificate_of_incorporation: value })
              }
              errorState={inputError?.certificate_of_incorporation}
            />
            {inputError?.certificate_of_incorporation && (
              <div className="text-glade-red-400 text-sm mt-2">
                Upload a valid format (JPG, PNG, PDF)
              </div>
            )}
            <span className="glade-small-text-one glade-grey-cool block">
              Supported file types: PDF, PNG & JPEG. Max file size allowed is
              3MB.
            </span>
          </div>
        )}

        <div>
          <FileInput
            label={"Business Directors Document"}
            id={"business_directors_document"}
            name={"business_directors_document"}
            multiple={true}
            accept=".png, .jpeg, .pdf, .jpg"
            maximumSize={3}
            returnInput={(value) =>
              setInput({ ...input, business_directors_document: value })
            }
            errorState={inputError?.business_directors_document}
          />
          {inputError?.business_directors_document && (
            <div className="text-glade-red-400 text-sm mt-2">
              Upload a valid format (JPG, PNG, PDF)
            </div>
          )}
          <span className="glade-small-text-one glade-grey-cool block">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.
          </span>
        </div>

        {/* Fields for NGO */}
        {input?.business_type?.toLowerCase() === "ngo" && (
          <>
            <div>
              <FileInput
                label={"SCUML"}
                id={"scuml"}
                name={"scuml"}
                multiple={true}
                accept=".png, .jpeg, .pdf, .jpg"
                maximumSize={3}
                returnInput={(value) => setInput({ ...input, scuml: value })}
                errorState={inputError?.scuml}
              />
              {inputError?.scuml && (
                <div className="text-glade-red-400 text-sm mt-2">
                  Upload a valid format (JPG, PNG, PDF)
                </div>
              )}
              <span className="glade-small-text-one glade-grey-cool block">
                Supported file types: PDF, PNG & JPEG. Max file size allowed is
                3MB.
              </span>
            </div>
            <div>
              <FileInput
                label={"CAC IT Form 1"}
                id={"cac_it_form"}
                name={"cac_it_form"}
                multiple={true}
                accept=".png, .jpeg, .pdf, .jpg"
                maximumSize={3}
                returnInput={(value) =>
                  setInput({ ...input, cac_it_form: value })
                }
                errorState={inputError?.cac_it_form}
              />
              {inputError?.cac_it_form && (
                <div className="text-glade-red-400 text-sm mt-2">
                  Upload a valid format (JPG, PNG, PDF)
                </div>
              )}
              <span className="glade-small-text-one glade-grey-cool block">
                Supported file types: PDF, PNG & JPEG. Max file size allowed is
                3MB.
              </span>
            </div>
          </>
        )}
      </div>
      <div className="p-6">
        <div className="w-1/3 md:w-1/4 float-left ">
          <Button.Transparent
            onClick={() => setFormLevel("owner information")}
            className="w-full"
            title={"Back"}
          />
        </div>
        <div className="w-1/3 md:w-1/4 float-right">
          <Button.Blue onClick={onSubmit} className="w-full" title={"Next"} />
        </div>
      </div>
    </>
  );
};
