import React from 'react';

export default function SearchSelectInput({
    label, 
    type, 
    name, 
    id, 
    value, 
    onChange=()=>{}, 
    placeholder, 
    autoComplete,
    disabled=false, 
    className, 
    children,
}) {

    const listId = `${Math.random()}-list`;
    const onInteraction = (e) => {
        let list_id = e.target.getAttribute('list');
        let list = document.getElementById(list_id);
        let selectedOption = list?.options?.namedItem(e.target.value);
        let selectedOptionData = selectedOption?.getAttribute('data');

        if (selectedOption) {
            e.target.data = selectedOptionData;
            onChange(e);
        } else {
            e.target.data = undefined;
            onChange(e);
        }
    }

    return (
        <div className="space-y-1 col-span-6 sm:col-span-3">
            <label htmlFor={name??'name'} className="block glade-small-text-two glade-black">
                {label??''}
            </label>
            <input
                list={listId}
                type={type??"text"}
                name={name??'name'}
                id={id??name??'id'}
                value={value??''}
                onChange={onInteraction}
                disabled={disabled}
                className={className + " focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"}
                placeholder={placeholder??''}
                autoComplete={autoComplete??''}
                data={undefined}
            />
            <datalist id={listId}>
                {children}
            </datalist>
        </div>
    )
}
