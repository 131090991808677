import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import {CardPlainWithButton, CardPlainWithButtonSection} from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import useFundTransfer from '../../hooks/useFundTransfer';
import {AiFillFileAdd} from 'react-icons/ai';
import {rememberBulkFundTransferList, getBanks} from '../../redux/fund-transfer/fundTransferActions';
import {downloadSampleBulkFundTransferCSV, downloadBankCodesCSV, processUploadedBulkFundTransferCSV} from './UploadBulkFundTransferAsCSV';
import useTeam from '../../hooks/useTeam';

export default function FundTransferBulkFileUpload() {

    const history = useHistory();
    const dispatch = useDispatch();
    const fundTransfer = useFundTransfer();
    const {permissions} = useTeam();

    React.useEffect(() => {
        dispatch(getBanks({ mode: "external" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async () => {
        if (input.files[0]) {

            let data = await processUploadedBulkFundTransferCSV(input.files[0]);
            
            if (data?.length > 0) {
                data = data.map((item) => {
                    return {
                        "note": item.note,
                        "id": item.id,
                        "amount": item.amount,
                        "account_name": item.account_name,
                        "bank_code": item.bank_code,
                        "account_number": item.account_number,
                        "bank_name": getBankNameWithCode(item.bank_code) ?? ""
                    }
                });
                dispatch(rememberBulkFundTransferList(data,()=>history.push(!permissions?.includes("fund transfer") ? "/dashboard/fund-transfer/bulk-transfer/review" : "/teams/fund-transfer/bulk-transfer/review")));
            }
        }
    }

    const getBankNameWithCode = (code) => {
        const bank = fundTransfer.banks?.filter(item => {
             return Number(item.bank_code) == Number(code);
        });
        return bank[0]?.bank_name ?? "";
    }

    const [input, setInput] = React.useState({files:[]});
    const handleFileInput = (e) => {
        const newFiles = [...input.files, ...e.target.files];
        setInput({files:newFiles});
    }

    return (
        <div className="p-3">
            <div className="flex flex-col">
                <div className="mb-6">
                    <BreadCrumbs title={'Fund Transfer'} path={!permissions?.includes("fund transfer") ? '/dashboard/fund-transfer' : "/teams/fund-transfer"}/>
                </div>

                <CardPlainWithButton type={'single'}>
                    <CardPlainWithButtonSection 
                        title={'Bulk Transfer'} 
                        type={'single'}
                        loading={fundTransfer.isLoading}
                        buttonTitle={'Process Uploaded File'}
                        buttonOnClick={onSubmit}
                        buttonDisabled={input.files.length === 0 }>
                        <div className="mt-9 px-6">
                            <div className="space-y-6">
                                <div>
                                    <div className="flex flex-col items-center my-3 py-16 glade-bg-blue-100">
                                        <AiFillFileAdd size={50} color={'#1634A1'} title={'Manual Upload'} />
                                        <h5 className="mt-3 text-center glade-blue">Drag and drop or upload a .csv or .xlsx file</h5>
                                        <div className="filter drop-shadow-lg mt-3 p-2 bg-white cursor-pointer rounded-md">
                                            <label htmlFor="file-upload" className="cursor-pointer">
                                                <span className="glade-normal-text-three glade-black">{input.files.length > 0 ? 'Replace File' : 'Choose File'}</span>
                                                <input id="file-upload" name="file-upload" type="file" onChange={handleFileInput} className="sr-only" />
                                            </label>
                                        </div>
                                    </div>
                                    <h3 className="text-sm">
                                        <span className="mr-1 glade-black cursor-pointer">Maximum file size is 3MB. </span>
                                        <span className="mr-1 glade-blue cursor-pointer" onClick={()=>downloadSampleBulkFundTransferCSV()}>see a sample .csv file </span>
                                        <span className="mr-1 glade-black cursor-pointer">or </span>
                                        <span className="mr-1 glade-blue cursor-pointer" onClick={()=>downloadBankCodesCSV(fundTransfer.banks)}>see list of bank codes.</span> 
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </CardPlainWithButtonSection>
                </CardPlainWithButton>
            </div>
        </div>
    )
}
