import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Button from "../../components/buttons/Button";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import { TransferFormContext } from "../../context/TransferFormContext";
import { removeCommas } from "../../helpers/helper";
import InputGroup from "../../components/transfer/InputGroup";
import ChargeInfo from "../../components/transfer/ChargeInfo";
import { useDispatch } from "react-redux";
import {
  getInternationalTransferBankBranches,
  getInternationalTransferBanks,
  getInternationalTransferMethods,
  getInternationalTransferRate,
  getBanksForTransfer,
} from "../../redux/fund-transfer/fundTransferActions";
import useFundTransfer from "../../hooks/useFundTransfer";
import useUser from "../../hooks/useUser";
import FormBuilder from "../../components/form/FormBuilder";
import { InternationalNonNairaTransferFormEnum } from "../../helpers/TransferFormFieldsHelper";
import { validateLocalTransferForm } from "../../helpers/validator";
import {
  getApprovedCountries,
  getApprovedStates,
} from "../../redux/countries/countriesActions";

const TransferInternationalForm2 = () => {
  const dispatch = useDispatch();

  const {
    availableBalance,
    setAvailableBalance,
    transferForm,
    setTransferForm,
    setFormStage,
    transferCharge,
    setTransferCharge,
    accountTypes,
    currencies,
    outsideAfrica,
  } = useContext(TransferFormContext);

  const { internationalTransferRate, internationalCountries } =
    useFundTransfer();

  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [recipientAccountType, setRecipientAccountType] =
    useState("individual");
  const [formErrors] = useState({});
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfStates, setListOfStates] = useState([]);
  const { accountInfo } = useUser();
  const [transferMethod, setTransferMethod] = useState([]);
  const [selectedTransferMethod, setSelectedTransferMethod] = useState("");
  const [transferProvider, setTransferProvider] = useState([]);
  const [transferBranches, setTransferBranches] = useState([]);

  useEffect(() => {
    listOfCountries?.length < 1 &&
      dispatch(
        getApprovedCountries({ status: 1 }, (res) =>
          setListOfCountries(res.data.data)
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfCountries]);

  useEffect(() => {
    transferForm?.beneficiary_country_id &&
      dispatch(
        getApprovedStates(
          { country_id: transferForm.beneficiary_country_id },
          (res) => setListOfStates(res.data.data)
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferForm?.beneficiary_country_id]);

  useEffect(() => {
    toDisableButton(outsideAfrica, currencies?.selectedCurrency?.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outsideAfrica, currencies?.selectedCurrency?.currency]);

  useEffect(() => {
    setTransferCharge(internationalTransferRate?.fee);
  }, [dispatch, accountInfo, setAvailableBalance, setTransferCharge]);

  useEffect(() => {
    dispatch(
      getInternationalTransferMethods(
        { country_code: currencies?.selectedCurrency?.code },
        (data) => setTransferMethod(data)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies]);

  useEffect(() => {
    dispatch(
      getInternationalTransferRate({
        amount: removeCommas(transferForm?.amount, true) || 1,
        source_currency: currencies?.activeCurrency?.currency,
        destination_currency: currencies?.selectedCurrency?.currency ?? "USD",
        destination_country:
          currencies?.selectedCurrency?.currency?.slice(0, -1) ?? "US",
        transfer_method: transferForm?.transfer_method || "bank",
      })
    );
  }, [
    currencies?.selectedCurrency?.currency,
    transferForm?.transfer_method,
    transferMethod?.selected?.code,
    dispatch,
    currencies?.activeCurrency?.currency,
    transferForm?.amount,
  ]);

  const africanFields =
    transferForm.amount === "0" ||
    (transferForm.transfer_method === "account" &&
      !transferForm?.beneficiary_account_number) ||
    (transferForm.transfer_method === "mobilemoney" &&
      !transferForm?.beneficiary_phone) ||
    !transferForm?.beneficiary_bank_name ||
    !transferForm?.beneficiary_email ||
    !transferForm?.transfer_method ||
    !transferForm?.beneficiary_name;

  const isFormNotComplete =
    !Number(String(transferForm?.amount).replaceAll(",", "")) ||
    !transferForm?.beneficiary_bank_name ||
    !transferForm?.beneficiary_email ||
    !transferForm?.beneficiary_account_number ||
    !transferForm?.beneficiary_phone ||
    !transferForm?.beneficiary_account_type ||
    !transferForm?.beneficiary_country ||
    !transferForm?.beneficiary_address ||
    !transferForm?.beneficiary_email ||
    !transferForm?.beneficiary_account_number ||
    (recipientAccountType === "corporate" && !transferForm?.beneficiary_name) ||
    (recipientAccountType === "individual" &&
      (!transferForm?.beneficiary_first_name ||
        !transferForm?.beneficiary_last_name)) ||
    !transferForm?.beneficiary_country ||
    !transferForm?.beneficiary_address ||
    !transferForm?.beneficiary_phone ||
    !transferForm?.beneficiary_state ||
    !transferForm?.beneficiary_city ||
    !transferForm?.beneficiary_zip_code ||
    !transferForm?.narration ||
    !transferForm?.beneficiary_sort_code ||
    !transferForm?.beneficiary_swift_code;

  const southAfricanFields =
    !transferForm.amount ||
    !transferForm.transfer_method ||
    !transferForm.beneficiary_account_number;

  function reviewTransferDetails() {
    setFormStage("review");
  }

  const toDisableButton = (countryCodes = [], code) => {
    if (countryCodes.includes(code)) {
      return isFormNotComplete;
    } else if (code === "ZA") {
      return southAfricanFields;
    } else {
      return africanFields;
    }
  };

  const enoughFunds =
    Number(parseFloat(removeCommas(transferForm?.amount))) <
    parseFloat(availableBalance);

  const disabled = () => {
    //Get data for validation
    let dynamicFormData = {};
    InternationalNonNairaTransferFormEnum[
      `${currencies?.selectedCurrency?.currency}`
    ] &&
      InternationalNonNairaTransferFormEnum[
        `${currencies?.selectedCurrency?.currency}`
      ]["formList"].map(
        (name) => (dynamicFormData[name?.id] = transferForm[name?.id])
      );

    // Run validation
    let result = validateLocalTransferForm(
      { ...dynamicFormData, amount: transferForm?.amount },
      transferForm
    );

    // Enable or disable button
    return result?.length > 0 ||
      // || (!transferCharge) disabled fees charge because it can be set to zero at times
      Number(parseFloat(removeCommas(transferForm?.amount))) +
        Number(parseFloat(transferCharge)) >
        parseFloat(availableBalance) // Check for if fund is enough
      ? true
      : false;
  };

  const onTransferMethodSelect = (method, callback) => {
    setTransferForm((form) => ({
      ...form,
      transfer_method: method.code,
    }));
    setSelectedTransferMethod(method);
    if (method.code === "mobilemoney") {
      dispatch(
        getInternationalTransferBanks(
          {
            country_code: currencies?.selectedCurrency?.currency?.slice(0, -1),
            method_code: method?.code,
          },
          (providers) => {
            setTransferProvider((_providers) => ({
              ..._providers,
              providers: providers,
              selected: null,
            }));
            setTransferBranches({});
          }
        )
      );
    }
    if (method.code === "account") {
      dispatch(
        getBanksForTransfer(
          currencies?.selectedCurrency?.currency?.slice(0, -1),
          (providers) => {
            setTransferProvider((_providers) => ({
              ..._providers,
              providers: providers,
              selected: null,
            }));
            setTransferBranches({});
          }
        )
      );
    }
    callback();
  };

useEffect(()=>{
  setTransferForm((form) => ({
    ...form,
    transfer_method: 'bank',
  }));
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  const onProviderSelect = (method, callback) => {
    setTransferForm((form) => ({
      ...form,
      provider: method.code,
      beneficiary_bank: method?.name
    }));


    setTransferProvider((_providers) => ({ ..._providers, selected: method }));
    if (method?.id) {
      dispatch(
        getInternationalTransferBankBranches(
          {
            country_code: currencies?.selectedCurrency?.currency?.slice(0, -1),
            bank_id: method.id,
          },
          (branches) => {
            setTransferBranches((_branches) => ({
              ..._branches,
              branches: branches,
              selected: null,
            }));
          }
        )
      );
    }
    callback();
  };

  const onBranchSelect = (method, callback) => {
    setTransferForm((form) => ({
      ...form,
      branch: method.branch_code,
    }));
    setTransferBranches((_branches) => ({ ..._branches, selected: method }));
    callback();
  };

  const onCountrySelect = (country, callback) => {
    setTransferForm((form) => ({
      ...form,
      beneficiary_country: country.country_name,
      beneficiary_state: "",
      beneficiary_country_id: country.country_id,
    }));
    callback();
  };
  const onStateSelect = (state, callback) => {
    setTransferForm((form) => ({
      ...form,
      beneficiary_state: state.state_name,
    }));
    callback();
  };

  const checkForBranch = (countries = [], currency) => {
    const country = countries?.filter(
      (country) => country?.code === currency?.code
    );
    return country[0]?.has_branch ? true : false;
  };

  useEffect(() => {
    setRecipientAccountType("individual");
    setTransferForm((form) => ({
      ...form,
      beneficiary_account_type: "individual",
    }));
  }, []);

  const onAccountTypeSelect = (type) => {
    setRecipientAccountType(type);
    setTransferForm((form) => ({ ...form, beneficiary_account_type: type }));
  };

  const toggleSaveBeneficiary = () => {
    setTransferForm((form) => ({
      ...form,
      save_beneficiary: !saveBeneficiary,
    }));
    setSaveBeneficiary((saveBeneficiary) => !saveBeneficiary);
  };

  return (
    <div className="mb-8">
      <FormBuilder
        formList={
          InternationalNonNairaTransferFormEnum[
            `${currencies?.selectedCurrency?.currency}`
          ]?.formList ?? []
        }
        setFormStage={setFormStage}
        recipientAccountType={recipientAccountType}
        accountTypes={accountTypes}
        onAccountTypeSelect={onAccountTypeSelect}
        transferForm={transferForm}
        listOfCountries={listOfCountries}
        listOfStates={listOfStates}
        onCountrySelect={onCountrySelect}
        onStateSelect={onStateSelect}
        transferMethod={transferMethod}
        onTransferMethodSelect={onTransferMethodSelect}
        selectedTransferMethod={selectedTransferMethod}
        transferProvider={transferProvider}
        onProviderSelect={onProviderSelect}
        transferBranches={transferBranches}
        onBranchSelect={onBranchSelect}
      />

      <InputGroup
        label={"Narration"}
        placeholder={"Transfer Description"}
        value={transferForm?.narration}
        name={"narration"}
      />

      <CheckboxInput
        label={"Save as beneficiary"}
        checked={saveBeneficiary}
        onChange={toggleSaveBeneficiary}
      />

      {transferForm.amount && transferForm.amount != 0 && (
        <ChargeInfo className={"text-white"} transferCharge={transferCharge} />
      )}
      <div className="flex justify-center">
        <Button.Blue
          disabled={disabled()}
          onClick={reviewTransferDetails}
          title={"Confirm"}
          className={"mt-6"}
        />
      </div>
    </div>
  );
};

export default TransferInternationalForm2;

export const LinkText = styled.span`
  color: #1634a1;
  cursor: pointer;
`;
