import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const storeUserInformationStepOneValidation = (payLoad, silently=false) => {

    let data = {
        first_name:payLoad.first_name,
        last_name:payLoad.last_name,
        phone_number:payLoad.phone_number,
    }
    
    let rules = {
        first_name:'required|minSize:1|maxSize:50',
        last_name:'required|minSize:1|maxSize:50',
        phone_number:'required|phone|minSize:9|maxSize:15',
    }
    let messages = {
        first_name:{
            required:'First name is required',
            minSize:'First name is too short',
            maxSize:'First name is too long'
        },
        last_name:{
            required:'Last name is required',
            minSize:'Last name is too short',
            maxSize:'Last name is too long'
        },
        phone_number:{
            required:'A phone number is required',
            phone:'Phone number is not valid',
            minSize:'Phone number is too short',
            maxSize:'Phone number is too long'
        }
    }
    if(payLoad?.country?.name?.toLocaleLowerCase() === 'nigeria'){
        data['bvn'] = payLoad?.bvn;
        rules['bvn'] ='required|minSize:10|maxSize:12';
        messages['bvn'] = {
            required:'Bvn is required',
            minSize:'BVN is not valid. Minimum 11 characters',
            maxSize:'BVN is not valid. Maximum 11 characters'
        };
    }
    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently; 
    });

    return Object.keys(result);
}

export const storeUserInformationStepTwoValidation = (payLoad, silently=false) => {

    let data = {
        passcode:payLoad.passcode,
        confirm_passcode:payLoad.confirm_passcode,
    }
    let rules = {
        passcode:'required|minSize:0|maxSize:5',
        confirm_passcode:'required|minSize:0|maxSize:5|match:passcode',
    }
    let messages = {
        passcode:{
            required:'A passcode is required',
            minSize:'Pin is not valid. Minimum 4 characters',
            maxSize:'Pin is not valid. Maximum 4 characters'
        },
        confirm_passcode:{
            required:'A passcode is required',
            minSize:'Pin is not valid. Minimum 4 characters',
            maxSize:'Pin is not valid. Maximum 4 characters',
            match:'Pin does not match'
        }
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}