import { useSelector } from "react-redux";

const useUser = () => {
  const user = useSelector((state) => state.auth);

  const {
    isLoading,
    isLoadingVerifyPin,
    isLoadingTeam,
    isLoadingUserTag,
    checkUserTag,
    userTag,
    isVerifyingQRCode,
    isGeneratingQRCode,
    isVerifyingUser,
    shouldReload,
    isBusiness,
    isBusinessUUID,
    user:userData,
    token,
    refresh_token,
    is_agent,
    teamUser,
    storeUserInfo,
    verifyUserEmail,
    resendEmailVerification,
    createPassCode,
    createTeamPassword,
    isLoadingBusinessPersonalAccounts,
    businessAndPersonalAccounts,
    selectedBusinessOrPersonalAccount,
    verifyUserPin,
    verifyQRCode,
    generatedQRCode,
    verifiedUser,
    hasCompliance,
    isOneBusinessLoading,
    shouldReloadBusinessPersonalAccounts,
    is2FAEnabled,
    singleBusinessDetails,
    isLoadingVerificationID,
    submitVerificationID,
    isLoadingMetaMapStatus
  } = user;
  
  return {
    isLoading,
    isLoadingTeam,
    isLoadingUserTag,
    userTag,
    checkUserTag,
    isVerifyingQRCode,
    isLoadingVerifyPin,
    isGeneratingQRCode,
    is2FAEnabled,
    isVerifyingUser,
    shouldReload,
    isBusiness,
    isBusinessUUID,
    token,
    is_agent,
    teamUser,
    createTeamPassword,
    refresh_token,
    storeUserInfo,
    verifyQRCode,
    verifyUserEmail,
    isOneBusinessLoading,
    resendEmailVerification,
    createPassCode,
    isLoadingBusinessPersonalAccounts,
    businessAccounts: businessAndPersonalAccounts?.business_accounts ?? [],
    personalAccounts: businessAndPersonalAccounts?.personal_accounts ?? [],
    selectedBusinessOrPersonalAccount,
    verifyUserPin,
    hasCompliance,
    generatedQRCode,
    shouldReloadBusinessPersonalAccounts,
    verifiedUser,
    userInfo: userData?.user,
    accountInfo: userData?.account,
    businessInfo: userData?.business,
    compliance: userData?.compliance_status,
    has_business_account: userData?.has_business_account,
    has_passcode: userData?.has_passcode,
    is_bvn_matched: userData?.is_bvn_matched,
    is_email_verified: userData?.is_email_verified,
    is_2fa_enabled: userData?.is_2fa_enabled,
    singleBusinessDetails,
    isLoadingVerificationID,
    submitVerificationID,
    isLoadingMetaMapStatus
  };
};

export default useUser;
