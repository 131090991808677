import React, { useState, useEffect } from "react";
import nigeria_flag from "../../assets/images/nigeria_flag.svg";
import britain_flag from "../../assets/images/britain_flag.svg";
import usa_flag from "../../assets/images/usa_flag.svg";
import euro_flag from "../../assets/images/euro_flag.svg";
import { FiCopy } from "react-icons/fi";
import {
  copyTextToClipboard,
  currencySymbolEnum,
  getSuppliedFieldValue,
} from "../../helpers/helper";
import useUser from "../../hooks/useUser";
import { toast } from "react-toastify";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { getAllWalletBalances } from "../../redux/balances/balancesActions";
import useBalances from "../../hooks/useBalances";
import useHome from "../../hooks/useHome";

const MainAccountBox = () => {
  const user = useUser();
  const home = useHome();
  const dispatch = useDispatch();
  const { balancesData, isLoadingBalances } = useBalances();
  const [selectedTab, setSelectedTab] = useState("balance");
  const [selectedInfo, setSelectedInfo] = useState("bank-name");
  const [selectedAccountDetails, setSelectedAccountDetails] =
    useState("account_1");
  useEffect(() => {
    if (user?.isBusiness) dispatch(getAllWalletBalances());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.shouldReload]);

  return (
    <>
      <div className="border-2 border-glade-gray-50 p-5 rounded-lg">
        <div>
          {isLoadingBalances ? (
            <div className="flex justify-center items-center">
              <RiLoader5Fill size={24} className="animate-spin ml-4" />
            </div>
          ) : (
            <React.Fragment>
              <div className="text-left glade-heading-two glade-home-dark">
                <span className="font-bold">
                  {currencySymbolEnum[
                    !user?.isBusiness
                      ? getSuppliedFieldValue("currency", user)
                      : balancesData?.currency
                  ] ?? "" + " "}
                  {!user.isBusiness
                    ? getSuppliedFieldValue("balance", user)
                      ? Number(
                        getSuppliedFieldValue("balance", user)
                      ).toLocaleString()
                      : "0.00"
                    : balancesData?.balance}
                </span>
              </div>
              <div className="flex justify-start items-center ">
                <span>
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "NGN") ||
                    (balancesData?.data?.currency === "NGN" && (
                      <img src={nigeria_flag} />
                    ))}
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "USD") ||
                    (balancesData?.data?.currency === "USD" && (
                      <img src={usa_flag} />
                    ))}
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "GBP") ||
                    (balancesData?.data?.currency === "GBP" && (
                      <img src={britain_flag} />
                    ))}
                  {(getSuppliedFieldValue("currency", user) &&
                    !user?.isBusiness === "EUR") ||
                    (balancesData?.data?.currency === "EUR" && (
                      <img src={euro_flag} />
                    ))}
                </span>
                <span className="glade-normal-text-four text-glade-gray-600 ">
                  Total Balance{" "}
                  {getSuppliedFieldValue("currency", user) && !user?.isBusiness
                    ? `in ${getSuppliedFieldValue("currency", user)}`
                    : `in ${balancesData?.currency}`}
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>

      <div className="border rounded-lg h-60">
        <div className="flex justify-center align-center gap-4 pt-5 border-b mb-6">
          <p
            className={`rounded capitalize cursor-pointer font-bold ${selectedTab !== "info"
                ? "border-b-4 glade-home-dark"
                : "glade-grey-cool"
              }`}
            onClick={() => setSelectedTab("balance")}
          >
            Balance
          </p>
          <p
            className={`rounded capitalize cursor-pointer font-bold ${selectedTab === "info"
                ? "border-b-4 glade-home-dark"
                : "glade-grey-cool"
              }`}
            onClick={() => setSelectedTab("info")}
          >
            Account Info
          </p>
        </div>
        {selectedTab !== "info" ? (
          <div>
            {isLoadingBalances ? (
              <div className="flex justify-center items-center mt-10">
                <RiLoader5Fill size={24} className="animate-spin ml-4" />
              </div>
            ) : (
              <React.Fragment>
                <div className="flex justify-center items-center mt-10">
                  <span>
                    {(getSuppliedFieldValue("currency", user) &&
                      !user?.isBusiness === "NGN") ||
                      (balancesData?.data?.currency === "NGN" && (
                        <img src={nigeria_flag} />
                      ))}
                    {(getSuppliedFieldValue("currency", user) &&
                      !user?.isBusiness === "USD") ||
                      (balancesData?.data?.currency === "USD" && (
                        <img src={usa_flag} />
                      ))}
                    {(getSuppliedFieldValue("currency", user) &&
                      !user?.isBusiness === "GBP") ||
                      (balancesData?.data?.currency === "GBP" && (
                        <img src={britain_flag} />
                      ))}
                    {(getSuppliedFieldValue("currency", user) &&
                      !user?.isBusiness === "EUR") ||
                      (balancesData?.data?.currency === "EUR" && (
                        <img src={euro_flag} />
                      ))}
                  </span>

                  <span className="glade-normal-text-four glade-home-dark ml-2">
                    Total Balance{" "}
                    {getSuppliedFieldValue("currency", user) && !user?.isBusiness
                      ? `in ${getSuppliedFieldValue("currency", user)}`
                      : `in ${balancesData?.currency ?? balancesData?.data?.currency}`}
                  </span>
                </div>
                <div className="text-center glade-heading-two mt-4 glade-home-dark">
                  <span className="font-bold">
                    {currencySymbolEnum[
                      !user?.isBusiness
                        ? getSuppliedFieldValue("currency", user)
                        : balancesData?.currency ?? balancesData?.data?.currency
                    ] ?? "" + " "}
                    {!user.isBusiness
                      ? getSuppliedFieldValue("balance", user)
                        ? Number(
                          getSuppliedFieldValue("balance", user)
                        ).toLocaleString()
                        : "0.00"
                      : balancesData?.balance ?? balancesData?.data?.balance}
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div className="px-4">
            {/* Tabs for account details */}
            <div className="grid grid-cols-2 bg-glade-gray-50 p-1 rounded text-xs text-glade-black-700">
              <div
                className={`text-center py-1 cursor-pointer ${selectedAccountDetails === "account_1" ? "bg-white rounded" : ""
                  } `}
                onClick={() => setSelectedAccountDetails("account_1")}
              >
                Account 1
              </div>
              <div
                className={`text-center py-1 cursor-pointer ${selectedAccountDetails === "account_2" ? "bg-white rounded" : ""
                  } `}
                onClick={() => setSelectedAccountDetails("account_2")}
              >
                Account 2
              </div>
            </div>

            {selectedAccountDetails === "account_1" && (
              <AccountTab
                selectedInfo={selectedInfo}
                user={user}
                details={
                  user?.isBusiness
                    ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                    home.oneBusinessAccount?.naira_accounts?.accounts[0]
                    : user?.accountInfo?.accounts &&
                    user?.accountInfo?.accounts[0]
                }
              />
            )}

            {selectedAccountDetails === "account_2" && (
              <AccountTab
                selectedInfo={selectedInfo}
                user={user}
                details={
                  user?.isBusiness
                    ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                    home.oneBusinessAccount?.naira_accounts?.accounts[1]
                    : user?.accountInfo?.accounts &&
                    user?.accountInfo?.accounts[1]
                }
              />
            )}
          </div>
        )}
      </div>
    </>

  );
};

export const AccountTab = ({ details, selectedInfo, user }) => {
  return (
    <div className="flex pt-4">
      <div className=" pr-3 border-dashed border-r-2 w-5/12 text-sm">
        <div className={`cursor-pointer font-normal pb-3 glade-grey-cool`}>
          Bank Name
        </div>
        <div
          className={`cursor-pointer font-normal pb-3 ${selectedInfo === "account-name"
              ? "glade-home-dark"
              : "glade-grey-cool"
            }`}
        >
          Account Name
        </div>
        <div
          className={`cursor-pointer font-normal pb-3 ${selectedInfo === "account-number"
              ? "glade-home-dark"
              : "glade-grey-cool"
            }`}
        >
          Account Number
        </div>
      </div>
      {selectedInfo === "bank-name" && (
        <div className="p-4 pt-0 w-7/12">
          {details?.bank_name === "pending" ? (
            <div
              className="flex items-center justify-center p-2 rounded-3xl"
              style={{ backgroundColor: "rgba(204, 183, 0, 0.1)" }}
            >
              <span className="glade-yellow">Pending Approval</span>
            </div>
          ) : (
            <div>
              <div className="flex justify-between pb-2">
                <p
                  className={`${details?.bank_name === "pending"
                      ? "rounded-3xl glade-yellow h-4/12 glade-bg-blue"
                      : "font-bold glade-home-dark capitalize"
                    }`}
                >
                  {details?.bank_name.length
                    ? details?.bank_name
                    : "Not Available"}
                </p>
                <p>
                  <FiCopy
                    size={20}
                    className="ml-3 cursor-pointer glade-grey-dark"
                    onClick={() => {
                      copyTextToClipboard(details?.bank_name);
                      toast.success("Copied to clipboard!", {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored",
                      });
                    }}
                  />
                </p>
              </div>
              <div className="flex justify-between pb-3">
                <p className="font-bold glade-home-dark capitalize">
                  {details?.account_name
                    ? details?.account_name
                    : "Not Available"}
                </p>
                <p>
                  <FiCopy
                    size={20}
                    className="ml-3 cursor-pointer glade-grey-dark"
                    onClick={() => {
                      copyTextToClipboard(details?.account_name);
                      toast.success("Copied to clipboard!", {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored",
                      });
                    }}
                  />
                </p>
              </div>
              <div className="flex justify-between pb-3">
                <p className="font-bold glade-home-dark">
                  {details?.account_number
                    ? details?.account_number
                    : "Not Available"}
                </p>
                <p>
                  <FiCopy
                    size={20}
                    className="ml-3 cursor-pointer glade-grey-dark"
                    onClick={() => {
                      copyTextToClipboard(details?.account_number);
                      toast.success("Copied to clipboard!", {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored",
                      });
                    }}
                  />
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      {/* {selectedInfo === "account-name" && (
        <div className="p-4 pt-2 w-8/12">
          {getSuppliedFieldValue("account_name", user) === "pending" ? (
            <div
              className="flex items-center justify-center p-2 rounded-3xl"
              style={{ backgroundColor: "rgba(204, 183, 0, 0.1)" }}
            >
              <span className="glade-yellow">Pending Approval</span>
            </div>
          ) : (
            <div className="flex justify-between">
              <p className="font-bold glade-home-dark">
                {getSuppliedFieldValue("account_name", user)
                  ? getSuppliedFieldValue("account_name", user)
                  : "Not Available"}
              </p>
              <p>
                <FiCopy
                  size={20}
                  className="ml-3 cursor-pointer glade-grey-dark"
                  onClick={() => {
                    copyTextToClipboard(
                      getSuppliedFieldValue("account_name", user)
                    );
                    toast.success("Copied to clipboard!", {
                      position: toast.POSITION.TOP_RIGHT,
                      theme: "colored",
                    });
                  }}
                />
              </p>
            </div>
          )}
        </div>
      )} */}
      {selectedInfo === "account-number" && (
        <div className="p-4 pt-2 w-8/12">
          {getSuppliedFieldValue("account_number", user) === "pending" ? (
            <div
              className="flex items-center justify-center p-2 rounded-3xl"
              style={{ backgroundColor: "rgba(204, 183, 0, 0.1)" }}
            >
              <span className="glade-yellow">Pending Approval</span>
            </div>
          ) : (
            <div className="flex justify-between">
              <p className="font-bold glade-home-dark">
                {getSuppliedFieldValue("account_number", user)
                  ? getSuppliedFieldValue("account_number", user)
                  : "Not Available"}
              </p>
              <p>
                <FiCopy
                  size={20}
                  className="ml-3 cursor-pointer glade-grey-dark"
                  onClick={() => {
                    copyTextToClipboard(
                      getSuppliedFieldValue("account_number", user)
                    );
                    toast.success("Copied to clipboard!", {
                      position: toast.POSITION.TOP_RIGHT,
                      theme: "colored",
                    });
                  }}
                />
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default MainAccountBox;
