import { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import InputGroup from "./InputGroup";
import TextInput from "../inputs/TextInput";
import { IoIosClose } from "react-icons/io";
// import useOnClickOutside from "../../hooks/useOnClickOutside";

const Dropdown = ({
  selected,
  accessor,
  label,
  items = [],
  onSelect,
  children,
  placeholder,
  emptyMessage
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [itemsDuplicate, setItemsDuplicate] = useState([]);

  useEffect(() => {
    setItemsDuplicate(items);
  }, [items])

  const toggleDropdown = () => {
    setIsOpen(open => !open);
  };

  const closeDropdown = () => setIsOpen(false);
  const filterDropdownList = (e) => {
    setFilterValue(e.target.value);
    setItemsDuplicate(items);

    let filteredArray = [];
    if (e.target.value) {
      itemsDuplicate.forEach((filter, i) => {
        if (e.target.value && filter[accessor].toLowerCase().includes(e.target.value.toLowerCase())) {
          filteredArray.push(itemsDuplicate[i]);
        }
        else if (e.target.value && !filter[accessor].toUpperCase().indexOf(e.target.value.toUpperCase() < 0) > -1) {
          // return setItemsDuplicate([]);
        }
        setItemsDuplicate(filteredArray)

      })
    } else {
      return setItemsDuplicate(items);
    }
  }

  const dropdownVariants = {
    open: {
      opacity: 1,
      height: "auto"
    },
    closed: {
      opacity: 0,
      height: 0,
    }
  };

  return (
    <Div className="mb-10">
      {label && <label className="font-bold mb-4">{label}</label>}
      <DropdownContainer>
        <DropdownHeader onClick={toggleDropdown} isOpen={isOpen}>
          {(selected?.name || selected?.branch_name) || <span className="unselected">{placeholder}</span>}
          <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
        </DropdownHeader>
        <AnimatePresence>
          {isOpen && (
            <Container
              animate="open"
              initial="closed"
              exit="closed"
              variants={dropdownVariants}
            >
              <ListContainer isOpen={isOpen}>
                {items.length !== 0 ? items?.map((item, key) =>
                  <ListItem onClick={() => onSelect(item, closeDropdown)} key={key}>
                    {item[accessor]}
                  </ListItem>)
                  : (
                    <EmptySection>
                      <h2>{emptyMessage}</h2>
                    </EmptySection>
                  )}
              </ListContainer>
            </Container>
          )}
        </AnimatePresence>
      </DropdownContainer>
      {children}
    </Div>
  );
};

export default Dropdown;

const Div = styled.div`  
`;

const EmptySection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  top: 0; 
  bottom: 0;
  height: 100%;
`;

const Container = styled(motion.div)`
  // width: 100%;
  position: absolute;
  top: 1.2rem;
  background-color: #fff;
  z-index: 100;
`;

const DropdownContainer = styled.div`
  margin-top: .5rem;
  position: relative;
`;

const SearchBox = styled.div`
  input {
    border-radius: 5px;
    border: none;
    outline: 2px solid gainsboro;
    width: 445px;
    margin: 0;

    &:focus {
      border: none;
      outline-color: #1634A1;
    }

    &::placeholder {
      color: gainsboro;
    }
  }
`;

const DropdownHeader = styled.div`
  margin-bottom: .8rem;
  padding: 1rem;
  // box-shadow: 0 2px 3px rgba(0, 0, 0, .15);
  color: #333;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E4E4F3;
  border-radius: 5px;
  gap: .5rem;
  cursor: pointer;
  box-shadow: ${({ isOpen }) => isOpen ? "0px 4px 10px rgba(3, 32, 134, 0.1)" : "none"};
  background: #fff;

  .unselected {
    color: #9CA3AF;
  }
`;

const ListContainer = styled.ul`
  margin-top: 2rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  top: 41%;
  width: 393px;
  left: 0;
  
  : 0;
  min-height: auto;
  max-height: 150px;
  background: #fff;
  border: 1px solid gainsboro;
  border-radius: 5px;
  box-shadow: ${({ isOpen }) => isOpen ? "0px 4px 10px rgba(3, 32, 134, 0.1)" : "none"};
  overflow-y: auto;
  `;

const ListItem = styled.li`
  // outline: 2px solid blue;
  list-style: none;
  padding: .5rem .8rem;
  display: flex;
  align-itens: center;
  gap: .5rem;
  // margin-bottom: .8rem;
  cursor: pointer;
  transition: 200ms ease-in color 200ms ease-in background;

  // &:first-child {
  //     padding-top: 4rem;
  //   }

  &:hover {
    color: #1634A1;
    background: whitesmoke;
  }
`;