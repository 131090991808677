import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { addHours, formatDistanceToNowStrict } from "date-fns";

const NotificationDetails = ({ item, setNotificationDetails }) => {

  function isNumber(value) {
    if (typeof value === "string") {
      return !isNaN(value);
    } else {
      return typeof value === "number";
    }
  }

  function rearrangeObjectKeys(obj) {
    const keys = Object.keys(obj);
    const numericKeys = keys.filter(key => isNumber(key) && key !== '0');
    const nonNumericKeys = keys.filter(key => !isNumber(key) && key !== '0');
  
    numericKeys.sort((a, b) => a - b);
  
    const rearrangedKeys = ['0', ...nonNumericKeys, ...numericKeys];
  
    const rearrangedObj = {};
    rearrangedKeys.forEach(key => {
      rearrangedObj[' ' + key] = obj[key];
    });
  
    return rearrangedObj;
  }
  

  const JsonDisplay = ({ jsonData, className }) => {    
    const rearrangedObj = rearrangeObjectKeys(jsonData)

    const lines = Object.entries(rearrangedObj).map(([key, value]) => (
      <div key={key} className={className}>
        {!isNumber(key) && <strong className="capitalize">{key}: </strong>}
        <span className="">{value}</span>
      </div>
    ));

    return <div className="flex flex-col space-y-3">{lines}</div>;
  };


  return (
    <>
      <div className="flex justify-around py-20">
        <div className="flex space-x-4 -ml-10 ">
          <div className="text-center text-lg cursor-pointer mt-1 ">
            <IoMdArrowBack
              onClick={() =>
                setNotificationDetails(false)
              }
            />
          </div>
          <div className="flex flex-col space-y-6">
            <h2 className="font-medium text-glade-black-700 text-lg">
              {item?.title}
            </h2>
            <div>
              <JsonDisplay jsonData={JSON.parse(item?.body)} className="flex space-x-3 glade-grey-text glade-subtitle-one" />
            </div>
          </div>
        </div>
        <div className="">
          <span className="text-glade-gray-400 whitespace-nowrap">{formatDistanceToNowStrict(addHours(new Date(item?.created_at), 1))} ago</span>
        </div>
      </div>
    </>
  );
};

export default NotificationDetails;
