import React from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";

import TextIndicator from "../../components/indicators/TextIndicator";
import { statusEnum, simpleDate } from "../../helpers/helper";
// import { downloadFundTransferReceiptAsPDF } from "./DownloadFundTransferReceiptAsPDF";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineDownload } from "react-icons/ai";
import { FiRepeat } from "react-icons/fi";
import { BsArrowRepeat, BsFlag } from "react-icons/bs";
import useTeam from "../../hooks/useTeam";

export default function PayBillsDetail() {
  const location = useLocation();
  const history = useHistory();
  const {permissions} = useTeam();

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Bills"} path={!permissions?.includes("bill payment") ? "/dashboard/pay-bills" : "/teams/pay-bills"} />
        </div>

        <div className="hidden md:flex flex-col">
          <CardPlainWithButton>
            <CardPlainWithButtonSection
              title={"Bills Details"}
              loading={false}
              buttonDisabled={false}
              buttonTitle={"Download Receipt"}
              buttonSection={
                <div className="flex p-4 gap-7 whitespace-nowrap pt-6">
                  <div className="cursor-pointer flex justify-center text-center flex-col items-center text-glade-blue gap-1 ">
                    <span className="glade-coming-soon-bg p-1 rounded-full">
                      <AiOutlineDownload size={16} />{" "}
                    </span>
                    <p className="text-xs"> Download Receipt</p>
                  </div>

                  {!permissions?.includes("bill payment") && (
                    <div
                      className="cursor-pointer flex justify-center text-center flex-col items-center text-glade-blue gap-1"
                      onClick={() =>
                        history.push({
                          pathname: "/dashboard/pay-bills/repeat-bill",
                          state: location?.state,
                        })
                      }
                    >
                      <span className="glade-coming-soon-bg p-1 rounded-full">
                        <FiRepeat size={16} />{" "}
                      </span>
                      <p className="text-xs">Repeat Transaction</p>
                    </div>
                  )}

                  <div
                    className="cursor-pointer flex justify-center text-center flex-col items-center text-glade-blue gap-1"
                    onClick={() =>
                      history.push({
                        pathname: "/dashboard/recurring-transaction",
                        state: location?.state,
                      })
                    }
                  >
                    <span className="glade-coming-soon-bg p-1 rounded-full">
                      <BsArrowRepeat size={16} />
                    </span>
                    <p className="text-xs">Set as Recurring</p>
                  </div>

                  <div
                    className="cursor-pointer text-red-400 flex justify-center text-center flex-col items-center gap-1"
                    onClick={() =>
                      history.push({
                        pathname: "/dashboard/report-transfer",
                        state: location?.state,
                      })
                    }
                  >
                    <span className="bg-red-100 p-1 rounded-full">
                      <BsFlag size={14} />
                    </span>
                    <p className="text-xs">Report Transaction</p>
                  </div>
                </div>
              }
            >
              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Payment Id
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {location?.state?.bill_reference}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Biller
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {location?.state?.bill_name}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Transaction Reference
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  <CopyTextButton
                    text={location?.state?.txn_ref}
                    maskAsCardNumber={true}
                  />
                </h3>
              </div>

              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Amount
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  NGN{" "}
                  {Number(
                    location?.state?.actual_value ??
                      location?.state?.amount_charged
                  )?.toLocaleString()}
                </h3>
              </div>

              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">Date</h3>
                <h3 className="glade-normal-text-three glade-black">
                  {simpleDate(
                    location?.state?.created_at,
                    "numeric",
                    "short",
                    "2-digit"
                  )}
                </h3>
              </div>

              <div className="flex flex-col md:flex-row justify-between py-4 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Status
                </h3>
                <TextIndicator
                  type={statusEnum[location?.state?.status]}
                  title={location?.state?.status}
                />
              </div>
            </CardPlainWithButtonSection>
          </CardPlainWithButton>
        </div>
        <div></div>
      </div>
    </div>
  );
}
