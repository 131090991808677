import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import { getPayday } from "../../redux/payroll/payrollActions";
import usePayroll from "../../hooks/usePayroll";
import {
  simpleDate,
  statusEnum,
  isArray,
  isEmpty,
  pagination,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import Button from "../../components/buttons/Button";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import Sieve from "../../components/filter/Sieve";
import PaydayEditModal from "./PaydayEditModal";
import PaydayDeleteModal from "./PaydayDeleteModal";
import PaydayRunAgainModal from "./PaydayRunAgainModal";
import PayrollRunModal from "./PayrollRunModal";
import { IoIosArrowForward } from "react-icons/io";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { PopoverMain } from "../../components/popovers/Popover";
import { FiMoreVertical, FiAlertCircle } from "react-icons/fi";
import SalaryAdvancementModal from "./SalaryAdvancementModal";
import useTeam from "../../hooks/useTeam";
import EmptyCard from "../../components/empty-states/EmptyCard";

export default function Payday() {
  const PaydayEditModalRef = React.useRef();
  const PaydayDeleteModalRef = React.useRef();
  const PayrollRunModalRef = React.useRef();
  const PaydayRunAgainModalRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const payroll = usePayroll();
  const {permissions, team_user} = useTeam();
  const [showAdvancementModal, setShowAdvancementModal] = useState(false);
  React.useEffect(() => {
    dispatch(getPayday());
    setInput({});
    // eslint-disable-next-line
  }, [payroll.shouldReloadPayday]);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(payroll.paydayIndex, 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, payroll.paydayIndex]);

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getPayday({
        date_from: data.date_from ?? data.date,
        date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
      })
    );
  };
  // User checkbox input
  const [input, setInput] = React.useState({});
  const [selectedItem, setSelectedItem] = useState([]);

  const handleInputChange = (e, item) => {
    // Pass the item as an argument
    if (e.target.name === "all" && payroll.paydayIndex?.length > 0) {
      const newInput = { all: e.target.checked };
      payroll.paydayIndex.forEach((staff) => {
        newInput[staff.payroll_id] = e.target.checked;
      });
      setInput(newInput);
    } else {
      setInput({ ...input, [e.target.name]: e.target.checked, all: false });
    }
    if(e.target.checked){
      setSelectedItem([...selectedItem, item])
    }else if(!e.target.checked){
      setSelectedItem((prev) => {
        return prev.filter((id)=> id?.payroll_id !== item?.payroll_id )
      })
    }
  };

  // Extract payroll_id from input
  const payrollList = (list) => {
    let newList = [];
    for (const key in list) {
      if (list[key] === true && key !== "all") {
        newList.push(key); // Push the payroll_id into the array
      }
    }
    return newList;
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getPayday());
  };

  const renderPaydayTable = (data) => {
    const onClickOfRow = (item) => {
      history.push({
        pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/payday-detail" : "/teams/payroll/payday-detail",
        state: item,
      });
    };

    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell className="cursor-pointer">
            <span>
              <CheckboxInput
                label={
                  <BasicIndicator
                    type={statusEnum[item?.processed ? "success" : "pending"]}
                    title={item?.processed ? "Successful" : "Scheduled"}
                    onClick={() => onClickOfRow(item)}
                  />
                }
                name={item?.payroll_id}
                type={"checkbox"}
                checked={input[item?.payroll_id] ?? false}
                onChange={(e) => handleInputChange(e, item)}
                placeholder={"Charge user (optional)"}
                autoComplete={""}
                className={"appearance-none"}
              />
            </span>
          </CardTableBodyCell>

          <CardTableBodyCell>
            <span className="glade-normal-text-two">
              <CopyTextButton text={item?.payroll_id} />
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two">{item?.description}</span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two whitespace-nowrap">
              {simpleDate(item?.transaction_date)}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two">{item?.total_staff}</span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two whitespace-nowrap font-medium">
              NGN {Number(item?.total_amount)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <PopoverMain
              button={
                <div type="button" className={"py-2 glade-ash"}>
                  <div className="inline-flex items-center justify-items-center text-center text-xl">
                    <FiMoreVertical
                      color="#151515"
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                </div>
              }
              popoverClassName="right-6 relative"
            >
              {item?.processed === 0 ? (
                <ul className="px-2 py-5 bg-white shadow rounded-md w-56">
                  <li
                    className="p-3 glade-normal-text-two hover:bg-gray-200 glade-black cursor-pointer"
                    onClick={() => PaydayEditModalRef.current.open(item)}
                  >
                    Edit Payroll
                  </li>

                  <li
                    className="p-3 glade-normal-text-two hover:bg-gray-200 glade-black cursor-pointer"
                    onClick={() => PaydayDeleteModalRef.current.open(item)}
                  >
                    Delete Payroll
                  </li>
                </ul>
              ) : (
                <ul className="px-2 py-5 bg-white shadow rounded-md w-56">
                  <li
                    className="p-3 glade-normal-text-two hover:bg-gray-200 glade-black cursor-pointer"
                    onClick={() => PayrollRunModalRef.current.open(item)}
                  >
                    Run Again
                  </li>

                  <li
                    className="p-3 glade-normal-text-two hover:bg-gray-200 glade-black cursor-pointer"
                    onClick={() => PaydayDeleteModalRef.current.open(item)}
                  >
                    Delete Payroll
                  </li>
                </ul>
              )}
            </PopoverMain>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderPaydayDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() =>
            history.push({
              pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/payday-detail" : "/teams/payroll/payday-detail",
              state: item,
            })
          }
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <span>
                <CheckboxInput
                  label={""}
                  name={item?.payroll_id}
                  type={"checkbox"}
                  checked={input[item?.payroll_id] ?? false}
                  onChange={(e) => handleInputChange(e)}
                  placeholder={"Charge user (optional)"}
                  autoComplete={""}
                  className={"appearance-none"}
                />
              </span>
            </div>
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.processed]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  NGN {Number(item?.total_amount)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {item?.description}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(
                    item?.transaction_date,
                    "numeric",
                    "short",
                    "2-digit"
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <CardTableHeaderComplex
        title={
          <div className="flex flex-row justify-around gap-x-9 ">
            <div>
              <p>Showing {tableData.totalItems} records</p>
            </div>
            <div className="hidden md:flex absolute right-14">
              <div className="inline-flex items-center justify-items-center mr-1">
                <FiAlertCircle className="text-sm " />
              </div>
              <p className="text-sm">
                Need Payroll Advance. Click{" "}
                <span
                  className="glade-blue cursor-pointer"
                  onClick={() => setShowAdvancementModal(!showAdvancementModal)}
                >
                  here
                </span>{" "}
                to request{" "}
              </p>
            </div>
          </div>
        }
        filter={
          <Sieve
            types={["date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={payroll.isLoadingPayroll}
            loading={payroll.isLoadingPayroll}
          />
        }
        rightButton={
          <div className="flex flex-row space-x-3">
            <div>
              {!team_user?.employee?.is_teams && <Button.Blue
                title={"View Staff"}
                onClick={() => history.push(!permissions?.includes("payroll") ? "/dashboard/payroll/staff" : "/teams/payroll/staff")}
              />}
            </div>

            <div>
            {!team_user?.employee?.is_teams &&
            <Button.Danger2
                title={"Delete"}
                disabled={payrollList(input).length < 1}
                onClick={() => {
                  PaydayDeleteModalRef.current.open(
                    input?.all ? tableData?.data : selectedItem
                  );
                }}
              />}
            </div>
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={payroll.isLoadingPayroll}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={payroll.isLoadingPayroll}
                    loading={payroll.isLoadingPayroll}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <span
                    onClick={() => history.push(!permissions?.includes("payroll") ? "/dashboard/payroll/staff" : "/teams/payroll/staff")}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    View Staff
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    View Staff
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <span
                    onClick={() =>
                      setShowAdvancementModal(!showAdvancementModal)
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Request Advance
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Request Advance
                  </span>
                }
              />
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || payroll.isLoadingPayroll ? (
        <Fragment>
          <div className="hidden md:block">
            <EmptyTable
              column={[
                "",
                "Status",
                "Payroll ID",
                "Description",
                "Date",
                "Total Staff",
                "Amount",
                "Actions",
                "",
              ]}
              loading={payroll.isLoadingPayroll}
            />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2]} loading={payroll.isLoadingPayroll} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="hidden md:block">
            {!isArray(tableData.data) && !tableData?.data?.length > 0 ?
            <div className="w-full flex flex-col items-center justify-center">
              {" "}
              <EmptyCard
                title="No payroll to display"
                subtitle="You do not have any payroll transaction"
                loading={payroll?.isLoadingPayroll}
              />
            </div>
            :
              <CardTableBody
              column={[
                "",
                <CheckboxInput
                  label={"Status"}
                  name={"all"}
                  type={"checkbox"}
                  checked={input.all ?? false}
                  onChange={(e) => handleInputChange(e)}
                  placeholder={""}
                  autoComplete={""}
                  className={"appearance-none"}
                />,
                "Payroll ID",
                "Description",
                "Date",
                "Total Staff",
                "Amount",
                "Actions",
                "",
              ]}
            >
              {isArray(tableData.data) && renderPaydayTable(tableData.data)}
            </CardTableBody>}
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderPaydayDesk(tableData.data)}
              
            </div>
          </div>
        </Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={payroll.isLoadingPayroll}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
      {showAdvancementModal && (
        <SalaryAdvancementModal
          showAdvancementModal={showAdvancementModal}
          setShowAdvancementModal={setShowAdvancementModal}
        />
      )}
      <PaydayEditModal ref={PaydayEditModalRef} />
      <PaydayDeleteModal ref={PaydayDeleteModalRef} />
      <PayrollRunModal
        ref={PayrollRunModalRef}
        item={selectedItem}
        input={input}
        setInput={setInput}
        PaydayRunAgainModalRef={PaydayRunAgainModalRef}
      />
      <PaydayRunAgainModal ref={PaydayRunAgainModalRef} />
    </div>
  );
}
