import {
  LOADING_POS,
  RELOAD_POS,
  GET_REVENUE,
  GET_SALES,
  GET_STATES,
  GET_LGA,
  REQUEST_PERSONAL_POS,
  REQUEST_BUSINESS_POS,
  CANCEL_REQUEST,
  GET_PENDING_REQUESTS,
  GET_APPROVED_REQUESTS,
  GET_POS_TRANSACTIONS,
  GET_POS_TERMINALS,
  GET_POS_TERMINAL_DETAILS,
  GET_POS_TERMINAL_REQUESTS,
  GET_POS_TERMINAL_REQUESTS_BY_ID,
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload: false,
  posRevenue: [],
  posSales:[],
  posStates:[],
  posLga:[],
  requestPersonalPos:{},
  requestBusinessPos:{},
  cancelPosRequest:{},
  pendingPosRequests:[],
  approvedPosRequests:[],
  posTransactions:[],
  posTerminals:[],
  posTerminalDetails:[],
  posTerminalRequests:[],
  posTerminalRequestById:{},
};

export default function posReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_POS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_POS:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case GET_REVENUE:
      return {
        ...state,
        posRevenue: action.payLoad.data.data,
      };

    case GET_SALES:
      return {
        ...state,
        posSales: action.payLoad.data.data,
      };

    case GET_STATES:
      return {
        ...state,
        posStates: action.payLoad.data.data,
      };

    case GET_LGA:
      return {
        ...state,
        posLga: action.payLoad.data.data,
      };

    case REQUEST_PERSONAL_POS:
      return {
        ...state,
        requestPersonalPos: action.payLoad.data.data,
      };

    case REQUEST_BUSINESS_POS:
      return {
        ...state,
        requestBusinessPos: action.payLoad.data.data,
      };

    case CANCEL_REQUEST:
      return {
        ...state,
        cancelPosRequest: action.payLoad.data.data,
      };

    case GET_PENDING_REQUESTS:
      return {
        ...state,
        pendingPosRequests: action.payLoad.data.data,
      };

    case GET_APPROVED_REQUESTS:
      return {
        ...state,
        approvedPosRequests: action.payLoad.data.data,
      };

    case GET_POS_TRANSACTIONS:
      return {
        ...state,
        posTransactions: action.payLoad.data.data,
      };

    case GET_POS_TERMINALS:
      return {
        ...state,
        posTerminals: action.payLoad.data.data,
      };
  
    case GET_POS_TERMINAL_DETAILS:
      return {
        ...state,
        posTerminalDetails: action.payLoad.data.data,
      };

    case GET_POS_TERMINAL_REQUESTS:
      return {
        ...state,
        posTerminalRequests: action.payLoad.data.data,
      };

    case GET_POS_TERMINAL_REQUESTS_BY_ID:
      return {
        ...state,
        posTerminalRequestById: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
