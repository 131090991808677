import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {CardPlainWithButton,CardPlainWithButtonSection} from "../../components/cards/CardPlainWithButton";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import useBusiness from "../../hooks/useBusiness";
import {getBusinessRegistrationCharge} from '../../redux/business/businessActions';

export default function RegisterBusiness() {

  const dispatch = useDispatch();
  const history = useHistory();
  const business = useBusiness();
  const [selectedOption, setSelectedOption] = React.useState({selectedOption:''});
  const [businessNamePrice, setBusinessNamePrice] = React.useState('...');
  const [companyNamePrice, setCompanyNamePrice] = React.useState('...');

  React.useLayoutEffect(() => {
    dispatch(getBusinessRegistrationCharge({type:'business'}, (res)=>setBusinessNamePrice(res) ));
    dispatch(getBusinessRegistrationCharge({type:'company'}, (res)=>setCompanyNamePrice(res) ));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    selectedOption==='register_business_name'?
      history.push('/dashboard/business/register-business/business-name')
    :
      history.push('/dashboard/business/register-business/business-company');
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Home"} path={"/dashboard/home"} />
        </div>

        <CardPlainWithButton>
          <CardPlainWithButtonSection 
            title={"Register Business Account"}
            loading={business.isSubmittingBusiness} 
            buttonTitle={"Continue"} 
            buttonOnClick={() => onSubmit()} 
            buttonDisabled={business.isSubmittingBusiness}>
            <div className="my-6 px-6">
              <div className="flex flex-col space-y-6">

                <div 
                  className='border-2 border-solid hover:border-blue-800 rounded-sm space-y-3 p-6 cursor-pointer' 
                  style={{borderColor: selectedOption === 'register_business_name'?'#1634A1':null}}
                  onClick={()=>setSelectedOption('register_business_name')}>
                  <h3 className='glade-heading-three glade-black'>Register your business name</h3>
                  <p className='glade-normal-text-two glade-grey-cool'>Register a business name with Glade. Submit the required information and let us handle the rest.</p>
                  <b className='glade-subtitle-one glade-black'>Requirements:</b>
                  <ul className='list-disc ml-6'>
                    <li className='glade-normal-text-two glade-grey-cool'>Identity Card</li>
                    <li className='glade-normal-text-two glade-grey-cool'>Business Name</li>
                    <li className='glade-normal-text-two glade-grey-cool'>Business Email</li>
                    <li className='glade-normal-text-two glade-grey-cool'>Business Address</li>
                    <li className='glade-normal-text-two glade-grey-cool'>NGN {businessNamePrice} Registration Fee</li>
                  </ul>
                </div>

                <div 
                  className='border-2 border-solid hover:border-blue-800 rounded-sm space-y-3 p-6 cursor-pointer'
                  style={{borderColor: selectedOption === 'register_company_name'?'#1634A1':null}}
                  onClick={()=>setSelectedOption('register_company_name')}>
                  <h3 className='glade-heading-three glade-black'>Register your company</h3>
                  <p className='glade-normal-text-two glade-grey-cool'>Our legal partners are here to help you incorporate your business with ease.</p>
                  <b className='glade-subtitle-one glade-black'>Requirements:</b>
                  <ul className='list-disc ml-6'>
                    <li className='glade-normal-text-two glade-grey-cool'>Identity Card</li>
                    <li className='glade-normal-text-two glade-grey-cool'>Business Name</li>
                    <li className='glade-normal-text-two glade-grey-cool'>Director’s Information</li>
                    <li className='glade-normal-text-two glade-grey-cool'>Business Email</li>
                    <li className='glade-normal-text-two glade-grey-cool'>Business Address</li>
                    <li className='glade-normal-text-two glade-grey-cool'>NGN {companyNamePrice} Registration Fee</li>
                  </ul>
                </div>

              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  );
}
