import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  CLEAR_NOTIFICATIONS_FAILURE,
  CLEAR_NOTIFICATIONS_LOADING,
  CLEAR_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATION_FAILURE,
  MARK_NOTIFICATION_LOADING,
  MARK_NOTIFICATION_SUCCESS,
  GET_IN_APP_NOTIFICATIONS_LOADING,
  GET_IN_APP_NOTIFICATIONS_SUCCESS,
  GET_IN_APP_NOTIFICATIONS_FAILURE
} from "./types";

export const getNotifications = () => {
  return (dispatch) => {
    dispatch({ type: GET_NOTIFICATIONS_LOADING });
    Axios.get(`/notifications`)
      .then((res) => {
        dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: GET_NOTIFICATIONS_FAILURE });
        errorHandler(error);
      });
  };
};

export const getInAppNotifications = () => {
  return (dispatch) => {
    dispatch({ type: GET_IN_APP_NOTIFICATIONS_LOADING });
    Axios.get(`/in-app-notifications/all`)
      .then((res) => {
        dispatch({ type: GET_IN_APP_NOTIFICATIONS_SUCCESS, payload: res.data.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: GET_IN_APP_NOTIFICATIONS_FAILURE });
        errorHandler(error);
      });
  };
};

export const markNotificationAsRead = (id) => {
  return (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_LOADING });
    Axios.get(`/notifications/mark-notification/${id}`)
      .then((res) => {
        dispatch({ type: MARK_NOTIFICATION_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: MARK_NOTIFICATION_FAILURE });
        errorHandler(error, true);
      });
  };
};
export const markInAppNotificationAsRead = (id) => {
  return (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_LOADING });
    Axios.get(`/in-app-notifications/${id}`)
      .then((res) => {
        dispatch({ type: MARK_NOTIFICATION_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: MARK_NOTIFICATION_FAILURE });
        errorHandler(error, true);
      });
  };
};
export const markInAppNotificationAsUnRead = (id) => {
  return (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_LOADING });
    Axios.post(`/in-app-notifications/mark-unread/${id}`)
      .then((res) => {
        dispatch({ type: MARK_NOTIFICATION_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: MARK_NOTIFICATION_FAILURE });
        errorHandler(error, true);
      });
  };
};

export const markMultipleNotificationsAsRead = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_LOADING });
    Axios.post(`/in-app-notifications/mark-read`, payLoad)
      .then((res) => {
        dispatch({ type: MARK_NOTIFICATION_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: MARK_NOTIFICATION_FAILURE });
        errorHandler(error, true);
      });
  };
};


export const clearAllNotifications = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATIONS_LOADING });
    Axios.delete(`/in-app-notifications/delete-all`)
      .then((res) => {
        dispatch({ type: CLEAR_NOTIFICATIONS_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: CLEAR_NOTIFICATIONS_FAILURE });
        errorHandler(error, true);
      });
  };
}

export const deleteAllInAppNotifications = (all, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATIONS_LOADING });
    Axios.delete(`/in-app-notifications/delete-all?ids=[${all}]`)
      .then((res) => {
        dispatch({ type: CLEAR_NOTIFICATIONS_SUCCESS, payload: res.data });
        successHandler(res);
        callBack()
      })
      .catch((error) => {
        dispatch({ type: CLEAR_NOTIFICATIONS_FAILURE });
        errorHandler(error, true);
        callBack()
      });
  };
}

export const deleteSingleInAppNotifications = (id, callBack = () =>{}) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATIONS_LOADING });
    Axios.delete(`/in-app-notifications/${id}/delete`)
      .then((res) => {
        dispatch({ type: CLEAR_NOTIFICATIONS_SUCCESS, payload: res.data });
        callBack()
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: CLEAR_NOTIFICATIONS_FAILURE });
        callBack()
        errorHandler(error, true);
      });
  };
}
