import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import useFundTransfer from '../../hooks/useFundTransfer';
import { AiFillFileAdd } from 'react-icons/ai';
import { rememberBulkCreateStaffList } from '../../redux/payroll/payrollActions';
import { getBanks } from '../../redux/fund-transfer/fundTransferActions';
import { downloadSampleBulkCreateStaffCSV, downloadBankCodesCSV, processUploadedBulkCreateStaffCSV } from './UploadBulkCreateStaffAsCSV';
import useTeam from '../../hooks/useTeam';

export default function PayrollStaffCreateBulkFileUpload() {

  const history = useHistory();
  const dispatch = useDispatch();
  const fundTransfer = useFundTransfer();
  const {permissions} = useTeam();

  React.useEffect(() => {
    dispatch(getBanks({ mode: "external" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    if (input.files[0]) {
      let data = await processUploadedBulkCreateStaffCSV(input.files[0]);
      if (data?.length > 0) {
        dispatch(rememberBulkCreateStaffList(data, () => history.push(!permissions?.includes("payroll") ? "/dashboard/payroll/staff/create/bulk/review" : "/teams/payroll/staff/create/bulk/review")));
      }
    }
  }

  const [input, setInput] = React.useState({ files: [] });
  const handleFileInput = (e) => {
    const newFiles = [...input.files, ...e.target.files];
    setInput({ files: newFiles });
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">

        <div className="mb-6">
          <BreadCrumbs title={'Payroll Staff'} path={!permissions?.includes("payroll") ? '/dashboard/payroll/staff' : '/teams/payroll/staff'} />
        </div>

        <div className={"grid grid-cols-1 md:grid-cols-8 gap-1 md:gap-4"}>
          <div className={"md:col-start-3 md:col-span-4 flex flex-col border rounded-md"}>
            <div className="bg-white py-4 overflow-hidden rounded-sm shadow-sm">
              <div className="flex justify-center py-2 border-b-2  border-solid border-gray-200">
                <h3 className='glade-heading-three text-center'>Bulk Staff</h3>
              </div>
              <div className="px-6 my-3">

                <div className="mt-9 px-6">
                  <div className="space-y-6">
                    <div>
                      <div className="flex flex-col items-center my-3 py-16 glade-bg-grey-file-overlay">
                        <AiFillFileAdd size={50} color={'#7CA4EE'} title={'Manual Upload'} />
                        <h5 className="mt-3 glade-file-blue">Drag and drop or upload a .csv or .xlsx file</h5>
                        <div className="filter drop-shadow-lg mt-3 p-2 bg-white cursor-pointer rounded-md">
                          <label htmlFor="file-upload" className="cursor-pointer">
                            <span className="glade-normal-text-three glade-black">{input.files.length > 0 ? 'Replace File' : 'Choose File'}</span>
                            <input id="file-upload" name="file-upload" type="file" onChange={handleFileInput} className="sr-only" />
                          </label>
                        </div>
                      </div>
                      <h3 className="text-sm text-center">
                        <span className="mr-1 glade-black cursor-pointer">Maximum file size is 3MB. </span>
                        <span className="mr-1 glade-blue cursor-pointer" onClick={() => downloadSampleBulkCreateStaffCSV()}>see a sample .xlsx file </span>
                        <span className="mr-1 glade-black cursor-pointer">or </span>
                        <span className="mr-1 glade-blue cursor-pointer" onClick={() => downloadBankCodesCSV(fundTransfer.banks)}>see list of bank codes.</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="mt-9">
            <button
              onClick={onSubmit}
              disabled={input.files.length === 0}
              className={"glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm mb-4 "}>
              Add Staff
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
