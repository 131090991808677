import { useSelector } from "react-redux";

const useVirtualCards = () => {
  const virtualCards = useSelector((state) => state.virtualCards);

  const {
    isLoading,
    shouldReload,
    isLoadingCards,
    editVirtualCard,
    virtualCardTitles,
    virtualCardCurrencies,
    virtualCardDesigns,
    createdVirtualCard,
    virtualCardIndex,
    virtualCardDetails,
    virtualCardTransactions,
    fundVirtualCard,
    withdrawVirtualCardFund,
    freezeVirtualCard,
    unfreezeVirtualCard,
    terminateVirtualCard,
    virtualCardSearchResults,
    virtualCardFilterResults,
    virtualCardCharges
  } = virtualCards;

  const virtualCardSearchResultsByCardStatus = virtualCardSearchResults?.data?.filter(item => item.card_status === 1);
  const virtualCardFilterResultsByCardStatus = virtualCardFilterResults?.data?.filter(item => item.card_status === 1);

  return {
    isLoading,
    shouldReload,
    isLoadingCards,
    editVirtualCard,
    virtualCardTitles,
    virtualCardCurrencies,
    virtualCardDesigns,
    createdVirtualCard,
    virtualCardIndex,
    virtualCardDetails,
    virtualCardTransactions,
    fundVirtualCard,
    withdrawVirtualCardFund,
    freezeVirtualCard,
    unfreezeVirtualCard,
    terminateVirtualCard,
    virtualCardSearchResults,
    virtualCardFilterResults,
    virtualCardSearchResultsByCardStatus,
    virtualCardFilterResultsByCardStatus,
    virtualCardCharges
  };
};

export default useVirtualCards;
