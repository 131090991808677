import { csvWorkbookDownload } from "../../helpers/XlsxUtils";
import * as xlsx from "xlsx";

export function downloadSettlementAsCSV(allSettlements, onChange=()=>{}){

    const fileName = "Settlements";
    const getWorksheets=({ xlsx }) => {
        return {
            data: xlsx.utils.json_to_sheet(
                allSettlements?.map((item, i) => ({
                  Status: item.settled === 1 ? "Settled" : "Not Settled",
                  "Total Amount": item.total_amount,
                  "Settled Amount": item.amount_paid,
                  Date: item.txn_date,
                })),
            ),
        }
    }

    (async () => {
        if (getWorksheets) {
            try {
                onChange({ isLoading: true, isError: false });
                const workbook = xlsx.utils.book_new();
                const worksheets = await getWorksheets({ workbook, xlsx });
                if (worksheets) {
                    for (const key in worksheets) {
                        xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
                    }
                    if (workbook.SheetNames.length) {
                        csvWorkbookDownload(workbook, fileName);
                    }
                }
            } catch (error) {
                onChange({ isLoading: false, isError: true });
            }
            onChange({ isLoading: false, isError: false });
        }
    })();
}