import React, { useEffect, useState } from 'react';
import Logo from "../../assets/images/glade_logo.png";
import { CgClose } from "react-icons/cg";
import TextInput from "../../components/inputs/TextInput";
import { ImSpinner2 } from "react-icons/im";
import Button from "../../components/buttons/Button";
import { submitRegisteredBusiness } from '../../redux/business/businessActions';
import useBusiness from "../../hooks/useBusiness";
import useUser from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { validateNairaInputObject } from "../../helpers/validator";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import errorHandler from "../../handlers/errorHandler";
import { IoIosCheckmark } from 'react-icons/io';
import { RegisterNewBusiness, Container } from './AddNewBusinessModal';
import { getOneBusinessDetails } from "../../redux/home/homeActions";
import { toggleAccountAction } from "../../redux/auth/authActions";
import SelectInput from '../../components/inputs/SelectInput';
import { isArray } from '../../helpers/helper';
import useSettings from '../../hooks/useSettings';
import { getCountries } from '../../redux/settings/settingsActions';

const AddNewRegisteredBusiness = ({ setOpenNewBusiness, setNewBusinessOpen }) => {

  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const business = useBusiness();
  const settings = useSettings();

  const { isSubmittingBusiness } = business;

  const [businessCreated, setBusinessCreated] = useState(false);
  const [input, setInput] = useState({
    business_name: "",
    business_email: "",
  });
  const [inputError, setInputError] = useState({
    business_name: false,
    business_email: false,
    country_name: ''
  })
  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  const onSubmit = () => {
    let validator = validateNairaInputObject(input, setInputError);
    if (!validator) {
      dispatch(submitRegisteredBusiness({
        ...input
      }, () => { setBusinessCreated(true); dispatch(getPersonalAndBusinessAccounts()) }));
    } else {
      errorHandler({ response: { data: { message: 'Invalid Field(s)' } } }, true)
    }
  }

  return (
    <RegisterNewBusiness>
      <div className="header container mx-auto">
        {/* Make header sticky */}
        <img src={Logo} alt="logo" />
        <span onClick={() => setOpenNewBusiness(false)} className="icon close">
          <CgClose size={31} />
        </span>
      </div>
      {!businessCreated ?
        <Container>
          <div className="p-3">
            <div className="flex flex-col justify-center w-full mx-auto">
              {!isSubmittingBusiness ? (
                <div>
                  <div className={"flex flex-col border rounded"}>
                    <div className="bg-white py-4 overflow-hidden rounded-sm border-b-2 border-solid">
                      <div className="py-2 px-2 md:px-4 lg:px-14">
                        <h4 className="glade-heading-three glade-black">
                          Add a new business
                        </h4>
                        <p>Please provide the following information about your business </p>
                      </div>
                      <div className="mt-6 px-2 md:px-4 lg:px-14">
                        <div className="space-y-6">

                          <div className="space-y-2">
                            <TextInput
                              label={"Business Name"}
                              id={"business_name"}
                              name={"business_name"}
                              type={"text"}
                              value={input.business_name}
                              onChange={onChange}
                              placeholder={"Enter Business Name"}
                              autoComplete={"business_name"}
                              className={`appearance-none rounded-md}`}
                              errorState={inputError?.business_name}
                            />
                          </div>

                          <div className="space-y-2">
                            <TextInput
                              label={"Business Email"}
                              id={"business_email"}
                              name={"business_email"}
                              type={"email"}
                              value={input.business_email}
                              onChange={onChange}
                              placeholder={"Enter Business Email"}
                              autoComplete={"business_email"}
                              className={`appearance-none rounded-md}`}
                              errorState={inputError?.business_email}
                            />
                          </div>
                          <div>
                            <SelectInput
                              label={"Country"}
                              id={"country"}
                              name={"country_name"}
                              value={input?.country_name ?? ""}
                              onChange={onChange}
                              autoComplete={"country"}
                              className={"appearance-none"}
                              errorState={inputError.country_name}
                            >
                              <option value={""} disabled={true}>
                                Select Country
                              </option>
                              {isArray(settings.complianceCountriesNew) &&
                                settings.complianceCountriesNew?.map((country, index) => (
                                  <option key={index} value={country.country_name}>
                                    {country.country_name}
                                  </option>
                                ))}
                            </SelectInput>
                            {inputError.country_name && (
                              <div className="text-glade-red-400 text-sm mt-2">
                                Country is a required field
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <Button.Blue
                      onClick={onSubmit}
                      title={"Create Business"}
                      className={"my-6 py-2"}
                      disabled={!input.business_name || !input.business_email || !input.country_name}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center px-6 py-12 w-full bg-white h-full">
                  <div>
                    <div className="mt-6">
                      <span className="glade-heading-three glade-home-dark">
                        Creating Business
                      </span>
                    </div>
                    <div className="flex justify-center">
                      <ImSpinner2
                        className="mt-6 spinner text-center"
                        size={70}
                        color="#0B7AE0"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
        :
        <div className='md:w-3/5 lg:w-2/5 mx-6 md:mx-auto'>
          <div className='flex flex-col justify-center items-center mb-6 mt-6 mx-6'>
            <IoIosCheckmark className='mt-6 mb-4' style={{ color: "#4AFA4A", borderRadius: "50%", background: "#E6FBE9" }} size={100} />
            <span className='glade-heading-three glade-home-dark'>Successful</span>
            <p className='text-center mt-6'>Your business has been added, submit your compliance to activate your account</p>
          </div>
          <div className='mt-9 flex flex-col items-center justify-center'>
            <Button.Blue
              onClick={() => {
                setOpenNewBusiness(false);
                setNewBusinessOpen(false);
                localStorage.setItem('currencyIndex', 0);
                dispatch(getOneBusinessDetails());
                dispatch(toggleAccountAction({ ...user?.businessAccounts[0], is_business: true }, () => history.push("/dashboard/home")));
                localStorage.setItem('currencyIndex', 0);
                // history.push("/dashboard/home/business-compliance")
              }}
              title={"Submit Compliance"}
              className={"mt-6 py-3"}
            />
            <Button.Base
              onClick={() => {
                setOpenNewBusiness(false);
                setNewBusinessOpen(false);
                localStorage.setItem('currencyIndex', 0);
                dispatch(getOneBusinessDetails());
                dispatch(toggleAccountAction({ ...user?.businessAccounts[0], is_business: true }, () => history.push("/dashboard/home")));
                localStorage.setItem('currencyIndex', 0);
                history.push("/dashboard/home")
              }}
              title={"Return Home"}
              className={"mt-6 py-3 glade-bg-extra-light-blue glade-blue font-bold"}
            />
          </div>
        </div>
      }

    </RegisterNewBusiness>
  );
};

export default AddNewRegisteredBusiness;
