import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AddCurrencyForm1 from "./AddCurrencyForm1";
import AddCurrencyForm2 from "./AddCurrencyForm2";
import { getBusinessCurrencyInfo } from "../../../redux/addCurrency/addCurrencyActions";
import useAvailableCurrencies from "../../../hooks/useCurrencies";
import { useDispatch } from "react-redux";
import { getCountries } from "../../../redux/addCurrency/addCurrencyActions";

const NewAddCurrencyForm = ({
  input,
  setInput,
  setContent,
  next,
  activeStepBusDetails,
  onProceed,
}) => {
  const currencies = useAvailableCurrencies();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    dispatch(getBusinessCurrencyInfo());
    countries?.length < 1 && dispatch(getCountries((res) => setCountries(res)));
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getBusinessCurrencyInfo());
      return response;
    }

    fetchData();
    const businessCurrencyInfo = currencies?.businessCurrencyInfo;

    if (businessCurrencyInfo?.hasIfProfile) {
      let country_code = countries.find(
        (x) =>
          x?.country_name?.toLowerCase() ===
          businessCurrencyInfo?.country?.toLowerCase()
      )?.country_code;
      setInput({
        ...input,
        business_legal_name: businessCurrencyInfo?.registered_name,
        business_country: country_code,
        business_state: businessCurrencyInfo?.state,
        business_city: businessCurrencyInfo?.city,
        postal_code: businessCurrencyInfo?.postal_code,
        business_phone_number: businessCurrencyInfo?.contact_phone,
        director_first_name:
          businessCurrencyInfo?.business_director_compliance_first_name,
        director_last_name:
          businessCurrencyInfo?.business_director_compliance_last_name,
        director_phone_number: businessCurrencyInfo?.director_phone_number,
      });
    }
  }, []);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <div className="mt-9 md:mt-24 w-full flex justify-center">
      <div className="md:w-3/5 lg:w-1/2 xl:w-2/5 flex flex-col md:flex-row md:space-x-24">
        <div className="flex md:flex-col space-x-9 md:space-x-0 md:space-y-9 mt-1  md:mb-auto lg:w-2/7 md:w-1/3">
          <Step className="flex space-x-3 items-center">
            <span className="step glade-bg-blue text-white glade-normal-text-one flex items-center justify-center">
              {" "}
              1{" "}
            </span>
            <span className="glade-blue glade-normal-text-one">
              Business details
            </span>
          </Step>
          <Step className="flex space-x-3 items-center">
            <span
              className={`step glade-normal-text-one flex items-center justify-center ${
                !activeStepBusDetails
                  ? "glade-bg-blue text-white"
                  : "glade-bg-accent-light glade-grey-dark"
              }`}
            >
              {" "}
              2{" "}
            </span>
            <span
              className={`glade-normal-text-one ${
                !activeStepBusDetails ? "glade-blue" : "glade-grey-dark"
              }`}
            >
              Director's info
            </span>
          </Step>
        </div>

        <div className="lg:w-5/7 md:w-2/3">
          <div className="mb-9 items-center flex flex-col">
            <h3 className="text-xl font-bold">Add currency</h3>
            <p>Enter your business information</p>
          </div>
          {activeStepBusDetails && (
            <AddCurrencyForm1
              next={next}
              input={input}
              setInput={setInput}
              onChange={handleChange}
            />
          )}

          {!activeStepBusDetails && (
            <AddCurrencyForm2
              input={input}
              setInput={setInput}
              openConfirmPinModal={onProceed}
              onChange={handleChange}
              setContent={setContent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewAddCurrencyForm;

const Step = styled.div`
  .step {
    width: 25px;
    height: 25px;
    border-radius: 100px;
  }
`;
