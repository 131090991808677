import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";
import { ErrorMessage } from '@hookform/error-message';

export const virtualCardCreationValidation = (payLoad, silently=false) => {

    let data = {
        name:payLoad.card_title,
        amount:payLoad.amount,
        currency:payLoad.currency
    };

    let rules = {};
    switch (payLoad.currency) {
        case 'NGN':
        rules = {
            name:'required|minSize:2|maxSize:100',
            amount:'required|min:999|max:999999999999999',
            currency:'required'
        }
        break;

        case 'USD':
        rules = {
            name:'required|minSize:2|maxSize:100',
            amount:'required|min:4|max:999999999999999',
            currency:'required'
        }
        break;
    
        default:
        rules = {
            name:'required|minSize:2|maxSize:100',
            amount:'required|min:999|max:999999999999999',
            currency:'required'
        }
        break;
    }

    let messages = {};
    switch (payLoad.currency) {
        case 'NGN':
        messages = {
            name:{
                required:'Please enter a card name',
                min:'Card name must be at least 3 characters',
                max:'Card name must be less than 100 characters'
            },
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 1000 NGN',
                max:'Amount is not valid'
            },
            currency:{
                currency:'A valid reference is required'
            }
        }
        break;

        case 'USD':
        messages = {
            name:{
                required:'Please enter a card name',
                min:'Card name must be at least 3 characters',
                max:'Card name must be less than 100 characters'
            },
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 5 USD',
                max:'Amount is not valid'
            },
            currency:{
                currency:'A valid reference is required'
            }
        }
        break;

        default:
        messages = {
            name:{
                required:'Please enter a card name',
                min:'Card name must be at least 3 characters',
                max:'Card name must be less than 100 characters'
            },
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 1000 NGN',
                max:'Amount is not valid'
            },
            currency:{
                currency:'A valid reference is required'
            }
        }
        break;
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}

export const virtualCardFundingValidation = (payLoad, silently=false) => {
    
    let data = {
        amount:payLoad.amount,
        currency:payLoad.cardCurrency,
        debitCurrency: payLoad?.debitCurrency
    };

    let rules = {};
    switch (payLoad.currency) {
        case 'NGN':
        rules = {
            amount:'required|min:99|max:999999999999999',
            currency:'required',
            debitCurrency: 'required'
        }
        break;

        case 'USD':
        rules = {
            amount:'required|min:4|max:999999999999999',
            currency:'required',
            debitCurrency: 'required'
        }
        break;
    
        default:
        rules = {
            amount:'required|min:4|max:999999999999999',
            currency:'required',
            debitCurrency: 'required'
        }
        break;
    }

    let messages = {};
    switch (payLoad.currency) {
        case 'NGN':
        messages = {
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 100 NGN',
                max:'Amount is not valid'
            },
            currency:{
                currency:'A valid reference is required'
            },
            debitCurrency: 'Select currency to deduct from'
        }
        break;

        case 'USD':
        messages = {
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 5 USD',
                max:'Amount is not valid'
            },
            currency:{
                currency:'A valid reference is required'
            },
            debitCurrency: 'Select currency to deduct from'
        }
        break;
    
        default:
        messages = {
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 100 NGN',
                max:'Amount is not valid'
            },
            currency:{
                currency:'A valid reference is required'
            },
            debitCurrency: 'Select currency to deduct from'
        }
        break;
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}

export const virtualCardWithdrawalValidation = (payLoad, silently=false) => {

    let data = {
        amount:payLoad.amount,
        reference:payLoad.reference
    };

    let rules = {};
    switch (payLoad.currency) {
        case 'NGN':
        rules = {
            amount:'required|min:99|max:999999999999999',
            reference:'required',
            currency:'required'

        }
        break;

        case 'USD':
        rules = {
            amount:'required|min:4|max:999999999999999',
            reference:'required',
            currency:'required'
        }
        break;

        default:
        rules = {
            amount:'required|min:99|max:999999999999999',
            reference:'required',
            currency:'required'
        }
        break;
    }

    let messages = {};
    switch (payLoad.currency) {
        case 'NGN':
        messages = {
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 100 NGN',
                max:'Amount is not valid'
            },
            reference:{
                required:'A valid reference is required'
            },
            currency:{
                currency:'A valid reference is required'
            }
        }
        break;

        case 'USD':
        messages = {
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 5 USD',
                max:'Amount is not valid'
            },
            reference:{
                required:'A valid reference is required'
            },
            currency:{
                currency:'A valid reference is required'
            }
        }
        break;
    
        default:
        messages = {
            amount:{
                required:'An amount is required',
                min:'Minimum amount is 100 NGN',
                max:'Amount is not valid'
            },
            reference:{
                required:'A valid reference is required'
            },
            currency:{
                currency:'A valid reference is required'
            }
        }
        break;
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}

export const VirtualCardPersonalAddress = (payLoad, silently = false) => {
  let data = {
    address: payLoad.address,
    country: payLoad.country,
    state: payLoad.state,
    postal_code: payLoad.postal_code,
    passport: payLoad.passport[0],
    place_of_birth: payLoad.place_of_birth
  }
  let rules = {
    address: 'required|minSize:8|maxSize:300',
    postal_code: 'required|minSize:3',
    country: 'required',
    state: 'required',
    place_of_birth: 'required|minSize:2',
    passport: (payLoad) => { return payLoad ? true : false; },
  }
  let messages = {
    address: {
      required: 'Address is required',
      minSize: 'Address is too short',
      maxSize: 'Address is too long'
    },
    country: {
      required: 'Country is a required field',
    },
    state: {
      required: 'State is a required field',
    },
    postal_code: {
      required: 'Postal Code is a required field',
      minSize: 'Postal Code is too short',
    },
    passport: {
      custom: 'Passport is required',
    },
    place_of_birth: {
      required: 'Place of birth is a required field',
      minSize: 'Place of birth is too short'
    }
  }

  // If they are no errors then exit
  let result = validate(data, rules, messages);
  if (Object.keys(result).length === 0) {
    return true;
  }

  // Picking the first error and dispatching it to the user
  Object.keys(result).map((value) => {
    let error = result[value];
    let msg = error[Object.keys(error)[0]];
    return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
  });

  return false;
}

export const FormError = ({errors, name }) => (
	<ErrorMessage errors={errors} name={name}
		as={
			<p className="text-red-500 text-xs mt-1" />
		}
	>
		{({ messages }) =>
			messages &&
			Object.entries(messages).map(([type, message]) => (
				<p key={type}>{message}</p>
			))
		}
	</ErrorMessage>
)