import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";

import {
  GET_ACCOUNT_LIMIT_LOADING, 
  GET_ACCOUNT_LIMIT_SUCCESS, 
  GET_ACCOUNT_LIMIT_FAILURE, 
  
  REQUEST_ACCOUNT_LIMIT_LOADING, 
  REQUEST_ACCOUNT_LIMIT_SUCCESS, 
  REQUEST_ACCOUNT_LIMIT_FAILURE,

  GET_SYSTEM_ACCOUNT_TIER_LOADING, 
  GET_SYSTEM_ACCOUNT_TIER_SUCCESS, 
  GET_SYSTEM_ACCOUNT_TIER_FAILURE, 
  REQUEST_UPGRADE_TIER_LOADING, 
  REQUEST_UPGRADE_TIER_SUCCESS, 
  REQUEST_UPGRADE_TIER_FAILURE,
} from "./types";

export const getAccountLimit = () => {
    return (dispatch) => {
      dispatch({ type: GET_ACCOUNT_LIMIT_LOADING });
      Axios.get(`/account-tier/tier/details`)
      .then((res) => {
        dispatch({ type: GET_ACCOUNT_LIMIT_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: GET_ACCOUNT_LIMIT_FAILURE});
        errorHandler(error, true);
      });
    };
  };

  export const getSystemAccountTier = () => {
    return (dispatch) => {
      dispatch({ type: GET_SYSTEM_ACCOUNT_TIER_LOADING });
      Axios.get(`/account-tier`)
      .then((res) => {
        dispatch({ type: GET_SYSTEM_ACCOUNT_TIER_SUCCESS, payload: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: GET_SYSTEM_ACCOUNT_TIER_FAILURE});
        errorHandler(error, true);
      });
    };
  };
  export const requestTransactionLimitIncrease =(amount) => {
    return (dispatch) => {
      dispatch({ type: REQUEST_ACCOUNT_LIMIT_LOADING });
      Axios.post(`/transfers/limits/increase`, amount)
      .then((res) => {
        dispatch({ type: REQUEST_ACCOUNT_LIMIT_SUCCESS, payload: res.data });
        successHandler(res, true);
      })
      .catch((error) => {
        dispatch({ type: REQUEST_ACCOUNT_LIMIT_FAILURE});
        errorHandler(error, true);
      });
    };
  };
  
  export const requestUpgradeTier =({selectedTier, reason}) => {
    return (dispatch) => {
      dispatch({ type: REQUEST_UPGRADE_TIER_LOADING });
      Axios.post(`/account-tier/request`, {
        tier_id: selectedTier.id,
        reason: reason
      })
      .then((res) => {
        dispatch({ type: REQUEST_UPGRADE_TIER_SUCCESS, payload: res.data });
        successHandler(res, true);
      })
      .catch((error) => {
        dispatch({ type: REQUEST_UPGRADE_TIER_FAILURE});
        errorHandler(error, true);
      });
    };
  };