import React from "react";
import AddCurrencyModal from "../../components/modals/add currency/AddCurrencyModal";
import Modal from "../../components/modals/Modal";
import { useHistory } from "react-router-dom";

export const AddCurrency =()=>{
  const history = useHistory();

    const closeModal = () => {
        history.push('/dashboard/balances')
      };
    return (
    <div>
       {<Modal open={true} onClose={closeModal}>
        <AddCurrencyModal onClose={closeModal} />
      </Modal>} 
    </div>)
}