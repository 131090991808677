import React from "react";
import PlainModal from "../../components/modals/PlainModal";
import { IoCheckmark } from "react-icons/io5";
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/buttons/Button";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import { useHistory } from "react-router-dom";
import useSettings from "../../hooks/useSettings";
import useTeam from "../../hooks/useTeam";

export default React.forwardRef(function CreateTagSuccessModa(props, ref) {
  const history = useHistory();
  const settings = useSettings();
  const {permissions} = useTeam();

  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: () => plainModalRef.current.open(),
    close: () => plainModalRef.current.close(),
    toggle: () => plainModalRef.current.toggle(),
  }));

 

  const onSubmit = () => {
plainModalRef.current.close()
history.go(!permissions?.includes("fund transfer") ? "/dashboard/home" : "/teams/home");
  };

  return (
    <PlainModal
      ref={plainModalRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitleSection={""}
      modelContentSection={
        <div className="flex flex-col">
          <div className="py-6 px-6">
            <div className="my-3 flex justify-center">
              <IoCheckmark
                className="bg-green-100 rounded-full p-2 "
                color={"#5CDB5C"}
                size={"4em"}
              />
            </div>
            <div className="my-9">
              <div>
                <h2 className="glade-black my-3  pb-2 font-bold">Successful</h2>
              </div>
              <div>
                <p className="glade-normal-text-two l">
                  Your user tag has been created. You can now copy and send to
                  your friends to start receiving funds with it
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center mb-3">
              <Button.Blue title={"Continue"} 
              onClick={onSubmit} />
            </div>
          </div>
          <div className="flex mx-auto w-5/12 mb-4  justify-center items-center glade-coming-soon-bg rounded-md px-10 py-3">
              <TextInput
                className={
                  "appearance-none bg-transparent text-glade-blue-500 border-glade-blue-500 "
                }
              />
              <span className="absolute right-52 ">
              { settings?.userDetails?.user?.user_tag &&   <CopyTextButton
                  text={ settings?.userDetails?.user?.user_tag }
                  copyIcon={{ color: "2C6DEA" }} 
                  textClassName='text-md text-glade-blue-500'
                />}
              </span>
            </div>
        </div>
      }
    />
  );
});
