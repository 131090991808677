import { useSelector } from "react-redux";

const useSettlements = () => {
    const settlements = useSelector(state => state.settlements);

    const {
        isLoading,
        isLoadingTransactions,
        shouldReload,
        settlementIndex,
        settledSettlementIndex,
        unsettledSettlementIndex,
        settlementTransactions,
        createSettlementAccount,
        updateSettlementAccount,
        settlementAccounts
    } = settlements;

    return {
        isLoading,
        isLoadingTransactions,
        shouldReload,
        settlementIndex,
        settledSettlementIndex,
        unsettledSettlementIndex,
        settlementTransactions,
        createSettlementAccount,
        updateSettlementAccount,
        settlementAccounts
    };
}

export default useSettlements;
