import Button from "../../components/buttons/Button";
import SelectInput from "../../components/inputs/SelectInput";

const RecurringDetails = ({ input, setInput, setStep, step }) => {
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
      beneficiary_account_name:
        input?.beneficiary_account_number?.length !== 10
          ? ""
          : input?.beneficiary_account_name,
    });
  };
  return (
    <div className="md:w-3.5/12">
      <p className="mt-12 glade-heading-two glade-home-dark">
        <span className="font-bold ">Enter Recurring Payment Details</span>
      </p>
      <div className="text-left flex flex-col gap-4 py-12">
        <div className="text-left py-2">
          <label>Select Frequency</label>
          <SelectInput
            id={"frequency"}
            name={"frequency"}
            value={input?.frequency ?? ""}
            onChange={(e) => handleChange(e)}
            className={"appearance-none"}
          >
            <option value={""}>Select Frequency</option>
            <option value={"daily"}>Daily</option>
            <option value={"weekly"}>Weekly</option>
            <option value={"monthly"}>Monthly</option>
          </SelectInput>
        </div>
        <div className="text-left py-2">
          <label htmlFor="start_date">Start date</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            className="focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"
            value={input?.start_date}
            onChange={handleChange}
          />
        </div>
        <div className="text-left py-2">
          <label htmlFor="start_date">End date (Optional)</label>
          <input
            type="date"
            id="end_date"
            name="end_date"
            className="focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"
            value={input?.end_date}
            placeholder="Optional"
            onChange={handleChange}
          />
        </div>
        <div className="text-left py-2">
          <label htmlFor="start_time">Time</label>
          <input
            type="time"
            id="start_time"
            name="start_time"
            className="focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"
            value={input?.start_time}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-center mt-2">
          <Button.Blue
            className={"px-3"}
            title={"Continue"}
            onClick={() => setStep(step + 1)}
            disabled={
              !input?.start_time || !input?.start_date || !input?.frequency
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RecurringDetails;
