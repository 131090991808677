import { GrFormClose } from "react-icons/gr";
import BasicModal from "../../components/modals/BasicModal";
import { useState } from "react";

const TransactionOptions = ({
  modalTitle,
  showTransactionType,
  setShowTransactionType,
  options,
  emitValue = () => {},
}) => {
  const [showHover, setShowHover] = useState(false);
  return (
    <BasicModal
      visibility={showTransactionType}
      onClose={() => {
        setShowTransactionType(false);
      }}
      primaryButton={true} // Pass true to or valid component to avoid default display
      secondaryButton={true} // Pass true to or valid component to avoid default display
      modalTitleSection={
        <div className="capitalize pb-2 border-b flex justify-between items-center ">
          <div className="font-bold">{modalTitle}</div>
          <GrFormClose
            color="#9CA3AF"
            onClick={() => setShowTransactionType(false)}
          />
        </div>
      }
      modelContentSection={
        <div className="mt-4">
          {options.map((option, key) => (
            <div
              key={key}
              className="px-4 py-3 cursor-pointer mt-1 bg-sky-500 rounded-md"
              onMouseOver={() => setShowHover(key)}
              onMouseLeave={() => setShowHover(false)}
              style={{
                background: showHover === key ? "rgba(44, 109, 234, 0.10)" : "",
                color: showHover === key ? "#032086" : "",
              }}
              onClick={() =>
                emitValue(option) & setShowTransactionType(false)
              }
            >
              <span>{option.title}</span>
            </div>
          ))}
        </div>
      }
    />
  );
};

export default TransactionOptions;
