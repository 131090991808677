import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_SETTLEMENTS,
  LOADING_SETTLEMENT_TRANSACTIONS,
  RELOAD_SETTLEMENTS,
  GET_ALL_SETTLEMENTS,
  GET_SETTLED_SETTLEMENTS,
  GET_UNSETTLED_SETTLEMENTS,
  GET_SETTLEMENT_TRANSACTIONS,
  CREATE_SETTLEMENT_ACCOUNT,
  EDIT_SETTLEMENT_ACCOUNT,
  GET_SETTLEMENT_ACCOUNTS,
} from "./types";

/**
 * Get all settlements
 * 
 * @param {object} payLoad {paginate:1, page:1, per_page:10, status:'settled', date_from:'', date_to:''}
 * @returns {void}
 */
export const getAllSettlements = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SETTLEMENTS, payLoad:true});
    Axios.get(`/banking/settlements/all`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_SETTLEMENTS, payLoad:false});
      dispatch({ type: GET_ALL_SETTLEMENTS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_SETTLEMENTS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get settled settlements
 * 
 * @param {object} payLoad {paginate:1, page:1}
 * @returns {void}
 */
export const getSettledSettlements = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SETTLEMENTS, payLoad:true});
    Axios.get(`/banking/settlements/settled`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_SETTLEMENTS, payLoad:false});
      dispatch({ type: GET_SETTLED_SETTLEMENTS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_SETTLEMENTS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get unsettled settlements
 * 
 * @param {object} payLoad {paginate:1, page:1}
 * @returns {void}
 */
export const getUnsettledSettlements = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SETTLEMENTS, payLoad:true});
    Axios.get(`/banking/settlements/unsettled`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_SETTLEMENTS, payLoad:false});
      dispatch({ type: GET_UNSETTLED_SETTLEMENTS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_SETTLEMENTS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get settlement transactions
 * 
 * @param {object} payLoad {id:'1}
 * @returns {void}
 */
export const getSettlementTransactions = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SETTLEMENT_TRANSACTIONS, payLoad:true});
    Axios.get(`/banking/settlements/${payLoad.id}`)
    .then((res) => {
      dispatch({ type: LOADING_SETTLEMENT_TRANSACTIONS, payLoad:false});
      dispatch({ type: GET_SETTLEMENT_TRANSACTIONS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_SETTLEMENT_TRANSACTIONS, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Create settlement account
 * 
 * @param {object} payLoad {
	"currency": "NGN",
	"account_number": "1789896888",
	"bank_code": "058",
	"status": 1,
  "account_name": "John Doesa",
  "bank_name": "Accessa Bank"
 }
 * @returns {void}
 */
export const createSettlementAccount = (payLoad) => {
  return (dispatch) => {
    Axios.post(`/banking/settlement-accounts`,payLoad)
    .then((res) => {
      dispatch({ type: CREATE_SETTLEMENT_ACCOUNT, payLoad:res});
      dispatch({ type: RELOAD_SETTLEMENTS});
      successHandler(res,true);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Edit settlement account
 * 
 * @param {object} payLoad {
  "id": 1,
	"currency": "NGN",
	"account_number": "1789896888",
	"bank_code": "058",
	"status": 1,
  "account_name": "John Doesa",
  "bank_name": "Accessa Bank"
 }
 * @returns {void}
 */
export const editSettlementAccount = (payLoad) => {
  return (dispatch) => {
    Axios.put(`/banking/settlement-accounts`,payLoad)
    .then((res) => {
      dispatch({ type: EDIT_SETTLEMENT_ACCOUNT, payLoad:res});
      dispatch({ type: RELOAD_SETTLEMENTS});
      successHandler(res,true);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get settlement accounts
 * 
 * @param {object} payLoad {paginate:1, page:1}
 * @returns {void}
 */
export const getSettlementAccounts = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/banking/settlement-accounts`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_SETTLEMENT_ACCOUNTS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}