import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const makeExternalTransferValidation = (payLoad, silently = false) => {

    let data = {
        account_number: payLoad.account_number,
        amount: payLoad.amount,
        can_transact: payLoad.currentBalance
    };
    let rules = {
        account_number: 'required|minSize:9|maxSize:11',
        amount: 'required|min:0|max:999999999999999',
        can_transact: () => { return parseInt(payLoad.amount) >= parseInt(payLoad.currentBalance) ? false : true; }
    };
    let messages = {
        account_number: {
            required: 'An account number is required',
            min: 'Account number is not valid',
            max: 'Account number is not valid'
        },
        amount: {
            required: 'An amount is required',
            min: 'Amount is not valid',
            max: 'Amount is not valid'
        },
        can_transact: {
            custom: 'Insufficient balance'
        }
    };

    // If they are no errors then exit
    let result = validate(data, rules, messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
    });

    return false;
};

export const makeInternalTransferValidation = (payLoad, silently = false) => {

    let data = {
        account_number: payLoad.account_number,
        amount: payLoad.amount,
        can_transact: payLoad.currentBalance
    };
    let rules = {
        account_number: 'required|minSize:9|maxSize:11',
        amount: 'required|min:0|max:999999999999999',
        can_transact: () => { return parseInt(payLoad.amount) >= parseInt(payLoad.currentBalance) ? false : true; }
    };
    let messages = {
        account_number: {
            required: 'An account number is required',
            min: 'Account number is not valid',
            max: 'Account number is not valid'
        },
        amount: {
            required: 'An amount is required',
            min: 'Amount is not valid',
            max: 'Amount is not valid'
        },
        can_transact: {
            custom: 'Insufficient balance'
        }
    };

    // If they are no errors then exit
    let result = validate(data, rules, messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
    });

    return false;
};

export const makeExternalBulkTransferValidation = (payLoad, silently = false) => {

    let data = {
        count: payLoad,
        account_number: payLoad,
        amount: payLoad,
    };
    let rules = {
        count: (payLoad) => {
            return payLoad && payLoad.length < 1 ? false : true;
        },
        account_number: (payLoad) => {
            let checks = [];
            payLoad.forEach(item => {
                if (item.account_number?.length < 0 || item.account_number === '' || item.account_number === undefined || item.account_number === null) {
                    checks.push(false);
                }
                return;
            });
            return checks.length > 0 ? false : true;
        },
        amount: (payLoad) => {
            let checks = [];
            payLoad.forEach(item => {
                if (item.amount < 0 || item.amount === '' || item.amount === undefined || item.amount === null) {
                    checks.push(false);
                }
                return;
            });
            return checks.length > 0 ? false : true;
        },
    };
    let messages = {
        count: {
            custom: 'List of accounts is required',
        },
        account_number: {
            custom: 'Account number is not valid for a single transfer',
        },
        amount: {
            custom: 'Amount is not valid for a single transfer',
        },
    };

    // If they are no errors then exit
    let result = validate(data, rules, messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
    });

    return false;
};

export const makeInternationalTransferValidation = (payLoad, silently = false) => {

    let data = {
        account_number: (!isNaN(payLoad.account_number) && payLoad.account_number !== '') ? payLoad.account_number : payLoad.phone_number,
        amount: payLoad.amount,
        can_transact: payLoad.currentBalance
    };
    let rules = {
        account_number: 'required|minSize:9|maxSize:20',
        amount: 'required|min:0|max:999999999999999',
        can_transact: () => { return parseInt(payLoad.amount) >= parseInt(payLoad.currentBalance) ? false : true; }
    };
    let messages = {
        account_number: {
            required: 'An account number is required',
            min: 'Account number is not valid',
            max: 'Account number is not valid'
        },
        amount: {
            required: 'An amount is required',
            min: 'Amount is not valid',
            max: 'Amount is not valid'
        },
        can_transact: {
            custom: 'Insufficient balance'
        }
    };

    // If they are no errors then exit
    let result = validate(data, rules, messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
    });

    return false;
};