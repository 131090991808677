import React from 'react';
import { useHistory } from "react-router-dom";
import PlainModal from '../../components/modals/PlainModal';
import { BsCreditCard } from 'react-icons/bs';
import { IoTicketOutline } from 'react-icons/io5';
import useTeam from '../../hooks/useTeam';

export default React.forwardRef(function NewPaymentPageModal(props,ref) {

    const history = useHistory();
    const {permissions} = useTeam();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'New Payment Page'}
            modelContentSection={
                <div className="flex flex-col">
                    <div className="flex flex-row p-9 cursor-pointer" onClick={()=>history.push(!permissions?.includes("payment requests") ? "/dashboard/payment-pages/create/page" : "/teams/payment-pages/create/page")}>
                        <div className="flex flex-col">
                            <div className="rounded-full glade-bg-blue-100 p-3">
                                <BsCreditCard size={30} color={'#1634A1'} title={'Payment page'}/>
                            </div>
                        </div>
                        <div className="flex flex-col ml-9">
                            <h2 className="glade-heading-three glade-black text-left">Payment page</h2>
                            <h6 className="glade-normal-text-two glade-ash text-left">Create a page that allows your customers carry out payments</h6>
                        </div>
                    </div>
                    <div className="flex flex-row p-9 cursor-pointer" onClick={()=>history.push(!permissions?.includes("payment requests") ? "/dashboard/payment-pages/create/ticket" : "/teams/payment-pages/create/ticket")}>
                        <div className="flex flex-col">
                            <div className="rounded-full glade-bg-blue-100 p-3">
                                <IoTicketOutline size={30} color={'#1634A1'} title={'Ticketing'}/>
                            </div>
                        </div>
                        <div className="flex flex-col ml-9">
                            <h2 className="glade-heading-three glade-black text-left">Ticketing</h2>
                            <h6 className="glade-normal-text-two glade-ash text-left">Create a page for your customers to buy tickets</h6>
                        </div>
                    </div>
                </div>
            }
        />
    )
})
