import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from "styled-components";
import { BsSearch } from "react-icons/bs";
import Beneficiary from './Beneficiary';
import Button from '../../components/buttons/Button';
import { TransferFormContext } from '../../context/TransferFormContext';
import { toastSuccess } from '../../helpers/utils.toast';
import { getBeneficiaries, getUsertagBeneficiaries } from '../../redux/fund-transfer/fundTransferActions';
import { useDispatch } from 'react-redux';
import useFundTransfer from '../../hooks/useFundTransfer';
import Spinner from '../../loading/Spinner';

const DisplayBeneficiaries = () => {
  const { setTransferForm, setFormStage, formType, isNGNTransfer,  gladeTransferType } = useContext(TransferFormContext);
  const [beneficiarySearchInput, setBeneficiarySearchInput] = useState('');
  const [mode, setMode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const dispatch = useDispatch();
  const { fundTransferBeneficiaries, isLoadingBeneficiaries } = useFundTransfer();

  useEffect(() => {
    if (formType === "glade" && gladeTransferType === "bank") {
      setMode('internal');
    } else if (formType === 'local' && isNGNTransfer) {
      setMode('external_local');
    } else {
      setMode('external_foreign');
    }
    if(formType === "glade" && gladeTransferType === "usertag"){
      dispatch(getUsertagBeneficiaries())
    }else if(mode) {
      dispatch(getBeneficiaries({ mode }));
    }
  }, [formType, isNGNTransfer, dispatch,gladeTransferType, mode]);

  

  useMemo(() => {
    if (!isLoadingBeneficiaries) {
      setIsLoading(false);
      setBeneficiaries(fundTransferBeneficiaries);
    }
    return () => setBeneficiaries([]);
  }, [isLoadingBeneficiaries]);

  function selectBeneficiary(beneficiary) {
    if (isNGNTransfer) {
      toastSuccess(`${beneficiary.account_name} selected as beneficiary`);
      setTransferForm(form => ({
        ...form,
        beneficiary_bank: beneficiary?.bank_code,
        // beneficiary_account_name: beneficiary?.account_name,
        beneficiary_bank_name: beneficiary?.bank_name,
        beneficiary_account_number: beneficiary?.account_number,
        user_tag: beneficiary?.user_tag
      }));
    } else if (formType === "local") {
      const [first_name, ...other_names] = beneficiary?.account_name.split(" ");
      toastSuccess(`${first_name} selected as beneficiary`);
      setTransferForm(form => ({
        ...form,
        beneficiary_account_number: beneficiary?.account_number,
        beneficiary_bank_code: beneficiary?.bank_code,
        beneficiary_first_name: first_name,
        beneficiary_bank_name: beneficiary?.bank_name,
        beneficiary_last_name: other_names.join(" ")
      }));
    } else {
      const [first_name, ...other_names] = beneficiary?.account_name.split(" ");
      toastSuccess(`${first_name} selected as beneficiary`);
      let user_Tag = beneficiary?.user_tag?.split("");
      let pop = user_Tag?.shift();
      setTransferForm(form => ({
        ...form,
        beneficiary_account_number: beneficiary?.account_number,
        beneficiary_bank_code: beneficiary?.bank_code,
        beneficiary_first_name: first_name,
        beneficiary_bank_name: beneficiary?.bank_name,
        beneficiary_last_name: other_names.join(" "),
        user_tag: user_Tag?.join("")
      }));
    }
    setTimeout(() => setFormStage(formType), 1000);
  }

  function onSearchInputChange(e) {
    setBeneficiarySearchInput(e.target.value);
  }

  return (
    <React.Fragment>
      {
        isLoading ? (
          <Spinner infoText={"Loading Beneficiaries"} />
        ) :
          (<Div>
            <section className="page-title mb-24 items-center flex flex-col">
              <h2 className='text-3xl font-bold'>Select a beneficiary</h2>
            </section >
            <React.Fragment>
              {beneficiaries.length !== 0 && <section className="search-input">
                <span className="search-icon"><BsSearch size={21} /></span>
                <input
                  type="text"
                  autoFocus
                  className='focus:border-none focus:ring-0'
                  placeholder='Beneficiary Name'
                  onChange={onSearchInputChange}
                  value={beneficiarySearchInput}
                />
              </section>}

              <section className="beneficiaries flex flex-col gap-8 mt-14">
                {beneficiaries?.length > 0 ? (
                  <React.Fragment>
                    {
                      beneficiaries
                        .filter(beneficiary =>
                          beneficiary.account_name
                            .toLowerCase()
                            .includes(beneficiarySearchInput))
                        .map((beneficiary, key) =>
                          <Beneficiary
                            onClick={() => selectBeneficiary(beneficiary)}
                            beneficiary={beneficiary}
                            key={key}
                          />)
                    }
                  </React.Fragment>
                )
                  : (
                    <React.Fragment>
                      <h2 className='text-center text-xl'>No Beneficiaries</h2>
                    </React.Fragment>
                  )
                }
              </section>
            </React.Fragment>
            <section className='mt-24'>
              <Button.Blue className={"p-2"} title={"Cancel"} onClick={() => setFormStage(formType)} />
            </section>
          </Div >)
      }
    </React.Fragment >
  );
};

export default DisplayBeneficiaries;

const Div = styled.div`
  width: 600px;
  padding-bottom: 2rem;
  .search-input {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 5px;
    border: 3px solid #C2C9D1;
    input {
      width: 100%;
      border: none;
      // position: relative;
      color: #303030;

      &::placeholder {
        color: #C2C9D1;
      }
    }
    .search-icon {
      color: #C2C9D1;
    }
  }

  .beneficiaries {
    min-height: auto;
    max-height: 400px;
    overflow-y: auto;
    padding: 0 0 1rem;
  }
`;
