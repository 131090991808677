import { IoCloseOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import Button from "../../../components/buttons/Button";
import { isEmptyObject } from "../../../helpers/helper";
import { deleteBusinessDirector } from "../../../redux/settings/addBusinessOwners/businessOwnersActions";

export const OwnerInformation = ({
  setFormLevel,
  setOwnerFormType,
  businessType,
  ownerList,
  setOwnerList,
  directorList,
  trusteeList,
  setDirectorList,
  setTrusteeList
}) => {
  const dispatch = useDispatch();
  const removeFromDirectorOrTrusteeList = (listType, index, data) => {
    const onSuccess = () => {
      if (listType === 'director') {
        setDirectorList((prev) => prev.filter((director, id) => id !== index));
      }
      if (listType === 'trustee') {
        setTrusteeList((prev) => prev.filter((trustee, id) => id !== index));
      }
      if (listType === 'primary-owner') setOwnerList({});

    }
    if (listType === 'director' || listType === 'trustee') {
      dispatch(deleteBusinessDirector(data.compliance_id, data.director_compliance_id, 'business-director', onSuccess))
    }
    else {
      dispatch(deleteBusinessDirector(data.compliance_id, data.director_compliance_id,'primary-owner', onSuccess));
    }
  }
  return (
    <>
      <div className="px-12">
        <h3 className="font-bold mb-2 text-glade-black-800">Primary Owner</h3>
      </div>
      <div className="px-12 pb-8 space-y-6">
        <div>
          <h4 className="text-glade-gray-400">
            Please add the primary owner of this business account
          </h4>
          {isEmptyObject(ownerList) ?
            <div className="w-2/3 md:w-1/3 mt-3">
              <Button.Base
                title="Add Primary Owner"
                className="border border-glade-gray-400 text-glade-black-800 bg-gray-50"
                onClick={() => setOwnerFormType("owner") & setFormLevel("owner information form")}
              />
            </div>
            :
            <div className="glade-bg-light-grey w-full mt-3 p-2 glade-grey-cool capitalize flex justify-between items-center">{`${ownerList.first_name} ${ownerList.last_name}`} <IoCloseOutline className="cursor-pointer" onClick={() => removeFromDirectorOrTrusteeList('primary-owner', 0, ownerList)} /></div>
          }
        </div>

        {(businessType === 'NGO') &&
          <div>
            <h3 className="mb-2 font-bold text-glade-black-800">Trustees</h3>
            <h4 className="text-glade-gray-400">
              Please add all other Trustees of this business
            </h4>
            {
              trusteeList?.length > 0 &&
              trusteeList.map((trustee, index) =>
                <div key={index} className="glade-bg-light-grey w-full mt-3 p-2 glade-grey-cool capitalize flex justify-between items-center">{`${trustee.first_name} ${trustee.last_name}`} <IoCloseOutline className="cursor-pointer" onClick={() => removeFromDirectorOrTrusteeList('trustee', index, trustee)} /></div>
              )
            }
            <div className="w-2/3 md:w-1/3 mt-3">
              <Button.Base
                title="Add Trustee"
                className="border border-glade-gray-400 text-glade-black-800 bg-gray-50"
                onClick={() => setOwnerFormType("trustee") & setFormLevel("owner information form")}
              />
            </div>
          </div>}

        {(businessType === 'Partnership' || businessType === 'LLC') &&
          <div>
            <h3 className="mb-2 font-bold text-glade-black-800">Directors</h3>
            <h4 className="text-glade-gray-400">
              Please add all other directors of this business
            </h4>
            {
              directorList?.length > 0 &&
              directorList.map((director, index) =>
                <div key={index} className="glade-bg-light-grey w-full mt-3 p-2 glade-grey-cool capitalize flex justify-between items-center">{`${director.first_name} ${director.last_name}`} <IoCloseOutline className="cursor-pointer" onClick={() => removeFromDirectorOrTrusteeList('director', index, director)} /></div>
              )
            }
            <div className="w-2/3 md:w-1/3 mt-3">
              <Button.Base
                title="Add Directors"
                className="border border-glade-gray-400 text-glade-black-800 bg-gray-50"
                onClick={() => setOwnerFormType("director") & setFormLevel("owner information form")}
              />
            </div>
          </div>}
      </div>
      <div className="p-6">
        <div className="w-1/3 md:w-1/4 float-left ">
          <Button.Transparent
            onClick={() => setFormLevel("business information")}
            className="w-full"
            title={"Back"}
          />
        </div>
        <div className="w-1/3 md:w-1/4 float-right">
          <Button.Blue
            onClick={() => setFormLevel("business documents")}
            className="w-full"
            title={"Next"}
            disabled={
              isEmptyObject(ownerList) ||
              ((businessType === 'Partnership' || businessType === 'LLC') && directorList < 1) ||
              (businessType === "NGO" && trusteeList < 1)
            }
          />
        </div>
      </div>
    </>
  );
};
