import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_POS,
  RELOAD_POS,
  GET_REVENUE,
  GET_SALES,
  GET_STATES,
  GET_LGA,
  REQUEST_PERSONAL_POS,
  REQUEST_BUSINESS_POS,
  CANCEL_REQUEST,
  GET_PENDING_REQUESTS,
  GET_APPROVED_REQUESTS,
  GET_POS_TRANSACTIONS,
  GET_POS_TERMINALS,
  GET_POS_TERMINAL_DETAILS,
  GET_POS_TERMINAL_REQUESTS,
  GET_POS_TERMINAL_REQUESTS_BY_ID,
} from "./types";
import { objectToFormData } from "../../helpers/helper";

/**
 * Get pos revenue
 * @returns {void}
 */
export const getPosRevenue = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_POS, payLoad: true });
    Axios.get(`/pos/lookup/revenue`,{params:payLoad})
      .then((res) => {
      dispatch({ type: LOADING_POS, payLoad: false });
        dispatch({ type: GET_REVENUE, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get pos sales
 * @returns {void}
 */
export const getPosSales = () => {
  return (dispatch) => {
    Axios.get(`/pos/lookup/sales`)
      .then((res) => {
        dispatch({ type: GET_SALES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get pos states
 * @returns {void}
 */
export const getPosStates = () => {
  return (dispatch) => {
    Axios.get(`/pos/lookup/states`)
      .then((res) => {
        dispatch({ type: GET_STATES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get pos LGS
 * @params {object} payLoad {state_id:2}
 * @returns {void}
 */
export const getPosLga = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/pos/lookup/${payLoad.state_id}/lgas`)
      .then((res) => {
        dispatch({ type: GET_LGA, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Request Personal Pos
 * @param {object} payLoad {
  "quantity": "1",
  "revenue_id": "2",
  "sales_id": "2",
  "delivery_address": "no 34 barca",
  "state_id": "1",
  "lga_id": "1",
  "additional_note": "I need this pos please"
 }
 * @returns {void}
 */
export const requestPersonalPos = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_POS, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`/pos/personal`, formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        dispatch({ type: RELOAD_POS });
        dispatch({ type: REQUEST_PERSONAL_POS, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Request Business Pos
 * @param {object} payLoad {
    "quantity": "2",
    "revenue_id": "2",
    "sales_id": "2",
    "delivery_address": "no 34 barawa",
    "state_id": "1",
    "lga_id": "1",
    "additional_note": "I need this pos please"
 }
 * @returns {void}
 */
export const requestBusinessPos = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_POS, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`/pos/business`, formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        dispatch({ type: RELOAD_POS });
        dispatch({ type: REQUEST_BUSINESS_POS, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Cancel Pos Request
 * @param {object} payLoad {"comment" : "tired", "id" : "1"}
 * @returns {void}
 */
export const cancelPosRequest = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_POS, payLoad: true });
    Axios.post(`pos/request/${payLoad.id}/cancel`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        dispatch({ type: RELOAD_POS });
        dispatch({ type: CANCEL_REQUEST, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get all pending pos requests
 * @returns {void}
 */
export const getPendingPosRequests = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_POS, payLoad: true });
    Axios.get(`/pos/history/pending`)
      .then((res) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        dispatch({ type: GET_PENDING_REQUESTS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get all approved pos requests
 * @returns {void}
 */
export const getApprovedPosRequests = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_POS, payLoad: true });
    Axios.get(`/pos/history/approved`)
      .then((res) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        dispatch({ type: GET_APPROVED_REQUESTS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get all approved pos requests
 * @returns {void}
 */
 export const getActivePosTerminals = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_POS, payLoad: true });
    Axios.get(`/pos/history/terminals?status=1`)
      .then((res) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        dispatch({ type: GET_POS_TERMINALS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_POS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get all pos transactions
 * @param {object} payLoad {"pos_id" : "1"}
 * @returns {void}
 */
export const getPosTransactions = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/pos/history/transactions/${payLoad.pos_id}`)
      .then((res) => {
        dispatch({ type: GET_POS_TRANSACTIONS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get all pos terminals
 * @returns {void}
 */
export const getPosTerminals = () => {
  return (dispatch) => {
    Axios.get(`/pos/history/terminals`)
      .then((res) => {
        dispatch({ type: GET_POS_TERMINALS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get all pos terminal details
 * @param {object} payLoad {"pos_id" : "1"}
 * @returns {void}
 */
export const getPosTerminalDetails = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/pos/history/terminals/${payLoad.pos_id}`)
      .then((res) => {
        dispatch({ type: GET_POS_TERMINAL_DETAILS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get all pos terminal requests
 * @returns {void}
 */
export const getPosTerminalRequests = () => {
  return (dispatch) => {
    Axios.get(`/pos/history/request/terminals`)
      .then((res) => {
        dispatch({ type: GET_POS_TERMINAL_REQUESTS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get all pos terminal requests by id
 * @param {object} payLoad {"pos_id" : "1"}
 * @returns {void}
 */
export const getPosTerminalRequestsByID = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/pos/history/request/${payLoad.pos_id}/fetch`)
      .then((res) => {
        dispatch({ type: GET_POS_TERMINAL_REQUESTS_BY_ID, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};
