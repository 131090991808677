import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { WarningIcon } from "../../assets/icons/Icons";
import virtualcard from "../../assets/icons/grouped-vcards.png";
import Button from "../../components/buttons/Button";
import useVirtualCards from "../../hooks/useVirtualCards";
import { filterVirtualCards } from "../../redux/virtual-cards/virtualCardActions";

export default function CreateVirtualCard({ setStep, formData, setFormData }) {
  const virtualCards = useVirtualCards();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dollarLimit, setDollarLimit] = useState();

  React.useEffect(() => {
    dispatch(filterVirtualCards({ currency: "USD", status: 1 }));
    // eslint-disable-next-line
  }, []);

  const handleOptionSelect = (option) => {
    setFormData({ ...formData, currency: option });
  };

  return (
    <div className="p-3 mt-8">
      <div className="flex flex-col">
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <h1 className="text-2xl font-bold">
              Get started with GladeFinance virtual cards
            </h1>
          </div>
          <div className="pb-7 pt-20">
            <img src={virtualcard} className="" alt="virtual_card" />
          </div>
          <div className="text-left">
            <div
              className={`border cursor-pointer ${
                formData.currency === "ngn"
                  ? "text-glade-blue glade-coming-soon-bg"
                  : "border-gray-100"
              } rounded mb-10 py-6 px-6`}
              onClick={() => handleOptionSelect("ngn")}
            >
              <div className="flex gap-2 ">
                <div className="leading-5">
                  <p className="font-medium text-lg">Naira Cards</p>
                  <p
                    className={`${
                      formData.currency === "ngn"
                        ? "text-glade-blue"
                        : "text-glade-gray-500"
                    } text-sm`}
                  >
                    Own a virtual card in naira currency and make online
                    transactions on the go
                  </p>
                </div>
              </div>
              {formData.currency === "ngn" && (
                <div className=" leading-5 pt-2">
                  <div className="flex justify-between  border-b border-solid border-blue-200 py-2">
                    <p className="">Issuance Fee</p>
                    <span>2000 NGN</span>
                  </div>
                  <div className="flex justify-between  border-b border-solid border-blue-200 py-2">
                    <p>Dispute / Chargeback Fee</p>
                    <span>60,000.00 NGN</span>
                  </div>
                  <div className="flex justify-between  border-b border-solid border-blue-200 py-2">
                    <p>Card Funding Fee</p>
                    <span>2% + 1,000.00 NGN</span>
                  </div>
                  <div className="flex justify-between py-2">
                    <p>Maximum Funding</p>
                    <span>5,000,000.00 NGN</span>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`border cursor-pointer ${
                formData.currency === "usd"
                  ? "text-glade-blue glade-coming-soon-bg"
                  : "border-gray-100"
              } rounded mb-10 py-4 px-6`}
              onClick={() => handleOptionSelect("usd")}
            >
              <div className="flex gap-2 ">
                <div className="leading-5 pt-2">
                  <p className="font-medium text-lg">Dollar Cards</p>
                  <p
                    className={`${
                      formData.currency === "usd"
                        ? "text-glade-blue"
                        : "text-glade-gray-500"
                    } text-sm`}
                  >
                    Create one virtual card in dollar currency and make online
                    transactions on the go
                  </p>
                </div>
              </div>
              {formData.currency === "usd" && (
                <div className="text-sm leading-5 pt-2">
                  <div className="flex justify-between  border-b border-solid border-blue-200 py-2">
                    <p className="">Issuance Fee</p>
                    <span>2 USD</span>
                  </div>
                  <div className="flex justify-between  border-b border-solid border-blue-200 py-2">
                    <p>Dispute / Chargeback Fee</p>
                    <span>60 USD</span>
                  </div>
                  <div className="flex justify-between  border-b border-solid border-blue-200 py-2">
                    <p>Card Funding Fee</p>
                    <span>2% + 1 USD</span>
                  </div>
                  <div className="flex justify-between py-2">
                    <p>Maximum Funding</p>
                    <span>10,000 USD</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {formData.currency === "ngn" ? (
            <button
              type="button"
              className="inline-block py-2  text-center rounded-lg text-white bg-glade-blue w-48"
              onClick={() => {
                setStep(2);
              }}
            >
              Create Naira Card
            </button>
          ) : formData.currency === "usd" ? (
            <button
              type="button"
              className="inline-block py-2 text-center rounded-lg text-white bg-glade-blue w-48"
              onClick={() =>
                virtualCards?.virtualCardFilterResults?.length > 1
                  ? setDollarLimit(true)
                  : setStep(2)
              }
            >
              Create Dollar Card
            </button>
          ) : (
            <button
              type="button"
              className="inline-block py-2 text-center rounded text-glade-gray-300 bg-glade-gray w-28"
              disabled
            >
              Create
            </button>
          )}
        </div>
      </div>
      {dollarLimit && (
        <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-8 lg:px-32 py-32 flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <div className="self-center bg-white rounded md:w-1/3 p-5  h-full">
              <div className="mx-auto w-20">
                <WarningIcon />
              </div>
              <div className="text-center mt-6">
                <h4 className="font-bold glade-heading-two-bold">
                  Card Creation Limit Exceeded
                </h4>
                <p className="text-glade-black-800 pt-3">
                  You can only create one dollar card. Delete your active card
                  to create a new one
                </p>
                <div className="mt-6 flex justify-center">
                  <Button.Blue
                    title="Go to Cards"
                    type="button"
                    onClick={() => history.push("/dashboard/cards")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
