import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import PipedButton from '../../components/buttons/PipedButton';
import Button from '../../components/buttons/Button';
import {getPaymentLinks} from '../../redux/payment-pages/paymentPagesActions';
import usePaymentPages from '../../hooks/usePaymentPages';
import {simpleDate, isArray, isEmpty, pagination} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import SearchInput from '../../components/inputs/SearchInput';
import Sieve from '../../components/filter/Sieve';
import NewPaymentPageModal from './NewPaymentPageModal';
import {IoIosArrowForward} from "react-icons/io";
import {KebabDropdown, KebabDropdownItem} from "../../components/dropdowns/KebabDropdown";
import useTeam from '../../hooks/useTeam';

export default function PaymentPages() {

    const newPaymentPageModalRef = React.useRef();

    const history = useHistory();
    const dispatch = useDispatch();
    const {permissions} = useTeam();
    const paymentPages = usePaymentPages();

    React.useEffect(() => {
        dispatch(getPaymentLinks());
        // eslint-disable-next-line 
    }, []);

    // Pagination Function And Control
    const [currentPage, setCurrentPage] = React.useState(1);
    const [tableData, setTableData] = React.useState([]);
    React.useEffect(() => {
        setTableData(pagination(paymentPages.paymentLinkIndex?.links ?? [], 10, currentPage));
        // eslint-disable-next-line 
    }, [currentPage, paymentPages.paymentLinkIndex?.links]);

    // Search Function And Control
    const onSearch = (search) => {
        const filteredData = paymentPages.paymentLinkIndex?.links?.filter(item => {
            return item.title.toLowerCase().includes(search.toLowerCase());
        });
        setTableData(pagination(filteredData ?? [], 10, currentPage));
    }

    // Filter Function And Control
    const onFilter = (data) => {
        dispatch(getPaymentLinks({
            status:data.status,
            date_from:data.date_from??data.date,
            date_to:data.date_to??new Date().toISOString().substring(0, 10),
        }));
    }

    // Reset Filter Function And Control
    const onReset = () => {
        dispatch(getPaymentLinks());
    }

    const renderPaymentPagesTable = (data) => {
        const onClickOfRow = (item) => {
            history.push({pathname: !permissions?.includes("payment requests") ? "/dashboard/payment-page-detail" : "/teams/payment-page-detail", state:item});
        }

        return data.map((item,key)=>{
            return (
                <CardTableBodyRow key={key} className="cursor-pointer">
                    <CardTableBodyCell onClick={()=>onClickOfRow(item)}>
                    </CardTableBodyCell>
                    <CardTableBodyCell onClick={()=>onClickOfRow(item)}>
                        <span className="glade-normal-text-two">{item.title}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell onClick={()=>onClickOfRow(item)}>
                        <span className="glade-normal-text-two">{item?.meta_data?.currency}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell onClick={()=>onClickOfRow(item)}>
                        <span className="glade-normal-text-two">{item?.meta_data?.is_ticket ? 'Ticket':'One-Time'}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell onClick={()=>onClickOfRow(item)}>
                        <span className="glade-normal-text-two">{item?.meta_data?.currency} {item.amount}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell onClick={()=>onClickOfRow(item)}>
                        <span className="glade-normal-text-two">{simpleDate(item.date)}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <PipedButton 
                            primaryButtonTitle="Edit" 
                            primaryButtonOnClick={()=>{
                                return item?.meta_data?.is_ticket === 1?
                                history.push({pathname: !permissions?.includes("payment requests") ? "/dashboard/payment-pages/edit/ticket" : "/teams/payment-pages/edit/ticket", state:item}):
                                history.push({pathname: !permissions?.includes("payment requests") ? "/dashboard/payment-pages/edit/page" : "/teams/payment-pages/edit/page", state:item});
                            }}
                            secondaryButtonTitle="Preview" 
                            secondaryButtonOnClick={()=>window.open(item?.url ?? process.env.REACT_APP_PUBLIC_URL, "_blank")}
                        />
                    </CardTableBodyCell>
                    <CardTableBodyCell onClick={()=>onClickOfRow(item)}>
                    </CardTableBodyCell>
                </CardTableBodyRow>
            )
        });
    }

    const renderPaymentPagesDesk = (data) => {
        return data.map((item, key) => {
          return (
            <div key={key} className="flex flex-row justify-between p-1 border-b" onClick={()=>history.push({pathname:!permissions ? '/dashboard/payment-page-detail' : '/teams/payment-page-detail',state:item})}>
              <div className="flex flex-row">
                <div className="flex flex-col space-y-1 py-1">
                  <div>
                    <span className="glade-subtitle-one glade-black whitespace-nowrap">
                        {item.title}
                    </span>
                  </div>
                  <div>
                    <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                        {item?.meta_data?.is_ticket ? 'Ticket':'One-Time'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <IoIosArrowForward />
              </div>
            </div>
          );
        });
    };

    return (
        <div className="p-3">
            <CardTableHeaderComplex 
                title={`Showing ${tableData.totalItems} records`}
                filter={<Sieve types={['status','date']} onFilter={(value)=>onFilter(value)} onReset={()=>onReset()} disabled={paymentPages.isLoading} loading={paymentPages.isLoading} />}
                searchBar={<SearchInput disabled={paymentPages.isLoading} onSearch={onSearch} placeholder={'Page Name'} />}
                rightButton={
                    <div>
                        <Button.Blue title={'New Payment Page'} onClick={()=>newPaymentPageModalRef.current.open()}/>
                    </div>
                }
                kebabMenuButton={
                    <div>
                      <KebabDropdown className="z-10" disabled={paymentPages.isLoading}>
                        <KebabDropdownItem
                          activeChild={<Sieve
                            button={<div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"> Filter </div>}
                            types={["status", "date"]}
                            onFilter={(value) => onFilter(value)}
                            onReset={() => onReset()}
                            disabled={paymentPages.isLoading}
                            loading={paymentPages.isLoading}
                          />}
                          inactiveChild={<span className="glade-normal-text-two glade-black p-4"> Filter </span>}
                        />
                        <KebabDropdownItem
                          activeChild={<span onClick={()=>newPaymentPageModalRef.current.open()} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">New Payment Page</span>}
                          inactiveChild={<span className="glade-normal-text-two glade-black p-4"> New Payment Page</span>}
                        />
                      </KebabDropdown>
                    </div>
                  }
            />

            {isEmpty(tableData.data) || paymentPages.isLoading?
                <React.Fragment>
                    <div className="hidden md:block">
                        <EmptyTable column={['','Name','Currency','Type','Amount','Date Created','','']} loading={paymentPages.isLoading} />
                    </div>

                    <div className="block md:hidden">
                        <EmptyDesk row={[1,2]} loading={paymentPages.isLoading} />
                    </div>
                </React.Fragment>
            :
                <React.Fragment>
                    <div className="hidden md:block">
                        <CardTableBody column={['','Name','Currency','Type','Amount','Date Created','','']}>
                            {isArray(tableData.data) && renderPaymentPagesTable(tableData.data)}
                        </CardTableBody>
                    </div>

                    <div className="block md:hidden">
                        <div className="bg-white px-6">
                            {isArray(tableData.data) && renderPaymentPagesDesk(tableData.data)}
                        </div>
                    </div>
                </React.Fragment>
            }

            <CardTableFooter 
                empty={isEmpty(tableData.data)}
                loading={paymentPages.isLoading}
                pageNumber={currentPage} 
                totalPages={tableData.totalPages}
                prevOnClick={()=>setCurrentPage(tableData.previousPage)}
                nextOnClick={()=>setCurrentPage(tableData.nextPage)}
                thereIsPreviousPage={tableData.thereIsPreviousPage}
                thereIsNextPage={tableData.thereIsNextPage}
            />

            <NewPaymentPageModal ref={newPaymentPageModalRef} />
        </div>
    )
}
