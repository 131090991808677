import { MdClose, MdOutlineModeEditOutline } from "react-icons/md";
import Button from "../../../components/buttons/Button";
import NumericInput from "../../../components/inputs/NumericInput";
import TextInput from "../../../components/inputs/TextInput";
import ReceiverFlag from "../../../components/transfer/ReceiverFlag";
import { truncateText } from "../../../helpers/helper";

const BulkStepOne = ({ setBulkStep, bulkStep, setStep, input, setInput }) => {
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const editInList = (id) => {
    const editData = input?.payload?.filter((_data, key) => id === key);
    let newPayload = input?.payload?.filter((_data, key) => id !== key);
    setInput({ ...input, payload: [...newPayload, ...editData] });
    setBulkStep(bulkStep + 2);
  };
  const deleteFromList = (id) => {
    let newPayload = input?.payload?.filter((_data, key) => id !== key);
    setInput({ ...input, payload: newPayload });
  };
  return (
    <div className="md:w-3.5/12">
      <p className="mt-12 glade-heading-two glade-home-dark">
        <span className="font-bold">Enter Recurring Payment Details</span>
      </p>
      <div className="text-left flex flex-col gap-6 py-12">
        <TextInput
          label={"Group Name"}
          name={"group_name"}
          id={"group_name"}
          type={"text"}
          value={input?.group_name}
          placeholder={"Enter group name"}
          onChange={handleChange}
        />

        <NumericInput
          id="amount"
          label={"Amount"}
          type={"number"}
          placeholder={"Enter amount"}
          name={"amount"}
          value={input?.amount}
          onChange={handleChange}
          leftSection={
            <div className="border rounded-sm px-4 flex gap-2 items-center">
              <span className="flag">
                <ReceiverFlag flagCode={"NG"} />
              </span>
              <span>NGN</span>
            </div>
          }
        />

        {input?.payload?.map((receiver, key) => {
          return (
            <div
              className="border rounded-sm px-2 py-2 flex flex-row justify-between"
              key={key}
            >
              <div>
                {truncateText(
                  `${receiver?.beneficiary_account_name} | 
                ${receiver?.beneficiary_account_number} | 
                ${receiver?.beneficiary_bank_name ?? "Gladefinance"}`,
                  30
                )}
              </div>
              <span className="flex">
                <MdOutlineModeEditOutline
                  className="glade-grey-dark cursor-pointer"
                  size={20}
                  onClick={() => editInList(key)}
                />{" "}
                <MdClose
                  className="glade-grey-dark cursor-pointer"
                  size={20}
                  onClick={() => deleteFromList(key)}
                />
              </span>
            </div>
          );
        })}

        {(
          <span
            className="glade-blue cursor-pointer"
            onClick={() => setBulkStep(bulkStep + 1)}
          >
            <span className="glade-heading-three">+</span> Add Recipient
          </span>
        )}

        {input?.payload?.length > 0 && input?.group_name && input?.amount && (
          <div className="flex justify-center mt-2">
            <Button.Blue
              className={"px-3"}
              title={"Set Schedule"}
              onClick={() => setStep(3)}
              disabled={!input?.group_name || !input?.amount}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default BulkStepOne;
