import { useSelector } from "react-redux";

const useAvailableCurrencies = () => {
  const currencies = useSelector((state) => state.currencies);

  const {
    isLoading,
    availableCurrencies,
    businessCurrencyInfo
  } = currencies;



  return {
    isLoading,
    availableCurrencies,
    businessCurrencyInfo
  };
};

export default useAvailableCurrencies;
