import React from 'react';
import styled from 'styled-components';

const NavAvatar = ({ onClick, getInitials = () => { } }) => {
  return (
    <Div
      onClick={onClick}
    >
      <h3 className="glade-heading-four" style={{ color: '#9CA3AF' }}>{getInitials() || "JD"}</h3>
    </Div>
  );
};

export default NavAvatar;

const Div = styled.div`
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;
  background-color: #E4E4F3;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
`;