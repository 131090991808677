import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardPlainWithButton, CardPlainWithButtonSection } from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import ImageInput from '../../components/inputs/ImageInput';
import SelectInput from '../../components/inputs/SelectInput';
import useSettings from "../../hooks/useSettings";
import { isArray } from "../../helpers/helper";
import { getStatusOfAgentRequest, submitAgentRequest, getBAAFile } from '../../redux/settings/settingsActions';
import { getComplianceCountries, getComplianceStates, getComplianceLga, } from "../../redux/settings/settingsActions";
import { becomeAnAgentValidation } from "../../validation/settings/settingsValidation";
export default function SettingsManageServicesBAA() {

    const dispatch = useDispatch();
    const history = useHistory();
    const settings = useSettings();
    const [input, setInput] = React.useState({
        address: '',
        user_state: '',
        user_lga: '',
        guarantor_form: [],
        guarantor_template: '',
        compliance_country: 1,
        compliance_state: "",
        compliance_lga: "",
    });

    useEffect(() => {
        dispatch(getStatusOfAgentRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        !input.compliance_country && dispatch(getComplianceCountries());
        input.compliance_country && dispatch(getComplianceStates({ country_id: input.compliance_country }));
        input.compliance_state && dispatch(getComplianceLga({ state_id: input.compliance_state }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input.compliance_country, input.compliance_state]);

    const onSubmit = () => {
        if (becomeAnAgentValidation(input)) {
            dispatch(
                submitAgentRequest({
                    address: input.address,
                    state_id: input.compliance_state,
                    lga_id: input.compliance_lga,
                    guarantor_document: input.guarantor_form[0]
                }, () => history.goBack())
            );
        }
    };


    const onChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    };
    const downloadPdfFile = () => {
        dispatch(getBAAFile());
    };

    return (
        <div className="p-3">
            <div className="flex flex-col">

                <div className="mb-6">
                    <BreadCrumbs title={'Manage Services'} path={'/dashboard/settings?tab=4'} />
                </div>

                <CardPlainWithButton>
                    <CardPlainWithButtonSection
                        title={'Become an Agent'}
                        loading={settings.isLoading}
                        buttonTitle={'Submit'}
                        buttonOnClick={() => onSubmit()}
                        buttonDisabled={settings.isLoading || !becomeAnAgentValidation(input, true)}>
                        <div className="mt-6 px-14">
                            <div className="space-y-6">

                                <TextAreaInput
                                    label={'Contact Address'}
                                    id={'address'}
                                    name={'address'}
                                    rows={3}
                                    type={'text'}
                                    value={input.address ?? ''}
                                    onChange={onChange}
                                    placeholder={'Enter address'}
                                    autoComplete={'address'}
                                    className={'appearance-none'}
                                />

                                <SelectInput
                                    label={"Select State"}
                                    id={"compliance_state"}
                                    name={"compliance_state"}
                                    value={input.compliance_state ?? ""}
                                    onChange={onChange}
                                    autoComplete={"compliance_state"}
                                    className={"appearance-none"}
                                >
                                    <option value={""} disabled={true}>Select A State</option>
                                    {isArray(settings.complianceStates) && settings.complianceStates?.map((state, index) => (
                                        <option key={index} value={state.state_id}>{state.state_name}</option>
                                    ))}
                                </SelectInput>

                                <SelectInput
                                    label={"Select LGA"}
                                    id={"compliance_lga"}
                                    name={"compliance_lga"}
                                    value={input.compliance_lga ?? ""}
                                    onChange={onChange}
                                    autoComplete={"compliance_lga"}
                                    className={"appearance-none"}
                                >
                                    <option value={""} disabled={true}>Select A Local Government Area</option>
                                    {isArray(settings.complianceLga) && settings.complianceLga?.map((lga, index) => (
                                        <option key={index} value={lga.city_id}>{lga.city_name}</option>
                                    ))}
                                </SelectInput>

                                <ImageInput
                                    label={'Upload Guarantor’s Form'}
                                    id={'guarantor_form'}
                                    name={'guarantor_form'}
                                    multiple={false}
                                    accept=".pdf"
                                    returnInput={(value) => setInput({ ...input, guarantor_form: value })}
                                />
                                <button className="glade-small-text-one glade-blue mt-3" onClick={() => downloadPdfFile()}>Download Guarantor’s Form</button>
                            </div>
                        </div>
                    </CardPlainWithButtonSection>
                </CardPlainWithButton>
            </div>
        </div>
    );
}
