import React from "react";

export default function SidebarGroupLink({
  children,
  title,
  onClick = () => {},
}) {
  return (
    <div>
      {/* Heading */}
      <div
        className="flex items-center justify-between mt-1 mb-4"
        onClick={() => onClick(title)}>
        <h6 className="glade-normal-text-one glade-black uppercase block">
          {title}
        </h6>
      </div>

      <React.Fragment>
        {/* Navigation */}
        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
          {children}
        </ul>

        {/* Divider */}
        <div className="mt-4 mb-10 md:min-w-full" />
      </React.Fragment>
    </div>
  );
}
