import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/buttons/Button";
import {
  getComplianceIdentityTypes,
  submitPersonalCompliance
} from "../../redux/settings/settingsActions";
import {
  getApprovedCountries,
  getApprovedStates,
  getApprovedLGAs
} from "../../redux/countries/countriesActions"
import useCountries from '../../hooks/useCountry';
import { isArray, isObject, toTitleCase } from "../../helpers/helper";
import { personalComplianceValidation } from "../../validation/settings/settingsValidation";
import TextInput from "../../components/inputs/TextInput";
import FileInput from "../../components/inputs/FileInput";
import SelectInput from "../../components/inputs/SelectInput";
import useSettings from "../../hooks/useSettings";
import { ImSpinner2 } from "react-icons/im";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
// import NoticeModal from '../../components/modals/NoticeModal';
import useUser from "../../hooks/useUser";

export default function HomePersonalCompliance() {
  // const NoticeModalRef = React.useRef(null);
  const dispatch = useDispatch();
  const settings = useSettings();
  const history = useHistory();
  const user = useUser();
  const countries = useCountries();
  const { isLoadingCompliance } = settings;
  const [input, setInput] = React.useState({
    address: "",
    compliance_country: "",
    compliance_state: "",
    compliance_lga: "",
    personal_proof_of_address: [],
    dob: "",
    day: "",
    month: "",
    year: "",
    city: "",
    postal_code: "",
    id_number: "",
    id_type: "",
    id_upload: [],
    country_id: "",
    state_id: ""
  });
  const [errorState, setErrorState] = React.useState(false);
  const [errorAddress, setErrorAddress] = React.useState(false);
  const [errorPostalCode, setErrorPostalCode] = React.useState(false);
  const [errorCity, setErrorCity] = React.useState(false);
  const [errorLga, setErrorLga] = React.useState(false);
  const [errorFile, setErrorFile] = React.useState(false);
  const [errorDob, setErrorDob] = React.useState(false);
  const [errorDay, setErrorDay] = React.useState(false);
  const [errorMonth, setErrorMonth] = React.useState(false);
  const [errorYear, setErrorYear] = React.useState(false);
  const [errorDayMonth, setErrorDayMonth] = React.useState(false);
  const [errorIdType, setErrorIdType] = React.useState(false);
  const [errorIdNumber, setErrorIdNumber] = React.useState(false);
  const [errorIdUpload, setErrorIdUpload] = React.useState(false);

  useEffect(() => {
    async function fetchCountries() {
      const response = await dispatch(getApprovedCountries({ status: 1 }))
      return response;
    }
    fetchCountries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function findCountry() {
      if (isArray(countries?.getCountries)) {
        let country = await countries?.getCountries?.find(country => country.country_name === user.userInfo?.country_name);
        if (input.compliance_state !== "") {
          let stateObj = isArray(countries?.getCountries) && countries?.getStates?.find(state => state?.state_name === input?.compliance_state);
          setInput({ ...input, state_id: stateObj?.state_id });
        }
        if (isObject(country)) {
          setInput({ ...input, compliance_country: country?.country_id, country_id: country?.country_id });
        }
      }   
  }     
  findCountry()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries?.getCountries])

  const getCountryName = () => {
    let country = countries?.getCountries?.find(country => country.country_name === user.userInfo?.country_name);
    if (isObject(country)) {
      return { country_name: country?.country_name, country_code: country?.country_code, country_id: country?.country_id };
    }
  }

  const getStateObject = (val) => {
    if (val !== "") {
      let stateObj = isArray(countries?.getStates) && countries?.getStates?.find(state => state?.state_name === val);
      if (isObject(stateObj)) {
        return { state_name: stateObj?.state_name, state_code: stateObj?.stateObj_code, state_id: stateObj?.state_id };
      }
    }
  }

  useEffect(() => {
    if (input.country_id !== "") {
      dispatch(getApprovedStates({ country_id: input?.country_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.country_id])

  useEffect(() => {
    dispatch(getComplianceIdentityTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (input?.compliance_state) {
      dispatch(getApprovedLGAs({ state_id: getStateObject(input.compliance_state)?.state_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.compliance_state]);


  const onSubmit = () => {

    if (input.address.length < 1) {
      setErrorAddress(true);
    }
    if (input.city.length < 1) {
      setErrorCity(true);
    }
    if (input.postal_code.length < 1) {
      setErrorPostalCode(true);
    }
    if (input.compliance_state === "") {
      setErrorState(true);
    }
    if (input.compliance_lga === "") {
      setErrorLga(true);
    }
    if (!input.personal_proof_of_address?.length) {
      setErrorFile(true);
    }
    if (input.day === "") {
      setErrorDay(true);
    }
    if (input.month === "") {
      setErrorMonth(true);
    }
    if (input.year === "") {
      setErrorYear(true);
    }
    if (input.dob === "") {
      setErrorDob(true);
    }
    if (input.id_type === "") {
      setErrorIdType(true)
    }
    if (input.id_number === "") {
      setErrorIdNumber(true)
    }
    if (
      input.month === "04" ||
      input.month === "06" ||
      input.month === "09" ||
      input.month === "11"
    ) {
      if (input.day > 30) {
        setErrorDayMonth(true);
        setErrorDay(true);
        return false;
      }
    }
    if (input.month === "02") {
      if (checkLeapYear(input.year)) {
        if (input.day > 29) {
          setErrorDayMonth(true);
          setErrorDay(true);
          return false;
        }
      } else {
        if (input.day > 28) {
          setErrorDayMonth(true);
          setErrorDay(true);
          return false;
        }
      }
    }

    if (personalComplianceValidation(input)) {
      let data = {
        proof_of_address: input.personal_proof_of_address[0],
        address: input.address,
        state: input.compliance_state,
        dob: input.dob,
        country: input.compliance_country,
        id_card: input.id_upload[0],
        id_card_type: input.id_type,
        id_card_number: input.id_number
      };
      if (getCountryName()?.country_name?.toLocaleLowerCase() === "nigeria") {
        data.lga = countries?.getLgas?.find(
          (item) =>
            item.id?.toString() === input.compliance_lga?.toString()
        )?.name;
      }
      if (input?.country_name?.toLocaleLowerCase() !== "nigeria") {
        data.city = input.city;
        data.postal_code = input.postal_code;
      }
      dispatch(
        submitPersonalCompliance(data, () => {
          dispatch(getPersonalAndBusinessAccounts());
          history.push("/dashboard/home");
        })
      );
    }
  };

  // Function to check leap year
  function checkLeapYear(year) {
    const leap = new Date(year, 1, 29).getDate() === 29;
    if (leap) {
      return true;
    } else {
      return false;
    }
  }

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setErrorFile(false);
    setErrorIdUpload(false);
    if (e.target.name === "compliance_state") {
      if (e.target.value.length > 0) {
        setErrorState(false);
      } else {
        setErrorState(true);
      }
    }
    if (e.target.name === "address") {
      if (e.target.value.length > 0) {
        setErrorAddress(false);
      } else {
        setErrorAddress(true);
      }
    }
    if (
      user.userInfo.country_name !== "Nigeria" &&
      e.target.name === "postal_code"
    ) {
      if (e.target.value.length > 0) {
        setErrorPostalCode(false);
      } else {
        setErrorPostalCode(true);
      }
    }
    if (user.userInfo.country_name !== "Nigeria" && e.target.name === "city") {
      if (e.target.value.length > 0) {
        setErrorCity(false);
      } else {
        setErrorCity(true);
      }
    }
    if (
      user.userInfo.country_name === "Nigeria" &&
      e.target.name === "compliance_lga"
    ) {
      if (e.target.value.length > 0) {
        setErrorLga(false);
      } else {
        setErrorLga(true);
      }
    }
    if (e.target.name === "id_type") {
      setErrorIdType(false);
    }
    if (e.target.name === "id_number") {
      setErrorIdNumber(false);
    }
  };

  const handleDateChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });

    if (e.target.name === "day") {
      if (e.target.value !== "") {
        setErrorDay(false);
        setErrorDayMonth(false);
        if (input.month !== "" && input.year !== "") {
          setInput({
            ...input,
            day: e.target.value,
            dob: input.year + "-" + input.month + "-" + e.target.value,
          });
          setErrorDob(false);
        }
      } else {
        setErrorDay(true);
        setErrorDob(true);
      }
    }

    if (e.target.name === "month") {
      if (e.target.value !== "") {
        setErrorMonth(false);
        setErrorDayMonth(false);
        if (input.day !== "" && input.year !== "") {
          setInput({
            ...input,
            month: e.target.value,
            dob: input.year + "-" + e.target.value + "-" + input.day,
          });
          setErrorDob(false);
        }
      } else {
        setErrorMonth(true);
        setErrorDob(true);
      }
    }

    if (e.target.name === "year") {
      if (e.target.value !== "") {
        setErrorYear(false);
        setErrorDayMonth(false);
        if (input.day !== "" && input.month !== "") {
          setInput({
            ...input,
            year: e.target.value,
            dob: e.target.value + "-" + input.month + "-" + input.day,
          });
          setErrorDob(false);
        }
      } else {
        setErrorYear(true);
        setErrorDob(true);
      }
    }
  };

  const generateArrayOfYears = () => {
    var max = new Date().getFullYear();
    var years = [];

    for (var i = max; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };

  return (
    <div className="p-3">
      <div className="flex flex-col justify-center md:w-3/5 w-full mx-auto">
        {!isLoadingCompliance ? (
          <div className={"flex flex-col border rounded"}>
            <div className="bg-white py-4 overflow-hidden rounded-sm border-b-2 border-solid">
              <div className="py-2 px-2 md:px-4 lg:px-14">
                <h4 className="glade-heading-three glade-black">
                  Please tell us about yourself
                </h4>
              </div>
              <div className="mt-6 px-2 md:px-4 lg:px-14">
                <div className="space-y-6">
                  <div className="space-y-2">
                    {/* <NewDateInput 
                        day={input.day ?? ''}
                        month={input.month ?? ''}
                        year={input.year ?? ''}
                        onChange={handleDateChange}
                      /> */}
                    <div className={"flex space-x-3 items-center"}>
                      <div className="w-1/3">
                        <SelectInput
                          label={"Date of Birth"}
                          id={"day"}
                          name={"day"}
                          value={input.day ?? ""}
                          onChange={handleDateChange}
                          autoComplete={"day"}
                          className={`appearance-none rounded-md ${errorDay ? "border-2 border-red-500" : ""
                            }`}
                        >
                          <option value={""} disabled={true}>
                            Day
                          </option>
                          {[
                            "01",
                            "02",
                            "03",
                            "04",
                            "05",
                            "06",
                            "07",
                            "08",
                            "09",
                            "10",
                            "11",
                            "12",
                            "13",
                            "14",
                            "15",
                            "16",
                            "17",
                            "18",
                            "19",
                            "20",
                            "21",
                            "22",
                            "23",
                            "24",
                            "25",
                            "26",
                            "27",
                            "28",
                            "29",
                            "30",
                            "31",
                          ].map((day, index) => {
                            return (
                              <option key={index} value={day}>
                                {day}
                              </option>
                            );
                          })}
                        </SelectInput>
                      </div>
                      <div className="w-1/3 mt-3">
                        <SelectInput
                          label={""}
                          id={"month"}
                          name={"month"}
                          value={input.month ?? ""}
                          onChange={handleDateChange}
                          autoComplete={"month"}
                          className={`appearance-none rounded-md ${errorMonth ? "border-2 border-red-500" : ""
                            }`}
                        >
                          <option value={""} disabled={true}>
                            Month
                          </option>
                          {[
                            { month: "January", id: "01" },
                            { month: "February", id: "02" },
                            { month: "March", id: "03" },
                            { month: "April", id: "04" },
                            { month: "May", id: "05" },
                            { month: "June", id: "06" },
                            { month: "July", id: "07" },
                            { month: "August", id: "08" },
                            { month: "September", id: "09" },
                            { month: "October", id: "10" },
                            { month: "November", id: "11" },
                            { month: "December", id: "12" },
                          ].map((month, index) => {
                            return (
                              <option key={index} value={month?.id}>
                                {month?.month}
                              </option>
                            );
                          })}
                        </SelectInput>
                      </div>
                      <div className="w-1/3 mt-3">
                        <SelectInput
                          label={""}
                          id={"year"}
                          name={"year"}
                          value={input.year ?? ""}
                          onChange={handleDateChange}
                          autoComplete={"year"}
                          className={`appearance-none rounded-md ${errorYear ? "border-2 border-red-500" : ""
                            }`}
                        >
                          <option value={""} disabled={true}>
                            Year
                          </option>
                          {generateArrayOfYears()?.map((year, index) => {
                            return (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </SelectInput>
                      </div>
                    </div>
                    {errorDob && (
                      <p className="text-red-500">Date of Birth is required</p>
                    )}
                    {errorDayMonth && (
                      <p className="text-red-500">
                        Selected month does not have {input.day} days
                      </p>
                    )}
                  </div>

                  <div className="space-y-2">
                    <TextInput
                      label={"Residential Address"}
                      id={"address"}
                      name={"address"}
                      type={"text"}
                      value={input.address}
                      onChange={onChange}
                      placeholder={"Enter Residential Address"}
                      autoComplete={"address"}
                      className={`appearance-none rounded-md ${errorAddress
                        ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                        : ""
                        }`}
                      errorState={errorAddress}
                    />
                    {errorAddress && (
                      <p className="text-red-500">
                        Residential Address is a required field
                      </p>
                    )}
                  </div>
                  {input.compliance_country !== "Nigeria" && (
                    <>
                      <TextInput
                        label={"City"}
                        id={"city"}
                        name={"city"}
                        type={"text"}
                        value={input.city}
                        onChange={onChange}
                        placeholder={"Enter City"}
                        autoComplete={"city"}
                        className={`appearance-none rounded-md ${errorCity
                          ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                          : ""
                          }`}
                        errorState={errorCity}
                      />
                      {errorCity && (
                        <p className="text-red-500">City is a required field</p>
                      )}
                    </>
                  )}

                  <div className="space-y-2">
                    <SelectInput
                      label={"State"}
                      id={"compliance_state"}
                      name={"compliance_state"}
                      value={input.compliance_state ?? ""}
                      onChange={onChange}
                      autoComplete={"compliance_state"}
                      className={`appearance-none rounded-md ${errorState ? "border-2 border-red-500" : ""
                        }`}
                    >
                      <option value={""} disabled={true}>
                        Select State
                      </option>
                      {isArray(countries?.getStates) && countries.getStates?.map((state, index) => (
                        <option key={index} value={state.state_name}>{state.state_name}</option>
                      ))}
                    </SelectInput>
                    {errorState && (
                      <p className="text-red-500">State is a required field</p>
                    )}
                  </div>
                  {user?.userInfo?.country_name !== "Nigeria" && (
                    <>
                      <TextInput
                        label={"Postal Code"}
                        id={"postal_code"}
                        name={"postal_code"}
                        type={"text"}
                        value={input.postal_code}
                        onChange={onChange}
                        placeholder={"Enter Postal Code"}
                        autoComplete={"postal_code"}
                        className={`appearance-none rounded-md ${errorPostalCode
                          ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                          : ""
                          }`}
                        errorState={errorPostalCode}
                      />
                      {errorPostalCode && (
                        <p className="text-red-500">
                          Postal is a required field
                        </p>
                      )}
                    </>
                  )}
                  {user?.userInfo?.country_name === "Nigeria" && (
                    <>
                      <div className="space-y-2">
                        <SelectInput
                          label={"LGA"}
                          id={"compliance_lga"}
                          name={"compliance_lga"}
                          value={input.compliance_lga ?? ""}
                          onChange={onChange}
                          autoComplete={"lga"}
                          className={`appearance-none rounded-md ${errorLga ? "border-2 border-red-500" : ""
                            }`}
                        >
                          <option value={""} disabled={true}>
                            Select LGA
                          </option>
                          {isArray(countries?.getLgas) && countries.getLgas?.map((lga, index) => (
                            <option key={index} value={lga.id}>{lga.name}</option>
                          ))}
                        </SelectInput>
                        {errorLga && (
                          <p className="text-red-500">LGA is a required field</p>
                        )}
                      </div>
                      <div className="space-y-2">
                        <SelectInput
                          label={"ID Type"}
                          id={"id_type"}
                          name={"id_type"}
                          value={input.id_type ?? ""}
                          onChange={onChange}
                          autoComplete={"lga"}
                          className={`appearance-none rounded-md ${errorIdType ? "border-2 border-red-500" : ""
                            }`}
                        >
                          <option value={""} disabled={true}>
                            Select ID
                          </option>
                          {isArray(settings.complianceIdentityTypes) &&
                            settings.complianceIdentityTypes?.map((type, index) => (
                              <option key={index} value={type.type_name}>
                                {toTitleCase(type.type_name ?? "")}
                              </option>
                            ))}
                        </SelectInput>
                        {errorIdType && (
                          <p className="text-red-500">ID Type is a required field</p>
                        )}
                      </div>
                      <div className="space-y-2">
                        <TextInput
                          label={"ID Number"}
                          id={"id_number"}
                          name={"id_number"}
                          type={"text"}
                          value={input.id_number}
                          onChange={onChange}
                          placeholder={"Enter ID Number"}
                          autoComplete={"id_number"}
                          className={`appearance-none rounded-md ${errorIdNumber
                            ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                            : ""
                            }`}
                          errorState={errorIdNumber}
                        />
                        {errorIdNumber && (
                          <p className="text-red-500">
                            ID Number is a required field
                          </p>
                        )}
                      </div>
                      <div className="space-y-2">
                        <FileInput
                          label={"ID Upload"}
                          id={"id_upload"}
                          name={"id_upload"}
                          accept={".pdf, .png, .jpeg, .jpg"}
                          maximumSize={3}
                          multiple={true}
                          returnInput={(value) => {
                            setErrorIdUpload(false);
                            setInput({
                              ...input,
                              id_upload: value,
                            });
                          }}
                          className={`${errorIdUpload ? "border-red-500 " : ""}`}
                        />
                        <div className="glade-subtitle-two">
                          <h5>
                            Supported file types: PDF, PNG & JPEG. Max file size
                            allowed is 3MB.
                          </h5>
                        </div>
                        {errorIdUpload && (
                          <p className="text-red-500">
                            Upload a valid format (JPG, PNG, PDF)
                          </p>
                        )}
                      </div>

                    </>
                  )}

                  <div className="space-y-2">
                    <FileInput
                      label={"Proof of Address"}
                      id={"personal_proof_of_address"}
                      name={"personal_proof_of_address"}
                      accept={".pdf, .png, .jpeg, .jpg"}
                      maximumSize={3}
                      multiple={true}
                      returnInput={(value) => {
                        setErrorFile(false);
                        setInput({
                          ...input,
                          personal_proof_of_address: value,
                        });
                      }}
                      className={`${errorFile ? "border-red-500 " : ""}`}
                    />
                    {errorFile && (
                      <p className="text-red-500">
                        Upload a valid format (JPG, PNG, PDF)
                      </p>
                    )}
                  </div>
                  <div className="glade-subtitle-two space-y-3">
                    <h5>
                      Proof of address can be recognized documents that verify
                      your acurrent address. Such as recent Utility bills, Bank
                      statements or Cable bills not more than 3 months old.
                    </h5>
                    <h5>
                      Supported file types: PDF, PNG & JPEG. Max file size
                      allowed is 3MB.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="mx-6">
                <Button.Blue
                  onClick={onSubmit}
                  title={"Submit"}
                  className={"my-6 py-2"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center px-6 py-12 w-full bg-white h-full">
            <div>
              <div className="mt-6">
                <span className="glade-heading-three glade-home-dark">
                  Submitting Compliance
                </span>
              </div>
              <div className="flex justify-center">
                <ImSpinner2
                  className="mt-6 spinner text-center"
                  size={70}
                  color="#0B7AE0"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <NoticeModal ref={NoticeModalRef} onOpen={() => { }} onClose={()=>history.push("/dashboard/home")} /> */}
    </div>
  );
}
