import React from 'react'
import { useState, useEffect, useCallback } from "react";
import ReceiverFlag from "../../components/transfer/ReceiverFlag";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { getSuppliedFieldValue, isArray } from '../../helpers/helper';
import useUser from '../../hooks/useUser';
import { useDispatch } from "react-redux";
import useConvert from '../../hooks/useConvert';
import { convertCurrency, getConvertRate } from "../../redux/convert/convertActions";
import { getPersonalAndBusinessAccounts, toggleSelectedCurrency, verifyUserPin } from '../../redux/auth/authActions';
import { getCurrencyName } from "../../assets/icons/BalancesFlagIcons";
import useTeam from '../../hooks/useTeam';


const SenderFlag = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState('');
  const sendCurrency = getSuppliedFieldValue('currency', user) ? getSuppliedFieldValue('currency', user) : "NGN";
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [recieveCurrency, setRecieveCurrency] = useState('EUR');

  const [selectedCurrencyData, setSelectedCurrencyData] = useState({});

  const [options, setOptions] = useState([]);
  const {team_user} = useTeam();

  const optionKey = 'currency';

  // Get available multicurrencies
  useEffect(() => {
    let currencies = isArray(user?.selectedBusinessOrPersonalAccount?.accounts) ?
      user?.selectedBusinessOrPersonalAccount?.accounts?.map((account) => account?.currency) :
      [];
    currencies = currencies?.filter(value => value !== sendCurrency);
    setAvailableCurrencies(currencies?.filter(value => value !== sendCurrency));
    setRecieveCurrency(currencies[0]);

    //if logged in user is_team
    if(team_user?.employee?.is_teams){
      team_user?.business?.accounts && setOptions(team_user?.business?.accounts);
    }else{
      user?.selectedBusinessOrPersonalAccount?.accounts &&
      setOptions(user?.selectedBusinessOrPersonalAccount?.accounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user?.selectedBusinessOrPersonalAccount?.hasOwnProperty('selectedCurrency')) {
      setBaseCurrency(user?.selectedBusinessOrPersonalAccount?.selectedCurrency?.currency);
    } else if (user?.selectedBusinessOrPersonalAccount?.accounts?.length > 0) {
      setBaseCurrency(user?.selectedBusinessOrPersonalAccount?.accounts[0].currency);
    }else if(team_user?.employee?.is_teams){
      setBaseCurrency(team_user?.business?.default_currency || 'NGN')
    }
  }, [user?.selectedBusinessOrPersonalAccount, setBaseCurrency])

  // Hook for rate and charge
  useEffect(() => {
    // dispatch(getConvertRate({ amount: String(input.send_amount).split(',').join(''), source_currency: baseCurrency, destination_currency: recieveCurrency, action: 'conversion' }));
    if (isArray(user?.selectedBusinessOrPersonalAccount?.accounts)) {
      setSelectedCurrencyData(user?.selectedBusinessOrPersonalAccount?.accounts.find((data) => data.currency === recieveCurrency))
    }else if(team_user?.employee?.is_teams){
      setSelectedCurrencyData(team_user?.business?.accounts.find((data) => data.currency === recieveCurrency))
    }
    // setInput({...input, send_amount: '', recieve_amount: ''})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recieveCurrency, baseCurrency]);

  const selectedFlagOption = (e, index) => {
    // Set state value and push currency to header
    setBaseCurrency(e);
    if(team_user?.employee?.is_teams){
      dispatch(toggleSelectedCurrency(team_user?.business?.accounts[index], team_user?.business?.accounts[index]))
    }else{
      dispatch(toggleSelectedCurrency(user?.selectedBusinessOrPersonalAccount?.accounts[index], user?.selectedBusinessOrPersonalAccount))
    }
  }

  const emitFlagOption = (e, index) => {
    selectedFlagOption(e, index);
    setShowFilter(!showFilter);
  }

  return (
    <div className="mb-9">
      <p className='font-bold glade-black mb-3'>Send From:</p>
      <div className='border flex justify-between items-center pl-3 cursor-pointer' onClick={() => setShowFilter(!showFilter)}>
        <span className='flex items-center justify-center gap-2 h-40'>
          <ReceiverFlag flagCode={baseCurrency?.slice(0, -1)} />
          {baseCurrency}
        </span>
        {!showFilter ?
          <RiArrowDropDownLine size={'28px'} className="cursor-pointer mr-3" color="#000" />
          :
          <RiArrowDropUpLine size={'28px'} className="cursor-pointer" color="#000" />
        }

      </div>
      {showFilter &&
        <div className="flex flex-col gap-2 p-2 bg-white glade-grey-dark border max-h-72 overflow-y-auto">
          {
            options.length > 0 ?
              options.map((option, key) =>
                <div key={key}>
                  <button onClick={() => emitFlagOption(option[`${optionKey}`], key)}>
                    {
                      optionKey === 'currency' && getCurrencyName(option[`${optionKey}`]) ?
                        <p className="flex gap-2 items-center">
                          <ReceiverFlag flagCode={option[`${optionKey}`].slice(0, -1)} />
                          {`${getCurrencyName(option[`${optionKey}`])} (${option[`${optionKey}`]})`}
                        </p>
                        : option[`${optionKey}`]
                    }
                  </button>
                </div>)
              : <p className="text-center">List is Empty</p>
          }
        </div>
      }
    </div>
  )
}

export default SenderFlag