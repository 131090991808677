import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Fragment } from "react";

// components
import NavbarAuth from "../components/navbars/NavbarAuth";
import FooterAuth from "../components/footers/FooterAuth";

// pages
import TeamsLogin from "../pages/auth/Teams/TeamsLogin";
import TeamsResetPassword from "../pages/auth/Teams/TeamsResetPassword";

export default function TeamLayout() {
  return (
    <Fragment>
      <main className="relative bg-white min-h-screen">

        {/* Body */}
        <section>
          <Switch>
            <Route path="/team/login" exact component={TeamsLogin} />
            <Route path="/team/reset-password" exact component={TeamsResetPassword} />
            <Redirect from="/team" to="/team/login" />
          </Switch>
        </section>
      </main>
    </Fragment>
  );
}
