import { ONE_BUSINESS_DETAILS_FAIL, ONE_BUSINESS_DETAILS_SUCCESS } from "../home/types";

import {
  LOADING_AUTH,
  LOADING_VERIFY_USER_PIN,
  RELOAD_AUTH,
  LOGOUT_USER,
  LOADING_USERTAG ,
  USERTAG_SUCCESS,
  USERTAG_FAILURE,
  LOADING_USERTAG_AVAILABLE,
  USERTAG_AVAILABLE_SUCCESS,
  USERTAG_AVAILABLE_FAILURE,
  NEWTEAM_PASSWORD_FAILURE,
  NEWTEAM_PASSWORD_SUCCESS,
  LOADING_NEWTEAM_PASSWORD,
  REGISTER_USER,
  REFRESHED_TOKEN,
  TOGGLE_ACCOUNT,
  LOGIN_USER,
  STORE_USER_INFO,
  VERIFY_USER_EMAIL,
  RESEND_EMAIL_VERIFICATION,
  CREATE_PASS_CODE,
  INIT_PASSWORD_RESET,
  COMPLETE_PASSWORD_RESET,
  GET_BUSINESS_PERSONAL_ACCOUNTS,
  VERIFY_USER_PIN,
  TOGGLE_CURRENCY,
  UPDATE_SELECTED_ACCOUNT_BALANCE,
  VERIFY_TWO_FACTOR_QR_CODE_START,
  VERIFY_TWO_FACTOR_QR_CODE_FAILURE,
  VERIFY_TWO_FACTOR_QR_CODE_SUCCESS,
  LOADING_TWO_AUTH,
  GENERATE_QR_CODE_SUCCESS,
  GENERATE_QR_CODE_START,
  GENERATE_QR_CODE_FAILURE,
  VERIFY_USER_FAILURE,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_START,
  REFRESH_2FA_STATUS_FAILURE, 
  REFRESH_2FA_STATUS_START, 
  REFRESH_2FA_STATUS_SUCCESS,
  LOADING_VERIFICATION_ID,
  SUBMIT_VERIFICATION_ID,
  LOADING_METAMAP_STATUS,
  GET_BUSINESS_PERSONAL_ACCOUNTS_LOADING,
  GET_BUSINESS_PERSONAL_ACCOUNTS_FAILURE
} from "./types";

const initialState = {
  isLoading: false,
  isLoadingTeam:false,
  isLoadingUserTag:false,
  isLoadingTwoFactor: false,
  isLoadingVerifyPin: false,
  isVerifyingQRCode: false,
  isVerifyingUser: false,
  isRefreshingUser: false,
  isGeneratingQRCode: false,
  shouldReload:false,
  user: {},
  userTag:{},
  checkUserTag:{},
  isBusiness: false,
  isBusinessUUID: undefined,
  is2FAEnabled: false,
  token: "",
  refresh_token: "",
  storeUserInfo: {},
  verifyUserEmail: {},
  resendEmailVerification: {},
  verifyQRCode: {},
  generatedQRCode: {},
  createPassCode: {},
  verifiedUser: {},
  initPasswordReset: {},
  completePasswordReset: {},
  createTeamPassword: {},
  businessAndPersonalAccounts: {},
  isLoadingBusinessPersonalAccounts: false,
  selectedBusinessOrPersonalAccount: {},
  singleBusinessDetails: {},
  verifyUserPin: {},
  teamUser:{},
  hasCompliance: false,
  isOneBusinessLoading: true,
  isLoadingVerificationID: false,
  submitVerificationID: {},
  isLoadingMetaMapStatus: false
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };
      
    case LOADING_TWO_AUTH: 
      return {
        ...state,
        isLoadingTwoFactor: action.payLoad
      }
      
    case VERIFY_USER_START:
      return {
        ...state,
        isVerifyingUser: true,
      }
      
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        isVerifyingUser: false,
        verifiedUser: action.payLoad
      }
      
    case VERIFY_USER_FAILURE:
      return {
        ...state,
        isVerifyingUser: false,
      }
      
    case VERIFY_TWO_FACTOR_QR_CODE_START:
      return {
        ...state,
        isVerifyingQRCode: true
      }
      
    case VERIFY_TWO_FACTOR_QR_CODE_FAILURE:
    return {
      ...state,
      isVerifyingQRCode: false,
    }
    
    case VERIFY_TWO_FACTOR_QR_CODE_SUCCESS:
    return {
      ...state,
      isVerifyingQRCode: false,
      verifyQRCode: action.payLoad
    }
    
    case GENERATE_QR_CODE_START: 
      return {
        ...state,
        isGeneratingQRCode: true
      }
        
    case GENERATE_QR_CODE_SUCCESS:
      return {
        ...state,
        isGeneratingQRCode: false,
        generatedQRCode: action.payLoad
      }
      
    case GENERATE_QR_CODE_FAILURE:
      return {
        ...state,
        isGeneratingQRCode: false,
      }
   
    case LOADING_VERIFY_USER_PIN:
      return {
        ...state,
        isLoadingVerifyPin: action.payLoad,
      };

    case RELOAD_AUTH:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case LOGIN_USER:
      return {
        ...state,
        user: action.payLoad?.data?.data,
        token: action.payLoad?.data?.data?.token,
        refresh_token: action.payLoad?.data?.data?.refresh_token,
        is_agent: action.payLoad?.data?.data?.is_agent,
        is2FAEnabled: action.payLoad?.data?.data?.is_2fa_enabled,
        isBusiness: action.payLoad?.data?.data?.is_business ?? false,
        isBusinessUUID: action.payLoad?.data?.data?.business_uuid ?? undefined,
        selectedBusinessOrPersonalAccount: action.payLoad?.data?.data?.selectedBusinessOrPersonalAccount ?? {},
        hasCompliance: action.payLoad?.data?.data?.compliance_status === 'approved'? true : false,
      };
      
    case LOGOUT_USER:
      return {
        ...state,
        user: {},
        token: "",
      };

      case LOADING_USERTAG:
        return {
          ...state,
          isLoading: true,
        };
        
        case USERTAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userTag: action.payLoad.data.data,
      };
    case USERTAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        userTag: {}
      };
      
      case LOADING_USERTAG_AVAILABLE:
        return {
          ...state,
          isLoading: true,
        };
        
        case USERTAG_AVAILABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkUserTag: action.payLoad.data.data,
      };
    case USERTAG_AVAILABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        checkUserTag: {}
      };
    case REFRESH_2FA_STATUS_START:
      return {
        ...state,
        isRefreshingUser: true
      }
      
    case REFRESH_2FA_STATUS_FAILURE:
      return {
        ...state,
        isRefreshingUser: false
      }
      
    case REFRESH_2FA_STATUS_SUCCESS:
      return {
        ...state,
        isRefreshingUser: false,
        is2FAEnabled: action.payLoad?.is_2fa_enabled
      }

    case TOGGLE_ACCOUNT:
      return {
        ...state,
        user:{
          ...state.user,
          compliance_status: action.payLoad.compliance_status,
        },
        isBusiness: action.payLoad?.is_business,
        isBusinessUUID: action.payLoad?.business_uuid,
        selectedBusinessOrPersonalAccount: action.payLoad,
        singleBusinessDetails: action.payLoad,
        hasCompliance: action.payLoad?.compliance_status === 'approved'? true : false,
      };

    case TOGGLE_CURRENCY:
      return {
        ...state,
        selectedBusinessOrPersonalAccount: { 
          ...action.payLoad.selectedAccount, 
          selectedCurrency: action.payLoad.payLoad
        }
      }

    case REGISTER_USER:
      return {
        ...state,
        user: {},
      };

    case REFRESHED_TOKEN:
      return {
        ...state,
        token: action.payLoad?.data?.data?.token,
        refresh_token: action.payLoad?.data?.data?.refresh_token,
      };

    case STORE_USER_INFO:
      return {
        ...state,
        storeUserInfo: action.payLoad.data.data,
      };

    case VERIFY_USER_EMAIL:
      return {
        ...state,
        verifyUserEmail: action.payLoad.data.data,
      };

    case RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        resendEmailVerification: action.payLoad.data.data,
      };

    case CREATE_PASS_CODE:
      return {
        ...state,
        createPassCode: action.payLoad.data.data,
      };

    case INIT_PASSWORD_RESET:
      return {
        ...state,
        initPasswordReset: action.payLoad.data.data,
      };

    case COMPLETE_PASSWORD_RESET:
      return {
        ...state,
        completePasswordReset: action.payLoad.data.data,
      };

      case LOADING_NEWTEAM_PASSWORD:
        return {
          ...state,
          isLoadingTeam:true,
          createTeamPassword: {},
        };

      case NEWTEAM_PASSWORD_SUCCESS:
        return {
          ...state,
          createTeamPassword: action.payLoad.data.data,
          isLoadingTeam:false
        };

        case NEWTEAM_PASSWORD_FAILURE:
        return {
          ...state,
          createTeamPassword:{},
          isLoadingTeam:false
        };
    case GET_BUSINESS_PERSONAL_ACCOUNTS_LOADING:
      return{
        ...state,
        isLoadingBusinessPersonalAccounts : true
      }
    case GET_BUSINESS_PERSONAL_ACCOUNTS: 
      return {
        ...state,
        isLoadingBusinessPersonalAccounts: false,
        businessAndPersonalAccounts: action.payLoad.data.data,
      };
    case GET_BUSINESS_PERSONAL_ACCOUNTS_FAILURE: 
    return{
      ...state,
      isLoadingBusinessPersonalAccounts : false
    }
    case UPDATE_SELECTED_ACCOUNT_BALANCE:
      let selelectedCurrencyCheck = state?.selectedBusinessOrPersonalAccount?.selectedCurrency ? state?.selectedBusinessOrPersonalAccount?.selectedCurrency : null;
      if(selelectedCurrencyCheck){
        selelectedCurrencyCheck['balance'] = action.payLoad;
      } else if (state?.selectedBusinessOrPersonalAccount?.accounts?.length > 0){
        selelectedCurrencyCheck = state.selectedBusinessOrPersonalAccount?.accounts[0];
        selelectedCurrencyCheck['balance'] = action.payLoad;
      }else{
        //pick first object of personal accounts array
      }

      return {
        ...state,
        selectedBusinessOrPersonalAccount: {
          ...state.selectedBusinessOrPersonalAccount,
          balance: action.payLoad,
          selectedCurrency: {
            ...selelectedCurrencyCheck
          }
        },
      }

    case VERIFY_USER_PIN:
      return {
        ...state,
        verifyUserPin: action.payLoad.data.data,
      };

    case ONE_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        isOneBusinessLoading: false,
        selectedBusinessOrPersonalAccount: {
          ...action.payLoad.data.account, 
          ...action.payLoad.data.business,
          accounts: action.payLoad.data.account,
          is_business: true, 
          compliance_status: action.payLoad.data.compliance_status,
          selectedCurrency: action.payLoad.data.account[localStorage.getItem('currencyIndex')? Number(localStorage.getItem('currencyIndex')) : 0]
        }
    }

    case ONE_BUSINESS_DETAILS_FAIL:
      return {
        ...state,
        isOneBusinessLoading: false
      }
    
    case LOADING_VERIFICATION_ID:
      return {
        ...state,
        isLoadingVerificationID: action.payLoad
      }
    
    case SUBMIT_VERIFICATION_ID:
      return {
        ...state,
        submitVerificationID: action.payLoad.data.data
      }
    
    case LOADING_METAMAP_STATUS:
      return {
        ...state,
        isLoadingMetaMapStatus: action.payLoad
      }
    
    default:
      return state;
  }
}
