import React,  { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardPlainWithButton, CardPlainWithButtonSection } from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { storePaymentLinks, getCurrencies } from '../../redux/payment-pages/paymentPagesActions';
import usePaymentPages from '../../hooks/usePaymentPages';
import { BsCaretDownFill, BsCaretUpFill, BsPlus } from 'react-icons/bs';
import { CgClose } from "react-icons/cg";
import { currencyNameEnum, filterAfricanCurrencies, randomString } from '../../helpers/helper';
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import ImageInput from '../../components/inputs/ImageInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import useUser from "../../hooks/useUser";
import useTeam from '../../hooks/useTeam';

export default function PaymentPageCreateTicket() {
  
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const paymentPages = usePaymentPages();
  const {permissions, team_user} = useTeam();

  const [input, setInput] = useState({
    title: '',
    description: '',
    currency_id: '',
    currency_name: 'NGN',
    ticketFields: [{ id: randomString(), ticket_name: '', ticket_amount: '' }],
    seo_image: '',
    reoccurring: false,
    interval: '',
    reoccurring_limit: '',
    accept_phone_numbers: true,
    customer_pay_for_charges: false,
    fixed_amount: true,
    amount: '',
    hide_advanced_option: true,
    accept_quantity: false,
    quantity: '',
    split_payment: '',
    custom_url: '',
    redirect_after_payment_url: '',
    success_message: '',
    notification_emails: '',
    extraFields: [],
  });

  useEffect(() => {
    dispatch(getCurrencies({ mode: 'internal' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSubmit = () => {
    dispatch(storePaymentLinks({
      title: input.title, // correct
      state: 1,
      description: input.description, // correct
      amount: input.amount, // correct
      quantity: input.quantity, // correct
      currency: input.currency_name, // correct
      split: 0,
      split_payload: [],
      type: input.reoccurring ? 'recurrent' : 'one_time', // correct - onetime, recurrent
      frequency: input.interval, // correct - daily, weekly, monthly, yearly
      frequency_value: input.reoccurring_limit, // correct - 1,2,3
      is_fixed: input.fixed_amount ? 1 : 0, // correct
      amount_is_fixed: input.fixed_amount ? 1 : 0, // correct
      extra_currency: null,
      extra_amount: null,
      show_quantity: false,
      accept_number: input.accept_phone_numbers ? 1 : 0, // correct
      payer_bears_fees: input.customer_pay_for_charges ? 1 : 0, // correct
      custom_link: input.custom_url, // correct
      delete_image: null,
      image: (!input.seo_image || !input.seo_image.length) ? "" : input.seo_image[0], // correct
      redirect_url: input.redirect_after_payment_url, // correct
      custom_message: input.success_message, // correct
      notification_email: input.notification_emails, // correct
      extra_fields: input.extraFields, // correct
      is_ticket: 1, // correct
      ticket_data: input.ticketFields.map((item) => { return { ticket_name: item.ticket_name, amount: Number(item.ticket_amount.replaceAll(',', '')) } }), // correct
      event_data: [],
      ticket_currency: input.currency_name // correct
    }, () => history.goBack()));
  }

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  const onSelectOfCurrency = (e) => {
    let name = e?.target?.value;
    setInput({ ...input, currency_id: name, currency_name: name });
  }

  const addExtraField = () => {
    setInput({ ...input, extraFields: [...input.extraFields, { id: randomString(), field_name: '' }] });
  }

  const removeExtraField = (value) => {
    let fields = input.extraFields.filter(item => item.id !== value.id);
    setInput({ ...input, extraFields: fields });
  }

  const onChangeOfExtraField = (e) => {
    let fields = input.extraFields.map(item => { return (item.id === e.target.id) ? { ...item, field_name: e.target.value } : item; });
    setInput({ ...input, extraFields: fields });
  }

  const addTicketField = () => {
    setInput({ ...input, ticketFields: [...input.ticketFields, { id: randomString(), ticket_name: '', ticket_amount: '' }] });
  }

  const removeTicketField = (value) => {
    let fields = input.ticketFields.filter(item => item.id !== value.id);
    setInput({ ...input, ticketFields: fields });
  }

  const onChangeOfTicketField = (e) => {

    let fields = input.ticketFields.map(item => { return (item.id === e.target.id) ? { ...item, [e.target.name]: e.target.name === 'ticket_amount' ? Number(e.target.value).toLocaleString('en-US') : e.target.value } : item; });
    setInput({ ...input, ticketFields: fields });
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">

        <div className="mb-6">
          <BreadCrumbs title={'Payment Pages'} path={!permissions.includes("payment requests") ? '/dashboard/payment-pages' : '/teams/payment-pages'} />
        </div>

        <CardPlainWithButton>
          <CardPlainWithButtonSection
            title={'Create Ticket Payment Page'}
            loading={paymentPages.isLoading}
            buttonTitle={'Create Ticket'}
            buttonOnClick={() => onSubmit()}
            buttonDisabled={paymentPages.isLoading}>
            <div className="mt-6 px-14">
              <div className="space-y-6">

                <TextInput
                  label={'Page Name'}
                  id={"title"}
                  name={'title'}
                  type={'text'}
                  value={input.title ?? ''}
                  onChange={onChange}
                  placeholder={'Enter page name'}
                  autoComplete={"title"}
                  className={"appearance-none"}
                />

                <TextInput
                  label={'Description'}
                  id={"description"}
                  name={'description'}
                  type={'text'}
                  value={input.description ?? ''}
                  onChange={onChange}
                  placeholder={'Enter page name'}
                  autoComplete={"description"}
                  className={"appearance-none"}
                />

                <SelectInput
                  label={'Select Currency'}
                  id={'currency_id'}
                  name={'currency_id'}
                  value={input.currency_id ?? ''}
                  onChange={onSelectOfCurrency}
                  autoComplete={'currency_id'}
                  className={'appearance-none'}>
                  <option value={''} disabled={true}>Select Currency</option>
                  {filterAfricanCurrencies(user)?.length > 0 ? filterAfricanCurrencies(user)?.map(
                        (account, key) => (
                          <option
                            value={`${account?.currency}`}
                            key={key}
                          >
                            {`${currencyNameEnum[account?.currency] ?? account?.currency}`}
                          </option>
                        )
                      )
                    : null}
                  {team_user?.employee?.is_teams
                    ? filterAfricanCurrencies(
                        team_user?.business?.accounts,
                        true
                      )?.map((account, key) => (
                        <option value={`${account?.currency}`} key={key}>
                          {`${
                            currencyNameEnum[account?.currency] ??
                            account?.currency
                          }`}
                        </option>
                      ))
                    : null}
                </SelectInput>

                {input.ticketFields && input.ticketFields.map((item, key) => {
                  return (
                    <div key={key} className="col-span-6 sm:col-span-3">
                      <label className="block glade-small-text-two glade-black">Ticket {key + 1}</label>
                      <div className="flex flex-row">
                        <div className="w-1/2 mr-2">
                          <TextInput
                            label={<React.Fragment></React.Fragment>}
                            id={item.id}
                            name={'ticket_name'}
                            type={'text'}
                            value={input.ticketFields.find(field => field.id === item.id).ticket_name}
                            onChange={onChangeOfTicketField}
                            placeholder={'Enter ticket name'}
                            autoComplete={"ticket_name"}
                            className={"appearance-none"}
                          />
                        </div>
                        <div className="w-1/2">
                          <NumericInput
                            label={<React.Fragment></React.Fragment>}
                            id={item.id}
                            name={'ticket_amount'}
                            type={'number'}
                            value={input.ticketFields.find(field => field.id === item.id).ticket_amount}
                            onChange={onChangeOfTicketField}
                            placeholder={'Enter amount'}
                            autoComplete={"ticket_amount"}
                            className={"appearance-none"}
                            rightSection={
                              <span
                                onClick={() => removeTicketField(item)}
                                className="inline-flex items-center px-3 rounded-r-sm border border-l-0 border-gray-300 text-gray-500 text-sm cursor-pointer">
                                <CgClose />
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="col-span-6 sm:col-span-3">
                  <div className="flex justify-center items-center p-3 cursor-pointer" onClick={addTicketField}>
                    <span className="mr-3"><BsPlus color={'#00AFEF'} /></span>
                    <span className="glade-blue glade-subtitle-two">Add ticket</span>
                  </div>
                </div>

                <ImageInput
                  label={'SEO Image'}
                  id={'seo_image'}
                  name={'seo_image'}
                  multiple={false}
                  returnInput={(value) => setInput({ ...input, seo_image: value })}
                />

                <CheckboxInput
                  label={'Accept phone numbers on this page'}
                  id={"accept_phone_numbers"}
                  name={'accept_phone_numbers'}
                  type={'checkbox'}
                  checked={input.accept_phone_numbers}
                  onChange={(e) => setInput({ ...input, [e.target.name]: e.target.checked })}
                  placeholder={'Accept phone numbers on this page'}
                  autoComplete={"accept_phone_numbers"}
                  className={"appearance-none"}
                />

                <CheckboxInput
                  label={'Customer should pay for charges'}
                  id={"customer_pay_for_charges"}
                  name={'customer_pay_for_charges'}
                  type={'checkbox'}
                  checked={input.customer_pay_for_charges}
                  onChange={(e) => setInput({ ...input, [e.target.name]: e.target.checked })}
                  placeholder={'Customer should pay for charges'}
                  autoComplete={"customer_pay_for_charges"}
                  className={"appearance-none"}
                />

                <div className="col-span-6 sm:col-span-3">
                  <div className="flex justify-center items-center p-3 cursor-pointer" onClick={() => setInput({ ...input, 'hide_advanced_option': !input.hide_advanced_option })}>
                    <span className="glade-blue glade-subtitle-two">Hide Advanced Options</span>
                    {!input.hide_advanced_option ?
                      <span className="ml-3"><BsCaretUpFill color={'#1634A1'} /></span>
                      :
                      <span className="ml-3"><BsCaretDownFill color={'#1634A1'} /></span>
                    }
                  </div>
                </div>

                {!input.hide_advanced_option &&
                  <React.Fragment>
                    <SelectInput
                      label={'Split Payment'}
                      id={"split_payment"}
                      name={'split_payment'}
                      value={input.split_payment ?? ''}
                      onChange={onChange}
                      autoComplete={"split_payment"}
                      className={"appearance-none"}>
                      <option value={''} disabled={true}>Select split account</option>
                      {paymentPages.splitAccounts?.accounts && paymentPages.splitAccounts?.accounts?.map((item, key) => {
                        return (
                          <option key={key} value={item.id} data={JSON.stringify(item)}>{item.split_name}</option>
                        );
                      })}
                    </SelectInput>

                    <TextInput
                      leftSection={
                        <span className="inline-flex items-center px-3 rounded-l-sm border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          https://pay.gladefinance.co/
                        </span>
                      }
                      label={'Use Custom URL'}
                      id={"custom_url"}
                      name={'custom_url'}
                      type={'text'}
                      value={input.custom_url ?? ''}
                      onChange={onChange}
                      placeholder={'Custom URL'}
                      autoComplete={"custom_url"}
                      className={"appearance-none"}
                    />

                    <TextInput
                      label={'Redirect after payment'}
                      id={"redirect_after_payment_url"}
                      name={'redirect_after_payment_url'}
                      type={'text'}
                      value={input.redirect_after_payment_url ?? ''}
                      onChange={onChange}
                      placeholder={'google.com'}
                      autoComplete={"redirect_after_payment_url"}
                      className={"appearance-none"}
                    />

                    <TextInput
                      label={'Success Message'}
                      id={"success_message"}
                      name={'success_message'}
                      type={'text'}
                      value={input.success_message ?? ''}
                      onChange={onChange}
                      placeholder={'Enter message to be displayed after payment'}
                      autoComplete={"success_message"}
                      className={"appearance-none"}
                    />

                    <TextInput
                      label={'Notification Emails'}
                      id={"notification_emails"}
                      name={'notification_emails'}
                      type={'text'}
                      value={input.notification_emails ?? ''}
                      onChange={onChange}
                      placeholder={'Enter notification email addresses'}
                      autoComplete={"notification_emails"}
                      className={"appearance-none"}
                    />
                    <span className="glade-small-text-one glade-ash">Separate each email by a comma</span>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="flex justify-center items-center p-3 cursor-pointer" onClick={addExtraField}>
                        <span className="mr-3"><BsPlus color={'#00AFEF'} /></span>
                        <span className="glade-blue glade-subtitle-two">Add Extra Field</span>
                      </div>
                    </div>

                    {input.extraFields && input.extraFields.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <TextInput
                            leftSection={
                              <span className="inline-flex items-center px-3 rounded-l-sm border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                Field Name
                              </span>
                            }
                            label={<React.Fragment></React.Fragment>}
                            id={item.id}
                            name={item.id}
                            type={'text'}
                            value={input.extraFields.find(field => field.id === item.id).field_name}
                            onChange={onChangeOfExtraField}
                            placeholder={''}
                            autoComplete={item.id}
                            className={"appearance-none"}
                            rightSection={
                              <span
                                onClick={() => removeExtraField(item)}
                                className="inline-flex items-center px-3 rounded-r-sm border border-l-0 border-gray-300 text-gray-500 text-sm cursor-pointer">
                                <CgClose />
                              </span>
                            }
                          />
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                }

              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  )
}
