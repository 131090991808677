import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import {getAllCustomers} from '../../redux/customers/customersActions';
import useCustomers from '../../hooks/useCustomers';
import {simpleDate, isArray, isEmpty, pagination} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import Button from '../../components/buttons/Button';
import SearchInput from '../../components/inputs/SearchInput';
import {downloadCustomersAsCSV} from './DownloadCustomersAsCSV';
import {IoIosArrowForward} from "react-icons/io";
import {KebabDropdown, KebabDropdownItem} from "../../components/dropdowns/KebabDropdown";
import useTeam from '../../hooks/useTeam';

export default function Customers() {

  const history = useHistory();
  const dispatch = useDispatch();
  const customer = useCustomers();
  const {permissions} = useTeam();
  const dataForCSV = customer.customerIndex?.customers;

  React.useEffect(() => {
    dispatch(getAllCustomers());
    // eslint-disable-next-line 
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(customer.customerIndex?.customers, 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, customer.customerIndex?.customers]);

  // Search Function And Control
  const onSearch = (search) => {
    const filteredData = customer.customerIndex?.customers?.filter(item => {
      return (item.user_email.toLowerCase().includes(search.toLowerCase())
      || item.user_name.toLowerCase().includes(search.toLowerCase()));
    });
    setTableData(pagination(filteredData ?? [], 10, currentPage));
  }

  const renderCustomerTable = (data) => {
    return data.map((item,key)=>{
      return (
        <CardTableBodyRow className={'cursor-pointer'} key={key} onClick={()=>history.push({pathname:!permissions?.includes("customers") ? `/dashboard/customers/customer-detail/${item?.user_email}` :  `/teams/customers/customer-detail/${item?.user_email}`, state:item})}>
          <CardTableBodyCell>
              <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
              <span className="glade-normal-text-two">{item?.user_email}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
              <span className="glade-normal-text-two">{item?.user_name}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
              <span className="glade-normal-text-two">{item?.user_phone}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
              <span className="glade-normal-text-two">{simpleDate(item?.created_at)}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
              <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      )
    });
  }

  const renderCustomerDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div key={key} className="flex flex-row justify-between p-1 border-b" onClick={()=>history.push({pathname:!permissions?.includes("customers") ? `/dashboard/customers/customer-detail/${item?.user_email}` :  `/teams/customers/customer-detail/${item?.user_email}`,state:item})}>
          <div className="flex flex-row">
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item?.user_name}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {item?.user_email}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <CardTableHeaderComplex 
        title={`Showing ${tableData.totalItems} records`}
        searchBar={<SearchInput disabled={customer.isLoading} onSearch={onSearch} placeholder={'Customer Name or Email'} />}
        rightButton={
          <div>
            <Button.Blue
              onClick={() => downloadCustomersAsCSV(dataForCSV)}
              disabled={customer.isLoading}
              title="Download as CSV"
            />
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" title="Download" disabled={customer.isLoading}>
              {!isEmpty(customer.customerIndex?.customers)?
              <KebabDropdownItem
                activeChild={<span onClick={() => downloadCustomersAsCSV(dataForCSV)} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Download as CSV</span>}
                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Download as CSV</span>}
              />:null}
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || customer.isLoading?
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable column={['','Customer Email','Customer Name','Phone Number','Date Created','']} loading={customer.isLoading} />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1,2]} loading={customer.isLoading} />
          </div>
        </React.Fragment>
      :
        <React.Fragment>
          <div className="hidden md:block">
            <CardTableBody column={['','Customer Email','Customer Name','Phone Number','Date Created','']}>
              {isArray(tableData.data) && renderCustomerTable(tableData.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderCustomerDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      }

      <CardTableFooter 
        empty={isEmpty(tableData.data)}
        loading={customer.isLoading}
        pageNumber={currentPage} 
        totalPages={tableData.totalPages}
        prevOnClick={()=>setCurrentPage(tableData.previousPage)}
        nextOnClick={()=>setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
    </div>
  )
}
