import React, { useEffect, useState } from "react";
import Button from "../../components/buttons/Button";
import FileInput from "../../components/inputs/FileInput";
import SelectInput from "../../components/inputs/SelectInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import TextInput from "../../components/inputs/TextInput";
import { isArray } from "../../helpers/helper";
import useBusiness from "../../hooks/useBusiness";
import { getBusinessCategories, submitBusinessCacInformtion } from "../../redux/business/businessActions";
import { useDispatch } from "react-redux";
import { addBusinessInformationValidation } from "../../validation/settings/settingsValidation";
export const RegisterBusinessInformation = ({
  input,
  setInput,
  setFormLevel,
}) => {
  const business = useBusiness();
  const dispatch = useDispatch();
  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputError({ ...inputError, [e.target.name]: false });
  };
  const [inputError, setInputError] = useState({});
  useEffect(() => {
    dispatch(getBusinessCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabled = () => {
    let disable = false;
    switch (input.business_category) {
      case "SP":
        disable = !input.business_name || !input.business_name_two || !input.business_name_three || !input.business_address || !input.business_description || !input.business_email || !input.phone_number;
        break;
      case "LLC":
        disable = !input.business_name || !input.business_name_two || !input.business_name_three || !input.business_address || !input.business_description || !input.business_email || !input.phone_number || !input.share_capital || !input.business_objective || !input.business_industry;
        break;
      case "LLP":
        disable = !input.business_name || !input.business_name_two || !input.business_address || !input.partnership_agreement;
        break;
      case "NGO":
        disable = !input.business_name || !input.business_name_two || !input.business_name_three || !input.organization_objective || !input.chairman_of_trustees || !input.trustee_tenureship || !input.max_number_trustees;
        break;
      default:
        disable = false;       
    }
    return disable;
  }


  const handleSubmit = () => {
    let isInputValid = addBusinessInformationValidation(input);
    if (isArray(isInputValid)) {
      isInputValid.forEach((field) => {
        setInputError((prev) => ({ ...prev, [field]: true }));
      });
    } else {
      let data;
      if (input.business_category === "LLC") {
        data = {
          registration_name: input.business_name,
          registration_name_two: input.business_name_two,
          registration_name_three: input.business_name_three,
          registration_phone: input.phone_number,
          registration_email: input.business_email,
          registration_address: input.business_address,
          registration_description: input.business_description,
          share_capital: input.share_capital,
          registration_objective: input.business_objective,
          registration_industry: input.business_industry,
          registration_uuid: input.registration_uuid,
        };

      } else if (input.business_category === "LLP") {
        data = {
          registration_name: input.business_name,
          registration_name_two: input.business_name_two,
          registration_address: input.business_address,
          registration_uuid: input.registration_uuid,
        };
        if (input?.partnership_agreement && typeof input.partnership_agreement === "object") {
          data = {...data,  partnership_agreement: input.partnership_agreement[0]}
          }
      } else if (input.business_category === "SP") {
        data = {
          registration_name: input.business_name,
          registration_name_two: input.business_name_two,
          registration_name_three: input.business_name_three,
          registration_phone: input.phone_number,
          registration_email: input.business_email,
          registration_address: input.business_address,
          registration_description: input.business_description,
          registration_uuid: input.registration_uuid,
        };
      } else if (input.business_category === "NGO") {
        data = {
          registration_name: input.business_name,
          registration_name_two: input.business_name_two,
          registration_name_three: input.business_name_three,
          chairman_trustee: input.chairman_of_trustees,
          registration_objective: input.organization_objective,
          trustee_tenureship: parseInt(input.trustee_tenureship),
          max_trustee: parseInt(input.max_number_trustees),
          registration_uuid: input.registration_uuid,
        };
      }
         
          dispatch(submitBusinessCacInformtion(data, { business_category: input.business_category?.toLowerCase()}, ()=> {setFormLevel("proprietor information")}))
    }
  };

  return (
    <div className="space-y-6">
      <h3 className="font-bold">Please provide all necessary information</h3>
      <div>
        <TextInput
          label={`Proposed ${input.business_category === "NGO" ? "NGO" : ""} ${
            input.business_category === "LLC" ||
            input.business_category === "LLP"
              ? "Company"
              : input.business_category === "SP" ? "Business" : ""
          } Name 1`}
          id={"business_name"}
          name={"business_name"}
          type={"text"}
          value={input.business_name}
          onChange={onChange}
          placeholder={`Enter ${
            input.business_category === "NGO" ? "NGO" : ""
          } ${
            input.business_category === "LLC" ||
            input.business_category === "LLP"
              ? "company"
              : input.business_category === "SP" ? "business" : ""
          } name`}
          autoComplete={"business_name"}
          className={"appearance-none"}
        />

        {inputError.business_name && (
          <div className="text-glade-red-400 text-sm mt-2">
            {`Proposed ${input.business_category === "NGO" ? "NGO" : ""} ${
              input.business_category === "LLC" ||
              input.business_category === "LLP"
                ? "Company"
                : input.business_category === "SP" ? "Business" : ""
            } Name 1  is invalid`}
          </div>
        )}
      </div>

      <div>
        <TextInput
          label={`Proposed ${input.business_category === "NGO" ? "NGO" : ""} ${
            input.business_category === "LLC" ||
            input.business_category === "LLP"
              ? "Company"
              : input.business_category === "SP" ? "Business" : ""
          } Name 2 `}
          id={"business_name_two"}
          name={"business_name_two"}
          type={"text"}
          value={input.business_name_two}
          onChange={onChange}
          placeholder={`Enter ${
            input.business_category === "NGO" ? "NGO" : ""
          } ${
            input.business_category === "LLC" ||
            input.business_category === "LLP"
              ? "company"
              : input.business_category === "SP" ? "business" : ""
          } name`}
          autoComplete={"business_name_two"}
          className={"appearance-none"}
        />

        {inputError.business_name_two && (
          <div className="text-glade-red-400 text-sm mt-2">
            {`Proposed ${input.business_category === "NGO" ? "NGO" : ""} ${
              input.business_category === "LLC" ||
              input.business_category === "LLP"
                ? "Company"
                : input.business_category === "SP" ? "Business" : ""
            } Name 2  is invalid`}
          </div>
        )}
      </div>

      {input.business_category !== "LLP" && (
        <div>
          <TextInput
            label={`Proposed ${
              input.business_category === "NGO" ? "NGO" : ""
            } ${
              input.business_category === "LLC" ||
              input.business_category === "LLP"
                ? "Company"
                : input.business_category === "SP" ? "Business" : ""
            } Name 3`}
            id={"business_name_three"}
            name={"business_name_three"}
            type={"text"}
            value={input.business_name_three}
            onChange={onChange}
            placeholder={`Enter ${
              input.business_category === "NGO" ? "NGO" : ""
            } ${
              input.business_category === "LLC" ||
              input.business_category === "LLP"
                ? "company"
                : input.business_category === "SP" ? "business" : ""
            } name`}
            autoComplete={"business_name_three"}
            className={"appearance-none"}
          />
          {inputError.business_name_three && (
            <div className="text-glade-red-400 text-sm mt-2">
              {`Proposed ${input.business_category === "NGO" ? "NGO" : ""} ${
                input.business_category === "LLC" ||
                input.business_category === "LLP"
                  ? "Company"
                  : input.business_category === "SP" ? "Business" : ""
              } Name 3  is invalid`}
            </div>
          )}
        </div>
      )}

      {input.business_category === "NGO" && (
        <div className="space-y-6">
          <div>
            <TextAreaInput
              label={"Organization Objective"}
              id={"organization-objective"}
              name={"organization_objective"}
              rows={3}
              type={"text"}
              value={input.organization_objective}
              onChange={onChange}
              placeholder={"Tell us about your organization"}
              autoComplete={"organization-objective"}
              className={"appearance-none"}
            />

            {inputError.organization_objective && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Organization Objective is invalid"}
              </div>
            )}
          </div>

          <div>
            <TextInput
              label="Chairman of Trustees"
              id={"chairman_of_trustees"}
              name={"chairman_of_trustees"}
              placeholder={"John Doe"}
              type="text"
              onChange={onChange}
              value={input.chairman_of_trustees}
              className="appearance-none"
              autoComplete={"chairman_of_trustees"}
            />

            {inputError.chairman_of_trustees && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Chairman of Trustees is invalid"}
              </div>
            )}
          </div>

          <div>
            <TextInput
              label="Trustee Tenureship"
              id={"trustee_tenureship"}
              name={"trustee_tenureship"}
              placeholder={"How many years is a trustees tenure?"}
              type="number"
              onChange={onChange}
              value={input.trustee_tenureship}
              className="appearance-none"
              autoComplete={"trustee_tenureship"}
            />
            {inputError.trustee_tenureship && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Trustee Tenureship is invalid"}
              </div>
            )}
          </div>

          <div>
            <TextInput
              label="Maximum Number of Trustees"
              id={"max_number_trustees"}
              name={"max_number_trustees"}
              placeholder={""}
              type="number"
              onChange={onChange}
              value={input.max_number_trustees}
              className="appearance-none"
              autoComplete={"max_number_trustees"}
            />

            {inputError.max_number_trustees && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Maximum Number of Trustees is invalid"}
              </div>
            )}
          </div>
        </div>
      )}

      {input.business_category !== "LLP" &&
      input.business_category !== "NGO" ? (
        <div className="space-y-6">
          <div>
            <TextInput
              leftSection={
                <span className="glade-button-gray glade-home-dark self-center border border-gray-300 rounded-sm p-2">
                  +234
                </span>
              }
              label={"Phone Number"}
              id={"phone_number"}
              name={"phone_number"}
              value={input.phone_number ?? ""}
              onChange={onChange}
              autoComplete={"phone_number"}
              className={"appearance-none"}
              errorState={inputError.phone_number}
            />

            {inputError.phone_number && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Phone Number is invalid"}
              </div>
            )}
          </div>

          <div>
            <TextInput
              label={"Business Email"}
              id={"business_email"}
              name={"business_email"}
              type={"text"}
              value={input.business_email}
              onChange={onChange}
              placeholder={"Enter registered business email"}
              autoComplete={"business_email"}
              className={"appearance-none"}
            />

            {inputError.business_email && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Business Email is invalid"}
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {input.business_category !== "NGO" && (
        <div>
          <TextInput
            label={"Business Address"}
            id={"business_address"}
            name={"business_address"}
            type={"text"}
            value={input.business_address}
            onChange={onChange}
            placeholder={"Enter business address"}
            autoComplete={"business_address"}
            className={"appearance-none"}
          />
          {inputError.business_address && (
            <div className="text-glade-red-400 text-sm mt-2">
              {"Business Address is invalid"}
            </div>
          )}
        </div>
      )}
      {input.business_category === "LLC" && (
        <div className="">
          <TextInput
            label="Share Capital"
            id={"share_capital"}
            name={"share_capital"}
            placeholder={"100000"}
            type="number"
            onChange={onChange}
            value={input.share_capital}
            className="appearance-none"
            autoComplete={"share_capital"}
          />
          <div>
            {inputError.share_capital && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Share Capital is invalid"}
              </div>
            )}
          </div>
        </div>
      )}

      {input.business_category !== "LLP" &&
      input.business_category !== "NGO" ? (
        <div>
          <TextAreaInput
            label={"Business Description"}
            id={"business_description"}
            name={"business_description"}
            rows={3}
            type={"text"}
            value={input.business_description}
            onChange={onChange}
            placeholder={"What does your business do?"}
            autoComplete={"business_description"}
            className={"appearance-none"}
          />

          {inputError.business_description && (
            <div className="text-glade-red-400 text-sm mt-2">
              {"Business Descption is invalid"}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}

      {input.business_category === "LLC" && (
        <>
          <div>
            <TextAreaInput
              label={"Business Objective"}
              id={"business_objective"}
              name={"business_objective"}
              rows={2}
              type={"text"}
              value={input.business_objective}
              onChange={onChange}
              placeholder={"What is the objective of this business?"}
              autoComplete={"business_objective"}
              className={"appearance-none"}
            />
            {inputError.business_objective && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Business Objective is invalid"}
              </div>
            )}
          </div>

          <div>
            <SelectInput
              label={"Business Industry"}
              id={"business_industry"}
              name={"business_industry"}
              value={input.business_industry ?? ""}
              onChange={onChange}
              autoComplete={"business_industry"}
              className={"appearance-none"}
            >
              <option value={""} disabled={true}>
                Select Industry
              </option>
              {isArray(business?.businessCategories) &&
                business?.businessCategories.map((item, key) => {
                  return (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  );
                })}
            </SelectInput>

            {inputError.business_industry && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Business Industry is invalid"}
              </div>
            )}
          </div>
        </>
      )}

      {input.business_category === "LLP" && (
        <div className="">
          <FileInput
            label={"Partnership Agreement"}
            id={"partnership_agreement"}
            name={"partnership_agreement"}
            multiple={true}
            accept=".png, .jpeg, .pdf, .jpg"
            maximumSize={3}
            returnInput={(value) => {
              setInputError({
                ...inputError,
                ["partnership_agreement"]: false,
              });
              setInput({ ...input, partnership_agreement: value });
            }}
            errorState={inputError.partnership_agreement}
          />
          {inputError.partnership_agreement && (
            <div className="text-glade-red-400 text-sm mt-2">
              Upload a valid format (JPG, PNG, PDF)
            </div>
          )}
          <p>
            Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.
          </p>
        </div>
      )}
      <div className="flex flex-col lg:w-3/4 mx-auto">
        <Button.Blue title="Next" onClick={handleSubmit} loading={business.isSubmittingBusiness} disabled={business.isSubmittingBusiness || disabled()} />
        {!disabled &&
          <Button.Transparent
            title={"Save & Continue Later"}
            onClick={handleSubmit}
            loading={business.isSubmittingBusiness}
            disabled={business.isSubmittingBusiness || disabled}
            style={{ color: "#2C6DEA" }}
            className="mt-2"
          />
        }
      
        </div>
    </div>
  );
};
