import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { destroyLastLogin } from "../../helpers/session";
import { LOGOUT_USER } from "../auth/types";
import {
  // LOADING_HOME,
  // RELOAD_HOME,
  GET_BUSINESS_INFLOW,
  GET_BUSINESS_OUTFLOW,
  GET_BUSINESS_INFLOW_OUTFLOW,
  GET_PERSONAL_INFLOW,
  GET_PERSONAL_OUTFLOW,
  GET_PERSONAL_INFLOW_OUTFLOW,
  GET_CARDS,
  GET_BUSINESS_TRANSACTIONS,
  GET_PERSONAL_TRANSACTIONS,
  ONE_BUSINESS_DETAILS_LOADING,
  ONE_BUSINESS_DETAILS_SUCCESS,
  ONE_BUSINESS_DETAILS_FAIL,
  GET_STATES_BY_COUNTRYID,
  GET_LGAS_BY_STATEID,
  LOADING_REQUEST_REVOKE_SESSION,
  LOADING_REVOKE_SESSION,
  REQUEST_REVOKE_SESSION_SUCCESS,
  REQUEST_REVOKE_SESSION_FAILURE,
 REVOKE_SESSION_SUCCESS,
  REVOKE_SESSION_FAILURE,

} from "./types";

/**
 * Get business inflow
 * @returns {void}
 */
export const getBusinessInflow = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/business/inflow-summary`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_BUSINESS_INFLOW, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get business outflow
 * @returns {void}
 */
export const getBusinessOutflow = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/business/outflow-summary`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_BUSINESS_OUTFLOW, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get business inflow and outflow
 * @returns {void}
 */
export const getBusinessInflowAndOutflow = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/business/inflow-outflow-summary`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_BUSINESS_INFLOW_OUTFLOW, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error,);
    });
  };
};

/**
 * Get personal inflow
 * @returns {void}
 */
 export const getPersonalInflow = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/personal/inflow-summary`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_PERSONAL_INFLOW, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get personal outflow
 * @returns {void}
 */
 export const getPersonalOutflow = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/personal/outflow-summary`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_PERSONAL_OUTFLOW, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get personal inflow and outflow
 * @returns {void}
 */
 export const getPersonalInflowAndOutflow = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/personal/inflow-outflow-summary`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_PERSONAL_INFLOW_OUTFLOW, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error,);
    });
  };

  // return (dispatch) => {
  //   Promise.all([
  //     Axios.get(`/dashboard/personal/inflow-summary`,{params:payLoad?.inflow}), 
  //     Axios.get(`/dashboard/personal/outflow-summary`,{params:payLoad?.outflow})
  //   ])
  //   .then((res) => {
  //     dispatch({ 
  //       type: GET_PERSONAL_INFLOW_OUTFLOW, 
  //       payLoad: {
  //         data:{
  //           data:{
  //             inflow:res[0]['data']['data'], 
  //             outflow:res[1]['data']['data']
  //           }
  //         }
  //       } 
  //     });
  //     successHandler(res[0]);
  //     successHandler(res[1]);
  //   })
  //   .catch((error) => {
  //     errorHandler(error, true);
  //   });
  // };
};

/**
 * Get cards
 * @returns {void}
 */
export const getCards = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/virtual-cards`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_CARDS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error);
    });
  };
};

/**
 * Get business transactions
 * @returns {void}
 */
export const getBusinessTransactions = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/business/recent-transactions`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_BUSINESS_TRANSACTIONS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, );
    });
  };
};

/**
 * Get personal transactions
 * @returns {void}
 */
export const getPersonalTransactions = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/dashboard/personal/recent-transactions`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_PERSONAL_TRANSACTIONS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, );
    });
  };
};

export const getOneBusinessDetails = (onSuccess=()=>{}) => {
  return (dispatch) => {
    dispatch({type: ONE_BUSINESS_DETAILS_LOADING})
    Axios.get(`/businesses/details`)
    .then((res) => {
      dispatch({ type: ONE_BUSINESS_DETAILS_SUCCESS, payLoad: res.data });
      successHandler(res);
      onSuccess();
    })
    .catch((error) => {
      dispatch({type: ONE_BUSINESS_DETAILS_FAIL})
      errorHandler(error, true);
    });
  };
};

/**
 * Get States By Country
 * @returns {void}
 */

export const getStatesByCountryId = (countryId) => {
  return (dispatch) => {
    Axios.get(`/go-live/lookup/country/${countryId}/states`)
    .then((res) => {
      dispatch({ type: GET_STATES_BY_COUNTRYID, payLoad: res.data });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get States By Country
 * @param {object} payLoad {"country_id":"1", "state_id": "1"}
 * @returns {void}
 */

export const getLgasByStateId = (payload) => {
  return (dispatch) => {
    Axios.get(`go-live/lookup/country/${payload.country_id}/${payload.state_id}/lgas`)
    .then((res) => {
      dispatch({ type: GET_LGAS_BY_STATEID, payLoad: res.data });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};


export const requestRevokeSession = (
  postData,
  onSuccess = () => {},
  callback = () => { }
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_REQUEST_REVOKE_SESSION });
    Axios.post(`auth/request-session-revoke`, postData)
      .then((res) => {
        dispatch({ type:REQUEST_REVOKE_SESSION_SUCCESS, payLoad: res });
        onSuccess();
        successHandler(res, true);
        callback()
      })

      .catch((error) => {
        dispatch({ type:REQUEST_REVOKE_SESSION_FAILURE });
        errorHandler(error, true);
      });
  };
};

export const revokeSessions = (
  postData,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_REVOKE_SESSION });
    destroyLastLogin();
    localStorage.clear();
    dispatch({ type: LOGOUT_USER, payLoad: {} });
    Axios.post(`/auth/revoke-session`, postData)
      .then((res) => {
        dispatch({ type: REVOKE_SESSION_SUCCESS, payLoad: res });
        onSuccess();

        successHandler(res);
      })

      .catch((error) => {
        dispatch({ type: REVOKE_SESSION_FAILURE });
        errorHandler(error, true);
        onFailure({ error });
      });
  };
};
