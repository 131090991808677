import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import { getAllPaymentFlows } from "../../redux/payment-inflow/paymentInFlowActions";
import usePaymentFlows from "../../hooks/usePaymentFlows";
import {simpleDate, statusEnum, isArray, isEmpty, toTitleCase, paginationByBackend} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import TextIndicator from '../../components/indicators/TextIndicator';
import CircleIndicator from "../../components/indicators/CircleIndicator";
import SearchInput from '../../components/inputs/SearchInput';
import SelectInput from '../../components/inputs/SelectInput';
import Sieve from '../../components/filter/Sieve';
import Button from "../../components/buttons/Button";
import CopyTextButton from '../../components/buttons/CopyTextButton';
import {downloadPaymentInflowAsCSV} from './DownloadPaymentInflowAsCSV';
import {IoIosArrowForward} from "react-icons/io";
import {KebabDropdown, KebabDropdownItem} from "../../components/dropdowns/KebabDropdown";
import useTeam from "../../hooks/useTeam";

export default function AllCurrencyLists({selectedCurrency}) {

  const history = useHistory();
  const dispatch = useDispatch();
  const paymentFlow = usePaymentFlows({selectedCurrency});
  const {permissions} = useTeam();

  const dataForCSV = paymentFlow?.allPaymentFlows?.transactions?.data ?? [];

  React.useEffect(() => {
    dispatch(getAllPaymentFlows({paginate:1, per_page:10, current_page:1}, selectedCurrency));
    // eslint-disable-next-line 
  }, [selectedCurrency]);

  // Pagination Function And Control
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(paginationByBackend(paymentFlow?.allPaymentFlows?.transactions ?? {}));
    // eslint-disable-next-line
  }, [paymentFlow?.allPaymentFlows?.transactions]);

  // inputs for filter type
  const [input, setInput] = React.useState({search_type:'transaction_id'});
  const [paginationAttributes, setPaginationAttributes] = React.useState({});

  // Search Function And Control
  const onSearch = (search) => {
    if (input.search_type === 'transaction_id'){
      dispatch(getAllPaymentFlows({search:search, paginate:1, per_page:10, current_page:1}, ''));
      setPaginationAttributes({reference:search});
    }

    if (input.search_type === 'customer_email'){
      dispatch(getAllPaymentFlows({reference:search, paginate:1, per_page:10, current_page:1}, ''));
      setPaginationAttributes({search:search});
    }
  }

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(getAllPaymentFlows({
      status:data.status,
      date_from:data.date_from??data.date,
      date_to:data.date_to??new Date().toISOString().substring(0, 10),
      paginate:1,
      per_page:10,
      current_page:1
    }));
    setPaginationAttributes({
      status:data.status,
      date_from:data.date_from??data.date,
      date_to:data.date_to??new Date().toISOString().substring(0, 10),
    });
  }

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getAllPaymentFlows({paginate:1, per_page:10, current_page:1}));
    setPaginationAttributes({});
  }

  const renderPaymentInflowsTable = (data, selectedCurrency) => {
    return data.map((item,key)=>{
      return (
      <CardTableBodyRow className={'cursor-pointer'} key={key} >
          <CardTableBodyCell onClick={()=>history.push({pathname: !permissions?.includes("payment inflow") ? "/dashboard/payment-inflow-detail" :  "/teams/payment-inflow-detail",state:{...item,inflow_currency: selectedCurrency}})}>
              <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>history.push({pathname: !permissions?.includes("payment inflow") ? "/dashboard/payment-inflow-detail" :  "/teams/payment-inflow-detail",state:{...item,inflow_currency: selectedCurrency}})}>
            <TextIndicator type={statusEnum[item.txn_status]} title={toTitleCase(item.txn_status)} />
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>history.push({pathname: !permissions?.includes("payment inflow") ? "/dashboard/payment-inflow-detail" :  "/teams/payment-inflow-detail",state:{...item,inflow_currency: selectedCurrency}})}>
            <span className="glade-normal-text-two whitespace-nowrap">{simpleDate(item?.created_at,'numeric','short')}</span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>history.push({pathname: !permissions?.includes("payment inflow") ? "/dashboard/payment-inflow-detail" :  "/teams/payment-inflow-detail",state:{...item,inflow_currency: selectedCurrency}})}>
            <span className="glade-normal-text-one whitespace-nowrap">{item.user_email}</span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>history.push({pathname: !permissions?.includes("payment inflow") ? "/dashboard/payment-inflow-detail" :  "/teams/payment-inflow-detail",state:{...item,inflow_currency: selectedCurrency}})}>
            <span className="glade-normal-text-one whitespace-nowrap">{toTitleCase(item.payment_type)}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap"><CopyTextButton text={item?.txn_ref} /></span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>history.push({pathname: !permissions?.includes("payment inflow") ? "/dashboard/payment-inflow-detail" :  "/teams/payment-inflow-detail",state:{...item,inflow_currency: selectedCurrency}})}>
            <span className="glade-normal-text-one whitespace-nowrap">{item.currency} {Number(item?.value)?.toLocaleString()}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      )
    });
  }

  const renderPaymentInflowsDesk = (data, selectedCurrency) => {
    return data.map((item, key) => {
      return (
        <div key={key} className="flex flex-row justify-between p-1 border-b" onClick={()=>history.push({pathname: !permissions?.includes("payment inflow") ? "/dashboard/payment-inflow-detail" :  "/teams/payment-inflow-detail",state:{...item,inflow_currency: selectedCurrency}})}>
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.txn_status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
               {item.currency} {Number(item?.value)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {item.user_email}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.created_at, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
        </div>
        );
    });
  };

  return (
    <div className="px-3">
      <CardTableHeaderComplex 
        title={`Showing ${tableData.totalItems} records`}
        filter={<Sieve types={['status','date']} onFilter={(value)=>onFilter(value)} onReset={()=>onReset()} disabled={paymentFlow.isLoading} loading={paymentFlow.isLoading} />}
        searchBar={
          <div className="flex flex-row">
            <SelectInput
              label={''}
              id={'search_type'}
              name={'search_type'}
              value={input.search_type}
              onChange={(e)=>setInput({...input, search_type:e.target.value})}
              autoComplete={'search_type'}
              className={'appearance-none glade-grey-cool'}>
              <option value={'transaction_id'}>Transaction ID</option>
              <option value={'customer_email'}>Customer Email</option>
            </SelectInput>
            <SearchInput
              disabled={paymentFlow.isLoading}
              onSearch={onSearch}
              placeholder={input.search_type === 'transaction_id'?'Transaction Reference':'Customer Email'}
            />
          </div>
        }
        rightButton={
          <div>
            <Button.Blue title={'Download As CSV'} onClick={() => downloadPaymentInflowAsCSV(dataForCSV, selectedCurrency)} disabled={paymentFlow.isLoading || isEmpty(paymentFlow?.allPaymentFlows?.transactions)} />
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={paymentFlow.isLoading}>
              <KebabDropdownItem
                activeChild={<Sieve
                  button={<div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"> Filter </div>}
                  types={['status','date']}
                  onFilter={(value) => onFilter(value)}
                  onReset={() => onReset()}
                  disabled={paymentFlow.isLoading}
                  loading={paymentFlow.isLoading}
                />}
                inactiveChild={<span className="glade-normal-text-two glade-black p-4"> Filter </span>}
              />
              {!isEmpty(paymentFlow?.allPaymentFlows?.transactions)?
              <KebabDropdownItem
                activeChild={<span onClick={() => downloadPaymentInflowAsCSV(dataForCSV)} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Download as CSV</span>}
                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Download as CSV</span>}
              />:null}
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || paymentFlow.isLoading?
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable column={['','Status','Date','Customer','Payment Type','Transaction Ref','Payment Amount','']} loading={paymentFlow.isLoading} />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1,2,3]} loading={paymentFlow.isLoading} />
          </div>
        </React.Fragment>
      :
        <React.Fragment>
          <div className="hidden md:block">
            <CardTableBody column={['','Status','Date','Customer','Payment Type','Transaction Ref','Payment Amount','']}>
              {isArray(tableData.data) && renderPaymentInflowsTable(tableData.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderPaymentInflowsDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      }

      <CardTableFooter 
        empty={isEmpty(tableData.data)}
        loading={paymentFlow.isLoading}
        pageNumber={tableData.pageNumber}
        totalPages={tableData.totalPages}
        prevOnClick={()=>dispatch(getAllPaymentFlows({...paginationAttributes, paginate:1, per_page:10, current_page:tableData.previousPage}, selectedCurrency))}
        nextOnClick={()=>dispatch(getAllPaymentFlows({...paginationAttributes, paginate:1, per_page:10, current_page:tableData.nextPage}, selectedCurrency))}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
    </div>
  )
}