import { useSelector } from 'react-redux';

const useTransactionSplit = () => {
    const transactionSplit = useSelector(state => state.transactionSplit);

    let {
        isLoading,
        shouldReload,
        splitAccountIndex,
        splitAccount,
        storeSplitAccount,
        updateSplitAccount,
        removeSplitAccount,
    } = transactionSplit;

    return {
        isLoading,
        shouldReload,
        splitAccountIndex,
        splitAccount,
        storeSplitAccount,
        updateSplitAccount,
        removeSplitAccount,
    }
};

export default useTransactionSplit;
