import React, { useState } from "react";
import GladeLogo from "../logos/GladeLogo";
import { IoCloseSharp } from "react-icons/io5";
import { ImSpinner2 } from "react-icons/im";
import { IoIosArrowBack } from "react-icons/io";
import useUser from "../../hooks/useUser";
import useConvert from "../../hooks/useConvert";
import { CgClose } from "react-icons/cg";
import Button from "../buttons/Button";
import IndividualInput from "../inputs/IndividualInput";
import {
  getPersonalAndBusinessAccounts,
  toggleSelectedCurrency,
  verifyUserPin,
} from "../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import {
  convertCurrency,
  getConvertRate,
} from "../../redux/convert/convertActions";
import { useEffect } from "react";
import SuccessModal from "./SuccessModal";
import { getSuppliedFieldValue, isArray } from "../../helpers/helper";
import InputWithFlagOptions from "./InputWithFlagOptions";
import RateRatio from "./RateRatio";

export default (function ConvertModal({ onClose }) {
  const user = useUser();
  const [input, setInput] = useState({ send_amount: "", recieve_amount: "" });
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [recieveCurrency, setRecieveCurrency] = useState("EUR");
  const [showReview, setShowReview] = useState(false);
  const [openConfirmPin, setOpenConfirmPin] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState();
  const { rateDetails } = useConvert();
  const [itemToDisplay, setItemToDisplay] = useState("");
  const dispatch = useDispatch();
  const sendCurrency = getSuppliedFieldValue("currency", user);
  const [selectedCurrencyData, setSelectedCurrencyData] = useState({});
  const [baseCurrency, setBaseCurrency] = useState("NGN");

  // Get available multicurrencies
  useEffect(() => {
    let currencies = isArray(user?.selectedBusinessOrPersonalAccount?.accounts)
      ? user?.selectedBusinessOrPersonalAccount?.accounts?.map((account) => {
          return { currency: account?.currency };
        })
      : [user?.selectedBusinessOrPersonalAccount?.accounts];
    currencies = currencies?.filter(
      (value) => value?.currency !== sendCurrency
    );
    setAvailableCurrencies(
      currencies?.filter((value) => value?.currency !== sendCurrency)
    );
    setRecieveCurrency(currencies[0]?.currency);
  }, [sendCurrency]);

  useEffect(() => {
    if (
      Number(input?.send_amount.replaceAll(",", "")) +
        Number(rateDetails?.data?.charge) >
      getSuppliedFieldValue("balance", user)
    ) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [input?.send_amount]);

  // Hook for rate and charge
  useEffect(() => {
    dispatch(
      getConvertRate({
        amount: String(input.send_amount).split(",").join(""),
        source_currency: baseCurrency,
        destination_currency: recieveCurrency,
        action: "conversion",
      })
    );
    if (isArray(user?.selectedBusinessOrPersonalAccount?.accounts)) {
      setSelectedCurrencyData(
        user?.selectedBusinessOrPersonalAccount?.accounts.find(
          (data) => data.currency === recieveCurrency
        )
      );
    }
    setInput({ ...input, send_amount: "", recieve_amount: "" });
  }, [recieveCurrency, baseCurrency]);

  useEffect(() => {});

  const selectedFlagOption = (e, index) => {
    // Set state value and puah currency to header
    setBaseCurrency(e);
    dispatch(
      toggleSelectedCurrency(
        user?.selectedBusinessOrPersonalAccount?.accounts[index],
        user?.selectedBusinessOrPersonalAccount
      )
    );
  };
  const onSubmit = () => {
    dispatch(verifyUserPin({ pin: input.pin }, onSuccess, onFailure));
    setItemToDisplay(2);
  };
  const onSuccess = () => {
    dispatch(
      convertCurrency(
        {
          source_amount: Number(String(input.send_amount).split(",").join("")),
          source_currency: sendCurrency,
          destination_currency: recieveCurrency,
          destination_type: user?.selectedBusinessOrPersonalAccount?.is_business
            ? "business"
            : "personal",
          source_type: user?.selectedBusinessOrPersonalAccount?.is_business
            ? "business"
            : "personal",
          destination_uuid: user?.selectedBusinessOrPersonalAccount?.is_business
            ? user?.selectedBusinessOrPersonalAccount?.business_uuid
            : user?.selectedBusinessOrPersonalAccount?.user_uuid,
          source_uuid: user?.selectedBusinessOrPersonalAccount?.is_business
            ? user?.selectedBusinessOrPersonalAccount?.business_uuid
            : user?.selectedBusinessOrPersonalAccount?.user_uuid,
        },
        displaySuccess
      )
    );
    setInput({ ...input, pin: "" });
    // setOpenConfirmPin(false);
  };

  const displaySuccess = (res) =>
    res
      ? setItemToDisplay("") & setShowSuccessModal(true)
      : setOpenConfirmPin(false);
  const onFailure = () => {
    setInput({ ...input, pin: "" });
    setOpenConfirmPin(false);
  };

  const onChange = (e) => {
    if (e.target.name === "send_amount") {
      setInput({
        ...input,
        [e.target.name]: !Number(String(e.target.value).split(",").join(""))
          ? ""
          : Number(String(e.target.value).split(",").join("")).toLocaleString(
              "en-US"
            ),
        recieve_amount: !Number(String(e.target.value).split(",").join(""))
          ? ""
          : (
              Number(String(e.target.value).split(",").join("")) *
              Number(rateDetails?.data?.rate)
            ).toLocaleString("en-US"),
      });
    } else {
      setInput({
        ...input,
        [e.target.name]: !Number(String(e.target.value).split(",").join(""))
          ? ""
          : Number(String(e.target.value).split(",").join("")).toLocaleString(
              "en-US"
            ),
        send_amount: !Number(String(e.target.value).split(",").join(""))
          ? ""
          : (
              Number(String(e.target.value).split(",").join("")) /
              Number(rateDetails?.data?.rate)
            ).toLocaleString("en-US"),
      });
    }
  };
  return (
    <div className="flex flex-col items-center px-6 py-12 w-full bg-white h-full">
      <div className="border-b pb-6 flex justify-between w-10/12 ">
        {!showReview && <GladeLogo />}
        {showReview && (
          <div>
            <div className="flex " onClick={() => setShowReview(false)}>
              <p hidden={showReview === "hide" || showReview === "completed"}>
                <IoIosArrowBack size={28} /> Back
              </p>
            </div>
          </div>
        )}
        <IoCloseSharp
          className="glade-blue cursor-pointer"
          size={35}
          onClick={() => {
            setShowReview(false);
            onClose();
          }}
        />
      </div>
      <div className="mt-6">
        <span className="glade-heading-three glade-home-dark">
          {!showReview
            ? "Convert Currency"
            : showReview === "completed"
            ? ""
            : "Review details of this transfer"}
        </span>
      </div>
      {!showReview ? (
        <div>
          <div className="mt-6">
            <span className="font-bold glade-black">You Convert</span>
            <div className="items-center p-0 m-0 w-full">
              <InputWithFlagOptions
                name="send_amount"
                id="send_amount"
                placeholder={"0.00"}
                value={input?.send_amount}
                onChange={onChange}
                selectedFlag={sendCurrency}
                defaultOptions={
                  user?.selectedBusinessOrPersonalAccount?.accounts
                }
                selectedFlagOption={selectedFlagOption}
              />
            </div>
            <div className="border p-2 glade-button-gray">
              <span className="glade-green">
                YOUR BALANCE -{" "}
                {getSuppliedFieldValue("balance", user)
                  ? Number(
                      getSuppliedFieldValue("balance", user)
                    ).toLocaleString()
                  : "0.00"}{" "}
                {sendCurrency}
              </span>
            </div>
          </div>

          <div className="mt-6">
            {availableCurrencies?.length > 0 ? (
              <span className="font-bold glade-black">You Get</span>
            ) : null}
            {availableCurrencies?.length > 0 ? (
              <div className="p-0 m-0 relative">
                <div className="">
                  <div className="items-center p-0 m-0 w-full">
                    <InputWithFlagOptions
                      name="recieve_amount"
                      id="recieve_amount"
                      placeholder={"0.00"}
                      value={input?.recieve_amount}
                      onChange={onChange}
                      selectedFlag={recieveCurrency}
                      defaultOptions={
                        user?.selectedBusinessOrPersonalAccount?.accounts
                      }
                      selectedFlagOption={(value) => setRecieveCurrency(value)}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {selectedCurrencyData?.account_number === "pending" && (
              <div className="border p-2 glade-bg-red-100">
                <span className="glade-red">
                  <span className="font-bold">{recieveCurrency}</span> Account
                  number is unavailable
                </span>
              </div>
            )}
            {/* RATE RATIO BOX */}
            <RateRatio
              baseCurrency={sendCurrency}
              recieveCurrency={recieveCurrency}
              send_amount={input?.send_amount}
            />

            <button
              disabled={
                !input.send_amount ||
                input.send_amount === "0.00" ||
                insufficientBalance ||
                !rateDetails?.data?.rate ||
                selectedCurrencyData?.account_number === "pending"
              }
              className="w-full p-2 rounded glade-button-blue mt-6"
              onClick={() => setShowReview(true)}
            >
              Confirm
            </button>
          </div>
        </div>
      ) : (
        <div className="gap-4 flex flex-col mt-6 w-4/12">
          <div>
            <p>You are converting</p>
            <p className="glade-heading-two mt-3">
              <span className="font-bold">
                {sendCurrency}{" "}
                {Number(input?.send_amount.replaceAll(",", "")).toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </span>
            </p>
          </div>
          <div>
            <p>To</p>
            <p className="glade-heading-two mt-3">
              <span className="font-bold">
                {recieveCurrency}{" "}
                {Number(
                  input?.recieve_amount.replaceAll(",", "")
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </span>
            </p>
          </div>
          <button
            disabled={!input.send_amount || input.send_amount === "0.00"}
            className="w-full p-2 rounded glade-button-blue"
            onClick={() => {
              setOpenConfirmPin(true);
              setItemToDisplay(1);
            }}
          >
            Complete Transaction
          </button>
        </div>
      )}
      {openConfirmPin && (
        <div className="modal-overlay">
          {itemToDisplay === 1 && (
            <div className="toastr-container  flex justify-center items-center">
              <div className="inline-block w-full max-w-lg py-3 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-md">
                <div className="flex justify-center items-center px-6 pb-3 border-b-2 border-solid border-gray-100">
                  <div className="glade-subtitle-two font-bold glade-black text-left">
                    <span className="glade-heading-two">
                      Transfer Confirmation
                    </span>
                  </div>
                  <button
                    className="glade-black absolute right-10"
                    onClick={() => setOpenConfirmPin(false)}
                  >
                    <CgClose />
                  </button>
                </div>
                <div className="p-6">
                  <div className="flex justify-center mb-6">
                    <span className="glade-small-text-one glade-grey-cool">
                      Enter transfer pin to proceed
                    </span>
                  </div>
                  <div className="flex justify-center mb-12">
                    <IndividualInput
                      numberOfInputTags={4}
                      label={""}
                      onChange={(value) => setInput({ ...input, pin: value })}
                    />
                  </div>
                  <div className="flex justify-center px-12">
                    <Button.Blue
                      title={"Submit Pin"}
                      type={"button"}
                      className={"py-3"}
                      onClick={onSubmit}
                      disabled={user.isLoadingVerifyPin || !input?.pin}
                      loading={user.isLoadingVerifyPin}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {itemToDisplay === 2 && (
            <div className="absolute flex flex-col items-center px-6 py-12 w-full bg-white h-full">
              <div className="border-b pb-6 flex justify-between w-10/12">
                <div>
                  <div className="flex " onClick={() => setShowReview(false)}>
                    <p hidden={true}>
                      <IoIosArrowBack size={28} /> Back
                    </p>
                  </div>
                </div>
                <IoCloseSharp
                  className="glade-blue cursor-pointer"
                  size={35}
                  onClick={() => {
                    setShowReview(false);
                    onClose();
                  }}
                />
              </div>
              <div>
                <div className="mt-6">
                  <span className="glade-heading-three glade-home-dark">
                    Converting Funds
                  </span>
                </div>
                <div className="flex justify-center">
                  <ImSpinner2
                    className="mt-6 text-center"
                    size={70}
                    color="#0B7AE0"
                  />
                </div>
              </div>
            </div>
          )}
          {showSuccessModal && (
            <div className="absolute flex flex-col items-center px-6 py-12 w-full bg-white h-full">
              <div className="border-b pb-6 flex justify-between w-10/12">
                <div>
                  <div className="flex " onClick={() => setShowReview(false)}>
                    <p hidden={true}>
                      <IoIosArrowBack size={28} /> Back
                    </p>
                  </div>
                </div>
                <IoCloseSharp
                  className="glade-blue cursor-pointer"
                  size={35}
                  onClick={() => {
                    dispatch(getPersonalAndBusinessAccounts());
                    setShowReview(false);
                    onClose();
                  }}
                />
              </div>
              <SuccessModal
                title={"Conversion Successful"}
                showDownloadButton={false}
                downloadAction={() => {}}
                onClose={() => {
                  dispatch(getPersonalAndBusinessAccounts());
                  onClose();
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
});
