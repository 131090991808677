// Application types
export const LOADING_PAYROLL = 'LOADING_PAYROLL';
export const LOADING_PAYDAY = 'LOADING_PAYDAY';
export const RELOAD_PAYROLL = 'RELOAD_PAYROLL';
export const RELOAD_PAYDAY = 'RELOAD_PAYDAY';

export const GET_PAYROLL_STAFF = 'GET_PAYROLL_STAFF';
export const CREATE_PAYROLL_STAFF = 'CREATE_PAYROLL_STAFF';
export const CREATE_PAYROLL_STAFF_BULK = 'CREATE_PAYROLL_STAFF_BULK';
export const REMEMBER_BULK_CREATE_STAFF_LIST = 'REMEMBER_BULK_CREATE_STAFF_LIST';
export const UPDATE_PAYROLL_STAFF = 'UPDATE_PAYROLL_STAFF';
export const DELETE_PAYROLL_STAFF = 'DELETE_PAYROLL_STAFF';

export const GET_PAYDAY = 'GET_PAYDAY';
export const GET_PAYDAY_BY_ID = 'GET_PAYDAY_BY_ID';
export const CREATE_PAYDAY = 'CREATE_PAYDAY';
export const UPDATE_PAYDAY = 'UPDATE_PAYDAY';
export const DELETE_PAYDAY = 'DELETE_PAYDAY';

export const LOADING_SALARY_ADVANCEMENT = 'LOADING_SALARY_ADVANCEMENT';
export const SUCCESS_SALARY_ADVANCEMENT = 'SUCCESS_SALARY_ADVANCEMENT';
export const FAILURE_SALARY_ADVANCEMENT = 'FAILURE_SALARY_ADVANCEMENT';

export const LOADING_PAYROLL_STAFF = "LOADING_PAYROLL_STAFF";
export const PAYROLL_STAFF_SUCCESS = "PAYROLL_STAFF_SUCCESS";
export const PAYROLL_STAFF_FAILURE = "PAYROLL_STAFF_FAILURE";

export const LOADING_DELETE_PAYDAY = "LOADING_DELETE_PAYDAY"