import { csvWorkbookDownload } from "../../helpers/XlsxUtils";
import * as xlsx from "xlsx";

export function downloadTransactionsAsCSV(statements, onChange=()=>{}){
    
    const fileName = "Glade-Account-Statement";
    const getWorksheets=({ xlsx }) => {
        return {
            data: xlsx.utils.json_to_sheet(
                statements?.map((value, i) => ({
                    "S/N": `${i + 1}`,
                    "Transaction Type": value?.txn_type,
                    "Narration": value?.narration,
                    "Amount": value?.value,
                    "Transaction Date": `${value?.txn_date} UTC`,
                    "Transaction Ref": value?.txn_ref,
                    "Session ID": value?.session_id,
                    "Balance": value?.balance_after,
                    "Date": `${value?.created_at} UTC`,
                })),
            ),
        }
    }

  (async () => {
    if (getWorksheets) {
      try {
        onChange({ isLoading: true, isError: false });
        const workbook = xlsx.utils.book_new();
        const worksheets = await getWorksheets({ workbook, xlsx });
        if (worksheets) {
          for (const key in worksheets) {
            xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
          }
          if (workbook.SheetNames.length) {
            csvWorkbookDownload(workbook, fileName);
          }
        }
      } catch (error) {
        onChange({ isLoading: false, isError: true });
      }
      onChange({ isLoading: false, isError: false });
    }
  })();
}