import { LOADING_TEAM, LOGIN_TEAM_FAILURE, LOGIN_TEAM_SUCCESS, SAVE_TEAM_PERMISSIONS } from "./Types";
const initialState = {
  isLoadingTeam: false,
  team_user: "",
  token: "",
  refresh_token: "",
  user_uuid: "",
  isBusiness: "",
  business: {},
  isBusinessUUID: "",
  ngn_accounts: [],
  permissions: []
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_TEAM:
      return {
        ...state,
        isLoadingTeam: true,
      };

    case LOGIN_TEAM_SUCCESS:
      return {
        ...state,
        isLoadingTeam: false,
        team_user: action?.payLoad?.data?.data,
        token: action?.payLoad?.data?.data?.token,
        refresh_token: action?.payLoad?.data?.data?.token,
        user_uuid: action?.payLoad?.data?.data?.employee?.user_uuid,
        business: action?.payLoad?.data?.data?.business,
        isBusiness: action?.payLoad?.data?.data?.employee?.business_uuid ? true : false,
        isBusinessUUID: action?.payLoad?.data?.data?.employee?.business_uuid,
        ngn_accounts: action?.payLoad?.data?.data?.ngn_accounts
      };
    case LOGIN_TEAM_FAILURE:
      return {
        ...state,
        isLoadingTeam: false,
        teamUser: {},
      };
    case SAVE_TEAM_PERMISSIONS:
      let flatPermission = action?.payload.map(perm => perm.permission_title)
      return {
        ...state,
        permissions: flatPermission,
            }
    default:
        return {
            ...state,
        }
  }
}
