import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {CardPlainWithButton,CardPlainWithButtonSection} from "../../components/cards/CardPlainWithButton";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import useBusiness from "../../hooks/useBusiness";
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import {getBusinessCategories, submitUnRegisteredBusiness} from '../../redux/business/businessActions';
import { isArray } from "../../helpers/helper";

export default function RegisterBusinessName() {

  const history = useHistory();
  const dispatch = useDispatch();
  const business = useBusiness();
  const [input, setInput] = React.useState({
    business_category: "",
    business_name: "",
    business_name_two: "",
    business_name_three: "",
    business_email: "",
    business_description: "",
    business_objective: "",
    business_address: "",
    business_website: "",
  });

  React.useEffect(() => {
    dispatch(getBusinessCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const onSubmit = () => {
    dispatch(submitUnRegisteredBusiness(input,()=>history.push('/dashboard/home')));
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Home"} path={"/dashboard/home"} />
        </div>

        <CardPlainWithButton>
          <CardPlainWithButtonSection 
            title={"Register Business Name"}
            loading={business.isSubmittingBusiness} 
            buttonTitle={"Submit"} 
            buttonOnClick={() => onSubmit()} 
            buttonDisabled={business.isSubmittingBusiness}>
            <div className="my-6 px-14">
              <div className="space-y-6">

                <TextInput 
                  label={'Business Name 1st Option'}
                  id={'business_name'}
                  name={'business_name'}
                  type={'text'}
                  value={input.business_name}
                  onChange={onChange}
                  placeholder={'Enter business name 1st option'}
                  autoComplete={'business_name'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Business Name 2nd Option '}
                  id={'business_name_two'}
                  name={'business_name_two'}
                  type={'text'}
                  value={input.business_name_two}
                  onChange={onChange}
                  placeholder={'Enter business name 2nd option'}
                  autoComplete={'business_name_two'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Business Name 3rd Option'}
                  id={'business_name_three'}
                  name={'business_name_three'}
                  type={'text'}
                  value={input.business_name_three}
                  onChange={onChange}
                  placeholder={'Enter business name 3rd option'}
                  autoComplete={'business_name_three'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Business Email'}
                  id={'business_email'}
                  name={'business_email'}
                  type={'text'}
                  value={input.business_email}
                  onChange={onChange}
                  placeholder={'Enter registered business email'}
                  autoComplete={'business_email'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Business Address'}
                  id={'business_address'}
                  name={'business_address'}
                  type={'text'}
                  value={input.business_address}
                  onChange={onChange}
                  placeholder={'Enter business address'}
                  autoComplete={'business_address'}
                  className={'appearance-none'}
                />

                <SelectInput 
                  label={'Select Business Industry'}
                  id={'business_category'}
                  name={'business_category'}
                  value={input.business_category ?? ''}
                  onChange={onChange}
                  autoComplete={'business_category'}
                  className={'appearance-none'}>
                  <option value={''} disabled={true}>Select Industry</option>
                  {isArray(business?.businessCategories) && business?.businessCategories.map((item, key)=>{
                    return (
                      <option key={key} value={item}>{item}</option>
                    );
                  })}
                </SelectInput>

                <TextAreaInput 
                  label={'Business Description'}
                  id={'business_description'}
                  name={'business_description'}
                  rows={3}
                  type={'text'}
                  value={input.business_description}
                  onChange={onChange}
                  placeholder={'What does your business do?'}
                  autoComplete={'business_description'}
                  className={'appearance-none'}
                />

                <TextAreaInput 
                  label={'Business Objective'}
                  id={'business_objective'}
                  name={'business_objective'}
                  rows={2}
                  type={'text'}
                  value={input.business_objective}
                  onChange={onChange}
                  placeholder={'What is the objective of this business?'}
                  autoComplete={'business_objective'}
                  className={'appearance-none'}
                />

                <TextInput 
                  label={'Business Website'}
                  id={'business_website'}
                  name={'business_website'}
                  type={'text'}
                  value={input.business_website}
                  onChange={onChange}
                  placeholder={'Enter business website'}
                  autoComplete={'business_website'}
                  className={'appearance-none'}
                />

              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  );
}