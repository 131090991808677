import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_LGAS
} from "./types";

/**
 * Get list of countries
 * @returns {void}
 */
 export const getApprovedCountries = (payLoad, callBack=()=> {}) => {
  return (dispatch) => {
    Axios.get(`lookup/countries`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_COUNTRIES, payLoad: res });
      successHandler(res);
      callBack(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
 };

 /**
 * Get list of states
 * @returns {void}
 */
  export const getApprovedStates = (payLoad, callBack=()=> {}) => {
    return (dispatch) => {
      Axios.get(`lookup/states/${payLoad.country_id}`)
      .then((res) => {
        dispatch({ type: GET_STATES, payLoad: res });
        successHandler(res);
        callBack(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
    };
  };
    

 /**
 * Get list of LGAs
 * @returns {void}
 */
export const getApprovedLGAs = (payLoad, callBack = () => { }) => {
    return (dispatch) => {
      Axios.get(`lookup/state/lga?state_id=${payLoad.state_id}`)
      .then((res) => {
        dispatch({ type: GET_LGAS, payLoad: res });
        successHandler(res);
        callBack(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
    };
  };