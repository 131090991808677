import React, { useState } from 'react';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import {  clearAllNotifications, markInAppNotificationAsRead } from '../../redux/notifications/notificationsActions';
import { addHours, formatDistanceToNowStrict, } from 'date-fns';
import { useHistory } from 'react-router-dom';

export default function Notifications({ totalNotifications, setShowNotificationModal }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showNotificationList, setShowNotificationList] = useState(true);
  const [viewNotificationData, setViewNotificationData] = useState('');

  const JsonDisplay = ({ jsonData, className }) => {
    const lines = Object.entries(jsonData).map(([key, value]) => (
      <div key={key} className={className}>
        <strong>{key}:</strong> {value}
      </div>
    ));
    return <div className='pl-4'>{lines?.length > 1 ? `${jsonData[0]}...` : jsonData[0]}</div>
  };

  return (
    <div className="absolute mt-14 h-[648px] overflow-auto w-full md:w-3/4 lg:w-1/2 xl:w-1/3 scroll-y-auto z-20 border right-0 md:right-20 bg-white p-4 rounded-md">
      {
        totalNotifications?.length === 0 ?
          <div className="h-full">
            <div className='w-full flex flex-col bg-white rounded-md p-3'>
              <div className='h-full flex flex-col justify-between'>
                <div className="flex space-x-4 justify-center relative">
                  <h3 className="glade-heading-four">Notifications</h3>
                  <button className="absolute right-0 bottom-0 glade-ash">Clear All</button>
                </div>
                <div className='flex flex-col justify-center text-center h-44'>
                  <h4 className='glade-heading-four'>No notifications</h4>
                  <span className='glade-normal-text-two glade-ash'>You do not have any notifications</span>
                </div>
              </div>
            </div>
          </div>
          :
          showNotificationList ?
            <div>
              <div className="flex space-x-4 justify-center relative">
                <h3 className="glade-heading-four font-bold">Notifications</h3>
                <button className="absolute right-0" style={{ color: '#1634A1' }} onClick={() => { dispatch(clearAllNotifications()) }}>Clear All</button>
              </div>
              <div className='flex flex-col-reverse'>
                {
                  totalNotifications?.slice(0, 3).map((note, index) =>
                    <div className={`mt-4 scroll-y-auto cursor-pointer ${note?.is_read === 0 ? 'glade-bg-light-grey shadow-md rounded-lg' : ''}`} key={index}
                      onClick={() => {

                        history.push({
                          pathname: '/dashboard/notifications',
                          state: { notification: note }
                        });
                        dispatch(markInAppNotificationAsRead(note?.id))
                        setShowNotificationModal(false)
                      }}
                    >
                      <div className="flex mb-2 justify-between">
                        <p><span className="inline-block mr-2 w-2 h-2 glade-bg-ash rounded " style={{ background: note.is_read === 0 ? '#032086' : 'glade-bg-ash' }} ></span><span className="font-bold">{note.title}</span></p>
                        {formatDistanceToNowStrict(addHours(new Date(note.created_at), 1))} ago
                      </div>
                      <JsonDisplay jsonData={JSON.parse(note?.body)} />
                    </div>
                  )?.reverse()}
              </div>
              <div className='flex justify-center mt-4'><button className='glade-blue font-bold' onClick={() => { history.push({ pathname: '/dashboard/notifications', state: { notification: totalNotifications[0], viewAll: true } }); setShowNotificationModal(false) }}>View All Notifications</button></div>
            </div>
            :
            <div>
              <div className="flex space-x-4 justify-center relative">
                <h3 className="glade-heading-four">Notifications</h3>
                <button className="absolute left-0 glade-ash" onClick={() => setShowNotificationList(true)}><BsArrowLeftShort size={25} color={'#000000'} /></button>
              </div>
              <div className="mt-4 cursor-pointer">
                <div className="flex mb-2 justify-between">
                  <p><span className="font-bold">{viewNotificationData.title}</span></p>
                  {formatDistanceToNowStrict(addHours(new Date(viewNotificationData.created_at), 1))} ago
                </div>
                <div className='h-72 min-h-72'>{`${viewNotificationData.body}`}</div>
              </div>
            </div>
      }
    </div>
  );
}
