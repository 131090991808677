import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PlainModal from "../../components/modals/PlainModal";
import Button from "../../components/buttons/Button";
import { useForm } from "react-hook-form";
import { terminateVirtualCard } from "../../redux/virtual-cards/virtualCardActions";
import { MdOutlineClose } from "react-icons/md";
import useVirtualCards from "../../hooks/useVirtualCards";
import { RiErrorWarningLine, RiLoader5Fill } from "react-icons/ri";
import { FormError } from "../../validation/virtual-card/virtualCardValidation";

export default React.forwardRef(function VirtualCardDeleteModal(props, ref) {
  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: (value) => {
      setInput(value);
      plainModalRef.current.open();
    },
    close: () => {
      setInput({});
      plainModalRef.current.close();
    },
    toggle: (value) => {
      setInput(value);
      plainModalRef.current.toggle();
    },
  }));

  const history = useHistory();
  const dispatch = useDispatch();
  const [input, setInput] = React.useState({});
  const { isLoading } = useVirtualCards();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });

  const onsubmit = (e, action) => {
    if (input.reference) {
      dispatch(
        terminateVirtualCard({ reference: input.reference, reason: e.reason }, () =>
          history.push(`/dashboard/cards`)
        )
      );
    }
  };

  return (
    <PlainModal
      ref={plainModalRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitleSection={<React.Fragment></React.Fragment>}
      modelContentSection={
        <form onSubmit={handleSubmit((e) => onsubmit(e, "delete"))}>
          <div className="p-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-8">
            <h4 className="glade-normal-text-two glade-black">
              <span className="font-bold">Delete Card</span>
            </h4>
            <MdOutlineClose
              size={16}
              className="glade-black cursor-pointer"
              onClick={() => plainModalRef.current.close()}
            />
          </div>
          <div className="mb-4 pr-6 pl-6">
            <label htmlFor="reason" className="flex glade-small-text-two">
              Reason
            </label>
            <textarea
              rows={5}
              id="reason"
              name="reason"
              {...register("reason", {
                required: "Enter a reason",
              })}
              // required
              placeholder="Enter a reason"
              className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two resize-none"
            />
            <div className="flex items-center justify-between flex-wrap">
              <FormError errors={errors} name="reason" />
            </div>
          </div>
          <div className="pl-6 pr-6 text-left flex gap-2">
            <RiErrorWarningLine size={30} />
            <p className="pt-1 glade-grey-dark">
              Note that card deletion is permanent and action can not be
              reversed. Card will be deleted within 24 hours after request is
              submitted and approved.
            </p>
          </div>
          <div className="-mx-6 -mb-6 flex justify-between p-5 border-t-2 border-solid border-gray-200 mt-10">
          
          <div className="pl-6 pr-6 w-full flex items-center justify-between">
            <div className=''>
            <Button.Transparent
              title={"No, Go back"}
              onClick={() => plainModalRef.current.close()}
              className="w-full py-3"
            />
            </div>
            <div className=''>
            <button
              disabled={isLoading}
              className={`glade-normal-text-three glade-button-red ${
                isLoading ? "cursor-not-allowed" : ""
              } text-white py-2 px-4 flex justify-center items-center text-center rounded-sm`}
            >
              {isLoading ? "Submitting" : "Submit and Delete Card"}
              {isLoading && (
                <RiLoader5Fill size={24} className="animate-spin ml-4" />
              )}
            </button>
            </div>
            </div>
          </div>
        </form>
      }
      // modelContentSection={
      //     <div className='p-6'>
      //         <div className="flex flex-col space-y-3">
      //             <h4 className='font-medium text-lg'>Are you sure you want to delete this card?</h4>
      //             <p>Funds in this card will be sent to your wallet and this action can not be reversed</p>
      //             <div className="flex justify-between gap-x-6 pt-8">
      //   <div className='w-1/2'>
      //     <Button.Transparent title={'No, Go back'} onClick={() => plainModalRef.current.close()} className='w-full py-3' />
      //   </div>
      //   <div className='w-1/2'>
      //     <Button.Danger2 title={'Yes, Delete'} onClick={() => onsubmit()} className='w-full py-3' />
      //   </div>
      // </div>

      //         </div>
      //     </div>
      // }
    />
  );
});
