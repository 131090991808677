import { useSelector } from "react-redux";

const useAccountLimit = () => {
    const accountLimit = useSelector((state) => state.accountLimit);
    
    const {
        isLoading,
        limit_data,
        limit_data_fail,
        system_data,
        system_data_fail,
        updateSuccess
      } = accountLimit;

      return {
        isLoading,
        limit_data,
        limit_data_fail,
        system_data,
        system_data_fail,
        updateSuccess
      }
}
    
export default useAccountLimit;