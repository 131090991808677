import { useEffect, Fragment } from "react";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/inputs/TextInput";
import { useDispatch } from "react-redux";
import {
  getBanks,
  resolveAccountDetails,
} from "../../redux/fund-transfer/fundTransferActions";
import useFundTransfer from "../../hooks/useFundTransfer";
import CountrySelectionField from "../auth/CountrySelectionField";

const RecipientDetails = ({ input, setInput, setStep, step }) => {
  const dispatch = useDispatch();
  const { banks } = useFundTransfer();
  useEffect(() => {
    dispatch(getBanks({ mode: "external" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      (input?.beneficiary_account_number?.length === 10 &&
        input?.beneficiary_bank_code) ||
      (input?.transfer_type === "internal" &&
        input?.beneficiary_account_number?.length === 10)
    ) {
      dispatch(
        resolveAccountDetails(
          {
            account_number: input.beneficiary_account_number,
            bank_code: input.beneficiary_bank_code ?? "",
            mode:
              input?.transfer_type !== "external"
                ? "internal"
                : "external_local",
          },
          (resolveAccountDetails) =>
            setInput((form) => ({
              ...form,
              beneficiary_account_name: resolveAccountDetails.account_name,
              session_id: resolveAccountDetails?.session_id ?? "",
            }))
        )
      );
    }
  }, [input?.beneficiary_account_number, input?.beneficiary_bank_code]);
  function onSelectProvider(provider) {
    setInput((form) => ({
      ...form,
      beneficiary_bank_code: provider.code,
      beneficiary_bank_name: provider.name,
    }));
  }
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
      beneficiary_account_name:
        input?.beneficiary_account_number?.length !== 10
          ? ""
          : input?.beneficiary_account_name,
    });
  };
  return (
    <div className="md:w-3/12">
      <p className="mt-12 glade-heading-two glade-home-dark">
        <span className="font-bold">Enter Recipient Details</span>
      </p>
      <div className="text-left flex flex-col gap-4 py-12">
        <TextInput
          label={"Account Number"}
          name={"beneficiary_account_number"}
          id={"beneficiary_account_number"}
          type={"number"}
          value={input?.beneficiary_account_number}
          placeholder={"Enter Account number"}
          onChange={handleChange}
        />
        <div>
          {banks?.length > 0 && input?.transfer_type === "external" && (
            <Fragment>
              {" "}
              <label className="block glade-small-text-two glade-black mb-1">
                Select Bank
              </label>
              <CountrySelectionField
                title={"Select Bank"}
                placeholder={"Search Bank"}
                defaultValue={input?.beneficiary_bank_name}
                onSelectionChange={onSelectProvider}
                optionsList={banks.map((bank) => {
                  return {
                    ...bank,
                    country_name: bank?.bank_name, //component was initially designed for country, modified the data to suit the component.
                    code: bank?.code,
                  };
                })}
              />
            </Fragment>
          )}
        </div>
        <TextInput
          label={"Account Name"}
          placeholder={""}
          name={"beneficiary_account_name"}
          id={"beneficiary_account_name"}
          value={input?.beneficiary_account_name}
          disabled
        />
        <TextInput
          label={"Amount"}
          name={"amount"}
          id={"amount"}
          type={"number"}
          value={input?.amount}
          placeholder={"Enter Amount"}
          onChange={handleChange}
        />
        <TextInput
          label={"Narration"}
          placeholder={"Optional"}
          name={"narration"}
          id={"narration"}
          value={input?.narration}
          onChange={handleChange}
        />

        <div className="flex justify-center mt-2">
          <Button.Blue
            className={"px-3"}
            title={"Continue"}
            onClick={() => setStep(step + 1)}
            disabled={!input?.beneficiary_account_name || !input?.amount}
          />
        </div>
      </div>
    </div>
  );
};

export default RecipientDetails;
