import React from 'react';
import PlainModal from '../../components/modals/PlainModal';
import CopyTextButton from "../../components/buttons/CopyTextButton";
import LinkWithIconRight from '../../components/buttons/LinkWithIconRight';
import ReferralRewardsModal from './ReferralRewardsModal';
import { getReferralRewardsByYear } from '../../redux/settings/settingsActions';
import { useState } from 'react';
import { useDispatch } from "react-redux";


const ReferralModal = ({ referral, referralRewardsBreakdown, referralYears, showRef, setShowRef }) => {

  const dispatch = useDispatch();
  const [showRefRewardModal, setShowRefRewardModal] = useState(false);

  return (
    <PlainModal
      visibility={showRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitle={''}
      modelContentSection={
        <div className="flex flex-col space-y-9">

          {!showRefRewardModal ?
            <div className="flex flex-col px-9 pb-9 space-y-2">
              <div className="flex flex-col px-9 pt-9 text-center space-y-2">
                <h3>Referral Details</h3>
                <span className='glade-text-grey pt-0'>Share your referral link or code with your friends and earn ₦200 once they sign up and get verified.</span>
              </div>
              <div className="flex flex-row justify-between py-5 px-2 mx-4 border-b-2 glade-grey-dark">
                <div className="flex flex-col">
                  <h3>Referral Code</h3>
                </div>
                <div className="flex flex-col">
                  <span className="glade-normal-text-one glade-black whitespace-nowrap">
                    <CopyTextButton text={referral?.referral_code} />
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between py-5 px-2 mx-4 border-b-2 glade-grey-dark">
                <div className="flex flex-col">
                  <h3>Referral Link</h3>
                </div>
                <div className="flex flex-col">
                  <span className="glade-normal-text-one whitespace-nowrap">
                    <CopyTextButton text={referral.referral_link} textClassName='glade-blue glade-normal-text-one' />
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between py-5 px-2 mx-4 border-b-2 glade-grey-dark">
                <div className="flex flex-col">
                  <h4>Sign Ups</h4>
                </div>
                <div className="flex flex-col">
                  <h4 className="glade-normal-text-one glade-black">
                    {referral.sign_ups ? referral.sign_ups : 0}
                  </h4>
                </div>
              </div>
              <div className="flex flex-row justify-between py-5 px-2 mx-4 glade-grey-dark">
                <div className="flex flex-col">
                  <h4>Total Rewards</h4>
                </div>
                <div className="flex flex-col">
                  <h4 className="glade-normal-text-one glade-black">
                    {referral.total_rewards ? '\u20A6' + referral.total_rewards : '\u20A6' + 0}
                  </h4>
                </div>
              </div>
              <div>
                <div className='flex flex-row justify-end'>
                  <LinkWithIconRight title='View Rewards Breakdown'
                    onClick={() => { setShowRefRewardModal(true) }
                    } />
                </div>
              </div>
            </div>
            :
            <ReferralRewardsModal
              referralRewardsBreakdown={referralRewardsBreakdown}
              referral={referral}
              referralYears={referralYears}
            />
          }
        </div>
      }
      onClose={() => { setShowRef(); dispatch(getReferralRewardsByYear(referral.year)) }}
    />
  )
}

export default ReferralModal
