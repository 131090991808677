import { useSelector } from "react-redux";

const useCountries = () => {
    const countries = useSelector((state) => state.countries);

    const {
      getCountries,
      getStates,
      getLgas
    } = countries;

    return {
      getCountries,
      getStates,
      getLgas
    };
};

export default useCountries;
