import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import Button from '../../components/buttons/Button';
import SearchInput from '../../components/inputs/SearchInput';
import PipedButton from '../../components/buttons/PipedButton';
import {rememberBulkFundTransferList, makeExternalBulkTransfer} from '../../redux/fund-transfer/fundTransferActions';
import useFundTransfer from '../../hooks/useFundTransfer';
import useUser from '../../hooks/useUser';
import {isArray, isEmpty, pagination, statusEnum} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import TextIndicator from '../../components/indicators/TextIndicator';
import {processUploadedBulkFundTransferCSV} from './UploadBulkFundTransferAsCSV';
import FundTransferBulkFileUploadReviewModal from './FundTransferBulkFileUploadReviewModal';
import ConfirmPinModal from './ConfirmPinModal';
import {makeExternalBulkTransferValidation} from "../../validation/fund-transfer/fundTransferValidation";
import {getPersonalAndBusinessAccounts} from "../../redux/auth/authActions";
import {KebabDropdown, KebabDropdownItem} from "../../components/dropdowns/KebabDropdown";
import useTeam from '../../hooks/useTeam';

export default function FundTransferBulkFileUploadReview() {

    const hiddenFileUploadButtonRef = React.useRef(null);
    const fileUploadBulkFundTransferReviewModalRef = React.useRef(null);
    const confirmPinModalRef = React.useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useUser();
    const fundTransfer = useFundTransfer();
    const {permissions} = useTeam();
    
    const [input, setInput] = React.useState({recipients:[]});

    // Pagination Function And Control
    const [currentPage, setCurrentPage] = React.useState(1);
    const [tableData, setTableData] = React.useState([]);
    React.useEffect(() => {
        setTableData(pagination(fundTransfer.bulkTransferList ?? [], 10, currentPage));
        // eslint-disable-next-line 
    }, [currentPage, fundTransfer.bulkTransferList]);

    const getBankNameWithCode = (code) => {
        const bank = fundTransfer.banks?.filter(item => {
            return item.bank_code == code;
       });
       return bank[0]?.bank_name ?? "";
    }

    // Search Function And Control
    const onSearch = (search) => {
        const filteredData = fundTransfer.bulkTransferList?.filter(item => {
            return item.account_number.toLowerCase().includes(search.toLowerCase());
        });
        setTableData(pagination(filteredData ?? [], 10, currentPage));
    }

    const onSubmitWithPin = () => {
        let data = fundTransfer.bulkTransferList.map((item) => {
            return {
                "narration": item.note,
                "orderRef": item.account_number  + "-" + new Date().getTime()+ '-' + item.id,
                "amount": item.amount,
                "sender_name": item.account_name,
                "bankcode": item.bank_code,
                "bank_name": item.bank_name ?? "",
                "accountnumber": item.account_number,
                "account_number":item.account_number,
            }
        });

        setInput({recipients: data});
        if (makeExternalBulkTransferValidation(data)) {
          confirmPinModalRef.current.open();
        }
    }

    const onSubmit = () => {
        dispatch(makeExternalBulkTransfer(input.recipients,()=>{history.push(!permissions?.includes("fund transfer") ? '/dashboard/fund-transfer' : "/teams/fund-transfer"); dispatch(getPersonalAndBusinessAccounts());}));
    }

    const onReplaceFile = async (e) => {
        if (e.target.files[0]) {
            let data = await processUploadedBulkFundTransferCSV(e.target.files[0]);

            if (data?.length > 0) {
                data = data.map((item) => {
                    return {
                        "note": item.note,
                        "id": item.id,
                        "amount": item.amount,
                        "account_name": item.account_name,
                        "bank_code": item.bank_code,
                        "account_number": item.account_number,
                        "bank_name": getBankNameWithCode(item.bank_code) ?? ""
                    }
                });

                dispatch(rememberBulkFundTransferList(data));
            }
        }
    }

    const onDelete = (data) => {
        let newBulkTransferList = fundTransfer.bulkTransferList.filter(item => item.id !== data.id);
        dispatch(rememberBulkFundTransferList(newBulkTransferList));
    }

    const renderBulkTransferListTable = (data) => {
        return data.map((item,key)=>{
            return (
                <CardTableBodyRow key={key}>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.account_number}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.account_name}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{(item.bank_name != "" && item.bank_name != null)  ? item.bank_name : item.bank_code}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.amount}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.note}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <TextIndicator type={statusEnum[item.status??'pending']} title={item.status??'pending'} />
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <PipedButton 
                            primaryButtonTitle="Edit" 
                            primaryButtonOnClick={()=>{fileUploadBulkFundTransferReviewModalRef?.current?.setStateAndOpen(item)}}
                            secondaryButtonTitle="Delete" 
                            secondaryButtonOnClick={()=>onDelete(item)}
                            secondaryButtonClassName="glade-red"
                        />
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                </CardTableBodyRow>
            )
        });
    }

    const renderBulkTransferListDesk = (data) => {
        return data.map((item, key) => {
          return (
            <div key={key} className="flex flex-row justify-between p-1 border-b">
              <div className="flex flex-row space-x-3">
                <div className="flex flex-col space-y-1 py-1">
                  <div>
                    <span className="glade-subtitle-one glade-black whitespace-nowrap">
                        {item.account_number}
                    </span>
                  </div>
                  <div>
                    <span className="glade-normal-text-two glade-black whitespace-nowrap">
                        {item.account_name} 
                    </span>
                  </div>
                  <div>
                    <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                        {item.currency} {Number(item?.amount ?? 0)?.toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <PipedButton 
                    primaryButtonTitle="Edit" 
                    primaryButtonOnClick={()=>{fileUploadBulkFundTransferReviewModalRef?.current?.setStateAndOpen(item)}}
                    secondaryButtonTitle="Delete" 
                    secondaryButtonOnClick={()=>onDelete(item)}
                    secondaryButtonClassName="glade-red"
                />
              </div>
            </div>
          );
        });
    };

    return (
        <div className="p-3">
            <div className="flex flex-col">

                <FundTransferBulkFileUploadReviewModal ref={fileUploadBulkFundTransferReviewModalRef} />

                <div className="mb-6">
                    <BreadCrumbs title={'Bulk Transfer'} path={!permissions?.includes("fund transfer") ? '/dashboard/fund-transfer/bulk-transfer/upload' : "/teams/fund-transfer/bulk-transfer/upload"} />
                </div>

                <CardTableHeaderComplex 
                    title={`Showing ${tableData.totalItems} records`}
                    searchBar={<SearchInput disabled={fundTransfer.bulkTransferList.length < 1 ? true : false} onSearch={onSearch} placeholder={'Account Number'} />}
                    rightButton={
                        <div className="flex flex-row">
                            <Button.Blue 
                                title={'Make Transfer'} 
                                className="whitespace-nowrap mx-3" 
                                onClick={onSubmitWithPin} 
                                loading={fundTransfer.isLoading} 
                                disabled={fundTransfer.isLoading || !user.hasCompliance} 
                            />
                            <Button.Transparent 
                                title={'Replace CSV File'} 
                                className="whitespace-nowrap mx-3" 
                                onClick={()=>hiddenFileUploadButtonRef.current.click()}  
                                disabled={fundTransfer.isLoading || !user.hasCompliance}
                            />
                            <input ref={hiddenFileUploadButtonRef} type="file" id="file" className="hidden" onChange={onReplaceFile} />
                        </div>
                    }
                    kebabMenuButton={
                        <div>
                          <KebabDropdown className="z-10" disabled={fundTransfer.isLoading || !user.hasCompliance}>
                            {(fundTransfer.isLoading || !user.hasCompliance) ? null :
                            <KebabDropdownItem
                                activeChild={<span onClick={onSubmitWithPin} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Make Transfer</span>}
                                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Make Transfer</span>}
                            />}
                            {(fundTransfer.isLoading || !user.hasCompliance) ? null :
                            <KebabDropdownItem
                                activeChild={<span onClick={()=>hiddenFileUploadButtonRef.current.click()} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Replace CSV File</span>}
                                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Replace CSV File</span>}
                            />}
                          </KebabDropdown>
                        </div>
                    }
                />

                {isEmpty(tableData.data) || fundTransfer.isLoading?
                    <React.Fragment>
                        <div className="hidden md:block">
                            <EmptyTable column={['','Account Number','Account Name','Bank Name','Amount','Description','Status',' ','']} loading={fundTransfer.isLoading} />
                        </div>

                        <div className="block md:hidden">
                            <EmptyDesk row={[1,2,3]} loading={fundTransfer.isLoading} />
                        </div>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <div className="hidden md:block">
                            <CardTableBody column={['','Account Number','Account Name','Bank Name','Amount','Description','Status',' ','']}>
                                {isArray(tableData.data) && renderBulkTransferListTable(tableData.data)}
                            </CardTableBody>
                        </div>

                        <div className="block md:hidden">
                            <div className="bg-white px-6">
                                {isArray(tableData.data) && renderBulkTransferListDesk(tableData.data)}
                            </div>
                        </div>
                    </React.Fragment>
                }

                <CardTableFooter 
                    empty={isEmpty(tableData.data)}
                    loading={fundTransfer.isLoading}
                    pageNumber={currentPage} 
                    totalPages={tableData.totalPages}
                    prevOnClick={()=>setCurrentPage(tableData.previousPage)}
                    nextOnClick={()=>setCurrentPage(tableData.nextPage)}
                    thereIsPreviousPage={tableData.thereIsPreviousPage}
                    thereIsNextPage={tableData.thereIsNextPage}
                />

                <ConfirmPinModal ref={confirmPinModalRef} onSuccess={onSubmit} onFailure={()=>{}} />
            </div>
        </div>
    )
}
