import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  CardTableHeaderComplex,
} from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import usePayroll from "../../hooks/usePayroll";
import {
  getPaydayById,
} from "../../redux/payroll/payrollActions";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {
  simpleDate,
  statusEnum,
  isArray,
  isEmpty,
  pagination,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import SearchInput from "../../components/inputs/SearchInput";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import useTeam from "../../hooks/useTeam";

export default function PaydayDetail() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const payroll = usePayroll();
  const {permissions} = useTeam();
  
  React.useEffect(() => {
    if (location?.state?.payroll_id) {
      dispatch(getPaydayById({ payday_id: location?.state?.payroll_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(
      pagination(payroll.paydayById?.payroll_details ?? [], 10, currentPage)
    );
    // eslint-disable-next-line
  }, [currentPage, payroll.paydayById?.payroll_details]);

  // Search Function And Control
  const onSearch = (search) => {
    const filteredData = payroll?.paydayById?.payroll_details?.filter((item) => {
      return item.staff_name.toLowerCase().includes(search.toLowerCase());
    });
    setTableData(pagination(filteredData ?? [], 10, currentPage));
  };

  const renderTransactionsTable = (data) => {
    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <BasicIndicator
              type={statusEnum[item?.transaction_status]}
              title={item?.transaction_status}
            />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              {item?.staff_id}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              {item?.staff_name}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              {item?.bank_name}
              <br />
              {item?.account_number}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one whitespace-nowrap">
              NGN {Number(item?.amount)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderTransactionsDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.transaction_status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item?.staff_name}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {item?.bank_name}
                  <br />
                  {item?.account_number}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  NGN {Number(item?.amount)?.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Back"} path={!permissions?.includes("payroll") ? "/dashboard/payroll/payday" : "/teams/payroll/payday"} />
        </div>

        <div className="flex flex-col bg-white rounded-sm p-3 my-6 border-2 border-solid border-gray-100">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">Payroll Details</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-y-3 md:gap-3 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Payroll ID
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.payroll_id ? (
                  <CopyTextButton text={location?.state?.payroll_id} />
                ) : null}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Description
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.description}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Date</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.transaction_date
                  ? simpleDate(
                      location?.state?.transaction_date,
                      "numeric",
                      "short"
                    )
                  : null}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Total Staff
              </h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.total_staff}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Net Pay</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.total_amount
                  ? "NGN " +
                    Number(location?.state?.total_amount)?.toLocaleString()
                  : null}
              </h6>
            </div>
          </div>
        </div>

        <div className="border-2 border-solid border-gray-100">
          {/* <CardTableHeaderPlain
                        title={`Transactions - ${tableData.totalItems}`}
                    /> */}

          <CardTableHeaderComplex
            title={`Showing ${tableData.totalItems} records`}
            searchBar={
              <SearchInput
                disabled={payroll.isLoadingPayroll}
                onSearch={onSearch}
                placeholder={"Search Staff"}
              />
            }
          />
          {!payroll.isLoadingPayday ? (
            <React.Fragment>
              <div className="hidden md:block">
                <EmptyTable
                  column={[
                    "",
                    "Status",
                    "Staff ID",
                    "Staff Name",
                    "Bank Account",
                    "Amount",
                    "",
                  ]}
                  loading={payroll.isLoadingPayday}
                />
              </div>

              <div className="block md:hidden">
                <EmptyDesk row={[1, 2, 3]} loading={false} />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="hidden md:block">
                <CardTableBody
                  column={[
                    "",
                    "Status",
                    "Staff ID",
                    "Staff Name",
                    "Bank Account",
                    "Amount",
                    "",
                  ]}
                >
                  {isArray(tableData.data) &&
                    renderTransactionsTable(tableData.data)}
                </CardTableBody>
              </div>

              <div className="block md:hidden">
                <div className="bg-white px-6">
                  {isArray(tableData.data) &&
                    renderTransactionsDesk(tableData.data)}
                </div>
              </div>
            </React.Fragment>
          )}

          <CardTableFooter
            empty={isEmpty(tableData.data)}
            loading={false}
            pageNumber={currentPage}
            totalPages={tableData.totalPages}
            prevOnClick={() => setCurrentPage(tableData.previousPage)}
            nextOnClick={() => setCurrentPage(tableData.nextPage)}
            thereIsPreviousPage={tableData.thereIsPreviousPage}
            thereIsNextPage={tableData.thereIsNextPage}
          />
        </div>
      </div>
    </div>
  );
}
