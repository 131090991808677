import { useContext } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import styled from "styled-components";
import { TransferFormContext } from "../../context/TransferFormContext";
import InfoIcon from "./InfoIcon";

const ChargeInfo = ({ transferCharge, className, info }) => {
  const { currencies, repeatTransaction } = useContext(TransferFormContext);

  return (
    <SFormInfo>
        {repeatTransaction ? null : (
          <>
    <IoMdInformationCircle className={className ? className : 'glade-grey-dark'} size={20} />
      <span className={className}>
        {info ?? `Note! You will be charged ${currencies?.activeCurrency?.currency} ${(Number(transferCharge).toLocaleString("en-US") || 0)} for this transaction`}
      </span>
      </>
       )}
    </SFormInfo>
  );
};

export default ChargeInfo;

const SFormInfo = styled.div`
  color: #9CA3AF;
  width: 100%;
  margin-top: 2rem;
  font-size: .9rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;