import React from 'react';
import { useDispatch } from 'react-redux';
import { CardPlainWithButton, CardPlainWithButtonSection } from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { getBanks, resolveAccountDetails } from '../../redux/fund-transfer/fundTransferActions';
import { createSplitAccount } from '../../redux/transaction-split/transactionSplitActions';
import useFundTransfer from '../../hooks/useFundTransfer';
import useTransactionSplit from '../../hooks/useTransactionSplit';
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import SelectInput from '../../components/inputs/SelectInput';
import useTeam from '../../hooks/useTeam';

export default function NewSplitAccount() {
  const dispatch = useDispatch();
  const fundTransfer = useFundTransfer();
  const transactionSplit = useTransactionSplit();
  const {permissions} = useTeam();
  
  const [input, setInput] = React.useState({
    split_account_name: '',
    bank_code: '',
    bank_name: '',
    account_number: '',
    account_name: '',
    split_name: '',
    split_type: '',
    account_share: '',
  });

  React.useEffect(() => {
    dispatch(getBanks({ mode: 'internal' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (input.account_number.length === 10 && input.bank_code) {
      dispatch(
        resolveAccountDetails(
          { account_number: input.account_number, bank_code: input.bank_code, mode: 'external_local' },
          (resolveAccountDetails) => setInput({ ...input, account_name: resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id })
        )
      );
    } else {
      setInput({ ...input, account_name: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.account_number, input.bank_code]);

  const onSubmit = () => {
    dispatch(createSplitAccount({
      split_name: input.split_account_name,
      split_type: input.split_type,
      account_number: input.account_number,
      share: input.account_share,
      name: input.account_name,
      bank_code: input.bank_code
    }));
    setInput({
      split_account_name: '',
      bank_code: '',
      bank_name: '',
      account_name: '',
      account_number: '',
      account_share: '',
      split_name: '',
      split_type: '',
    })
  }

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  const onSelectOfBank = (e) => {
    let data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data'));
    setInput({ ...input, bank_code: data.bank_code, bank_name: data.bank_name });
  }

  const onSelectOfSplit = (e) => {
    let data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data'));
    setInput({ ...input, split_type: data.split_type, split_name: data.split_name });
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">

        <div className="mb-6">
          <BreadCrumbs title={'Transaction Split'} path={!permissions?.includes("transaction split") ? '/dashboard/transaction-split' : '/teams/transaction-split'} />
        </div>

        <CardPlainWithButton>
          <CardPlainWithButtonSection
            title={'New Split Account'}
            loading={transactionSplit.isLoading}
            buttonTitle={'Create Account'}
            buttonOnClick={() => onSubmit()}
            buttonDisabled={transactionSplit.isLoading}>
            <div className="mt-6 px-14">
              <div className="space-y-6">

                <TextInput
                  label={'Split Account Name'}
                  id={'split_account_name'}
                  name={'split_account_name'}
                  type={'text'}
                  value={input.split_account_name}
                  onChange={onChange}
                  placeholder={'Enter account name'}
                  autoComplete={'split_account_name'}
                  className={'appearance-none'}
                />

                <SelectInput
                  label={'Select Bank'}
                  id={'bank_code'}
                  name={'bank_code'}
                  value={input.bank_code ?? ''}
                  onChange={onSelectOfBank}
                  autoComplete={'bank_code'}
                  className={'appearance-none'}>
                  <option value={''} disabled={true}>Select A Bank</option>
                  {fundTransfer.banks && fundTransfer.banks.map((item, key) => {
                    return (
                      <option key={key} value={item.bank_code} data={JSON.stringify(item)}>{item.bank_name}</option>
                    );
                  })}
                </SelectInput>

                <NumericInput
                  label={'Account Number'}
                  id={'account_number'}
                  name={'account_number'}
                  type={'number'}
                  value={input.account_number}
                  onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value.substring(0, 10) })}
                  placeholder={'Enter account number'}
                  autoComplete={'account_number'}
                  className={'appearance-none'}
                />

                <TextInput
                  label={'Account Name'}
                  id={'account_name'}
                  name={'account_name'}
                  disabled={true}
                  type={'text'}
                  value={input.account_name}
                  onChange={onChange}
                  placeholder={'Enter account name'}
                  autoComplete={'account_name'}
                  className={'appearance-none'}
                />

                <SelectInput
                  label={'Split Type'}
                  id={'split_type'}
                  name={'split_type'}
                  value={input.split_type}
                  onChange={onSelectOfSplit}
                  autoComplete={'split_type'}
                  className={'appearance-none'}>
                  <option value={''} disabled={true}>Select Split Type</option>
                  {[{ split_name: 'Percentage', split_type: 'percentage' }, { split_name: 'Fixed', split_type: 'fixed' }].map((item, key) => {
                    return (
                      <option key={key} value={item.split_type} data={JSON.stringify(item)}>{item.split_name}</option>
                    );
                  })}
                </SelectInput>

                <NumericInput
                  label={`${input.split_type === 'percentage' ? 'Split Share (%)' : 'Split Share (₦)'}`}
                  id={'account_share'}
                  name={'account_share'}
                  type={'number'}
                  max={'100'}
                  value={input.account_share}
                  onChange={onChange}
                  placeholder={'Enter split share'}
                  autoComplete={'account_share'}
                  className={'appearance-none'}
                />

              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  )
}
