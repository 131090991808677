import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/buttons/Button";
import useSettings from "../../../hooks/useSettings";
import { useHistory } from "react-router-dom";
import FileInput from "../../../components/inputs/FileInput";
import TextInput from "../../../components/inputs/TextInput";
import TextAreaInput from "../../../components/inputs/TextAreaInput";
import { isArray } from "../../../helpers/helper";
import { businessDocumentNonNigeriaValidation } from "../../../validation/settings/settingsValidation";
import {
  submitBusinessDocumentsNonNigeria
} from "../../../redux/settings/settingsActions";
import { getPersonalAndBusinessAccounts } from "../../../redux/auth/authActions";
import SelectInput from "../../../components/inputs/SelectInput";


export const BusinessDocumentNonNigeria = ({ setFormLevel, input, setInput }) => {
  const dispatch = useDispatch();
  const settings = useSettings();
  const [inputErrors, setInputErrors] = React.useState({
    business_proof_of_address: '',
    business_proof_of_address_error: false,
    certificate_of_incorporation: '',
    certificate_of_incorporation_error: false,
    // other_supporting_documents: '',
    // other_supporting_documents_error: false,
    business_description: '',
    business_description_error: false,
    hear_about_us: '',
    hear_about_us_error: false,
    have_political_foreign_office: '',
    have_political_foreign_office_error: false,
    reg_number: '',
    reg_number_error: false
  });
  const history = useHistory();

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputErrors({ ...inputErrors, [`${e.target.name}_error`]: false });
  };

  const setErrorField = (field) => {
    if (field.toLowerCase().includes('business proof of address')) {
      inputErrors.business_proof_of_address_error = true;
      inputErrors.business_proof_of_address = field;
    } if (field.toLowerCase().includes('certificate of incorporation')) {
      inputErrors.certificate_of_incorporation_error = true;
      inputErrors.certificate_of_incorporation = field;
    } if (field.toLowerCase().includes('business description')) {
      inputErrors.business_description_error = true;
      inputErrors.business_description = field;
    } if (field.toLowerCase().includes('hear about us')) {
      inputErrors.hear_about_us_error = true;
      inputErrors.hear_about_us = field;
    } if (field.toLowerCase().includes('registration number')) {
      inputErrors.reg_number_error = true;
      inputErrors.reg_number = field;
    } 
    setInputErrors({...inputErrors});
  } 
  
  const onSubmit = () => {
    let inputValid = businessDocumentNonNigeriaValidation(input);
    if (isArray(inputValid)) {
      inputValid.map((field) =>
        setErrorField(field)
      );
    } else {
      // Handle Submission
      // Payload 
      let reqPayLoad;
      // compliance id
      let id = isArray(settings.submitBusinessInformation) ?
        settings.submitBusinessInformation[0]['id']
        : settings.submitBusinessInformation.data?.compliance_id;

      reqPayLoad = {
        proof_of_address: input?.business_proof_of_address[0],
        certificate_of_incorporation: input?.certificate_of_incorporation[0],
        other_document: input?.other_supporting_documents[0],
        business_description: input?.business_description,
        have_political_foreign_office: input?.have_political_foreign_office === "Yes" ? true : false,
        source: input?.hear_about_us === "Others" ? input?.hear_about_us_others : input?.hear_about_us,
        rc_number: input?.reg_number
      }

      dispatch(
        submitBusinessDocumentsNonNigeria({ reqPayLoad, id }, () => { dispatch(getPersonalAndBusinessAccounts()); history.push('/dashboard/home'); })
      )

    }
  };
  return (
    <>
      <div className="px-12 mb-8">
        <h3 className="font-bold text-glade-black-800">
          Upload Documents
        </h3>
        <span className="glade-grey-cool">Please upload all documents</span>
      </div>

      <div className="w-full space-y-6 border-b border-gray-300 px-12 pb-8">
        <div>
          <FileInput
            label={"Proof of Address"}
            id={"business_proof_of_address"}
            name={"business_proof_of_address"}
            accept={".png, .jpeg, .pdf, .jpg"}
            maximumSize={3}
            returnInput={(value) => {
              setInputErrors({ ...inputErrors, business_proof_of_address_error: false});
              setInput({ ...input, business_proof_of_address: value })
              }
            }
            errorState={inputErrors?.business_proof_of_address_error}
          />
          {inputErrors.business_proof_of_address_error && (
            <div className="text-glade-red-400 text-sm mt-2 flex flex-col">
              <span>{inputErrors.business_proof_of_address}</span>
              <span>Upload a valid format (JPG, PNG, PDF)</span>
            </div>
          )}
          <span className="glade-small-text-one glade-grey-cool block">
            Proof of address can be recognized documents that verify your current address. Such as recent Utility bills, Bank statements or Cable bills not more than 3 months old.
          </span>
          <span className="glade-small-text-one glade-grey-cool block mt-6">Supported file types: PDF, PNG &  JPEG. Max file size allowed is 3MB.Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.</span>
        </div>

        <div>
          <TextAreaInput
            label={"Business Description"}
            id={"business_description"}
            name={"business_description"}
            row={5}
            value={input?.business_description ?? ""}
            onChange={onChange}
            placeholder={"Tell us about your business"}
            autoComplete={"business_description"}
            className={"appearance-none py-15"}
            errorState={inputErrors?.business_description_error}
          />
          {inputErrors.business_description_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors.business_description}
            </div>
          )}
        </div>

        <div>
          <TextInput
            label={"Registration Number"}
            id={"reg_number"}
            name={"reg_number"}
            value={input?.reg_number ?? ""}
            onChange={onChange}
            placeholder={"RC3499504"}
            autoComplete={"reg_number"}
            className={"appearance-none"}
            errorState={inputErrors?.reg_number_error}
          />
          {inputErrors?.reg_number_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              {inputErrors.reg_number}
            </div>
          )}
        </div>

        <div>
          <FileInput
            label={"Certificate of Incorporation"}
            id={"certificate_of_incorporation"}
            name={"certificate_of_incorporation"}
            multiple={true}
            accept=".png, .jpeg, .pdf, .jpg"
            maximumSize={3}
            returnInput={(value) => {
              setInputErrors({ ...inputErrors, certificate_of_incorporation_error: false });
              setInput({ ...input, certificate_of_incorporation: value })
              }
            }
            errorState={inputErrors?.certificate_of_incorporation_error}
          />
          {inputErrors?.certificate_of_incorporation_error && (
            <div className="text-glade-red-400 text-sm mt-2 flex flex-col">
            <span>{inputErrors.certificate_of_incorporation}</span>
              <span>Upload a valid format (JPG, PNG, PDF)</span>
            </div>
          )}
          <span className="glade-small-text-one glade-grey-cool block">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is
            3MB.
          </span>
        </div>


        <div>
          <FileInput
            label={"Other Supporting Documents"}
            id={"other_supporting_documents"}
            name={"other_supporting_documents"}
            multiple={true}
            accept=".png, .jpeg, .pdf, .jpg"
            maximumSize={3}
            returnInput={(value) => {
              // setInputErrors({ ...inputErrors, other_supporting_documents_error: false});
              setInput({ ...input, other_supporting_documents: value })
              }
            }
            // errorState={inputErrors?.other_supporting_documents}
          />
          {/* {inputErrors?.other_supporting_documents && (
            <div className="text-glade-red-400 text-sm mt-2">
              Upload a valid format (JPG, PNG, PDF)
            </div>
          )} */}
          <span className="glade-small-text-one glade-grey-cool block">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.
          </span>
        </div>

        <div>
          <label
            htmlFor={"have_political_foreign_office"}
            className="block glade-small-text-two glade-black"
          >
            {"Have you or any persons associated with you ever held a political office in a foreign country?"}
          </label>
          <div className="flex items-center mt-3 space-x-9">
            <div className="space-x-3 flex items-center">
              <input
                type={'radio'}
                id="Yes"
                name={"have_political_foreign_office"}
                className="rounded-full glade-button-outline-blue"
                value="Yes"
                checked={input.have_political_foreign_office === "Yes"}
                onChange={onChange}
              />
              <span>Yes</span>
            </div>
            <div className="space-x-3 flex items-center">
              <input 
                className="rounded-full glade-button-outline-blue"
                type="radio"
                name="have_political_foreign_office"
                value="No"
                id="No"
                checked={input.have_political_foreign_office === "No"}
                onChange={onChange}
              />
              <span>No</span>
            </div>
          </div>
        </div>

        <div>
          <SelectInput
            label={"How did you hear about us?"}
            id={"hear_about_us"}
            name={"hear_about_us"}
            value={input.hear_about_us ?? ""}
            onChange={onChange}
            autoComplete={"hear_about_us"}
            className={"appearance-none"}
            errorState={inputErrors.hear_about_us_error}
          >
            <option value={""} disabled={true}>
              Select
            </option>
            {['Facebook', 'Twitter', 'Instagram', 'Google', 'Friend', 'Others'].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))
            }
          </SelectInput>
          {inputErrors.hear_about_us_error && (
            <div className="text-glade-red-400 text-sm mt-2">
              Please select how you heard about us
            </div>
          )}

          {input.hear_about_us === "Others" &&
            <>
              <TextInput
                label={""}
                id={"hear_about_us_others"}
                name={"hear_about_us_others"}
                value={input?.hear_about_us_others ?? ""}
                onChange={onChange}
                placeholder={"Please Specify"}
                autoComplete={"hear_about_us_others"}
                className={"appearance-none"}
                errorState={inputErrors?.hear_about_us_others}
              />
              {inputErrors.hear_about_us_error && (
                <div className="text-glade-red-400 text-sm mt-2">
                  Please enter how you heard about us
                </div>
              )}
            </>
          }
        </div>


      </div>
      <div className="p-6">
        <div className="w-1/3 md:w-1/4 float-left ">
          <Button.Base
            onClick={() => setFormLevel("business information")}
            className="w-full glade-button-cancel"
            title={"Back"}
          />
        </div>
        <div className="w-1/3 md:w-1/4 float-right">
          <Button.Blue
            onClick={onSubmit}
            className="w-full"
            title={"Submit"}
            disabled={settings.isLoadingCompliance}
            loading={settings.isLoadingCompliance}
          />
        </div>
      </div>
    </>
  );
};
