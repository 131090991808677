import {
    LOADING_EXCHANGE_RATES,
    RELOAD_EXCHANGE_RATES,
    GET_GLADE_EXCHANGE_RATES
} from "./types";

const initialState = {
    isLoading:false,
    shouldReload:false,
    gladeExchangeRates:[],
};

export default function exchangeRateReducer(state = initialState, action) {
    switch (action.type) {

        case LOADING_EXCHANGE_RATES:
            return {
                ...state,
                isLoading: action.payLoad,
            };

        case RELOAD_EXCHANGE_RATES:
            return {
                ...state,
                shouldReload: !state.shouldReload,
            };

        case GET_GLADE_EXCHANGE_RATES:
            return {
                ...state,
                gladeExchangeRates: action.payLoad.data.data,
            };

        default:
            return state;
    }
}