import React, { useState, useEffect } from "react";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import { isArray } from "../../helpers/helper";
import { useDispatch } from "react-redux";
import useSettings from "../../hooks/useSettings";
import {
  getCountries,
  getComplianceLga,
  getComplianceStates,
  getComplianceStatesNew,
} from "../../redux/settings/settingsActions";
import NumericInput from "../../components/inputs/NumericInput";
import FileInput from "../../components/inputs/FileInput";
import Button from "../../components/buttons/Button";
import { addProprietorInformationValidation } from "../../validation/settings/settingsValidation";

export const AddProprietorInformation = ({
  formFields,
  proprietorList,
  setProprietorList,
  directorList,
  setDirectorList,
  partnerList,
  setPartnerList,
  trusteeList,
  setTrusteeList,
  setFormLevel,
  businessType,
}) => {
  const settings = useSettings();
  const dispatch = useDispatch();
  const [stateInput, setStateInput] = useState({
    full_name: "",
    email: "",
    phone: "",
    international_passport: "",
    passport_photograph: "",
    e_signature: "",
    government_id: "",

  });
  const [inputError, setInputError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [countrySelected, setCountrySelected] = useState({});
  const [stateSelected, setStateSelected] = useState({});

  const onChange = (e) => {
    setStateInput({ ...stateInput, [e.target.name]: e.target.value });
    setInputError({ ...inputError, [e.target.name]: false });
  };
  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateInput?.country?.length > 0) {
      let countryObj = settings?.complianceCountriesNew?.find(
        (country) => country.country_name === stateInput.country
      );
      setCountrySelected(countryObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInput?.country]);

  useEffect(() => {
    if (stateInput?.country?.length > 0) {
      if (countrySelected?.country_code?.toLowerCase() === "nga") {
        dispatch(getComplianceStates({ country_id: countrySelected?.id }));
      } else if (countrySelected?.country_code?.toLowerCase() !== "nga") {
        dispatch(getComplianceStatesNew({ country_id: countrySelected?.id }));
      } else return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected]);

  useEffect(() => {
    if (stateInput?.state?.length) {
      const stateObj = settings?.complianceStates?.find(
        (state) => state.state_name === stateInput.state
      );
      setStateSelected(stateObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInput?.state]);

  useEffect(() => {
    if (stateInput?.state?.length) {
      countrySelected?.country_code?.toLowerCase() === "nga" &&
        stateSelected &&
        dispatch(getComplianceLga({ state_id: stateSelected.state_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected]);
  const handleSubmit = () => {
    // Validation

    let isInputValid = addProprietorInformationValidation(
      formFields.type,
      stateInput,
      countrySelected
    );
    if (isArray(isInputValid)) {
      isInputValid.forEach((field) => {
        setInputError((prev) => ({ ...prev, [field]: true }));
      });
    } else {
      setDisabled(true);
      if (formFields.type === "director") {
        setDirectorList([...directorList, stateInput]);
      } else if (formFields.type === "partner") {
        setPartnerList([...partnerList, stateInput]);
      } else if (formFields.type === "proprietor") {
        setProprietorList([...proprietorList, stateInput]);
      } else if (formFields.type === "trustee") {
        setTrusteeList([...trusteeList, stateInput]);
      }
      setFormLevel("proprietor information");
      setDisabled(false);
    }
  };
  const generateArrayOfYears = () => {
    var max = new Date().getFullYear();
    var years = [];

    for (var i = max; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };
  return (
    <div className="space-y-6">
      <div className="flex flex-col">
        <h3 className="font-bold">
          {businessType === "NGO" && "Trustee "}
          {businessType === "LLP" && "Partner "}
          {businessType === "LLC" && "Director "}
          {businessType === "SP" && "Proprietor "}
          Information
        </h3>
        <p className="text-glade-gray-400">
          Please provide all necessary information
        </p>
      </div>
      <div className="">
        <TextInput
          label={"Full Name"}
          id={"full_name"}
          name={"full_name"}
          value={stateInput?.full_name ?? ""}
          onChange={onChange}
          placeholder={"John Doe"}
          autoComplete={"full_name"}
          className={"appearance-none"}
          errorState={inputError.full_name}
        />
        {inputError.full_name && (
          <div className="text-glade-red-400 text-sm mt-2">
            {"Full name is required"}
          </div>
        )}
      </div>
      <div className="">
        <TextInput
          label={"Residential Address"}
          id={"residential_address"}
          name={"residential_address"}
          value={stateInput?.residential_address ?? ""}
          onChange={onChange}
          placeholder={"Enter Residential Address"}
          autoComplete={"residential_address"}
          className={"appearance-none"}
          errorState={inputError.residential_address}
        />
        {inputError.residential_address && (
          <div className="text-glade-red-400 text-sm mt-2">
            {"Residential address is required"}
          </div>
        )}
      </div>
      <div>
        <SelectInput
          label={"Country"}
          id={"country"}
          name={"country"}
          value={stateInput?.country ?? ""}
          onChange={onChange}
          autoComplete={"country"}
          className={"appearance-none"}
          errorState={inputError.country}
        >
          <option value={""} disabled={true}>
            Select Country
          </option>
          {isArray(settings.complianceCountriesNew) &&
            settings.complianceCountriesNew?.map((country, index) => (
              <option key={index} value={country.country_name}>
                {country.country_name}
              </option>
            ))}
        </SelectInput>
        {inputError.country && (
          <div className="text-glade-red-400 text-sm mt-2">
            Country is a required field
          </div>
        )}
      </div>

      <div>
        <SelectInput
          label={"State"}
          id={"state"}
          name={"state"}
          value={stateInput?.state ?? ""}
          onChange={onChange}
          autoComplete={"state"}
          className={"appearance-none"}
          errorState={inputError.state}
        >
          <option value={""} disabled={true}>
            Select A State
          </option>
          {countrySelected?.country_code?.toLowerCase() === "nga"
            ? isArray(settings.complianceStates) &&
            settings.complianceStates?.map((state, index) => (
              <option key={index} value={state.state_name}>
                {state.state_name}
              </option>
            ))
            : isArray(settings.complianceStatesNew?.states) &&
            settings.complianceStatesNew?.states?.map((state, index) => (
              <option key={index} value={state.state_name}>
                {state.state_name}
              </option>
            ))}
        </SelectInput>
        {inputError.state && (
          <div className="text-glade-red-400 text-sm mt-2">
            State is a required field
          </div>
        )}
      </div>
      {countrySelected?.country_code?.toLowerCase() === "nga" && (
        <div>
          <SelectInput
            label={"LGA"}
            id={"lga"}
            name={"lga"}
            value={stateInput?.lga ?? ""}
            onChange={onChange}
            autoComplete={"business_lga"}
            className={"appearance-none"}
            errorState={inputError.lga}
          >
            <option value={""} disabled={true}>
              Select A Local Government Area
            </option>
            {isArray(settings.complianceLga) &&
              settings.complianceLga?.map((lga, index) => (
                <option key={index} value={lga.city_name}>
                  {lga.city_name}
                </option>
              ))}
          </SelectInput>
          {inputError.lga && (
            <div className="text-glade-red-400 text-sm mt-2">
              LGA is a required field
            </div>
          )}
        </div>
      )}
      <div>
        <TextInput
          label={"City"}
          id={"city"}
          name={"city"}
          value={stateInput?.city ?? ""}
          onChange={onChange}
          placeholder={"Enter City"}
          autoComplete={"city"}
          className={"appearance-none"}
          errorState={inputError.city}
        />
        {inputError.city && (
          <div className="text-glade-red-400 text-sm mt-2">
            {"Enter a valid City"}
          </div>
        )}
      </div>
      <div>
        <TextInput
          label={"Postal"}
          id={"postal"}
          name={"postal"}
          value={stateInput?.postal ?? ""}
          onChange={onChange}
          placeholder={"Enter Postal Code"}
          autoComplete={"postal"}
          className={"appearance-none"}
          errorState={inputError.postal}
        />
        {inputError.postal && (
          <div className="text-glade-red-400 text-sm mt-2">
            {"Enter a valid Postal"}
          </div>
        )}
      </div>
      <div className="space-y-2">
        <div className={"flex space-x-3 items-center"}>
          <div className="w-1/3">
            <SelectInput
              label={"Date of Birth"}
              id={"day"}
              name={"day"}
              value={stateInput.day ?? ""}
              onChange={onChange}
              autoComplete={"day"}
              className={`appearance-none rounded-md ${inputError.day ? "border-2 border-red-500" : ""
                }`}
            >
              <option value={""} disabled={true}>
                Day
              </option>
              {[
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
              ].map((day, index) => {
                return (
                  <option key={index} value={day}>
                    {day}
                  </option>
                );
              })}
            </SelectInput>
            {inputError.day && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Enter day"}
              </div>
            )}
          </div>
          <div className="w-1/3 mt-3">
            <SelectInput
              label={""}
              id={"month"}
              name={"month"}
              value={stateInput.month ?? ""}
              onChange={onChange}
              autoComplete={"month"}
              className={`appearance-none rounded-md ${inputError.month ? "border-2 border-red-500" : ""
                }`}
            >
              <option value={""} disabled={true}>
                Month
              </option>
              {[
                { month: "January", id: "01" },
                { month: "February", id: "02" },
                { month: "March", id: "03" },
                { month: "April", id: "04" },
                { month: "May", id: "05" },
                { month: "June", id: "06" },
                { month: "July", id: "07" },
                { month: "August", id: "08" },
                { month: "September", id: "09" },
                { month: "October", id: "10" },
                { month: "November", id: "11" },
                { month: "December", id: "12" },
              ].map((month, index) => {
                return (
                  <option key={index} value={month?.id}>
                    {month?.month}
                  </option>
                );
              })}
            </SelectInput>
            {inputError.month && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Enter month"}
              </div>
            )}
          </div>
          <div className="w-1/3 mt-3">
            <SelectInput
              label={""}
              id={"year"}
              name={"year"}
              value={stateInput.year ?? ""}
              onChange={onChange}
              autoComplete={"year"}
              className={`appearance-none rounded-md ${inputError.year ? "border-2 border-red-500" : ""
                }`}
            >
              <option value={""} disabled={true}>
                Year
              </option>
              {generateArrayOfYears()?.map((year, index) => {
                return (
                  <option key={index} value={year}>
                    {year}
                  </option>
                );
              })}
            </SelectInput>
            {inputError.year && (
              <div className="text-glade-red-400 text-sm mt-2">
                {"Enter year"}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <NumericInput
          leftSection={
            <span className="glade-button-gray glade-home-dark self-center border border-gray-300 rounded-sm p-2">
              +234
            </span>
          }
          label={"Phone Number"}
          id={"phone"}
          name={"phone"}
          value={stateInput?.phone ?? ""}
          onChange={onChange}
          placeholder={"0800000000"}
          autoComplete={"phone"}
          className={"appearance-none"}
          errorState={inputError.phone}
        />
        {inputError.phone && (
          <div className="text-glade-red-400 text-sm mt-2">
            {"Enter a valid phone number"}
          </div>
        )}
      </div>
      <div>
        <TextInput
          label={"Email"}
          id={"email"}
          name={"email"}
          value={stateInput?.email ?? ""}
          onChange={onChange}
          placeholder={"Enter Email"}
          autoComplete={"email"}
          className={"appearance-none"}
          errorState={inputError.email}
        />
        {inputError.email && (
          <div className="text-glade-red-400 text-sm mt-2">
            {"Enter a valid Email Address"}
          </div>
        )}
      </div>

      {formFields?.data?.map((field, index) => (
        <div key={index}>
          {field.type === "select" && (
            <div>
              <SelectInput
                label={field.label}
                id={field.name}
                name={field.name}
                value={stateInput[`${field.name}`] ?? ""}
                onChange={onChange}
                autoComplete={field.name}
                className={"appearance-none"}
                errorState={inputError[`${field.name}`]}
              >
                <option value={""} disabled={true}>
                  Select Nationality
                </option>
                {isArray(settings.complianceCountriesNew) &&
                  settings.complianceCountriesNew?.map((country, index) => (
                    <option key={index} value={country.country_name}>
                      {country.country_name}
                    </option>
                  ))}
              </SelectInput>
              {inputError[`${field.name}`] && (
                <div className="text-glade-red-400 text-sm mt-2">
                  {field.errorText}
                </div>
              )}
            </div>
          )}
          {field.type === "text" && (
            <div>
              <TextInput
                label={field.label}
                id={field.name}
                name={field.name}
                value={stateInput[field.name] ?? ""}
                onChange={onChange}
                placeholder={field.placeholder}
                autoComplete={field.name}
                className={"appearance-none"}
                errorState={inputError[`${field.name}`]}
              />
              {inputError[`${field.name}`] && (
                <div className="text-glade-red-400 text-sm mt-2">
                  {field.errorText}
                </div>
              )}
            </div>
          )}
          {field.type === "number" && (
            <div>
              <NumericInput
                label={field.label}
                id={field.name}
                name={field.name}
                value={stateInput[field.name] ?? ""}
                onChange={onChange}
                placeholder={field.placeholder}
                autoComplete={field.name}
                className={"appearance-none"}
                errorState={inputError[`${field.name}`]}
              />
              {inputError[`${field.name}`] && (
                <div className="text-glade-red-400 text-sm mt-2">
                  {field.errorText}
                </div>
              )}
            </div>
          )}
          {field.type === "file" && (
            <div>
              <FileInput
                label={field.label}
                id={field.name}
                name={field.name}
                multiple={true}
                accept=".png, .jpeg, .pdf, .jpg"
                maximumSize={3}
                returnInput={(value) =>
                  setInputError({ ...inputError, [field.name]: false }) &
                  setStateInput({ ...stateInput, [field.name]: value })
                }
                errorState={inputError[`${field.name}`]}
              />
              {inputError[field.name] ? (
                <div className="text-glade-red-400 text-sm mt-2">
                  Upload a valid format (JPG, PNG, PDF)
                </div>
              ) : (
                <div className="text-glade text-sm mt-2">
                  {field.supportText}
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      <Button.Blue
        onClick={() => handleSubmit()}
        className="w-full"
        title={`Add ${businessType === "LLC" ? "Director" : ""} ${businessType === "SP" ? "Proprietor" : ""
          } ${businessType === "LLP" ? "Partner" : ""} ${businessType === "NGO" ? "Trustee" : ""
          }`}
        disabled={disabled}
        loading={disabled}
      />
    </div>
  );
};
