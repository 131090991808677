import React, { useContext } from 'react';
import { useDispatch } from "react-redux";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { CardTableHeaderPlain } from '../../components/cards/CardTableHeader';
import Button from '../../components/buttons/Button';
import ChangeUserPasswordModal from './ChangeUserPasswordModal';
import ChangeUserPinModal from './ChangeUserPinModal';
import CreateUserPinModal from './CreateUserPinModal';
import ResetUserPinModal from './ResetUserPinModal';
import ReferralModal from './ReferralModal';
import useSettings from '../../hooks/useSettings';
import useUser from '../../hooks/useUser';
import { getUserDetails, getBusinessDetails, updatePersonalProfile, updateBusinessProfile, getReferralDetails, getReferralRewardsByYear, getReferralYears } from '../../redux/settings/settingsActions';
import TextInput from '../../components/inputs/TextInput';
// import ToggleSwitch from '../../components/toggles/ToggleSwitch';
// import TwoFactorAuthModal from '../../components/modals/two-factor/TwoFactorAuthModal';
// import ConfirmPassword from '../../components/modals/two-factor/ConfirmPassword';
import { useState } from 'react';
// import CheckboxInput from '../../components/inputs/CheckboxInput';
import { TwoFactorContext } from '../../context/TwoFactorModalContext'
import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal';
import { useRef } from 'react';
import { useEffect } from 'react';

export default function SettingsProfile() {
  const ChangeUserPasswordModalRef = React.useRef();
  const ChangeUserPinModalRef = React.useRef();
  const CreateUserPinModalRef = React.useRef();
  const ResetUserPinModalRef = React.useRef();

  const dispatch = useDispatch();
  const { setIsOpen, isOpen } = useContext(TwoFactorContext)
  const settings = useSettings();
  const user = useUser();
  const [input, setInput] = React.useState({
    first_name: '',
    last_name: '',
    user_tag: '',
    email: '',
    phone_number: '',
    business_name: '',
    business_email: '',
    business_phone_number: '',
    business_address: '',
    tfa: false,
    tfa_status: 'active',
  });


  const currentYear = new Date().getFullYear();
  const [showRef, setShowRef] = useState(false);
  const [referral, setReferral] = useState({
    referral_code: '',
    referral_link: '',
    sign_ups: '',
    total_rewards: '',
    referral_dialog: false,
    year: { year: currentYear }
  });


  const [referralRewardsBreakdown, setReferralRewardsBreakdown] = useState([])
  const [referralYears, setReferralYears] = useState([])
  const TwoFactorAuthenticationModalRef = useRef();


  React.useEffect(() => {
    !user.isBusiness && dispatch(getUserDetails());
    !user.isBusiness && dispatch(getReferralDetails());
    !user.isBusiness && dispatch(getReferralRewardsByYear(referral.year));
    !user.isBusiness && dispatch(getReferralYears());
    user.isBusiness && dispatch(getBusinessDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.shouldReload]);

  React.useEffect(() => {
    settings.userDetails && setInput({
      ...input,
      first_name: settings.userDetails?.user?.first_name ?? '',
      last_name: settings.userDetails?.user?.last_name ?? '',
      user_tag: settings.userDetails?.user?.user_tag ?? '',
      email: settings.userDetails?.user?.email ?? '',
      phone_number: settings.userDetails?.user?.phone_number ?? '',
      tfa: settings.userDetails?.has_passcode ?? '',
      tfa_status: settings.userDetails?.has_passcode ? 'active' : 'inactive',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.userDetails]);

  React.useEffect(() => {
    settings.businessDetails && setInput({
      ...input,
      business_name: settings.businessDetails?.business?.business_name ?? '',
      business_email: settings.businessDetails?.business?.business_email ?? '',
      business_phone_number: settings.businessDetails?.business?.contact_phone ?? '',
      business_address: settings.businessDetails?.business?.business_address ?? '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.businessDetails]);

  React.useEffect(() => {
    settings?.referralDetails?.length > 0 && settings?.referralDetails[0] && setReferral({
      ...referral,
      referral_code: settings.referralDetails[0]?.referralCode ?? '',
      referral_link: `https://dashboard.glade.ng/auth/register?ref=${settings.referralDetails[0]?.referralCode}` ?? '',
      sign_ups: settings.referralDetails[0]?.signups ?? '',
      total_rewards: settings.referralDetails[0]?.totalRewards ?? '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.referralDetails]);

  React.useEffect(() => {
    settings.referralRewardsBreakdown && setReferralRewardsBreakdown(settings.referralRewardsBreakdown);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.referralRewardsBreakdown]);

  React.useEffect(() => {
    settings.referralYears && setReferralYears(settings.referralYears);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.referralYears]);

  const updatePersonalInformation = () => {
    dispatch(updatePersonalProfile({
      first_name: input.first_name,
      last_name: input.last_name,
      user_tag: input.user_tag,
      email: input.email,
      phone_number: input.phone_number,
    }));
  };

  // const updateBusinessInformation = () => {
  //   dispatch(updateBusinessProfile({
  //     business_name: input.business_name,
  //     business_email: input.business_email,
  //     contact_phone: input.business_phone_number,
  //     business_address: input.business_address,
  //   }));
  // };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const openTwoFactorModal = () => {
    setIsOpen(open => !open)
  }
  useEffect(() => {
    settings.referralRewardsBreakdown && setReferralRewardsBreakdown(settings.referralRewardsBreakdown);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.referralRewardsBreakdown]);

  useEffect(() => {
    settings.referralYears && setReferralYears(settings.referralYears);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.referralYears]);

  return (
    <React.Fragment>
      {!user.isBusiness &&
        <div className="px-3 py-0">
          <div className="mb-9 md:hidden">
            <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
          </div>

          <div>
            <CardTableHeaderPlain
              title={'Personal Information'}
              rightButton={
                <div className='flex flex-row space-x-3'>
                  {/* <Button.Transparent className="glade-button-gray" onClick={() => {
                    // ReferralModalRef.current.open();
                    setShowRef(true);
                  }} title={'Show Referral Details'} /> */}
                  <Button.Blue onClick={updatePersonalInformation} title={'Save Changes'} />
                </div>
              }
            />
            {
              showRef &&
              <ReferralModal
                referralRewardsBreakdown={referralRewardsBreakdown}
                setShowRef={() => setShowRef(false)}
                showRef={showRef}
                referral={referral}
                referralYears={referralYears}
              />
            }
          </div>


          <div className="relative flex flex-col min-w-0 w-full bg-white border-t-2 border-gray-100">
            <div className="p-9">

              <div className="w-full md:w-5/12 space-y-6">
                <div className="space-y-1">
                  <label htmlFor="first_name" className="block glade-small-text-two glade-black">Full Name</label>
                  <div className="flex flex-row">
                    <div className="w-full mr-3">
                      <TextInput
                        label={''}
                        id={"first_name"}
                        name={'first_name'}
                        value={input.first_name ?? ''}
                        onChange={onChange}
                        placeholder={'Enter First Name'}
                        autoComplete={"first_name"}
                        className={"appearance-none"}
                        disabled={true}
                      />
                    </div>
                    <div className="w-full">
                      <TextInput
                        label={''}
                        id={"last_name"}
                        name={'last_name'}
                        value={input.last_name ?? ''}
                        onChange={onChange}
                        placeholder={'Enter Last Name'}
                        autoComplete={"last_name"}
                        className={"appearance-none"}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <TextInput
                  label={'User Tag'}
                  id={"user_tag"}
                  name={'user_tag'}
                  type={'text'}
                  disabled={true}
                  value={input?.user_tag ?? ''}
                  onChange={onChange}
                  placeholder={'User Tag'}
                  autoComplete={"user_tag"}
                  className={"appearance-none"}
                />

                <TextInput
                  label={'Email Address'}
                  id={"email"}
                  name={'email'}
                  type={'text'}
                  disabled={true}
                  value={input.email ?? ''}
                  onChange={onChange}
                  placeholder={'Enter Email Address'}
                  autoComplete={"email"}
                  className={"appearance-none"}
                />

                <TextInput
                  label={'Phone Number'}
                  id={"phone_number"}
                  name={'phone_number'}
                  type={'tel'}
                  value={input.phone_number ?? ''}
                  onChange={onChange}
                  autoComplete={"phone_number"}
                  className={"appearance-none"}
                />

                <div className="space-y-1">
                  <label htmlFor="password" className="block glade-small-text-two glade-black">Password</label>
                  <div className="mt-3">
                    <div className="inline-flex">
                      <Button.Base
                        title={'Change Password'}
                        onClick={() => ChangeUserPasswordModalRef.current.open()}
                        className="glade-button-outline-blue"
                      />
                    </div>
                  </div>
                  <ChangeUserPasswordModal ref={ChangeUserPasswordModalRef} />
                </div>

                <div className="space-y-1">
                  <label htmlFor="pin" className="block glade-small-text-two glade-black">PIN</label>
                  <div className="mt-3">
                    <div className="inline-flex">
                      {settings.userDetails?.has_passcode === undefined ?
                        <Button.Base
                          title="Change PIN"
                          onClick={() => ChangeUserPinModalRef.current.open()}
                          className="glade-button-outline-blue"
                        />
                        : null}
                      {settings.userDetails?.has_passcode === true ?
                        <Button.Base
                          title="Reset PIN"
                          onClick={() => ResetUserPinModalRef.current.open()}
                          className="glade-button-outline-blue"
                        />
                        : null}
                      {settings.userDetails?.has_passcode === false ?
                        <Button.Base
                          title="Create PIN"
                          onClick={() => CreateUserPinModalRef.current.open()}
                          className="glade-button-outline-blue"
                        />
                        : null}
                    </div>
                  </div>
                  <ChangeUserPinModal ref={ChangeUserPinModalRef} />
                  <ResetUserPinModal ref={ResetUserPinModalRef} />
                  <CreateUserPinModal ref={CreateUserPinModalRef} />
                </div>

                {/* <CheckboxInput 
                                label={'Enable Two Factor Authentication'}
                                id={'tfa'}
                                name={'tfa'}
                                type={'checkbox'}
                                checked={input.tfa}
                                onChange={(e)=>TwoFactorAuthenticationModalRef.current.toggle()}
                                placeholder={''}
                                autoComplete={'tfa'}
                                className={'appearance-none'}
                            /> */}
                <TwoFactorAuthenticationModal ref={TwoFactorAuthenticationModalRef} type={input.tfa_status} email={settings.userDetails?.user?.email} />

              </div>
            </div>
          </div>
        </div>}

      {user.isBusiness &&
        <div className="p-3 py-0">
          <div className="mb-9 md:hidden">
            <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
          </div>

          <CardTableHeaderPlain
            title={'Business Information'}
          // rightButton={<Button.Blue onClick={updateBusinessInformation} title={'Save Changes'} />}
          />

          <div className="relative flex flex-col min-w-0 w-full bg-white border-t-2 border-gray-100">
            <div className="p-9">

              <div className="w-full md:w-5/12 space-y-6">
                <TextInput
                  label={'Business Name'}
                  id={"business_name"}
                  name={'business_name'}
                  type={'text'}
                  value={input.business_name ?? ''}
                  onChange={onChange}
                  placeholder={'Enter Business Name'}
                  autoComplete={"business_name"}
                  className={"appearance-none"}
                  disabled={true}
                />

                <TextInput
                  label={'Business Email'}
                  id={"business_email"}
                  name={'business_email'}
                  type={'text'}
                  value={input.business_email ?? ''}
                  onChange={onChange}
                  placeholder={'Enter Business Name'}
                  autoComplete={"business_email"}
                  className={"appearance-none"}
                  disabled={true}
                />

                <TextInput
                  label={'Business Phone Number'}
                  id={"business_phone_number"}
                  name={'business_phone_number'}
                  type={'tel'}
                  value={input.business_phone_number ?? ''}
                  onChange={onChange}
                  autoComplete={"business_phone_number"}
                  className={"appearance-none"}
                  disabled={true}
                />

                <TextInput
                  label={'Business Address'}
                  id={"business_address"}
                  name={'business_address'}
                  type={'text'}
                  value={input.business_address ?? ''}
                  onChange={onChange}
                  placeholder={'Enter Business Address'}
                  autoComplete={"business_address"}
                  className={"appearance-none"}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>}
      {/* <div className='ml-12 glade-small-text-two'>
        <h2>Two-Factor Authentication</h2>
        <p className='glade-grey-dark mt-1'>Require an additional code to login with your authenticator app</p>
        <div className='mt-4 flex' onClick={openTwoFactorModal}>
          <ToggleSwitch />
        </div>
      </div> */}
      {/* {isOpen && <TwoFactorAuthModal />} */}
    </React.Fragment>
  )
}
