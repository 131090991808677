import React from "react";
import { BsChevronRight, BsExclamationCircle } from "react-icons/bs";
import useUser from "../../hooks/useUser";
import RecentTransactionCard from "./RecentTransactionCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isArray } from "../../helpers/helper";
import { useState } from "react";
import AccountDetailModal from "./AccountDetailModal";
import { CloseIcon } from "../../assets/icons/Icons";
import { Link } from "react-router-dom";

export default function HomeSubmitCompliance({ onClick }) {
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [DisplayVideo, setDisplayVideo] = useState(false);
  const user = useUser();
  const history = useHistory();

  return (
    <div>
      {(user.compliance === "not-submitted" ||
        user.compliance === "rejected" ||
        user.compliance === "draft") && (
        <div>
          <div className="mt-2 glade-bg-warning py-3 px-4 space-x-3 flex glade-warning items-center justify-center">
            <BsExclamationCircle className="text-3xl lg:text-xl" />
            <h3>
              Complete your verification process to activate your account.{" "}
              <span className="cursor-pointer" onClick={() => onClick()}>
                <u>Click here</u>
              </span>{" "}
              to get started
            </h3>
          </div>
        </div>
      )}
      {user.compliance === "pending" && (
        <div className="mt-2 glade-bg-blue-review py-3 px-4 space-x-3 glade-blue flex flex-row items-center justify-center">
          <BsExclamationCircle />
          <h3>Your compliance is currently being reviewed.</h3>
        </div>
      )}
      <div className="md:grid grid-cols-2 gap-14 mt-12 mb-6">
        <div>
          <div className="glade-acct-bg border rounded-md p-6 flex justify-between items-center">
            <div>
              <h1 className="glade-home-dark glade-heading-two mt-2">
                <span className=" font-bold">
                  {isArray(user.selectedBusinessOrPersonalAccount?.accounts)
                    ? user?.selectedBusinessOrPersonalAccount?.accounts[0]?.balance?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : ""}{" "}
                </span>
              </h1>
              <p className="glade-home-dark">
                Total Balance in{" "}
                {isArray(user.selectedBusinessOrPersonalAccount?.accounts)
                  ? user?.selectedBusinessOrPersonalAccount?.accounts[0]
                      ?.currency
                  : ""}
              </p>
            </div>
            <h6
              className="glade-blue cursor-pointer"
              onClick={() => {
                setShowAccountDetails(true);
              }}
            >
              View Account Details
            </h6>
          </div>
          <div className="border p-6 mt-12 rounded-md">
            <div className="flex justify-between glade-acct-bg p-3 rounded-md">
              <p className="glade-heading-four">Recent Transactions</p>
              <p
                className="block flex items-center justify-center gap-2 cursor-pointer"
                onClick={() => history.push("/dashboard/transactions")}
              >
                <span className="">View All</span>{" "}
                <BsChevronRight size={"12px"} />
              </p>
            </div>
            <div className="h-92">
              <RecentTransactionCard />
            </div>
          </div>
        </div>
        <div>
          <h3 className="mb-4 glade-heading-three mt-4 md:mt-0">Get Started</h3>
          <Link
            to={{ pathname: "https://gladefinance.co/faqs/" }}
            target="_blank"
          >
            <h6 className="glade-blue cursor-pointer  mt-2">
              <u>How to submit compliance?</u>
            </h6>
          </Link>
          <Link
            to={{ pathname: "https://gladefinance.co/faqs/" }}
            target="_blank"
          >
            <h6 className="glade-blue cursor-pointer  mt-2">
              <u>Need help? Check our FAQs.</u>
            </h6>
          </Link>
        </div>
      </div>
      {showAccountDetails && (
        <AccountDetailModal
          showAccountDetails={showAccountDetails}
          setShowAccountDetails={setShowAccountDetails}
          accountDetailsToShow={
            user?.selectedBusinessOrPersonalAccount?.accounts?.length > 0
              ? user?.selectedBusinessOrPersonalAccount?.accounts[0]
              : ""
          }
        />
      )}

      {/* Compliance Video */}
      {DisplayVideo && (
        <div className="h-screen bg-glade-transparent fixed top-0 left-0 w-full pt-20 z-50 px-5">
          <div className="mb-20 lg:mb-5 text-right lg:pr-28">
            <span
              onClick={() => {
                setDisplayVideo(false);
                const scrollY = document.body.style.top;
                document.body.style.position = "";
                document.body.style.top = "";
                window.scrollTo(0, parseInt(scrollY || "0") * -1);
              }}
              className="cursor-pointer float-right"
            >
              <CloseIcon />
            </span>
          </div>
          <div className=" md:w-[70%] h-[450px] mx-auto bg-gray-300 rounded-xl">
            <video
              className="w-full h-full rounded-xl"
              controls
              loop
              autoPlay="yes"
              poster="/images/video-thumbnail.png"
            >
              <source
                src={
                  user?.isBusiness
                    ? "https://s3.eu-west-1.amazonaws.com/files.gladepay.com/businessweb.mp4"
                    : "https://s3.eu-west-1.amazonaws.com/files.gladepay.com/personalweb.mp4"
                }
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      )}
    </div>
  );
}
