import {
  LOADING_AIRTIME,
  RELOAD_AIRTIME,
  GET_ALL_AIRTIME,
  GET_ALL_BILL_TYPES,
  GET_BILLERS,
  GET_BILLER_ITEMS,
  BUY_AIRTIME,
  GET_AIRTIME_BENEFACTRICES
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload: false,
  airtimeIndex: [],
  billTypes: {},
  billers: [],
  billerItems: [],
  buyAirtime: {},
  beneficiary: {},
};

export default function airtimeReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_AIRTIME:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_AIRTIME:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case GET_ALL_AIRTIME:
      return {
        ...state,
        airtimeIndex: action.payLoad.data.data,
      };

    case GET_ALL_BILL_TYPES:
      return {
        ...state,
        billTypes: action.payLoad.data.data,
      };

    case GET_BILLERS:
      return {
        ...state,
        billers: action.payLoad.data.data,
      };

    case GET_BILLER_ITEMS:
      return {
        ...state,
        billerItems: action.payLoad.data.data,
      };

    case BUY_AIRTIME:
      return {
        ...state,
        buyAirtime: action.payLoad.data.data,
      };

    case GET_AIRTIME_BENEFACTRICES:
      return {
        ...state,
        beneficiary: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
