import axios from "axios";
import {
  logoutUserLocallyAction,
  logoutUserLocallyActionTrue,
  refreshUserTokenLocallyAction,
} from "../redux/auth/unAuthActions";
import store from "../redux/store";
import useTeam from "../hooks/useTeam";

// Set Base Url
const baseUrl = process.env.REACT_APP_API_URL;

// Get user details
const userDetails = () => {
  let authState = store.getState().auth;
  return authState;
};
const teamMember = () => {
  let team = store.getState().team;
  return team;
};

// Retry Counter
const retryAgain = async () => {
  // max count for retires
  let maxCount = 1;

  // get current retry count, if non assign zero
  let retryCount = (await localStorage.getItem("retryCount")) ?? 0;

  // check if current retry count is less that allowed max count
  let retry = parseInt(retryCount) < maxCount ? true : false;

  // store new retry count
  localStorage.setItem(
    "retryCount",
    retry ? (parseInt(retryCount) + 1).toString() : "0"
  );

  return retry;
};

// Refresh token
const refreshToken = async () => {
  // Get token from redux store
  const { token, refresh_token } = userDetails();

  // Actual path
  return axios
    .post(
      `${baseUrl}/auth/refresh`,
      { refresh_token: refresh_token },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then((res) => {
      // Modify data structure for consistency
      res = {
        ...res,
        data: {
          ...res.data,
          data: {
            ...res.data.data,
            data: {
              token: res.data.data.token,
              refresh_token: res.data.data.refresh_token,
            },
          },
        },
      };

      // Send new token to reducer
      store.dispatch(refreshUserTokenLocallyAction(res));
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// Logout user
const logoutUser = async (error) => {
  // Temporary logout as the logout route is not working
  await store.dispatch(logoutUserLocallyActionTrue(error));

  // Actual logout
  // const { token, refresh_token } = userDetails();
  // localStorage.clear()
};

// Handle success response
const successResponseHandler = (response) => {
  // No content response (204)
  if (response.status === 204) {
    response.data = { data: {} };
  }

  // Log response
  if (process.env.REACT_APP_APP_ENV === "development") {
    // console.log('Success Response From Connection', response.data);
  }

  // Additional checks for API that does not utilize the HTTP status code properly
  if (response.data.status === false || response.data.status === "failed") {
    if (response.data.error && response.data.error.code === 401) {
      // Logout user
      logoutUser(response);
      return Promise.reject(response.data.error);
    }

    // Error message is retrieved from the JSON body.
    const error = new Error(response.data.message);

    // Attach the response instance, in case you decide to access it.
    error.response = response;

    throw error;
  }

  // Return processed response
  return response;
};

// Handle failure response
const failureResponseHandler = async (error) => {
  // Log error response
  if (process.env.REACT_APP_APP_ENV === "development") {
    // console.log('Failure Response From Connection', error);
  }

  // No network response (ECONNABORTED)
  if (!error.response || error.code === "ECONNABORTED") {
    // alert(`Could not connect to network`);
    return Promise.reject(error);
  }
  // No authorization response (401)
  if (error.response && error.response.status === 401) {
    // Logout user
    logoutUser(error);
    return Promise.reject(error);
      // try {

    //   // Check if retry limit has been exceeded
    //   let shouldRetry = await retryAgain();
    //   if (!shouldRetry) { return Promise.reject(new Error('Retry count exceeded')); }

    //   // Attempt to refresh expired token
    //   let refreshTokenResponse = await refreshToken();

    //   // Handle token refresh blacklisting caused by a prior request having refreshed the token already
    //   if (refreshTokenResponse.status.toString().split('')[0] !== '2') {
    //     refreshTokenResponse = { data: { data: { token: userDetails().token } } }
    //   }

    //   // Retry the failed request with returned token
    //   return await instance.request({
    //     ...error.config, headers: {
    //       ...error.config.headers,
    //       Authorization: 'Bearer ' + refreshTokenResponse.data.data.token
    //     },
    //   })
    //     .then((response) => {
    //       return successResponseHandler(response);
    //     })
    //     .catch((error) => {
    //       return Promise.reject(error);
    //     });

    // } catch (error) {

    //   return Promise.reject(error);
    // }
  }

  // Return unprocessed error
  return Promise.reject(error);
};

// Create an axios instance
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  headers: {},
});

// Add a request interceptor
instance.interceptors.request.use((req) => {
  const {
    token,
    isBusiness,
    isBusinessUUID,
    selectedBusinessOrPersonalAccount,
  } = userDetails();
  const team = teamMember();
  if (team?.token && team?.isBusiness && team?.isBusinessUUID) {
    req.headers.Authorization = `Bearer ${team?.token}`;
    req.headers["business-uuid"] = team?.isBusinessUUID;
    req.headers["business-currency"] =
      team?.selectedCurrency?.currency ?? "NGN";
  }

  if (baseUrl.includes("testing")) {
    // Modify this condition to allow other environments
    req.headers["x-platform"] = "web";
  }

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
    if (isBusiness && !req.headers["business-uuid"] && isBusinessUUID) {
      req.headers["business-uuid"] = isBusinessUUID;
      req.headers["business-currency"] = selectedBusinessOrPersonalAccount
        ?.selectedCurrency?.currency
        ? selectedBusinessOrPersonalAccount?.selectedCurrency?.currency
        : "NGN";
    } else {
      req.headers["user-currency"] = selectedBusinessOrPersonalAccount
        ?.selectedCurrency?.currency
        ? selectedBusinessOrPersonalAccount?.selectedCurrency?.currency
        : "NGN";
    }
  }

  // Reattach the base url
  if (!req.baseURL) {
    req.url = baseUrl + "/" + req.url;
  }

  // Log requests
  if (process.env.REACT_APP_APP_ENV === "development") {
  }

  return req;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return successResponseHandler(response);
  },
  (error) => {
    return failureResponseHandler(error);
  }
);

export default instance;
