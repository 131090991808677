import { useEffect, Fragment } from "react";
import TextInput from "../../../components/inputs/TextInput";
import { useDispatch } from "react-redux";
import {
  getBanks,
  resolveAccountDetails,
} from "../../../redux/fund-transfer/fundTransferActions";
import useFundTransfer from "../../../hooks/useFundTransfer";
import Button from "../../../components/buttons/Button";
import CountrySelectionField from "../../auth/CountrySelectionField";

const BulkRecipientDetails = ({ input, setInput, setBulkStep, bulkStep }) => {
  const dispatch = useDispatch();
  const { banks } = useFundTransfer();
  useEffect(() => {
    dispatch(getBanks({ mode: "external" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      (input?.payload[input?.payload.length - 1]?.beneficiary_account_number
        ?.length === 10 &&
        input?.payload[input?.payload.length - 1]?.beneficiary_bank_code) ||
      (input?.payload[input?.payload.length - 1]?.transfer_type ===
        "internal" &&
        input?.payload[input?.payload.length - 1]?.beneficiary_account_number
          ?.length === 10)
    ) {
      dispatch(
        resolveAccountDetails(
          {
            account_number:
              input?.payload[input?.payload.length - 1]
                .beneficiary_account_number,
            bank_code:
              input?.payload[input?.payload.length - 1].beneficiary_bank_code ??
              "",
            mode:
              input?.transfer_type !== "external"
                ? "internal"
                : "external_local",
          },
          (resolveAccountDetails) =>
            setInput((form) => {
              let newData = form.payload[form?.payload?.length - 1];
              newData = {
                ...newData,
                ...{
                  beneficiary_account_name: resolveAccountDetails.account_name,
                  session_id: resolveAccountDetails?.session_id ?? "",
                },
              };
              let payload = form.payload;
              payload[form.payload.length - 1] = newData;
              return { ...form, payload };
            })
        )
      );
    }
  }, [
    input?.payload[input?.payload.length - 1]?.beneficiary_account_number,
    input?.payload[input?.payload.length - 1]?.beneficiary_bank_code,
  ]);

  const onSelectProvider = (provider) => {
    setInput((form) => {
      let newData = form.payload[form?.payload?.length - 1];
      newData = {
        ...newData,
        beneficiary_bank_code: provider.code,
        beneficiary_bank_name: provider.name,
      };
      let payload = form.payload;
      payload[form.payload.length - 1] = newData;
      return { ...form, payload };
    });
  };

  const handleChange = (e) => {
    setInput((prev) => {
      let newData = prev.payload[prev?.payload?.length - 1];
      newData = { ...newData, [e.target.name]: e.target.value };
      let payload = prev.payload;
      payload[prev.payload.length - 1] = newData;
      return { ...prev, payload };
    });
  };
  return (
    <div className="md:w-3.5/12">
      <p className="mt-12 glade-heading-two glade-home-dark">
        <span className="font-bold">Enter Recipient Details</span>
      </p>
      <div className="text-left flex flex-col gap-4 py-12">
        <TextInput
          label={"Account Number"}
          name={"beneficiary_account_number"}
          id={"beneficiary_account_number"}
          type={"number"}
          value={
            input?.payload[input?.payload.length - 1]
              ?.beneficiary_account_number
          }
          placeholder={"Enter Account number"}
          onChange={handleChange}
        />
        <div>
          {banks?.length > 0 &&
            input?.payload[input?.payload?.length - 1]?.transfer_type ===
              "external" && (
              <Fragment>
                {" "}
                <label className="block glade-small-text-two glade-black mb-1">
                  Select Bank
                </label>
                <CountrySelectionField
                  title={"Select Bank"}
                  placeholder={"Search Bank"}
                  defaultValue={
                    input?.payload[input?.payload?.length - 1]
                      ?.beneficiary_bank_name
                  }
                  onSelectionChange={onSelectProvider}
                  optionsList={banks.map((bank) => {
                    return {
                      ...bank,
                      country_name: bank?.bank_name, //component was initially designed for country, modified the data to suit the component.
                      code: bank?.code,
                    };
                  })}
                />
              </Fragment>
            )}
        </div>
        <TextInput
          label={"Account Name"}
          placeholder={""}
          name={"beneficiary_account_name"}
          id={"beneficiary_account_name"}
          value={
            input?.payload[input?.payload.length - 1]?.beneficiary_account_name
          }
          disabled
        />

        <TextInput
          label={"Narration"}
          placeholder={"Optional"}
          name={"narration"}
          id={"narration"}
          value={input?.payload[input?.payload.length - 1]?.narration}
          onChange={handleChange}
        />
        <div className="flex justify-center mt-2">
          <Button.Blue
            className={"px-3"}
            title={"Add"}
            onClick={() => setBulkStep(1)}
            disabled={
              !input?.payload[input?.payload.length - 1]
                ?.beneficiary_account_name
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BulkRecipientDetails;
