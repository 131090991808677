import { validate } from '../../helpers/validator';
import { toast } from "react-toastify";

export const createInvoiceValidation = (payLoad, silently=false) => {

    let data = {
        // invoice_id:payLoad.invoice_id,
        customer_id:payLoad.customer_id,
        currency:payLoad.currency,
        date_due:payLoad.date_due,
        discount_type:payLoad.discount_type,
        discount:payLoad.discount,
        shipping:payLoad.shipping,
        vat:payLoad.vat,
        // note:payLoad.note,
        items:payLoad.items,
        item_count:payLoad.items,
    }
    let rules = {
        // invoice_id:'required',
        customer_id:'required',
        currency:'required',
        date_due:'required',
        discount_type:'required',
        discount:'required|min:-1',
        shipping:'required|min:-1',
        vat:'required|min:-1',
        // note:'required',
        items:(value)=>{
            let checks = [];
            value.forEach(item => {
                if (item.description?.length < 3) {
                    checks.push(false);
                }
                if (!item.qty || parseInt(item.qty) < 1) {
                    checks.push(false);
                }
                if (!item.cost || parseInt(item.cost) < 0) {
                    checks.push(false);
                }
            });
            return checks.length > 0 ? false : true;
        },
        item_count:(value)=>{
            return value.length < 0 ? false : true;
        }
    }
    let messages = {
        invoice_id:{
            required:'Invoice number is required',
        },
        customer_id:{
            required:'A customer is required',
        },
        currency:{
            required:'A currency type is required',
        },
        date_due:{
            required:'An due date is required'
        },
        discount_type:{
            required:'A discount type is required',
        },
        discount:{
            required:'A discount is required',
            min:'Discount amount is not valid',
        },
        shipping:{
            required:'Shipping cost is required',
            min:'Shipping cost is not valid',
        },
        vat:{
            required:'Vat is required',
            min:'Vat value is not valid',
        },
        note:{
            required:'Note is required',
        },
        items:{
            custom:'An item name, cost or quantity is not valid'
        },
        item_count:{
            custom:'At least one item is required'
        }
    }

    // If they are no errors then exit
    let result = validate(data,rules,messages);
    if (Object.keys(result).length === 0) {
        return true;
    }

    // Picking the first error and dispatching it to the user
    Object.keys(result).map((value) => {
        let error = result[value];
        let msg = error[Object.keys(error)[0]];
        return !silently && toast.warning(msg.toString(), { position: toast.POSITION.TOP_RIGHT, theme: "colored" }); 
    });

    return false;
}