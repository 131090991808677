import React, { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { TransferFormContext } from '../../context/TransferFormContext';
import { removeCommas } from '../../helpers/helper';
import { updateSelectedAccountBalance } from '../../redux/auth/authActions';
import { makeExternalTransfer, makeInternalTransfer, makeInternalTransferWithUserTag, makeInternationalTransfer, repeatTransfer } from '../../redux/fund-transfer/fundTransferActions';
import ConfirmPinModal from './ConfirmPinModal';
import LocalNGNReview from './LocalNGNReview';
import TransferScreen from './TransferScreen';

const TransferDetailsReview = () => {
  const {
    setTransactionFailed,
    setFormStage,
    setTransferForm,
    transferForm,
    currencies,
    formType,
    isNGNTransfer,
    transferMethod,
    setReceiptInfo,
    gladeTransferType,
    repeatTransaction,
    setRepeatTransaction
  } = useContext(TransferFormContext);

  const modalRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();



  function onTransferSuccess(res) {
    const response = res.data.data;
    setTransactionFailed(false);
    setTransferForm({});
    setRepeatTransaction(false)
    const info = {
      bank_name: response?.beneficiary_bank_name,
      beneficiary_account: response.beneficiary_account,
      beneficiary_instituition: response.beneficiary_institution,
      beneficiary_name: response.beneficiary_name,
      currency: response.currency,
      destination: response.destination,
      narration: response.narration || "-",
      id: response.id,
      method: response.method,
      created_at: response.created_at,
      fee: response.calculatedfee,
      order_ref: response.order_ref,
      provider: response.provider,
      status: response.status,
      transfer_type: response.transfer_type,
      txn_ref: response.txn_ref,
      value: response.value,
      sender_name: response.sender_name,
      sender_bank: response.sender_bank,
      session_id: response?.session_id,
      sender_account_number: response.sender_account_number
    };
    setReceiptInfo(info);
    setFormStage('confirmation');
    dispatch(updateSelectedAccountBalance(res?.data?.data?.balance_after));
  }

  function onTransferFail() {
    setTransactionFailed(true);
    setTransferForm({});
    setRepeatTransaction(false)
  }

  function initiateTransfer(pin_code) {
    const { receiver_amount, amount, ...formInfo } = transferForm;
    let transferPayload = {};
    const formattedAmount = removeCommas(amount);
    if(repeatTransaction){
      dispatch(repeatTransfer({ txn_ref:location?.state?.txn_ref, passcode: pin_code },onTransferSuccess, onTransferFail))
    } else  {
        
    
    if (isNGNTransfer) {
      transferPayload = {
        ...formInfo,
        amount: Number(formattedAmount),
        save_beneficiary: formInfo?.save_beneficiary ?? false,
      };
      dispatch(makeExternalTransfer(transferPayload, onTransferSuccess, onTransferFail));
    } else if (formType === "glade") {
      if (gladeTransferType === 'usertag') {
        transferPayload = {
          amount: formattedAmount,
          user_tag: formInfo?.user_tag,
          save_beneficiary: formInfo?.save_beneficiary ?? false,
          beneficiary_email: formInfo?.beneficiary_email,
          narration: formInfo?.narration
        };
        dispatch(makeInternalTransferWithUserTag(transferPayload, onTransferSuccess, onTransferFail));
      } else {
        transferPayload = {
          amount: formattedAmount,
          account_number: formInfo?.beneficiary_account_number,
          save_beneficiary: formInfo?.save_beneficiary ?? false,
          beneficiary_email: formInfo?.beneficiary_email
        };
        dispatch(makeInternalTransfer(transferPayload, onTransferSuccess, onTransferFail));
      }
    } else if (formType === "local") {
      transferPayload = {
        ...formInfo,
        amount: formattedAmount,
        beneficiary_country_code: currencies?.activeCurrency?.currency.slice(0, -1), // just incase user didn't switch currencies
        beneficiary_currency_code: currencies?.activeCurrency?.currency,
        transfer_method: transferForm?.transfer_method ?? "bank",
        beneficiary_bank: formInfo?.beneficiary_bank
      };
      if(transferForm.branch){
        transferPayload = {...transferPayload, branch: transferForm.branch }
      }
      dispatch(makeInternationalTransfer(transferPayload, onTransferSuccess, onTransferFail));
    } else {
      if (transferMethod?.selected?.code === "mobilemoney") {
        transferPayload = {
          beneficiary_account_number: formInfo?.beneficiary_phone,
          beneficiary_name: formInfo?.beneficiary_phone
        };
      }
      transferPayload = {
        ...transferPayload,
        ...formInfo,
        amount: formattedAmount,
        beneficiary_country_code: currencies.selectedCurrency.currency.slice(0, -1), // just incase user didn't switch currencies
        beneficiary_currency_code: currencies.selectedCurrency.currency,
      };
      if(!transferPayload?.beneficiary_account_type || transferPayload?.beneficiary_account_type === 'corporate'){
        delete transferPayload.beneficiary_first_name;
        delete transferPayload.beneficiary_last_name;
        transferPayload['beneficiary_account_type'] = 'corporate';
      }else{
        delete transferPayload.beneficiary_name;
      }
      dispatch(makeInternationalTransfer(transferPayload, onTransferSuccess, onTransferFail));
    }}
    // setFormStage('confirmation');
  }

  function openConfirmPinModal() {
    modalRef.current.openModal();
  }

  return (
    <TransferScreen>
      <h4 className='text-2xl font-bold mb-8'>Review details of this transfer</h4>
      {
        (isNGNTransfer || formType === "glade")
          ? <LocalNGNReview onSubmit={openConfirmPinModal} />
          : <LocalNGNReview onSubmit={openConfirmPinModal} />
      }
      <ConfirmPinModal
        onCorrectPin={initiateTransfer}
        ref={modalRef}
      />
    </TransferScreen>
  );
};

export default TransferDetailsReview;

export const Review = styled.section`
  border-bottom: 2px solid #E8EBF6;
  margin: 0 auto;
  font-size: 1.1rem;
  width: 450px;
  margin-bottom: 2rem;
`;

export const ReviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  gap: 1rem;

  span:nth-child(2) {
    text-align: right;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
`;
