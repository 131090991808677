import React from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import {getBeneficiaries} from "../../redux/airtime/airtimeActions";
import { HeadlessRadioGroup } from "../../components/radios/HeadlessRadioGroup";
import useAirtime from "../../hooks/useAirtime";

export default React.forwardRef(function SelectBeneficiaryModal(props, ref) {

    const dispatch = useDispatch();
    const airtime = useAirtime();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    React.useEffect(() => {
        dispatch(getBeneficiaries({ type: 2 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PlainModal 
            visibility={props.showBeneficiaryModal}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Select Beneficiary'}
            modelContentSection={
                <HeadlessRadioGroup
                    data={airtime.beneficiary}
                    preInput={props.beneficiary}
                    returnInput={(value)=>{ props.onSelectOfBeneficiary(value); props.setShowBeneficiaryModal(); }}
                />
            }
            onClose={() => props.setShowBeneficiaryModal()}
        />
    )
})
