import React from 'react';
import { useDispatch } from "react-redux";
import useUser from '../../hooks/useUser';
import { updateSelectedAccountAction } from "../../redux/auth/authActions";
import { isEmptyArray } from "../../helpers/helper";

export default function AccountMonitor() {

    const firstUpdate = React.useRef(true);
    const user = useUser();
    const dispatch = useDispatch();

    React.useLayoutEffect(() => {

        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (!user.isBusiness && !isEmptyArray(user.personalAccounts) && !firstUpdate.current) {
            let selectedAccountDetails = user.personalAccounts[0];
            selectedAccountDetails && dispatch(updateSelectedAccountAction({ ...selectedAccountDetails, is_business: false }));
        }

        if (user.isBusiness && !isEmptyArray(user.businessAccounts) && !firstUpdate.current) {
            let selectedAccountDetails = user.businessAccounts.find(item => item.business_uuid === user.isBusinessUUID);
            selectedAccountDetails && dispatch(updateSelectedAccountAction({ ...selectedAccountDetails, is_business: true }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.businessAccounts, user.personalAccounts]);

    return (null);
}