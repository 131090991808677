export const CONVERT_RATE_LOADING = 'CONVERT_RATE_LOADING';
export const CONVERT_RATE_SUCCESS = 'CONVERT_RATE_SUCCESS';
export const CONVERT_RATE_FAILURE = 'CONVERT_RATE_FAILURE';

export const CONVERT_CURRENCY_LOADING = 'CONVERT_CURRENCY_LOADING';
export const CONVERT_CURRENCY_SUCCESS = 'CONVERT_CURRENCY_SUCCESS';
export const CONVERT_CURRENCY_FAILURE = 'CONVERT_CURRENCY_FAILURE';

export const CONVERSION_CHARGE_LOADING = 'CONVERSION_CHARGE_LOADING';
export const CONVERSION_CHARGE_SUCCESS = 'CONVERSION_CHARGE_SUCCESS';
export const CONVERSION_CHARGE_FAILURE = 'CONVERSION_CHARGE_FAILURE';