import jsPDF from "jspdf";
import "jspdf-autotable";
import "rasterizehtml";
import * as html2canvas from "html2canvas";
import { getCurrentDate } from "../../helpers/helper";
import {
  GLADE_PDF_BACKGROUND,
  GLADE_PDF_LOGO,
  GLADE_PDF_STAMP,
} from "../../assets/images/images";

window.html2canvas = html2canvas;
var BackgroundImage = new Image();
var GladeStampImage = new Image();
var LogoImage = new Image();

BackgroundImage.src = GLADE_PDF_BACKGROUND;
GladeStampImage.src = GLADE_PDF_STAMP;
LogoImage.src = GLADE_PDF_LOGO;

function addWaterMark(doc) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.addImage(GladeStampImage, "jpg", 10, 500, 100, 100);
    doc.setTextColor(150);
    doc.setFontSize(10);
    // doc.setFont("arial", "normal");
    doc.text(
      120,
      570,
      "DISCLAIMER: This is a computer generated statement and it represents our records of your transaction with us. Any exception must be advised to the bank immediately. If we do not hear from you within 2 weeks we will assume that you in agreement with the details stated. For any enquiries. Please contact Glade's customer care team via email at support@glade.ng",
      {
        maxWidth: 630,
        align: "justify",
      }
    );
  }

  return doc;
}

export function downloadTransactionsAsPDF(details, statements) {
  const fileName = "Glade-Account-Statement";
  const pdfHeaders = [
    [
      "S/N",
      "Transaction Ref",
      "Session ID",
      "Type",
      "Narration",
      "Amount",
      "Balance",
      "Date",
    ],
  ];
  const pdfData = statements?.map((value, i) => [
    `${i + 1}`,
    value?.txn_ref,
    value?.session_id,
    value?.txn_type,
    value?.narration,
    value?.value,
    // value?.balance_before,
    value?.balance_after,
    value?.created_at,
  ]);

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    let doc = new jsPDF(orientation, unit, size);
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(12);

    let content = {
      startY: 150,
      head: pdfHeaders,
      body: pdfData,
    };

    doc.addImage(BackgroundImage, "jpg", 0, 0, 1000, 175);
    doc.text(40, 40, `ACCOUNT NAME: ${details?.account_name}`);
    doc.text(40, 59, `ACCOUNT NUMBER:  ${details?.account_number}`);
    doc.text(
      40,
      78,
      `OPENING BALANCE: ${details?.currency} ${details?.opening_balance}`
    );
    doc.text(
      40,
      97,
      `CLOSING BALANCE:  ${details?.currency} ${details?.closing_balance}`
    );
    doc.addImage(LogoImage, "png", 600, 50, 110, 30);

    doc.setDrawColor(255, 255, 255);
    doc.line(0, 120, 1000, 120, "S");
    doc.text(40, 135, `CURRENCY:  ${details?.currency}`);
    doc.text(600, 135, `PERIOD REQUESTED: ${getCurrentDate()}`);
    doc.line(0, 143, 1000, 143, "S");

    doc.autoTable(content);
    doc = addWaterMark(doc);
    doc.save(`${fileName}.pdf`);
  };

  return !Array.isArray(pdfData) || !pdfData.length ? null : exportToPDF();
}
