import { useSelector } from "react-redux";

const useAirtime = () => {
    const airtime = useSelector((state) => state.airtime);

    const {
        isLoading,
        shouldReload,
        airtimeIndex,
        billers,
        billTypes,
        billerItems,
        buyAirtime,
        beneficiary,
    } = airtime;

    const airtimeHistory = airtimeIndex?.filter(
        (airtime) => airtime.bill_category === "Airtime",
    );

    const billsTypeCategories = billTypes?.categories?.filter(
        (category) => category.category_code === "airtime",
    );

    return {
        isLoading,
        shouldReload,
        airtimeIndex,
        airtimeHistory : airtimeHistory ?? [],
        billers,
        billTypes : {...billTypes, categories : billsTypeCategories},
        billerItems,
        buyAirtime,
        beneficiary,
    };
};

export default useAirtime;
