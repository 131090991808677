import React, { useState } from "react";
import useTeam from "../../../hooks/useTeam";
import { TextSkeleton } from "../../../components/skeletons";

const AccountSelection = ({  }) => {
  const [showAccounts] = useState(false);
  const { business, isLoadingTeam } = useTeam();

  return (
    <>
      <div
        className={`flex w-64 flex-col rounded-xl mb-2 glade-bg-grey py-2 space-y-1 relative ${
          !showAccounts ? "h-20" : "h-20"
        }`}
      >
        <div
          className="flex justify-between pl-2 pr-2 pb-0 pt-2 flex-row cursor-pointer"
        >
          <h3 className="glade-heading-four glade-black">
            {isLoadingTeam? <TextSkeleton width={'40px'} /> : business?.business_name}
          </h3>
        </div>
        <p className="glade-black pl-2 pr-2">Business Account</p>

        {showAccounts && (
          <div
            className="p-2 w-full absolute top-16 overflow-auto max-h-60 flex flex-col justify-between"
            style={{ background: "#DDE1EF", borderRadius: "0 0 16px 16px" }}
          >
            {/* Business Account */}
                <div
                  className="flex flex-col gap-0 cursor-pointer pt-2 border-b pb-2"
                >
                  <span className="glade-grey-dark font-bold">
                    {isLoadingTeam? <TextSkeleton /> : business?.business_name}
                  </span>
                  <span className="glade-grey-dark glade-normal-three">
                    Business Account
                  </span>
                </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountSelection;
