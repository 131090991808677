import React, { useState } from 'react'
import { SELFIE_EMOJI } from '../../../assets/images/images'
import Button from '../../../components/buttons/Button'
// import MetaMapButton from '../../../components/buttons/MetaMapButton'
import VideoRecorder from './VideoRecorder'
import {RiCheckboxCircleLine} from 'react-icons/ri'

const SelfieVerification = React.forwardRef((props, forwardedRef) => {
  const selfieInstruction = [
    'Keep your face within the frame',
    'Rotate your face within the frame',
    'Submit selfie recording'
  ]
  const [captureVideo, setCaptureVideo] = useState(false)
  return (
    <div ref={forwardedRef} className="w-full max-w-sm">
      <div className="flex flex-col justify-center">

        <h3 className="mt-6 glade-heading-two-bold glade-black text-center">Selfie verification</h3>
        <p className="mt-2 glade-subtitle-two glade-grey-dark text-center">Take a selfie video to complete the verification</p>
      </div>
      <div className="mt-8 space-y-12 flex flex-col justify-center items-center">
        <div className='pt-4'>
          <img src={SELFIE_EMOJI} alt='selfie emoji' />
        </div>
        <div className='flex space-y-3 flex-col'>
          {selfieInstruction.map((ins, i) => (
            <div className='flex space-x-3 items-center' key={i}>
              <RiCheckboxCircleLine color="#00D220" size={24} />
              <p className='glade-subtitle-one'>{ ins}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-col justify-center pt-3 space-y-3">
          {/* METAMAP BUTTON */}
          {/* <MetaMapButton
            userUuid={props.input?.user_uuid}
            email={props.input?.email}
            finishedCallback={() => props.finishedCallback()}
          /> */}
          <Button.Blue
            onClick={() => setCaptureVideo(true)}
            title={"Start Verification"}
            className={"text-center py-2"}
          />
          <Button.Base
            title={'Go back'}
            type={'button'}
            className={'py-3 glade-bkg-light-blue glade-blue'}
            onClick={() => props?.formWizardRef?.current?.goTo(3)}
          />
        </div>

        <div className="flex justify-center w-full">
          
        </div>
      </div>
      {captureVideo && (
        <>
          <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-32 flex flex-col justify-center" onClick={() => setCaptureVideo(false)}>
            <div className="flex justify-center items-center" >
              <div className="self-center bg-black rounded md:w-2/3 py-5 lg:min-h-screen " onClick={e => {
                e.stopPropagation();
              }}>
                <VideoRecorder input={props?.input} setIdUploadResponse={props?.setIdUploadResponse} finishedCallback={props?.finishedCallback} setCaptureVideo={setCaptureVideo} />
              </div>
            </div>
          </div>
        </>

      )}
    </div>
  )
})

export default SelfieVerification