import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import { getAllStatements } from "../../redux/transactions/transactionActions";
import useTransactions from "../../hooks/useTransactions";
import {
  simpleDate,
  statusEnum,
  isArray,
  isEmpty,
  pagination,
  getSuppliedFieldValue,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import TextIndicator from "../../components/indicators/TextIndicator";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import SearchInput from "../../components/inputs/SearchInput";
import Sieve from "../../components/filter/Sieve";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import {
  ButtonWithDropDown,
  ButtonWithDropDownItem,
} from "../../components/buttons/ButtonWithDropDown";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import { downloadTransactionsAsPDF } from "./DownloadTransactionsAsPDF";
import { downloadTransactionsAsCSV } from "./DownloadTransactionsAsCSV";
import { IoIosArrowForward } from "react-icons/io";
import useUser from "../../hooks/useUser";
import useTeam from "../../hooks/useTeam";

export default function Transactions() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useUser();
  const transaction = useTransactions();
  const {permissions} = useTeam(); 
  const dataForCSV = transaction?.statementIndex?.statement;
  const dataForPDF = {
    details: transaction?.statementIndex?.details,
    statement: transaction?.statementIndex?.statement,
  };

  React.useEffect(() => {
    dispatch(getAllStatements());
    // eslint-disable-next-line
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(
      pagination(transaction.statementIndex?.statement, 10, currentPage)
    );
    // eslint-disable-next-line
  }, [currentPage, transaction.statementIndex?.statement]);

  // Search Function And Control
  const onSearch = (search) => {
    const filteredData = transaction.statementIndex?.statement?.filter(
      (item) => {
        return item.txn_ref.toLowerCase().includes(search.toLowerCase());
      }
    );
    setTableData(pagination(filteredData ?? [], 10, currentPage));
  };

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getAllStatements({
        type: data.transaction_type,
        date_from: data.date_from ?? data.date,
        date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
      })
    );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getAllStatements());
  };

  const renderTransactionsTable = (data) => {
    const onClickOfRow = (item) => {
      let transaction_type = "";
      let showRepeatButton = false;
      if(item?.txn_source === "transfer" && item?.txn_type === "debit"){
        transaction_type = "fund-transfer";
      }else if(item?.txn_source === "transfer" && item?.txn_type === "credit" ){
        transaction_type = "credit"
      }else if (item?.txn_source === "banking" && item?.narration?.includes("TopUp")){
        transaction_type = "airtime"
      }else if(item?.txn_source === "banking" && item?.narration?.includes("BILL") ){
        transaction_type = "bill"
      }else{
        transaction_type = "others"
      }
      history.push({ pathname: !permissions.includes("transactions page") ? `/dashboard/${transaction_type}/detail` : `/teams/${transaction_type}/detail`, state: item, showRepeatButton });
    };
    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key} onClick={() => {}}>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <TextIndicator
              type={statusEnum[item?.txn_type]}
              title={item?.txn_type}
            />
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <span className="glade-normal-text-two whitespace-nowrap">
              {simpleDate(item?.txn_date, "numeric", "short", "2-digit")}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <p className="glade-normal-text-two whitespace-normal capitalize">
              {item?.provider !== "glade" ? item?.provider : "Gladefinance"}
            </p>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <span className="glade-normal-text-one whitespace-nowrap">
              {getSuppliedFieldValue("currency", user)}{" "}
              {Number(item?.actual_value ?? item?.value)?.toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">
              <CopyTextButton text={item?.txn_ref} />
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <span className="glade-normal-text-two whitespace-nowrap">
              {item?.fee}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <span className="glade-normal-text-two whitespace-nowrap">
              {getSuppliedFieldValue("currency", user)}{" "}
              {Number(item?.balance_before)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <span className="glade-normal-text-two whitespace-nowrap">
              {getSuppliedFieldValue("currency", user)}{" "}
              {Number(item?.balance_after)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={() => onClickOfRow(item)} className="cursor-pointer">
            <p className="glade-normal-text-two w-52 whitespace-normal">
              {item?.narration}
            </p>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderTransactionsDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() =>
            history.push({
              pathname: "/dashboard/transactions/transaction-detail",
              state: item,
            })
          }
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.txn_type]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {getSuppliedFieldValue("currency", user)}{" "}
                  {Number(item?.actual_value ?? item?.value)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.txn_date, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <CardTableHeaderComplex
        title={`Showing ${tableData.totalItems} records`}
        filter={
          <Sieve
            types={["transaction type", "date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={transaction.isLoading}
            loading={transaction.isLoading}
          />
        }
        searchBar={
          <SearchInput
            disabled={transaction.isLoading}
            onSearch={onSearch}
            placeholder={"Transaction Reference"}
          />
        }
        rightButton={
          <div>
            <ButtonWithDropDown
              className="z-10"
              title="Download"
              disabled={
                transaction.isLoading ||
                isEmpty(transaction.statementIndex?.statement)
              }
            >
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() =>
                      downloadTransactionsAsPDF(
                        dataForPDF.details,
                        dataForPDF.statement
                      )
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Download as PDF{" "}
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Download as PDF{" "}
                  </span>
                }
              />
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() => downloadTransactionsAsCSV(dataForCSV)}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Download as CSV
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Download as CSV
                  </span>
                }
              />
            </ButtonWithDropDown>
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={transaction.isLoading}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["transaction type", "date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={transaction.isLoading}
                    loading={transaction.isLoading}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              {!isEmpty(transaction.statementIndex?.statement) ? (
                <KebabDropdownItem
                  activeChild={
                    <span
                      onClick={() =>
                        downloadTransactionsAsPDF(
                          dataForPDF.details,
                          dataForPDF.statement
                        )
                      }
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Download as PDF{" "}
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      {" "}
                      Download as PDF{" "}
                    </span>
                  }
                />
              ) : null}
              {!isEmpty(transaction.statementIndex?.statement) ? (
                <KebabDropdownItem
                  activeChild={
                    <span
                      onClick={() => downloadTransactionsAsCSV(dataForCSV)}
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Download as CSV
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Download as CSV
                    </span>
                  }
                />
              ) : null}
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || transaction.isLoading ? (
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable column={["", "Type", "Date", "Account", "Amount", "Transaction Ref", "Fee", "Balance Before", "Balance After", "Details", ""]} loading={transaction.isLoading} />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2]} loading={transaction.isLoading} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="hidden md:block">
            <CardTableBody column={[ "", "Type", "Date", "Account", "Amount", "Transaction Ref", "Fee", "Balance Before", "Balance After", "Details", ""]}>
              {isArray(tableData.data) && renderTransactionsTable(tableData.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) &&
                renderTransactionsDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={transaction.isLoading}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
    </div>
  );
}
