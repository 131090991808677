import React, { useEffect, useState } from "react";
import TextInput from "../../inputs/TextInput";
import ReceiverFlag from "../../../components/transfer/ReceiverFlag";
import { RiArrowDropDownLine } from "react-icons/ri";
import Button from "../../buttons/Button";
import { IoIosClose } from "react-icons/io";
import useUser from "../../../hooks/useUser";
import { isArray } from "@craco/craco/lib/utils";
import useAvailableCurrencies from "../../../hooks/useCurrencies";
import { useDispatch } from "react-redux";
import { getAvailableCurrencies } from "../../../redux/addCurrency/addCurrencyActions";
import { getAllIFAccountCreatedAccount } from "../../../redux/balances/balancesActions";
import useBalances from "../../../hooks/useBalances";

const CurrencySelectionField = ({
  onSelectionChange,
  defaultValue,
  handleClick,
  displayButton,
  title,
  displayWalletCurrencies = true,
}) => {
  const user = useUser();
  const { allIFAccountData } = useBalances();
  const currencies = useAvailableCurrencies();
  const dispatch = useDispatch();
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [currenciesArray, setCurrenciesArray] = useState([]);
  const [defaultCurrencyArray, setDefaultCurrencyArray] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [input, setInput] = useState({
    currency: defaultValue,
    filter: "",
    is_wallet: null,
  });
  useEffect(() => {
    dispatch(getAvailableCurrencies());
    dispatch(getAllIFAccountCreatedAccount());
  }, []);

  useEffect(() => {
    let existingCurrencies = isArray(
      user?.selectedBusinessOrPersonalAccount?.accounts
    )
      ? user?.selectedBusinessOrPersonalAccount?.accounts?.map((account) =>
          account?.currency?.toLowerCase()
        )
      : ["ngn"];
    const currenciesToDeleteSet = new Set(existingCurrencies);
    const unusedCurrencies = currencies?.availableCurrencies?.filter((name) => {
      return !currenciesToDeleteSet.has(name?.destination_currency);
    });

    const currencyArray = unusedCurrencies?.filter(
      (curr) =>
        !allIFAccountData?.some(
          (cur) =>
            curr?.destination_currency?.toLowerCase() ===
            cur?.currency?.toLowerCase()
        )
    );

    setCurrenciesArray(currencyArray);
    setDefaultCurrencyArray(currencyArray);
  }, [searchDropDown]);

  useEffect(() => {
    let bankAccountsData = currenciesArray.filter(
      (field) => field.is_wallet === 0
    );
    setBankAccounts(bankAccountsData);
  }, [currenciesArray]);

  return (
    <div className="w-full mt-9">
      {!searchDropDown ? (
        <button
          className="flex items-center rounded p-2 border justify-between relative w-full"
          onClick={() => setSearchDropDown(!searchDropDown)}
        >
          {!input?.currency ? (
            <div className="glade-grey-dark">{title}</div>
          ) : (
            <div className="flex gap-3 glade-grey-dark items-center">
              <ReceiverFlag flagCode={input.currency.slice(0, -1)} />
              <div>{input.currency.toUpperCase()}</div>
            </div>
          )}
          <RiArrowDropDownLine
            size={"28px"}
            className="cursor-pointer"
            color="#000000"
          />
        </button>
      ) : (
        <div>
          <div className="items-center relative">
            <TextInput
              value={input?.filter}
              placeholder={"Search Currency"}
              onChange={(e) => {
                setInput({ ...input, filter: e.target.value });
                let filteredArray = [];
                if (e.target.value) {
                  currenciesArray.forEach((filter, i) => {
                    if (
                      e.target.value &&
                      filter.destination_currency
                        ?.toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    ) {
                      filteredArray.push(currenciesArray[i]);
                    } else if (
                      e.target.value &&
                      !filter.destination_currency
                        .toUpperCase()
                        .indexOf(e.target.value.toUpperCase() < 0) > -1
                    ) {
                      // return setCurrenciesArray([]);
                    }
                    setCurrenciesArray(filteredArray);
                  });
                } else {
                  return setCurrenciesArray(defaultCurrencyArray);
                }
              }}
            />
            <IoIosClose
              size={30}
              className="top-1 right-2 absolute"
              onClick={() => {
                setInput({ ...input, filter: "" });
                setCurrenciesArray(defaultCurrencyArray);
                setSearchDropDown(!searchDropDown);
              }}
            />
          </div>
          <div>
            <div className="flex flex-col gap-2 p-2 top-2 bg-white glade-grey-dark border max-h-72 overflow-y-auto">
              {bankAccounts.length > 0 && (
                <div className="font-bold my-2">Bank Accounts</div>
              )}
              {currenciesArray.map((field, index) => (
                <>
                  {field.is_wallet === 0 && (
                    <div
                      className="flex gap-3 cursor-pointer items-center"
                      key={`bank_${index}`}
                      onClick={() => {
                        setInput({
                          ...input,
                          currency: field.destination_currency.toUpperCase(),
                          is_wallet: field.is_wallet,
                        });
                        onSelectionChange(field);
                        setSearchDropDown(!searchDropDown);
                      }}
                    >
                      <ReceiverFlag
                        flagCode={field.destination_currency.slice(0, -1)}
                      />
                      <div>{field.destination_currency.toUpperCase()}</div>
                    </div>
                  )}
                </>
              ))}
              {displayWalletCurrencies && (
                <>
                  <div className="font-bold mb-2 mt-3">Wallet Currencies</div>
                  {currenciesArray.map((field, index) => (
                    <>
                      {field.is_wallet === 1 && (
                        <div
                          className="flex gap-3 cursor-pointer items-center"
                          key={`wallet_${index}`}
                          onClick={() => {
                            setInput({
                              ...input,
                              currency:
                                field.destination_currency.toUpperCase(),
                              is_wallet: field.is_wallet,
                            });
                            onSelectionChange(field.destination_currency);
                            setSearchDropDown(!searchDropDown);
                          }}
                        >
                          <ReceiverFlag
                            flagCode={field.destination_currency.slice(0, -1)}
                          />
                          <div>{field.destination_currency.toUpperCase()}</div>
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        {input.currency && displayButton && (
          <Button.Blue
            title={"Confirm"}
            type={"button"}
            className={"py-3 mt-10"}
            disabled={!input.currency}
            onClick={() => handleClick(input)}
          />
        )}
      </div>
    </div>
  );
};

export default CurrencySelectionField;
