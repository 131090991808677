import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { objectToFormData } from "../../helpers/helper";
import {
  LOADING_SETTINGS,
  LOADING_COMPLIANCE,
  RELOAD_SETTINGS,

  CREATE_PASS_CODE,
  RESET_PASS_CODE,
  UPDATE_PASS_CODE,
  COMPLETE_PASS_CODE,
  RESET_PASSWORD,
  UPDATE_RESET_PASSWORD,
  COMPLETE_RESET_PASSWORD,

  GET_USER_DETAILS,
  GET_BUSINESS_DETAILS,
  UPDATE_PERSONAL_PROFILE,
  UPDATE_BUSINESS_PROFILE,

  GET_COMPLIANCE_COUNTRIES,
  GET_COMPLIANCE_STATES,
  GET_COMPLIANCE_LGA,
  GET_AGENT_GUARANTOR_TEMPLATE,
  GET_COMPLIANCE_PERSONAL_DOCUMENT_TYPES,
  GET_COMPLIANCE_BUSINESS_DOCUMENT_TYPES,
  GET_COMPLIANCE_IDENTITY_TYPES,
  GET_METAMAP_IDENTITY_TYPES,
  GET_PERSONAL_COMPLIANCES,
  GET_RECENT_PERSONAL_COMPLIANCE,
  GET_BUSINESS_COMPLIANCES,
  GET_RECENT_BUSINESS_COMPLIANCE,
  GET_COMPLIANCE_COUNTRIES_NEW,
  GET_SUBMITTED_PERSONAL_COMPLIANCE_DETAILS,
  GET_SUBMITTED_BUSINESS_COMPLIANCE_DETAILS,
  SUBMIT_PERSONAL_COMPLIANCE,
  SUBMIT_BUSINESS_COMPLIANCE,
  SUBMIT_BUSINESS_INFORMATION,
  SUBMIT_BUSINESS_DOCUMENTS,


  SEND_USER_INVITES,
  VERIFY_USER_INVITES,
  UPDATE_USER_INVITES,
  GET_USER_INVITES,
  RESEND_USER_INVITES,
  DELETE_USER_INVITES,

  GET_SETTLEMENT_ACCOUNTS,
  CREATE_SETTLEMENT_ACCOUNT,
  UPDATE_SETTLEMENT_ACCOUNT,
  DELETE_SETTLEMENT_ACCOUNT,
  GET_SUPPLIED_ACCOUNT_NUMBER_DETAILS,

  GET_ROLES,
  CREATE_ROLES,
  GET_PERMISSIONS,
  UPDATE_ROLE,
  DELETE_ROLE,

  GET_DASHBOARD_FEATURES,
  GET_PAYMENT_METHODS,
  UPDATE_DASHBOARD_FEATURES,
  UPDATE_PAYMENT_METHODS,

  GET_STATUS_OF_AGENT_REQUEST,
  SUBMIT_AGENT_REQUEST,

    GET_MERCHANT_CREDENTIALS,
    GET_MERCHANT_WEBHOOK,
    CREATE_OR_UPDATE_MERCHANT_WEBHOOK,
    GET_DEVELOPER_WEB_PAGE_LINK,
    GUARANTOR_FORM_SUCCESS,
    GENERATE_NEW_KEY,
    GET_COMPLIANCE_STATES_NEW,
    GET_REFERRAL_DETAILS,
    GET_REFERRAL_REWARDS_BY_YEAR,
    GET_REFERRAL_YEARS,
    LOADING_DASHBOARD_FEATURES,
    DASHBOARD_FEATURES_FAILURE
} from "./types";

/**
 * Section For Settings Profile
 */

/**
 * Create pass code
 *
 * @param {object} payLoad {"user_uuid" : "","passcode" : ""}
 * @returns {void}
 */
export const createPassCode = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SETTINGS, payLoad: true });
    Axios.post(`auth/passcode`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_SETTINGS, payLoad: false });
        dispatch({ type: CREATE_PASS_CODE, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SETTINGS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Reset pass code
 *
 * @param {object} payLoad {"user_uuid" : ""}
 * @returns {void}
 */
export const resetPassCode = (payLoad) => {
  return (dispatch) => {
    Axios.post(`auth/reset/passcode`, payLoad)
      .then((res) => {
        dispatch({ type: RESET_PASS_CODE, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update pass code
 *
 * @param {object} payLoad {"user_uuid" : "","old_passcode" : "","new_passcode" : ""}
 * @returns {void}
 */
export const updatePassCode = (payLoad) => {
  return (dispatch) => {
    Axios.put(`auth/passcode`, payLoad)
      .then((res) => {
        dispatch({ type: UPDATE_PASS_CODE, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Complete pass code reset
 *
 * @param {object} payLoad {"user_uuid" : "","verification_code": "","new_passcode" : ""}
 * @returns {void}
 */
export const completePassCodeReset = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    Axios.put(`auth/reset/passcode`, payLoad)
      .then((res) => {
        dispatch({ type: COMPLETE_PASS_CODE, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get user details
 *
 * @returns {void}
 */
export const getUserDetails = () => {
  return (dispatch) => {
    Axios.get(`auth/me`)
      .then((res) => {
        dispatch({ type: GET_USER_DETAILS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
};

/**
 * Reset password
 *
 * @param {object} payLoad {"email" : ""}
 * @returns {void}
 */
export const resetPassword = (payLoad) => {
  return (dispatch) => {
    Axios.post(`reset/password`, payLoad)
      .then((res) => {
        dispatch({ type: RESET_PASSWORD, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update password
 *
 * @param {object} payLoad {"user_uuid" : "", "old_password" : "", "new_password" : ""}
 * @returns {void}
 */
export const updatePassword = (payLoad) => {
  return (dispatch) => {
    Axios.put(`auth/password`, payLoad)
      .then((res) => {
        dispatch({ type: UPDATE_RESET_PASSWORD, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Complete reset password
 *
 * @param {object} payLoad {"email" : "", "verification_code" : "", "new_password" : ""}
 * @returns {void}
 */
export const completeResetPassword = (payLoad) => {
  return (dispatch) => {
    Axios.put(`reset/password`, payLoad)
      .then((res) => {
        dispatch({ type: COMPLETE_RESET_PASSWORD, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Business Details
 *
 * @returns {void}
 */
export const getBusinessDetails = () => {
  return (dispatch) => {
    Axios.get(`businesses/details`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_DETAILS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update personal details
 * 
 * @param {object} payLoad { 
    "first_name" : "", 
    "last_name" : "", 
    "phone_number" : "",
    "email" : "", 
    "password" : "",
    "pin" : "",
 }
 * @returns {void}
*/
export const updatePersonalProfile = (payLoad) => {
  return (dispatch) => {
    Axios.put(`auth/update-details`, payLoad)
      .then((res) => {
        dispatch({ type: UPDATE_PERSONAL_PROFILE, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

export const getBAAFile = () => {
  return (dispatch) => {
    Axios.get("agent/guarantor-form")
      .then((res) => {
        const name = res?.data?.data[0]["display_name"];
        const docUrl = res?.data?.data[0]["value"];

        fetch(docUrl)
          .then((response) => {
            response.arrayBuffer().then((buffer) => {
              let url = window.URL.createObjectURL(new Blob([buffer]));
              let link = document.createElement("a");
              link.href = url;

              link.setAttribute("download", `${name}.pdf`); //or any other extension
              document.body.appendChild(link);
              link.click();
              successHandler(res, true);
            });
          })
          .catch((err) => {
            errorHandler(err, true);
          });
        dispatch({ type: GUARANTOR_FORM_SUCCESS, payLoad: res?.data.data });
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update business details
 * 
 * @param {object} payLoad { 
    "business_name" : "", 
    "business_address" : "", 
    "business_email" : "", 
    "contact_phone" : "", 
 }
 * @returns {void}
*/
export const updateBusinessProfile = (payLoad) => {
  return (dispatch) => {
    let formData = objectToFormData(payLoad);
    Axios.post(`businesses/update-details`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: UPDATE_BUSINESS_PROFILE, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Section For Settings Compliance
 */

/**
 * Get Compliance Countries
 *
 * @returns {void}
 */
 export const getComplianceCountries = () => {
  return (dispatch) => {
    let res = {
        data: {
            data: [
                {
                    "country_id": '1',
                    "country_code": "NG",
                    "country_name": "Nigeria"
                }
            ]
        }
    }
    dispatch({ type: GET_COMPLIANCE_COUNTRIES, payLoad: res });
}
};

/**
 * Get Countries
 * 
 * @returns {void}
*/
export const getCountries = (callBack=()=>{}) => {
  return (dispatch) => {
    Axios.get(`countries`)
      .then((res) => {
        dispatch({ type: GET_COMPLIANCE_COUNTRIES_NEW, payLoad: res });
        callBack(res.data.data);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  }
}


/**
 * Get States
 * 
 * @param {object} payLoad {"country_id" : ""}
 * @returns {void}
*/
export const getStates = (payLoad) => {
  return (dispatch) => {
    Axios.get(`states/${payLoad.country_id}`)
      .then((res) => {
        dispatch({ type: GET_COMPLIANCE_STATES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  }
}

/**
 * Get Compliance States
 *
 * @param {object} payLoad {"country_id" : ""}
 * @returns {void}
 */
export const getComplianceStates = (payLoad) => {
  return (dispatch) => {
    Axios.get(`go-live/lookup/country/${payLoad.country_id}/states`)
      .then((res) => {
        dispatch({ type: GET_COMPLIANCE_STATES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Compliance States (New)
 *
 * @param {object} payLoad {"country_id" : ""}
 * @returns {void}
 */
 export const getComplianceStatesNew = (payLoad) => {
    return (dispatch) => {
      Axios.get(`states/${payLoad.country_id}`)
        .then((res) => {
          dispatch({ type: GET_COMPLIANCE_STATES_NEW, payLoad: res });
          successHandler(res);
        })
        .catch((error) => {
          errorHandler(error, true);
        });
    };
  };

/**
 * Get Compliance LGA
 *
 * @param {object} payLoad {"state_id" : ""}
 * @returns {void}
 */
export const getComplianceLga = (payLoad) => {
  return (dispatch) => {
    Axios.get(`go-live/lookup/country/{country_id}/${payLoad.state_id}/lgas`)
      .then((res) => {
        dispatch({ type: GET_COMPLIANCE_LGA, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Agent Guarantor Form Template
 *
 * @returns {void}
 */
export const getGuarantorTemplate = () => {
  return (dispatch) => {
    Axios.get(`agent/guarantor-form`)
      .then((res) => {
        dispatch({ type: GET_AGENT_GUARANTOR_TEMPLATE, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Compliance Document Types
 *
 * @param {object} payLoad {"type" : "user"} // types: user, business
 * @returns {void}
 */
export const getComplianceDocumentType = (payLoad) => {
  return (dispatch) => {
    Axios.get(`compliance/document-types/${payLoad.type}`)
      .then((res) => {
        if (payLoad.type === "user") {
          dispatch({
            type: GET_COMPLIANCE_PERSONAL_DOCUMENT_TYPES,
            payLoad: res,
          });
        } else {
          dispatch({
            type: GET_COMPLIANCE_BUSINESS_DOCUMENT_TYPES,
            payLoad: res,
          });
        }
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get personal compliance identiy types
 *
 * @param {void}
 * @returns {void}
 */
export const getComplianceIdentityTypes = () => {
  return (dispatch) => {
    Axios.get(`go-live/lookup/identity-types`)
      .then((res) => {
        dispatch({ type: GET_COMPLIANCE_IDENTITY_TYPES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};


/**
 * Get Metamap Identity Types
 *
 * @param {void}
 * @returns {void}
 */
 export const getMetamapIdentityTypes = (payload) => {
  return (dispatch) => {
    Axios.get(`auth/new-signup/kyc/required-documents/${payload.country_code}`)
      .then((res) => {
        dispatch({ type: GET_METAMAP_IDENTITY_TYPES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Personal Compliancies
 *
 * @returns {void}
 */
export const getPersonalCompliancies = () => {
  return (dispatch) => {
    Axios.get(`auth/compliance`)
      .then((res) => {
        dispatch({ type: GET_PERSONAL_COMPLIANCES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Recent Personal Compliancies
 *
 * @returns {void}
 */
export const getPersonalRecentCompliancies = () => {
  return (dispatch) => {
    Axios.get(`auth/recent-compliance`)
      .then((res) => {
        dispatch({ type: GET_RECENT_PERSONAL_COMPLIANCE, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Business Compliancies
 *
 * @returns {void}
 */
export const getBusinessCompliancies = () => {
  return (dispatch) => {
    Axios.get(`businesses/compliance`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_COMPLIANCES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Recent Business Compliancies
 *
 * @returns {void}
 */
export const getBusinessRecentCompliancies = () => {
  return (dispatch) => {
    Axios.get(`businesses/recent-compliance`)
      .then((res) => {
        dispatch({ type: GET_RECENT_BUSINESS_COMPLIANCE, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Personal Compliance Details
 *
 * @param {object} payLoad {"id" : ""}
 * @returns {void}
 */
export const getPersonalComplianceDetail = (payLoad) => {
  return (dispatch) => {
    Axios.get(`auth/compliance/${payLoad.id}`)
      .then((res) => {
        dispatch({
          type: GET_SUBMITTED_PERSONAL_COMPLIANCE_DETAILS,
          payLoad: res,
        });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Business Compliance Details
 *
 * @param {object} payLoad {"id" : ""}
 * @returns {void}
 */
export const getBusinessComplianceDetail = (payLoad) => {
  return (dispatch) => {
    Axios.get(`businesses/compliance/${payLoad.id}`)
      .then((res) => {
        dispatch({
          type: GET_SUBMITTED_BUSINESS_COMPLIANCE_DETAILS,
          payLoad: res,
        });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Submit Compliance for personal account
 *
 * @param {object} payLoad {"id_card":"file","id_card_type":"","id_card_number":"","state":"","lga":"","address":""}
 * @returns {void}
 */
export const submitPersonalCompliance = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_COMPLIANCE, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`auth/compliance`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        dispatch({ type: SUBMIT_PERSONAL_COMPLIANCE, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Submit Compliance for business account
 * 
 * @param {object} payLoad {
    "registered_name":"",
    "rc_number":"",
    "type_of_utility_bill":"",
    "tin_number":"",
    "utility_bill":"file",
    "tin_certificate":"file",
    "cac_certificate":"file",
    "director_page":"file",
 }
 * @returns {void}
*/
export const submitBusinessCompliance = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_COMPLIANCE, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`businesses/compliance`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        dispatch({ type: SUBMIT_BUSINESS_COMPLIANCE, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Submit Compliance business information
 * 
 * @param {object} payLoad {
  business_type: "",
    industry: "",
    business_description: "",
    secondary_phone: "",
    address: "",
    lga: "",
    state: "",
    proof_of_address: {},
 }
 * @returns {void}
*/
export const submitBusinessInformation = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_COMPLIANCE, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`businesses/compliance/business-information`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        dispatch({ type: SUBMIT_BUSINESS_INFORMATION, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        errorHandler(
          {
            ...error,
            message: error?.response?.data?.developer_message?.message,
          },
          true
        );
      });
  };
};

/**
 * Submit Compliance business information Non Nigeria
 * 
 * @param {object} payLoad {
  business_type: "",
    industry: "",
    business_description: "",
    secondary_phone: "",
    address: "",
    lga: "",
    state: "",
    proof_of_address: {},
 }
 * @returns {void}
*/
export const submitBusinessInformationNonNigeria = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_COMPLIANCE, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`businesses/compliance/business-information-non-nigerian`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        dispatch({ type: SUBMIT_BUSINESS_INFORMATION, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        errorHandler(
          {
            ...error,
            message: error?.response?.data?.developer_message?.message,
          },
          true
        );
      });
  };
};

/**
 * Submit Compliance business Documents
 * 
 * @param {object} payLoad {
    tin: "",
    rc_number: "",
    bn_number: "",
    tin_certificate: {},
    business_name_certificate: {},
    certificate_of_incorporation: {},
    business_directors_document: {},
    scuml: {},
    cac_it_form: {},
 }
 * @returns {void}
*/
export const submitBusinessDocuments = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_COMPLIANCE, payLoad: true });
    let formData = objectToFormData(payLoad.reqPayLoad);
    Axios.post(`businesses/compliance/${payLoad.id}/document`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        dispatch({ type: SUBMIT_BUSINESS_DOCUMENTS, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Submit Compliance business Documents Non Nigeria
 * 
 * @param {object} payLoad {
    tin: "",
    rc_number: "",
    bn_number: "",
    tin_certificate: {},
    business_name_certificate: {},
    certificate_of_incorporation: {},
    business_directors_document: {},
    scuml: {},
    cac_it_form: {},
 }
 * @returns {void}
*/
export const submitBusinessDocumentsNonNigeria = (payLoad, callBack = () => { }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_COMPLIANCE, payLoad: true });
    let formData = objectToFormData(payLoad.reqPayLoad);
    Axios.post(`businesses/compliance/${payLoad.id}/non-nigerian-document`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        dispatch({ type: SUBMIT_BUSINESS_DOCUMENTS, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_COMPLIANCE, payLoad: false });
        errorHandler(error, true);
      });
  };
};


/**
 * Section For Settings Compliance
 */

/**
 * Send User Invites
 *
 * @param {object} payLoad {"email": "mark7@gmail.com", "role_id": "1"}
 * @returns {void}
 */
export const sendUserInvites = (payLoad) => {
  return (dispatch) => {
    Axios.post(`businesses/invitations`, payLoad)
      .then((res) => {
        dispatch({ type: SEND_USER_INVITES, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Verify User Invites
 *
 * @param {object} payLoad {"email": "mark7@gmail.com", "invite_code": "321456"}
 * @returns {void}
 */
export const verifyUserInvites = (payLoad) => {
  return (dispatch) => {
    Axios.post(`businesses/invitations/confirm`, payLoad)
      .then((res) => {
        dispatch({ type: VERIFY_USER_INVITES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update User Invites
 *
 * @param {object} payLoad {"invitation_id": "45", "role_id": "2"}
 * @returns {void}
 */
export const updateUserInvites = (payLoad) => {
  return (dispatch) => {
    Axios.post(`businesses/invitations/${payLoad.invitation_id}`, payLoad)
      .then((res) => {
        dispatch({ type: UPDATE_USER_INVITES, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get User Invites
 *
 * @returns {void}
 */
export const getUserInvites = () => {
  return (dispatch) => {
    Axios.get(`businesses/invitations`)
      .then((res) => {
        dispatch({ type: GET_USER_INVITES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Resend User Invites
 *
 * @param {object} payLoad {"invitation_id": "45"}
 * @returns {void}
 */
export const resendUserInvites = (payLoad) => {
  return (dispatch) => {
    Axios.post(`businesses/invitations/resend/${payLoad.invitation_id}`)
      .then((res) => {
        dispatch({ type: RESEND_USER_INVITES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Delete User Invites
 *
 * @param {object} payLoad {"invitation_id": "45"}
 * @returns {void}
 */
export const deleteUserInvites = (payLoad) => {
  return (dispatch) => {
    Axios.delete(`businesses/invitations/${payLoad.invitation_id}`)
      .then((res) => {
        dispatch({ type: DELETE_USER_INVITES, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Section For Settings Settlements
 */

/**
 * Get settlement accounts
 *
 * @returns {void}
 */
export const getSettlementAccounts = () => {
  return (dispatch) => {
    Axios.get(`banking/settlement-accounts`)
      .then((res) => {
        dispatch({ type: GET_SETTLEMENT_ACCOUNTS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Create settlement account
 * 
 * @param {object} payLoad {
    "currency": "NGN",
    "account_number": "1789896888",
    "bank_code": "058",
    "status": 1, // This indicates if this will be the default account or not
    "account_name": "John Doesa",
    "bank_name": "Accessa Bank"
 }
 * @returns {void}
*/
export const createSettlementAccount = (payLoad) => {
  return (dispatch) => {
    Axios.post(`banking/settlement-accounts`, payLoad)
      .then((res) => {
        dispatch({ type: CREATE_SETTLEMENT_ACCOUNT, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update settlement account
 * 
 * @param {object} payLoad {
    "currency": "NGN",
    "account_number": "1234567890",
    "account_name": "Bruce Wayne",
    "bank_code": "058",
    "bank_name": "Guarantee Trust Bank",
    "status": 1,
    "id": 1
 }
 * @returns {void}
 */
export const updateSettlementAccount = (payLoad) => {
  return (dispatch) => {
    Axios.put(`banking/settlement-accounts/${payLoad.id}`, payLoad)
      .then((res) => {
        dispatch({ type: UPDATE_SETTLEMENT_ACCOUNT, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Delete settlement account
 *
 * @param {object} payLoad {"id": 1}
 * @returns {void}
 */
export const deleteSettlementAccount = (payLoad) => {
  return (dispatch) => {
    Axios.delete(`banking/settlement-accounts/${payLoad.id}`, payLoad)
      .then((res) => {
        dispatch({ type: DELETE_SETTLEMENT_ACCOUNT, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get supplied account
 *
 * @param {object} payLoad {"account_number":"123456789"}
 * @returns {void}
 */
export const getSuppliedAccountDetailByAccountNumber = (payLoad) => {
  return (dispatch) => {
    Axios.get(
      `banking/settlement-account-number/${payLoad.account_number}`,
      payLoad
    )
      .then((res) => {
        dispatch({ type: GET_SUPPLIED_ACCOUNT_NUMBER_DETAILS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Section For Settings Roles
 */

/**
 * Get Roles
 *
 * @returns {void}
 */
export const getRoles = () => {
  return (dispatch) => {
    Axios.get(`businesses/roles`)
      .then((res) => {
        dispatch({ type: GET_ROLES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Create Roles
 *
 * @param {object} payLoad {"role_title": "Third_role","permissions": [2, 3, 4, 5]}
 * @returns {void}
 */
export const createRoles = (payLoad) => {
  return (dispatch) => {
    Axios.post(`businesses/roles`, payLoad)
      .then((res) => {
        dispatch({ type: CREATE_ROLES, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Permissions
 *
 * @returns {void}
 */
export const getPermissions = () => {
  return (dispatch) => {
    Axios.get(`businesses/permissions`)
      .then((res) => {
        dispatch({ type: GET_PERMISSIONS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update Role
 *
 * @param {object} payLoad {"id":"5","role_title": "Third_role","permissions": [2, 3, 4, 5]}
 * @returns {void}
 */
export const updateRole = (payLoad) => {
  return (dispatch) => {
    Axios.post(`businesses/edit-role/${payLoad.id}`)
      .then((res) => {
        dispatch({ type: UPDATE_ROLE, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Delete Role
 *
 * @param {object} payLoad {"id":"5"}
 * @returns {void}
 */
export const deleteRole = (payLoad) => {
  return (dispatch) => {
    Axios.post(`businesses/delete-role/${payLoad.id}`)
      .then((res) => {
        dispatch({ type: DELETE_ROLE, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get dashboard features
 *
 * @returns {void}
 */
export const getDashboardFeatures = () => {
  return (dispatch) => {
    dispatch({type: LOADING_DASHBOARD_FEATURES});
    Axios.get(`services/business/dashboard-features`)
      .then((res) => {
        dispatch({ type: GET_DASHBOARD_FEATURES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({type: DASHBOARD_FEATURES_FAILURE});
        errorHandler(error, true);
      });
  };
};

/**
 * Get payment methods
 *
 * @returns {void}
 */
export const getPaymentMethods = () => {
  return (dispatch) => {
    Axios.get(`services/business/payment-methods`)
      .then((res) => {
        dispatch({ type: GET_PAYMENT_METHODS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update dashboard features
 * 
 * @param {object} payLoad {
    "payment_inflow": 1,
    "settlements": 0,
    "customers": 1,
    "payment_pages": 0,
    "transaction_split": 1,
    "buy_airtime": 1,
    "pay_bills": 1,
    "payroll": 0,
    "loans": 0,
    "pos": 0,
    "store": 0,
    "invoices": 0
 }
 * @returns {void}
*/
export const updateDashboardFeatures = (payLoad) => {
  return (dispatch) => {
    Axios.post(`services/business/dashboard-features`, payLoad)
      .then((res) => {
        dispatch({ type: UPDATE_DASHBOARD_FEATURES, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Update payment methods
 * 
 * @param {object} payLoad {
    "card": 1,
    "bank": 1,
    "ussd": 0,
    "qr": 0,
    "phone": 0,
    "mobile_money": 0,
    "banktransfer": 1
 }
 * @returns {void}
*/
export const updatePaymentMethods = (payLoad) => {
  return (dispatch) => {
    Axios.post(`services/business/payment-methods`, payLoad)
      .then((res) => {
        dispatch({ type: UPDATE_PAYMENT_METHODS, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get status of agent registration
 *
 * @returns {void}
 */
export const getStatusOfAgentRequest = () => {
  return (dispatch) => {
    Axios.get(`agent/status`)
      .then((res) => {
        dispatch({ type: GET_STATUS_OF_AGENT_REQUEST, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Submit agent registration
 *
 * @params {object} payLoad {"address": "yaba","state_id": "37","lga_id": "744","guarantor_document":"file"}
 * @returns {void}
 */
export const submitAgentRequest = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SETTINGS, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`agent`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_SETTINGS, payLoad: false });
        dispatch({ type: SUBMIT_AGENT_REQUEST, payLoad: res });
        dispatch({ type: RELOAD_SETTINGS });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SETTINGS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get Merchant Credentials
 *
 * @returns {void}
 */
export const getMerchantCredentials = () => {
  return (dispatch) => {
    Axios.get(`webhook/credentials`)
      .then((res) => {
        dispatch({ type: GET_MERCHANT_CREDENTIALS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Merchant Webhook
 *
 * @returns {void}
 */
export const getMerchantWebhook = () => {
  return (dispatch) => {
    Axios.get(`webhook/webhook`)
      .then((res) => {
        dispatch({ type: GET_MERCHANT_WEBHOOK, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Create Or Update Merchant Webhook
 *
 * @param {object} payLoad {"webhook_url": "https://new_webhosok_url.com","status": 1}
 * @returns {void}
 */
export const createOrUpdateMerchantWebhook = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SETTINGS, payLoad: true });
    Axios.post(`webhook/webhook`, payLoad)
      .then((res) => {
        dispatch({ type: CREATE_OR_UPDATE_MERCHANT_WEBHOOK, payLoad: res });
        dispatch({ type: LOADING_SETTINGS, payLoad: false });
        successHandler(res, true);
      })
      .catch((error) => {
        dispatch({ type: LOADING_SETTINGS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get developer webpage link
 *
 * @returns {void}
 */
export const getDeveloperWebPageLink = () => {
  return (dispatch) => {
    Axios.get(`/webhook/developer`)
      .then((res) => {
        dispatch({ type: GET_DEVELOPER_WEB_PAGE_LINK, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Generate New Key
 * 
 * @returns {void}
*/
export const generateNewKey = () => {
  return (dispatch) => {
      Axios.put(`/webhook/reset-credentials`)
          .then((res) => {
              dispatch({ type: GENERATE_NEW_KEY, payLoad: res });
              successHandler(res);
          })
          .catch((error) => {
              errorHandler(error, true);
          });
  }
}

/**
 * Get Referral Details
 *
 * @returns {void}
 */
export const getReferralDetails = () => {
  return (dispatch) => {
    Axios.get(`referral/get-referral-details`)
      .then((res) => {
        dispatch({ type: GET_REFERRAL_DETAILS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Referral Rewards Breakdown by Year
 *
 * @param {object} payLoad {"year":"2022"}
 * @returns {void}
 */
export const getReferralRewardsByYear = (payLoad) => {
  return (dispatch) => {
    Axios.get(`referral/get-referral-breakdown/${payLoad.year}`, payLoad)
      .then((res) => {
        dispatch({ type: GET_REFERRAL_REWARDS_BY_YEAR, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get Referral Years
 *
 * @returns {void}
 */
export const getReferralYears = () => {
  return (dispatch) => {
    Axios.get(`referral/get-referral-years`)
      .then((res) => {
        dispatch({ type: GET_REFERRAL_YEARS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};
