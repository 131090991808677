import jsPDF from "jspdf";
import "jspdf-autotable";
import "rasterizehtml";
import * as html2canvas from "html2canvas";
import { GLADE_FINANCE_BLUE_PNG } from "../../assets/images/images";
import { simpleDate } from "../../helpers/helper";

window.html2canvas = html2canvas;
var LogoImage = new Image();
LogoImage.src = GLADE_FINANCE_BLUE_PNG;

export function downloadCrossBorderTransferReceiptAsPDF(receiptInfo) {
  const fileName = `Glade Receipt - ${receiptInfo.currency} Transfer`;
  const receipt = `
    <div style="margin: 0; font-size: 16pt; width: 595pt; height: 842pt">
        <div style="padding: 60pt;">
            <div style="display: flex; flex-direction: row; justify-content: center; align-items:center">
                <img src="${GLADE_FINANCE_BLUE_PNG}" alt="logo" height="60pt" width="150pt" style="object-fit: cover;">
            </div>
            <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#afafaf; padding-top: 30pt; padding-bottom: 30pt;">
                <tr style="display: flex; flex-direction: column; justify-content: center;">
                    <tr style="display: flex; flex-direction: row; justify-content: center;">
                        <td style="font-family: Inter; font-style: normal; font-weight: bold; font-size: 16pt; line-height: 30pt;">Transaction Receipt</td>
                    </tr>
                </tr>
            </table>
            <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#afafaf; padding-top: 30pt; padding-bottom: 30pt;">
                <tbody style="display: flex; flex-direction: column;">
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Transaction Reference</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${
                          receiptInfo?.txn_ref
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Amount</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${
                          receiptInfo?.currency + " " + receiptInfo?.value
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Fee</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${
                          receiptInfo?.currency + " " + receiptInfo?.fee ?? 0
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Sender</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; text-transform: capitalize;">${
                          receiptInfo?.provider
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Recipient</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${
                          receiptInfo?.beneficiary_name
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Recipients Bank</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${
                          receiptInfo?.bank_name
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style = "font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Recipients ${
                          receiptInfo.method === "mobilemoney"
                            ? "Phone"
                            : "Account"
                        } Number</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${
                          receiptInfo?.beneficiary_account
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Transfer Method</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; text-transform: capitalize;">${
                          receiptInfo?.method
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Status</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; text-transform: capitalize;">${
                          receiptInfo?.status
                        }</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Date</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${simpleDate(
                          receiptInfo?.created_at
                        )}</td>
                    </tr>
                    <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485;">Narration</td>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt;">${
                          receiptInfo?.narration ?? "None"
                        }</td>
                    </tr>
                </tbody>
            </table>
            <table style="display: flex; flex-direction: column; padding-top: 30pt; padding-bottom: 30pt;">
                <tfoot>
                    <tr>
                        <td style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14pt; line-height: 20pt; color: #717485; text-align: center;">
                            Disclaimer
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14pt; line-height: 20pt; color: #717485; text-align: center;">
                            This is a system generated receipt and does not require signature.
                            Your Transfer has been successful and the beneficiary's account will be credited.
                            Should this document be falsified or manipulated, GLADE INC. will not be held responsible.
                            For any enquiries, please contact Glade's customer care team via email at support@gladefinance.co
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    `;

  const exportToPDF = () => {
    const unit = "pt";
    const size = [430, 520]; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    let doc = new jsPDF(orientation, unit, size);
    doc.setTextColor("#4D4D4D");
    doc.setFontSize(12);

    doc.html(receipt, {
      callback: function (doc) {
        doc.save(`${fileName}.pdf`);
      },
      html2canvas: {
        scale: 0.55,
      },
      width: 560,
    });
  };

  return !receiptInfo || !receiptInfo.txn_ref ? null : exportToPDF();
}

export function downloadFundTransferReceiptAsPDF(details) {
  const fileName = "Glade Receipt";
  const currentYear = new Date().getFullYear();
  const currencyText =
    details?.destination === "local" ||
    details?.destination === "foreign" ||
    details?.currency === "NGN"
      ? "Sender's Account Name"
      : "Sender's Wallet Name";
  const currencyId =
    (details?.destination === "local" || details?.destination === "foreign" || details?.txn_source === "transfer")
      ? "Account Number"
      : "Wallet ID";

  const showSessionId =
    details?.currency === "NGN" &&
    details?.bank_name !== "Gladefinance" &&
    details?.sender_bank === "Gladefinance";

  const body = `
  <div>
    <div style="margin: 0 auto; font-size: 16pt; width: 600pt; max-height: 842pt; font-family: Arial, Helvetica, sans-serif; ">
    <div style="padding: 20pt 60pt 0 60pt; background-color:#FFFFFFF;">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items:center">
            <img src="${GLADE_FINANCE_BLUE_PNG}" alt="logo" height="30" width="279" style="object-fit: cover;">
        </div>
        <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#EBEBF2; padding-top: 8pt; padding-bottom: 20pt;">
            <tr style="display: flex; flex-direction: column; justify-content: center;">
                <tr style="display: flex; flex-direction: row; justify-content: center;">
                    <td style="font-style: normal; font-weight: 400; font-size: 16pt; line-height: 25pt; color:#303030;">Transaction Receipt</td>
                </tr>
            </tr>
        </table>
        <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#EBEBF2; padding-top: 10pt; padding-bottom: 20pt;">
            <tbody style="display: flex; flex-direction: column;">
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-family: Arial, Helvetica, sans-serif; font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Transaction Reference</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt; color:#131313;">${
                      details?.txn_ref
                    }</td>
                </tr>
                  <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                  <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">${
                    details?.transfer_type ? "Transfer Type" : " "
                  }</td>
                  <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                    details?.transfer_type ? details?.transfer_type : " "
                  }</td>
              </tr>
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Amount</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">
                      ${details?.currency ? details?.currency : "NGN" } ${details?.value}
                    </td>
                </tr>
                ${
                  details?.fee
                    ? `
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Fee</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">
                      ${details?.currency ? details?.currency : "NGN"} ${details?.fee}
                    </td>
                </tr>
                `
                    : ""
                }

                ${
                  details?.destination === "local"
                    ? ` 
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">${currencyText}</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                      details?.sender_name || "null"
                    }</td>
                </tr> `
                    : ""
                }

                ${details?.sender_bank ? 
                `<tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Sender's Institution</td>
                    <td style="font-style: normal; font-weight:600; font-size: 14pt; line-height: 18pt;">${details?.sender_bank}</td>
                </tr>` 
                : ""}

                ${
                  details?.destination !== "local" && details?.sender_account_number
                    ? ` 
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">${currencyId}</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                      details?.sender_account_number || "-"  
                    }</td>
                </tr> `
                    : ""
                }

            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Recipient's Name</td>
                <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                  details?.beneficiary_name || details?.receipient_or_creditor
                }</td>
            </tr>
                
               
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Recipient's ${
                      details.transfer_type === "mobilemoney"
                        ? "Provider"
                        : "Bank"
                    }</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                      details?.bank_name || details?.receipient_or_creditor_institution
                    }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Recipient's ${
                  details.transfer_type === "mobilemoney"
                    ? "Phone Number"
                    : `${currencyId}`
                } </td>
                <td style=" font-style: normal; font-weight: 600;font-size: 14pt; line-height: 18pt;">${
                  details?.beneficiary_account || details?.transfer_reciepient_account_number
                }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
            <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Status</td>
            <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt; color: ${
              details?.status === "successful"
                ? "#00D220"
                : details?.status === "pending"
                ? "#FF6F00"
                : "#FF0021"
            }; text-transform:capitalize;">
                ${details?.status}
            </td>
        </tr>
        ${
          details?.session_id
            ? ` 
        <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
        <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">${
          showSessionId ? "Session ID" : ""
        }</td>
        <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
          showSessionId ? details?.session_id : ""
        }</td>
    </tr>  `
            : ""
        }
    
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Date</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${simpleDate(
                      details?.created_at
                    )}</td>
                </tr>
                <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Narration</td>
                    <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                      details?.narration ?? "None"
                    }</td>
                </tr>
            </tbody>
        </table>
      
    </div>
      <table style="display: flex; flex-direction: column; padding-top: 20pt;">
            <tfoot>
                <tr>
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">
                        <div style="margin-bottom: 20pt; padding: 0pt 60pt 0 60pt;">
                        If you have any enquiries about this transaction, view our <a href="https://gladefinance.co/faqs/" style="text-decoration: none; color: #2C6DEA ">FAQs</a>  or contact us at <a href="mailto:support@gladefinance.co" style="text-decoration: none; color: #2C6DEA ">support@gladefinance.co</a>.
                  </div>
                    </td>
                </tr>
                <tr style="background-color: #FAFAFA;">
                    <td style="font-style: normal; font-weight: normal; font-size: 14pt; line-height: 18pt; color: #717485; text-align: center;">
                     <div style="margin-top: 25pt; margin-bottom: 5pt;">All-in-one business finance solution</div>   
                     <div style="margin-bottom: 20pt;">206 Middletown, New Castle Delaware 19709 USA</div>
                     <div">© Gladefinance ${currentYear}. All Rights Reserved</div>
                    </td>
                 
                   
                </tr>
            </tfoot>
        </table>
</div> 
</div>
    `;

  const exportToPDF = () => {
    const unit = "pt";
    const size = [430, 520]; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    let doc = new jsPDF(orientation, unit, size);
    doc.setTextColor("#4D4D4D");
    doc.setFontSize(12);

    doc.html(body, {
      callback: function (doc) {
        doc.save(`${fileName}.pdf`);
      },
      html2canvas: {
        scale: 0.55,
      },
      width: 560,
    });
  };

  return !details || !details.txn_ref ? null : exportToPDF();
}
export function downloadCreditReceiptAsPDF(details) {
  const fileName = "Glade Credit Receipt";
  const currentYear = new Date().getFullYear();

  const showSessionId =
    details?.currency === "NGN" &&
    details?.bank_name !== "Gladefinance" &&
    details?.sender_bank === "Gladefinance";

  const body = `
  <div>
    <div style="margin: 0 auto; font-size: 16pt; width: 600pt; max-height: 842pt; font-family: Arial, Helvetica, sans-serif; ">
    <div style="padding: 20pt 60pt 0 60pt; background-color:#FFFFFFF;">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items:center">
            <img src="${GLADE_FINANCE_BLUE_PNG}" alt="logo" height="30" width="279" style="object-fit: cover;">
        </div>
        <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#EBEBF2; padding-top: 8pt; padding-bottom: 20pt;">
        
            <tr style="display: flex; flex-direction: column; justify-content: center;">
                <tr style="display: flex; flex-direction: row; justify-content: center;">
                    <td style="font-style: normal; font-weight: 400; font-size: 16pt; line-height: 25pt; color:#303030;">Transaction Receipt</td>
                </tr>
            </tr>
        </table>
        <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#EBEBF2; padding-top: 10pt; padding-bottom: 20pt;">
            <tbody style="display: flex; flex-direction: column;">
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Date</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${simpleDate(
                details?.created_at
              )}</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Transaction reference</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                details?.txn_ref
              }</td>
            </tr>    
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Amount</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                details?.currency + " " + details?.value
              }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">Balance Before</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.currency ?? "NGN"
              } 
                      ${Number(details?.balance_before).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
              </td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">Balance After</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.currency ?? "NGN"
              } 
                      ${Number(details?.balance_after).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
              </td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">Type</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.type
              }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">Sender</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.receipient_or_creditor
              }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">Sender's Institution</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.receipient_or_creditor_institution
              }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Status</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt; color: ${
                details?.status === "successful"
                  ? "#00D220"
                  : details?.status === "pending"
                  ? "#FF6F00"
                  : "#FF0021"
              }; text-transform:capitalize;">
                  ${details?.status}
              </td>
            </tr>
            ${
              details?.session_id
                ? ` 
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">${
                showSessionId ? "Session ID" : ""
              }</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                showSessionId ? details?.session_id : ""
              }</td>
            </tr>  `
                : ""
            }
            <tr style="display: flex; flex-direction: row; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485; padding-right: 2pt">Narration</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                details?.narration ?? "None"
              }</td>
            </tr>
            </tbody>
        </table>
      
    </div>
      <table style="display: flex; flex-direction: column; padding-top: 20pt;">
            <tfoot>
                <tr>
                    <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">
                        <div style="margin-bottom: 20pt; padding: 0pt 60pt 0 60pt;">
                        If you have any enquiries about this transaction, view our <a href="https://gladefinance.co/faqs/" style="text-decoration: none; color: #2C6DEA ">FAQs</a>  or contact us at <a href="mailto:support@gladefinance.co" style="text-decoration: none; color: #2C6DEA ">support@gladefinance.co</a>.
                  </div>
                    </td>
                </tr>
                <tr style="background-color: #FAFAFA;">
                    <td style="font-style: normal; font-weight: normal; font-size: 14pt; line-height: 18pt; color: #717485; text-align: center;">
                     <div style="margin-top: 25pt; margin-bottom: 5pt;">All-in-one business finance solution</div>   
                     <div style="margin-bottom: 20pt;">206 Middletown, New Castle Delaware 19709 USA</div>
                     <div style="margin-bottom: 4pt;">© Gladefinance ${currentYear}. All Rights Reserved</div>
                    </td>
                 
                   
                </tr>
            </tfoot>
        </table>
        </div>
</div> 
</div>
    `;

  const exportToPDF = () => {
    const unit = "pt";
    const size = [430, 520]; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    let doc = new jsPDF(orientation, unit, size);
    doc.setTextColor("#4D4D4D");
    doc.setFontSize(12);

    doc.html(body, {
      callback: function (doc) {
        doc.save(`${fileName}.pdf`);
      },
      html2canvas: {
        scale: 0.55,
      },
      width: 560,
    });
  };

  return !details || !details.txn_ref ? null : exportToPDF();
}
export function downloadAirtimeReceiptAsPDF(details) {
  const fileName = "Gladefinance Bill Receipt";
  const currentYear = new Date().getFullYear();

  const showSessionId =
    details?.currency === "NGN" &&
    details?.bank_name !== "Gladefinance" &&
    details?.sender_bank === "Gladefinance";

  const body = `
  <div>
    <div style="margin: 0 auto; font-size: 16pt; width: 600pt; max-height: 842pt; font-family: Arial, Helvetica, sans-serif; ">
    <div style="padding: 20pt 60pt 0 60pt; background-color:#FFFFFFF;">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items:center">
            <img src="${GLADE_FINANCE_BLUE_PNG}" alt="logo" height="30" width="279" style="object-fit: cover;">
        </div>
        <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#EBEBF2; padding-top: 8pt; padding-bottom: 20pt;">
        
            <tr style="display: flex; flex-direction: column; justify-content: center;">
                <tr style="display: flex; flex-direction: row; justify-content: center;">
                    <td style="font-style: normal; font-weight: 400; font-size: 16pt; line-height: 25pt; color:#303030;">Transaction Receipt</td>
                </tr>
            </tr>
        </table>
        <table style="display: flex; flex-direction: column; border-style:solid; border-width:0pt 0pt 1pt 0pt; border-color:#EBEBF2; padding-top: 10pt; padding-bottom: 20pt;">
            <tbody style="display: flex; flex-direction: column;">
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Date</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${simpleDate(
                details?.created_at
              )}</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Transaction reference</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                details?.txn_ref
              }</td>
            </tr>    
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Amount</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                details?.currency +
                " " +
                details?.value.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">Type</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.type
              }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">${
                details?.narration?.includes("TopUp")
                  ? "Phone Number"
                  : "Bill Number"
              }</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.receipient_or_creditor
              }</td>
            </tr>
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt;font-weight: 400 ; color: #717485;">Provider</td>
              <td style=" font-style: normal; font-size: 14pt; line-height:18pt; font-weight: 600; text-transform: capitalize;">${
                details?.receipient_or_creditor_institution
              }</td>
            </tr>
            ${
              details?.status &&
              `<tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">Status</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt; color: ${
                details?.status === "successful"
                  ? "#00D220"
                  : details?.status === "pending"
                  ? "#FF6F00"
                  : "#FF0021"
              }; text-transform:capitalize;">
                  ${details?.status}
              </td>
            </tr>`
            }
            ${
              details?.session_id
                ? ` 
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">${
                showSessionId ? "Session ID" : ""
              }</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                showSessionId ? details?.session_id : ""
              }</td>
            </tr>  `
                : ""
            }
            <tr style="display: flex; flex-direction: row; justify-content: space-between; padding-top: 5pt; padding-bottom: 5pt;">
              <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485; padding-right: 2pt">Narration</td>
              <td style="font-style: normal; font-weight: 600; font-size: 14pt; line-height: 18pt;">${
                details?.narration ?? "None"
              }</td>
            </tr>
            </tbody>
        </table>
      
    </div>
      <table style="display: flex; flex-direction: column; padding-top: 20pt;">
            <tfoot>
            <tr><th></th></tr>
            <tr><th></th></tr>
            <tr><th></th></tr>
            <tr>
                <td style="font-style: normal; font-weight: 400; font-size: 14pt; line-height: 18pt; color: #717485;">
                    <div style="margin-bottom: 20pt; padding: 0pt 60pt 0 60pt;">
                    If you have any enquiries about this transaction, view our <a href="https://gladefinance.co/faqs/" style="text-decoration: none; color: #2C6DEA ">FAQs</a>  or contact us at <a href="mailto:support@gladefinance.co" style="text-decoration: none; color: #2C6DEA ">support@gladefinance.co</a>.
              </div>
                </td>
            </tr>
            <tr style="background-color: #FAFAFA;">
                <td style="font-style: normal; font-weight: normal; font-size: 14pt; line-height: 18pt; color: #717485; text-align: center;">
                  <div style="margin-top: 25pt; margin-bottom: 5pt;">All-in-one business finance solution</div>   
                  <div style="margin-bottom: 20pt;">206 Middletown, New Castle Delaware 19709 USA</div>
                  <div style="margin-bottom: 4pt;">© Gladefinance ${currentYear}. All Rights Reserved</div>
                </td>
            </tr>
            </tfoot>
        </table>
        </div>
</div> 
</div>
    `;

  const exportToPDF = () => {
    const unit = "pt";
    const size = [430, 420]; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    let doc = new jsPDF(orientation, unit, size);
    doc.setTextColor("#4D4D4D");
    doc.setFontSize(12);

    doc.html(body, {
      callback: function (doc) {
        doc.save(`${fileName}.pdf`);
      },
      html2canvas: {
        scale: 0.55,
      },
      width: 560,
    });
  };

  return !details || !details.txn_ref ? null : exportToPDF();
}
