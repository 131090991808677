import { useSelector } from "react-redux";

const useTeam = () => {
    const team = useSelector((state) => state.team);
    
    const {
        isLoadingTeam,
        team_user,
        permissions,
        token,
        refresh_token,
        user_uuid,
        isBusiness,
        business,
        isBusinessUUID,
        ngn_accounts
        
      } = team;

      return {
        isLoadingTeam,
        team_user,
        token,
        refresh_token,
        user_uuid,
        isBusiness,
        business,
        isBusinessUUID,
        ngn_accounts,
        permissions
      }
}
    
export default useTeam;