import React from 'react';
import eur from "./Europe.png"
import gbp from "./gbp.png";
import usa from "./usa.png";


export const getCurrencyName = (country_code) => {
    let flags = {
        'NGN': 'Nigerian Naira',
        'GBP': 'Great Britain Pound',
        'EUR': 'Euro',
        'USA': 'US Dollar',
        'HKD': 'Hong Kong Dollar',
        'CAD': 'Canadian Dollar',
        'INR': 'Indian Rupee',
        'UGX': 'Ugandan Shilling',
        'CHF': 'Swiss Franc',
        'GHS': 'Ghana Cedis',
        'ZAR': 'South African Rand',
        'AUD': 'Australian Dollar',
        'KES': 'Kenyan Shilling',
        'TZS': 'Tanzanian Shilling',
        'RWF': 'Rwandan Franc',
        'USD': 'United States Dollar',
        'CNY': 'Chinese Yuan',





    }
    return flags[`${country_code}`];
}

export function NGNFlag({ size, title, color, children }) {
    return (
        <span title={title}>
            <svg className="rounded-full" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clip-rule="evenodd" d="M-6 0H8.93333V32H-6V0ZM23.8667 0H38.8V32H23.8667V0Z" fill="#00884F" />
            </svg>
            {children}
        </span>
    )
}

export function GBPFlag({ size, title, color, children }) {
    return (
        <span title={title}>
            <img src={gbp} className="rounded-full" alt="Britain Flag" />
            {children}
        </span>
    )
}

export function EURFlag({ size, title, color, children }) {
    return (
        <span title={title}>
            <img src={eur} className="rounded-full" alt="Europe Flag" />
            {children}
        </span>
    )
}

export function USAFlag({ size, title, color, children }) {
    return (
        <span title={title}>
            <img src={usa} className="rounded-full" alt="U.S.A Flag" />
            {children}
        </span>
    )
}

export const getFlag = {
    'NGN':
    {
        'img':
            <NGNFlag />,
        'title': 'Nigerian Naira'
    },
    'GBP':
    {
        'img': <GBPFlag />
        ,
        'title': 'Great Britain Pound'
    },
    'EUR':
    {
        'img': <EURFlag />,
            'title': 'Euro'
    },
    'USA':
    {
        'img': <USAFlag />,
            'title': 'Euro'
    }
}