import {
  LOADING_PAYMENT_INFLOW,
  LOADING_NGN_PAYMENT_INFLOW,
  LOADING_ANY_PAYMENT_INFLOW,
  SUCCESS_ANY_PAYMENT_INFLOW,
  FAILURE_ANY_PAYMENT_INFLOW,
  RELOAD_PAYMENT_INFLOW,
  GET_PAYMENT_INFLOW_SUMMARY,
  GET_PAYMENT_OUTFLOW_SUMMARY,
  GET_SPECIFIC_PAYMENT_INFLOW,
  DOWNLOAD_ALL_NGN_PAYMENT_INFLOW,
} from "./types";

const initialState = {
  isLoading: false,
  shouldReload: false,
  paymentInflowSummary: {},
  paymentOutflowSummary: {},
  allPaymentFlows: [],
  specificPaymentFlow: {},
  downloadAllNGNPaymentFlows: [],
};

export default function paymentInFlowReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_PAYMENT_INFLOW:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOADING_ANY_PAYMENT_INFLOW:
      return {
        ...state,
        isLoading: action.payLoad,
      };

      case SUCCESS_ANY_PAYMENT_INFLOW:
       return {
        ...state,
       allPaymentFlows: action.payLoad.data.data,
      };

  
      // case FAILURE_ANY_PAYMENT_INFLOW:
      //   return {
      //     ...state,
      //     allPaymentFlows: action.payLoad.data.data,
      //   };

    case RELOAD_PAYMENT_INFLOW:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case GET_PAYMENT_INFLOW_SUMMARY:
      return {
        ...state,
        paymentInflowSummary: action.payLoad.data.data,
      };

    case GET_PAYMENT_OUTFLOW_SUMMARY:
      return {
        ...state,
        paymentOutflowSummary: action.payLoad.data.data,
      };


    case DOWNLOAD_ALL_NGN_PAYMENT_INFLOW:
      return {
        ...state,
        downloadAllNGNPaymentFlows: action.payLoad.data.data,
      };

    case GET_SPECIFIC_PAYMENT_INFLOW:
      return {
        ...state,
        specificPaymentFlow: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
