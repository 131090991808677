import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useBusiness from "../../hooks/useBusiness";
import { getBusinessCacTypes, getBusinessTypeCacFee } from "../../redux/business/businessActions";
import SelectInput from "../../components/inputs/SelectInput";
import { isArray } from "../../helpers/helper";
import styled from "styled-components";
import BackButton from "../../components/transfer/BackButton";
import { CgClose } from "react-icons/cg";
import Button from "../../components/buttons/Button";
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { Link } from "react-router-dom";

export default function SelectCompanyType({ onClick, input, setInput }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const business = useBusiness();
  const [errorIndustry, setErrorIndustry] = useState(false);
  const [agreement, setAgreement] = useState(false);

  React.useEffect(() => {
    dispatch(getBusinessCacTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    input?.business_category?.length > 0 && dispatch(getBusinessTypeCacFee(input.business_category.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.business_category]);

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setErrorIndustry(false)
  };

  const handleSubmit = () => {
    if (!input?.business_category?.length) {
      setErrorIndustry(true)
    }
    else {
      onClick(input.business_category)
    }
  }
  return (
    <RegisterNewBusiness className="py-0 px-8 md:px-20">
      <div className="flex justify-between header">
        <BackButton onClick={() => history.push({ pathname: '/dashboard/home' })} />
        <span onClick={() => history.push({ pathname: '/dashboard/home' })} className="icon close">
          <CgClose size={31} />
        </span>
      </div>
      <Container className="">
        <h2 className="text-lg font-semibold text-glade-black-800 mb-2">Select Registration Type</h2>
        <p className="text-sm text-glade-black-800">What type of registeration do you want?</p>
        <div className="bg-white w-full md:w-3/5 lg:w-2/5 mt-6">
          <SelectInput
            label={""}
            id={"business_category"}
            name={"business_category"}
            value={input.business_category ?? ""}
            onChange={onChange}
            autoComplete={"business_category"}
            className={"appearance-none"}
          >
            <option value={""} disabled={true}>
              Select Industry
            </option>
            {isArray(business?.businessCacTypes) &&
              business?.businessCacTypes.map((item, key) => {
                return (
                  <option key={key} value={item.type_code}>
                    {item.type_name}
                  </option>
                );
              })}
          </SelectInput>
          {
            errorIndustry && <div className="text-red-700 text-sm">Select your industry type</div>
          }
          <hr className="mt-14" />
          <div className="flex items-center justify-between mt-3 mb-10 text-sm">
            <div className="text-glade-gray-500">You will pay:</div>
            <div>NGN {business?.businessCacTypeFee && Number(business?.businessCacTypeFee).toLocaleString("en-us")}</div>
          </div>
          <div className='flex justify-center flex-col items-center'>
            <div className="flex items-center mb-6">
              <CheckboxInput
                id={'agreement'}
                type={'checkbox'}
                checked={agreement}
                onChange={(e) => setAgreement(!agreement)}
              />
              <p className="glade-home-dark">
                {'I agree to the '}
                <span
                  onClick={() => window.open('https://gladefinance.co/terms', '_blank')}
                  className="glade-blue cursor-pointer">
                  Terms & Conditions
                </span>
              </p>
            </div>
            <Button.Blue
              className={'pl-12 pr-12'}
              title="Proceed"
              onClick={handleSubmit}
              disabled={!agreement}
            />
          </div>
        </div>
      </Container>
    </RegisterNewBusiness>
  );
}

export const RegisterNewBusiness = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: Inter, sans-serif;
  color: #151515;
  z-index: 300;
  width: 100%;
  background: #fff;
  overflow-y: auto;
  // padding: 0 5rem;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    margin-bottom: 2rem;
    border-bottom: 3px solid gainsboro;
  }

  .icon {
    cursor: pointer;
    color: #1634a1;
    &:hover {
      color: #ea0202;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 65vh;
  // width: 501px

  .selected-business {
    background: rgba(4, 43, 183, 0.05);
    border: 1px solid #153bc6;
    color: #153bc6;
  }
`;
