import { ImSpinner2 } from "react-icons/im";

const Spinner = ({ infoText }) => {
  return (
    <div className="justify-center flex flex-col items-center">
      <ImSpinner2 className='mt-6 spinner text-center' size={50} color='#0B7AE0' />
      <p className="text-left mt-4">{infoText}</p>
    </div>
  );
};

export default Spinner;