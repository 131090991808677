


export const FormStateIndicator = ({ formStep, input }) => {
  return (
    <>
      {input.country_code.toLowerCase() === 'nga' &&
        <div className="flex sm:flex-row justify-around content-between mb-5 ">
          <div
            className={` w-1/3 pb-2`}
          >
            <div className="flex justify-center items-center mb-5 w-full ">
              <div className={`ml-8 font-bold h-7 w-7 rounded-full text-center pt-1 text-sm bg-glade-blue-500 text-white`}>
                01
              </div>
              <hr className={`flex-1 border-gray-100 border-t-4 ${formStep === "owner information" || formStep === "business documents" ? "border-glade-blue-500" : ""}`} />
            </div>

            <div className={`text-xs text-glade-blue-500`}>
              Business Information
            </div>
          </div>
          <div
            className={` w-1/3 self-center pb-2`}
          >
            <div className="flex justify-center items-center  mb-5">
              <hr className={`flex-1 border-gray-100 border-t-4 ${formStep === "owner information" || formStep === "business documents" ? "border-glade-blue-500" : ""}`} />

              <div className={`font-bold h-7 w-7 rounded-full text-center pt-1 text-sm ${formStep === "owner information" || formStep === "business documents" ? "bg-glade-blue-500 text-white" : "text-glade-gray-400 bg-gray-100"}`}>
                02
              </div>
              <hr className={`flex-1 border-gray-100 border-t-4 ${formStep === "busines information" || formStep === "business documents" ? "border-glade-blue-500" : ""}`} />
            </div>
            <div className={`text-center text-xs ${formStep === "owner information" || formStep === "business documents" ? "text-glade-blue-500" : ""}`} >
              Owner Information
            </div>
          </div>

          <div
            className={` w-1/3 self-end pb-2`}
          >
            <div className="flex justify-center items-center mb-5 w-4/5 ">
              <hr className={`flex-1 border-gray-100 border-t-4 ${formStep === "business documents" && "border-glade-blue-500"}`} />
              <div className={`font-bold h-7 w-7 rounded-full text-center pt-1 text-sm ${formStep === "business documents" ? "bg-glade-blue-500 text-white" : "text-glade-gray-400 bg-gray-100"}`}>
                03
              </div>
            </div>
            <div className={`text-right text-xs ${formStep === "business documents" ? "text-glade-blue-500" : ""}`}>
              Business Documents
            </div>
          </div>
        </div>
      }
      {input.country_code.toLowerCase() !== 'nga' &&
        <div className="flex sm:flex-row justify-around content-between mb-5 ">
          <div
            className={` w-1/2 pb-2`}
          >
            <div className="flex justify-center items-center mb-5 w-full ">
              <div className={`ml-8 font-bold h-7 w-7 rounded-full text-center pt-1 text-sm bg-glade-blue-500 text-white`}>
                01
              </div>
              <hr className={`flex-1 border-gray-100 border-t-4 ${formStep === "owner information" || formStep === "business documents" ? "border-glade-blue-500" : ""}`} />
            </div>

            <div className={`text-xs text-glade-blue-500`}>
              Business Information
            </div>
          </div>


          <div
            className={` w-1/2 self-end pb-2`}
          >
            <div className="flex justify-center items-center mb-5 w-4/5 ">
              <hr className={`flex-1 border-gray-100 border-t-4 ${formStep === "business documents" && "border-glade-blue-500"}`} />
              <div className={`font-bold h-7 w-7 rounded-full text-center pt-1 text-sm ${formStep === "business documents" ? "bg-glade-blue-500 text-white" : "text-glade-gray-400 bg-gray-100"}`}>
                02
              </div>
            </div>
            <div className={`text-right text-xs ${formStep === "business documents" ? "text-glade-blue-500" : ""}`}>
              Business Documents
            </div>
          </div>
        </div>
      }
    </>
  )
}