import React, { useContext, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { removeCommas } from "../../helpers/helper";
import { TransferFormContext } from '../../context/TransferFormContext';
import { calculateBalance, commmaToInput, currencySymbolEnum } from '../../helpers/helper';
import useFundTransfer from "../../hooks/useFundTransfer";
import { useDispatch } from "react-redux";
import ErrorMessage from '../../validation/fund-transfer/ErrorMessage';
import { getInternationalTransferRate, getLocalTransferRate } from '../../redux/fund-transfer/fundTransferActions';
import ReceiverFlag from './ReceiverFlag';

const SendInput = ({ label, placeholder, oneWay, icon, onBlur }) => {
  const {
    setBalanceAfterTransfer,
    availableBalance,
    currencies,
    transferForm,
    setTransferForm,
    transferCharge,
    setTransferCharge,
    formType,
    isNGNTransfer,
    transferMethod,
    repeatTransaction,
    repeatTransferDetails
  } = useContext(TransferFormContext);
  const { internationalTransferRate } = useFundTransfer();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();

  let newValues = {
    amount: "0",
    receiver_amount: "0",
  };

 
  function onChange(e) {
    setError(false)
    if (oneWay) {
      newValues = {
        [e.target.name]: commmaToInput(e.target.value),
      };
    } else {
      newValues = {
        [e.target.name]: commmaToInput(e.target.value),
        receiver_amount: internationalTransferRate?.rate ? commmaToInput(Number.parseFloat(commmaToInput(e.target.value, true) * Number(internationalTransferRate?.rate)).toFixed(2)) : Number.parseFloat(commmaToInput(e.target.value, true)),
      };
    }

    if (newValues?.amount === 'NaN') {
      setErrorMsg('You can only type numbers');
      setError(true);
      setTimeout(() => setError(false), 1000);
      return;
    }

    if (removeCommas(e.target.value) * 100 + (transferCharge * 100 || 0) > availableBalance * 100) {

      setErrorMsg('This amount exceeds your available balance');
      setError(true);
    }
    if (removeCommas(e.target.value) < 10) {
      setErrorMsg('The minimum acceptable amount is 10');
      setError(true);
    }

    setTransferForm(form => ({
      ...form,
      ...newValues
    }));

    if (formType === "local" && isNGNTransfer) {
      dispatch(getLocalTransferRate({
        source_currency: currencies?.activeCurrency?.currency,
        amount: removeCommas(newValues?.amount, true) || 0
      }));
    } else if (formType === "local") {
      dispatch(getInternationalTransferRate({
        amount: removeCommas(newValues?.amount, true) || 0,
        source_currency: currencies?.activeCurrency?.currency,
        destination_currency: currencies?.activeCurrency?.currency ?? "USD",
        destination_country: currencies?.activeCurrency?.currency?.slice(0, -1) ?? "US",
        transfer_method: transferForm?.transfer_method || "bank"
      },
        (data) => {
          setTransferCharge(data?.fee ?? data?.rate);
          setBalanceAfterTransfer(calculateBalance(availableBalance, removeCommas(e.target.value, true), data.fee));
        }
      ));
    } else if(formType === "glade"){
      dispatch(getInternationalTransferRate({
        amount: removeCommas(newValues?.amount, true) || 0,
        source_currency: currencies?.activeCurrency?.currency,
        destination_currency: currencies?.activeCurrency?.currency,
        destination_country: currencies?.activeCurrency?.currency?.slice(0, -1),
        transfer_method: transferForm?.transfer_method || "bank"
      },
        (data) => {
          setTransferCharge(data?.fee ?? data?.rate);
          setBalanceAfterTransfer(calculateBalance(availableBalance, removeCommas(e.target.value, true), data.fee));
        }
      ));
    }else {
      dispatch(getInternationalTransferRate({
        amount: removeCommas(newValues?.amount, true) || 0,
        source_currency: currencies?.activeCurrency?.currency,
        destination_currency: currencies?.selectedCurrency?.currency ?? "USD",
        destination_country: currencies?.selectedCurrency?.currency?.slice(0, -1) ?? "US",
        transfer_method: transferForm?.transfer_method || "bank"
      },
        (data) => {
          setTransferCharge(data?.fee ?? data?.rate);
          setBalanceAfterTransfer(calculateBalance(availableBalance, removeCommas(e.target.value, true), data.fee));
        }
      ));
    }
  };

  const fundsInsufficient = useMemo(() => {
    if (Number(parseFloat(removeCommas(transferForm?.amount))) + internationalTransferRate?.fee < parseFloat(availableBalance)) {
      return false;
    } else {
      return true;
    }
  }, [transferForm.amount, availableBalance, internationalTransferRate?.fee]);


  useEffect(() => {
    if (repeatTransaction && repeatTransferDetails){
    setTransferForm(form => (
      { ...form,
      amount:Number( repeatTransferDetails?.value)?.toLocaleString()

        }))
      };
  },[repeatTransaction , repeatTransferDetails])

  return (
    <Div icon={icon}>
      <label htmlFor="" className="font-bold">{label}</label>
      <section>
        <div className="input-area">
          <div className='flag-area'>
            {icon && (
              <span className='flag'>
                <ReceiverFlag flagCode={currencies?.activeCurrency?.code} />
              </span>
            )}
            <span className='currency'>{currencies?.activeCurrency?.currency}</span>
          </div>

          <input
            type="text"
            placeholder='0'
            name="amount"
            className='focus:outline-0'
            onChange={onChange}
            onBlur={onBlur}
            value={transferForm?.amount || ""}
            disabled={repeatTransaction ? true : false}

          />
        </div>
        <div className='current-balance'>
          <span>
            {currencies?.activeCurrency?.currency} Balance - {currencySymbolEnum[currencies?.activeCurrency?.currency]} {Number(availableBalance).toLocaleString('en-US')}
          </span>
          {/* {transferForm?.amount && fundsInsufficient && <span className='insufficient'>Insufficient funds</span>} */}
        </div>
      </section>
      {error && <ErrorMessage msg={errorMsg || "Input Error"} />}
    </Div>
  );
};

export default SendInput;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  width: 100%;
  
  label {
    margin-bottom: .5rem;
  }

  section {
    background: #FAFAFA;
    // color: ;
    border: 1.5px solid #E4E4F3;
    border-radius: 5px;
    overflow: hidden;
  }

  .flag-area {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid #E4E4F3;
    // padding: 0 1rem;
    width: ${({ icon }) => icon ? "40%" : "20%"};
  }

  .input-area {
    border-bottom: 1.5px solid #E4E4F3;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 5px;

    .currency {
      padding: .8rem;
    }

    input {
      border: 0;
      border-top: 3px solid transparent;
      transition: border 50ms ease-out;
      width: 100%;
      padding: .8rem;
      
      &:focus {
        border-top: 3px solid dodgerblue;
        border-right: 3px solid dodgerblue;
        outline: 2px solid dodgerblue;
      }
    }
  }

  .current-balance {
      padding: 1rem;
      color: #4BB94B;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .insufficient {
      color: crimson;
      font-size: .8rem;
      // float: right;
    }
  
`;
