import React from 'react';
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi';
import Button from '../../../components/buttons/Button';
import { BsCheck } from 'react-icons/bs';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { MetamapStatusVerification } from '../../../redux/auth/authActions';
import useUser from '../../../hooks/useUser';
import { ImSpinner2 } from 'react-icons/im';

const SelfieVerificationStatus = (({ input, setIdUploadResponse, status = 'fail', SelfieVerificationStatusCallback }) => {

  const dispatch = useDispatch()
  const user = useUser()

  const onSuccessStatus = (res) => {
    if (res.status === 'verified') {
      setIdUploadResponse('success')
    } else if (res.status === 'pending') {
      setIdUploadResponse('loading')
    } else if (res.status === 'reviewNeeded') {
      setIdUploadResponse('reviewNeeded')
    } else {
      setIdUploadResponse('fail')
    }
  }

  const onFailure = () => {
    setIdUploadResponse('fail')
  }

  const recheck = () => {
    dispatch(MetamapStatusVerification(input?.email, onSuccessStatus, onFailure))
  }

  return (
    <>
      {!user?.isLoadingMetaMapStatus &&
        <div className='flex flex-col items-center'>
          {(status === 'loading' || status === 'reviewNeeded') &&
            <div className='p-3 mt-4 glade-bg-yellow-opacity rounded-full mb-6'>
              <HiOutlineDotsCircleHorizontal size={70} color='#F9C900' />
            </div>}
          {status === 'success' &&
            <div className='p-3 mt-4 glade-bg-green-200 rounded-full mb-6'>
              <BsCheck size={70} color='#02e625' />
            </div>}
          {status === 'fail' &&
            <div className='p-3 mt-4 glade-bg-red-200 rounded-full mb-6'>
              <IoCloseSharp size={70} color='red' />
            </div>}
          <p className='pb-2 mt-4 glade-heading-three font-bold'>Verification {(status === 'loading' || status === 'reviewNeeded') ? 'Pending' : status === 'success' ? 'Successful' : "Failed"}</p>
          <p className='px-6 text-center mb-12'>{
            status === 'loading' ?
              'Your verification has been submitted and is currently being reviewed. Kindly wait for 3- 5 minutes.'
              : status === 'reviewNeeded' ? 'Your verification has been submitted and is currently being reviewed. Kindly check back in 1-2 hours' : status === 'success' ?
                'Your verification is successful' :
                'Your ID verification failed. '
          }</p>
          {
            (status === 'loading' || status === 'reviewNeeded') ?
              <Button.Blue
                title={'Recheck'}
                type={'button'}
                className={'py-3 px-10'}
                onClick={recheck}
                // disabled={status === 'loading' }
                loading={user?.isLoadingMetaMapStatus}
              /> :
              <Button.Blue
                title={status === 'fail' ? 'Retry' : 'Continue'}
                type={'button'}
                className={'py-3 px-10'}
                onClick={() => { SelfieVerificationStatusCallback(status) }}
                disabled={status === 'loading'}
                loading={false}
              />
          }

        </div>
      }
      {user?.isLoadingMetaMapStatus &&

        <div className="flex flex-col items-center px-6 py-12 w-full bg-white h-full">
          <div>
            <div className="mt-6">
              <span className="glade-heading-three glade-home-dark">
                Checking Status
              </span>
            </div>
            <div className="flex justify-center">
              <ImSpinner2
                className="mt-6 spinner text-center"
                size={70}
                color="#0B7AE0"
              />
            </div>
          </div>
        </div>
      }

    </>
  )
})

export default SelfieVerificationStatus;