import React, { useState, useRef, useEffect } from "react";
import {
  RiBillLine,
  RiFileCopy2Line,
  RiSendPlaneLine,
  RiCellphoneLine,
  RiAtLine,
} from "react-icons/ri";
import { FiLink } from "react-icons/fi";
import { AiOutlineCreditCard } from "react-icons/ai";
import { Convert } from "../../assets/icons/Icons";
import { FaChevronRight } from "react-icons/fa";
import ConvertModal from "../../components/modals/ConvertModal";
import Modal from "../../components/modals/Modal";
import useUser from "../../hooks/useUser";
import { isArray } from "../../helpers/helper";
import AccountActivationModal from "../../components/modals/AccountActivationModal";
import CreateUserTag from "../fund-transfer-new/CreateUserTag";
import NewPaymentPageModal from "../payment-pages/NewPaymentPageModal";
import { useHistory } from "react-router-dom";
import useSettings from "../../hooks/useSettings";
import { getUserDetails } from "../../redux/settings/settingsActions";
import { useDispatch } from "react-redux";
import CreateTagSuccessModal from "../fund-transfer-new/CreateTagSuccessModal";
import useTeam from "../../hooks/useTeam";

const QuickActions = () => {
  const newPaymentPageModalRef = React.useRef();
  const CreateUserTagRef = useRef();
  const CreateTagSuccessModalRef = useRef();
  const settings = useSettings();
  const dispatch = useDispatch();

  const [showConvertModal, setShowConvertModal] = useState(false);
  const [openActivationModal, setActivationModal] = useState(false);

  const user = useUser();
  const history = useHistory();
  const { team_user, permissions } = useTeam();

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  const openModal = (action) => {
    if (user.compliance !== "approved" && !team_user?.employee?.is_teams) {
      setActivationModal(true);
    } else {
      if (action?.text?.toLowerCase() === "send money") {
        history.push({
          pathname: !team_user?.employee?.is_teams
            ? "/dashboard/fund-transfer"
            : "/teams/fund-transfer",
          state: { showModal: !team_user?.employee?.is_teams? true : false },
        });
      }
      if (action?.text?.toLowerCase() === "fund account") {
        history.push({
          pathname: !team_user?.employee?.is_teams
            ? "/dashboard/fund-account"
            : "/teams/fund-account",
        });
      }
      if (action?.text?.toLowerCase() === "create an invoice") {
        history.push({
          pathname: !team_user?.employee?.is_teams
            ? "/dashboard/invoice/create-invoice"
            : "/teams/invoice/create-invoice",
        });
      }
      if (action?.text?.toLowerCase() === "pay a bill") {
        history.push({
          pathname: !team_user?.employee?.is_teams
            ? "/dashboard/pay-bills/new-bill"
            : "/teams/pay-bills",
        });
      }
      if (action?.text?.toLowerCase() === "buy airtime") {
        history.push({
          pathname: !team_user?.employee?.is_teams
            ? "/dashboard/airtime/new-airtime"
            : "/teams/airtime/new-airtime",
        });
      }
      if (action?.text?.toLowerCase() === "convert funds")
        setShowConvertModal(true);
    }

    if (action?.text?.toLowerCase() === "request a payment") {
      !team_user?.employee?.is_teams ||
      (team_user?.employee?.is_teams &&
        permissions?.includes("payment requests"))
        ? newPaymentPageModalRef.current.open()
        : setActivationModal(true);
    }
    if (action?.icon?.props?.title === "usertag") {
      CreateUserTagRef.current.open();
    }
    if(action?.text?.toLowerCase() === "recurring payment"){
      history.push({
        pathname: !team_user?.employee?.is_teams
          ? "/dashboard/recurring-payment/single"
          : "/teams/recurring-payment/single",
      });
    }
  };
  const businessQuickActions = [
    {
      icon: <RiSendPlaneLine title="Transfer" size={30} />,
      text: "Send Money",
      show: true,
    },
    {
      icon: <RiFileCopy2Line title="Transfer" size={30} />,
      text: "Create an invoice",
      show: true,
    },
    // {
    //   icon: <Convert title="Convert" size={23} color="#2C6DEA" />,
    //   text: "Convert Funds",
    //   show: true,
    // },
    {
      icon: <RiBillLine title="Bill" size={30} />,
      text: "Pay a Bill",
      show: true,
    },
    {
      icon: <FiLink title="Payment Link" size={30} />,
      text: "Request a payment",
      show: true,
    },
    {
      icon: <RiBillLine title="Recurring Payment" size={30} />,
      text: "Recurring Payment",
      show: !team_user?.employee?.is_teams ? true : false,
    },
  ];
  const personalQuickActions = [
    {
      icon: <RiAtLine title="usertag" size={25} />,
      text: "Usertags are now available",
      text_2: "Create your tag and start making transactions with it",
      show: !settings.userDetails?.user?.user_tag ? true : false,
    },
    {
      icon: <RiSendPlaneLine title="Transfer" size={30} />,
      text: "Send Money",
      show: true,
    },
    {
      icon: <AiOutlineCreditCard title="fund" size={30} />,
      text: "Fund Account",
      show: true,
    },
    {
      icon: <RiCellphoneLine title="Airtime" size={30} />,
      text: "Buy Airtime",
      show: true,
    },
    {
      icon: <RiBillLine title="Bill" size={30} />,
      text: "Pay a Bill",
      show: true,
    },
    {
      icon: <RiBillLine title="Recurring Payment" size={30} />,
      text: "Recurring Payment",
      show: !team_user?.employee?.is_teams ? true : false,
    },
  ];

  const quickActions =
    (user?.selectedBusinessOrPersonalAccount?.is_business &&
      isArray(user?.selectedBusinessOrPersonalAccount?.accounts)) ||
    team_user?.employee?.is_teams
      ? businessQuickActions
      : personalQuickActions;

  return (
    <div className="flex flex-col px-6 py-4">
      <div className="mt-2 glade-bg-light-grey flex items-center px-3 py-2 rounded-md">
        <h3 className="glade-heading-three glade-home-dark">Quick Actions</h3>
      </div>
      <div className="mt-4 flex flex-col space-y-4 cursor-pointer">
        {quickActions.map(
          (action, i) =>
            action?.show && (
              <div key={i}>
                <div
                  className="flex p-3 justify-between items-center glade-blue glade-coming-soon-bg rounded-md"
                  onClick={() => (action.link ? undefined : openModal(action))}
                >
                  <div className="flex items-center space-x-3">
                    <span
                      className={`${
                        action?.icon?.props?.title === "usertag"
                          ? "bg-glade-blue text-white p-1 rounded"
                          : ""
                      } `}
                    >
                      {action?.icon}
                    </span>
                    <div className="flex flex-col">
                      <span
                        className={`${
                          action?.icon?.props?.title === "usertag"
                            ? "text-md font-medium"
                            : ""
                        } `}
                      >
                        {action?.text}
                      </span>
                      <span
                        className={`${
                          action?.icon?.props?.title === "usertag"
                            ? "text-xs text-blue-500"
                            : ""
                        } `}
                      >
                        {action?.text_2}
                      </span>
                    </div>
                  </div>
                  <span>
                    <FaChevronRight />
                  </span>
                </div>
              </div>
            )
        )}
      </div>
      {
        <Modal
          open={showConvertModal}
          onClose={() => setShowConvertModal(false)}
        >
          <ConvertModal
            onClose={() => {
              setShowConvertModal(false);
            }}
          />
        </Modal>
      }

      <NewPaymentPageModal ref={newPaymentPageModalRef} />
      <CreateUserTag
        ref={CreateUserTagRef}
        CreateTagSuccessModalRef={CreateTagSuccessModalRef}
      />
      <CreateTagSuccessModal ref={CreateTagSuccessModalRef} />
      {/* Account Actvation Modal */}
      {openActivationModal && (
        <AccountActivationModal
          openActivationModal={openActivationModal}
          setActivationModal={setActivationModal}
          complianceStatus={user?.compliance}
          isBusiness={user.isBusiness }
          isTeam={team_user?.employee?.is_teams}
        />
      )}
    </div>
  );
};

export default QuickActions;
