import React from 'react';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';

export default React.forwardRef(function TeamActivationToggleModal(props, ref) {

  const { toggleActivationHandler } = props
  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: (value) => { setInput(value); plainModalRef.current.open() },
    close: () => { setInput({}); plainModalRef.current.close() },
    toggle: (value) => { setInput(value); plainModalRef.current.toggle() },
  }));

  const [input, setInput] = React.useState({});

  const onsubmit = () => {
    toggleActivationHandler(input);
    plainModalRef.current.close();

  }

  return (
    <PlainModal
      ref={plainModalRef}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitleSection={<React.Fragment></React.Fragment>}
      modelContentSection={
        <div className='p-6'>
          <div className="flex flex-col space-y-3">
            <h4>Are you sure you want to {input?.status === "deactivated" ? "activate" : "deactivate"} this team member? </h4>
            <div className="flex justify-between gap-x-6">
              <div className='w-1/2'>
                <Button.Transparent title={'Cancel'} onClick={() => plainModalRef.current.close()} className='w-full py-3' />
              </div>
              <div className='w-1/2'>
                <Button.Danger2 title={'Yes'} onClick={() => onsubmit()} className='w-full py-3' />
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
})
