// Application types
export const LOADING_SETTINGS = 'LOADING_SETTINGS';
export const LOADING_COMPLIANCE = 'LOADING_COMPLIANCE';
export const RELOAD_SETTINGS = 'RELOAD_SETTINGS';

export const CREATE_PASS_CODE = 'CREATE_PASS_CODE';
export const RESET_PASS_CODE = 'RESET_PASS_CODE';
export const UPDATE_PASS_CODE = 'UPDATE_PASS_CODE';
export const COMPLETE_PASS_CODE = 'COMPLETE_PASS_CODE';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_RESET_PASSWORD = 'UPDATE_RESET_PASSWORD';
export const COMPLETE_RESET_PASSWORD = 'COMPLETE_RESET_PASSWORD';
export const GET_BUSINESS_DETAILS = 'GET_BUSINESS_DETAILS';
export const UPDATE_PERSONAL_PROFILE = 'UPDATE_PERSONAL_PROFILE';
export const UPDATE_BUSINESS_PROFILE = 'UPDATE_BUSINESS_PROFILE';

export const GET_COMPLIANCE_COUNTRIES = 'GET_COMPLIANCE_COUNTRIES';
export const GET_COMPLIANCE_STATES = 'GET_COMPLIANCE_STATES';
export const GET_COMPLIANCE_LGA = 'GET_COMPLIANCE_LGA';;
export const GET_AGENT_GUARANTOR_TEMPLATE = 'GET_AGENT_GUARANTOR_TEMPLATE';
export const GET_COMPLIANCE_PERSONAL_DOCUMENT_TYPES = 'GET_COMPLIANCE_PERSONAL_DOCUMENT_TYPES';
export const GET_COMPLIANCE_BUSINESS_DOCUMENT_TYPES = 'GET_COMPLIANCE_BUSINESS_DOCUMENT_TYPES';
export const GET_PERSONAL_COMPLIANCES = 'GET_PERSONAL_COMPLIANCES';
export const GET_RECENT_PERSONAL_COMPLIANCE = 'GET_RECENT_PERSONAL_COMPLIANCE';
export const GET_BUSINESS_COMPLIANCES = 'GET_BUSINESS_COMPLIANCES';
export const GET_RECENT_BUSINESS_COMPLIANCE = 'GET_RECENT_BUSINESS_COMPLIANCE';
export const GET_SUBMITTED_PERSONAL_COMPLIANCE_DETAILS = 'GET_SUBMITTED_PERSONAL_COMPLIANCE_DETAILS';
export const GET_SUBMITTED_BUSINESS_COMPLIANCE_DETAILS = 'GET_SUBMITTED_BUSINESS_COMPLIANCE_DETAILS';
export const GET_COMPLIANCE_IDENTITY_TYPES = 'GET_COMPLIANCE_IDENTITY_TYPES';
export const GET_METAMAP_IDENTITY_TYPES = 'GET_METAMAP_IDENTITY_TYPES';
export const SUBMIT_PERSONAL_COMPLIANCE = 'SUBMIT_PERSONAL_COMPLIANCE';
export const SUBMIT_BUSINESS_COMPLIANCE = 'SUBMIT_BUSINESS_COMPLIANCE';
export const SUBMIT_BUSINESS_INFORMATION= 'SUBMIT_BUSINESS_INFORMATION';
export const SUBMIT_BUSINESS_DOCUMENTS= 'SUBMIT_BUSINESS_DOCUMENTS';
export const SEND_USER_INVITES = 'SEND_USER_INVITES';
export const VERIFY_USER_INVITES = 'VERIFY_USER_INVITES';
export const UPDATE_USER_INVITES = 'UPDATE_USER_INVITES';
export const GET_USER_INVITES = 'GET_USER_INVITES';
export const RESEND_USER_INVITES = 'RESEND_USER_INVITES';
export const DELETE_USER_INVITES = 'DELETE_USER_INVITES';

export const GET_SETTLEMENT_ACCOUNTS = 'GET_SETTLEMENT_ACCOUNTS';
export const CREATE_SETTLEMENT_ACCOUNT = 'CREATE_SETTLEMENT_ACCOUNT';
export const UPDATE_SETTLEMENT_ACCOUNT = 'UPDATE_SETTLEMENT_ACCOUNT';
export const DELETE_SETTLEMENT_ACCOUNT = 'DELETE_SETTLEMENT_ACCOUNT';
export const GET_SUPPLIED_ACCOUNT_NUMBER_DETAILS = 'GET_SUPPLIED_ACCOUNT_NUMBER_DETAILS';

export const GET_ROLES = 'GET_ROLES';
export const CREATE_ROLES = 'CREATE_ROLES';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

export const GET_DASHBOARD_FEATURES = 'GET_DASHBOARD_FEATURES';
export const LOADING_DASHBOARD_FEATURES = "LOADING_DASHBOARD_FEATURES";
export const DASHBOARD_FEATURES_FAILURE = "DASHBOARD_FEATURES_FAILURE";
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const UPDATE_DASHBOARD_FEATURES = 'UPDATE_DASHBOARD_FEATURES';
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';

export const GET_STATUS_OF_AGENT_REQUEST = 'GET_STATUS_OF_AGENT_REQUEST';
export const SUBMIT_AGENT_REQUEST = 'SUBMIT_AGENT_REQUEST';

export const GET_MERCHANT_CREDENTIALS = 'GET_MERCHANT_CREDENTIALS';
export const GET_MERCHANT_WEBHOOK = 'GET_MERCHANT_WEBHOOK';
export const CREATE_OR_UPDATE_MERCHANT_WEBHOOK = 'CREATE_OR_UPDATE_MERCHANT_WEBHOOK';
export const GET_DEVELOPER_WEB_PAGE_LINK = 'GET_DEVELOPER_WEB_PAGE_LINK';
export const GENERATE_NEW_KEY = 'GENERATE_NEW_KEY';

export const GUARANTOR_FORM_SUCCESS = 'GUARANTOR_FORM_SUCCESS';

export const GET_REFERRAL_DETAILS = 'GET_REFERRAL_DETAILS';
export const GET_REFERRAL_REWARDS_BY_YEAR = 'GET_REFERRAL_REWARDS_BY_YEAR';
export const GET_REFERRAL_YEARS = 'GET_REFERRAL_YEARS';
export const GET_COMPLIANCE_STATES_NEW = 'GET_COMPLIANCE_STATES_NEW';
export const GET_COMPLIANCE_COUNTRIES_NEW = 'GET_COMPLIANCE_COUNTRIES_NEW';

