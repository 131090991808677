import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { objectToFormData } from "../../helpers/helper";
import {
  LOADING_BUSINESS,
  LOADING_SUBMITTING_BUSINESS,
  LOADING_TEAM_ROLES,
  LOADING_ADD_TEAM_MEMBER,
  GET_TEAM_ROLES,
  GET_TEAM_DETAILS,
  // RELOAD_BUSINESS,
  SUBMIT_REGISTERED_BUSINESS,
  SUBMIT_UNREGISTERED_BUSINESS,
  SUBMIT_UNREGISTERED_COMPANY,
  GET_BUSINESS_REGISTRATIONS,
  GET_BUSINESS_REGISTRATION_CHARGE,
  GET_BUSINESS_CATEGORIES,
  GET_BUSINESS_TYPES,
  GET_BUSINESS_CAC_TYPES,
  GET_BUSINESS_TYPE_CAC_FEE,
  SELECT_BUSINESS_CAC_TYPE,
  CHECK_DRAFT_STATUS,
  SUBMIT_BUSINESS_CAC_INFORMATION,
  CHECK_DRAFT_DETAILS,
  CANCEL_BUSINESS_CAC_REGISTRATION,
  SUBMIT_BUSINESS_CAC_OFFICER_INFORMATION,
  CAC_FEE_PAYMENT,
  GET_TEAM_LIST,
  DELETE_TEAM_LIST,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  LOADING_EDIT_TEAM_MEMBER,
  EDIT_TEAM_MEMBER_SUCCESS,
  EDIT_TEAM_MEMBER_FAILURE,
  TEAM_MEMBER_DETAILS_SUCCESS,
  LOADING_TEAM_MEMBER_DETAILS,
  TEAM_MEMBER_DETAILS_FAILURE,
  ACTIVATION_TOGGLE_SUCCESS,
  ACTIVATION_TOGGLE_FAILURE,
  LOADING_ACTIVATION_TOGGLE,
} from "./types";

/**
 * Submit a registered business
 * 
 * @param {object} payLoad {
    "business_category": "education",
    "business_name": "Peters Limited",
    "business_email": "peter@mail.com",
    "contact_email": "contact@peter.com",
    "contact_phone": "07098765432",
    "business_address": "250 John Doe Road, North West East",
    "state": "Abuja",
    "country": "Nigeria",
    "business_description": "This is the description of the business",
    "business_website": "www.john.doe.com"
 }
 * @param {func} callback
 * @returns {void}
 */
export const submitRegisteredBusiness = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    Axios.post(`/businesses`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: SUBMIT_REGISTERED_BUSINESS, payLoad: res });
        successHandler(res, true);
        callBack();

        // This api call is for the creation of a bank account for the registered business
        // Axios.post(`/businesses/details`).then((res)=>successHandler(res)).catch((error)=>errorHandler(error));
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Submit an unregistered business
 * 
 * @param {object} payLoad {
    "business_name": "Peters Limited",
    "business_name_two": "Peters and sons Limited",
    "business_name_three": "Peters  and brothers Limited",
    "business_email": "peter@mail.com",
    "contact_email": "contact@peter.com",
    "contact_phone": "07098765432",
    "business_address": "250 John Doe Road, North West East",
    "state": "Abuja",
    "country": "Nigeria",
    "business_description": "This is the description of the business",
    "business_objective": "feed the poor"
 }
 * @param {func} callback
 * @returns {void}
 */
export const submitUnRegisteredBusiness = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    Axios.post(`/businesses/registration/business`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: SUBMIT_UNREGISTERED_BUSINESS, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Submit an unregistered company
 * @param {object} payLoad {
    director_full_name:Odiri Udele
    company_name:Peters Limited
    company_name_two:Peters and sons Limited
    company_name_three:Peters  and brothers Limited
    company_email:peter@mail.com
    share_capital:12
    per_capital_share:12
    company_description:This is the description of the business
    company_objective:feed the poor
    company_address:250 John Doe Road, North West East
    director_email:odi@gmail.com
    director_phone:08115260577
    director_id_card_type:international passport
    director_id_card_number:34763748gd887
    director_address:56 fish road
    director_state:Lagos
    director_lga:Tower
    director_country:Nigeria
 }
 * @param {func} callback
 * @returns 
 */
export const submitUnRegisteredCompany = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(`/businesses/registration/company`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: SUBMIT_UNREGISTERED_COMPANY, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get submitted business registrations
 *
 * @param {void}
 * @returns {void}
 */
export const getAllSubmittedBusinessRegistration = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_BUSINESS, payLoad: true });
    Axios.get(`/businesses/registration`)
      .then((res) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        dispatch({ type: GET_BUSINESS_REGISTRATIONS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get business registration charge
 *
 * @param {object} payLoad {type:'company'} // business
 * @param {func} callBack
 * @returns {void}
 */
export const getBusinessRegistrationCharge = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BUSINESS, payLoad: true });
    Axios.get(`/businesses/registration/charge/${payLoad.type}`)
      .then((res) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        dispatch({ type: GET_BUSINESS_REGISTRATION_CHARGE, payLoad: res });
        successHandler(res);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get business categories
 *
 * @param {void}
 * @returns {void}
 */
export const getBusinessCategories = () => {
  return (dispatch) => {
    Axios.get(`businesses/lookup/categories`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_CATEGORIES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get business type
 *
 * @param {void}
 * @returns {void}
 */
export const getBusinessTypes = () => {
  return (dispatch) => {
    Axios.get(`businesses/lookup/business-categories`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_TYPES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get business cac registration type
 *
 * @param {void}
 * @returns {void}
 */
export const getBusinessCacTypes = () => {
  return (dispatch) => {
    Axios.get(`businesses/new-registration/type`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_CAC_TYPES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get business cac registration fee
 *
 * @param {void}
 * @returns {void}
 */
export const getBusinessTypeCacFee = (type) => {
  return (dispatch) => {
    Axios.get(`businesses/new-registration/${type}/registration-fees`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_TYPE_CAC_FEE, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Submit an selected business 
 * 
 * @param {object} payLoad {

 }
 * @param {func} callback
 * @returns {void}
 */
export const selectBusinessCacType = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    Axios.post(`/businesses/new-registration`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: SELECT_BUSINESS_CAC_TYPE, payLoad: res });
        successHandler(res, true);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};
// put post patch get delete

/**
 * Submit an selected business 
 * 
 * @param {object} payLoad {

 }
 * @param {func} callback
 * @returns {void}
 */
export const checkDraftStatus = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    Axios.post(`/businesses/new-registration/check-draft`)
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: CHECK_DRAFT_STATUS, payLoad: res });
        successHandler(res, true);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Submit business registration Information
 * 
 * @param {object} payLoad {
  
 }
 * @returns {void}
*/
export const submitBusinessCacInformtion = (
  payLoad,
  type,
  callBack = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(
      `/businesses/new-registration/${type.business_category}/details`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: SUBMIT_BUSINESS_CAC_INFORMATION, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

//   businesses/new-registration/sp/officer-details

/**
 * Submit business registration Information
 * 
 * @param {object} payLoad {
  
 }
 * @returns {void}
*/
export const submitBusinessCacOfficerInformtion = (
  payLoad,
  type,
  callBack = () => {},
  onFailure
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    let formData = objectToFormData(payLoad);
    Axios.post(
      `/businesses/new-registration/${type.business_category}/officer-details`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({
          type: SUBMIT_BUSINESS_CAC_OFFICER_INFORMATION,
          payLoad: res,
        });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
        //   onFailure(error)
      });
  };
};

/**
 * Submit business registration Information
 * 
 * @param {object} payLoad {
  
 }
 * @returns {void}
*/
export const checkDraftDetails = (payLoad, type, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    Axios.post(`/businesses/new-registration/get-drafts/${type.type}`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: CHECK_DRAFT_DETAILS, payLoad: res });
        successHandler(res);
        callBack(res?.data?.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
* Submit fee payment
* 
* @param {object} payLoad {
 
}
* @returns {void}
*/
export const cacFeePayment = (
  payLoad,
  type,
  callBack = () => {},
  onFailure
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    Axios.post(`/businesses/new-registration/${type.type}/fee-payment`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: CAC_FEE_PAYMENT, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
        onFailure(error);
      });
  };
};

export const cancelBusinessCacRegistration = (payLoad, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: true });
    Axios.post("/businesses/new-registration/cancel-registration", payLoad)
      .then((res) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        dispatch({ type: CANCEL_BUSINESS_CAC_REGISTRATION, payLoad: res });
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: LOADING_SUBMITTING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const addTeamMember = (postData, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_ADD_TEAM_MEMBER });
    Axios.post(`/businesses/employee-access/add-team-member`, postData)
      .then((res) => {
        dispatch({ type: ADD_TEAM_MEMBER_SUCCESS, payLoad: res });
        successHandler(res);
        callBack(res?.data?.data);
      })
      .catch((error) => {
        dispatch({ type: ADD_TEAM_MEMBER_FAILURE });
        errorHandler(error, true);
      });
  };
};

export const editTeamMember = (putData, id, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_EDIT_TEAM_MEMBER });
    Axios.put(`/businesses/employee-access/edit-role/${id}`, putData)
      .then((res) => {
        dispatch({ type: EDIT_TEAM_MEMBER_SUCCESS, payLoad: res });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: EDIT_TEAM_MEMBER_FAILURE });
        errorHandler(error, true);
      });
  };
};
// /businesses/employee-access/roles
export const getTeamRoles = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_TEAM_ROLES, payLoad: true });
    Axios.get(`/businesses/employee-access/roles`)
      .then((res) => {
        dispatch({ type: LOADING_TEAM_ROLES, payLoad: false });
        dispatch({ type: GET_TEAM_ROLES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_TEAM_ROLES, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Get business Employee list
 *
 * @params {object} payLoad
 * @returns {void}
 */
export const getEmployeeTeamList = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BUSINESS, payLoad: true });
    Axios.get(`/businesses/employee`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        dispatch({ type: GET_TEAM_LIST, payLoad: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const getTeamDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TEAM_MEMBER_DETAILS });
    Axios.get(`/businesses/employee-access/${id}/details`)
      .then((res) => {
        dispatch({ type: TEAM_MEMBER_DETAILS_SUCCESS, payLoad: res });
        successHandler(res, true);
      })
      .catch((error) => {
        dispatch({ type: TEAM_MEMBER_DETAILS_FAILURE, payLoad: false });
        errorHandler(error, true);
      });
  };
};
/**
 * Toggle Business Employee Activation
 *
 */
export const toggleActivation = (id, status, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_ACTIVATION_TOGGLE });
    Axios.put(`/businesses/employee/${status}?employee_ids=${id}`)
      .then((res) => {
        dispatch({ type: ACTIVATION_TOGGLE_SUCCESS, payLoad: res.data });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: ACTIVATION_TOGGLE_FAILURE, payLoad: false });
        errorHandler(error, true);
      });
  };
};
/**
 * Delete Business Employee List
 *
 */

export const deleteEmployeeTeamList = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BUSINESS, payLoad: true });
    Axios.get(`/businesses/employee/delete?employee_ids=${payLoad}`)
      .then((res) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        dispatch({ type: DELETE_TEAM_LIST, payLoad: res.data });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_BUSINESS, payLoad: false });
        errorHandler(error, true);
      });
  };
};
