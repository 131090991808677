import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import {getInvoice, getInvoiceCurrencies} from '../../redux/invoice/invoiceActions';
import useInvoice from '../../hooks/useInvoice';
import {simpleDate, statusEnum, isArray, isEmpty, pagination} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import TextIndicator from '../../components/indicators/TextIndicator';
import CircleIndicator from "../../components/indicators/CircleIndicator";
import Button from '../../components/buttons/Button';
import SearchInput from '../../components/inputs/SearchInput';
import Sieve from '../../components/filter/Sieve';
import {IoIosArrowForward} from "react-icons/io";
import {KebabDropdown, KebabDropdownItem} from "../../components/dropdowns/KebabDropdown";
import useTeam from '../../hooks/useTeam';

export default function Invoice() {

    const history = useHistory();
    const dispatch = useDispatch();
    const invoice = useInvoice();
    const {permissions} = useTeam();
    React.useEffect(() => {
        dispatch(getInvoice());
        dispatch(getInvoiceCurrencies());
        // eslint-disable-next-line 
    }, []);

    // Pagination Function And Control
    const [currentPage, setCurrentPage] = React.useState(1);
    const [tableData, setTableData] = React.useState([]);
    React.useEffect(() => {
        setTableData(pagination(invoice.invoiceIndex, 10, currentPage));
        // eslint-disable-next-line
    }, [currentPage, invoice.invoiceIndex]);

    // Search Function And Control
    const onSearch = (search) => {
        const filteredData = invoice.invoiceIndex?.filter(item => {
            return (item.invoice.invoice_no.toLowerCase().includes(search.toLowerCase())
            || item.invoice.email.toLowerCase().includes(search.toLowerCase()));
        });
        setTableData(pagination(filteredData ?? [], 10, currentPage));
    }

    // Filter Function And Control
    const onFilter = (data) => {
        dispatch(getInvoice({
            status:data.status_invoice,
            date_from:data.date_from??data.date,
            date_to:data.date_to??new Date().toISOString().substring(0, 10),
        }));
    }

    // Reset Filter Function And Control
    const onReset = () => {
        dispatch(getInvoice());
    }

    const renderInvoiceTable = (data) => {
        return data.map((item,key)=>{
            return (
                <CardTableBodyRow className={'cursor-pointer'} key={key} onClick={()=>history.push({pathname: !permissions.includes("invoicing") ? "/dashboard/invoice/invoice-detail" : "/teams/invoice/invoice-detail", state:item})}>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <TextIndicator type={statusEnum[item?.invoice?.status]} title={item?.invoice?.status} />
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item?.invoice?.currency} {Number(item?.invoice?.total_value ?? 0)?.toLocaleString()}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item?.invoice?.email}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item?.invoice?.user_name}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item?.invoice?.invoice_no}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{simpleDate(item?.invoice?.created_at)}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                </CardTableBodyRow>
            )
        });
    }

    const renderInvoiceDesk = (data) => {
        return data.map((item, key) => {
            return (
                <div key={key} className="flex flex-row justify-between p-1 border-b" onClick={()=>history.push({pathname: !permissions?.includes("invoicing") ? '/dashboard/invoice/invoice-detail' : '/teams/invoice/invoice-detail',state:item})}>
                    <div className="flex flex-row space-x-3">
                        <div className="flex items-center">
                            <CircleIndicator type={statusEnum[item?.invoice?.status]} />
                        </div>
                        <div className="flex flex-col space-y-1 py-1">
                            <div>
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                                    {item?.invoice?.currency} {Number(item?.invoice?.total_value ?? 0)?.toLocaleString()}
                                </span>
                            </div>
                            <div>
                                <span className="glade-normal-text-two glade-blue whitespace-nowrap">
                                    {item?.invoice?.email}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <IoIosArrowForward />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="p-3">
            <CardTableHeaderComplex 
                title={`Showing ${tableData.totalItems} records`}
                filter={<Sieve types={['invoice status','date']} onFilter={(value)=>onFilter(value)} onReset={()=>onReset()} disabled={invoice.isLoading} loading={invoice.isLoading} />}
                searchBar={<SearchInput disabled={invoice.isLoading} onSearch={onSearch} placeholder={'Invoice Number or Customer Email'} />}
                rightButton={
                    <div>
                        <Button.Blue title={'Create Invoice'} onClick={()=>history.push(!permissions?.includes("invoicing") ? '/dashboard/invoice/create-invoice' : "/teams/invoice/create-invoice")} />
                    </div>
                }
                kebabMenuButton={
                    <div>
                      <KebabDropdown className="z-10" disabled={invoice.isLoading}>
                        <KebabDropdownItem
                          activeChild={<Sieve
                            button={<div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"> Filter </div>}
                            types={['invoice status','date']}
                            onFilter={(value) => onFilter(value)}
                            onReset={() => onReset()}
                            disabled={invoice.isLoading}
                            loading={invoice.isLoading}
                          />}
                          inactiveChild={<span className="glade-normal-text-two glade-black p-4"> Filter </span>}
                        />
                        <KebabDropdownItem 
                            activeChild={<span onClick={()=> history.push(!permissions?.includes("invoicing") ? '/dashboard/invoice/create-invoice' : "/teams/invoice/create-invoice")} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Create Invoice</span>}
                            inactiveChild={<span className="glade-normal-text-two glade-black p-4">Create Invoice</span>}
                        />
                      </KebabDropdown>
                    </div>
                }
            />

            {isEmpty(tableData.data) || invoice.isLoading?
                <React.Fragment>
                    <div className="hidden md:block">
                        <EmptyTable column={['','Status','Amount','Customer Email','Customer Name','Invoice Number','Date Created','']} loading={invoice.isLoading} />
                    </div>

                    <div className="block md:hidden">
                        <EmptyDesk row={[1,2]} loading={invoice.isLoading} />
                    </div>
                </React.Fragment>
            :
                <React.Fragment>
                    <div className="hidden md:block">
                        <CardTableBody column={['','Status','Amount','Customer Email','Customer Name','Invoice Number','Date Created','']}>
                            {isArray(tableData.data) && renderInvoiceTable(tableData.data)}
                        </CardTableBody>
                    </div>

                    <div className="block md:hidden">
                        <div className="bg-white px-6">
                            {isArray(tableData.data) && renderInvoiceDesk(tableData.data)}
                        </div>
                    </div>
                </React.Fragment>
            }

            <CardTableFooter 
                empty={isEmpty(tableData.data)}
                loading={invoice.isLoading}
                pageNumber={currentPage} 
                totalPages={tableData.totalPages}
                prevOnClick={()=>setCurrentPage(tableData.previousPage)}
                nextOnClick={()=>setCurrentPage(tableData.nextPage)}
                thereIsPreviousPage={tableData.thereIsPreviousPage}
                thereIsNextPage={tableData.thereIsNextPage}
            />
        </div>
    )
}
