import React from "react";
import { GLADEFINANCE_LOGO } from "../../assets/images/images";

export default function GladeLogo({ alternate = false }) {
  return (
    <div className="inline-flex">
      {alternate ? (
        <img src={GLADEFINANCE_LOGO} alt="logo" />
      ) : (
        <img src={GLADEFINANCE_LOGO} alt="logo" />
      )}
    </div>
  );
}
