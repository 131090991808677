import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { CardTableHeaderPlain } from "../../components/cards/CardTableHeader";
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import useCustomers from "../../hooks/useCustomers";
import { getCustomerByEmail, blacklistCustomer, whitelistCustomer } from "../../redux/customers/customersActions";
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import {simpleDate, statusEnum, isArray, isEmpty, pagination} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import TextIndicator from '../../components/indicators/TextIndicator';
import CircleIndicator from "../../components/indicators/CircleIndicator";
import CopyTextButton from '../../components/buttons/CopyTextButton';
import Button from '../../components/buttons/Button';
import { TextSkeleton } from "../../components/skeletons";
import {IoIosArrowForward} from "react-icons/io";
import useTeam from "../../hooks/useTeam";

export default function SettlementDetail() {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const customers = useCustomers();
  const {permissions} = useTeam();

  const [input, setInput] = React.useState({});

  React.useEffect(() => {
    if (customers.customerByEmail?.user_email || location?.state?.user_email) {
      dispatch(getCustomerByEmail({email: location?.state?.user_email}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers.customerByEmail?.email, location?.state?.user_email, customers.shouldReload]);
  
  React.useEffect(() => {

    if (customers.customerByEmail?.email || location?.state?.user_email) {
      setInput({
        user_email: customers.customerByEmail?.email ?? location?.state?.user_email,
        user_name: customers.customerByEmail?.last_customer_transaction?.user_name ?? location?.state?.user_name,
        user_phone: customers.customerByEmail?.last_customer_transaction?.user_phone == null ? 'N/A' :  customers.customerByEmail?.last_customer_transaction?.user_phone ?? location?.state?.user_phone,
        created_at: customers.customerByEmail?.created_at ?? location?.state?.created_at,
        blacklisted: customers.customerByEmail?.blacklisted ?? location?.state?.blacklisted,
        id: customers.customerByEmail?.id ?? location?.state?.id,
        currency: customers.customerByEmail?.filters_curr,
        total_txns: customers.customerByEmail?.history?.total_txns,
        total_amount: customers.customerByEmail?.history?.total_amount,
        last_txn_date: customers.customerByEmail?.history?.last_txn_date,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customers.customerByEmail?.email, location?.state]);

  const blacklistACustomer = () => {
    dispatch(blacklistCustomer({customer_email:input.user_email}));
  }

  const whitelistACustomer = () => {
    dispatch(whitelistCustomer({customer_email:input.user_email}));
  }

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(customers.customerByEmail?.recent_transactions ?? [], 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, customers.customerByEmail?.recent_transactions]);

  const renderTransactionsTable = (data) => {
    return data.map((item,key)=>{
      return (
        <CardTableBodyRow key={key}>
          <CardTableBodyCell>
            <TextIndicator type={statusEnum[item?.txn_status]} title={item?.txn_status} />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">{simpleDate(item?.created_at,'numeric','short')}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">{item?.user_email}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap">{item?.payment_type}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two whitespace-nowrap"><CopyTextButton text={item?.txn_ref} /></span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-one whitespace-nowrap">{item?.currency} {Number(item?.value ?? 0)?.toLocaleString()}</span>
          </CardTableBodyCell>

        </CardTableBodyRow>
      )
    });
  }

  const renderTransactionsDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div key={key} className="flex flex-row justify-between p-1 border-b" onClick={()=>history.push({pathname: !permissions?.includes("customers") ? '/dashboard/customers/customer-detail/transaction-detail' : '/teams/customers/customer-detail/transaction-detail', state:item})}>
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.txn_status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item?.currency} {Number(item?.value)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.created_at, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Customers"} path={ !permissions?.includes("customers") ? "/dashboard/customers" : "/teams/customers"} />
        </div>

        <div className="flex flex-row justify-end">
          {input.blacklisted?
            <div>
              <Button.Success
                loading={customers.isLoadingDetails}
                disabled={customers.isWhitelisting}
                title={customers.isLoadingDetails ? "Whitelisting" : "Whitelist"}
                className="mr-3"
                onClick={() => whitelistACustomer()}
              />
            </div>
          :
            <div>
              <Button.Danger
                loading={customers.isLoadingDetails}
                disabled={customers.isBlacklisting}
                title={customers.isLoadingDetails ? "Blacklisting" : "Blacklist"}
                className="mr-3"
                onClick={() => blacklistACustomer()}
              />
            </div>
          }
        </div>

        <div className="flex flex-col bg-white rounded-sm p-3 my-6">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">Customer Details</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-4 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Customer Name</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {input.user_name??<TextSkeleton width="200px" />}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Customer Email</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {input.user_email??<TextSkeleton width="200px" />}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Phone Number</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {input.user_phone??<TextSkeleton width="200px" />}
              </h6>
            </div>
          </div>
        </div>

        <div className="flex flex-col bg-white rounded-sm p-3 my-6">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">Customer History</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-4 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Total Spend</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {input.total_amount? `${input.currency} ${input.total_amount}` :<TextSkeleton width="200px" />}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Total Transactions</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {input.total_txns??<TextSkeleton width="200px" />}
              </h6>
            </div>
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Last Transaction Date</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {input.last_txn_date? simpleDate(input.last_txn_date,'numeric','short') : <TextSkeleton width="200px" />}
              </h6>
            </div>
          </div>
        </div>

        <div className="">
          <CardTableHeaderPlain
            title={`Transactions - ${tableData.totalItems}`}
          />

          {isEmpty(tableData.data) || customers.isLoadingDetails?
            <React.Fragment>
              <div className="hidden md:block">
                <EmptyTable column={['Status','Date','Customer','Payment Type','Transaction Ref','Amount','']} loading={customers.isLoadingDetails} />
              </div>

              <div className="block md:hidden">
                <EmptyDesk row={[1,2]} loading={customers.isLoadingDetails} />
              </div>
            </React.Fragment>
          :
            <React.Fragment>
              <div className="hidden md:block">
                <CardTableBody column={['Status','Date','Customer','Payment Type','Transaction Ref','Amount','']}>
                  {isArray(tableData.data) && renderTransactionsTable(tableData.data)}
                </CardTableBody>
              </div>

              <div className="block md:hidden">
                <div className="bg-white px-6">
                  {isArray(tableData.data) && renderTransactionsDesk(tableData.data)}
                </div>
              </div>
            </React.Fragment>
          }

          <CardTableFooter 
            empty={isEmpty(tableData.data)}
            loading={customers.isLoadingDetails}
            pageNumber={currentPage} 
            totalPages={tableData.totalPages}
            prevOnClick={()=>setCurrentPage(tableData.previousPage)}
            nextOnClick={()=>setCurrentPage(tableData.nextPage)}
            thereIsPreviousPage={tableData.thereIsPreviousPage}
            thereIsNextPage={tableData.thereIsNextPage}
          />
        </div>
      </div>
    </div>
  );
}