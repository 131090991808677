import React from 'react'
import { ID_HERO } from '../../../assets/images/images'
import Button from '../../../components/buttons/Button'
import {RiCheckboxCircleLine} from 'react-icons/ri'

const IDView = React.forwardRef((props, forwardedRef) => {
  const {setIdUploadField} = props
  const IdInstruction = [
    'Make sure your ID is clear',
    'Make sure your ID information is visble to read'
  ]
  return (
    <div ref={forwardedRef} className="w-full max-w-sm">
      <div className="flex flex-col justify-center">

        <h3 className="mt-6 glade-heading-two-bold glade-black text-center">ID verification</h3>
        <p className="mt-2 glade-subtitle-two glade-grey-dark text-center">Verify your identity with a government issued ID.</p>
      </div>
      <div className="mt-8 space-y-12 flex flex-col justify-center items-center">
        <div className='pt-4'>
          <img src={ID_HERO} alt='selfie emoji' />
        </div>
        <div className='flex space-y-3 flex-col'>
          {IdInstruction.map((ins, i) => (
            <div className='flex space-x-3 items-center' key={i}>
              <RiCheckboxCircleLine color="#00D220" size={24} />
              <p className='glade-subtitle-one'>{ ins}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-col justify-center pt-3 space-y-3">

          <Button.Blue
            onClick={() => setIdUploadField(true)}
            title={"Verify"}
            className={"text-center py-2"}
          />
          {/* <Button.Base
            title={'Go back'}
            type={'button'}
            className={'py-3 glade-bkg-light-blue glade-blue'}
            onClick={() => props?.formWizardRef?.current?.goTo(3)}
          /> */}
        </div>

        <div className="flex justify-center w-full">
          
        </div>
      </div>
    </div>
  )
})

export default IDView