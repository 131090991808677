import {
  GET_ACCOUNT_LIMIT_FAILURE,
  GET_ACCOUNT_LIMIT_LOADING,
  GET_ACCOUNT_LIMIT_SUCCESS,
  REQUEST_ACCOUNT_LIMIT_FAILURE,
  REQUEST_ACCOUNT_LIMIT_LOADING,
  REQUEST_ACCOUNT_LIMIT_SUCCESS,
  GET_SYSTEM_ACCOUNT_TIER_FAILURE,
  GET_SYSTEM_ACCOUNT_TIER_LOADING,
  GET_SYSTEM_ACCOUNT_TIER_SUCCESS,

  REQUEST_UPGRADE_TIER_LOADING, 
  REQUEST_UPGRADE_TIER_SUCCESS, 
  REQUEST_UPGRADE_TIER_FAILURE,
} from "./types";

const initialState = {
  isLoading: false,
  limit_data: "",
  limit_data_fail: false,
  updateSuccess: false,
};

export default function accountLimitReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_LIMIT_LOADING:
      return { ...state, isLoading: true };
      case GET_SYSTEM_ACCOUNT_TIER_LOADING:
      return { ...state, isLoading: true };
    case REQUEST_ACCOUNT_LIMIT_LOADING:
      return { ...state, isLoading: true };
      case REQUEST_UPGRADE_TIER_LOADING:
        return { ...state, isLoading: true };
    case GET_ACCOUNT_LIMIT_SUCCESS:
      return { ...state, isLoading: false, limit_data: action.payload };
      case GET_SYSTEM_ACCOUNT_TIER_SUCCESS:
        return { ...state, isLoading: false, system_data: action.payload };
    case REQUEST_ACCOUNT_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        limit_data: { ...state.limit_data, updated: true },
      };
      case REQUEST_UPGRADE_TIER_SUCCESS:
        return {
          ...state,
          isLoading: false,
          limit_data: { ...state.limit_data, updated: true },
        };
    case GET_ACCOUNT_LIMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        limit_data_fail: true,
        limit_data: action.payload,
      };
      case GET_SYSTEM_ACCOUNT_TIER_FAILURE:
        return {
          ...state,
          isLoading: false,
          system_data_fail: true,
          system_data: action.payload,
        };
    case REQUEST_ACCOUNT_LIMIT_FAILURE:
      return { ...state, isLoading: false };
      case REQUEST_UPGRADE_TIER_FAILURE:
        return { ...state, isLoading: false };
    default:
      return state;
  }
}
