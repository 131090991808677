import { useSelector } from "react-redux";

const useNotifications = () => {
  const notifications = useSelector((state) => state.notification);

  const {
    isLoading,
    dataFetchFail,
    notificationsData,
    markedAsRead,
    inAppNotificationsData,
    isInAppNotificationLoading
  } = notifications;

  return {
    isLoading,
    dataFetchFail,
    markedAsRead,
    notificationsData,
    inAppNotificationsData,
    isInAppNotificationLoading
  }
}

export default useNotifications;