import React, { useEffect } from "react";
import { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import Button from "../../../components/buttons/Button";
import FileInput from "../../../components/inputs/FileInput";
import NumericInput from "../../../components/inputs/NumericInput";
import SelectInput from "../../../components/inputs/SelectInput";
import TextInput from "../../../components/inputs/TextInput";
import { isArray, objectToFormData, toTitleCase } from "../../../helpers/helper";
import useSettings from "../../../hooks/useSettings";
import { resolveBvn } from "../../../redux/auth/authActions";
import { addBusinessDirector, addPrimaryOwner } from "../../../redux/settings/addBusinessOwners/businessOwnersActions";
import { getComplianceStates } from "../../../redux/settings/settingsActions";
import { addOwnerInformationValidation } from "../../../validation/settings/settingsValidation";
import { getApprovedLGAs } from "../../../redux/countries/countriesActions";
import useCountries from "../../../hooks/useCountry";

const AddOwnerInformation = ({ formFields, setFormLevel, setOwnerList, setDirectorList, directorList, setTrusteeList, trusteeList, input }) => {
    const settings = useSettings();
    const dispatch = useDispatch();
  const countries = useCountries();

    const [stateInput, setStateInput] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        state: '',
        lga: ''
    })
    const [inputError, setInputError] = useState({});
    const [disabled, setDisabled] = useState(false);
    const onChange = (e) => {
        setStateInput({ ...stateInput, [e.target.name]: e.target.value });
        setInputError({ ...inputError, [e.target.name]: false });
    };

  useEffect(() => {
      dispatch(getComplianceStates({ country_id: input?.compliance_country }));  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formFields.data.forEach((field) => {
            setStateInput((prev) => { return { ...prev, [field.name]: '' } })
        })
    }, [formFields])
    useEffect(() => {
        (stateInput.state) && dispatch(getApprovedLGAs({ state_id: stateInput.state }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateInput.state]);

    const handleSubmit = () => {
        //Validate Data
        let isInputValid = addOwnerInformationValidation(formFields.type, stateInput);
        //Get compliance_id
        let id = isArray(settings.submitBusinessInformation) ? 
        settings.submitBusinessInformation[0]['id'] 
        : settings.submitBusinessInformation.data?.compliance_id;
        if (isArray(isInputValid)) {
            isInputValid.forEach((field) => {
                setInputError((prev) => ({ ...prev, [field]: true }));
            });
        } else {
            // dispatch data
            const onSuccess = (res) => {
                let director_compliance_id = res?.data?.data?.director_compliance_id;
                if (formFields.type === "owner") setOwnerList({ ...stateInput, compliance_id: id })
                else if (formFields.type === 'director') setDirectorList([...directorList, { ...stateInput, compliance_id: id, director_compliance_id }])
                else if (formFields.type === 'trustee') setTrusteeList([...trusteeList, { ...stateInput, compliance_id: id, director_compliance_id }])
                // set form level
                setDisabled(false);
                setFormLevel('owner information');
            }
            const onFailure = () => {
                setDisabled(false);
            }

            setDisabled(true);

            if (formFields.type === "owner") {
                let postdata = objectToFormData({ ...stateInput, type: 'business_primary_owner', id_card: stateInput.id_card[0] });
                dispatch(
                    addPrimaryOwner(
                        id,
                        postdata, onSuccess, onFailure)
                )
            }
            else if (formFields.type === 'director' || formFields.type === 'trustee') {
                let postdata = { ...stateInput, type: 'business_director', id_card: stateInput.id_card[0] };
                delete postdata['state'];
                delete postdata['lga'];
                dispatch(resolveBvn({
                    bvn: stateInput?.bvn,
                    first_name: stateInput.first_name,
                    last_name: stateInput.last_name,
                    owner_type: "business"
                },
                    // On success function
                    () =>
                        dispatch(
                            addBusinessDirector(
                                id,
                                objectToFormData(postdata), onSuccess, onFailure)
                        ),
                    // On Failure function
                    () => {
                        setDisabled(false);
                    }
                ));

            };
        }

    }
    return (
        <div>
            <>
                {
                    formFields?.type !== 'owner' ?
                        <div className="capitalize flex justify-between items-center border-b p-4 pt-0 font-bold mb-6">Add {formFields?.type} <IoCloseOutline className="cursor-pointer" size={'24'} onClick={() => setFormLevel('owner information')} /></div>
                        :
                        <div className="px-12">
                            <h3 className="font-bold mb-5 text-glade-black-800">
                                Please add the primary owner of this business account
                            </h3>
                        </div>}
                <div>
                    <div className="w-full space-y-6 border-b border-gray-300 px-12 pb-8">
                        <div className="flex justify-between gap-8">
                            <div className="w-1/2">
                                <TextInput
                                    label={"First Name"}
                                    id={"first_name"}
                                    name={"first_name"}
                                    value={stateInput?.first_name ?? ""}
                                    onChange={onChange}
                                    placeholder={"John"}
                                    autoComplete={"first_name"}
                                    className={"appearance-none"}
                                    errorState={inputError.first_name}
                                />
                                {inputError.first_name && (
                                    <div className="text-glade-red-400 text-sm mt-2">
                                        {'First name is invalid'}
                                    </div>
                                )}
                            </div>
                            <div className="w-1/2">
                                <TextInput
                                    label={"Last Name"}
                                    id={"last_name"}
                                    name={"last_name"}
                                    value={stateInput?.last_name ?? ""}
                                    onChange={onChange}
                                    placeholder={"Doe"}
                                    autoComplete={"last_name"}
                                    className={"appearance-none"}
                                    errorState={inputError.last_name}
                                />
                                {inputError.last_name && (
                                    <div className="text-glade-red-400 text-sm mt-2">
                                        {'Last Name is invalid'}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <TextInput
                                label={"Email"}
                                id={"email"}
                                name={"email"}
                                value={stateInput?.email ?? ""}
                                onChange={onChange}
                                placeholder={"Enter Email"}
                                autoComplete={"email"}
                                className={"appearance-none"}
                                errorState={inputError.email}
                            />
                            {inputError.email && (
                                <div className="text-glade-red-400 text-sm mt-2">
                                    {'Enter a valid Email Address'}
                                </div>
                            )}
                        </div>
                        <div>
                            <NumericInput
                                leftSection={
                                    <span className="glade-button-gray glade-home-dark self-center border border-gray-300 rounded-sm p-2">
                                        +234
                                    </span>
                                }
                                label={"Phone Number"}
                                id={"phone"}
                                name={"phone"}
                                value={stateInput?.phone ?? ""}
                                onChange={onChange}
                                placeholder={"0800000000"}
                                autoComplete={"phone"}
                                className={"appearance-none"}
                                errorState={inputError.phone}
                            />
                            {inputError.phone && (
                                <div className="text-glade-red-400 text-sm mt-2">
                                    {'Enter a valid phone number'}
                                </div>
                            )}
                        </div>
                        {formFields?.data?.map(
                            (field, index) =>
                                <div key={index} >
                                    {
                                        field.type === 'select' &&
                                        <div>
                                            <SelectInput
                                                label={field.label}
                                                id={field.name}
                                                name={field.name}
                                                value={stateInput[`${field.name}`] ?? ""}
                                                onChange={onChange}
                                                autoComplete={field.name}
                                                className={"appearance-none"}
                                                errorState={inputError[`${field.name}`]}
                                            >
                                                <option value={""} disabled={true}>
                                                    Select Id Type
                                                </option>
                                                {isArray(settings.complianceIdentityTypes) &&
                                                    settings.complianceIdentityTypes?.map((type, index) => (
                                                        <option key={index} value={type.type_name}>
                                                            {toTitleCase(type.type_name ?? "")}
                                                        </option>
                                                    ))}
                                            </SelectInput>
                                            {inputError[`${field.name}`] && (
                                                <div className="text-glade-red-400 text-sm mt-2">
                                                    {field.errorText}
                                                </div>
                                            )}

                                        </div>
                                    }
                                    {
                                        field.type === 'text' &&
                                        <div>
                                            <TextInput
                                                label={field.label}
                                                id={field.name}
                                                name={field.name}
                                                value={stateInput[field.name] ?? ""}
                                                onChange={onChange}
                                                placeholder={field.placeholder}
                                                autoComplete={field.name}
                                                className={"appearance-none"}
                                                errorState={inputError[`${field.name}`]}
                                            />
                                            {inputError[`${field.name}`] && (
                                                <div className="text-glade-red-400 text-sm mt-2">
                                                    {field.errorText}
                                                </div>
                                            )}

                                        </div>
                                    }
                                    {
                                        field.type === 'number' &&
                                        <div>
                                            <NumericInput
                                                label={field.label}
                                                id={field.name}
                                                name={field.name}
                                                value={stateInput[field.name] ?? ""}
                                                onChange={onChange}
                                                placeholder={field.placeholder}
                                                autoComplete={field.name}
                                                className={"appearance-none"}
                                                errorState={inputError[`${field.name}`]}
                                            />
                                            {inputError[`${field.name}`] && (
                                                <div className="text-glade-red-400 text-sm mt-2">
                                                    {field.errorText}
                                                </div>
                                            )}
                                        </div>
                                    }
                                    {
                                        field.type === 'file' &&
                                        <div>
                                            <FileInput
                                                label={field.label}
                                                id={field.name}
                                                name={field.name}
                                                multiple={true}
                                                accept=".png, .jpeg, .pdf, .jpg"
                                                maximumSize={3}
                                                returnInput={(value) =>
                                                    setInputError({ ...inputError, [field.name]: false }) & setStateInput({ ...stateInput, [field.name]: value })
                                                }
                                                errorState={inputError[`${field.name}`]}
                                            />
                                            {inputError[field.name] ?
                                                <div className="text-glade-red-400 text-sm mt-2">
                                                    Upload a valid format (JPG, PNG, PDF)
                                                </div>
                                                :
                                                <div className="text-glade text-sm mt-2">
                                                    {field.supportText}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                        )}
                        {
                            formFields.type === 'owner' &&
                            <div className="space-y-6">
                                <div>
                                    <SelectInput
                                        label={"State"}
                                        id={"state"}
                                        name={"state"}
                                        value={stateInput?.state ?? ""}
                                        onChange={onChange}
                                        autoComplete={"state"}
                                        className={"appearance-none"}
                                        errorState={inputError.state}
                                    >
                                        <option value={""} disabled={true}>
                                            Select A State
                                        </option>
                                        {isArray(settings.complianceStates) &&
                                            settings.complianceStates?.map((state, index) => (
                                                <option key={index} value={state.state_id}>
                                                    {state.state_name}
                                                </option>
                                            ))}
                                    </SelectInput>
                                    {inputError.state && (
                                        <div className="text-glade-red-400 text-sm mt-2">
                                            State is a required field
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <SelectInput
                                        label={"LGA"}
                                        id={"lga"}
                                        name={"lga"}
                                        value={stateInput?.lga ?? ""}
                                        onChange={onChange}
                                        autoComplete={"business_lga"}
                                        className={"appearance-none"}
                                        errorState={inputError.lga}
                                    >
                                        <option value={''} disabled={true}>Select A Local Government Area</option>
                                        {isArray(countries.getLgas) && countries.getLgas?.map((lga, index) => (
                                            <option key={index} value={lga.id}>{lga.name}</option>
                                        ))}
                                    </SelectInput>
                                    {inputError.lga && (
                                        <div className="text-glade-red-400 text-sm mt-2">
                                            LGA is a required field
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="p-6">
                    <div className="w-1/3 md:w-1/4 float-right">
                        <Button.Blue
                            onClick={() => handleSubmit()}
                            className="w-full"
                            title={"Next"}
                            disabled={disabled}
                            loading={disabled}
                        />
                    </div>
                </div>
            </>
        </div>
    )
}

export default AddOwnerInformation;