import React from 'react';

export function CardPlainWithoutButtonSection({
    children,
    type='single',
    title,
    titleSection,
    className='',
}) {
    return (
        <div className={type==='single'? "md:col-start-3 md:col-span-4 flex flex-col "+className:type==='dual'? "flex flex-col "+className:null}>
            <div className="bg-white py-4 overflow-hidden rounded-sm shadow-lg">
                {titleSection??
                <div className="flex justify-center py-2 border-b-2 border-solid border-gray-200">
                    <h4 className="glade-subtitle-one glade-black">{title??'Title'}</h4>
                </div>}
                <div className="px-6 my-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export function CardPlainWithoutButton({children, type='single'}) {
    return (
        <div className={type==='single'?"grid grid-cols-1 md:grid-cols-8 gap-1 md:gap-4":type==='dual'?"grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4":null}>
            {children}
        </div>
    )
}