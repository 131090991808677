import React from "react";
import styled from "styled-components";

import InfoIcon from "../transfer/InfoIcon";

const RateInfo = ({ debitcurrency, receivecurrency, amount, rate }) => {
  return (
    <Div>
      <section className="flex items-center gap-2">
        <InfoIcon />
        <span>Rate</span>
      </section>

      <section>
        <span>1 {debitcurrency?.toUpperCase()}</span>
        <span> = </span>
        <span>
          {1 / rate} {receivecurrency?.toUpperCase()}
        </span>
      </section>
      {amount && (
        <section>
          <span>
            {amount} {" " + receivecurrency?.toUpperCase()}
          </span>
          <span> = </span>
          <span>
            {(rate * amount || 0).toFixed(2)} {debitcurrency?.toUpperCase()}
          </span>
        </section>
      )}
      <section>
        <span className="font-bold">
          Your total ={" "}
          {receivecurrency?.toLowerCase() === "ngn"
            ? Number(String((
                amount
                  ? (Number(amount * 0.02) + Number(amount) + Number(1000)) *
                      rate
                  : 0
              )
              .toFixed(2)))
              .toLocaleString("en-US")
            : Number(String((amount
                ? (Number(amount * 0.02) + Number(amount) + Number(1)) * rate
                : 0
              )
                .toFixed(2)))
                .toLocaleString("en-US")}{" "}
          {debitcurrency?.toUpperCase()}
        </span>
      </section>
    </Div>
  );
};

export default RateInfo;

const Div = styled.div`
  background: #fafafa;
  padding: 1.5rem 2rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0 0 1rem;
  overflow-x: auto;
  color: #6d6d6d;
`;
