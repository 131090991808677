import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import Button from "../../components/buttons/Button";
import useVirtualCards from "../../hooks/useVirtualCards";
import { simpleDate, pagination } from "../../helpers/helper";
import Sieve from "../../components/filter/Sieve";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import { useLocation } from "react-router-dom";
import { getVirtualCardTransactions } from "../../redux/virtual-cards/virtualCardActions";
import { TextSkeleton } from "../../components/skeletons";
import { downloadCardAsCSV } from "./DownloadCardAsCSV";
import SearchInput from "../../components/inputs/SearchInput";
import { PaginatedTable } from "../../components/table/Table";
import useQuery from "../../hooks/useQuery";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function CardTransactions() {
  const dispatch = useDispatch();
  const virtualCards = useVirtualCards();
  const [currentPage, setCurrentPage] = useState(1);
  const query = useQuery();
  React.useEffect(() => {
    dispatch(
      getVirtualCardTransactions({
        reference: query.get("reference"),
        paginate: 1,
        current_page: currentPage,
        per_page: 10,
      })
    );
    // eslint-disable-next-line
  }, [virtualCards.shouldReload, currentPage]);

  const dataForCSV = virtualCards.virtualCardTransactions;

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getVirtualCardTransactions({
        reference: query.get("reference"),
        status: data?.card_transaction_status,
      })
    );
  };

  // Search Function And Control
  const onSearch = (search) => {
    search
      ? dispatch(
          getVirtualCardTransactions({
            reference: query.get("reference"),
            paginate: 1,
            current_page: 1,
            per_page: 10,
            search,
          })
        )
      : dispatch(
          getVirtualCardTransactions({
            reference: query.get("reference"),
            paginate: 1,
            current_page: 1,
            per_page: 10,
          })
        );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(
      getVirtualCardTransactions({ reference: query.get("reference") })
    );
  };

  const columns = [
    {
      Header: "",
      accessor: "none",
      Cell: ({ value }) => <span></span>,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span
          className={`py-1 px-4 rounded-md ${
            value === "successful"
              ? "bg-green-100 text-green-500"
              : "bg-red-100 text-red-500"
          }`}
        >
          {value === "successful" ? "Successful" : "Failed"}
        </span>
      ),
    },
    {
      Header: "Date",
      accessor: "transaction_date",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/virtual-cards/detail?reference=${row?.original?.reference}`}
        >
          <span className="glade-normal-text-two">
            {value && simpleDate(value, "numeric", "short", "2-digit")}
          </span>
        </Link>
      ),
    },
    {
      accessor: "transaction_ref",
      Header: "Transaction Ref",
      Cell: ({ value }) => (
        <span className="glade-normal-text-two">
          {value ? (
            <CopyTextButton text={value} truncate={false} />
          ) : (
            <TextSkeleton width="200px" />
          )}
        </span>
      ),
    },
    {
      accessor: "customer_detail",
      Header: "Description",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two">
          {row?.original?.customer_detail?.description ?? "N/A"}
        </span>
      ),
    },
    {
      accessor: "amount",
      Header: "Amount",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two">
          {row?.original?.meta_data?.currency}{" "}
          {Number(value ?? 0)?.toLocaleString()}
        </span>
      ),
    },
    {
      accessor: "none_one",
      Header: "",
      Cell: () => (
        <span className="glade-normal-text-two">
        </span>
      ),
    },
  ];

  return (
    <div className="px-3">
      <CardTableHeaderComplex
        title={"Recent Transactions"}
        filter={
          <Sieve
            types={["card transaction status"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={virtualCards.isLoading}
            loading={virtualCards.isLoading}
          />
        }
        searchBar={
          <SearchInput
            disabled={virtualCards.isLoading}
            onSearch={onSearch}
            placeholder={"Search Transaction"}
          />
        }
        rightButton={
          <div>
            <Button.Blue
              onClick={() => downloadCardAsCSV(dataForCSV)}
              className="glade-button-blue"
              title={"Download CSV"}
            />
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={virtualCards.isLoading}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["card transaction status"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={virtualCards.isLoading}
                    loading={virtualCards.isLoading}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <div
                    onClick={() => downloadCardAsCSV(dataForCSV)}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Download CSV
                  </div>
                }
              />
            </KebabDropdown>
          </div>
        }
      />
      <PaginatedTable
        columns={columns}
        data={
          typeof virtualCards.virtualCardTransactions === "object" &&
          Array.isArray(virtualCards.virtualCardTransactions?.data)
            ? virtualCards.virtualCardTransactions?.data
            : []
        }
        empty_message="No Virtual card's Transaction"
        empty_sub_message=""
        current_page={currentPage}
        loading={virtualCards?.isLoading}
        setCurrentPage={setCurrentPage}
        totalPages={virtualCards.virtualCardTransactions?.total}
      />
    </div>
  );
}
