import { useSelector } from "react-redux";

const useBills = () => {
  const bills = useSelector((state) => state.bills);

  const {
    isLoading,
    shouldReload,
    billsIndex,
    billers,
    billTypes,
    billerItems,
    meterDetails,
    internetAccountDetails,
    cableTvDetails,
    buyAirtime,
    buyElectricity,
    buyCableTv,
    buyInternet,
    beneficiary,
  } = bills;

  const billHistory = billsIndex?.filter(
    (bill) => bill.bill_category !== "Airtime",
  );

  const billsTypeCategories = billTypes?.categories?.filter(
    (category) => category.category_code !== "airtime",
  );

  return {
    isLoading,
    shouldReload,
    billsIndex,
    billHistory: billHistory ?? [],
    billers,
    billTypes: {...billTypes, categories: billsTypeCategories ?? []},
    billerItems,
    meterDetails,
    internetAccountDetails,
    cableTvDetails,
    buyAirtime,
    buyElectricity,
    buyCableTv,
    buyInternet,
    beneficiary,
  };
};

export default useBills;
