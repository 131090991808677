import React from 'react';

export default function DateInput({
    label, 
    id,
    name, 
    type,
    value, 
    min,
    max,
    onChange=()=>{}, 
    placeholder, 
    autoComplete,
    disabled=false, 
    className, 
    leftSection, 
    rightSection
}) {
    return (
        <div className="space-y-1 col-span-6 sm:col-span-3">
            <label htmlFor={name??'name'} className="block glade-small-text-two glade-black">
                {label??''}
            </label>
            <div className="mt-1 flex rounded-sm shadow-sm">
                {leftSection??<React.Fragment></React.Fragment>}
                <input
                    type={type??"date"}
                    name={name??'name'}
                    id={id??name??'id'}
                    value={value??''}
                    min={min??''}
                    max={max??''}
                    onChange={onChange}
                    disabled={disabled}
                    className={className + " focus:ring-blue-800 focus:border-blue-800 glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm border-gray-300 placeholder-gray-400"}
                    placeholder={placeholder??''}
                    autoComplete={autoComplete??''}
                />
                {rightSection??<React.Fragment></React.Fragment>}
            </div>
        </div>
    );
}
