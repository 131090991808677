// Application types
export const LOADING_INVOICE = 'LOADING_INVOICE';
export const RELOAD_INVOICE = 'RELOAD_INVOICE';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID';
export const GET_INVOICE_COUNTER = 'GET_INVOICE_COUNTER';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_INVOICE_DRAFT = 'CREATE_INVOICE_DRAFT';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_STATUS = 'UPDATE_INVOICE_STATUS';
export const DELETE_INVOICE = 'DELETE_INVOICE';

export const GET_INVOICE_CURRENCIES = 'GET_INVOICE_CURRENCIES';
export const SEND_INVOICE_REMINDER = 'SEND_INVOICE_REMINDER';

export const CREATE_INVOICE_CUSTOMER = 'CREATE_INVOICE_CUSTOMER';
export const GET_INVOICE_CUSTOMER_WHITELISTED = 'GET_INVOICE_CUSTOMER_WHITELISTED';
export const GET_INVOICE_CUSTOMER_BLACKLISTED = 'GET_INVOICE_CUSTOMER_BLACKLISTED';