// Application types
export const LOADING_CUSTOMERS = 'LOADING_CUSTOMERS';
export const LOADING_CUSTOMER_DETAILS = 'LOADING_CUSTOMER_DETAILS';
export const RELOAD_CUSTOMERS = 'RELOAD_CUSTOMERS';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const BLACKLIST_CUSTOMER = 'BLACKLIST_CUSTOMER';
export const WHITELIST_CUSTOMER = 'WHITELIST_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID';
export const GET_CUSTOMER_BY_EMAIL = 'GET_CUSTOMER_BY_EMAIL';
export const GET_BLACKLISTED_CUSTOMERS = 'GET_BLACKLISTED_CUSTOMERS';
export const GET_WHITELISTED_CUSTOMERS = 'GET_WHITELISTED_CUSTOMERS';