import React from "react";
import { CgClose } from "react-icons/cg";

const MultipleEmailInput = ({
  emails,
  email,
  handleKeyDown,
  handleChange,
  handlePaste,
  error,
  handleDelete,
}) => {
  return (
    <>
      <div>
        <div className="border-2 p-2 mt-2 rounded-md">
          <div className="flex flex-column flex-wrap ">
            {emails.map((item) => (
              <div
                className="rounded-full glade-bg-input-grey p-2 space-x-2 font-semibold m-1"
                key={item}
              >
                <span>{item}</span>
                <button
                  type="button"
                  className="font-bold text-lg"
                  onClick={() => handleDelete(item)}
                >
                  <CgClose className="rounded-full glade-bg-deep-grey text-white" />
                </button>
              </div>
            ))}
          </div>
          <input
            type="text"
            name="beneficiary-email"
            className={`outline-transparent w-full focus:outline-transparent focus:ring-white focus:border-transparent border-transparent rounded-md`}
            value={email}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
          />
        </div>

        {error && <p className="text-red-400">{error}</p>}
      </div>
    </>
  );
};

export default MultipleEmailInput;
