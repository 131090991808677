import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { CardPlainWithButton, CardPlainWithButtonSection } from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import Button from "../../components/buttons/Button";
import { statusEnum, toTitleCase, simpleDate, isEmpty } from '../../helpers/helper';
import useInvoice from '../../hooks/useInvoice';
import useUser from '../../hooks/useUser';
import { deleteInvoiceAction, updateInvoiceStatus, sendInvoiceReminder, getInvoiceCustomersOnWhiteList } from '../../redux/invoice/invoiceActions';
import GLADE_LOGO from '../../components/logos/GladeLogo';
import TextIndicator from '../../components/indicators/TextIndicator';
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { ButtonWithDropDown, ButtonWithDropDownItem } from '../../components/buttons/ButtonWithDropDown';
import useTeam from '../../hooks/useTeam';

export default function InvoiceDetails() {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const invoice = useInvoice();
    const user = useUser();
    const {permissions} = useTeam();

    const [data, setData] = React.useState({ ...location?.state?.invoice, items: location?.state?.items });

    React.useEffect(() => {
        if (isEmpty(invoice?.whitelistedInvoiceCustomers?.customers)) {
            dispatch(getInvoiceCustomersOnWhiteList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const markAsPaid = () => {
        dispatch(
            updateInvoiceStatus({
                id: data?.id,
                status: 'paid'
            }, () => { setData({ ...data, status: 'paid' }); history.goBack(); })
        );
    }

    const sendReminder = () => {
        dispatch(
            sendInvoiceReminder({
                id: data?.id,
            }, () => history.goBack())
        );
    }

    const deleteInvoice = () => {
        dispatch(
            deleteInvoiceAction({
                id: data?.id
            }, () => history.goBack())
        );
    }

    const calculateSubTotal = (data)=>{
        return data?.items.map(item => item.quantity * item.unit_cost).reduce((acc, item) => acc + item);
    }

    return (
        <div className="p-3 pb-52">
            <div className="flex flex-col">

                <div className="mb-6">
                    <BreadCrumbs title={'Invoice'} path={!permissions?.includes("invoicing") ? '/dashboard/invoices' : '/teams/invoices'} />
                </div>

                <div className="flex justify-between items-center mb-3">
                    <BasicIndicator
                        className="px-5 py-1 capitalize"
                        type={statusEnum[data.status]}
                        title={data.status}
                    />
                    {data.status !== 'paid' ?
                        <ButtonWithDropDown className="z-10" title="Actions">
                            <ButtonWithDropDownItem
                                activeChild={<span onClick={() => sendReminder()} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Send Reminder</span>}
                                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Send Reminder</span>}
                            />
                            <ButtonWithDropDownItem
                                activeChild={<span onClick={() => history.push({ pathname: !permissions?.includes("invoicing") ? "/dashboard/invoice/edit-invoice" : "/teams/invoice/edit-invoice", state: data })} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Edit Invoice</span>}
                                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Edit Invoice</span>}
                            />
                            <ButtonWithDropDownItem
                                activeChild={<span onClick={() => markAsPaid()} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Mark as Paid</span>}
                                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Mark as Paid</span>}
                            />
                            <ButtonWithDropDownItem
                                activeChild={<span onClick={() => deleteInvoice()} className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">Delete Invoice</span>}
                                inactiveChild={<span className="glade-normal-text-two glade-black p-4">Delete Invoice</span>}
                            />
                        </ButtonWithDropDown> : null}
                </div>

                <CardPlainWithButton type={'single'}>
                    <CardPlainWithButtonSection
                        titleSection={<React.Fragment></React.Fragment>}
                        type={'single'}
                        loading={invoice.isLoading}
                        buttonDisabled={invoice.isLoading}
                        buttonSection={
                            data.status !== 'paid' ?
                                <div className="hidden md:flex flex-row space-x-4 mt-6">
                                    <Button.Blue
                                        title={"Send Reminder"}
                                        className="whitespace-nowrap"
                                        onClick={() => sendReminder()}
                                        disabled={invoice.isLoading}
                                    />
                                    <Button.Base
                                        title={"Edit Invoice"}
                                        className="whitespace-nowrap glade-button-gray"
                                        onClick={() => history.push({ pathname: !permissions?.includes("invoicing") ? "/dashboard/invoice/edit-invoice" : "/teams/invoice/edit-invoice", state: data })}
                                        disabled={invoice.isLoading}
                                    />
                                    <Button.Base
                                        title={"Mark as Paid"}
                                        className="whitespace-nowrap glade-button-gray"
                                        onClick={() => markAsPaid()}
                                        disabled={invoice.isLoading}
                                    />
                                    <Button.Base
                                        title={"Delete Invoice"}
                                        className="whitespace-nowrap glade-button-gray"
                                        style={{ color: '#ff0021' }}
                                        onClick={() => deleteInvoice()}
                                        disabled={invoice.isLoading}
                                    />
                                </div>
                                :
                                <React.Fragment></React.Fragment>
                        }>
                        <div className="mt-6 px-2">
                            <div className="flex flex-col space-y-6">

                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <GLADE_LOGO alternate={true} />
                                    </div>
                                    <div className="flex flex-col space-y-2 text-right">
                                        <TextIndicator type={statusEnum[data.status]} title={toTitleCase(data.status)} />
                                        <h4 className="glade-normal-text-one glade-black">Invoice: <span className="glade-normal-text-two">{data?.invoice_no}</span></h4>
                                        <h4 className="glade-normal-text-one glade-black">Due on: <span className="glade-normal-text-two">{simpleDate(data?.due_date)}</span></h4>
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between pb-8 border-b-2">
                                    <div className="flex flex-col">
                                        <h4 className="glade-normal-text-two glade-ash">From</h4>
                                        <h4 className="glade-normal-text-one glade-black">{user?.businessInfo?.business_name}</h4>
                                        <h4 className="glade-normal-text-two glade-black">{user?.businessInfo?.business_phone}</h4>
                                    </div>
                                    <div className="flex flex-col">
                                        <h4 className="glade-normal-text-two glade-ash">To</h4>
                                        <h4 className="glade-normal-text-one glade-black">
                                            {data.customer_id ? invoice.whitelistedInvoiceCustomers?.customers?.find(c => c.id.toString() === data.customer_id)?.user_name : ''}
                                        </h4>
                                        <h4 className="glade-normal-text-two glade-black">
                                            {data.customer_id ? invoice.whitelistedInvoiceCustomers?.customers?.find(c => c.id.toString() === data.customer_id)?.user_phone : ''}
                                        </h4>
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between pb-8 border-b-2">
                                    <div className="flex flex-col">
                                        {data?.items?.map((item, index) => (
                                            <h4 key={index} className="glade-normal-text-two glade-black">{toTitleCase(item.description)}</h4>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        {data?.items?.map((item, index) => (
                                            <h4 key={index} className="glade-normal-text-two glade-black text-right">
                                                {data.currency + ' ' + item.unit_cost}
                                                <span className="glade-normal-text-two glade-ash">{item.quantity ? ' x ' + item.quantity : ' x 1'}</span>
                                            </h4>
                                        ))}
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between pb-8 border-b-2">
                                    <div className="flex flex-col">
                                        <h4 className="glade-normal-text-one glade-black">Subtotal</h4>
                                        <h4 className="glade-normal-text-two glade-ash">Discount</h4>
                                        <h4 className="glade-normal-text-two glade-ash">VAT</h4>
                                        <h4 className="glade-normal-text-two glade-ash">Shipping</h4>
                                    </div>
                                    <div className="flex flex-col">
                                        <h4 className="h-full glade-normal-text-one glade-black text-right">{data.currency + ' ' + calculateSubTotal(data).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}</h4>
                                        <h4 className="h-full glade-normal-text-two glade-ash text-right">{data.currency + ' ' + (data?.discount / 100 * calculateSubTotal(data)).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}</h4>
                                        <h4 className="h-full glade-normal-text-two glade-ash text-right">{data.currency + ' ' + (data?.vat / 100 * (calculateSubTotal(data) - (data?.discount / 100 * calculateSubTotal(data)))).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}</h4>
                                        <h4 className="h-full glade-normal-text-two glade-ash text-right">{data.currency + ' ' + data?.shipping}</h4>
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between pb-8 border-b-2">
                                    <div className="flex flex-col">
                                        <h4 className="glade-normal-text-one glade-black">Total</h4>
                                    </div>
                                    <div className="flex flex-col">
                                        <h4 className="glade-normal-text-one glade-black">{data.currency + ' ' + Number(data?.total_value).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}</h4>
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="flex flex-col">
                                        <h4 className="glade-normal-text-two glade-ash">Notes</h4>
                                    </div>
                                    <div className="flex flex-col pt-3">
                                        <h4 className="glade-normal-text-two glade-black">{data?.note}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardPlainWithButtonSection>
                </CardPlainWithButton>
            </div>
        </div>
    )
}
