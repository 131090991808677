import React from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import useTransactionSplit from '../../hooks/useTransactionSplit';
import useTeam from "../../hooks/useTeam";

export default function TransactionSplitDetail() {

  const location = useLocation();
  const transactionSplit = useTransactionSplit();
  const {permissions} = useTeam();

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Transaction Split"} path={!permissions?.includes("transaction split") ? "/dashboard/transaction-split" : "/teams/transaction-split"} />
        </div>

        <div>
          <div className="flex flex-col bg-white rounded-sm p-3 my-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
              <h3 className="glade-heading-four glade-black">Split Details</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-4 py-3">
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Name</h5>
                <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.split_name}</h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Bank Account</h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                    {transactionSplit?.splitAccountIndex?.banks[location?.state?.bank_code]}
                    {transactionSplit?.splitAccountIndex?.banks[location?.state?.bank_code] && <br/>}
                    {location?.state?.account_number}
                </h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Split Type</h5>
                <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.split_type}</h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Split Reference</h5>
                <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.reference}</h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Split Share</h5>
                <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.share}%</h6>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
