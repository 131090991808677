import { useEffect, useState } from "react";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/inputs/TextInput";
import FileInput from "../../components/inputs/FileInput";
import TransactionOptions from "./TransactionOptions";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isEmptyArray } from "../../helpers/helper";
import { reportTransaction } from "../../redux/transactions/transactionActions";
import { useDispatch } from "react-redux";
import ReportSuccess from "./ReportSuccess";
import {IoIosArrowBack} from 'react-icons/io';

const ReportTransaction = () => {
  const [loading, setLoading] = useState(false);
  const [showTransactionType, setShowTransactionType] = useState(false);
  const [showReportSuccess, setShowReportSuccess] = useState(false);
  const [options, setOptions] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [input, setInput] = useState({
    txn_type: {},
    transfer_type: {},
    txn_ref: location?.state?.txn_ref,
    user_description: "",
    image: [],
  });

  useEffect(() => {
    if (location?.state?.txn_source === "transfer" || location?.state?.transfer_type==="internal" || location?.state?.transfer_type==="external") {
      setInput({
        ...input,
        txn_type: { title: "Transfers", value: "transfer" },
        transfer_type: location?.state?.transfer_reciepient_bank 
          ? { title: "Transfer to/from External Bank", value: "external" }
          : { title: "Transfer to/from Glade Account", value: "internal" },
      });
    } else if (location?.state?.txn_source === "card") {
      setInput({
        ...input,
        txn_type: { title: "Card Payment", value: "card_payment" },
      });
    } else if((location?.state?.txn_source === "banking" && (location?.state?.narration?.includes("BILL") )) || location?.state?.bill_item === "TopUp" || location?.state?.bill_item) {
      setInput({ ...input, txn_type: { title: "Airtime or Bill Payment", value: "bill_payment" } });
    }else {
      setInput({ ...input, txn_type: { title: "Others", value: "others" } });
    }
  }, []);

  const selectOption = (type) => {
    if (type === "transfer_type") {
      setModalTitle("Transfer Type");
      setOptions([
        { title: "Transfer to/from Glade Account", value: "internal" },
        { title: "Transfer to/from External Bank", value: "external" },
      ]);
    } else if (type === "txn_type") {
      setModalTitle("Transaction Type");
      setOptions([
        { title: "Transfers", value: "transfer" },
        { title: "Card Payment", value: "card_payment" },
        { title: "Airtime or Bill Payment", value: "bill_payment" },
        { title: "Others", value: "others" },
      ]);
    }
  };
  const validateFields = () => {
    let error = [];
    Object.keys(input).forEach((field) => {
      if (field === "txn_type" && !input?.txn_type?.value) {
        error.push(field);
      } else if (
        field === "transfer_type" &&
        input?.txn_type?.value === "transfer" &&
        !input?.transfer_type?.value
      ) {
        error.push(field);
      }
      if (!input[field]) {
        error.push(field);
      }
    });
    if (isEmptyArray(error)) {
      return false;
    } else {
      return true;
    }
  };
  return (
    
    <div>
      <div className="inline-flex items-center justify-start glade-subtitle-two glade-black cursor-pointer ml-6 mt-6" onClick={()=> history.goBack()}>
        {<IoIosArrowBack />} <span className="ml-2">{"Back"}</span>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="w-full max-w-md mt-8">
          <div className="flex flex-col justify-center ">
            <p className="glade-heading-two flex flex-row justify-center">
              <span className="font-bold mb-2">Report Transaction</span>
            </p>
            <p className="flex flex-row justify-center mb-12">
              Please fill the information below
            </p>
            <div className="flex flex-col gap-4">
              <div>
                <p className="pb-1 block glade-small-text-two glade-black">
                  Transaction Type
                </p>
                <div className="border rounded-sm p-1.5 pl-2 pr-3 flex items-center justify-between cursor-pointer">
                  {input?.txn_type?.title ?? "Select"}
                </div>
              </div>
              {input?.txn_type?.value === "transfer" && (
                <div>
                  <p className="pb-1 block glade-small-text-two glade-black">
                    Transfer Type
                  </p>
                  <div className="border rounded-sm p-1.5 pl-2 pr-3 flex items-center justify-between cursor-pointer">
                    {input?.transfer_type?.title ?? "Select"}
                  </div>
                </div>
              )}
              <TextInput
                label={"Transaction Reference"}
                name={"txn_ref"}
                id={"txn_ref"}
                value={input?.txn_ref}
                onChange={(e) =>
                  setInput({ ...input, txn_ref: e.target.value })
                }
                disabled
              />
              <TextInput
                label={"Note"}
                placeholder={"Tell us about the transaction"}
                value={input?.user_description}
                onChange={(e) =>
                  setInput({ ...input, user_description: e.target.value })
                }
              />
              <div>
                <FileInput
                  label={"Attachments"}
                  id={"image"}
                  name={"image"}
                  multiple={false}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) => setInput({ ...input, image: value })}
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>
              </div>
              <div className="flex flex-row justify-center">
                <Button.Blue
                  title={"Confirm"}
                  disabled={validateFields()}
                  className={"p-3"}
                  loading={loading}
                  onClick={() =>
                    dispatch(
                      reportTransaction(
                        {
                          ...input,
                          txn_type: input.txn_type.value,
                          transfer_type: input.transfer_type.value,
                        },
                        () => setShowReportSuccess(true),
                        (status) => setLoading(status)
                      )
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {showTransactionType && (
          <TransactionOptions
            modalTitle={modalTitle}
            showTransactionType={showTransactionType}
            setShowTransactionType={setShowTransactionType}
            options={options}
            emitValue={(val) =>
              modalTitle !== "Transfer Type"
                ? setInput({ ...input, txn_type: val })
                : setInput({ ...input, transfer_type: val })
            }
          />
        )}
        {showReportSuccess && (
          <ReportSuccess
            showReportSuccess={showReportSuccess}
            setShowReportSuccess={setShowReportSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default ReportTransaction;
