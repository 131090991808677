import {
  LOADING_BUSINESS,
  LOADING_SUBMITTING_BUSINESS,
  RELOAD_BUSINESS,
  LOADING_ADD_TEAM_MEMBER,
  LOADING_TEAM_ROLES,
  GET_TEAM_DETAILS,
  GET_TEAM_ROLES,
  SUBMIT_REGISTERED_BUSINESS,
  SUBMIT_UNREGISTERED_BUSINESS,
  SUBMIT_UNREGISTERED_COMPANY,
  GET_BUSINESS_REGISTRATIONS,
  GET_BUSINESS_REGISTRATION_CHARGE,
  GET_BUSINESS_CATEGORIES,
  GET_BUSINESS_TYPES,
  GET_BUSINESS_CAC_TYPES,
  GET_BUSINESS_TYPE_CAC_FEE,
  SELECT_BUSINESS_CAC_TYPE,
  CHECK_DRAFT_STATUS,
  SUBMIT_BUSINESS_CAC_INFORMATION,
  CHECK_DRAFT_DETAILS,
  CANCEL_BUSINESS_CAC_REGISTRATION,
  SUBMIT_BUSINESS_CAC_OFFICER_INFORMATION,
  CAC_FEE_PAYMENT,
  GET_TEAM_LIST,
  DELETE_TEAM_LIST,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  EDIT_TEAM_MEMBER_SUCCESS,
  EDIT_TEAM_MEMBER_FAILURE,
  LOADING_EDIT_TEAM_MEMBER,
  TEAM_MEMBER_DETAILS_SUCCESS,
  LOADING_TEAM_MEMBER_DETAILS,
  TEAM_MEMBER_DETAILS_FAILURE,
} from "./types";

const initialState = {
  isLoading: false,
  isSubmittingBusiness: false,
  shouldReload: false,
  submitRegisteredBusiness: {},
  submitUnRegisteredBusiness: {},
  submitUnRegisteredCompany: {},
  businessRegistrationIndex: [],
  businessRegistrationCharge: {},
  businessCategories: [],
  businessTypes: [],
  businessCacTypes: [],
  businessCacTypeFee: [],
  selectBusinessCacType: {},
  draftStatus: {},
  addTeam: {},
  editTeamMember: {},
  teamDetails: {},
  teamRoles: [],
  isLoadingTeamRoles: false,
  isLoadingAddTeam: false,
  isLoadingEditTeam: false,
  isLoadingTeamDetails: false,
  submitBusinessCacInformation: {},
  draftDetails: [],
  cancelBusinessCacRegistration: {},
  teamList: [],
  deleteTeamMember: {},
};
export default function businessReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_TEAM_MEMBER_DETAILS:
      return {
        ...state,
        isLoadingTeamDetails: true
      }
    case LOADING_BUSINESS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOADING_SUBMITTING_BUSINESS:
      return {
        ...state,
        isSubmittingBusiness: action.payLoad,
      };

    case LOADING_TEAM_ROLES:
      return {
        ...state,
        isLoadingTeamRoles: action.payLoad,
      };

    case TEAM_MEMBER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingTeamDetails: false,
        teamDetails: action.payLoad.data.data[0],
      };
    case TEAM_MEMBER_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingTeamDetails: false,
        teamDetails: {},
      };

    case GET_TEAM_ROLES:
      return {
        ...state,
        teamRoles: action.payLoad.data.data,
      };
    case LOADING_EDIT_TEAM_MEMBER: 
    return {
      ...state,
      isLoadingEditTeam: true,
      editTeamMember: {}
    }
    case EDIT_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingEditTeam: false,
        editTeamMember: action.payLoad.data.data,
      };
    case EDIT_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        isLoadingEditTeam: false,
        editTeamMember: {},
      };

    case RELOAD_BUSINESS:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case SUBMIT_REGISTERED_BUSINESS:
      return {
        ...state,
        submitRegisteredBusiness: action.payLoad.data.data,
      };

    case SUBMIT_UNREGISTERED_BUSINESS:
      return {
        ...state,
        submitUnRegisteredBusiness: action.payLoad.data.data,
      };

    case SUBMIT_UNREGISTERED_COMPANY:
      return {
        ...state,
        submitUnRegisteredCompany: action.payLoad.data.data,
      };

    case GET_BUSINESS_REGISTRATIONS:
      return {
        ...state,
        businessRegistrationIndex: action.payLoad.data.data,
      };

    case GET_BUSINESS_REGISTRATION_CHARGE:
      return {
        ...state,
        businessRegistrationCharge: action.payLoad.data.data,
      };

    case GET_BUSINESS_CATEGORIES:
      return {
        ...state,
        businessCategories: action.payLoad.data.data,
      };
    case GET_BUSINESS_TYPES:
      return {
        ...state,
        businessTypes: action.payLoad.data.data,
      };
    case GET_BUSINESS_CAC_TYPES:
      return {
        ...state,
        businessCacTypes: action.payLoad.data.data,
      };
    case GET_BUSINESS_TYPE_CAC_FEE:
      return {
        ...state,
        businessCacTypeFee: action.payLoad.data.data,
      };
    case SELECT_BUSINESS_CAC_TYPE:
      return {
        ...state,
        selectBusinessCacType: action.payLoad.data.data,
      };
    case CHECK_DRAFT_STATUS:
      return {
        ...state,
        draftStatus: action.payLoad.data.data,
      };
      case LOADING_ADD_TEAM_MEMBER:
        return {
          ...state,
          isLoadingAddTeam: true,
          addTeam: {}
        };
    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingAddTeam: false,
        addTeam: action.payLoad.data.data,
      };
    case ADD_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        isLoadingAddTeam: false,
        addTeam: {}
      };

    case SUBMIT_BUSINESS_CAC_INFORMATION:
      return {
        ...state,
        submitBusinessCacInformation: action.payLoad.data.data,
      };
    case CHECK_DRAFT_DETAILS:
      return {
        ...state,
        draftDetails: action.payLoad.data.data,
      };
    case CANCEL_BUSINESS_CAC_REGISTRATION:
      return {
        ...state,
        cancelBusinessCacRegistration: action.payLoad.data.data,
      };
    case SUBMIT_BUSINESS_CAC_OFFICER_INFORMATION:
      return {
        ...state,
        submitBusinessCacOfficerInformation: action.payLoad.data.data,
      };
    case CAC_FEE_PAYMENT:
      return {
        ...state,
        cacFeePayment: action.payLoad.data.data,
      };
    case GET_TEAM_LIST:
      return {
        ...state,
        teamList: action.payLoad.data,
      };
    case DELETE_TEAM_LIST:
      return {
        ...state,
        deleteTeamMember: action.payLoad,
      };
    default:
      return state;
  }
}
