import React from 'react';
import { copyTextToClipboard, truncateText, maskCardNumber } from "../../helpers/helper";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";

export default function CopyTextButton({text='', textClassName='', type="span", truncate=true, maskAsCardNumber=false, ...props }) {

    const formatText = (text) => {
        if (truncate) {
            text = truncateText(text);
        }

        if (maskAsCardNumber) {
            text = maskCardNumber(text);
        }

        return text;
    }
    
    return (
        <div className={"flex items-center"}>
            {type==='span'?<span className={textClassName+" glade-normal-text-two"}>{formatText(text)}</span>:null}
            {type==='link'?<a className={textClassName+" glade-normal-text-two"} href={text} target="_blank" rel="noreferrer">{formatText(text)}</a>:null}
            <FiCopy
                size={props?.copyIcon?.size??16}
                color={props?.copyIcon?.color??"#AFAFAF"}
                className={props?.copyIcon?.className??"ml-3 cursor-pointer"}
                onClick={() => { copyTextToClipboard(text); toast.success("Copied to clipboard!",{position:toast.POSITION.TOP_RIGHT,theme: "colored"}); }}
            />
        </div>
    )
}
