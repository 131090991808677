import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import { getPayrollStaff } from "../../redux/payroll/payrollActions";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import usePayroll from "../../hooks/usePayroll";
import {
  simpleDate,
  isArray,
  isEmpty,
  pagination,
  toTitleCase,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import {
  ButtonWithDropDown,
  ButtonWithDropDownItem,
} from "../../components/buttons/ButtonWithDropDown";
import Button from "../../components/buttons/Button";
import PipedButton from "../../components/buttons/PipedButton";
import SearchInput from "../../components/inputs/SearchInput";
import Sieve from "../../components/filter/Sieve";
import PayrollStaffDeleteModal from "./PayrollStaffDeleteModal";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import PaydayCreateModal from "./PaydayCreateModal";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import PayrollStaffAddModal from "./PayrollStaffAddModal";
import useTeam from "../../hooks/useTeam";
import EmptyCard from "../../components/empty-states/EmptyCard";

export default function PayrollStaff() {
  const PayrollStaffDeleteModalRef = React.useRef();
  const PaydayCreateModalRef = React.useRef();
  const addPayrollStaffModalRef = React.useRef();

  const history = useHistory();
  const dispatch = useDispatch();
  const payroll = usePayroll();
  const {permissions} = useTeam();

  React.useEffect(() => {
    dispatch(getPayrollStaff());
    // eslint-disable-next-line
  }, [payroll.shouldReloadPayroll]);

  // User checkbox input
  const [input, setInput] = React.useState({});
  const handleInputChange = (e) => {
    if (e.target.name === "all" && payroll.payrollStaffIndex?.length > 0) {
      const newInput = { all: e.target.checked };
      payroll.payrollStaffIndex.forEach((staff) => {
        newInput[staff.id] = e.target.checked;
      });
      setInput(newInput);
    } else {
      setInput({ ...input, [e.target.name]: e.target.checked, all: false });
    }
  };

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(payroll.payrollStaffIndex, 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, payroll.payrollStaffIndex]);

  // Search Function And Control
  const onSearch = (search) => {
    const filteredData = payroll?.payrollStaffIndex?.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
    setTableData(pagination(filteredData ?? [], 10, currentPage));
  };

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getPayrollStaff({
        date_from: data.date_from ?? data.date,
        date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
      })
    );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getPayrollStaff());
  };

  // Extract staff id from input
  const staffList = (list) => {
    let newList = [];
    Object.keys(list).map(function (key) {
      if (list[key] === true && key !== "all") {
        return newList.push(key);
      }
      return key;
    });
    return newList;
  };

  const renderPayrollStaff = (data) => {
    return data?.map((item, key) => {
      return (
        <CardTableBodyRow key={key} className="cursor-pointer" >
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell >
            <span>
              <CheckboxInput
                label={item?.staff_number}
                name={item?.id}
                type={"checkbox"}
                checked={input[item?.id] ?? false}
                onChange={(e) => handleInputChange(e)}
                placeholder={"Charge user (optional)"}
                autoComplete={""}
                className={"appearance-none"}
              />
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>   history.push({
          pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/details" : "/teams/payroll/staff/details",
          state: item,
        })}>
            <span className="glade-normal-text-two">{item?.name}</span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>   history.push({
          pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/details" : "/teams/payroll/staff/details",
          state: item,
        })}>
            <span className="glade-normal-text-two">
              {toTitleCase(item?.bank_name ?? "")}
              <br />
              {item?.account_number}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>   history.push({
          pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/details" : "/teams/payroll/staff/details",
          state: item,
        })}>
            <span className="glade-normal-text-two">
              {item?.staff_position}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>   history.push({
          pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/details" : "/teams/payroll/staff/details",
          state: item,
        })}>
            <span className="glade-normal-text-two">{item?.phone}</span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>   history.push({
          pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/details" : "/teams/payroll/staff/details",
          state: item,
        })}>
            <span className="glade-normal-text-two whitespace-nowrap">
              {item?.currency} {Number(item?.value)?.toLocaleString()}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell onClick={()=>   history.push({
          pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/details" : "/teams/payroll/staff/details",
          state: item,
        })}>
            <span className="glade-normal-text-two">
              {simpleDate(item?.created_at)}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <PipedButton
              primaryButtonTitle="Edit"
              primaryButtonOnClick={() =>
                history.push({
                  pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/edit" : "/teams/payroll/staff/edit",
                  state: item,
                })
              }
              secondaryButtonTitle="Delete"
              secondaryButtonOnClick={() =>
                PayrollStaffDeleteModalRef.current.open(item)
              }
              secondaryButtonClassName={"glade-red"}
            />
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderPaydayDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="  p-1 border-b "
          onClick={() => {}}
        >
          <div className="flex flex-row space-x-3 items-center justify-between">
            <div className="flex items-center">
              <span>
                <CheckboxInput
                  label={""}
                  name={item?.id}
                  type={"checkbox"}
                  checked={input[item?.id] ?? false}
                  onChange={(e) => handleInputChange(e)}
                  placeholder={"Charge user (optional)"}
                  autoComplete={""}
                  className={"appearance-none"}
                />
              </span>
            </div>
            <div className="flex flex-col space-y-1 py-1 cursor-pointer">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item?.name}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {item?.staff_position}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {item?.phone}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  NGN {Number(item?.value)?.toLocaleString()}
                </span>
              </div>
            </div>
            <div>
          <Button.Blue
            title={"Details"}
            onClick={() =>
              history.push({
                pathname: !permissions?.includes("payroll") ? "/dashboard/payroll/staff/details" : "/teams/payroll/staff/details",
                state: item,
              })
            }
            className={"py-3 px-5"}
          />
        </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <div className="mb-6">
        <BreadCrumbs title={"Payroll"} path={!permissions?.includes("payroll") ? "/dashboard/payroll/payday" : "/teams/payroll/payday"} />
      </div>

      <CardTableHeaderComplex
        title={`Showing ${tableData.totalItems} records`}
        filter={
          <Sieve
            types={["date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={payroll.isLoadingPayroll}
            loading={payroll.isLoadingPayroll}
          />
        }
        searchBar={
          <SearchInput
            disabled={payroll.isLoadingPayroll}
            onSearch={onSearch}
            placeholder={"Staff Name"}
          />
        }
        rightButton={
          <div className="flex flex-row space-x-3">
            <div>
            <Button.Blue onClick={()=>addPayrollStaffModalRef.current.open()} title="+ Add Staff" />
            </div>
            <div>
              <Button.Blue
                title={"Pay Staff"}
                disabled={staffList(input).length < 1}
                onClick={() => PaydayCreateModalRef.current.open(input)}
                className={"py-3"}
              />
            </div>
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={payroll.isLoadingPayroll}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={payroll.isLoadingPayroll}
                    loading={payroll.isLoadingPayroll}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <span
                    onClick={() =>
                      history.push(!permissions?.includes("payroll") ? "/dashboard/payroll/staff/create/single" : "/teams/payroll/staff/create/single")
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Add Staff
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Add Staff
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <span
                    onClick={() =>
                      history.push(
                        !permissions?.includes("payroll") ? "/dashboard/payroll/staff/create/bulk/upload" : "/teams/payroll/staff/create/bulk/upload"
                      )
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    File Upload
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Bulk Upload
                  </span>
                }
              />
              {staffList(input).length > 0 ? (
                <KebabDropdownItem
                  activeChild={
                    <span
                      onClick={() => PaydayCreateModalRef.current.open(input)}
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      Pay Staff
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      Pay Staff
                    </span>
                  }
                />
              ) : null}
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || payroll.isLoadingPayroll ? (
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable
              column={[
                "",
                "Staff ID",
                "Staff Name",
                "Bank Account",
                "Position",
                "Phone Number",
                "Amount",
                "Date Added",
                "",
                "",
              ]}
              loading={payroll.isLoadingPayroll}
            />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2, 3]} loading={payroll.isLoadingPayroll} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="hidden md:block">
            {!isArray(tableData.data) && !tableData?.data?.length > 0 ?
            <div className="w-full flex flex-col items-center justify-center">
              {" "}
              <EmptyCard
                title="No payroll staff to display"
                subtitle="You do not have any staff on your payroll"
                loading={payroll?.isLoadingPayroll}
              />
            </div>
            :
            <CardTableBody
              column={[
                "",
                <CheckboxInput
                  label={"Staff ID"}
                  name={"all"}
                  type={"checkbox"}
                  checked={input.all ?? false}
                  onChange={(e) => handleInputChange(e)}
                  placeholder={""}
                  autoComplete={""}
                  className={"appearance-none"}
                />,
                "Staff Name",
                "Bank Account",
                "Position",
                "Phone Number",
                "Amount",
                "Date Added",
                "",
                "",
              ]}
            >
              {isArray(tableData?.data) && renderPayrollStaff(tableData.data)}
            </CardTableBody>}
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderPaydayDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={payroll.isLoadingPayroll}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />

      <PayrollStaffDeleteModal ref={PayrollStaffDeleteModalRef} />
      <PaydayCreateModal ref={PaydayCreateModalRef} />

      {/* Payroll Add Staff Modal */}
      <PayrollStaffAddModal ref={addPayrollStaffModalRef} />
    </div>
  );
}
