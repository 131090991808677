import React from "react";

// Toasts Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Router
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

// layouts
import DashboardLayout from "./layouts/DashboardLayout";
import AuthLayout from "./layouts/AuthLayout";

// pages without layouts
import Landing from "./pages/common/Landing";
import TeamLayout from "./layouts/TeamLayout";
import TeamsDashboardLayout from "./layouts/TeamsDashboardLayout";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop/>
      <BrowserRouter>
        <Switch>
          {/* add routes with layouts */}
          <Route path="/teams" component={TeamsDashboardLayout} />
          <Route path="/team" component={TeamLayout} />
          <Route path="/dashboard" component={DashboardLayout} />
          <Route path="/auth" component={AuthLayout} />

          {/* add routes without layouts */}
          <Route path="/landing" exact component={Landing} />

          {/* add redirect for first page */}
          <Redirect from="*" to="/landing" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
