import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { ALL_BALANCES_FAILURE, ALL_BALANCES_LOADING, ALL_BALANCES_SUCCESS, ACCOUNT_DETAILS_FAILURE, ACCOUNT_DETAILS_LOADING, ACCOUNT_DETAILS_SUCCESS, ALL_IF_ACCOUNT_FAILURE, ALL_IF_ACCOUNT_LOADING, ALL_IF_ACCOUNT_SUCCESS } from "./types";

/**
 * Get all account/wallet balances
 * @returns {void}
 */
export const getAllWalletBalances = () => {
    return (dispatch) => {
        dispatch({ type: ALL_BALANCES_LOADING});
        Axios.get(`/businesses/multicurrency-wallet/balance`)
            .then((res) => {
                dispatch({ type: ALL_BALANCES_SUCCESS, payLoad: res });
                successHandler(res);
            })
            .catch((error) => {
                dispatch({ type: ALL_BALANCES_FAILURE});
                errorHandler(error, true);
            });
    };
};


/**
 * Get all Integrated Finance account
 * @returns {void}
 */
 export const getAllIFAccountCreatedAccount = () => {
  return (dispatch) => {
      dispatch({ type: ALL_IF_ACCOUNT_LOADING});
      Axios.get(`/multi-wallet/all-accounts`)
          .then((res) => {
              dispatch({ type: ALL_IF_ACCOUNT_SUCCESS, payLoad: res });
              successHandler(res);
          })
          .catch((error) => {
              dispatch({ type: ALL_IF_ACCOUNT_FAILURE});
              errorHandler(error, true);
          });
  };
};

/**
 * Get all Integrated Finance account details
 * @returns {void}
 */
 export const getAllIFAccountDetails = (currency) => {
  return (dispatch) => {
      dispatch({ type: ACCOUNT_DETAILS_LOADING});
      Axios.get(`/multi-wallet/banking-accounts?currency=${currency}`)
          .then((res) => {
              dispatch({ type: ACCOUNT_DETAILS_SUCCESS, payLoad: res });
              successHandler(res);
          })
          .catch((error) => {
              dispatch({ type: ACCOUNT_DETAILS_FAILURE});
              errorHandler(error, true);
          });
  };
};
