import {
  CLEAR_NOTIFICATIONS_FAILURE,
  CLEAR_NOTIFICATIONS_LOADING,
  CLEAR_NOTIFICATIONS_SUCCESS,
  GET_IN_APP_NOTIFICATIONS_FAILURE,
  GET_IN_APP_NOTIFICATIONS_LOADING,
  GET_IN_APP_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATION_SUCCESS
} from "./types";

const initialState = {
  isLoading: false,
  dataFetchFail: false,
  markedAsRead: false,
  notificationsData: [],
  inAppNotificationsData: [],
  isInAppNotificationLoading: false
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_LOADING:
      return { ...state, isLoading: true, markedAsRead: false }
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, markedAsRead: false, notificationsData: action.payload, isLoading: false }
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state, markedAsRead: false, dataFetchFail: action.payload, isLoading: false }
    case GET_IN_APP_NOTIFICATIONS_LOADING:
      return { ...state, isInAppNotificationLoading: true, markedAsRead: false }
    case GET_IN_APP_NOTIFICATIONS_SUCCESS:
      return { ...state, markedAsRead: false, inAppNotificationsData: action.payload, isInAppNotificationLoading: false }
    case GET_IN_APP_NOTIFICATIONS_FAILURE:
      return { ...state, markedAsRead: false, isInAppNotificationLoading: false }
    // Notification marked as read
    case MARK_NOTIFICATION_SUCCESS:
      return { ...state, markedAsRead: true }
    case CLEAR_NOTIFICATIONS_LOADING:
      return { ...state, isInAppNotificationLoading: true, markedAsRead: false  }
      case CLEAR_NOTIFICATIONS_FAILURE:
        return {...state, isInAppNotificationLoading: false, markedAsRead: true  }
    case CLEAR_NOTIFICATIONS_SUCCESS:
      return { ...state, markedAsRead: true, isInAppNotificationLoading: false, }
    default:
      return state;
  }
}