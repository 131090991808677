import React, { useEffect, useState, useContext, Fragment } from "react";
import SideBarCloseButton from "./SideBarCloseButton";
import SidebarGroupLink from "./SidebarGroupLink";
import SidebarLink from "./SidebarLink";
import GladeLogo from "../logos/GladeLogo";
import { Bank } from "../../assets/icons/Icons";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import { getDashboardFeatures } from "../../redux/settings/settingsActions";
import PillIndicator from "../../components/indicators/PillIndicator";
import { FaMobileAlt, FaFileInvoice, FaUniversity } from "react-icons/fa";
import { IoWalletOutline, IoPaperPlaneOutline } from "react-icons/io5";
import { RiExchangeDollarLine, RiHome4Line } from "react-icons/ri";
import { FiUsers, FiCreditCard } from "react-icons/fi";
import { BiCog, BiUser } from "react-icons/bi";
import { MdOutlinePayments } from "react-icons/md";
import {
  Payroll,
  Cancel,
  Invoice,
  PayBills,
  Pos,
  Logout,
} from "../../assets/icons/Icons";
import useUser from "../../hooks/useUser";
import { getNotifications } from "../../redux/notifications/notificationsActions";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useNotifications from "../../hooks/useNotifications";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import AccountSelection from "./AccountSelection";
import Modal from "../modals/Modal";
import AddCurrencyModal from "../modals/add currency/AddCurrencyModal";
import { toggleSelectedCurrency } from "../../redux/auth/authActions";
import {
  getBusinessInflowAndOutflow,
  getBusinessTransactions,
  getPersonalInflowAndOutflow,
  getPersonalTransactions,
} from "../../redux/home/homeActions";
import { getAllStatements } from "../../redux/transactions/transactionActions";
import { SideNavContext } from "../../context/SideNavContext";
import AccountActivationModal from "../modals/AccountActivationModal";
import { CgFolderRemove } from "react-icons/cg";
import useSettings from "../../hooks/useSettings";
import { logoutUserLocallyAction } from "../../redux/auth/unAuthActions";

export default function NewSidebar() {
  const user = useUser();
  const { notificationsData, markedAsRead } = useNotifications();
  const location = useLocation();
  const dispatch = useDispatch();
  const settings = useSettings();
  const { dashboardFeatures, isLoadingDashboardFeatures } = settings;

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState([]);
  const [readNote, setReadNote] = useState([]);
  const [unreadNote, setUnreadNote] = useState([]);
  const [addCurrency, showAddCurrency] = useState(false);
  const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);
  const [accountsHolder, setAccountsHolder] = useState(
    user?.selectedBusinessOrPersonalAccount?.accounts
  );
  const { closeNav, navCollapsed } = useContext(SideNavContext);
  const [openActivationModal, setActivationModal] = useState(false);

  //Get notifications on page load
  useEffect(() => {
    if (currentLocation !== location.pathname) {
      dispatch(getNotifications());
      setCurrentLocation(location.pathname);
      if (currentLocation !== location.pathname)
        setShowNotificationModal(false);
    }
  }, [location]);
  //
  // Refresh notification list after mark as read request
  useEffect(() => {
    if (markedAsRead) {
      dispatch(getNotifications());
    }
  }, [markedAsRead]);

  // Set notifications to useState variable
  useEffect(() => {
    if (notificationsData?.data) {
      // Filter for read or unread notifications
      let unread = notificationsData?.data?.filter((note) => {
        return note.is_read === 0;
      });
      let read = notificationsData?.data?.filter((note) => {
        return note.is_read !== 0;
      });
      let modifiedNotifications = read.concat(unread);
      setUnreadNote(unread.reverse());
      setReadNote(read.reverse());

      setTotalUnreadNotifications(modifiedNotifications);
    }
  }, [notificationsData]);

  useEffect(() => {
    dispatch(getPersonalAndBusinessAccounts());
  }, []);
  const logoutFxn = () => {
    dispatch(logoutUserLocallyAction());
  };

  // This life hook pushes the selected currency account into the user.selectedCurrency key in the state user object.
  // Also calls all dashboard dependent endpoint based on selected currency/
  useEffect(() => {
    if (user?.isBusiness) {
      dispatch(getDashboardFeatures());
      dispatch(getBusinessTransactions());
      dispatch(getAllStatements());
      dispatch(
        getBusinessInflowAndOutflow({
          start_date: new Date(new Date().setDate(new Date().getDate() - 7))
            .toISOString()
            .substring(0, 10),
          end_date: new Date().toISOString().substring(0, 10),
        })
      );
    } else {
      dispatch(getPersonalTransactions());
      dispatch(getAllStatements());
      dispatch(
        getPersonalInflowAndOutflow({
          start_date: new Date(new Date().setDate(new Date().getDate() - 7))
            .toISOString()
            .substring(0, 10),
          end_date: new Date().toISOString().substring(0, 10),
        })
      );
    }

    if (
      user &&
      !user?.selectedBusinessOrPersonalAccount?.hasOwnProperty("currency") &&
      user?.selectedBusinessOrPersonalAccount?.accounts?.length > 0
    ) {
      // Save selected currency index to storage, to be available on refresh.
      dispatch(
        toggleSelectedCurrency(
          user?.selectedBusinessOrPersonalAccount?.accounts[
            Number(localStorage.getItem("currencyIndex"))
          ],
          user?.selectedBusinessOrPersonalAccount
        )
      );
    }
  }, [selectedCurrencyIndex, localStorage.getItem("currencyIndex")]);

  const handleSelectedCurrency = (index) => {
    localStorage.setItem("currencyIndex", index);
    setAccountsHolder(user?.selectedBusinessOrPersonalAccount?.accounts);
    setSelectedCurrencyIndex(index);
  };

  const restoreCurrencyIndexToDefault = () => {
    setSelectedCurrencyIndex(0);
    localStorage.setItem("currencyIndex", 0);
  };

  const openModal = () => {
    if (user.compliance !== "approved") {
      setActivationModal(true);
    } else {
      document.body.style.overflowY = "hidden";
      showAddCurrency(true);
    }
  };

  const closeModal = () => {
    document.body.style.overflowY = "auto";
    showAddCurrency(false);
  };

  return (
    <SideNavSC navCollapsed={navCollapsed}>
      {
        <Modal open={addCurrency} onClose={closeModal}>
          <AddCurrencyModal onClose={closeModal} />
        </Modal>
      }
      <div className={navCollapsed ? "hidden" : ""}>
        {/* Toggler For Smaller Screens*/}

        {/* Collapsable sidebar */}
        <div>
          {/* Collapsed sidebar header */}
          <div className="md:min-w-full md:hidden block pt-8 pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12 flex justify-start">
                <GladeLogo />
              </div>
              <div className="w-6/12 flex justify-end">
                <SideBarCloseButton onClick={closeNav} />
              </div>
            </div>
          </div>

          {/* Expanded sidebar header */}
          <div className="md:min-w-full md:block hidden my-6">
            <div className="w-full flex flex-wrap justify-start">
              <GladeLogo />
            </div>
          </div>

          {/* Account Actvation Modal */}
          {openActivationModal && (
            <AccountActivationModal
              openActivationModal={openActivationModal}
              setActivationModal={setActivationModal}
              complianceStatus={user?.compliance}
              isBusiness={user.isBusiness}
            />
          )}
          {/* Account Selection */}
          <AccountSelection
            restoreCurrencyIndexToDefault={restoreCurrencyIndexToDefault}
          />
          {/* List of Currencies */}
          {user.hasCompliance && !isLoadingDashboardFeatures ? (
            <>
              <SidebarGroupLink title={""}>
                <SidebarLink
                  name={"Home"}
                  path={"/dashboard/home"}
                  icon={<RiHome4Line title={"Home"} />}
                />
                <SidebarLink
                  name={"Balance"}
                  path={"/dashboard/balances"}
                  icon={<CgFolderRemove title={"Balance"} />}
                />
              </SidebarGroupLink>
              {!user.isBusiness ? (
                <div
                  className="flex flex-col justify-between"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <SidebarGroupLink title={"REPORTS"}>
                    <SidebarLink
                      name={"Transactions"}
                      path={"/dashboard/transactions"}
                      icon={<FaFileInvoice title={"Transactions"} />}
                    />
                  </SidebarGroupLink>

                  <SidebarGroupLink title={"PAYMENTS"}>
                    <SidebarLink
                      name={"Fund Transfer"}
                      path={"/dashboard/fund-transfer"}
                      icon={<IoPaperPlaneOutline title={"Fund Transfer"} />}
                    />
                    {user?.isBusiness && (
                      <SidebarLink
                        name={"Request Payments"}
                        path={"/dashboard/payment-pages"}
                        icon={<MdOutlinePayments title={"Request Payments"} />}
                      />
                    )}

                    <SidebarLink
                      name={
                        <Fragment>
                          <p className="mr-2">Cards</p>
                          <div>
                            <PillIndicator
                              type={"coming-soon"}
                              title={"Coming Soon"}
                            />
                          </div>
                        </Fragment>
                      }
                      path={"/dashboard"}
                      icon={<FiCreditCard title={"Cards"} />}
                    />
                    {(
                      <SidebarLink
                        name={"Recurring Payments"}
                        path={"/dashboard/recurring-payment/single"}
                        icon={<MdOutlinePayments title={"Recurring Payments"} />}
                      />
                    )}
                  </SidebarGroupLink>

                  <SidebarGroupLink title={"MARKETPLACE"}>
                    <SidebarLink
                      name={"Airtime"}
                      path={"/dashboard/airtime"}
                      icon={<FaMobileAlt title={"Airtime"} />}
                    />

                    <SidebarLink
                      name={"Pay Bills"}
                      path={"/dashboard/pay-bills"}
                      icon={<PayBills title={"Pay Bills"} color={"#717485"} />}
                    />
                  </SidebarGroupLink>

                  {user?.is_agent && !user?.isBusiness && (
                    <SidebarGroupLink title={"SERVICES"}>
                      <SidebarLink
                        name={"POS"}
                        path={"/dashboard/pos"}
                        icon={<Pos title={"POS"} color={"#717485"} />}
                      />
                    </SidebarGroupLink>
                  )}
                </div>
              ) : 
              // Business Side links
              (
                <div
                  className="flex flex-col justify-between"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <SidebarGroupLink title={"REPORTS"}>
                    <SidebarLink
                      name={"Transactions"}
                      path={"/dashboard/transactions"}
                      icon={<FaFileInvoice title={"Transactions"} />}
                    />
                    {(dashboardFeatures?.payment_inflow ||
                      dashboardFeatures?.payment_inflow === 1) && (
                      <SidebarLink
                        name={"Payment Inflow"}
                        path={"/dashboard/payment-inflow"}
                        icon={<IoWalletOutline title={"Payment Inflow"} />}
                      />
                    )}

                    {(dashboardFeatures?.settlements ||
                      dashboardFeatures?.settlements === 1) && (
                      <SidebarLink
                        name={"Settlements"}
                        path={"/dashboard/settlements"}
                        icon={<RiExchangeDollarLine title={"Settlements"} />}
                      />
                    )}

                    {(dashboardFeatures?.customers ||
                      dashboardFeatures?.customers === 1) && (
                      <SidebarLink
                        name={"Customers"}
                        path={"/dashboard/customers"}
                        icon={<FiUsers title={"Customers"} />}
                      />
                    )}
                  </SidebarGroupLink>

                  <SidebarGroupLink title={"PAYMENTS"}>
                    <SidebarLink
                      name={"Fund Transfer"}
                      path={"/dashboard/fund-transfer"}
                      icon={<IoPaperPlaneOutline title={"Fund Transfer"} />}
                    />
                    {user.isBusiness && (
                      <Fragment>
                        {(dashboardFeatures?.payment_pages ||
                          dashboardFeatures?.payment_pages === 1) && (
                          <SidebarLink
                            name={"Request Payments"}
                            path={"/dashboard/payment-pages"}
                            icon={
                              <MdOutlinePayments title={"Request Payments"} />
                            }
                          />
                        )}

                        {(dashboardFeatures?.loans ||
                          dashboardFeatures?.loans === 1) && (
                          <SidebarLink
                            name={"Financing"}
                            path={"/dashboard/loans"}
                            icon={<Bank title={"Get a Loan"} size={15} />}
                          />
                        )}
                      </Fragment>
                    )}
                    {(dashboardFeatures?.transaction_split ||
                      dashboardFeatures?.transaction_split === 1) && (
                      <SidebarLink
                        name={"Transaction Split"}
                        path={"/dashboard/transaction-split"}
                        icon={
                          <Cancel
                            title={"Transaction Split"}
                            color={"#717485"}
                          />
                        }
                      />
                    )}
                    {(
                      <SidebarLink
                        name={"Recurring Payments"}
                        path={"/dashboard/recurring-payment/single"}
                        icon={<MdOutlinePayments title={"Recurring Payments"} />}
                      />
                    )}
                    {/* 
                  <SidebarLink
                    name={"Chargebacks"}
                    path={"/dashboard/charge-backs"}
                    icon={<RiFlagLine title={"Chargebacks"} />}
                  />
                */}
                    <SidebarLink
                      name={
                        <Fragment>
                          <p className="mr-2">Cards</p>
                          <div>
                            <PillIndicator
                              type={"coming-soon"}
                              title={"Coming Soon"}
                            />
                          </div>
                        </Fragment>
                      }
                      path={"/dashboard"}
                      icon={<FiCreditCard title={"Cards"} />}
                    />
                  </SidebarGroupLink>

                  <SidebarGroupLink title={"MARKETPLACE"}>
                    {(dashboardFeatures?.buy_airtime ||
                      dashboardFeatures?.buy_airtime === 1) && (
                      <SidebarLink
                        name={"Airtime"}
                        path={"/dashboard/airtime"}
                        icon={<FaMobileAlt title={"Airtime"} />}
                      />
                    )}
                    {(dashboardFeatures?.pay_bills ||
                      dashboardFeatures?.pay_bills === 1) && (
                      <SidebarLink
                        name={"Pay Bills"}
                        path={"/dashboard/pay-bills"}
                        icon={
                          <PayBills title={"Pay Bills"} color={"#717485"} />
                        }
                      />
                    )}
                  </SidebarGroupLink>

                  <SidebarGroupLink title={"BUSINESS"}>
                    {(dashboardFeatures?.payroll ||
                      dashboardFeatures?.payroll === 1) && (
                      <SidebarLink
                        name={"Payroll"}
                        path={"/dashboard/payroll/payday"}
                        icon={<Payroll title={"Payroll"} color={"#717485"} />}
                      />
                    )}
                    {(
                      <SidebarLink
                        name={"Teams"}
                        path={"/dashboard/teams"}
                        icon={<Payroll title={"Teams"} color={"#717485"} />}
                      />
                    )}

                    {(dashboardFeatures?.pos ||
                      dashboardFeatures?.pos === 1) && (
                      <SidebarLink
                        name={"POS"}
                        path={"/dashboard/pos"}
                        icon={<Pos title={"POS"} color={"#717485"} />}
                      />
                    )}
                    {(dashboardFeatures?.invoices ||
                      dashboardFeatures?.invoices === 1) && (
                      <SidebarLink
                        name={"Invoices"}
                        path={"/dashboard/invoices"}
                        icon={<Invoice title={"Invoices"} color={"#717485"} />}
                      />
                    )}
                  </SidebarGroupLink>
                </div>
              )}
              <div
                className="md:hidden mt-3 mb-6"
                onClick={() => setCollapseShow("hidden")}
              >
                <SidebarLink
                  listStyle={{ listStyle: "none" }}
                  name={"Profile"}
                  path={"/dashboard/settings/profile"}
                  icon={<BiUser color={"#717485"} size={15} />}
                />

                <SidebarLink
                  listStyle={{ listStyle: "none" }}
                  name={"Settings"}
                  path={"/dashboard/settings"}
                  icon={
                    <BiCog title={"Settings"} color={"#717485"} size={15} />
                  }
                />
                <button
                  className="flex gap-3 items-center justify-center"
                  onClick={() => logoutFxn()}
                >
                  <Logout title={"Logout"} color={"#717485"} size={15} /> Logout
                </button>
              </div>{" "}
            </>
          ) : (
            <>
              <SidebarGroupLink title={"ACCOUNTS"}>
                <SidebarLink
                  name={"Overview"}
                  path={"/dashboard"}
                  icon={<FaUniversity title={"Airtime"} />}
                />
              </SidebarGroupLink>
            </>
          )}
        </div>
      </div>
    </SideNavSC>
  );
}

const SideNavSC = styled(motion.nav)`
  overflow-x: hidden;
  width: 100%;
  padding: 0 1.5rem;
  top: 0;
  overflow-y: auto;
  position: relative;

  @media (max-width: 700px) {
    min-height: 100vh;
  }
`;


