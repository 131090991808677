import { useEffect, useState } from "react";
import PopModal from "./PopModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function MyModal() {
  let [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  function openModal() {
    setIsOpen(true);
  }
  useEffect(() => {
    setIsOpen(true);
  }, [isOpen]);

  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          Start
        </button>
      </div>
      <PopModal isOpen={isOpen} setIsOpen={setIsOpen} state={location?.state} />
    </>
  );
}
