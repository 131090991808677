import { useSelector } from "react-redux";

const useHome = () => {

    const home = useSelector((state) => state.home);
    const {
        isLoading,
        shouldReload,
        businessInflow,
        businessOutflow,
        businessInflowOutflow,
        personalInflow,
        personalOutflow,
        personalInflowOutflow,
        cards,
        businessTransactions,
        personalTransactions,
        statesByCountryId,
        lgasByStateId,
        loadingPersonalCompliance,
        submitPersonalCompliance,
        oneBusinessAccount,
        loadingRequestRevoke,
        loadingRevokeSession,
        requestRevoke,
        revokeSession
    } = home;

    const totalBusinessInflow = businessInflowOutflow?.inflow ? Object.keys(businessInflowOutflow.inflow).map(function(key) {
        return businessInflowOutflow.inflow[key]?.value ?? 0;
    }).reduce((acc, curr) => acc + parseInt(curr), 0) : 0;

    const totalBusinessOutflow = businessInflowOutflow?.outflow ? Object.keys(businessInflowOutflow.outflow).map(function(key) {
        return businessInflowOutflow.outflow[key]?.value ?? 0;
    }).reduce((acc, curr) => acc + parseInt(curr), 0) : 0;

    const totalPersonalInflow = personalInflowOutflow?.inflow ? Object.keys(personalInflowOutflow.inflow).map(function(key) {
        return personalInflowOutflow.inflow[key]?.value ?? 0;
    }).reduce((acc, curr) => acc + parseInt(curr), 0) : 0;

    const totalPersonalOutflow = personalInflowOutflow?.outflow ? Object.keys(personalInflowOutflow.outflow).map(function(key) {
        return personalInflowOutflow.outflow[key]?.value ?? 0;
    }).reduce((acc, curr) => acc + parseInt(curr), 0) : 0;

    return {
        isLoading,
        shouldReload,
        businessInflow,
        businessOutflow,
        businessInflowOutflow,
        personalInflow,
        personalOutflow,
        personalInflowOutflow,
        cards,
        businessTransactions,
        personalTransactions,
        totalBusinessInflow: totalBusinessInflow,
        totalBusinessOutflow: totalBusinessOutflow,
        totalPersonalInflow: totalPersonalInflow,
        totalPersonalOutflow: totalPersonalOutflow,
        statesByCountryId,
        lgasByStateId,
        loadingRequestRevoke,
        loadingRevokeSession,
        requestRevoke,
        revokeSession,
        loadingPersonalCompliance,
        submitPersonalCompliance, oneBusinessAccount
    };
};

export default useHome;
