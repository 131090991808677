import {
  LOADING_FUND_ACCOUNT, 
  RELOAD_FUND_ACCOUNT,
  FUND_ACCOUNT
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload:false,
  fundAccount:{},
};

export default function fundAccountReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_FUND_ACCOUNT:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_FUND_ACCOUNT:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case FUND_ACCOUNT:
      return {
        ...state,
        fundAccount: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
