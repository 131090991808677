// Application types
export const LOADING_PAYMENT_INFLOW = 'LOADING_PAYMENT_INFLOW';
export const LOADING_USD_PAYMENT_INFLOW = 'LOADING_USD_PAYMENT_INFLOW';
export const LOADING_NGN_PAYMENT_INFLOW = 'LOADING_NGN_PAYMENT_INFLOW';
export const RELOAD_PAYMENT_INFLOW = 'RELOAD_PAYMENT_INFLOW';

export const GET_PAYMENT_INFLOW_SUMMARY = 'GET_PAYMENT_INFLOW_SUMMARY';
export const GET_PAYMENT_OUTFLOW_SUMMARY = 'GET_PAYMENT_OUTFLOW_SUMMARY';
export const GET_ALL_USD_PAYMENT_INFLOW = 'GET_ALL_USD_PAYMENT_INFLOW';
export const GET_ALL_NGN_PAYMENT_INFLOW = 'GET_ALL_NGN_PAYMENT_INFLOW';
export const GET_ALL_PAYMENT_INFLOW = 'GET_ALL_PAYMENT_INFLOW';
export const GET_SPECIFIC_PAYMENT_INFLOW = 'GET_SPECIFIC_PAYMENT_INFLOW';
export const DOWNLOAD_ALL_NGN_PAYMENT_INFLOW = 'DOWNLOAD_ALL_NGN_PAYMENT_INFLOW';

export const LOADING_ANY_PAYMENT_INFLOW = "LOADING_ANY_PAYMENT_INFLOW"
export const SUCCESS_ANY_PAYMENT_INFLOW = "SUCCESS_ANY_PAYMENT_INFLOW"
export const FAILURE_ANY_PAYMENT_INFLOW = "FAILURE_ANY_PAYMENT_INFLOW"