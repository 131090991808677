import React from "react";
import CurrencySelectionField from "./CurrencySelectionField";

const SelectCurrency = ({ handleClick }) => {
    const onSelectionChange = () => null;
    return (
        <div className="w-10/12 lg:w-3/12 flex flex-col items-center">
            <div className='mt-6 mb-6 text-center'>
                <div className='glade-heading-three glade-home-dark'>Add Currency</div>
                <div className="mt-2 glade-grey-dark">Select a currency you wish to add</div>
            </div>
            <CurrencySelectionField title={'Select a currency to add'} defaultValue={''} handleClick={handleClick} displayButton={true} onSelectionChange={onSelectionChange} />
        </div>
    );
};

export default SelectCurrency;