import React from 'react';

export default function CheckboxInput({
    label, 
    id,
    name, 
    type,
    checked=false,
    onChange=()=>{}, 
    placeholder, 
    autoComplete,
    disabled=false, 
    className, 
    leftSection, 
    rightSection
}) {
    return (
        <div className="space-y-1 col-span-6 sm:col-span-3">
            <div className="flex items-center">
                {leftSection??<React.Fragment></React.Fragment>}
                <input
                    type={type??"checkbox"}
                    name={name??'name'}
                    id={id??name??'id'}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    className={className +" h-4 w-4 glade-blue focus:ring-blue-800 border-gray-300 rounded-sm placeholder-gray-400"}
                    placeholder={placeholder??''}
                    autoComplete={autoComplete??''}
                />
                {rightSection??<label htmlFor={name??'name'} className="ml-2 block text-sm glade-black capitalize">{label??''}</label>}
            </div>
        </div>
    )
}
