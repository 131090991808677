import {
  LOADING_PAYROLL,
  LOADING_PAYDAY,
  RELOAD_PAYROLL,
  RELOAD_PAYDAY,
  GET_PAYROLL_STAFF,
  CREATE_PAYROLL_STAFF,
  CREATE_PAYROLL_STAFF_BULK,
  UPDATE_PAYROLL_STAFF,
  DELETE_PAYROLL_STAFF,
  GET_PAYDAY,
  GET_PAYDAY_BY_ID,
  CREATE_PAYDAY,
  UPDATE_PAYDAY,
  DELETE_PAYDAY,
  REMEMBER_BULK_CREATE_STAFF_LIST,
  PAYROLL_STAFF_SUCCESS,
  LOADING_DELETE_PAYDAY,
} from "./types";

const initialState = {
  isLoadingPayroll:false,
  isLoadingPayday:false,
  isLoadingDeletePayday: false,
  shouldReloadPayroll:false,
  shouldReloadPayday:false,
  payrollStaffIndex:[],
  createPayrollStaff:{},
  createPayrollStaffBulk:{},
  updatePayrollStaff:{},
  deletePayrollStaff:{},
  paydayIndex:[],
  paydayById:{},
  createPayday:{},
  updatePayday:{},
  deletePayday:{},
  payrollStaffDetails: {},
  bulkCreateStaffList:[],
};

export default function payrollReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_PAYROLL:
      return {
        ...state,
        isLoadingPayroll: action.payLoad,
      };
    
    case LOADING_PAYDAY:
      return {
        ...state,
        isLoadingPayday: action.payLoad,
      };

    case RELOAD_PAYROLL:
      return {
        ...state,
        shouldReloadPayroll: !state.shouldReloadPayroll,
      };

    case RELOAD_PAYDAY:
      return {
        ...state,
        shouldReloadPayday: !state.shouldReloadPayday,
      };

    case GET_PAYROLL_STAFF:
      return {
        ...state,
        payrollStaffIndex: action.payLoad.data.data,
      };

    case CREATE_PAYROLL_STAFF:
      return {
        ...state,
        createPayrollStaff: action.payLoad.data.data,
      };

    case CREATE_PAYROLL_STAFF_BULK:
      return {
        ...state,
        createPayrollStaffBulk: action.payLoad.data.data,
      };

    case UPDATE_PAYROLL_STAFF:
      return {
        ...state,
        updatePayrollStaff: action.payLoad.data.data,
      };

    case DELETE_PAYROLL_STAFF:
      return {
        ...state,
        deletePayrollStaff: action.payLoad.data.data,
      };

    case GET_PAYDAY:
      return {
        ...state,
        paydayIndex: action.payLoad.data.data,
      };

    case GET_PAYDAY_BY_ID:
      return {
        ...state,
        paydayById: action.payLoad.data.data,
      };

    case CREATE_PAYDAY:
      return {
        ...state,
        createPayday: action.payLoad.data.data,
      };

    case UPDATE_PAYDAY:
      return {
        ...state,
        updatePayday: action.payLoad.data.data,
      };

    case DELETE_PAYDAY:
      return {
        ...state,
        deletePayday: action?.payLoad?.data?.data,
        isLoadingDeletePayday: false,
        
      };

    case REMEMBER_BULK_CREATE_STAFF_LIST:
      return {
        ...state,
        bulkCreateStaffList: action.payLoad.data.data,
      };
      case PAYROLL_STAFF_SUCCESS:
      return {
        ...state,
        payrollStaffDetails: action.payLoad,
      };
      case LOADING_DELETE_PAYDAY:
        return {
          ...state,
          isLoadingDeletePayday: true
        }
    default:
      return state;
  }
}