import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/buttons/Button";
import { getComplianceIdentityTypes } from "../../redux/settings/settingsActions";
import {
  getApprovedCountries,
  getApprovedStates,
} from "../../redux/countries/countriesActions";
import useCountries from "../../hooks/useCountry";
import { isArray, isEmptyArray } from "../../helpers/helper";
import TextInput from "../../components/inputs/TextInput";
import FileInput from "../../components/inputs/FileInput";
import SelectInput from "../../components/inputs/SelectInput";
import useUser from "../../hooks/useUser";
import CardSkinColor from "./CardSkinColor";
import { VirtualCardPersonalAddress } from "../../validation/virtual-card/virtualCardValidation";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import { IoIosCheckmark } from "react-icons/io";
import { useState } from "react";

export default function VirtualCardCreatePersonalDetails({
  formData,
  setFormData,
  openConfirmPinModal,
  cardSkinColor,
  setCardSkinColor,
  successful,
  cardDetails,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const countries = useCountries();

  const { gender, city, state, country, postal_code, passport, address, year, month, day } =
    formData;

  const [errorState, setErrorState] = useState(false);
  const [errorGender, setErrorGender] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [errorPostalCode, setErrorPostalCode] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [errorPlaceOfBirth, setErrorPlaceOfBirth] = useState(false);
  const [errorDateOfBirth, setErrorDateOfBirth] = useState({
    day: false,
    month: false,
    year: false,
  });
  const [errorIdUpload, setErrorIdUpload] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);

  useEffect(() => {
    async function fetchCountries() {
      const response = await dispatch(getApprovedCountries({ status: 1 }));
      return response;
    }
    fetchCountries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData.country !== "") {
      dispatch(getApprovedStates({ country_id: formData.country }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.country]);

  useEffect(() => {
    dispatch(getComplianceIdentityTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (VirtualCardPersonalAddress(formData)) {
      setCardSkinColor(true);
    }
  };

  const onChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrorIdUpload(false);
  };
  const handleDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrorIdUpload(false);
  };
  const monthList = [
    { month: "January", id: "01" },
    { month: "February", id: "02" },
    { month: "March", id: "03" },
    { month: "April", id: "04" },
    { month: "May", id: "05" },
    { month: "June", id: "06" },
    { month: "July", id: "07" },
    { month: "August", id: "08" },
    { month: "September", id: "09" },
    { month: "October", id: "10" },
    { month: "November", id: "11" },
    { month: "December", id: "12" },
  ];
  const generateArrayOfYears = () => {
    var max = new Date().getFullYear();
    var years = [];

    for (var i = max; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };
  function formatExpiryDate(inputDate) {
    const parts = inputDate.split("-");
    if (parts.length !== 2) {
      return "Invalid Date";
    }

    // Extract the year and month from the parts
    const year = parts[0].substring(2);
    const month = parts[1];

    // Format the date as 'MM/YY'
    const formattedDate = `${month}/${year}`;

    return formattedDate;
  }

  return (
    <div className="p-3">
      <div className="flex flex-col justify-center lg:w-1/3 w-full mx-auto">
        <div className={"flex flex-col"}>
          <div className="mt-6 px-2 md:px-4 lg:px-14">
            <div className="space-y-6">
              <div className="space-y-2">
                <SelectInput
                  label={"Gender"}
                  id={"gender"}
                  name={"gender"}
                  value={formData.gender ?? ""}
                  onChange={onChange}
                  autoComplete={"gender"}
                  className={`appearance-none rounded-md ${
                    errorGender ? "border-2 border-red-500" : ""
                  }`}
                >
                  <option value={""} disabled={true}>
                    Select Gender
                  </option>
                  {["Male", "Female"]?.map((gender, index) => (
                    <option key={index} value={gender}>
                      {gender}
                    </option>
                  ))}
                </SelectInput>
                {errorGender && (
                  <p className="text-red-500">Gender is a required field</p>
                )}
              </div>
              <div className="space-y-2">
                <TextInput
                  label={"Home Address"}
                  id={"address"}
                  name={"address"}
                  type={"text"}
                  value={formData.address}
                  onChange={onChange}
                  placeholder={"Enter Full Address"}
                  autoComplete={"address"}
                  className={`appearance-none rounded-md ${
                    errorAddress
                      ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                      : ""
                  }`}
                  errorState={errorAddress}
                />
                {errorAddress && (
                  <p className="text-red-500">
                    Full Address is a required field
                  </p>
                )}
              </div>

              <div className="flex justify-between w-full">
                <div className="space-y-2 w-full mr-2">
                  <SelectInput
                    label={"Country"}
                    id={"country"}
                    name={"country"}
                    value={formData.country ?? ""}
                    onChange={onChange}
                    autoComplete={"country"}
                    className={`appearance-none rounded-md w-full ${
                      errorState ? "border-2 border-red-500" : ""
                    }`}
                  >
                    <option value={""} disabled={true}>
                      Select Country
                    </option>
                    {isArray(countries?.getCountries) &&
                      countries.getCountries?.map((country, index) => (
                        <option key={index} value={country.country_id}>
                          {country.country_name}
                        </option>
                      ))}
                  </SelectInput>
                  {errorState && (
                    <p className="text-red-500">Country is a required field</p>
                  )}
                </div>

                <div className="space-y-2 w-full ml-2">
                  <SelectInput
                    label={"State"}
                    id={"state"}
                    name={"state"}
                    value={formData.state ?? ""}
                    onChange={onChange}
                    autoComplete={"state"}
                    className={`appearance-none rounded-md ${
                      errorState ? "border-2 border-red-500" : ""
                    }`}
                  >
                    <option value={""} disabled={true}>
                      Select State
                    </option>
                    {isArray(countries?.getStates) &&
                      countries.getStates?.map((state, index) => (
                        <option key={index} value={state.state_name}>
                          {state.state_name}
                        </option>
                      ))}
                  </SelectInput>
                  {errorState && (
                    <p className="text-red-500">State is a required field</p>
                  )}
                </div>
              </div>

              <div className="flex justify-between w-full">
                <div className="space-y-2 w-full mr-2">
                  <TextInput
                    label={"City"}
                    id={"city"}
                    name={"city"}
                    type={"text"}
                    value={formData.city}
                    onChange={onChange}
                    placeholder={"Enter City"}
                    autoComplete={"city"}
                    className={`appearance-none rounded-md ${
                      errorCity
                        ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                        : ""
                    }`}
                    errorState={errorCity}
                  />
                  {errorCity && (
                    <p className="text-red-500">City is a required field</p>
                  )}
                </div>
                <div className="space-y-2 w-full ml-2">
                  <TextInput
                    label={"Postal Code"}
                    id={"postal_code"}
                    name={"postal_code"}
                    type={"text"}
                    value={formData.postal_code}
                    onChange={onChange}
                    placeholder={"Enter Postal Code"}
                    autoComplete={"postal_code"}
                    className={`appearance-none rounded-md ${
                      errorPostalCode
                        ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                        : ""
                    }`}
                    errorState={errorPostalCode}
                  />
                  {errorPostalCode && (
                    <p className="text-red-500">Postal is a required field</p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="w-full">
                  <SelectInput
                    label={"Date of Birth"}
                    id={"day"}
                    name={"day"}
                    value={formData.day ?? ""}
                    onChange={handleDateChange}
                    autoComplete={"day"}
                    className={`appearance-none rounded-md ${
                      errorDateOfBirth?.day ? "border-2 border-red-500" : ""
                    }`}
                  >
                    <option value={""} disabled={true}>
                      Day
                    </option>
                    {Array(31)
                      .fill({ option: 0 })
                      .map((value, index) => (
                        <option
                          key={index}
                          value={
                            index <= 8
                              ? String(value?.option) + (index + 1)
                              : index + 1
                          }
                          className="mt-5"
                        >
                          {index <= 8
                            ? String(value?.option) + (index + 1)
                            : index + 1}
                        </option>
                      ))}
                  </SelectInput>
                </div>
                <div className="w-full">
                  <SelectInput
                    label={<span>&nbsp;</span>}
                    id={"month"}
                    name={"month"}
                    value={formData.month ?? ""}
                    onChange={handleDateChange}
                    autoComplete={"month"}
                    className={`appearance-none rounded-md ${
                      errorDateOfBirth?.month ? "border-2 border-red-500" : ""
                    }`}
                  >
                    <option value={""} disabled={true}>
                      Month
                    </option>
                    {monthList.map((value, index) => (
                      <option key={index} value={value?.id} className="mt-5">
                        {value?.month}
                      </option>
                    ))}
                  </SelectInput>
                </div>
                <div className="w-full">
                  <SelectInput
                    label={<span>&nbsp;</span>}
                    id={"year"}
                    name={"year"}
                    value={formData.year ?? ""}
                    onChange={onChange}
                    autoComplete={"year"}
                    className={`appearance-none rounded-md ${
                      errorDateOfBirth.year ? "border-2 border-red-500" : ""
                    }`}
                  >
                    <option value={""} disabled={true}>
                      Year
                    </option>
                    {generateArrayOfYears()?.map((year, index) => {
                      return (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </SelectInput>
                </div>
              </div>
              <div className="space-y-2">
                <TextInput
                  label={"Place of Birth"}
                  id={"place_of_birth"}
                  name={"place_of_birth"}
                  type={"text"}
                  value={formData.place_of_birth}
                  onChange={onChange}
                  placeholder={"City/country of birth"}
                  autoComplete={"place_of_birth"}
                  className={`appearance-none rounded-md ${
                    errorPlaceOfBirth
                      ? "border-2 border-red-500 focus:border-red-500 focus:outline-none focus:ring-red-100 focus:shadow-none "
                      : ""
                  }`}
                  errorState={errorPlaceOfBirth}
                />
                {errorPlaceOfBirth && (
                  <p className="text-red-500">
                    Place of Birth is a required field
                  </p>
                )}
              </div>

              <div className="space-y-2">
                <FileInput
                  label={"Passport Photo Upload"}
                  id={"id_upload"}
                  name={"id_upload"}
                  accept={".pdf, .png, .jpeg, .jpg"}
                  maximumSize={3}
                  multiple={true}
                  returnInput={(value) => {
                    setErrorIdUpload(false);
                    setFormData((input) => ({
                      ...input,
                      passport: value,
                    }));
                  }}
                  className={`${errorIdUpload ? "border-red-500 " : ""}`}
                />
                <div className="glade-subtitle-two">
                  <h5>
                    Supported file types: PDF, PNG & JPEG. Max file size allowed
                    is 3MB.
                  </h5>
                </div>
                {errorIdUpload && (
                  <p className="text-red-500">
                    Upload a valid format (JPG, PNG, PDF)
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="mx-6 flex justify-center">
            <Button.Blue
              onClick={onSubmit}
              title={"Continue"}
              className={"my-6 py-2"}
              disabled={
                !gender ||
                !address ||
                !postal_code ||
                !city ||
                !state ||
                !country ||
                isEmptyArray(passport) ||
                !passport ||
                !year || !month || !day
              }
            />
          </div>
        </div>
      </div>
      {cardSkinColor && (
        <CardSkinColor
          setCardSkinColor={setCardSkinColor}
          openConfirmPinModal={openConfirmPinModal}
          setFormData={setFormData}
          formData={formData}
        />
      )}
      {successful && (
        <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-8 lg:px-32 py-32 flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <div className="self-center bg-white rounded md:w-1/3 p-5  h-full">
              <div className="mx-auto w-20">
                <IoIosCheckmark
                  className="mt-6 mb-4"
                  style={{
                    color: "#4AFA4A",
                    borderRadius: "50%",
                    background: "#E6FBE9",
                  }}
                  size={100}
                />
              </div>
              <div className="text-center mt-3 p-5">
                <h4 className="font-bold">Successful</h4>
                <p className="text-glade-gray-400">
                  Your {formData.currency === "ngn" ? "naira" : "dollar"}{" "}
                  virtual card has been created and funded
                </p>
                <div className="mt-3 flex justify-center">
                  <Button.Blue
                    title="Continue"
                    type="button"
                    onClick={() => history.push("/dashboard/cards")}
                  />
                </div>
              </div>

              <div>
                {!showCardDetails ? (
                  <div
                    className="flex justify-center items-center space-x-6 cursor-pointer"
                    onClick={() => setShowCardDetails(true)}
                  >
                    <span className="text-glade-blue">Your Card Details</span>
                    <RiArrowDropDownLine
                      size={"28px"}
                      className="cursor-pointer mr-1"
                      color="#2C6DEA"
                    />
                  </div>
                ) : (
                  <div className="glade-coming-soon-bg flex flex-col border cursor-pointer space-y-6 p-4">
                    <div
                      className="flex justify-between items-center space-x-6 cursor-pointer"
                      onClick={() => setShowCardDetails(false)}
                    >
                      <span className="text-glade-blue">Your Card Details</span>
                      <RiArrowDropUpLine
                        size={"28px"}
                        className="cursor-pointer mr-1"
                        color="#2C6DEA"
                      />
                    </div>
                    <div>
                      <h3 className="glade-grey-text">CARD NUMBER</h3>
                      <div className="flex items-center space-x-3">
                        <CopyTextButton
                          text={cardDetails?.card_number}
                          truncate={false}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <h3 className="glade-grey-text">EXPIRY DATE</h3>
                        <div className="flex items-center space-x-3">
                          <CopyTextButton
                            text={formatExpiryDate(cardDetails?.expiry)}
                            truncate={false}
                          />
                        </div>
                      </div>
                      <div>
                        <h3 className="glade-grey-text">CVV</h3>
                        <div className="flex items-center space-x-3">
                          <CopyTextButton
                            text={cardDetails?.cvv}
                            truncate={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
