import React, { useState } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";

export function ImageItem({ imageName, onClick = () => {} }) {
  return (
    <div className="inline-flex flex-row items-center pl-3 pr-1 m-1 rounded-xl glade-bg-grey">
      <span className="glade-black mr-1">{imageName}</span>
      <span onClick={onClick}>
        <IoCloseCircleSharp color={"#717485"} size={25} />
      </span>
    </div>
  );
}

/**
 *
 * @param {number} maximumSize The maximum file size accepted in megabytes
 * @returns
 */
export default function ImageInput({
  label,
  id,
  name,
  multiple = false,
  returnInput,
  accept = ".png, .jpeg, .jpg",
  maximumSize = 10,
  placeholder = "+ Choose Image"
}) {
  const maxSize = maximumSize * (1024 * 1024);
  const [input, setInput] = React.useState({ files: [] });
  const [isFileToLarge, setIsFileToLarge] = useState(false);

  const checkIfFileIsTooLarge = (files) => {
    if (files?.length > 0) {
      for (let index = 0; index < files?.length; index++) {
        const file = files[index];
        if (file?.size > maxSize) {
          setIsFileToLarge(true);
        }
      }
    } else {
      setIsFileToLarge(false);
    }
  };

  const handleFileInput = (e) => {
    const newFiles = [...input.files, ...e.target.files];
    checkIfFileIsTooLarge(newFiles);
    setInput({ files: newFiles });
    returnInput(newFiles);
  };

  const removeFile = (file) => {
    const newFiles = input.files.filter((f) => f !== file);
    checkIfFileIsTooLarge(newFiles);
    setInput({ files: newFiles });
    returnInput(newFiles);
  };

  return (
    <div className="space-y-1 col-span-6 sm:col-span-3">
      <label
        htmlFor={name ?? "image_upload"}
        className="block glade-small-text-two glade-black"
      >
        {label ?? "Upload Image"}
      </label>
      <div className="border-dashed border-2 mt-3 p-2 cursor-pointer rounded-md">
        {input.files.length > 0 ? (
          <div className="w-full">
            {input.files.map((file, index) => (
              <ImageItem
                key={index}
                imageName={file.name}
                onClick={() => removeFile(file)}
              />
            ))}
          </div>
        ) : (
          <label
            htmlFor={name ?? "image_upload"}
            className="flex justify-center text-center cursor-pointer"
          >
            <span className="glade-normal-text-two glade-ash">{placeholder}</span>
            <input
              id={id ?? "image_upload"}
              name={name ?? "image_upload"}
              type="file"
              multiple={multiple}
              onChange={handleFileInput}
              accept={accept}
              className="sr-only"
            />
          </label>
        )}
      </div>

      {isFileToLarge && (
        <p className="glade-normal-text-two glade-red">
          {accept!=='*'?`Supported file types: ${accept}.`:null} Max file size allowed is {maximumSize}MB.
        </p>
      )}
    </div>
  );
}
