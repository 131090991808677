import {
  LOADING_INVOICE,
  RELOAD_INVOICE,
  GET_INVOICE,
  GET_INVOICE_BY_ID,
  GET_INVOICE_COUNTER,
  CREATE_INVOICE,
  CREATE_INVOICE_DRAFT,
  UPDATE_INVOICE,
  UPDATE_INVOICE_STATUS,
  DELETE_INVOICE,
  GET_INVOICE_CURRENCIES,
  SEND_INVOICE_REMINDER,
  CREATE_INVOICE_CUSTOMER,
  GET_INVOICE_CUSTOMER_WHITELISTED,
  GET_INVOICE_CUSTOMER_BLACKLISTED
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload:false,
  invoiceIndex:[],
  invoiceById:{},
  invoiceCounter:{},
  createInvoice:{},
  createInvoiceDraft:{},
  updateInvoice:{},
  updateInvoiceStatus:{},
  deleteInvoice:{},
  invoiceCurrencies:[],
  sendInvoiceReminder:{},
  createInvoiceCustomer:{},
  whitelistedInvoiceCustomers:[],
  blacklistedInvoiceCustomers:[],
};

export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_INVOICE:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_INVOICE:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case GET_INVOICE:
      return {
        ...state,
        invoiceIndex: action.payLoad.data.data,
      };

    case GET_INVOICE_BY_ID:
      return {
        ...state,
        invoiceById: action.payLoad.data.data,
      };

    case GET_INVOICE_COUNTER:
      return {
        ...state,
        invoiceCounter: action.payLoad.data.data,
      };

    case CREATE_INVOICE:
      return {
        ...state,
        createInvoice: action.payLoad.data.data,
      };

    case CREATE_INVOICE_DRAFT:
      return {
        ...state,
        createInvoiceDraft: action.payLoad.data.data,
      };

    case UPDATE_INVOICE:
      return {
        ...state,
        updateInvoice: action.payLoad.data.data,
      };

    case UPDATE_INVOICE_STATUS:
      return {
        ...state,
        updateInvoiceStatus: action.payLoad.data.data,
      };

    case DELETE_INVOICE:
      return {
        ...state,
        deleteInvoice: action.payLoad.data.data,
      };

    case GET_INVOICE_CURRENCIES:
      return {
        ...state,
        invoiceCurrencies: action.payLoad.data.data,
      };

    case SEND_INVOICE_REMINDER:
      return {
        ...state,
        sendInvoiceReminder: action.payLoad.data.data,
      };

    case CREATE_INVOICE_CUSTOMER:
      return {
        ...state,
        createInvoiceCustomer: action.payLoad.data.data,
      };

    case GET_INVOICE_CUSTOMER_WHITELISTED:
      return {
        ...state,
        whitelistedInvoiceCustomers: action.payLoad.data.data,
      };

    case GET_INVOICE_CUSTOMER_BLACKLISTED:
      return {
        ...state,
        blacklistedInvoiceCustomers: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
