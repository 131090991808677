import React, { useState } from "react";
import BulkStepOne from "./recurring bulk flow/BulkStepOne";
import SelectRecurringTransferType from "./SelectRecurringTransferType";
import BulkRecipientDetails from "./recurring bulk flow/BulkRecipientDetails";

const StartRecurringBulkTransfer = ({ input, setInput, setStep }) => {
  const [bulkStep, setBulkStep] = useState(1);
  
  const handleType = (type) => {
    setInput({ ...input, payload: [...input?.payload, {transfer_type: type}] });
    setBulkStep(3);
  };
  return (
    <div>
      {bulkStep === 1 && (
        <BulkStepOne
          bulkStep={bulkStep}
          setBulkStep={setBulkStep}
          setStep={setStep}
          input={input}
          setInput={setInput}
        />
      )}
      {bulkStep === 2 && (
        <SelectRecurringTransferType
        handleType={handleType}
        />
      )}
      {bulkStep === 3 && (
        <BulkRecipientDetails
            input={input}
            setInput={setInput}
            setBulkStep={setBulkStep}
        />
      )}
    </div>
  );
};

export default StartRecurringBulkTransfer;
