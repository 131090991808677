import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import BasicModal from "./BasicModal";

const AccountActivationModal = ({
  openActivationModal,
  setActivationModal,
  complianceStatus,
  isBusiness,
  isTeam,
}) => {
  const history = useHistory();
  const navigateToCompliance = () => {
    if (isBusiness) {
      history.push("/dashboard/settings?tab=6");
    } else {
      !isBusiness && history.push("/dashboard/settings?tab=3");
    }
    setActivationModal(false);
  };

  return (
    <BasicModal
      visibility={openActivationModal}
      onClose={() => {
        setActivationModal(false);
      }}
      modalTitle={""}
      primaryButton=" "
      secondaryButton=""
      modelContentSection={
        <Fragment>
          {!isTeam ? (
            <div className="">
              {complianceStatus === "pending" ? (
                <h3 className="glade-yellow glade-heading-three">
                  <span className="font-bold">Account Verification</span>
                </h3>
              ) : (
                <h3 className="glade-red glade-heading-three">
                  <span className="font-bold">Account Activation</span>
                </h3>
              )}
              {complianceStatus === "pending" ? (
                <p className="">
                  Our team is reviewing your information, you will receive an
                  email or message once completed.
                </p>
              ) : (
                <p className="">
                  Please, submit your compliance document to{" "}
                  <u className="cursor-pointer" onClick={navigateToCompliance}>
                    activate your account.
                  </u>
                </p>
              )}
            </div>
          ) : (
            <div>
              <h3 className="glade-red glade-heading-three">
                <span className="font-bold">Unauthorized Access</span>
              </h3>

              <p>
                Please contact the business owner for permission
              </p>
            </div>
          )}
        </Fragment>
      }
    />
  );
};

export default AccountActivationModal;
