import React from 'react';
import styled from 'styled-components';

const InfoIcon = () => {
  return (
    <Span>i</Span>
  );
};

export default InfoIcon;

const Span = styled.span`
    height: .4rem;
    width: .4rem;
    padding: .7rem;
    font-size: 13px;
    display: flex;
    font-weight: 800;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #6D6D6D;
    color: #fff;
`;