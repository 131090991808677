import React from 'react';
import { RadioGroup } from '@headlessui/react';

export function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#032086" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function HeadlessRadio({data, selected}) {

  const isSelected = (title) => {
    return title === selected.title?true:false
  }

  return (
    <RadioGroup.Option
      key={data.title}
      value={data}
      className={({ active, checked }) =>
        `relative px-3 py-3 rounded-md cursor-pointer 
        ${checked||isSelected(data.title) ? 'glade-bg-blue-100 text-white' : 'bg-white'}`
      }>
      {({ active, checked }) => (
        <React.Fragment>
          <div className="flex items-center justify-between w-full">

            <div className="flex items-center">
              <div className="text-sm">
                <RadioGroup.Label 
                  as="p" 
                  className={`flex glade-normal-text-two capitalize ${checked||isSelected(data.title) ? 'glade-blue-darker' : 'glade-black'}`}>
                  {data?.keycode} {data.title} {data?.reference}
                </RadioGroup.Label>

                <RadioGroup.Description
                  as="span"
                  className={`flex glade-small-text-one ${checked||isSelected(data.title) ? 'glade-blue-darker' : 'glade-black'}`}>
                  {data.description}
                </RadioGroup.Description>
              </div>
            </div>

            {(checked||isSelected(data.title)) && (
              <div className="flex-shrink-0 text-white">
                <CheckIcon className="w-6 h-6" />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </RadioGroup.Option>
  )
}

export function HeadlessRadioGroup({data, preInput={}, children, returnInput=()=>{}}) {
  const [selected, setSelected] = React.useState(preInput);

  return (
    <div className="w-full px-3 py-3">
      <div className="w-full max-w-md mx-auto">
        <RadioGroup value={selected} onChange={(value)=>{setSelected(value); returnInput(value);}}>

          {children??
          <div className="space-y-2">
            {data.length > 0? 
            data.map((item,key) => (
              <HeadlessRadio key={key} data={item} selected={selected} />
            ))
            :
            <div>List is Empty</div>
          }
          </div>}

        </RadioGroup>
      </div>
    </div>
  )
}