import {
  destroyLastLogin,
  updatePersistedLastLogin,
} from "../../helpers/session";
import { LOGOUT_USER, REFRESHED_TOKEN } from "./types";
import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";

export const logoutUserLocallyActionTrue = () => {
  return (dispatch) => {
    destroyLastLogin();
    localStorage.clear();
    dispatch({ type: LOGOUT_USER, payLoad: {} });
    errorHandler({ response: { data: { message: 'Unauthorized access' } } }, false);
  };
};

export const logoutUserLocallyAction = (onLogout=()=>{}) => {
  return (dispatch) => {
    Axios.get(`auth/logout`)
      .then((res) => {
        dispatch({ type: LOGOUT_USER, payLoad: {} });
        destroyLastLogin();
        localStorage.clear();
        successHandler(res, true);
        onLogout();
      })
      .catch((error) => {
        // onFail(error)
        errorHandler(error, true);
        dispatch({ type: LOGOUT_USER, payLoad: {} });
        destroyLastLogin();
        localStorage.clear();
        successHandler({}, true);
        onLogout();
      });
  };
};

export const refreshUserTokenLocallyAction = (payLoad) => {
  return (dispatch) => {
    updatePersistedLastLogin(payLoad);
    dispatch({ type: REFRESHED_TOKEN, payLoad: payLoad });
  };
};
