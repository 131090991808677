import {
  LOADING_VIRTUAL_CARDS,
  RELOAD_VIRTUAL_CARDS,
  GET_VIRTUAL_CARD_TITLES,
  GET_VIRTUAL_CARD_CURRENCIES,
  GET_VIRTUAL_CARD_DESIGNS,
  CREATE_VIRTUAL_CARD,
  GET_VIRTUAL_CARDS,
  GET_VIRTUAL_CARD_DETAILS,
  GET_VIRTUAL_CARD_TRANSACTIONS,
  FUND_VIRTUAL_CARD,
  WITHDRAW_VIRTUAL_CARD_FUND,
  FREEZE_VIRTUAL_CARD,
  UNFREEZE_VIRTUAL_CARD,
  TERMINATE_VIRTUAL_CARD,
  SEARCH_VIRTUAL_CARDS,
  FILTER_VIRTUAL_CARDS,
  LOADING_EDIT_VIRTUAL_CARDS,
  EDIT_VIRTUAL_CARDS_SUCCESS,
  EDIT_VIRTUAL_CARDS_FAILURE,
  GET_VIRTUAL_CARD_CHARGES
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload: false,
  isLoadingCards: false,
  editVirtualCard: {},
  virtualCardTitles: [],
  virtualCardCurrencies: [],
  virtualCardDesigns: [],
  createdVirtualCard: {},
  virtualCardIndex: [],
  virtualCardDetails: [],
  virtualCardTransactions: {},
  fundVirtualCard:{},
  withdrawVirtualCardFund:{},
  freezeVirtualCard:{},
  unfreezeVirtualCard:{},
  terminateVirtualCard:{},
  virtualCardSearchResults: [],
  virtualCardFilterResults: [],
  virtualCardCharges:{}
};

export default function cardReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_VIRTUAL_CARDS:
      return {
        ...state,
        isLoading: action.payLoad,
        virtualCardFilterResults: []
      };

    case RELOAD_VIRTUAL_CARDS:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };


      case LOADING_EDIT_VIRTUAL_CARDS: 
      return {
        ...state,
        isLoadingCards: true,
        editVirtualCard: {}
      }
      case EDIT_VIRTUAL_CARDS_SUCCESS:
        return {
          ...state,
          isLoadingCards: false,
          editVirtualCard: action.payLoad.data.data,
        };
      case EDIT_VIRTUAL_CARDS_FAILURE:
        return {
          ...state,
          isLoadingCards: false,
          editVirtualCard: {},
        };
    case GET_VIRTUAL_CARD_TITLES:
      return {
        ...state,
        virtualCardTitles: action.payLoad.data.data,
      };

    case GET_VIRTUAL_CARD_CURRENCIES:
      return {
        ...state,
        virtualCardCurrencies: action.payLoad.data.data,
      };

    case GET_VIRTUAL_CARD_DESIGNS:
      return {
        ...state,
        virtualCardDesigns: action.payLoad.data.data,
      };

    case CREATE_VIRTUAL_CARD:
      return {
        ...state,
        createdVirtualCard: action.payLoad.data.data,
      };

    case GET_VIRTUAL_CARDS:
      return {
        ...state,
        virtualCardIndex: action.payLoad.data.data,
      };

    case GET_VIRTUAL_CARD_DETAILS:
      return {
        ...state,
        virtualCardDetails: action.payLoad.data.data,
      };

    case GET_VIRTUAL_CARD_TRANSACTIONS:
      return {
        ...state,
        virtualCardTransactions: action.payLoad.data.data,
      };

    case FUND_VIRTUAL_CARD:
      return {
        ...state,
        fundVirtualCard: action.payLoad.data.data,
      };

    case WITHDRAW_VIRTUAL_CARD_FUND:
      return {
        ...state,
        withdrawVirtualCardFund: action.payLoad.data.data,
      };

    case FREEZE_VIRTUAL_CARD:
      return {
        ...state,
        freezeVirtualCard: action.payLoad.data.data,
      };

    case UNFREEZE_VIRTUAL_CARD:
      return {
        ...state,
        unfreezeVirtualCard: action.payLoad.data.data,
      };

    case TERMINATE_VIRTUAL_CARD:
      return {
        ...state,
        terminateVirtualCard: action.payLoad.data.data,
      };

    case SEARCH_VIRTUAL_CARDS:
      return {
        ...state,
        virtualCardSearchResults: action.payLoad.data.data,
      };

    case FILTER_VIRTUAL_CARDS:
      return {
        ...state,
        virtualCardFilterResults: action.payLoad.data.data,
      };
      case GET_VIRTUAL_CARD_CHARGES:
      return {
        ...state,
        virtualCardCharges: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
