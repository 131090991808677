import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { CardTableHeaderPlain } from "../../components/cards/CardTableHeader";
import Button from "../../components/buttons/Button";
import {
  getDashboardFeatures,
  getPaymentMethods,
  getStatusOfAgentRequest,
  updateDashboardFeatures,
  updatePaymentMethods,
  getGuarantorTemplate,
} from "../../redux/settings/settingsActions";
import useSettings from "../../hooks/useSettings";
import useUser from "../../hooks/useUser";
import { toTitleCase } from "../../helpers/helper";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import usePos from "../../hooks/usePos";
import { getPendingPosRequests } from "../../redux/pos/posActions";
import { getActivePosTerminals } from "../../redux/pos/posActions";
import { useEffect } from "react";

export default function SettingsManageServices() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const pos = usePos();

  const settings = useSettings();
  React.useEffect(() => {
    dispatch(getPendingPosRequests());
    // eslint-disable-next-line
  }, [pos.shouldReload]);
  React.useEffect(() => {
    dispatch(getActivePosTerminals());
    // eslint-disable-next-line
  }, [pos.shouldReload]);
  const [pendingRequestPos, setPendingRequestPos] = React.useState([]);
  const [activeRequestPos, setActiveRequestPos] = React.useState([]);

  React.useEffect(() => {
    // setPendingRequest(pagination(pos.pendingPosRequests, 10, currentPage));
    setPendingRequestPos(pos.pendingPosRequests);
    // eslint-disable-next-line
  }, [pos.pendingPosRequests]);

  React.useEffect(() => {
    user.isBusiness && dispatch(getDashboardFeatures());
    user.isBusiness && dispatch(getPaymentMethods());
    !user.isBusiness && dispatch(getStatusOfAgentRequest());
    !user.isBusiness && dispatch(getGuarantorTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.shouldReload]);

  const [inputDashboardFeatures, setInputDashboardFeatures] = React.useState({});
  const [inputPaymentMethods, setInputPaymentMethods] = React.useState({});
  const [disabledDF, setDisabledDF] = React.useState(true);
  const [disabledPM, setDisabledPM] = React.useState(true);

  useEffect(() => {
    settings.dashboardFeatures && setInputDashboardFeatures(settings.dashboardFeatures);
    settings.paymentMethods && setInputPaymentMethods(settings.paymentMethods);
  }, [settings.dashboardFeatures, settings.paymentMethods])

  const onSubmitDashboardFeatures = () => {
    user.isBusiness && dispatch(updateDashboardFeatures(inputDashboardFeatures));
  };

  const onSubmitPaymentMethods = () => {
    user.isBusiness && dispatch(updatePaymentMethods(inputPaymentMethods));
  };

  const onChangeDashboardFeatures = (e) => {
    setInputDashboardFeatures({ ...inputDashboardFeatures, [e.target.name]: e.target.checked });
    setDisabledDF(false);
  };

  const onChangePaymentMethods = (e) => {
    setInputPaymentMethods({ ...inputPaymentMethods, [e.target.name]: e.target.checked });
    setDisabledPM(false);
  };

  return (
    <div className="px-3 py-0">
      <div className="mb-9 md:hidden">
        <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
      </div>

      <CardTableHeaderPlain
        title={"Services"}
      />

      <div className="relative min-w-0 w-full bg-white border-2 border-t-0 border-gray-100">
        <div className="p-9 space-y-9">
          {user.isBusiness && (
            <div className="md:flex">
              <div className="md:w-full md:border-r-2">
                <div className="space-y-6 flex flex-col">
                <h4 className="glade-normal-text-three glade-home-dark px-3">
                  Features available on dashboard
                </h4>
                {settings.dashboardFeatures &&
                  Object.keys(settings.dashboardFeatures).map(
                    (feature, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CheckboxInput
                            label={toTitleCase(feature ?? "")}
                            id={feature}
                            name={feature}
                            type={"checkbox"}
                            checked={inputDashboardFeatures[feature]}
                            onChange={onChangeDashboardFeatures}
                            placeholder={""}
                            autoComplete={feature}
                            className={"appearance-none ml-3"}
                          />
                        </React.Fragment>
                      );
                    }
                    )}
                  <div className="self-start">
                    <Button.Blue
                      title={"Save Changes"}
                      disabled={disabledDF}
                      onClick={onSubmitDashboardFeatures}
                      className={"mt-3 ml-3"}
                    />
                    </div>
                </div>
              </div>
              <div className="md:w-full md:mx-9 mt-9 md:mt-0">
                <div className="flex flex-col space-y-6">
                <h4 className="glade-normal-text-three glade-home-dark px-3">
                  Payment methods
                </h4>
                {settings.paymentMethods &&
                  Object.keys(settings.paymentMethods).map((method, index) => {
                    return (
                      <React.Fragment key={index}>
                        <CheckboxInput
                          label={toTitleCase(method ?? "")}
                          id={method}
                          name={method}
                          type={"checkbox"}
                          checked={inputPaymentMethods[method]}
                          onChange={onChangePaymentMethods}
                          placeholder={""}
                          autoComplete={method}
                          className={"appearance-none mx-3"}
                        />
                      </React.Fragment>
                    );
                  })}
                  <div className="self-start">
                    <Button.Blue
                      title={"Save Changes"}
                      disabled={disabledPM}
                      onClick={onSubmitPaymentMethods}
                      className={"mt-3 ml-3"}
                    />
                    </div>
              </div>
              </div>
            </div>
           
          )}
          
          {!user.isBusiness && (
            <>
              {/* <div className="w-full flex flex-row">
                <div className="w-5/12 flex flex-col space-y-6">
                  <h4 className="glade-normal-text-three glade-black px-3">
                    Service Request
                  </h4>
                </div>
                <div className="w-7/12 flex flex-col space-y-6">
                  {settings.agentRequestStatus?.status === "rejected" ||
                  !settings.agentRequestStatus?.status ? (
                    <div className="flex">
                      <div>
                        <Button.Blue
                          onClick={() =>
                            history.push(
                              "/dashboard/settings/manage-services/become-an-agent"
                            )
                          }
                          title="Become an Agent"
                        />
                      </div>
                    </div>
                  ) : null}

                  {settings.agentRequestStatus?.status === "pending" ? (
                    <h5 className="glade-normal-text-two glade-black">
                      Your request to become an agent has been submitted and is
                      being reviewed, Kindly wait for approval.
                    </h5>
                  ) : null}

                  {settings.agentRequestStatus?.status === "approved" ? (
                    <h5 className="glade-normal-text-two glade-black">
                      Your request to become an agent has approved.
                    </h5>
                  ) : null}
                </div>
              </div> */}
              {/* Request POS */}
              <div className="w-full flex flex-row">
                <div className="w-5/12 flex flex-col space-y-6">
                  <h4 className="glade-normal-text-three glade-black px-3">
                    Service Request
                  </h4>
                </div>
                <div className="w-7/12 flex flex-col space-y-6">
                  {pendingRequestPos?.length === 0 &&
                  activeRequestPos?.length === 0 ? (
                    <div className="flex">
                      <div>
                        <Button.Blue
                          onClick={() =>
                            history.push(
                              "/dashboard/settings/manage-services/request-pos"
                            )
                          }
                          title="Request a POS"
                        />
                      </div>
                    </div>
                  ) : null}

                  {pendingRequestPos.length > 0 &&
                  activeRequestPos.length === 0 ? (
                    <h5 className="glade-normal-text-two glade-black">
                      Your request for a POS has been submitted and is being
                      reviewed, Kindly wait for approval.
                    </h5>
                  ) : null}

                  {activeRequestPos.length > 0 ? (
                    <h5 className="glade-normal-text-two glade-black">
                      POS service is live.
                    </h5>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

