import React from 'react'
import { EmptyFolder } from '../../assets/icons/Icons';
import { Fragment } from 'react';

export default function EmptyCard({className, title, subtitle, loading=false}) {

    return (
        <Fragment>
            {loading ?
                <div className="animate-bounce">
                    <EmptyFolder color="#1634A1" />
                </div>
            :
                <div className={className+" relative flex justify-center items-center bg-white mt-12"}>
                    <div className="flex flex-col">
                        <div className="inline-flex justify-center my-3">
                            <EmptyFolder color="#9CA3AF" />
                        </div>
                        <h3 className="glade-heading-three glade-black text-center my-1">
                            {title??'No Data'}
                        </h3>
                        <h6 className="glade-normal-text-two glade-ash text-center my-1">
                            {subtitle??'We don\'t have any information for this category'}
                        </h6>
                    </div>
                </div>
            }
        </Fragment>
    )
}
