import { useSelector } from "react-redux";

const usePaymentFlows = () => {
  const paymentFlows = useSelector((state) => state.paymentFlows);

  const {
    isLoading,
    allPaymentFlows,
    shouldReload,
    paymentInflowSummary,
    paymentOutflowSummary,
    specificPaymentFlow,
    downloadAllNGNPaymentFlows
  } = paymentFlows;


  const inflow = paymentInflowSummary;
  const totalInflow = inflow ? Object.values(inflow).reduce((t,{value})=>(value ? t + Number(value) : t), 0).toLocaleString(): 0;

  const outflow = paymentOutflowSummary;
  const totalOutflow = outflow ? Object.values(outflow).reduce((t,{value})=>(value ? t + Number(value) : t), 0).toLocaleString() : 0;

  return {
    isLoading,
    shouldReload,
    inflow: {
      ...inflow,
      totalInflow,
    },
    outflow: {
      ...outflow,
      totalOutflow,
    },
    paymentInflowSummary,
    paymentOutflowSummary,
    allPaymentFlows,
    specificPaymentFlow,
    downloadAllNGNPaymentFlows
  };
};

export default usePaymentFlows;