import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import TextIndicator from "../../components/indicators/TextIndicator";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import {
  ButtonWithDropDown,
  ButtonWithDropDownItem,
} from "../../components/buttons/ButtonWithDropDown";
import { getFundTransfer } from "../../redux/fund-transfer/fundTransferActions";
import useFundTransfer from "../../hooks/useFundTransfer";
import {
  statusEnum,
  simpleDate,
  isArray,
  isEmpty,
  pagination,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import SearchInput from "../../components/inputs/SearchInput";
import Sieve from "../../components/filter/Sieve";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import { IoIosArrowForward } from "react-icons/io";
import { TransferFormContext } from "../../context/TransferFormContext";
import FundTransferInternational from "../fund-transfer-new/FundTransferInternational";
import Button from "../../components/buttons/Button";
import useUser from "../../hooks/useUser";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";
import { downloadFundTransferReceiptAsPDF } from "./DownloadFundTransferReceiptAsPDF";
import useTeam from "../../hooks/useTeam";

export default function FundTransfer() {
  const history = useHistory();
  const user = useUser();
  const dispatch = useDispatch();
  const fundTransfer = useFundTransfer();
  const location = useLocation();
  const { permissions } = useTeam();

  useEffect(() => {
    dispatch(getFundTransfer());
    setShowReciept("");
    // eslint-disable-next-line
  }, [user?.selectedBusinessOrPersonalAccount?.selectedCurrency]);

  const { openForm, showForm } = useContext(TransferFormContext);
  const [showReciept, setShowReciept] = useState("");
  // Pagination Function And Control
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      pagination(fundTransfer.fundTransferIndex ?? [], 10, currentPage)
    );
    // eslint-disable-next-line
  }, [currentPage, fundTransfer.fundTransferIndex]);

  // Search Function And Control
  const onSearch = (search) => {
    const filteredData = fundTransfer.fundTransferIndex?.filter((item) => {
      return item.txn_ref.toLowerCase().includes(search.toLowerCase());
    });
    setTableData(pagination(filteredData ?? [], 10, currentPage));
  };

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getFundTransfer({
        status: data.status,
        date_from: data.date_from ?? data.date,
        date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
      })
    );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getFundTransfer());
  };
  // Check to open modal
  useEffect(() => {
    return location?.state?.showModal ? openForm() : null;
  }, []);

  const renderFundTransferTable = (data) => {
    const onClickOfRow = (item) => {
      history.push({
        pathname: !permissions?.includes("fund transfer")
          ? "/dashboard/fund-transfer/detail"
          : "/teams/fund-transfer/detail",
        state: item,
      });
    };

    return data.map((item, key) => {
      return (
        <React.Fragment key={key}>
          <CardTableBodyRow key={key} className="cursor-pointer">
            <CardTableBodyCell
              onClick={() => onClickOfRow(item)}
            ></CardTableBodyCell>
            <CardTableBodyCell onClick={() => onClickOfRow(item)}>
              <TextIndicator
                type={statusEnum[item.status]}
                title={item.status}
              />
            </CardTableBodyCell>
            <CardTableBodyCell onClick={() => onClickOfRow(item)}>
              <span className="glade-normal-text-two">
                {simpleDate(item.created_at)}
              </span>
            </CardTableBodyCell>
            <CardTableBodyCell onClick={() => onClickOfRow(item)}>
              <span className="glade-normal-text-two">
                {item.beneficiary_name}
              </span>
            </CardTableBodyCell>
            <CardTableBodyCell>
              <CopyTextButton text={item.txn_ref} />
            </CardTableBodyCell>
            <CardTableBodyCell onClick={() => onClickOfRow(item)}>
              <span className="glade-normal-text-one">
                {item.currency} {Number(item.value ?? 0)?.toLocaleString()}
              </span>
            </CardTableBodyCell>
            <CardTableBodyCell>
              {showReciept !== key ? (
                <span
                  onClick={() => {
                    setShowReciept(key);
                  }}
                >
                  <RiArrowDropDownLine size={"28px"} color="#000000" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setShowReciept("");
                  }}
                >
                  <RiArrowDropUpLine size={"28px"} color="#000000" />
                </span>
              )}
            </CardTableBodyCell>
          </CardTableBodyRow>
          {showReciept === key && (
            <div className="" key={`key${key}`}>
              <div
                className="md:flex flex-col p-4 absolute w-full justify-center items-center"
                style={{ background: "#F6F8FF" }}
              >
                <CardPlainWithButton>
                  <CardPlainWithButtonSection
                    title={"Transfer Details"}
                    loading={false}
                    buttonDisabled={false}
                    buttonTitle={"Download Receipt"}
                    buttonSection={
                      <div className="flex p-4 gap-4">
                        <Button.Blue
                          title="Download Receipt"
                          onClick={() =>
                            downloadFundTransferReceiptAsPDF({ ...item })
                          }
                        />
                        <Button.Transparent
                          title="Report Transaction"
                          className="glade-red"
                          onClick={() =>
                            history.push({
                              pathname: !permissions?.includes("fund transfer")
                                ? "/dashboard/report-transfer"
                                : "/teams/report-transfer",
                              state: item,
                            })
                          }
                        />
                      </div>
                    }
                  >
                    <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                      <h3 className="glade-normal-text-two glade-grey-cool">
                        Transaction Date
                      </h3>
                      <h3 className="glade-normal-text-three glade-black">
                        {simpleDate(item?.created_at)}
                      </h3>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between gap-6 py-4 border-b border-solid border-gray-200 items-center">
                      <h3 className="glade-normal-text-two glade-grey-cool">
                        Transaction Reference
                      </h3>
                      <h3 className="glade-normal-text-three glade-black">
                        {item?.txn_ref}
                      </h3>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                      <h3 className="glade-normal-text-two glade-grey-cool">
                        Amount
                      </h3>
                      <h3 className="glade-normal-text-three glade-black">
                        {item?.currency}{" "}
                        {Number(item?.value ?? 0)?.toLocaleString()}
                      </h3>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                      <h3 className="glade-normal-text-two glade-grey-cool">
                        Recipient
                      </h3>
                      <h3 className="glade-normal-text-three glade-black">
                        {item?.beneficiary_name}
                      </h3>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                      <h3 className="glade-normal-text-two glade-grey-cool">
                        Recipient’s Bank
                      </h3>
                      <h3 className="glade-normal-text-three glade-black">
                        {item?.beneficiary_account} {item?.bank_name}
                      </h3>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between py-4 items-center">
                      <h3 className="glade-normal-text-two glade-grey-cool">
                        Status
                      </h3>
                      <TextIndicator
                        type={statusEnum[item?.status]}
                        title={item?.status}
                      />
                    </div>
                  </CardPlainWithButtonSection>
                </CardPlainWithButton>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  const renderFundTransferDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() =>
            history.push({
              pathname: !permissions?.includes("fund transfer")
                ? "/dashboard/fund-transfer/detail"
                : "/teams/fund-transfer/detail",
              state: item,
            })
          }
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item.currency} {Number(item?.value ?? 0)?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  {item.beneficiary_name}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {simpleDate(item?.created_at, "numeric", "short", "2-digit")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      {showForm && <FundTransferInternational />}
      <CardTableHeaderComplex
        title={`Showing ${tableData.totalItems} records`}
        filter={
          <Sieve
            types={["status", "date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={fundTransfer.isLoading}
            loading={fundTransfer.isLoading}
          />
        }
        searchBar={
          <SearchInput
            disabled={fundTransfer.isLoading}
            onSearch={onSearch}
            placeholder={"Transaction Reference"}
          />
        }
        rightButton={
          <div className="flex flex-row space-x-3">
            {!permissions?.includes("fund transfer") && (
              <Fragment>
                <Button.Blue
                  title={"New Transfer"}
                  onClick={() => {
                    setShowReciept("");
                    return !permissions?.includes("fund transfer")
                      ? openForm()
                      : "";
                  }}
                />
                <ButtonWithDropDown className="z-10" title="New Bulk Transfer">
                  <ButtonWithDropDownItem
                    activeChild={
                      <span
                        onClick={() => {
                          setShowReciept("");
                          history.push(
                            !permissions?.includes("fund transfer")
                              ? "/dashboard/fund-transfer/bulk-transfer/manual"
                              : "/teams/fund-transfer/bulk-transfer/manual"
                          );
                        }}
                        className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                      >
                        Manual Upload
                      </span>
                    }
                    inactiveChild={
                      <span className="glade-normal-text-two glade-black p-4">
                        Manual Upload
                      </span>
                    }
                  />
                  <ButtonWithDropDownItem
                    activeChild={
                      <span
                        onClick={() => {
                          history.push(
                            !permissions?.includes("fund transfer")
                              ? "/dashboard/fund-transfer/bulk-transfer/upload"
                              : "/teams/fund-transfer/bulk-transfer/upload"
                          );
                          setShowReciept("");
                        }}
                        className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                      >
                        File Upload
                      </span>
                    }
                    inactiveChild={
                      <span className="glade-normal-text-two glade-black p-4">
                        File Upload
                      </span>
                    }
                  />
                </ButtonWithDropDown>
              </Fragment>
            )}
          </div>
        }
        kebabMenuButton={
          <div>
            {!permissions?.includes("fund transfer") &&
            <ButtonWithDropDown
              className="z-10"
              title="New Transfer"
              disabled={fundTransfer.isLoading}
            >
              <ButtonWithDropDownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["status", "date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={fundTransfer.isLoading}
                    loading={fundTransfer.isLoading}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() => openForm("glade")}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Glade Transfer
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Glade Transfer
                  </span>
                }
              />
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() => openForm("local")}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Local Transfer
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Local Transfer
                  </span>
                }
              />
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() => openForm("international")}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    International Transfer
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    International Transfer
                  </span>
                }
              />
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() =>
                      history.push(
                        !permissions?.includes("fund transfer")
                          ? "/dashboard/fund-transfer/bulk-transfer/manual"
                          : "/teams/fund-transfer/bulk-transfer/manual"
                      )
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Manual Upload
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Manual Upload
                  </span>
                }
              />
              <ButtonWithDropDownItem
                activeChild={
                  <span
                    onClick={() =>
                      history.push(
                        !permissions?.includes("fund transfer")
                          ? "/dashboard/fund-transfer/bulk-transfer/upload"
                          : "/teams/fund-transfer/bulk-transfer/upload"
                      )
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    File Upload
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    File Upload
                  </span>
                }
              />
            </ButtonWithDropDown>}
          </div>
        }
      />

      {isEmpty(tableData.data) || fundTransfer.isLoading ? (
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable
              column={[
                "",
                "Status",
                "Date",
                "Recipient",
                "Transaction Ref",
                "Amount",
                "",
              ]}
              loading={fundTransfer.isLoading}
            />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2, 3]} loading={fundTransfer.isLoading} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="hidden md:block">
            <CardTableBody
              column={[
                "",
                "Status",
                "Date",
                "Recipient",
                "Transaction Ref",
                "Amount",
                "",
              ]}
            >
              {isArray(tableData.data) &&
                renderFundTransferTable(tableData.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) &&
                renderFundTransferDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={fundTransfer.isLoading}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
    </div>
  );
}
