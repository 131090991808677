import React from 'react';
import { FormWizard, FormWizardSection } from '../../components/form/FormWizard';
import IndividualInput from '../../components/inputs/IndividualInput';
import { Carousel, CarouselSlide } from '../../components/carousel/Carousel';
import {GLADE_INFO_GRAPHICS_ONE,GLADE_INFO_GRAPHICS_TWO,GLADE_INFO_GRAPHICS_THREE,GLADE_INFO_GRAPHICS_FOUR} from '../../assets/images/images';
import {PopoverMain} from '../../components/popovers/Popover';
import Sieve from "../../components/filter/Sieve";
import Button from "../../components/buttons/Button";
import PipedButton from "../../components/buttons/PipedButton";
import BasicModal from '../../components/modals/BasicModal';
import PlainModal from '../../components/modals/PlainModal';
import {CardPlain, CardPlainSection} from '../../components/cards/CardPlain';
import {CardPlainWithButton, CardPlainWithButtonSection} from '../../components/cards/CardPlainWithButton';
import {Dropdown, DropdownItem} from '../../components/dropdowns/Dropdown';
import {HeadlessRadioGroup} from '../../components/radios/HeadlessRadioGroup';
import {Logout, Payroll, Cancel, Invoice, PayBills, Pos } from '../../assets/icons/Icons';
import TextInput from '../../components/inputs/TextInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import NumericInput from '../../components/inputs/NumericInput';
import ImageInput from '../../components/inputs/ImageInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import SearchSelectInput from '../../components/inputs/SearchSelectInput';
import NoticeModal from '../../components/modals/NoticeModal';

export default function ExampleDashboard() {

    // Form wizard
    const ref = React.useRef(null);

    // Notice Modal
    const NoticeModalRef = React.useRef(null);

    // Plain Modal
    const PlainModalRef = React.useRef(null);

    // HeadlessRadioGroup state
    const [headlessRadioGroupState, setHeadlessRadioGroupState] = React.useState({});

    // Carousel
    const IMAGES = [
        {
            imageUrl: GLADE_INFO_GRAPHICS_ONE,
            placeHolder: "Paris"
        },
        {
            imageUrl: GLADE_INFO_GRAPHICS_TWO,
            placeHolder: "Dog"
        },
        {
            imageUrl: GLADE_INFO_GRAPHICS_THREE,
            placeHolder: "Cat"
        },
        {
            imageUrl: GLADE_INFO_GRAPHICS_FOUR,
            placeHolder: "Forest"
        },
    ];

    // Inputs
    const [input, setInput] = React.useState({
        title:'',
        currency:'',
        food:'',
        currencySymbol:'',
        vat:'',
        number_entered:'',
        charge_user:false,
        business_proof_of_address:[],
        contact_address:''
    });
    const onChange = (e) => {
        setInput({...input,[e.target.name]:e.target.value});
    }

    return (
        <React.Fragment>

            <div className="my-10">
                <h1>Version:1.0.2</h1>
            </div>

            <div className="my-10">
                <h1>Notice Modal</h1>
                <div>
                    <button onClick={()=>NoticeModalRef.current.open()}>Toggle Notice Modal</button>
                    <NoticeModal ref={NoticeModalRef} />
                </div>
            </div>

            <div className="my-10">
                <h1>Inputs</h1>
                <div>
                    <TextAreaInput 
                        label={'Contact Address'}
                        id={'contact_address'}
                        name={'contact_address'}
                        rows={3}
                        type={'text'}
                        value={input.contact_address}
                        onChange={onChange}
                        placeholder={'Enter address'}
                        autoComplete={'contact_address'}
                        className={'appearance-none'}
                    />
                    <TextInput 
                        label={'Page Name'}
                        id={'title'}
                        name={'title'}
                        type={'text'}
                        value={input.title}
                        onChange={onChange}
                        placeholder={'Enter page name'}
                        autoComplete={'title'}
                        className={'appearance-none'}
                    />
                    <SelectInput 
                        label={'Select Currency'}
                        id={'currency'}
                        name={'currency'}
                        value={input.currency}
                        onChange={onChange}
                        autoComplete={'currency'}
                        className={'appearance-none'}>
                        <option value={''} disabled={true}>Select A Currency</option>
                        {[{currency_id:'1',currency:'NGN'}].map((item, key)=>{
                            return (
                                <option key={key} value={item.currency_id}>{item.currency}</option>
                            );
                        })}
                    </SelectInput>
                    <SearchSelectInput 
                        label={'Select Food'}
                        id={'food'}
                        name={'food'}
                        value={input.food}
                        onChange={onChange}
                        autoComplete={'food'}
                        className={'appearance-none'}>
                        <option value={''} disabled={true}>Select A Food</option>
                        {[
                            {name:'rice',type:'carbohydrate'},
                            {name:'beans',type:'protein'},
                            {name:'beef',type:'protein'},
                            {name:'corn',type:'carbohydrate'},
                            {name:'water',type:'water'},
                        ].map((item, key)=>{
                            return (
                                <option key={key} value={item.name}></option>
                            );
                        })}
                    </SearchSelectInput>
                    <NumericInput 
                        label={'VAT (%)'}
                        id={'vat'}
                        name={'vat'}
                        type={'number'}
                        value={input.vat}
                        onChange={onChange}
                        placeholder={'Enter A VAT Percentage'}
                        autoComplete={'vat'}
                        className={'appearance-none'}
                    />
                    <NumericInput 
                        label={'Enter a number'}
                        id={'number_entered'}
                        name={'number_entered'}
                        type={'number'}
                        value={input.number_entered}
                        onChange={onChange}
                        placeholder={'Enter A Number'}
                        autoComplete={'number_entered'}
                        className={'appearance-none'}
                    />
                    <CheckboxInput 
                        label={'Charge user?'}
                        id={'charge_user'}
                        name={'charge_user'}
                        type={'checkbox'}
                        checked={input.charge_user}
                        onChange={(e)=>setInput({...input,[e.target.name]:e.target.checked})}
                        placeholder={'Charge user (optional)'}
                        autoComplete={''}
                        className={'appearance-none'}
                    />
                    <ImageInput 
                        label={'Upload Utility Bill'} 
                        id={'business_proof_of_address'} 
                        name={'business_proof_of_address'} 
                        multiple={false} 
                        returnInput={(value)=>setInput({...input,business_proof_of_address:value})}
                    />
                </div>
            </div>


            <div className="my-10">
                <h1>Icons</h1>
                <div>
                    <Logout />
                    <Payroll />
                    <Cancel />
                    <Invoice />
                    <PayBills />
                    <Pos />
                </div>
            </div>

            <div className="my-10">
                <h1>Card Dual With Button</h1>
                <CardPlainWithButton type={'dual'}>
                    <CardPlainWithButtonSection type={'dual'}>
                        <span>hello world</span>
                        <span>hello world</span>
                    </CardPlainWithButtonSection>
                    <CardPlainWithButtonSection type={'dual'}>
                        <span>hello world</span>
                        <span>hello world</span>
                    </CardPlainWithButtonSection>
                </CardPlainWithButton>
            </div>

            <div className="my-10">
                <h1>Headless Radio Group</h1>
                <HeadlessRadioGroup
                    preInput={headlessRadioGroupState} 
                    returnInput={setHeadlessRadioGroupState}
                    data={[
                        {
                            title: 'Startup',
                            description: '160 GB SSD disk',
                        },
                        {
                            title: 'Business',
                            description: '512 GB SSD disk',
                        },
                        {
                            title: 'Enterprise',
                            description: '1024 GB SSD disk',
                        },
                    ]} 
                />
            </div>

             <div className="my-10 ml-64">
                <h1>Drop down</h1>
                <Dropdown>
                    <DropdownItem 
                        activeChild={<span>hello</span>}
                        inactiveChild={<span>world</span>}
                    />
                    <DropdownItem 
                        activeChild={<span>the quick</span>}
                        inactiveChild={<span>brown fox</span>}
                    />
                </Dropdown>
            </div>

            <div className="my-10">
                <h1>Card Plain</h1>
                <CardPlain>
                    <CardPlainSection>
                        <span>hello world</span>
                        <span>hello world</span>
                    </CardPlainSection>
                </CardPlain>
            </div>

            <div className="my-10">
                <h1>Button Piped</h1>
                <PipedButton />
            </div>

            <div className="my-10">
                <h1>Button</h1>
                <Button.Blue />
            </div>

            <div className="my-10">
                <h1>Button</h1>
                <Button.Danger />
            </div>

            <div className="my-10">
                <h1>Button</h1>
                <Button.Green />
            </div>

            <div className="my-10">
                <h1>Button</h1>
                <Button.Transparent />
            </div>

            <div className="my-10">
                <h1>Modal Basic</h1>
                <BasicModal visibility={false} />
            </div>

            <div className="my-10">
                <h1>Modal Plain</h1>
                <PlainModal ref={PlainModalRef} visibility={false} />
                <br/>
                <button onClick={()=>PlainModalRef.current.open()}>Plain Modal Via Ref Open</button>
                <br/>
                <button onClick={()=>PlainModalRef.current.close()}>Plain Modal Via Ref Close</button>
                <br/>
                <button onClick={()=>PlainModalRef.current.toggle()}>Plain Modal Via Ref Toggle</button>
            </div>

            <div className="my-10">
                <h1>Filter Button</h1>
                <Sieve returnInput={(value)=>{}} />
            </div>

            <div className="my-10">
                <h1>Popover / Drop down</h1>
                <PopoverMain button={<span>Click Me To See Popover</span>}>
                    <h6>Hello world</h6>
                </PopoverMain>
            </div>

            <div className="my-10">
                <h1>Carousel</h1>
                <Carousel time={3000}>
                    {IMAGES.map((item,key)=>{
                        return (
                            <CarouselSlide key={key} imageUrl={item.imageUrl} placeHolder={item.placeHolder} />
                        )
                    })}
                </Carousel>
            </div>

            <div className="my-6">
                <h1>Form Wizard</h1>
                <FormWizard ref={ref}>
                    <FormWizardSection position={0}>
                        <div>
                            the quick
                        </div>
                    </FormWizardSection>
                    <FormWizardSection position={1}>
                        <div>
                            brown fox
                        </div>
                    </FormWizardSection>
                    <FormWizardSection position={2}>
                        <div>
                            jumps over
                        </div>
                    </FormWizardSection>
                </FormWizard>

                <div className="flex mt-1">
                    <button className="" onClick={()=>{ ref.current.prev(); }}>Prev</button>
                    <button className="mx-6" onClick={()=>{ ref.current.next(); }}>Next</button>
                </div>
            </div>

            <div className="my-6">
                <h1>Single Input</h1>
                <IndividualInput 
                    label={'Pin Code'}
                    name={'pin_code'}
                />
            </div>
        </React.Fragment>
    )
}
