import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import { useLocation, useHistory } from "react-router-dom";
import { toTitleCase } from "../../helpers/helper";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import TextInput from "../../components/inputs/TextInput";
import useBusiness from "../../hooks/useBusiness";
import {
  addTeamMember,
  editTeamMember,
  getTeamDetails,
  getTeamRoles,
} from "../../redux/business/businessActions";

const AddandEditTeam = ({}) => {
  const dispatch = useDispatch();
  const business = useBusiness();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
 

  React.useEffect(() => {
    dispatch(getTeamRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    position: "",
    permissions: [1],
  });
  const [featurePermissions, setFeaturePermissions] = useState({dashboard: [1]});

  // Check if the URL contains "/edit"
  const isEdit = location?.pathname?.includes("/edit");

  React.useEffect(() => {
    if (isEdit && id.length) {
      dispatch(getTeamDetails(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (isEdit) {
      setInput({
        ...input,
        id: business?.teamDetails?.id,
        first_name: business?.teamDetails?.first_name,
        last_name: business?.teamDetails?.last_name,
        phone: business?.teamDetails?.phone,
        email: business?.teamDetails?.email,
        position: business?.teamDetails?.position,
        permissions: business?.teamDetails?.permissions,
      });

      // Set initial checked state for permissions checkboxes
      const initialPermissionsState = {};
      business?.teamDetails?.permissions?.forEach((permissionId) => {
        business?.teamRoles?.forEach((teamRole) => {
          if (teamRole.id === permissionId) {
            if (!initialPermissionsState[teamRole.feature]) {
              initialPermissionsState[teamRole.feature] = [];
            }
            initialPermissionsState[teamRole.feature].push(permissionId);
          }
        });
      });
      setFeaturePermissions(initialPermissionsState);
    }else{

    }

  }, [business.teamDetails]);

  // Group permissions by feature
  const groupedPermissions = {};
  business?.teamRoles?.forEach((teamRole) => {
    if (!groupedPermissions[teamRole?.feature]) {
      groupedPermissions[teamRole?.feature] = [];
    }
    groupedPermissions[teamRole?.feature].push(teamRole);
  });

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };


  const handleCheckboxChange = (feature, id) => (e) => {
    if (id) {
      // Permission title checkbox
      setFeaturePermissions((prevInput) => ({
        ...prevInput,
        [feature]: e.target.checked
          ? [...(prevInput[feature] || []), id]
          : (prevInput[feature] || []).filter((perm) => perm !== id),
      }));

      setInput((prevInput) => ({
        ...prevInput,
        permissions: e.target.checked
          ? [...new Set([...(prevInput.permissions || []), id])] // Used Set to prevent duplicates
          : prevInput.permissions.filter((perm) => perm !== id),
      }));
    } else {
      // Feature checkbox
      setFeaturePermissions((prevPermissions) => ({
        ...prevPermissions,
        [feature]: e.target.checked
          ? groupedPermissions[feature].map((teamRole) => teamRole?.id)
          : [],
      }));

      setInput((prevInput) => ({
        ...prevInput,
        permissions: e.target.checked
          ? [
              ...new Set([
                ...(prevInput.permissions || []),
                ...groupedPermissions[feature].map((teamRole) => teamRole?.id),
              ]),
            ]
          : prevInput.permissions.filter(
              (perm) =>
                !groupedPermissions[feature]
                  .map((teamRole) => teamRole?.id)
                  .includes(perm)
            ),
      }));
    }
  };

  const onSubmit = () => {
    if (isEdit) {
      let data = { permissions: input?.permissions, position: input?.position };
      dispatch(
        editTeamMember(data, id, () => {
          history.push(`/dashboard/teams`);
        })
      );
    } else {
      dispatch(
        addTeamMember(input, () => {
          history.push(`/dashboard/teams`);
        })
      );
    }
  };

  return (
    <div className="py-10">
      <div className="flex flex-col justify-center md:w-3/5 w-full mx-auto">
        <div className={"flex flex-col border rounded"}>
          <div className="bg-white py-4 overflow-hidden rounded-sm ">
            <div className="py-4 px-2 md:px-4 lg:px-14 border-solid border-b-2">
              <h4 className="glade-heading-three glade-black text-center text-sm">
                {isEdit ? "Edit Team Member Permissions" : "Add team member"}
              </h4>
            </div>
            <div className="mt-6 px-2 md:px-4 lg:px-14">
              <div className="space-y-6 ">
                <div className="space-y-2 ">
                  <div className={"flex space-x-3 items-center"}>
                    <div className="w-1/2">
                      <TextInput
                        label={"First Name"}
                        id={"first_name"}
                        name={"first_name"}
                        type={"text"}
                        value={input.first_name ?? ""}
                        onChange={onChange}
                        disabled={isEdit ? true : false}
                        placeholder={"Enter First Name"}
                        autoComplete={"first_name"}
                        className={"appearance-none"}
                      />
                    </div>
                    <div className="w-1/2 ">
                      <TextInput
                        label={"Last Name"}
                        id={"last_name"}
                        name={"last_name"}
                        type={"text"}
                        value={input?.last_name ?? ""}
                        onChange={onChange}
                        disabled={isEdit ? true : false}
                        placeholder={"Enter Last Name"}
                        autoComplete={"last_name"}
                        className={"appearance-none"}
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-2 ">
                  <div className={"flex space-x-3 items-center"}>
                    <div className="w-1/2">
                      <TextInput
                        label={"Email"}
                        id={"email"}
                        name={"email"}
                        type={"text"}
                        value={input?.email ?? ""}
                        onChange={onChange}
                        disabled={isEdit ? true : false}
                        placeholder={"Enter Email Address"}
                        autoComplete={"email"}
                        className={"appearance-none"}
                      />
                    </div>

                    <div className="w-1/2">
                      <TextInput
                        label={"Phone Number"}
                        id={"phone"}
                        name={"phone"}
                        type={"number"}
                        value={input?.phone ?? ""}
                        onChange={onChange}
                        disabled={isEdit ? true : false}
                        placeholder={"Enter phone number"}
                        autoComplete={"phone"}
                        className={"appearance-none"}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <TextInput
                    label={"Position"}
                    id={"position"}
                    name={"position"}
                    type={"text"}
                    value={input?.position ?? ""}
                    onChange={onChange}
                    placeholder={"Enter Position"}
                    autoComplete={"position"}
                    className={"appearance-none"}
                  />
                </div>
              </div>

              <div className=" space-y-2">
                <h4 className="my-4 font-medium">Permissions</h4>
              </div>

              <div className="grid grid-cols-2 gap-6">
                {Object.keys(groupedPermissions).map((feature) => (
                  <div key={feature} className="  ">
                    <div className=" font-medium mb-2 ">
                      <CheckboxInput
                        label={feature ?? ""}
                        id={feature}
                        name={feature}
                        type={"checkbox"}
                        checked={
                          featurePermissions[feature]?.length ===
                          groupedPermissions[feature]?.length
                        }
                        onChange={handleCheckboxChange(feature)}
                        autoComplete={feature}
                        disabled={feature === "dashboard"}
                        className={"appearance-none  font-bold"}
                      />
                    </div>
                    {groupedPermissions[feature].map((teamRole) => (
                      <div key={teamRole?.id} className="mb-1">
                        <CheckboxInput
                          label={toTitleCase(teamRole?.permission_title ?? "")}
                          id={teamRole.id}
                          name={teamRole.permission_title}
                          type={"checkbox"}
                          checked={featurePermissions[feature]?.includes(
                            teamRole.id
                          )}
                          onChange={handleCheckboxChange(feature, teamRole?.id)}
                          placeholder={""}
                          autoComplete={teamRole?.permission_title}
                          disabled={teamRole?.id === 1}
                          className={"appearance-none ml-4 "}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="mx-6">
              <Button.Blue
                onClick={onSubmit}
                title={isEdit ? "Save Changes" : "Add team"}
                disabled={(isEdit && business.isLoadingEditTeam) ||
                  (!isEdit && business.isLoadingAddTeam) ||
                  !input.first_name ||
                  !input.last_name ||
                  !input.email ||
                  !input.position ||
                  input.permissions.length === 0
                }
                className={"my-6 py-2"}
                loading={isEdit ? business.isLoadingEditTeam : business.isLoadingAddTeam}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddandEditTeam;
