import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { CardTableHeaderPlain } from "../../components/cards/CardTableHeader";
import Button from "../../components/buttons/Button";
import useSettings from "../../hooks/useSettings";
import useUser from "../../hooks/useUser";
import {
  submitPersonalCompliance,
  submitBusinessCompliance,
  getComplianceIdentityTypes,
  getComplianceCountries,
  getComplianceStates,
  getComplianceLga,
  // getPersonalRecentCompliancies,
  // getBusinessRecentCompliancies,
} from "../../redux/settings/settingsActions";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import FileInput from "../../components/inputs/FileInput";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import { isArray, toTitleCase } from "../../helpers/helper";
import {
  userComplianceValidation,
  businessComplianceValidation,
} from "../../validation/settings/settingsValidation";
import { BsCheckCircleFill } from "react-icons/bs";
import { CloseIcon } from "../../assets/icons/Icons";

export default function OldSettingsCompliance() {
  const NoticeModalRef = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSettings();
  const user = useUser();
  const [input, setInput] = React.useState({
    business_name: "",
    business_id_type: "",
    business_id_number: "",
    business_id_card: "",
    business_address: "",
    business_proof_of_address: "",
    business_tin_number: "",
    business_tin_certificate: "",
    business_rc_or_bn_number: "",
    business_cac_certificate: "",
    business_director_form: "",
    business_memorandum: "",
    personal_id_type: "",
    personal_id_number: "",
    personal_id_card: "",
    personal_proof_of_address: "",
    personal_address: "",
    compliance_country: 1,
    compliance_state: "",
    compliance_lga: "",
  });
  const [successful, setSuccessFul] = useState(false);
  const [timer, setTimer] = useState(20);
  React.useEffect(() => {
    dispatch(getComplianceIdentityTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    !input.compliance_country && dispatch(getComplianceCountries());
    input.compliance_country &&
      dispatch(getComplianceStates({ country_id: input.compliance_country }));
    input.compliance_state &&
      dispatch(getComplianceLga({ state_id: input.compliance_state }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.compliance_country, input.compliance_state]);

  const updatePersonalDetails = () => {
    if (userComplianceValidation(input)) {
      dispatch(
        submitPersonalCompliance(
          {
            id_card_type: settings.complianceIdentityTypes.find(
              (item) =>
                item.type_id?.toString() === input.personal_id_type?.toString()
            )?.type_name,
            id_card_number: input.personal_id_number,
            id_card: input.personal_id_card[0],
            proof_of_address: input.personal_proof_of_address[0],
            address: input.personal_address,
            state: settings.complianceStates.find(
              (item) =>
                item.state_id?.toString() === input.compliance_state?.toString()
            )?.state_name,
            lga: settings.complianceLga.find(
              (item) =>
                item.city_id?.toString() === input.compliance_lga?.toString()
            )?.city_name,
          },
          () => {
            dispatch(getPersonalAndBusinessAccounts());
            setSuccessFul(true);
          }
        )
      );
    }
  };

  const updateBusinessDetails = () => {
    if (businessComplianceValidation(input)) {
      dispatch(
        submitBusinessCompliance(
          {
            registered_name: input.business_name,
            id_card_type: settings.complianceIdentityTypes.find(
              (item) =>
                item.type_id?.toString() === input.business_id_type?.toString()
            )?.type_name,
            id_card_number: input.business_id_number,
            id_card: input.business_id_card[0],
            proof_of_address: input.business_proof_of_address[0],
            address: input.business_address,
            state: settings.complianceStates.find(
              (item) =>
                item.state_id?.toString() === input.compliance_state?.toString()
            )?.state_name,
            lga: settings.complianceLga.find(
              (item) =>
                item.city_id?.toString() === input.compliance_lga?.toString()
            )?.city_name,
            tin_number: input.business_tin_number,
            tin_certificate: input.business_tin_certificate[0],
            rc_number: input.business_rc_or_bn_number,
            cac_certificate: input.business_cac_certificate[0],
            director_page: input.business_director_form[0],
            memorandum: input.business_memorandum[0],
          },
          () => {
            dispatch(getPersonalAndBusinessAccounts());
            setSuccessFul(true);
          }
        )
      );
    }
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (successful) {
      timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
      if (timer <= 0) {
        setSuccessFul(false);
        history.push("/dashboard/home");
      }
    }
  }, [successful, timer]);
  return (
    <React.Fragment>
      {user.isBusiness && (
        <div className="px-3 py-0">
          <div className="mb-9 md:hidden">
            <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
          </div>

          <CardTableHeaderPlain
            title={"Business Compliance"}
            rightButton={
              <Button.Blue
                onClick={updateBusinessDetails}
                title={"Submit"}
                disabled={settings.isLoadingCompliance}
                loading={settings.isLoadingCompliance}
              />
            }
          />

          <div className="relative flex flex-col min-w-0 w-full bg-white border-t-2 border-gray-100">
            <div className="p-9">
              <div className="w-full md:w-5/12 space-y-6">
                <TextInput
                  label={"Registered Business Name"}
                  id={"business_name"}
                  name={"business_name"}
                  value={input.business_name ?? ""}
                  onChange={onChange}
                  placeholder={"Enter Business Name"}
                  autoComplete={"business_name"}
                  className={"appearance-none"}
                />

                <SelectInput
                  label={"ID Type"}
                  id={"business_id_type"}
                  name={"business_id_type"}
                  value={input.business_id_type ?? ""}
                  onChange={onChange}
                  autoComplete={"business_id_type"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select ID Type
                  </option>
                  {isArray(settings.complianceIdentityTypes) &&
                    settings.complianceIdentityTypes?.map((type, index) => (
                      <option key={index} value={type.type_id}>
                        {toTitleCase(type.type_name ?? "")}
                      </option>
                    ))}
                </SelectInput>

                <TextInput
                  label={"ID Number"}
                  id={"business_id_number"}
                  name={"business_id_number"}
                  value={input.business_id_number ?? ""}
                  onChange={onChange}
                  placeholder={"Enter ID Number"}
                  autoComplete={"business_id_number"}
                  className={"appearance-none"}
                />

                <FileInput
                  label={"Upload ID"}
                  id={"business_id_card"}
                  name={"business_id_card"}
                  multiple={false}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, business_id_card: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>

                <TextInput
                  label={"Residential Address"}
                  id={"business_address"}
                  name={"business_address"}
                  value={input.business_address ?? ""}
                  onChange={onChange}
                  placeholder={"Enter Residential Address "}
                  autoComplete={"business_address"}
                  className={"appearance-none"}
                />

                {/* <SelectInput
                  label={"Country"}
                  id={"compliance_country"}
                  name={"compliance_country"}
                  value={input.compliance_country ?? ""}
                  onChange={onChange}
                  autoComplete={"compliance_country"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>Select A Country</option>
                  {isArray(settings.complianceCountries) && settings.complianceCountries?.map((country, index) => (
                    <option key={index} value={country.country_id}>{country.country_name}</option>
                  ))}
                </SelectInput> */}

                <SelectInput
                  label={"State"}
                  id={"compliance_state"}
                  name={"compliance_state"}
                  value={input.compliance_state ?? ""}
                  onChange={onChange}
                  autoComplete={"compliance_state"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select A State
                  </option>
                  {isArray(settings.complianceStates) &&
                    settings.complianceStates?.map((state, index) => (
                      <option key={index} value={state.state_id}>
                        {state.state_name}
                      </option>
                    ))}
                </SelectInput>

                <SelectInput
                  label={"Local Government Area"}
                  id={"compliance_lga"}
                  name={"compliance_lga"}
                  value={input.compliance_lga ?? ""}
                  onChange={onChange}
                  autoComplete={"compliance_lga"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select A Local Government Area
                  </option>
                  {isArray(settings.complianceLga) &&
                    settings.complianceLga?.map((lga, index) => (
                      <option key={index} value={lga.city_id}>
                        {lga.city_name}
                      </option>
                    ))}
                </SelectInput>

                <FileInput
                  label={"Upload Proof of Address"}
                  id={"business_proof_of_address"}
                  name={"business_proof_of_address"}
                  multiple={true}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, business_proof_of_address: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Proof of address can be recognized documents that verify your
                  a current address. Such as Utility bills, Bank statements or
                  Cable bills not more than 3 months old.
                </span>
                <span className="glade-small-text-one glade-grey-cool block">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>

                <TextInput
                  label={"TIN Number"}
                  id={"business_tin_number"}
                  name={"business_tin_number"}
                  value={input.business_tin_number ?? ""}
                  onChange={onChange}
                  placeholder={"Enter Tin Number"}
                  autoComplete={"business_tin_number"}
                  className={"appearance-none"}
                />

                <FileInput
                  label={"Upload TIN Certificate"}
                  id={"business_tin_certificate"}
                  name={"business_tin_certificate"}
                  multiple={false}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, business_tin_certificate: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>

                <TextInput
                  label={"RC or BN Number"}
                  id={"business_rc_or_bn_number"}
                  name={"business_rc_or_bn_number"}
                  value={input.business_rc_or_bn_number ?? ""}
                  onChange={onChange}
                  placeholder={"Enter RC or BN Number"}
                  autoComplete={"business_rc_or_bn_number"}
                  className={"appearance-none"}
                />

                <FileInput
                  label={"Upload CAC Certificate"}
                  id={"business_cac_certificate"}
                  name={"business_cac_certificate"}
                  multiple={false}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, business_cac_certificate: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>

                <FileInput
                  label={"Upload Directors Form"}
                  id={"business_director_form"}
                  name={"business_director_form"}
                  multiple={false}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, business_director_form: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>

                <FileInput
                  label={"Upload Memorandum & Articles of Association"}
                  id={"business_memorandum"}
                  name={"business_memorandum"}
                  multiple={false}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, business_memorandum: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {!user.isBusiness && (
        <div className="p-3 py-0">
          <CardTableHeaderPlain
            title={"Personal Compliance"}
            rightButton={
              <Button.Blue
                onClick={updatePersonalDetails}
                title={"Submit"}
                disabled={settings.isLoadingCompliance}
                loading={settings.isLoadingCompliance}
              />
            }
          />

          <div className="relative flex flex-col min-w-0 w-full bg-white border-t-2 border-gray-100">
            <div className="p-9">
              <div className="w-full md:w-5/12 space-y-6">
                <SelectInput
                  label={"ID Type"}
                  id={"personal_id_type"}
                  name={"personal_id_type"}
                  value={input.personal_id_type ?? ""}
                  onChange={onChange}
                  autoComplete={"personal_id_type"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select ID Type
                  </option>
                  {isArray(settings.complianceIdentityTypes) &&
                    settings.complianceIdentityTypes?.map((type, index) => (
                      <option key={index} value={type.type_id}>
                        {toTitleCase(type.type_name ?? "")}
                      </option>
                    ))}
                </SelectInput>

                <TextInput
                  label={"ID Number"}
                  id={"personal_id_number"}
                  name={"personal_id_number"}
                  value={input.personal_id_number ?? ""}
                  onChange={onChange}
                  placeholder={"Enter ID Number"}
                  autoComplete={"personal_id_number"}
                  className={"appearance-none"}
                />

                <FileInput
                  label={"Upload ID"}
                  id={"personal_id_card"}
                  name={"personal_id_card"}
                  multiple={false}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, personal_id_card: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>

                <TextInput
                  label={"Residential Address"}
                  id={"personal_address"}
                  name={"personal_address"}
                  value={input.personal_address ?? ""}
                  onChange={onChange}
                  placeholder={"Enter Residential Address "}
                  autoComplete={"personal_address"}
                  className={"appearance-none"}
                />

                {/* <SelectInput
                  label={"Country"}
                  id={"compliance_country"}
                  name={"compliance_country"}
                  value={input.compliance_country ?? ""}
                  onChange={onChange}
                  autoComplete={"compliance_country"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>Select A Country</option>
                  {isArray(settings.complianceCountries) && settings.complianceCountries?.map((country, index) => (
                    <option key={index} value={country.country_id}>{country.country_name}</option>
                  ))}
                </SelectInput> */}

                <SelectInput
                  label={"State"}
                  id={"compliance_state"}
                  name={"compliance_state"}
                  value={input.compliance_state ?? ""}
                  onChange={onChange}
                  autoComplete={"compliance_state"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select A State
                  </option>
                  {isArray(settings.complianceStates) &&
                    settings.complianceStates?.map((state, index) => (
                      <option key={index} value={state.state_id}>
                        {state.state_name}
                      </option>
                    ))}
                </SelectInput>

                <SelectInput
                  label={"Local Government Area"}
                  id={"compliance_lga"}
                  name={"compliance_lga"}
                  value={input.compliance_lga ?? ""}
                  onChange={onChange}
                  autoComplete={"compliance_lga"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select A Local Government Area
                  </option>
                  {isArray(settings.complianceLga) &&
                    settings.complianceLga?.map((lga, index) => (
                      <option key={index} value={lga.city_id}>
                        {lga.city_name}
                      </option>
                    ))}
                </SelectInput>

                <FileInput
                  label={"Upload Proof of Address"}
                  id={"personal_proof_of_address"}
                  name={"personal_proof_of_address"}
                  multiple={true}
                  accept=".png, .jpeg, .pdf, .jpg"
                  maximumSize={3}
                  returnInput={(value) =>
                    setInput({ ...input, personal_proof_of_address: value })
                  }
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Proof of address can be recognized documents that verify your
                  a current address. Such as Utility bills, Bank statements or
                  Cable bills not more than 3 months old.
                </span>
                <span className="glade-small-text-one glade-grey-cool block">
                  Supported file types: PDF, PNG & JPEG. Max file size allowed
                  is 3MB.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {successful && (
        <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-32 ">
          <div className="flex justify-center items-center">
            <div className="self-center bg-white rounded md:w-2/5  h-full">
              <div className="text-right mb-12 border-b p-5">
                <button onClick={() => history.push("/dashboard/home")}>
                  <CloseIcon />
                </button>
              </div>
              <div className="mx-auto w-20">
                <BsCheckCircleFill color={"#5CDB5C"} size={"6em"} />
              </div>
              <div className="text-center mt-8 p-5">
                <h4 className="font-bold">Compliance submitted successfully</h4>
                <p className="text-glade-gray-400">
                  Your Compliance has been submitted and is under review. Your
                  account will be active in within an hour.
                </p>
                <p className="mt-8 text-glade-gray-400">
                  This modal will automatically close in
                  <span className="font-bold">{timer}</span> seconds
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
