import { ACCOUNT_DETAILS_FAILURE, ACCOUNT_DETAILS_LOADING, ACCOUNT_DETAILS_SUCCESS, ALL_BALANCES_FAILURE, ALL_BALANCES_LOADING, ALL_BALANCES_SUCCESS, ALL_IF_ACCOUNT_FAILURE, ALL_IF_ACCOUNT_LOADING, ALL_IF_ACCOUNT_SUCCESS } from "./types";

const initialState = {
  isLoadingBalances: false,
  balancesData: {},
  accountDetails: {},
  isLoadingAccountDetails: false,
  isLoadingAllIFAccount: false,
  allIFAccountData: [],
}

export default function balancesReducer(state = initialState, action) {
  switch (action.type) {

    case ALL_BALANCES_LOADING:
      return {
        ...state,
        isLoadingBalances: true,
        balancesData: {}
      };

    case ALL_BALANCES_SUCCESS:
      return {
        ...state,
        isLoadingBalances: false,
        balancesData: action.payLoad.data.data
      };

    case ALL_BALANCES_FAILURE:
      return {
        ...state,
        isLoadingBalances: false,
        balancesData: {}
      };

    case ACCOUNT_DETAILS_LOADING:
      return {
        ...state,
        isLoadingAccountDetails: true,
        accountDetails: {}
      };

    case ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingAccountDetails: false,
        accountDetails: action.payLoad.data.data.data
      };

    case ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingAccountDetails: false,
        accountDetails: {}
      };
    
      case ALL_IF_ACCOUNT_LOADING:
      return {
        ...state,
        isLoadingAllIFAccount: true,
        allIFAccountData: []
      };

    case ALL_IF_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingAllIFAccount: false,
        allIFAccountData: action.payLoad.data.data
      };

    case ALL_IF_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoadingAllIFAccount: false,
        allIFAccountData: []
      };

    default:
      return state;
  }
}