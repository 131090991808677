import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import CashFlowFilter from './CashFlowFilter';
import { getSuppliedFieldValue, currencySymbolEnum } from '../../helpers/helper';
import useHome from '../../hooks/useHome';
import useUser from "../../hooks/useUser";
import { getBusinessInflowAndOutflow, getBusinessTransactions, getCards } from '../../redux/home/homeActions';
import QuickActions from './QuickActions';
import CashFlowBarChart from './CashFlowBarChart';
import NewAccountBalance from './NewAccountBalance';
import HomeTransaction from './HomeTransaction';
import useTeam from '../../hooks/useTeam';

export default function HomeBusiness() {

  const [selectedFilterOptions, setSelectedFilterOptions] = useState('Last 7 days');
  const dispatch = useDispatch();
  const user = useUser();
  const home = useHome();
  const {business}= useTeam();

  useEffect(() => {
    dispatch(getBusinessInflowAndOutflow({
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substring(0, 10),
      end_date: new Date().toISOString().substring(0, 10),
    }));
    dispatch(getBusinessTransactions());
    user.hasCompliance && dispatch(getCards({}));
    // eslint-disable-next-line 
  }, [user.shouldReload]);


  // Filter Function And Control
  const onFilter = (data) => {
    setSelectedFilterOptions(data?.date?.split('.')[1]);
    dispatch(getBusinessInflowAndOutflow({
      start_date: data.date_from ?? data.date.split('.')[0],
      end_date: data.date_to ?? new Date().toISOString().substring(0, 10),
    }));
  };


  return (
    <div className="">
      <div className="my-4">
        <h2 className='text-2xl font-medium'>Welcome back, <span className="capitalize"> {home?.oneBusinessAccount?.business?.business_name ?? business?.business_name}</span></h2>
      </div>
      {/* user.compliance === "approved"
         ? */}
      <>
        <div className="flex flex-col space-y-6 rounded-md bg-white">

          {/* Top Section */}
          <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0  lg:space-x-6">
            {/* Top Section Left */}
            <div className="mt-8 lg:mt-0 flex flex-col  sm:items-stretch w-full lg:w-1/2 order-2 lg:order-1 border rounded-lg border-glade-gray-80 p-5 " style={{minHeight: '450px'}}>
            <div className="lg:ml-10 sm:ml-0 flex lg:justify-end lg:-mb-8 lg:text-right">
                  <CashFlowFilter types={['date']} onFilter={(value) => onFilter(value)} onReset={() => { }} />
                </div>
                <div className="md:flex md:flex-row sm:grid justify-center md:justify-between lg:items-center items-start gap-x-6 mb-5">
                <div className='flex gap-4 sm:gap-0 flex-row text-center sm:text-left items-center'>
                <div className='rounded-full p-4' style={{ background: '#2C6DEA' }}><BsArrowDownShort size={34} color={'#EEF4FF'} /></div>
                  <div className='ml-0 gap-2 flex flex-col sm:gap-0 sm:ml-4'>
                    <p className='glade-grey-cool'>Money in - {selectedFilterOptions}</p>
                    <p className='glade-heading-two glade-home-dark'><span className="font-bold">
                      {currencySymbolEnum[getSuppliedFieldValue('currency', user) || "NGN"]}
                      {" "}
                      {Number(home?.totalBusinessInflow ?? '0.00')?.toLocaleString()}.00</span></p>
                  </div>
                </div>
                <div className='flex items-center gap-4 sm:gap-0 text-center sm:text-left flex-row sm:items-center mt-12 sm:mt-0 mb-4 sm:mb-0'>
                <div className='rounded-full p-4' style={{ background: '#C0D6FF' }}><BsArrowUpShort size={34} color={'#2C6DEA'} /></div>
                  <div className='ml-0 gap-2 flex flex-col sm:gap-0 sm:ml-4'>
                    <p className='glade-grey-cool'>Money out - {selectedFilterOptions}</p>
                    <p className='glade-heading-two glade-home-dark'><span className="font-bold">
                      {currencySymbolEnum[getSuppliedFieldValue('currency', user) || 'NGN']}
                      {Number(home?.totalBusinessOutflow ?? '0.00')?.toLocaleString()}.00</span></p>
                  </div>
                </div>
                
              </div>
              <div className="flex flex-col md:space-y-3 w-full">
                <CashFlowBarChart chartData={home.businessInflowOutflow?.chart} />
              </div>
            </div>

            {/* Top Section Right */}
            <div className="flex flex-col w-full lg:w-1/2 space-y-9 order-1 lg:order-2">
              {/* Account Box */}
              <div className='mb-6 md:mb-0'>
                <NewAccountBalance />
              </div>
            </div>
          </div>

          {/* Bottom Section */}
          <div className='flex lg:space-x-6 flex-col lg:flex-row space-y-6 items-stretch lg:space-y-0'>
            <div className="mt-8 lg:mt-0 w-full lg:w-3/5 order-1 rounded-lg border">
              <HomeTransaction transactions={home.businessTransactions} />
            </div>
            <div className="mt-8 w-full lg:w-2/5 order-2 rounded-lg border">
              <QuickActions />
            </div>
          </div>
        </div>
      </>

    </div>
  );
}
