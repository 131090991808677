import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PlainModal from "../../components/modals/PlainModal";
import { BsPhone, BsLaptop } from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
  requestRevokeSession,
} from "../../redux/home/homeActions";


export default React.forwardRef(function LoginAccessModal(props, ref) {
  const history = useHistory();
  const dispatch = useDispatch(); 

  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: () => plainModalRef.current.open(),
    close: () => plainModalRef.current.close(),
    toggle: () => plainModalRef.current.toggle(),
  }));

  const callBack = () => {
    if (props.logoutSuccessModalRef.current) {
      plainModalRef.current.close();
      props.logoutSuccessModalRef.current.open();
      setTimeout(() => {
        if (props.logoutSuccessModalRef.current) {
          props.logoutSuccessModalRef.current.close();
          history.push({ pathname: '/auth/otplogin', state: props.input });
        }
      }, 1000);
    }
  };  


  const onSubmit = () => {
    dispatch(requestRevokeSession(props.input, () => callBack()));
  };

  return (
      <PlainModal
        ref={plainModalRef}
        primaryButton={<React.Fragment></React.Fragment>}
        modalTitle={"Login Acesss Denied"}
        modelContentSection={
          <div className="flex flex-col mb-7 mx-0">
            <div className="flex flex-row p-9 cursor-pointer text-gray-500 text-sm">
              <h1>
                You are currently logged in another device. Log out from your
                other device to continue
              </h1>
            </div>
            <div className="flex justify-around py-3 my-0 mx-auto w-10/12" style={{ background: '#FAFAFA' }}>
              <div className="flex gap-1">
                <div className="flex items-center">
                {props.errorMessage?.platform?.includes('Ios') ? (<BsPhone size={30} color={"#2C6DEA"} />) 
               : props.errorMessage?.platform?.includes('Android') ? (<BsPhone size={30} color={"#2C6DEA"} />
               ) : (
                 <BsLaptop size={30} color={"#2C6DEA"} />
                  )}
                </div>
                <div className="flex flex-col">
                  <p className="text-xs font-medium"> {props.errorMessage?.platform}</p>
                  <span className="text-xs text-gray-400 ">{props.errorMessage?.location}</span>
                </div>
              </div>
              <div>
                <button className='glade-blue glade-coming-soon-bg py-2 px-5 rounded font-medium text-xs'
                onClick={onSubmit}>
                    Log Out
                    </button>  
                 
              </div>
            </div>
          </div>
        }
      />

  );
});
