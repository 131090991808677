import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";
import {
  CardPlainWithoutButton,
  CardPlainWithoutButtonSection,
} from "../../components/cards/CardPlainWithoutButton";
import TextIndicator from "../../components/indicators/TextIndicator";
import { statusEnum, simpleDate, truncateText } from "../../helpers/helper";
import {
  downloadAirtimeReceiptAsPDF,
  downloadCreditReceiptAsPDF,
  downloadFundTransferReceiptAsPDF,
} from "./DownloadFundTransferReceiptAsPDF";
import PillIndicator from "../../components/indicators/PillIndicator";
import Button from "../../components/buttons/Button";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosArrowBack } from "react-icons/io";
import { TransferFormContext } from "../../context/TransferFormContext";
import FundTransferInternational from "../fund-transfer-new/FundTransferInternational";
import { AiOutlineDownload } from "react-icons/ai";
import { FiRepeat } from "react-icons/fi";
import { BsArrowRepeat, BsFlag } from "react-icons/bs";
import useTeam from "../../hooks/useTeam";

export default function FundTransferDetail() {
  const location = useLocation();
  const history = useHistory();
  const { permissions } = useTeam();
  const { openForm, showForm, setRepeatTransaction, setRepeatTransferDetails } =
    useContext(TransferFormContext);

  const handleRepeatTransaction = () => {
    setRepeatTransaction(true);
    setRepeatTransferDetails(location?.state);
    if (
      location?.state?.destination === "local" &&
      location?.state?.currency === "NGN"
    ) {
      openForm("local");
    } else if (
      location?.state?.destination === "local" &&
      location?.state?.transfer_type === "internal" &&
      location?.state?.currency === "NGN"
    ) {
      openForm("glade");
    } else if (
      location?.state?.destination === "local" &&
      location?.state?.transfer_type === "external" &&
      location?.state?.currency === "NGN"
    ) {
      openForm("glade");
    }
  };

  let transactionType = location?.pathname?.split("/")[2];

  const buildAirtimeCreditType = () => {
    let network =
      location?.state?.receipient_or_creditor_institution?.split(" ")[0];
    let phone =
      location?.state?.receipient_or_creditor?.split(" ")[
        location?.state?.receipient_or_creditor?.split(" ").length - 1
      ];

    return `${network} | ${transactionType} | ${phone}`;
  };
  const receiptDecider = () => {
    if (transactionType === "fund-transfer") {
      return downloadFundTransferReceiptAsPDF({ ...location?.state });
    } else if (
      transactionType === "credit" ||
      location?.state?.txn_type === "credit"
    ) {
      return downloadCreditReceiptAsPDF({
        ...location?.state,
        currency: "NGN",
        status: "successful",
        type: buildAirtimeCreditType(),
      });
    } else if (
      transactionType === "airtime" ||
      transactionType === "bill" ||
      location?.state?.txn_type === "airtime" ||
      location?.state?.txn_type === "bill"
    ) {
      return downloadAirtimeReceiptAsPDF({
        ...location?.state,
        currency: "NGN",
        type: buildAirtimeCreditType(),
      });
    }
  };

  const [shouldDisplayRepeatButton, setShouldDisplayRepeatButton] =
    useState(false);

  useEffect(() => {
    if (
      !(
        location?.state?.txn_type === "credit" ||
        location?.state?.txn_type === "debit" ||
        location?.state?.destination === "foreign"
      )
    ) {
      setShouldDisplayRepeatButton(true);
    }
    if (location?.showRepeatButton === false) {
      setShouldDisplayRepeatButton(false);
    }
    if (
      !permissions?.includes("transactions page") ||
      permissions?.includes("fund transfer")
    ) {
      setShouldDisplayRepeatButton(false);
    }
  }, []);

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div
          className="inline-flex items-center justify-start glade-subtitle-two glade-black cursor-pointer ml-6 mt-6"
          onClick={() => history.goBack()}
        >
          {<IoIosArrowBack />} <span className="ml-2">{"Back"}</span>
        </div>

        <div className="hidden md:flex flex-col">
          <CardPlainWithButton>
            <CardPlainWithButtonSection
              title={"Transaction Details"}
              loading={false}
              buttonDisabled={false}
              buttonTitle={"Download Receipt"}
              buttonSection={
                <div className="flex p-4 gap-7 whitespace-nowrap pt-6">
                  <div
                    className="cursor-pointer flex justify-center text-center flex-col items-center text-glade-blue gap-1 "
                    onClick={() => receiptDecider()}
                  >
                    <span className="glade-coming-soon-bg p-1 rounded-full">
                      <AiOutlineDownload size={16} />{" "}
                    </span>
                    <p className="text-xs"> Download Receipt</p>
                  </div>

                  {shouldDisplayRepeatButton && (
                    <div
                      className="cursor-pointer flex justify-center text-center flex-col items-center text-glade-blue gap-1"
                      onClick={() => {
                        if (transactionType === "airtime") {
                          history.push({
                            pathname: !permissions.includes("airtime")
                              ? "/dashboard/airtime/repeat-airtime"
                              : "/teams/airtime/repeat-airtime",
                            state: location?.state,
                          });
                        } else if (transactionType === "bill") {
                          history.push({
                            pathname: !permissions?.includes("bill payment")
                              ? "/dashboard/pay-bills/repeat-bill"
                              : "/teams/pay-bills/repeat-bill",
                            state: location?.state,
                          });
                        } else if (transactionType === "fund-transfer") {
                          handleRepeatTransaction();
                        }
                      }}
                    >
                      <span className="glade-coming-soon-bg p-1 rounded-full">
                        <FiRepeat size={16} />{" "}
                      </span>
                      <p className="text-xs">Repeat Transaction</p>
                    </div>
                  )}

                  {/* <div
                    className="cursor-pointer flex justify-center text-center flex-col items-center text-glade-blue gap-1"
                    onClick={() => history.push({
                      pathname: !permissions.includes("airtime")
                        ? "/dashboard/recurring-payment/create/airtime"
                        : "/teams/recurring-payment/create/airtime",
                      state: {...location?.state, existing: true},
                    })}
                  >
                    <span className="glade-coming-soon-bg p-1 rounded-full">
                      <BsArrowRepeat size={16} />
                    </span>
                    <p className="text-xs">Set as Recurring</p>
                  </div> */}

                  <div
                    className="cursor-pointer text-red-400 flex justify-center text-center flex-col items-center gap-1"
                    onClick={() =>
                      history.push({
                        pathname: !permissions?.includes("transactions page")
                          ? "/dashboard/report-transfer"
                          : "/teams/report-transfer",
                        state: location?.state,
                      })
                    }
                  >
                    <span className="bg-red-100 p-1 rounded-full">
                      <BsFlag size={14} />
                    </span>
                    <p className="text-xs">Report Transaction</p>
                  </div>
                </div>
              }
            >
              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Transaction Date
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {simpleDate(location?.state?.created_at)}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Transaction Reference
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {truncateText(location?.state?.txn_ref)}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Amount
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {location?.state?.currency ?? "NGN"}{" "}
                  {Number(location?.state?.value ?? 0)?.toLocaleString()}
                </h3>
              </div>
              {/* Airtime Data */}
              {(transactionType === "airtime" ||
                transactionType === "bill") && (
                <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                  <h3 className="glade-normal-text-two glade-grey-cool">
                    Type
                  </h3>
                  <h3 className="glade-normal-text-three glade-black capitalize">
                    {buildAirtimeCreditType()}
                  </h3>
                </div>
              )}
              {/* Credit Data */}
              {transactionType === "credit" && (
                <Fragment>
                  <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                    <h3 className="glade-normal-text-two glade-grey-cool">
                      Type
                    </h3>
                    <h3 className="glade-normal-text-three glade-black capitalize">
                      {buildAirtimeCreditType()}
                    </h3>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                    <h3 className="glade-normal-text-two glade-grey-cool">
                      Bank Institution
                    </h3>
                    <h3 className="glade-normal-text-three glade-black capitalize">
                      {location?.state?.receipient_or_creditor_institution}
                    </h3>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                    <h3 className="glade-normal-text-two glade-grey-cool">
                      Balance
                    </h3>
                    <h3 className="glade-normal-text-three glade-black capitalize">
                      {location?.state?.currency ?? "NGN"}{" "}
                      {Number(location?.state?.balance_after).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </h3>
                  </div>
                </Fragment>
              )}
              {(location?.state?.beneficiary_name ||
                location?.state?.transfer_reciepient) && (
                <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                  <h3 className="glade-normal-text-two glade-grey-cool">
                    Recipient
                  </h3>
                  <h3 className="glade-normal-text-three glade-black">
                    {location?.state?.beneficiary_name ||
                      location?.state?.transfer_reciepient}
                  </h3>
                </div>
              )}
              {((location?.state?.beneficiary_account &&
                location?.state?.bank_name) ||
                location?.state?.transfer_reciepient_bank) && (
                <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                  <h3 className="glade-normal-text-two glade-grey-cool">
                    Recipient’s Bank
                  </h3>
                  <h3 className="glade-normal-text-three glade-black">
                    {location?.state?.beneficiary_account ||
                      location?.state?.transfer_reciepient_bank}{" "}
                    {location?.state?.bank_name}
                  </h3>
                </div>
              )}
              {location?.state?.narration && (
                <div className="flex flex-col md:flex-row justify-between py-4 border-b border-solid border-gray-200 items-center">
                  <h3 className="glade-normal-text-two glade-grey-cool">
                    Narration
                  </h3>
                  <h3 className="glade-normal-text-three glade-black">
                    {truncateText(location?.state?.narration, 40)}
                  </h3>
                </div>
              )}
              {location?.state?.status && (
                <div className="flex flex-col md:flex-row justify-between py-4 items-center">
                  <h3 className="glade-normal-text-two glade-grey-cool">
                    Status
                  </h3>
                  <TextIndicator
                    type={statusEnum[location?.state?.status]}
                    title={location?.state?.status}
                  />
                </div>
              )}
            </CardPlainWithButtonSection>
          </CardPlainWithButton>
        </div>

        <div className="md:hidden flex flex-col">
          <div className="flex flex-row justify-between items-center my-3">
            <PillIndicator
              type={statusEnum[location?.state?.status]}
              title={location?.state?.status}
            />
            <div>
              {transactionType !== "others" && (
                <Button.Blue
                  onClick={() => receiptDecider()}
                  className="glade-button-blue"
                  title={"Download Receipt"}
                  disabled={false}
                />
              )}
            </div>
          </div>
          <CardPlainWithoutButton>
            <CardPlainWithoutButtonSection
              titleSection={
                <div className="flex pl-3 py-2 border-b-2 border-solid border-gray-200">
                  <h4 className="glade-subtitle-one glade-black">
                    {"Transfer Details"}
                  </h4>
                </div>
              }
            >
              <div className="flex flex-col md:flex-row justify-between py-3 space-y-3 border-b border-solid border-gray-200">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Transaction Date
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {simpleDate(location?.state?.created_at)}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-3 space-y-3 border-b border-solid border-gray-200">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Transaction Reference
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  <CopyTextButton
                    truncate={false}
                    text={location?.state?.txn_ref ?? ""}
                  />
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-3 space-y-3 border-b border-solid border-gray-200">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Amount
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {location?.state?.currency}{" "}
                  {Number(location?.state?.value ?? 0)?.toLocaleString()}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-3 space-y-3 border-b border-solid border-gray-200">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Recipient
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {location?.state?.beneficiary_name}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row justify-between py-3 space-y-3 border-b border-solid border-gray-200">
                <h3 className="glade-normal-text-two glade-grey-cool">
                  Recipient’s Bank
                </h3>
                <h3 className="glade-normal-text-three glade-black">
                  {location?.state?.beneficiary_account}{" "}
                  {location?.state?.bank_name}
                </h3>
              </div>
            </CardPlainWithoutButtonSection>
          </CardPlainWithoutButton>
        </div>
      </div>
      {showForm && <FundTransferInternational />}
    </div>
  );
}
