import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FaCaretDown } from "react-icons/fa";

export function ButtonWithDropDownItem({ activeChild, inactiveChild }) {
  return (
    <Menu.Item>
      {({ active }) =>
        active ?
          activeChild ?? inactiveChild :
          inactiveChild ?? activeChild
      }
    </Menu.Item>
  );
}

export function ButtonWithDropDown({ children, title, className, buttonClassName, style, disabled }) {
  return (
    <Menu as="div" className={className + " relative inline-block text-left"}>
      <div>
        <Menu.Button
          disabled={disabled}
          style={style}
          className={buttonClassName + " glade-normal-text-three glade-button-blue py-4 px-4 inline-flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap"}>
          {title ?? 'Title'}
          <FaCaretDown
            className="w-5 h-5 ml-2 -mr-1"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col px-1 py-1">
            {children}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}