import React from "react";
import CurrencySelectionList from "../../components/modals/add currency/CurrencySelectionList";


export default function PaymentInflow() {
  const onSelectionChange = () => null;

  return (
    
        <div>
            <CurrencySelectionList title={'All Currencies'} defaultValue={''}  onSelectionChange={onSelectionChange} />
        </div>
    
    
  );
}
