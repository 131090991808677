import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { 
  LOADING_TRANSACTION_SPLIT,
  RELOAD_TRANSACTION_SPLIT,
  GET_ALL_SPLIT_ACCOUNT,
  GET_SPLIT_ACCOUNT_DETAILS,
  STORE_SPLIT_PAYOUT_ACCOUNT,
  UPDATE_SPLIT_PAYOUT_ACCOUNT,
  REMOVE_SPLIT_PAYOUT_ACCOUNT,
} from "./types";

/**
 * Get all split account history
 * @param {object} payLoad {"split_type":"percentage"}
 * @returns {void}
 */
export const getSplitAccounts = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:true});
    Axios.get(`/split-accounts`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      dispatch({ type: GET_ALL_SPLIT_ACCOUNT, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Create a split account
 * @param {object} payLoad {"split_name":"","account_number":"","split_type":"","share":""}
 * @returns {void}
 */
export const createSplitAccount = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:true});
    Axios.post(`/split-accounts`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      dispatch({ type: STORE_SPLIT_PAYOUT_ACCOUNT, payLoad:res});
      successHandler(res, true);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Update a split account
 * @param {object} payLoad {"id":"","split_name":"","account_number":"","split_type":"","share":""}
 * @returns {void}
 */
export const updateSplitAccount = (id, payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:true});
    Axios.put(`/split-accounts/${id}`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      dispatch({ type: UPDATE_SPLIT_PAYOUT_ACCOUNT, payLoad:res});
      successHandler(res,true);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Delete a split account
 * @param {object} payLoad {"id":""}
 * @returns {void}
 */
export const deleteSplitAccount = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:true});
    Axios.delete(`/split-accounts/${payLoad.id}`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      dispatch({ type: REMOVE_SPLIT_PAYOUT_ACCOUNT, payLoad:res});
      dispatch({ type: RELOAD_TRANSACTION_SPLIT });
      successHandler(res,true);
    })
    .catch((error) => {
      dispatch({ type: LOADING_TRANSACTION_SPLIT, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get a split account detail
 * @param {object} payLoad {"id":""}
 * @returns {void}
 */
 export const getSplitAccountDetails = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/split-accounts/${payLoad.id}`,payLoad)
    .then((res) => {
      dispatch({ type: GET_SPLIT_ACCOUNT_DETAILS, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}
