// Application types
export const LOADING_SETTLEMENTS = 'LOADING_SETTLEMENTS';
export const LOADING_SETTLEMENT_TRANSACTIONS = 'LOADING_SETTLEMENT_TRANSACTIONS';
export const RELOAD_SETTLEMENTS = 'RELOAD_SETTLEMENTS';

export const GET_ALL_SETTLEMENTS = 'GET_ALL_SETTLEMENTS';
export const GET_SETTLED_SETTLEMENTS = 'GET_SETTLED_SETTLEMENTS';
export const GET_UNSETTLED_SETTLEMENTS = 'GET_UNSETTLED_SETTLEMENTS';
export const GET_SETTLEMENT_TRANSACTIONS = 'GET_SETTLEMENT_TRANSACTIONS';
export const CREATE_SETTLEMENT_ACCOUNT = 'CREATE_SETTLEMENT_ACCOUNT';
export const EDIT_SETTLEMENT_ACCOUNT = 'EDIT_SETTLEMENT_ACCOUNT';
export const GET_SETTLEMENT_ACCOUNTS = 'GET_SETTLEMENT_ACCOUNTS';