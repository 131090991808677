import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import TransferInternationalForm1 from './TransferInternationalForm1';
import TransferInternationalForm2 from './TransferInternationalForm2';
import Button from '../../components/buttons/Button';
import { TransferFormContext } from '../../context/TransferFormContext';
import { removeCommas } from "../../helpers/helper";
import useFundTransfer from "../../hooks/useFundTransfer";

const TransferInternationalForm = () => {
  const {
    transferForm,
    activeStepAmount,
    setActiveStepAmount,
    availableBalance
  } = useContext(TransferFormContext);
  const { internationalTransferRate } = useFundTransfer();


  const next = () => {
    setActiveStepAmount(!activeStepAmount);
  };

  const fundsInsufficient = () => {
    if (((Number(parseFloat(removeCommas(transferForm?.amount))) * 100) + Number(parseFloat(internationalTransferRate?.fee) * 100) || 0 ) < Number(parseFloat(availableBalance) * 100) 
    && (Number(parseFloat(removeCommas(transferForm?.amount))) * 100 ) >= 1000) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if ('rate' in internationalTransferRate) {
      return true;
    } else return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internationalTransferRate])

  return (
    <div className='space-y-9'>
      <section className="page-title mb-9 items-center flex flex-col">
        <h2 className='text-3xl font-bold' >Make Transfer</h2>
        <p>International money transfer</p>
      </section>

      <div className='flex flex-col md:flex-row md:space-x-12 md:mr-24'>
        <div className='flex md:flex-col space-x-9 md:space-x-0 md:space-y-9 mt-1 mb-9 md:mb-auto'>
          <Step className='flex space-x-3 items-center justify-center'>
            <span className='step glade-bg-blue text-white glade-normal-text-one flex items-center justify-center'> 1 </span>
            <span className='glade-blue glade-normal-text-one'>Amount</span>
          </Step>
          <Step className='flex space-x-3 items-center justify-center'>
            <span className={`step glade-normal-text-one flex items-center justify-center ${!activeStepAmount ? 'glade-bg-blue text-white' : 'glade-bg-accent-light glade-grey-dark'}`}> 2 </span>
            <span className={`glade-normal-text-one ${!activeStepAmount ? 'glade-blue' : 'glade-grey-dark'}`}>Recipient</span>
          </Step>
        </div>
        {activeStepAmount &&
          <div className='mb-8'>
          <TransferInternationalForm1 />
          <div className='flex justify-center'>
            <Button.Blue
              onClick={next}
              title={"Next"}
              disabled={!Number(removeCommas(transferForm?.amount)) || fundsInsufficient() }
              className={"mt-8"}
            />
          </div>
          </div>
        }
        {!activeStepAmount &&
          <TransferInternationalForm2 />
        }
      </div>
    </div >
  );
};

export default TransferInternationalForm;

export const STransferForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 4rem;
  width: 445px;
`;

export const LinkText = styled.span`
  color: #1634A1;
  cursor: pointer;
`;

const Step = styled.div`

    .step{
      width: 25px;
      height: 25px;
      border-radius: 100px;
    }
`;