import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Logo from "../../assets/images/gladefinance_logo.png";
import { CgClose } from "react-icons/cg";
import AddNewRegisteredBusiness from "./AddNewRegisteredBusiness";
import { AngleRightIcon } from "../../assets/icons/Icons";
import { checkDraftStatus } from "../../redux/business/businessActions";
import useBusiness from "../../hooks/useBusiness";
export default React.forwardRef(function AddNewBusinessModal(props, ref) {
  const history = useHistory();
  const dispatch = useDispatch();
  const business = useBusiness();
  const closeModal = () => plainModalRef.current.close();
  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: () => plainModalRef.current.open(),
    close: () => plainModalRef.current.close(),
    toggle: () => plainModalRef.current.toggle(),
  }));

  const [openNewRegisteredBusiness, setOpenNewRegisteredBusiness] =
    useState(false);
  const [selectedRegBus, setSelectedRegBus] = useState(false);
  const [selectedNoRegBus, setSelectedNoRegBus] = useState(false);
  const { setOpenNewBusiness } = props;

  useEffect(() => {
    dispatch(checkDraftStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        openNewRegisteredBusiness ? (
          <AddNewRegisteredBusiness
            setOpenNewBusiness={setOpenNewRegisteredBusiness}
            setNewBusinessOpen={setOpenNewBusiness}
          />
        ) : (
          <RegisterNewBusiness>
            <div className="header container mx-auto">
              {/* Make header sticky */}
              <img src={Logo} alt="logo" />
              <span
                onClick={() => {
                  setOpenNewBusiness(false);
                  setSelectedNoRegBus(false);
                  setSelectedRegBus(false);
                }}
                className="icon close"
              >
                <CgClose size={31} />
              </span>
            </div>

            <Container>
              <div className="flex flex-col w-full md:w-2/5 my-9 px-6 py-6 space-y-9">
                <h3 className="text-center mb-9 glade-heading-three">
                  Add a Business Account
                </h3>
                <div
                  className={`flex flex-row justify-between p-6 cursor-pointer border-2 border-solid rounded ${
                    selectedRegBus ? "selected-business" : ""
                  }`}
                  onClick={() => {
                    setSelectedNoRegBus(false);
                    setSelectedRegBus(true);
                    setTimeout(function () {
                      setOpenNewRegisteredBusiness(true);
                    }, 2000);
                  }}
                >
                  <div className="flex flex-col justify-start items-start space-y-3">
                    <h3 className="glade-heading-three">
                      I have a registered business
                    </h3>
                    <p
                      className={`glade-normal-text-two ${
                        !selectedRegBus ? "glade-grey-cool" : ""
                      }`}
                    >
                      You will be required to submit your business registration
                      documents
                    </p>
                  </div>
                  <div className="flex items-center">
                    <AngleRightIcon />
                  </div>
                </div>

                <div
                  className={`flex flex-row justify-between p-6 cursor-pointer border-2 border-solid rounded ${
                    selectedNoRegBus ? "selected-business" : ""
                  }`}
                  onClick={() => {
                    setSelectedRegBus(false);
                    setSelectedNoRegBus(true);
                    setTimeout(function () {
                    //   setOpenNewBusiness(false);
                      history.push({
                        pathname:
                          "/dashboard/business/register-business/select-type",
                        state: business?.draftStatus,
                      });
                    }, 2000);
                  }}
                >
                  <div className="flex flex-col justify-start items-start space-y-3">
                    {business?.draftStatus?.status && (
                      <span className="text-glade-yellow-500 bg-glade-yellow-100 text-sm py-1 px-5">
                        Pending
                      </span>
                    )}

                    <h3 className="glade-heading-three">
                      I do not have a registered business yet
                    </h3>
                    <p
                      className={`glade-normal-text-two ${
                        !selectedNoRegBus ? "glade-grey-cool" : ""
                      }`}
                    >
                      We can help you register your business
                    </p>
                  </div>
                  <div className="flex items-center">
                    <AngleRightIcon />
                  </div>
                </div>
              </div>
            </Container>
          </RegisterNewBusiness>
        )

        /* <PlainModal
       ref={plainModalRef}
       primaryButton={<React.Fragment></React.Fragment>}
       modalTitle={<div className='flex justify-center py-3'>Add New Business Account</div>}
       modelContentSection={
         <div className="flex flex-col my-9 px-6 py-6">
           <div className="flex flex-row justify-between px-3 cursor-pointer" onClick={() => {
             closeModal();
             setOpenNewBusiness(true);
           }}>
             <div className="flex flex-col justify-start items-start space-y-3">
               <h3 className="glade-heading-three glade-black">I have a registered business</h3>
               <p className="glade-normal-text-two glade-grey-cool">You will be required to submit the necessary CAC documents.</p>
             </div>
             <div className="flex items-center">
               <IoIosArrowForward size={24} color='#9CA3AF' />
             </div>
           </div>

           <div className='h-1 border-b-2 border-solid my-9'></div>

           <div className="flex flex-row justify-between px-3 cursor-pointer" onClick={() => closeModal() & history.push("/dashboard/business/register-business")}>
             <div className="flex flex-col justify-start items-start space-y-3">
               <h3 className="glade-heading-three glade-black">I do not have a registered business yet</h3>
               <p className="glade-normal-text-two glade-grey-cool">We can help you register a business name or company.</p>
             </div>
             <div className="flex items-center">
               <IoIosArrowForward size={24} color='#9CA3AF' />
             </div>
           </div>
         </div >
         
       }
     />  */
      }
    </>
  );
});

export const RegisterNewBusiness = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: Inter, sans-serif;
  color: #151515;
  z-index: 300;
  width: 100%;
  background: #fff;
  overflow-y: auto;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    margin-bottom: 2rem;
    border-bottom: 3px solid gainsboro;
  }

  .icon {
    cursor: pointer;
    color: #1634a1;
    &:hover {
      color: #ea0202;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 65vh;

  .selected-business {
    background: rgba(4, 43, 183, 0.05);
    border: 1px solid #153bc6;
    color: #153bc6;
  }
`;
