import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createNewTeamsPassword } from "../../../redux/auth/authActions";
import useUser from "../../../hooks/useUser";
import useTeam from "../../../hooks/useTeam";
import TextInput from "../../../components/inputs/TextInput";
import Button from "../../../components/buttons/Button";
import {
  FormWizard,
  FormWizardSection,
} from "../../../components/form/FormWizard";
import TeamsLayout from "./TeamsLayout";
import { useHistory } from "react-router-dom";
// Analytics
import mixpanel from "mixpanel-browser";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { IoCheckmark } from "react-icons/io5";
import { RiCloseFill } from "react-icons/ri";
import { isEmail } from "../../../helpers/helper";
import { loginTeamMember } from "../../../redux/Team/TeamActions";

export default function TeamsLogin() {
  // Analytics

  if (process.env.REACT_APP_APP_ENV === "production") {
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true });
    mixpanel.track("Team Login Page");
  }
  const user = useUser();
  const dispatch = useDispatch();
  const formWizardRef = React.useRef(null);
  const history = useHistory();

  const [input, setInput] = useState({
    email: "",
    password: "",
    new_password: "",
    confirm_password: "",
  });

  const {isLoadingTeam} = useTeam();

  const [inputError, setInputError] = useState(false);

  const [passwordField, setPasswordField] = useState("password");


  const updatePassword = () => {
    dispatch(
      createNewTeamsPassword(input, () => formWizardRef.current.goTo(0))
    );
  };

  const onSubmit = () => {
    const onFailure = (error) => {
      let errorData = error?.error?.response?.data?.data;
      let is_password_set = error?.error?.response?.data?.data?.is_set_password;
      if (is_password_set === "false") {
        setInput({
          ...input,
          first_name: errorData?.first_name,
          last_name: errorData?.last_name,
          position: errorData?.position,
          user_uuid: errorData?.user_uuid,
        });
        formWizardRef.current.goTo(1);
      }
    };
    dispatch(
      loginTeamMember(input, () => history.push("/teams"), onFailure)
    );
  };

  const togglePasswordField = (text) => {
    setPasswordField(text);
  };

  const onChange = (e) => {
    if (e.target.name === "email") {
      if (isEmail(e.target.value)) {
        setInputError({
          ...inputError,
          [e.target.name]: false,
        });
      } else {
        setInputError({
          ...inputError,
          [e.target.name]: true,
        });
      }
      setInput({ ...input, [e.target.name]: e.target.value });
    } else if (e.target.name === "confirm_password") {
      if (e.target.value !== input.new_password) {
        setInputError({
          ...inputError,
          [e.target.name]: true,
        });
      } else {
        setInputError({
          ...inputError,
          [e.target.name]: false,
        });
      }
      setInput({ ...input, [e.target.name]: e.target.value });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  // eslint-disable-next-line no-useless-escape
  const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const oneNumberRegex = /\d/;
  const oneCapitalLetter = /[A-Z]/;

  return (
    <TeamsLayout>
      <FormWizard ref={formWizardRef}>
        {/* Login Section */}
        <FormWizardSection position={0}>
          <div className="w-full max-w-md">
            <div className="flex flex-row justify-center">
              <h3 className="glade-heading-three glade-black mx-auto">
                Log in to your account
              </h3>
            </div>
            <div className="mt-6 space-y-5">
              <TextInput
                label={"Email"}
                id={"email"}
                name={"email"}
                type={"email"}
                value={input.email}
                onChange={onChange}
                placeholder={"Enter email address"}
                autoComplete={"email"}
                className={"appearance-none"}
                errorState={inputError.email}
              />
              {inputError.email && (
                <p className="glade-form-fail">Enter a valid email</p>
              )}

              <TextInput
                rightSection={
                  passwordField === "password" ? (
                    <BsEye
                      className="self-center rounded-sm relative right-10 cursor-pointer"
                      onClick={() => togglePasswordField("text")}
                    />
                  ) : (
                    <BsEyeSlash
                      className="self-center rounded-sm relative right-10 cursor-pointer"
                      onClick={() => togglePasswordField("password")}
                    />
                  )
                }
                label={"Password"}
                id={"password"}
                name={"password"}
                type={passwordField}
                value={input.password}
                onChange={onChange}
                placeholder={"Enter password"}
                autoComplete={"password"}
                className={"appearance-none"}
              />

              <div className="pt-3 flex justify-center items-center">
                <Button.Blue
                  title={"Log In"}
                  type={"submit"}
                  className={"py-3 px-10"}
                  onClick={onSubmit}
                  disabled={
                    isLoadingTeam ||
                    !input.password ||
                    !isEmail(input.email) ||
                    !input.email
                  }
                  loading={isLoadingTeam}
                />
              </div>

              <p className="mt-2 flex justify-center glade-normal-text-two">
                <a
                  href="/team/reset-password"
                  className="glade-normal-text-two glade-blue"
                >
                  Forgot your Passsword?
                </a>
              </p>
            </div>
          </div>
        </FormWizardSection>

        {/* Create new password Section */}
        <FormWizardSection position={1}>
          <div className="w-full max-w-md">
            <div className="flex flex-col justify-center">
              <h3 className="flex justify-center text-center mt-6 glade-heading-three glade-black">
                {" "}
                Create new Password to continue
              </h3>
            </div>
            <div className="mt-6 space-y-6 relative">
              <div>
                <TextInput
                  label={"Name"}
                  id={"first_name"}
                  name={"first_name"}
                  type={"text"}
                  value={`${input?.first_name} ${input?.last_name}` ?? ""}
                  onChange={onChange}
                  disabled
                  autoComplete={"first_name"}
                  className={"appearance-none"}
                />
              </div>

              <div>
                <TextInput
                  label={"Email"}
                  id={"email"}
                  name={"email"}
                  type={"email"}
                  value={input.email}
                  onChange={onChange}
                  autoComplete={"email"}
                  placeholder={"Enter Email Address"}
                  className={`appearance-none`}
                  errorState={inputError.email}
                />
                {inputError.email && (
                  <p className="glade-form-fail">Enter a valid email</p>
                )}
              </div>

              <div>
                <TextInput
                  label={"Position"}
                  id={"position"}
                  name={"position"}
                  type={"text"}
                  value={input?.position ?? ""}
                  onChange={onChange}
                  disabled
                  autoComplete={"position"}
                  className={"appearance-none"}
                />
              </div>
              <div>
                <TextInput
                  rightSection={
                    passwordField === "password" ? (
                      <BsEye
                        className="self-center rounded-sm absolute right-5 cursor-pointer"
                        onClick={() => togglePasswordField("text")}
                      />
                    ) : (
                      <BsEyeSlash
                        className="self-center rounded-sm absolute right-5 cursor-pointer"
                        onClick={() => togglePasswordField("password")}
                      />
                    )
                  }
                  label={"New Password"}
                  id={"new_password"}
                  name={"new_password"}
                  type={passwordField}
                  value={input.new_password}
                  onChange={onChange}
                  placeholder={"********"}
                  autoComplete={"new_password"}
                  className={"appearance-none relative"}
                />

                {input?.new_password && (
                  <div className="flex gap-2 pt-3 pb-2">
                    <div
                      className={`rounded-full h-1 w-3/12 ${
                        input.new_password.length > 7
                          ? "glade-bg-green-light"
                          : input.new_password.length > 4
                          ? "glade-bg-yellow"
                          : "glade-bg-grey"
                      }`}
                    ></div>
                    <div
                      className={`rounded-full h-1 w-3/12 ${
                        input.new_password.length > 7
                          ? "glade-bg-green-light"
                          : input.new_password.length > 4
                          ? "glade-bg-yellow"
                          : "glade-bg-grey"
                      }`}
                    ></div>
                    <div
                      className={`rounded-full h-1 w-3/12 ${
                        input.new_password.length > 7
                          ? "glade-bg-green-light"
                          : input.new_password.length > 4
                          ? "glade-bg-yellow"
                          : "glade-bg-grey"
                      }`}
                    ></div>
                    <div
                      className={`rounded-full h-1 w-3/12 ${
                        input.new_password.length > 7
                          ? "glade-bg-green-light"
                          : input.new_password.length > 4
                          ? "glade-bg-yellow"
                          : "glade-bg-grey"
                      }`}
                    ></div>
                    <div
                      className={`rounded-full h-1 w-3/12 ${
                        input.new_password.length > 7
                          ? "glade-bg-green-light"
                          : input.new_password.length > 4
                          ? "glade-bg-yellow"
                          : "glade-bg-grey"
                      }`}
                    ></div>
                  </div>
                )}
                {input?.new_password && (
                  <div>
                    <p className="glade-grey-dark flex items-center gap-2">
                      {input.new_password.length > 7 ? (
                        <IoCheckmark className="glade-form-mark" />
                      ) : (
                        <RiCloseFill className="glade-form-fail" />
                      )}{" "}
                      Password should be 8 or more characters{" "}
                    </p>
                    <p className="glade-grey-dark flex items-center gap-2">
                      {oneCapitalLetter.test(input.new_password) ? (
                        <IoCheckmark className="glade-form-mark" />
                      ) : (
                        <RiCloseFill className="glade-form-fail" />
                      )}{" "}
                      Have at least one upper case letter
                    </p>
                    <p className="glade-grey-dark flex items-center gap-2">
                      {oneNumberRegex.test(input.new_password) ? (
                        <IoCheckmark className="glade-form-mark" />
                      ) : (
                        <RiCloseFill className="glade-form-fail" />
                      )}{" "}
                      Have at least one number{" "}
                    </p>
                    <p className="glade-grey-dark flex items-center gap-2">
                      {specialCharacterRegex.test(input.new_password) ? (
                        <IoCheckmark className="glade-form-mark" />
                      ) : (
                        <RiCloseFill className="glade-form-fail" />
                      )}{" "}
                      Have at least one special character {`(!@#$%^&*()_+)`}
                    </p>
                  </div>
                )}
              </div>

              <div>
                <TextInput
                  rightSection={
                    passwordField === "password" ? (
                      <BsEye
                        className="self-center rounded-sm absolute right-5 cursor-pointer"
                        onClick={() => togglePasswordField("text")}
                      />
                    ) : (
                      <BsEyeSlash
                        className="self-center rounded-sm absolute right-5 cursor-pointer"
                        onClick={() => togglePasswordField("password")}
                      />
                    )
                  }
                  label={"Confirm Password"}
                  id={"confirm_password"}
                  name={"confirm_password"}
                  type={passwordField}
                  value={input.confirm_password}
                  onChange={onChange}
                  placeholder={"********"}
                  autoComplete={"confirm_password"}
                  className={"appearance-none relative"}
                  errorState={inputError.confirm_password}
                />
                <span className="glade-small-text-one glade-grey-cool">
                  Enter same password as before to confirm again
                </span>

                {inputError.confirm_password && (
                  <p className="glade-form-fail">Password doesn’t match</p>
                )}
              </div>

              <div className="pt-3 flex justify-center">
                <Button.Blue
                  title={"Continue"}
                  type={"submit"}
                  className={"py-3"}
                  onClick={updatePassword}
                  disabled={
                    isLoadingTeam ||
                    !input?.new_password ||
                    input?.new_password !== input?.confirm_password
                  }
                  loading={isLoadingTeam}
                />
              </div>
              {/* <p className="mt-2 glade-normal-text-two text-center">
                    <span className="mr-1 glade-black">Remember your password?</span>
                    <a href="/auth/login" className="glade-normal-text-two glade-blue">Sign In</a>
                  </p> */}
            </div>
          </div>
        </FormWizardSection>
      </FormWizard>
    </TeamsLayout>
  );
}
