// Application types
export const LOADING_VIRTUAL_CARDS = 'LOADING_VIRTUAL_CARDS';
export const RELOAD_VIRTUAL_CARDS = 'RELOAD_VIRTUAL_CARDS';

export const GET_VIRTUAL_CARD_TITLES = 'GET_VIRTUAL_CARD_TITLES';
export const GET_VIRTUAL_CARD_CURRENCIES = 'GET_VIRTUAL_CARD_CURRENCIES';
export const GET_VIRTUAL_CARD_DESIGNS = 'GET_VIRTUAL_CARD_DESIGNS';
export const CREATE_VIRTUAL_CARD = 'CREATE_VIRTUAL_CARD';
export const LOADING_EDIT_VIRTUAL_CARDS = 'LOADING_EDIT_VIRTUAL_CARDS';
export const EDIT_VIRTUAL_CARDS_SUCCESS = 'EDIT_VIRTUAL_CARDS_SUCCESS';
export const EDIT_VIRTUAL_CARDS_FAILURE = 'EDIT_VIRTUAL_CARDS_FAILURE';


export const GET_VIRTUAL_CARDS = 'GET_VIRTUAL_CARDS';
export const GET_VIRTUAL_CARD_DETAILS = 'GET_VIRTUAL_CARD_DETAILS';
export const GET_VIRTUAL_CARD_TRANSACTIONS = 'GET_VIRTUAL_CARD_TRANSACTIONS';

export const FUND_VIRTUAL_CARD = 'FUND_VIRTUAL_CARD';
export const WITHDRAW_VIRTUAL_CARD_FUND = 'WITHDRAW_VIRTUAL_CARD_FUND';
export const FREEZE_VIRTUAL_CARD = 'FREEZE_VIRTUAL_CARD';
export const UNFREEZE_VIRTUAL_CARD = 'UNFREEZE_VIRTUAL_CARD';
export const TERMINATE_VIRTUAL_CARD = 'TERMINATE_VIRTUAL_CARD';

export const SEARCH_VIRTUAL_CARDS = 'SEARCH_VIRTUAL_CARDS';
export const FILTER_VIRTUAL_CARDS = 'FILTER_VIRTUAL_CARDS';
export const GET_VIRTUAL_CARD_CHARGES = 'GET_VIRTUAL_CARD_CHARGES';
