import { useSelector } from "react-redux";

const useCustomers = () => {
  const customers = useSelector(state => state.customers);

  const {
    isLoading,
    isLoadingDetails,
    isBlacklisting,
    isWhitelisting,
    shouldReload,
    createdCustomer,
    blacklistedCustomer,
    whitelistedCustomer,
    customerIndex,
    customerById,
    customerByEmail,
    blacklistedCustomerIndex,
    whitelistedCustomerIndex,
  } = customers;

  return {
    isLoading,
    isLoadingDetails,
    isBlacklisting,
    isWhitelisting,
    shouldReload,
    createdCustomer,
    blacklistedCustomer,
    whitelistedCustomer,
    customerIndex,
    customerById,
    customerByEmail,
    blacklistedCustomerIndex,
    whitelistedCustomerIndex,
  }
}

export default useCustomers;
