import React from 'react';
import { Tab } from '@headlessui/react';

export function TabPanel({children, className=''}) {
    return (
        <Tab.Panel className={className}>
            {children}
        </Tab.Panel>
    )
}

export function TabGroup({column=[], className='', children, defaultIndex=0}) {
    return (
        <Tab.Group manual defaultIndex={defaultIndex}>
            <Tab.List className="flex pr-6 space-x-1 rounded-sm">
                {column.map((item, key) => (
                    <Tab
                        key={key}
                        className={({ selected })=>(
                            selected?
                                'w-1/6 md:w-1/12 py-2.5 mx-6 glade-subtitle-one glade-blue border-b-2 border-blue-800 '+className 
                            :
                                'w-1/6 md:w-1/12 py-2.5 mx-6 glade-subtitle-one glade-blue border-b-2 border-transparent '+className
                        )}>
                        {item}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels className="mt-0 pt-0">
                {children}
            </Tab.Panels>
        </Tab.Group>
    )
}
