import React, { useEffect, useState, useContext, Fragment } from "react";
import SideBarCloseButton from "../../../components/sidebars/SideBarCloseButton";
import SidebarGroupLink from "../../../components/sidebars/SidebarGroupLink";
import SidebarLink from "../../../components/sidebars/SidebarLink";
import GladeLogo from "../../../components/logos/GladeLogo";
import { Bank } from "../../../assets/icons/Icons";
import { getPersonalAndBusinessAccounts } from "../../../redux/auth/authActions";
import { getDashboardFeatures } from "../../../redux/settings/settingsActions";

import { FaMobileAlt, FaFileInvoice, FaUniversity } from "react-icons/fa";
import { IoWalletOutline, IoPaperPlaneOutline } from "react-icons/io5";
import { RiExchangeDollarLine, RiHome4Line } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { BiCog, BiUser } from "react-icons/bi";
import { MdOutlinePayments } from "react-icons/md";
import {
  Payroll,
  Cancel,
  Invoice,
  PayBills,
  Pos,
  Logout,
} from "../../../assets/icons/Icons";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import AccountSelection from "./AccountSelection";
import { toggleSelectedCurrency } from "../../../redux/auth/authActions";
import {
  getBusinessInflowAndOutflow,
  getBusinessTransactions,
} from "../../../redux/home/homeActions";
import { getAllStatements } from "../../../redux/transactions/transactionActions";
import { SideNavContext } from "../../../context/SideNavContext";
import { CgFolderRemove } from "react-icons/cg";
import useSettings from "../../../hooks/useSettings";
import { logoutUserLocallyAction } from "../../../redux/auth/unAuthActions";
import useTeam from "../../../hooks/useTeam";

export default function TeamSidebar() {
  const user = useTeam();
  const dispatch = useDispatch();
  const settings = useSettings();
  const { dashboardFeatures } = settings;

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [addCurrency, showAddCurrency] = useState(false);
  const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);
  const { closeNav, navCollapsed } = useContext(SideNavContext);

  useEffect(() => {
    dispatch(getPersonalAndBusinessAccounts());
  }, []);

  const logoutFxn = () => {
    dispatch(logoutUserLocallyAction());
  };

  // This life hook pushes the selected currency account into the user.selectedCurrency key in the state user object.
  // Also calls all dashboard dependent endpoint based on selected currency/
  useEffect(() => {
    if (user?.isBusiness) {
      dispatch(getDashboardFeatures());
      dispatch(getBusinessTransactions());
      dispatch(getAllStatements());
      dispatch(
        getBusinessInflowAndOutflow({
          start_date: new Date(new Date().setDate(new Date().getDate() - 7))
            .toISOString()
            .substring(0, 10),
          end_date: new Date().toISOString().substring(0, 10),
        })
      );
    }

    if (
      user &&
      !user?.selectedBusinessOrPersonalAccount?.hasOwnProperty("currency") &&
      user?.selectedBusinessOrPersonalAccount?.accounts?.length > 0
    ) {
      // Save selected currency index to storage, to be available on refresh.
      dispatch(
        toggleSelectedCurrency(
          user?.selectedBusinessOrPersonalAccount?.accounts[
            Number(localStorage.getItem("currencyIndex"))
          ],
          user?.selectedBusinessOrPersonalAccount
        )
      );
    }
  }, [selectedCurrencyIndex, localStorage.getItem("currencyIndex")]);

  const closeModal = () => {
    document.body.style.overflowY = "auto";
    showAddCurrency(false);
  };

  return (
    <SideNavSC navCollapsed={navCollapsed}>
      <div className={navCollapsed ? "hidden" : ""}>
        {/* Toggler For Smaller Screens*/}

        {/* Collapsable sidebar */}
        <div>
          {/* Collapsed sidebar header */}
          <div className="md:min-w-full md:hidden block pt-8 pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12 flex justify-start">
                <GladeLogo />
              </div>
              <div className="w-6/12 flex justify-end">
                <SideBarCloseButton onClick={closeNav} />
              </div>
            </div>
          </div>

          {/* Expanded sidebar header */}
          <div className="md:min-w-full md:block hidden my-6">
            <div className="w-full flex flex-wrap justify-start">
              <GladeLogo />
            </div>
          </div>

          {/* Account Selection */}
          <AccountSelection />
          {/* List of Currencies */}
          {user?.team_user?.employee?.is_teams ? (
            <>
              <SidebarGroupLink title={""}>
                <SidebarLink
                  name={"Home"}
                  path={"/teams/home"}
                  icon={<RiHome4Line title={"Home"} />}
                />
                <SidebarLink
                  name={"Balance"}
                  path={"/teams/balances"}
                  icon={<CgFolderRemove title={"Balance"} />}
                />
              </SidebarGroupLink>
              <div
                className="flex flex-col justify-between"
                onClick={() => setCollapseShow("hidden")}
              >
                <SidebarGroupLink title={"REPORTS"}>
                  <SidebarLink
                    name={"Transactions"}
                    path={"/teams/transactions"}
                    icon={<FaFileInvoice title={"Transactions"} />}
                  />
                  {(dashboardFeatures?.payment_inflow ||
                    dashboardFeatures?.payment_inflow === 1) && (
                    <SidebarLink
                      name={"Payment Inflow"}
                      path={"/teams/payment-inflow"}
                      icon={<IoWalletOutline title={"Payment Inflow"} />}
                    />
                  )}

                  {(dashboardFeatures?.settlements ||
                    dashboardFeatures?.settlements === 1) && (
                    <SidebarLink
                      name={"Settlements"}
                      path={"/teams/settlements"}
                      icon={<RiExchangeDollarLine title={"Settlements"} />}
                    />
                  )}

                  {(dashboardFeatures?.customers ||
                    dashboardFeatures?.customers === 1) && (
                    <SidebarLink
                      name={"Customers"}
                      path={"/teams/customers"}
                      icon={<FiUsers title={"Customers"} />}
                    />
                  )}
                </SidebarGroupLink>

                <SidebarGroupLink title={"PAYMENTS"}>
                  <SidebarLink
                    name={"Fund Transfer"}
                    path={"/teams/fund-transfer"}
                    icon={<IoPaperPlaneOutline title={"Fund Transfer"} />}
                  />
                  {user.isBusiness && (
                    <Fragment>
                      {(dashboardFeatures?.payment_pages ||
                        dashboardFeatures?.payment_pages === 1) && (
                        <SidebarLink
                          name={"Request Payments"}
                          path={"/teams/payment-pages"}
                          icon={
                            <MdOutlinePayments title={"Request Payments"} />
                          }
                        />
                      )}

                      {(dashboardFeatures?.loans ||
                        dashboardFeatures?.loans === 1) && (
                        <SidebarLink
                          name={"Financing"}
                          path={"/teams/loans"}
                          icon={<Bank title={"Get a Loan"} size={15} />}
                        />
                      )}
                    </Fragment>
                  )}
                  {(dashboardFeatures?.transaction_split ||
                    dashboardFeatures?.transaction_split === 1) && (
                    <SidebarLink
                      name={"Transaction Split"}
                      path={"/teams/transaction-split"}
                      icon={
                        <Cancel title={"Transaction Split"} color={"#717485"} />
                      }
                    />
                  )}

                  {/* 
                  <SidebarLink
                    name={"Chargebacks"}
                    path={"/teams/charge-backs"}
                    icon={<RiFlagLine title={"Chargebacks"} />}
                  />
                */}
                </SidebarGroupLink>

                <SidebarGroupLink title={"MARKETPLACE"}>
                  {(dashboardFeatures?.buy_airtime ||
                    dashboardFeatures?.buy_airtime === 1) && (
                    <SidebarLink
                      name={"Airtime"}
                      path={"/teams/airtime"}
                      icon={<FaMobileAlt title={"Airtime"} />}
                    />
                  )}
                  {(dashboardFeatures?.pay_bills ||
                    dashboardFeatures?.pay_bills === 1) && (
                    <SidebarLink
                      name={"Pay Bills"}
                      path={"/teams/pay-bills"}
                      icon={<PayBills title={"Pay Bills"} color={"#717485"} />}
                    />
                  )}
                </SidebarGroupLink>

                <SidebarGroupLink title={"BUSINESS"}>
                  {(dashboardFeatures?.payroll ||
                    dashboardFeatures?.payroll === 1) && (
                    <SidebarLink
                      name={"Payroll"}
                      path={"/teams/payroll/payday"}
                      icon={<Payroll title={"Payroll"} color={"#717485"} />}
                    />
                  )}

                  {(dashboardFeatures?.pos || dashboardFeatures?.pos === 1) && (
                    <SidebarLink
                      name={"POS"}
                      path={"/teams/pos"}
                      icon={<Pos title={"POS"} color={"#717485"} />}
                    />
                  )}
                  {(dashboardFeatures?.invoices ||
                    dashboardFeatures?.invoices === 1) && (
                    <SidebarLink
                      name={"Invoices"}
                      path={"/teams/invoices"}
                      icon={<Invoice title={"Invoices"} color={"#717485"} />}
                    />
                  )}
                </SidebarGroupLink>
              </div>
              <div
                className="md:hidden mt-3 mb-6"
                onClick={() => setCollapseShow("hidden")}
              >
                <SidebarLink
                  listStyle={{ listStyle: "none" }}
                  name={"Profile"}
                  path={"/teams/settings/profile"}
                  icon={<BiUser color={"#717485"} size={15} />}
                />

                <SidebarLink
                  listStyle={{ listStyle: "none" }}
                  name={"Settings"}
                  path={"/teams/settings"}
                  icon={
                    <BiCog title={"Settings"} color={"#717485"} size={15} />
                  }
                />
                <button
                  className="flex gap-3 items-center justify-center"
                  onClick={() => logoutFxn()}
                >
                  <Logout title={"Logout"} color={"#717485"} size={15} /> Logout
                </button>
              </div>{" "}
            </>
          ) : (
            <>
              <SidebarGroupLink title={"TEAMS"}>
                <SidebarLink
                  name={"Dashboard"}
                  path={"/teams/home"}
                  icon={<FaUniversity title={"Teams Dashboard"} />}
                />
              </SidebarGroupLink>
            </>
          )}
        </div>
      </div>
    </SideNavSC>
  );
}

const SideNavSC = styled(motion.nav)`
  overflow-x: hidden;
  width: 100%;
  padding: 0 1.5rem;
  top: 0;
  overflow-y: auto;
  position: relative;

  @media (max-width: 700px) {
    min-height: 100vh;
  }
`;
