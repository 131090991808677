// Application types
export const LOADING_AUTH = 'LOADING_AUTH';
export const LOADING_TEAM = 'LOADING_TEAM';
export const LOADING_VERIFY_USER_PIN = 'LOADING_VERIFY_USER_PIN';
export const LOADING_RESOLVE_BVN = 'LOADING_RESOLVE_BVN';
export const RELOAD_AUTH = 'RELOAD_AUTH';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const NEWTEAM_PASSWORD_FAILURE='NEWTEAM_PASSWORD_FAILURE'
export const NEWTEAM_PASSWORD_SUCCESS='NEWTEAM_PASSWORD_SUCCESS'
export const LOADING_NEWTEAM_PASSWORD='LOADING_NEWTEAM_PASSWORD'
export const TOGGLE_ACCOUNT = 'TOGGLE_ACCOUNT';
export const TOGGLE_CURRENCY = 'TOGGLE_CURRENCY';
export const REGISTER_USER = 'REGISTER_USER';
export const REFRESHED_TOKEN = 'REFRESHED_TOKEN';
export const STORE_USER_INFO = 'STORE_USER_INFO';
export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';
export const LOGIN_TEAM_SUCCESS = 'LOGIN_TEAM_SUCCESS';
export const LOGIN_TEAM_FAILURE = 'LOGIN_TEAM_FAILURE';
export const RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION';
export const CREATE_PASS_CODE = 'CREATE_PASS_CODE';
export const INIT_PASSWORD_RESET = 'INIT_PASSWORD_RESET';
export const COMPLETE_PASSWORD_RESET = 'COMPLETE_PASSWORD_RESET';
export const GET_BUSINESS_PERSONAL_ACCOUNTS = 'GET_BUSINESS_PERSONAL_ACCOUNTS';
export const GET_BUSINESS_PERSONAL_ACCOUNTS_LOADING = "GET_BUSINESS_PERSONAL_ACCOUNTS_LOADING";
export const GET_BUSINESS_PERSONAL_ACCOUNTS_FAILURE = "GET_BUSINESS_PERSONAL_ACCOUNTS_FAILURE";
export const UPDATE_SELECTED_ACCOUNT_BALANCE = 'UPDATE_SELECTED_ACCOUNT_BALANCE';
export const VERIFY_USER_PIN = 'VERIFY_USER_PIN';
export const RESOLVE_BVN = 'RESOLVE_BVN';
export const USERTAG_FAILURE= "USERTAG_FAILURE";
export const USERTAG_SUCCESS= "USERTAG_SUCCESS";
export const LOADING_USERTAG= "LOADING_USERTAG";
export const LOADING_USERTAG_AVAILABLE= "LOADING_USERTAG_AVAILABLE";
export const USERTAG_AVAILABLE_SUCCESS= "USERTAG_AVAILABLE_SUCCESS";
export const USERTAG_AVAILABLE_FAILURE= "USERTAG_AVAILABLE_FAILURE";
export const VERIFY_TWO_FACTOR_CODE = "VERIFY_TWO_FACTOR_CODE"

export const VERIFY_TWO_FACTOR_QR_CODE_START = "VERIFY_TWO_FACTOR_QR_CODE_START"
export const VERIFY_TWO_FACTOR_QR_CODE_FAILURE = "VERIFY_TWO_FACTOR_QR_CODE_FAILURE"
export const VERIFY_TWO_FACTOR_QR_CODE_SUCCESS = "VERIFY_TWO_FACTOR_QR_CODE_SUCCESS"

export const LOADING_TWO_AUTH = "LOADING_TWO_AUTH"

export const GENERATE_QR_CODE_START = "GENERATE_QR_CODE_START"
export const GENERATE_QR_CODE_FAILURE = "GENERATE_QR_CODE_FAILURE"
export const GENERATE_QR_CODE_SUCCESS = "GENERATE_QR_CODE_SUCCESS"

export const VERIFY_USER_START = "VERIFY_USER_START"
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS"
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE"

export const REFRESH_2FA_STATUS_START = "REFRESH_2FA_STATUS_START"
export const REFRESH_2FA_STATUS_SUCCESS = "REFRESH_2FA_STATUS_SUCCESS"
export const REFRESH_2FA_STATUS_FAILURE = "REFRESH_2FA_STATUS_FAILURE"

export const LOADING_VERIFICATION_ID = "LOADING_VERIFICATION_ID"
export const SUBMIT_VERIFICATION_ID = "SUBMIT_VERIFICATION_ID"
export const LOADING_METAMAP_STATUS = "LOADING_METAMAP_STATUS"