import React, { useEffect, useState } from "react";
import Button from "../../components/buttons/Button";
import useUser from "../../hooks/useUser";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllWalletBalances,
  getAllIFAccountDetails,
} from "../../redux/balances/balancesActions";
import { RiDownload2Line, RiWallet3Line } from "react-icons/ri";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  copyTextToClipboard,
  getSuppliedFieldValue,
} from "../../helpers/helper";
import Transactions from "../transactions/Transactions";
import { downloadTransactionsAsPDF } from "../transactions/DownloadTransactionsAsPDF";
import useTransactions from "../../hooks/useTransactions";
import ReceiverFlag from "../../components/transfer/ReceiverFlag";
import useBalances from "../../hooks/useBalances";
import { TextSkeleton } from "../../components/skeletons";
import useHome from "../../hooks/useHome";
import useTeam from "../../hooks/useTeam";

const AccountDetails = () => {
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const transaction = useTransactions();
  const home = useHome();
  const { business, team_user, permissions } = useTeam();
  const [details, setDetails] = useState({});
  const { isLoadingAccountDetails, accountDetails } = useBalances();

  useEffect(() => {
    if (
      !location.state.currency &&
      location?.pathname !== "/teams/account-details"
    ) {
      // setDetails(getSuppliedFieldValue("accounts", user)[location?.state?.index]);
    } else if (location?.pathname === "/teams/account-details") {
      setDetails(business?.accounts[location.state.index]);
    } else {
      dispatch(getAllIFAccountDetails(location.state?.currency));
    }
    return user?.isBusiness ? dispatch(getAllWalletBalances()) : null;
  }, []);

  const dataForPDF = {
    details: transaction?.statementIndex?.details,
    statement: transaction?.statementIndex?.statement,
  };

  return (
    <>
      {!location.state?.currency && (
        <div className="p-3">
          <div className="sm:block md:flex justify-between item-center border-b pb-6 pt-6">
            <div className="sm:pb-4 md:flex justify-center items-center gap-4">
              <div className="rounded-full">
                <ReceiverFlag
                  flagCode={details?.currency?.slice(0, -1)}
                  size={80}
                />
              </div>
              <div>
                <h2 className="glade-heading-two glade-home-dark">
                  <span className="font-bold">
                    {details?.balance?.toLocaleString()} {details?.currency}
                  </span>
                </h2>
                <span className="text-glade-gray-400">
                  Your total balance in {details?.currency}
                </span>
              </div>
            </div>
            <div className="flex gap-6">
              {!team_user?.employee?.is_teams && (
                <p>
                  <Button.Transparent
                    title={"Statement"}
                    onClick={() =>
                      downloadTransactionsAsPDF(
                        dataForPDF.details,
                        dataForPDF.statement
                      )
                    }
                    children={
                      <RiDownload2Line
                        size={16}
                        title="Statement"
                        color={"#717485"}
                      />
                    }
                  />
                </p>
              )}
              <p>
                <Button.Transparent
                  title={"Add Funds"}
                  onClick={() =>
                    !team_user?.employee?.is_teams
                      ? history.push("/dashboard/fund-account")
                      : history.push("/teams/fund-account")
                  }
                  children={
                    <RiWallet3Line
                      title="Add Fund"
                      size={16}
                      color={"#717485"}
                    />
                  }
                />
              </p>
              {!team_user?.employee?.is_teams && (
                <p>
                  <Button.Blue
                    title={"Send Money"}
                    onClick={() =>
                      history.push({
                        pathname: !team_user?.employee?.is_teams
                          ? "/dashboard/fund-transfer"
                          : "/teams/fund-transfer",
                        state: { showModal: true },
                      })
                    }
                  />
                </p>
              )}
            </div>
          </div>
          <div className="pt-6">
            <h3 className="glade-heading-four mb-4">Bank Account Details</h3>
            <div className="border p-8 min-h-60 rounded-md shadow flex flex-col gap-6 justify-center mb-6">
              {details?.currency?.toLowerCase() !== "ngn" ? (
                <>
                  <div className="grid sm:grid-cols-3 gap-8">
                    {details?.account_name && (
                      <div>
                        <h4
                          className={`font-semibold glade-grey-cool uppercase pb-3 text-xs`}
                        >
                          Account Name
                        </h4>
                        <div className="flex">
                          <h4 className={`glade-home-dark capitalize`}>
                            {details?.account_name}
                          </h4>
                          <h4>
                            <FiCopy
                              size={20}
                              className="ml-3 cursor-pointer glade-grey-dark"
                              onClick={() => {
                                copyTextToClipboard(details?.account_name);
                                toast.success("Copied to clipboard!", {
                                  position: toast.POSITION.TOP_RIGHT,
                                  theme: "colored",
                                });
                              }}
                            />
                          </h4>
                        </div>
                      </div>
                    )}
                    <div>
                      <h4
                        className={`font-semibold glade-grey-cool uppercase pb-3 text-xs`}
                      >
                        Bank or Wallet Name
                      </h4>
                      <h4 className={`glade-home-dark capitalize`}>
                        {details?.bank_name ? details?.bank_name : "N/A"}
                      </h4>
                    </div>

                    <div>
                      <h4
                        className={`font-semibold glade-grey-cool uppercase pb-3 text-xs`}
                      >
                        {details?.account_type !== "wallet"
                          ? "Bank Account Number"
                          : "WALLET ACCOUNT ID"}
                      </h4>
                      <div className="flex">
                        <h4 className={`glade-home-dark capitalize`}>
                          {details?.account_number}
                        </h4>
                        <h4>
                          <FiCopy
                            size={20}
                            className="ml-3 cursor-pointer glade-grey-dark"
                            onClick={() => {
                              copyTextToClipboard(details?.account_number);
                              toast.success("Copied to clipboard!", {
                                position: toast.POSITION.TOP_RIGHT,
                                theme: "colored",
                              });
                            }}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="text-lg font-semibold text-glade-black-800 border-b pb-2">
                    <h3>Account 1</h3>
                  </div>
                  <AccountDetailsDisplay
                    user={user}
                    details={
                      user?.isBusiness
                        ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                          home.oneBusinessAccount?.naira_accounts?.accounts[0]
                        : team_user?.employee?.is_teams
                        ? team_user?.ngn_accounts[0]
                        : user?.accountInfo?.accounts &&
                          user?.accountInfo?.accounts[0]
                    }
                  />
                  <div className="text-lg font-semibold text-glade-black-800 border-b pb-2">
                    {" "}
                    <h3>Account 2</h3>
                  </div>
                  <AccountDetailsDisplay
                    user={user}
                    details={
                      user?.isBusiness
                        ? home.oneBusinessAccount?.naira_accounts?.accounts &&
                          home.oneBusinessAccount?.naira_accounts?.accounts[1]
                        : team_user?.employee?.is_teams
                        ? team_user?.ngn_accounts[1]
                        : user?.accountInfo?.accounts &&
                          user?.accountInfo?.accounts[1]
                    }
                  />
                </>
              )}
            </div>
          </div>
          {((team_user?.employee?.is_teams &&
            permissions?.includes("transactions page")) ||
            !team_user?.employee?.is_teams) && (
            <div className="pt-6">
              <h3 className="glade-heading-four mb-4">Your Transactions</h3>
              <div>
                <Transactions
                  tableModifier={{
                    hideBalanceBefore: true,
                    hideDownloadButton: true,
                    numOfRole: 4,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {location.state?.currency && (
        <div className="p-3">
          {isLoadingAccountDetails ? (
            <TextSkeleton />
          ) : (
            <div className="pt-6">
              <h3 className="glade-heading-four mb-4">Bank Account Details</h3>
              <div className="border p-8 h-60 rounded-md shadow flex flex-col gap-6 justify-center">
                <div className="grid grid-cols-4 gap-8">
                  {accountDetails?.internal?.accountHolderName && (
                    <div>
                      <h4
                        className={`font-bold glade-grey-cool uppercase pb-3`}
                      >
                        Account Name
                      </h4>
                      <div className="flex">
                        <h4 className={`glade-home-dark capitalize`}>
                          {accountDetails?.internal?.accountHolderName
                            ? accountDetails?.internal?.accountHolderName
                            : "N/A"}
                        </h4>
                        <h4>
                          <FiCopy
                            size={20}
                            className="ml-3 cursor-pointer glade-grey-dark"
                            onClick={() => {
                              copyTextToClipboard(
                                accountDetails?.internal?.accountHolderName
                              );
                              toast.success("Copied to clipboard!", {
                                position: toast.POSITION.TOP_RIGHT,
                                theme: "colored",
                              });
                            }}
                          />
                        </h4>
                      </div>
                    </div>
                  )}
                  <div>
                    <h4 className={`font-bold glade-grey-cool uppercase pb-3`}>
                      {location.state?.currency?.toLowerCase() === "gbp"
                        ? "ACCOUNT NUMBER"
                        : "IBAN"}
                    </h4>
                    <div className="flex">
                      <h4 className={`glade-home-dark capitalize`}>
                        {location.state?.currency?.toLowerCase() === "gbp" &&
                          accountDetails?.internal?.accountNumber}{" "}
                        {location.state?.currency?.toLowerCase() === "eur" &&
                          accountDetails?.internal?.iban}
                        {location.state?.currency?.toLowerCase() === "usd" &&
                          accountDetails?.swift?.iban}
                      </h4>
                      <h4>
                        <FiCopy
                          size={20}
                          className="ml-3 cursor-pointer glade-grey-dark"
                          onClick={() => {
                            location.state?.currency?.toLowerCase() === "gbp" &&
                              copyTextToClipboard(
                                accountDetails?.internal?.accountNumber
                              );
                            location.state?.currency?.toLowerCase() === "eur" &&
                              copyTextToClipboard(
                                accountDetails?.internal?.routingCodes?.iban
                              );
                            location.state?.currency?.toLowerCase() === "usd" &&
                              copyTextToClipboard(accountDetails?.swift?.iban);
                            toast.success("Copied to clipboard!", {
                              position: toast.POSITION.TOP_RIGHT,
                              theme: "colored",
                            });
                          }}
                        />
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-8">
                  <div>
                    <h4 className={`font-bold glade-grey-cool uppercase pb-3 `}>
                      {location.state?.currency?.toLowerCase() === "gbp"
                        ? "SORT CODE"
                        : "BIC"}
                    </h4>
                    <div className="flex">
                      <h4 className={`glade-home-dark capitalize`}>
                        {location.state?.currency?.toLowerCase() === "gbp" &&
                          accountDetails?.internal?.routingCodes["sort-code"]}
                        {location.state?.currency?.toLowerCase() === "eur" &&
                          accountDetails?.internal?.routingCodes?.bic}{" "}
                        {location.state?.currency?.toLowerCase() === "usd" &&
                          accountDetails?.swift?.routingCodes?.bic}
                      </h4>
                      <h4>
                        <FiCopy
                          size={20}
                          className="ml-3 cursor-pointer glade-grey-dark"
                          onClick={() => {
                            location.state?.currency?.toLowerCase() === "gbp" &&
                              copyTextToClipboard(
                                accountDetails?.internal?.routingCodes[
                                  "sort-code"
                                ]
                              );
                            location.state?.currency?.toLowerCase() === "eur" &&
                              copyTextToClipboard(
                                accountDetails?.internal?.routingCodes?.bic
                              );
                            location.state?.currency?.toLowerCase() === "usd" &&
                              copyTextToClipboard(
                                accountDetails?.swift?.routingCodes?.bic
                              );
                            toast.success("Copied to clipboard!", {
                              position: toast.POSITION.TOP_RIGHT,
                              theme: "colored",
                            });
                          }}
                        />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const AccountDetailsDisplay = ({ details }) => {
  return (
    <div className="grid sm:grid-cols-3 gap-y-4 sm:gap-x-8">
      <div>
        <div
          className={`cursor-pointer font-normal pb-3 uppercase text-xs glade-grey-cool`}
        >
          Account Name
        </div>
        <div className="flex pb-3">
          <p className="font-normal glade-home-dark capitalize">
            {details?.account_name ? details?.account_name : "Not Available"}
          </p>
          <p>
            <FiCopy
              size={20}
              className="ml-3 cursor-pointer glade-grey-dark"
              onClick={() => {
                copyTextToClipboard(details?.account_name);
                toast.success("Copied to clipboard!", {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "colored",
                });
              }}
            />
          </p>
        </div>
      </div>

      <div>
        <div
          className={`cursor-pointer font-normal pb-3 uppercase text-xs glade-grey-cool`}
        >
          Account Number
        </div>
        <div className="flex pb-3">
          <p className="font-normal glade-home-dark">
            {details?.account_number
              ? details?.account_number
              : "Not Available"}
          </p>
          <p>
            <FiCopy
              size={20}
              className="ml-3 cursor-pointer glade-grey-dark"
              onClick={() => {
                copyTextToClipboard(details?.account_number);
                toast.success("Copied to clipboard!", {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "colored",
                });
              }}
            />
          </p>
        </div>
      </div>
      <div>
        <div
          className={`cursor-pointer font-normal pb-3 glade-grey-cool uppercase text-xs`}
        >
          Bank Name
        </div>
        {details?.bank_name === "pending" ? (
          <div
            className="flex items-center justify-center p-2 rounded-3xl"
            style={{ backgroundColor: "rgba(204, 183, 0, 0.1)" }}
          >
            <span className="glade-yellow">Pending Approval</span>
          </div>
        ) : (
          <div>
            <div className="flex pb-2">
              <p
                className={`${
                  details?.bank_name === "pending"
                    ? "rounded-3xl glade-yellow h-4/12 glade-bg-blue"
                    : "font-normal glade-home-dark capitalize"
                }`}
              >
                {details?.bank_name?.length
                  ? details?.bank_name
                  : "Not Available"}
              </p>
              <p>
                <FiCopy
                  size={20}
                  className="ml-3 cursor-pointer glade-grey-dark"
                  onClick={() => {
                    copyTextToClipboard(details?.bank_name);
                    toast.success("Copied to clipboard!", {
                      position: toast.POSITION.TOP_RIGHT,
                      theme: "colored",
                    });
                  }}
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AccountDetails;
