import { ImSpinner2 } from "react-icons/im";

const LoadingLayout = () => {
  return (
    <div className="flex flex-col items-center px-6 py-12 w-full bg-white h-full">
      <div>
        <div className="mt-6">
          <span className="glade-heading-three glade-home-dark">
            Completing Transfer
          </span>
        </div>
        <div className="flex justify-center">
          <ImSpinner2
            className="mt-6 spinner text-center"
            size={70}
            color="#0B7AE0"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingLayout;