import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { MASTER_CARD, VERVE } from '../../assets/images/images';
import Button from '../../components/buttons/Button';
import NumericInput from '../../components/inputs/NumericInput';
import SelectInput from '../../components/inputs/SelectInput';
import TextInput from '../../components/inputs/TextInput';
import useVirtualCards from '../../hooks/useVirtualCards';
import { getVirtualCardCurrencies, getVirtualCardsFees } from '../../redux/virtual-cards/virtualCardActions';
import useUser from '../../hooks/useUser';
import ChargeInfo from '../../components/transfer/ChargeInfo';
import ErrorMessage from '../../validation/fund-transfer/ErrorMessage';
import RateInfo from '../../components/virtual-cards/RateInfo';
import { getConvertRate } from '../../redux/convert/convertActions';
import useConvert from '../../hooks/useConvert';

const VirtualCardCreateCardDetails = ({ formData, setStep, setFormData }) => {

  const { card_type, card_name, charge_from_currency, fund_amount, currency } = formData

  const dispatch = useDispatch();
  const user = useUser();
  const { virtualCardCurrencies } = useVirtualCards();
  const {rateDetails} = useConvert()

  const [showFilter, setShowFilter] = useState(false)
  const cardOptions = [{ logo: MASTER_CARD, cardtype: 'Mastercard' }, { logo: VERVE, cardtype: 'Verve card' }];
  const [selectedOption, setSelectedOption] = useState(formData?.currency?.toLowerCase() === 'ngn' ? cardOptions[1] : cardOptions[0]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    dispatch(getVirtualCardCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (formData?.currency?.toLowerCase() === 'ngn') {
      setFormData((prevData) => ({ ...prevData, card_type: cardOptions[1].cardtype }))
    } else {
      setFormData((prevData) => ({ ...prevData, card_type: cardOptions[0].cardtype }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formData?.currency?.toLowerCase() && formData?.charge_from_currency?.toLowerCase() && (formData?.currency?.toLowerCase() !== formData?.charge_from_currency?.toLowerCase())) {
      dispatch(getConvertRate({
        source_currency: formData?.currency?.toUpperCase(),
        destination_currency: formData?.charge_from_currency?.toUpperCase(),
        action: 'conversion'
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, charge_from_currency])

  const onChange = (e) => {
    setFormData((prevData) => ({ ...prevData, [e.target.name]: Number(e.target.value) ? Number(e.target.value).toLocaleString('en-US') : e.target.value }))
    if (e.target.name === 'fund_amount') {
      setError(false)
      if (currency === 'ngn' && (parseInt(e.target.value) + parseInt(0.2 * e.target.value || 0)) > 1000000) {

        setErrorMsg(`Maximum funding amount is 1,000,000.00 NGN`);
        setError(true);
      }
      if (currency === 'usd' && (parseInt(e.target.value) + (2 * parseInt(e.target.value / parseInt(100)) || 0)) > parseInt(60)) {
        setErrorMsg(`Maximum funding amount is 60.00 USD`);
        setError(true);
      }
      if (e.target.value < 100 && currency === 'ngn') {
        setErrorMsg(`Minimum funding amount is ${currency === 'ngn' ? '100 NGN' : '10 USD'}`);
        setError(true);
      } if (e.target.value < 10 && currency === 'usd') {
        setErrorMsg(`Minimum funding amount is ${currency === 'ngn' ? '100 NGN' : '10 USD'}`);
        setError(true);
      }
    }

  }

  return (
    <div className='p-3 mt-8'>
      <div className='px-14 space-y-6 flex flex-col lg:w-1/3 mx-auto'>
        <div>
          <TextInput
            label={"Card Currency"}
            id={"currency"}
            name={"currency"}
            type={"text"}
            value={formData.currency === 'ngn' ? "Naira" : 'Dollar'}
            onChange={onChange}
            placeholder={""}
            autoComplete={"currency"}
            className={`appearance-none rounded-md`}
            disabled
          />
        </div>

        <div>
          <label className='glade-small-text-two glade-black block'>Card Type</label>
          <div className='border flex justify-between items-center pl-3 cursor-pointer h-40' onClick={() => setShowFilter(!showFilter)}>
            {selectedOption?.cardtype ?
              <span className='flex items-center justify-center gap-2 h-40'>
                <img src={selectedOption?.logo} alt='' />
                {selectedOption?.cardtype}
              </span>
              :
              <span className='glade-grey-text'>Select card type</span>
            }
            {!showFilter ?
              <RiArrowDropDownLine size={'28px'} className="cursor-pointer mr-1" color="#000" />
              :
              <RiArrowDropUpLine size={'28px'} className="cursor-pointer mr-1" color="#000" />
            }

          </div>
          {showFilter &&
            <div className="flex flex-col gap-2 p-2 bg-white glade-grey-dark border max-h-72 overflow-y-auto">
              {
                formData.currency.toLowerCase() === 'ngn' ?
                  <div disabled onClick={() => {
                    setSelectedOption(cardOptions[1]);
                    setFormData((prevData) => ({ ...prevData, card_type: cardOptions[1]?.cardtype }));
                    setShowFilter(false)
                  }} className='cursor-pointer pt-3 px-3'>
                    <p className="flex gap-2 items-center">
                      <img src={cardOptions[1].logo} alt='logo' />
                      {cardOptions[1].cardtype}
                    </p>

                  </div> :
                  <div disabled onClick={() => {
                    setSelectedOption(cardOptions[0]);
                    setFormData((prevData) => ({ ...prevData, card_type: cardOptions[1]?.cardtype }));
                    setShowFilter(false)
                  }} className='cursor-pointer pt-3 px-3'>
                    <p className="flex gap-2 items-center">
                      <img src={cardOptions[0].logo} alt='logo' />
                      {cardOptions[0].cardtype}
                    </p>

                  </div>
              }
              {/* =========== The below code will be useful when we are allowed to use more than one card type for a currency */}
              {/* cardOptions.length > 0 ?
                    cardOptions.map((option, key) =>
                      <div key={key} onClick={() => {
                        setSelectedOption(option);
                        setFormData((prevData) => ({ ...prevData, card_type: option?.cardtype }));
                        setShowFilter(false)
                      }} className='cursor-pointer pt-3 px-3'>
                        <p className="flex gap-2 items-center">
                          <img src={option.logo} alt='logo' />
                          {option.cardtype}
                        </p>

                      </div>)
                    : <p className="text-center">List is Empty</p> */}

            </div>
          }
        </div>
        <div>
          <TextInput
            label={'Card Name'}
            key='card_name'
            id={"card_name"}
            name={'card_name'}
            type={'text'}
            value={formData.card_name}
            onChange={(e) => onChange(e)}
            placeholder={'Give your card name'}
            autoComplete={"card_name"}
            className={"appearance-none"}
          />
        </div>
        <div>
          <SelectInput
            label={'Select Balance to Fund From'}
            id={'charge_from_currency'}
            name={'charge_from_currency'}
            value={formData.charge_from_currency}
            onChange={(e) => onChange(e)}
            autoComplete={'charge_from_currency'}
            className={'appearance-none'}>
            <option value='' disabled>Select</option>
            {user.singleBusinessDetails?.accounts?.map((item, key) => key <= 2 && (
              <option key={key} value={item?.currency}>
                {item?.currency}{" Balance - "}
                {Number(item?.balance).toLocaleString(
                  "en-us"
                )}{" "}
                {item?.currency}</option>
            )
            )}
          </SelectInput>
        </div>
        <div>
          <NumericInput
            leftSection={<span className="self-center border border-gray-300 rounded-sm p-2">{currency?.toUpperCase() ?? 'NGN'}</span>}
            label={'Fund Card with'}
            id={"fund_amount"}
            name={'fund_amount'}
            type={'number'}
            min={'0'}
            value={formData.fund_amount}
            onChange={(e) => onChange(e)}
            placeholder={'Enter amount'}
            autoComplete={"fund_amount"}
            className={"appearance-none"}
          />
          {error && <ErrorMessage msg={errorMsg || "Input Error"} />}
        </div>
        
        {(formData?.currency?.toLowerCase() && formData?.charge_from_currency?.toLowerCase() && (formData?.currency?.toLowerCase() !== formData?.charge_from_currency?.toLowerCase())) &&
          <RateInfo debitcurrency={formData.charge_from_currency} receivecurrency={formData?.currency} amount={formData?.fund_amount} rate={rateDetails?.data?.rate} />
        }
        <ChargeInfo transferCharge={''} info={`You will be charged a 2% + ${currency?.toLowerCase() === 'naira' || currency?.toLowerCase() === 'ngn' ? '1,000.00 NGN' : '1 USD'} for this card`} />
        <div className="pt-3 flex justify-center">
          <Button.Blue
            title={'Continue'}
            type='button'
            onClick={() => { setStep(3) }}
            className='py-3'
            disabled={!card_type || !card_name || !charge_from_currency || !fund_amount || !currency}
          />
        </div>
      </div>
    </div>
  )
}

export default VirtualCardCreateCardDetails