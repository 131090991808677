import React from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import {statusEnum, toTitleCase, simpleDate, isEmpty, formatJsonToString} from '../../helpers/helper';
import usePaymentFlows from "../../hooks/usePaymentFlows";
import useUser from "../../hooks/useUser";
import { getSpecificPaymentFlow } from "../../redux/payment-inflow/paymentInFlowActions";
import { TextSkeleton } from "../../components/skeletons";
import {downloadPaymentInflowReceiptAsPDF} from "./DownloadPaymentInflowReceiptAsPDF";
import useTeam from "../../hooks/useTeam";

export default function PaymentInflowDetail() {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useUser();
  const paymentFlow = usePaymentFlows();
  const {permissions} = useTeam();

  const dataForPaymentInflowReceiptAsPDF = {
    amount: `${location?.state?.currency} ${Number(location?.state?.value).toLocaleString()}`,
    payment_method: toTitleCase(location?.state?.payment_type ?? ''),
    txn_ref: location?.state?.txn_ref,
    date: simpleDate(location?.state?.created_at),
    merchant_name: user?.businessInfo?.business_name,
    merchant_email: user?.businessInfo?.business_email,
    merchant_phone: user?.accountInfo?.account_number,
  }

  React.useEffect(() => {
    if (!isEmpty(location?.state?.txn_ref)) {
      dispatch(getSpecificPaymentFlow({id:location?.state?.txn_ref}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Payment Inflow"} path={!permissions?.includes("payment inflow") ? `/dashboard/payment-inflow?tab=${location?.state?.inflow_currency==='NGN'?0:1}` : `/teams/payment-inflow?tab=${location?.state?.inflow_currency==='NGN'?0:1}`} />
        </div>

        <div className="flex justify-between mt-10">
          <BasicIndicator
            className="px-5 py-1 capitalize"
            type={statusEnum[location?.state?.txn_status]}
            title={location?.state?.txn_status}
          />
          <div className="flex items-center space-x-3">
            <Button.Blue
              title="Download Receipt"
              onClick={() => downloadPaymentInflowReceiptAsPDF(dataForPaymentInflowReceiptAsPDF)}
              disabled={paymentFlow.isLoading}
            />
          </div>
        </div>

        <div className="flex flex-col bg-white rounded-sm p-3 my-6">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">Transaction Details</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-y-3 md:gap-4 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Transaction Date</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {simpleDate(location?.state?.created_at)}
              </h6>
            </div>

            <div className="md:col-span-2 flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Transaction ID</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.txn_ref}
              </h6>
            </div>

            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {location?.state?.currency} {Number(location?.state?.value).toLocaleString()}
              </h6>
            </div>

            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Channel</h5>
              <h6 className="glade-normal-text-three glade-black capitalize py-1">
                {toTitleCase(location?.state?.txn_source ?? '')}
              </h6>
            </div>

            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Payment Type</h5>
              <h6 className="glade-normal-text-three glade-black capitalize py-1">
                {toTitleCase(location?.state?.payment_type ?? '')}
              </h6>
            </div>
          </div>
        </div>

        <div>
          <div className="flex justify-end mb-3">
            <div>
              <Button.Blue
                title="View Customer"
                onClick={() => history.push({pathname:`${!permissions?.includes("customers") ? '/dashboard/customers/customer-detail' : '/teams/customers/customer-detail'}/${location?.state?.user_email}`, state:{user_email: location?.state?.user_email }}) }
                disabled={paymentFlow.isLoading}
              />
            </div>
          </div>
          <div className="flex flex-col bg-white rounded-sm p-3 my-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
              <h3 className="glade-heading-four glade-black">Customer Details</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-4 py-3">
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Customer Name</h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {location?.state?.user_name}
                </h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Customer Email</h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {location?.state?.user_email}
                </h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Customer Phone Number</h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {location?.state?.user_phone ? location?.state?.user_phone : "N/A" }
                </h6>
              </div>
            </div>
          </div>
        </div>

       {location?.state?.payment_type === 'card' && <div className="flex flex-col bg-white rounded-sm p-3 my-6">
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <h3 className="glade-heading-four glade-black">Card Details</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-4 py-3">
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Card Number</h5>
              <h6 className="glade-normal-text-three glade-black py-1">
                {paymentFlow?.specificPaymentFlow?.card_no ?? <TextSkeleton />}
              </h6>
            </div>
            
          </div>
        </div> }
      </div>
    </div>
  );
}
