import { useSelector } from 'react-redux';

const useBusiness = () => {
  const business = useSelector(state => state.business);

  let {
    isLoading,
    isLoadingAddTeam,
    isLoadingEditTeam,
    isLoadingTeamDetails,
    isSubmittingBusiness,
    shouldReload,
    submitRegisteredBusiness,
    submitUnRegisteredBusiness,
    submitUnRegisteredCompany,
    businessRegistrationIndex,
    businessRegistrationCharge,
    businessCategories,
    businessTypes,
    businessCacTypes,
    businessCacTypeFee,
    selectBusinessCacType,
    draftStatus,
    teamRoles,
    draftDetails,
    editTeamMember,
    addTeam,
    teamDetails,
    teamList,
    deleteTeamMember

  } = business;

  return {
    isLoading,
    isLoadingAddTeam,
    isLoadingEditTeam,
    isLoadingTeamDetails,
    isSubmittingBusiness,
    shouldReload,
    submitRegisteredBusiness,
    submitUnRegisteredBusiness,
    submitUnRegisteredCompany,
    businessRegistrationIndex,
    businessRegistrationCharge,
    businessCategories,
    businessTypes,
    businessCacTypes,
    businessCacTypeFee,
    selectBusinessCacType,
    draftStatus,
    teamRoles,
    draftDetails,
    teamList,
    teamDetails,
    addTeam,
    editTeamMember,
    deleteTeamMember

  }
};

export default useBusiness;