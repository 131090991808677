import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { FormWizard, FormWizardSection } from '../../components/form/FormWizard';
import IndividualInput from '../../components/inputs/IndividualInput';
import useUser from "../../hooks/useUser";
import { BsChevronLeft, BsEye, BsEyeSlash } from 'react-icons/bs';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/buttons/Button';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import {
  registerUserAction,
  storeUserInformationAction,
  emailVerificationAction,
  resendEmailVerificationAction,
  resolveBvn,
  MetamapStatusVerification
} from "../../redux/auth/authActions";
import { GLADE_INFO_GRAPHICS_FIVE } from "../../assets/images/images";
import { storeUserInformationStepOneValidation, storeUserInformationStepTwoValidation } from "../../validation/auth/authValidation";
import id_logo from '../../assets/images/id_verification.png';
import metamaplogo from '../../assets/images/metamap-logo.png'

// Analytics
import mixpanel from 'mixpanel-browser';
import { getApprovedCountries } from '../../redux/countries/countriesActions';
import CountrySelectionField from './CountrySelectionField';
import { RiCloseFill } from 'react-icons/ri';
import { IoCheckmark } from 'react-icons/io5';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import NumericInput from '../../components/inputs/NumericInput';
import { isArray } from '../../helpers/helper';
import OnboardingLayout from './OnboardingLayout';
import BasicModal from '../../components/modals/BasicModal';
import { GrFormClose } from 'react-icons/gr';
import { setSeconds } from 'date-fns';
import IdUpload from './MetaMap/IdUpload';
import SelfieVerification from './MetaMap/SelfieVerification';
import SelfieVerificationStatus from './MetaMap/SelfieVerificationStatus';


export default function Register() {

  // Analytics
  if (process.env.REACT_APP_APP_ENV === 'production') {
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true });
    mixpanel.track('Register Page');
  }

  const formWizardRef = React.useRef(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useUser();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const [secondsLeft, setsecondsLeft] = useState(59);
  const [minutesLeft, setMinutesLeft] = useState(59);
  const [hideTime, setHideTime] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [showIssuesDetails, setIssuesDetails] = useState(false);

  const [input, setInput] = useState({
    user_uuid: "",
    bvn: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    preferred_phone_number: "",
    email: "",
    password: "",
    confirm_password: "",
    verification_code: "",
    passcode: "",
    confirm_passcode: "",
    id_type: "",
    id_card_front: [],
    id_card_back: [],
    // new state data inclusions
    country_name: "",
    country_code: "",
    dial_code: "",
    referral_code: "",
  });
  const [inputError, setInputError] = useState({
    first_name: false,
    last_name: false,
    phone_number: false,
    bvn: false
  });
  const [agreement, setAgreement] = useState(false);
  const [passwordField, setPasswordField] = useState('password');
  const [idUploadResponse, setIdUploadResponse] = useState("loading");

  useEffect(() => {
    let intervalCall = setInterval(() => {

      if (minutesLeft === 0 && secondsLeft === 0) {
        setHideTime(true);
      } else if (minutesLeft >= 0 && secondsLeft >= 0) {
        if (secondsLeft > 0) setsecondsLeft(secondsLeft - 1);
        if (secondsLeft === 0 && minutesLeft > 0) {
          setMinutesLeft(minutesLeft - 1);
          setsecondsLeft(59)
        }
      }

    }, 1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);

  useEffect(() => {
    if (location?.state?.tab) {
      setInput({ ...input, user_uuid: location.state.user.uuid, email: location.state.user.email });
      if (location?.state?.status) {
        setInput({...input, user_uuid: location.state.user.uuid, email: location.state.user.email,country_code:location.state.user.country_code, country_name: location.state.user.country_name  })
        setIdUploadResponse(location?.state?.status)
      }
      formWizardRef.current.goTo(location.state.tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [listOfCountries, setListOfCountries] = useState([]);
  const [isPasswordFocused, setIsFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmFocused] = useState(false);

  useEffect(() => {
    listOfCountries?.length < 1 && dispatch(getApprovedCountries({ status: 1 }, (res) => setListOfCountries(res.data.data[1])))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfCountries])

  useEffect(() => {
    if (user.token) {
      return history.push('/dashboard/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (location.state && !location?.state?.status) {
      setInput({ ...input, email: location?.state?.user?.email })
      dispatch(resendEmailVerificationAction({ email: location?.state?.user?.email }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleError = (error) => {
    console.log(error);
  }

  const onChange = (e) => {
    setInputError({ ...inputError, [e.target.name]: false })
    setInput({ ...input, [e.target.name]: e.target.value });
  };


  const registerUser = () => {
    dispatch(registerUserAction(input, (res) => {
      formWizardRef.current.next();
      setsecondsLeft(59);
      setInput({ ...input, user_uuid: res.user_uuid });
    }));    
  };
  const verifyEmail = () => {
    dispatch(emailVerificationAction(input, (data) => {
      // set data on success
      setInput({
        ...input,
        user_uuid: data?.user_uuid,
        country_code: data?.country_code,
        country_name: data?.country_name,
        dial_code: data?.dial_code
      });
      formWizardRef.current.next();
    },
      // set error on failure
      () => setDisplayError(true)
    ));
  };

  const resendEmailVerification = () => {
    dispatch(resendEmailVerificationAction(input, (data) => {
      setMinutesLeft(59);
      setSeconds(59);
      setInput({ ...input, user_uuid: data?.user_uuid?.user_uuid });
    }));
  };

  const storeUserInformationStepOne = () => {
    let inputFieldsValidation = storeUserInformationStepOneValidation(input);
    if (isArray(inputFieldsValidation)) {
      inputFieldsValidation.forEach((field) => { setInputError((prev) => ({ ...prev, [field]: true })) });
    } else {
      if (input?.country_name?.toLocaleLowerCase() === 'nigeria') {
        dispatch(resolveBvn({ ...input, owner_type: "personal" }, () => formWizardRef.current.next(), (err) => { handleError(err); }));
      } else {
        formWizardRef.current.next();
      }
    }
  };


  const finishedCallback = () => {
    formWizardRef.current.next()
  }

  const storeUserInformationStepTwo = () => {
    if (storeUserInformationStepTwoValidation(input)) {
      dispatch(storeUserInformationAction(input, () => history.push('/auth/login')));
    }
  };

  const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const oneNumberRegex = /\d/;
  const oneCapitalLetter = /[A-Z]/;
  const togglePasswordField = (text) => {
    setPasswordField(text);
  }

  const SelfieVerificationStatusCallback = (status) => {
    return status === "success" ? formWizardRef.current.next() : formWizardRef?.current?.goTo(3);
  }

  return (
    <OnboardingLayout>
      <div className="w-full max-w-md">
        <div className="w-full flex items-center justify-center">
          <div className="h-full w-full col-span-3 flex items-center justify-center">
            <div className="w-full max-w-md">
              <div className="w-full flex items-center justify-center">
                <FormWizard ref={formWizardRef}>

                  {/* Email Section */}
                  <FormWizardSection position={0}>
                    <div className="w-full max-w-md">
                      <div className="flex flex-row justify-center ">
                        <h3 className="mt-6 glade-heading-three glade-black">Create your account</h3>
                      </div>
                      <div className="mt-6 space-y-6">
                        <p>Select Country</p>
                        <CountrySelectionField
                          title={'Select'}
                          placeholder={'Search Country'}
                          defaultValue={input?.country_name}
                          onSelectionChange={(e) => setInput({ ...input, country_name: e.country_name, country_code: e.country_code, dial_code: e.dial_code })}
                          optionsList={[{
                            "country_id": 122,
                            "continent": "Africa",
                            "country_name": "Nigeria",
                            "country_code": "NGA",
                            "capital": "Abuja",
                            "dial_code": "+234",
                            "currency_name": "Nigerian Naira",
                            "currency_slug": "Nigerian Naira (NGN)",
                            "currency_code": "NGN",
                            "flag": "",
                            "has_branch": "false",
                            "status": 1
                          }]}
                        />

                        <TextInput
                          label={'Email Address'}
                          id={'email'}
                          name={'email'}
                          type={'email'}
                          value={input.email}
                          onChange={onChange}
                          placeholder={'Enter Email Address'}
                          autoComplete={'email'}
                          className={'appearance-none'}
                        />

                        <div className='relative'>
                          <TextInput
                            rightSection={passwordField === 'password' ? <BsEye className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('text')} /> : <BsEyeSlash className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('password')} />}
                            label={'Password'}
                            id={'password'}
                            name={'password'}
                            type={passwordField}
                            value={input.password}
                            onChange={onChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            placeholder={'Create Password'}
                            autoComplete={'password'}
                            className={'appearance-none relative'}
                          />

                          {isPasswordFocused &&
                            <div className='flex gap-2 pt-3 pb-2'>
                              <div className={`rounded-full h-1 w-3/12 ${input.password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                            </div>
                          }
                          {isPasswordFocused &&
                            <div>
                              <p className='glade-grey-dark flex items-center gap-2'>{input.password.length > 7 ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Password should be 8 or more characters </p>
                              <p className='glade-grey-dark flex items-center gap-2'>{oneCapitalLetter.test(input.password) ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Have at least one upper case letter</p>
                              <p className='glade-grey-dark flex items-center gap-2'>{oneNumberRegex.test(input.password) ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Have at least one number </p>
                              <p className='glade-grey-dark flex items-center gap-2'>{specialCharacterRegex.test(input.password) ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Have at least one special character {`(!@#$%^&*()_+)`}</p>

                            </div>}
                        </div>

                        <div className='relative'>
                          <TextInput
                            rightSection={passwordField === 'password' ? <BsEye className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('text')} /> : <BsEyeSlash className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('password')} />}
                            label={'Confirm Password'}
                            id={'confirm_password'}
                            name={'confirm_password'}
                            type={passwordField}
                            value={input.confirm_password}
                            onChange={onChange}
                            onFocus={() => setIsConfirmFocused(true)}
                            onBlur={() => setIsConfirmFocused(false)}
                            placeholder={'Confirm Password'}
                            autoComplete={'password'}
                            className={'appearance-none relative'}
                          />

                          {isConfirmPasswordFocused &&
                            <div className='flex gap-2 pt-3 pb-2'>
                              <div className={`rounded-full h-1 w-3/12 ${input.confirm_password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.confirm_password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.confirm_password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.confirm_password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                              <div className={`rounded-full h-1 w-3/12 ${input.confirm_password.length > 7 ? 'glade-bg-green-light' : input.password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                            </div>
                          }
                          {isConfirmPasswordFocused &&
                            <div>
                              <p className='glade-grey-dark flex items-center gap-2'>{input.password === input.confirm_password ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} {input.password === input.confirm_password ? "Password Match" : "Password does not match"}</p>

                            </div>}
                        </div>

                        <div className='flex items-center'>
                          <CheckboxInput
                            id={'agreement'}
                            type={'checkbox'}
                            checked={agreement}
                            onChange={(e) => setAgreement(!agreement)}
                          />
                          <p className="glade-home-dark pb-1">
                            {'I agree to the '}
                            <span
                              className="glade-blue cursor-pointer"
                              onClick={() => window.open('https://gladefinance.co/terms', '_blank')}
                            >
                              Terms & Conditions
                            </span>
                            {' and '}
                            <span
                              className='glade-blue cursor-pointer'
                              onClick={() => window.open('https://gladefinance.co/privacy', '_blank')}
                            >
                              Policies
                            </span>
                          </p>
                        </div>
                        <div className="pt-3 flex justify-center">
                          <Button.Blue
                            title={'Get Started'}
                            type={'button'}
                            className={'py-3'}
                            onClick={registerUser}
                            disabled={
                              user.isLoading ||
                              input.password !== input.confirm_password ||
                              !agreement ||
                              !input.country_name ||
                              !input.email ||
                              input.password.length < 8 ||
                              !oneCapitalLetter.test(input.password) ||
                              !oneNumberRegex.test(input.password) ||
                              !specialCharacterRegex.test(input.password)
                            }
                            loading={user.isLoading}
                          />
                        </div>
                        <p className="mt-2 glade-normal-text-two text-center">
                          <span className="mr-1 glade-black">Already have an account?</span>
                          <a href="/auth/login" className="glade-normal-text-two glade-blue">Sign In</a>
                        </p>
                      </div>
                    </div>
                  </FormWizardSection>

                  {/* OTP Section */}
                  <FormWizardSection position={1}>
                    <div className="w-full max-w-md">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="flex mt-6 mb-2 glade-heading-three items-center justify-center">Code Verification</h3>
                        <p className="flex mt-2 glade-subtitle-two glade-grey-dark">A code has been sent to {input.email}.</p>
                        <p className='glade-grey-dark'> Enter the code to continue your signup process.</p>
                      </div>
                      {displayError && <div className="pt-3">
                        <Button.DangerBackground
                          title={'Invalid verification code'}
                          type={'button'}
                          className={'py-3 w-full'}
                          disabled={true}
                        />
                      </div>}
                      <div className="mt-6 space-y-6">

                        <div className="mt-1">
                          <IndividualInput
                            label={''}
                            type={'text'}
                            onChange={(value) => { setDisplayError(false); setInput({ ...input, verification_code: value }) }}
                          />
                        </div>
                        <div className="pt-3 flex justify-center">
                          <Button.Blue
                            title={'Submit'}
                            type={'button'}
                            className={'py-3 pr-3 pl-3'}
                            onClick={verifyEmail}
                            disabled={user.isLoading || input?.verification_code?.length !== 6}
                            loading={user.isLoading}
                          />
                        </div>
                        <p className="mt-2 glade-normal-text-two glade-blue text-center">This code will expire in {minutesLeft}mins {`${secondsLeft < 10 ? 0 : ''}${secondsLeft}`} secs</p>

                        <div className='flex justify-between'>
                          <p className="flex glade-normal-text-two">
                            <span className="mr-1 glade-black">Didn't receive code?</span>
                            <span className="glade-normal-text-two glade-blue cursor-pointer" onClick={resendEmailVerification}>Resend Code</span>
                          </p>
                          <p className='glade-normal-text-two glade-blue cursor-pointer' onClick={() => setIssuesDetails(true)}>Still having issues?</p>
                        </div>
                      </div>
                      <BasicModal
                        visibility={showIssuesDetails}
                        onClose={() => { setIssuesDetails(false) }}
                        primaryButton={true} // Pass true or valid component to avoid default display
                        secondaryButton={true} // Pass true or valid component to avoid default display
                        modalTitleSection={
                          <div className='capitalize pb-2 border-b flex justify-between items-center '>
                            <p className="">Issues receiving OTP?</p>
                            <GrFormClose color='#9CA3AF' className='cursor-pointer' onClick={() => setIssuesDetails(false)} />
                          </div>
                        }
                        modelContentSection={<div>
                          <p className='mt-4 mb-2'>If you didn't receive an OTP in your inbox, one of these must have happened</p>
                          <ol>
                            <li className='mb-2'>1. You entered a wrong email</li>
                            <li>2. Your OTP is in your spam folder</li>
                          </ol>
                        </div>}

                      />
                    </div>
                  </FormWizardSection>

                  {/* Information Section */}
                  <FormWizardSection position={2}>
                    <div className="w-full max-w-md">
                      <div className="flex flex-col items-center">
                        {/* <div onClick={() => formWizardRef.current.prev()} className="glade-black cursor-pointer">
        <BsChevronLeft />
      </div> */}
                        <h3 className="flex mt-6 glade-heading-three glade-black">You're almost done!</h3>
                        <p className="flex mt-2 glade-subtitle-two glade-grey-dark justify-center items-center text-center">Complete your registration by providing the following information.</p>
                      </div>
                      <div className="mt-6 space-y-6">
                        {input?.country_name?.toLocaleLowerCase() === 'nigeria' &&
                          <div>
                            <div className='flex justify-between'>
                              <span className='glade-small-text-one'>BVN</span>
                              <span className="glade-small-text-one glade-blue">Dial <span className='font-bold'>*565*0#</span> to get your BVN</span>
                            </div>
                            <NumericInput
                              label={''}
                              id={'bvn'}
                              name={'bvn'}
                              type={'number'}
                              value={input.bvn}
                              onChange={onChange}
                              placeholder={'Enter BVN'}
                              autoComplete={'bvn'}
                              className={'appearance-none'}
                              errorState={inputError?.bvn}
                            />
                          </div>}
                        <TextInput
                          label={'First Name'}
                          id={'first_name'}
                          name={'first_name'}
                          type={'text'}
                          value={input.first_name}
                          onChange={onChange}
                          placeholder={'Enter First Name'}
                          autoComplete={'first_name'}
                          className={'appearance-none'}
                          errorState={inputError?.first_name}
                        />
                        <TextInput
                          label={'Last Name'}
                          id={'last_name'}
                          name={'last_name'}
                          type={'text'}
                          value={input.last_name}
                          onChange={onChange}
                          placeholder={'Enter Last Name'}
                          autoComplete={'last_name'}
                          className={'appearance-none'}
                          errorState={inputError?.last_name}
                        />
                        <NumericInput
                          leftSection={<span className="self-center glade-bg-grey border border-gray-300 rounded-sm p-2">{input?.dial_code}</span>}
                          label={'Phone Number'}
                          id={'phone_number'}
                          name={'phone_number'}
                          type={'tel'}
                          value={input.phone_number}
                          onChange={(e) => { setInputError({ ...inputError, phone_number: false }); setInput({ ...input, phone_number: e.target.value, preferred_phone_number: e.target.value }) }}
                          placeholder={'Enter Phone Number'}
                          autoComplete={'phone_number'}
                          className={'appearance-none'}
                          errorState={inputError?.phone_number}
                        />
                        <div className="pt-3 flex justify-center">
                          <Button.Blue
                            title={'Continue'}
                            type={'button'}
                            className={'py-3'}
                            onClick={storeUserInformationStepOne}
                            disabled={user.isLoading}
                            loading={user.isLoading}
                          />
                        </div>
                        {/* <p className="mt-2 flex justify-center glade-normal-text-two">
      <span className="mr-1 glade-black">Already have an account?</span>
      <a href="/auth/login" className="glade-normal-text-two glade-blue">Sign In</a>
    </p> */}
                      </div>
                    </div>
                  </FormWizardSection>

                  {/* {ID Uplaod} */}
                  <FormWizardSection position={3}>
                    <IdUpload input={input} setInput={setInput} finishedCallback={finishedCallback} />
                  </FormWizardSection>


                  {/* {SelfieVerification} */}
                  <FormWizardSection position={4}>
                    <SelfieVerification input={input} finishedCallback={finishedCallback} setIdUploadResponse={setIdUploadResponse} formWizardRef={formWizardRef} />
                  </FormWizardSection>

                  {/* {SelfieVerification} */}
                  <FormWizardSection position={5}>
                    <div>
                      <SelfieVerificationStatus
                        input={input}
                        status={idUploadResponse}
                        setIdUploadResponse={setIdUploadResponse}
                        SelfieVerificationStatusCallback={() => SelfieVerificationStatusCallback(idUploadResponse)}
                      />
                    </div>
                  </FormWizardSection>


                  {/* Pass Code Section */}
                  <FormWizardSection position={6}>
                    <div className="w-full max-w-sm">
                      <div className="flex flex-col justify-center">

                        <h3 className="flex justify-center text-center mt-3 glade-heading-three glade-black">Create Pin</h3>
                        <p className="flex justify-center text-center mt-2 glade-subtitle-two glade-black">Choose your preferred 4 digit PIN.</p>
                      </div>
                      <div className="mt-6 space-y-10">
                        <div className="flex flex-row justify-center">
                          <IndividualInput
                            label={'Enter PIN'}
                            numberOfInputTags={4}
                            onChange={(value) => setInput({ ...input, passcode: value })}
                          />
                        </div>
                        <div className="flex flex-row justify-center">
                          <IndividualInput
                            label={'Confirm PIN'}
                            numberOfInputTags={4}
                            onChange={(value) => setInput({ ...input, confirm_passcode: value })}
                          />
                        </div>
                        <div className="flex flex-row justify-center pt-3">
                          <Button.Blue
                            title={'Submit'}
                            type={'button'}
                            className={'py-3 px-10'}
                            onClick={storeUserInformationStepTwo}
                            disabled={user.isLoading}
                            loading={user.isLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </FormWizardSection>

                </FormWizard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  )
}
