import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getPersonalAndBusinessAccounts,
  loginUserAction,
  MetamapStatusVerification,
  toggleAccountAction,
} from "../../redux/auth/authActions";
import useUser from "../../hooks/useUser";
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/buttons/Button";
import UAParser from "../../helpers/user-agent-parser";

// Analytics
import mixpanel from "mixpanel-browser";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useState } from "react";
import useQuery from "../../hooks/useQuery";
import { isEmptyObject } from "../../helpers/helper";
import { getOneBusinessDetails } from "../../redux/home/homeActions";
import OnboardingLayout from "./OnboardingLayout";
import LoginAccessModal from "./LoginAccessModal";
import LogoutSuccessModal from "./LogoutSuccessModal";
import OtploginAccess from "./OtploginAccess";

export default function Login() {
  // Analytics
  if (process.env.REACT_APP_APP_ENV === "production") {
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true });
    mixpanel.track("Login Page");
  }

  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const userAgent = UAParser(window.navigator.userAgent);
  const loginAccessModalRef = React.useRef();
  const logoutSuccessModalRef = React.useRef();

  const [input, setInput] = useState({
    email: "",
    password: "",
    meta: {
      browser: userAgent.browser.name,
      platform: userAgent.os.name,
    },
  });
  const [passwordField, setPasswordField] = useState("password");
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Oops! Something Happened.");
  const [loginAccessError, setLoginAccessError]= useState(null)

  useEffect(() => {
    if (user.token) {
      // Check query parameter to redirect to compliance
      if (query.get("personal")) {
        return history.push("/dashboard/home/personal-compliance");
      } else if (query.get("business")) {
        let businessEmail = query.get("business");

        const onFailure = () => history.push("/dashboard/home");
        const onSuccess = (res) => {
          let { business_accounts } = res;
          let business_data = business_accounts?.find(
            (business) => business?.business_email === businessEmail
          );
          if (!isEmptyObject(business_data)) {
            let goto = () => {
              dispatch(
                getOneBusinessDetails(() =>
                  history.push(`/dashboard/home/business-compliance`)
                )
              );
              return;
            };
            dispatch(
              toggleAccountAction({ ...business_data, is_business: true }, goto)
            );
          } else {
            return history.push("/dashboard/home");
          }
        };

        dispatch(getPersonalAndBusinessAccounts(onSuccess, onFailure));
      } else return history.push("/dashboard/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.token]);

  const onChange = (e) => {
    setDisplayError(false);
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const onIncompleteDetails = (res) => {
    if (res.data.data.is_email_verified === false) {
      history.push({
        pathname: "/auth/register",
        state: { ...res.data.data, tab: 1 },
      });
    } else if (
      res.data.data.user.country_name === "Nigeria" &&
      res.data.data.is_bvn_matched === false
    ) {
      history.push({
        pathname: "/auth/register",
        state: { ...res.data.data, tab: 1 },
      });
    } else if (
      res.data.data.user.country_name === "Nigeria" &&
      res.data.data.user.bvn === null
    ) {
      history.push({
        pathname: "/auth/register",
        state: { ...res.data.data, tab: 1 },
      });
    } else if (res.data.data.is_email_verified === true && (res.data.data.user.country_name === "Nigeria" && res.data.data.is_bvn_matched === true) && res.data.data.has_passcode === false) {
      handleSubmit()
    }
  };

  const togglePasswordField = (text) => {
    setPasswordField(text);
  };

  const onSuccessStatus = (res) => {
    if (res.status === 'verified') {
      history.push({
        pathname: "/auth/register",
        state: { user: { uuid: res.user_info.user_uuid, email: input?.email, country_code: res.user_info.country_code, country_name: res.user_info.country_name }, tab: 5, status: 'success' },
      });
    } else if (res.status === 'pending') {
      history.push({
        pathname: "/auth/register",
        state: { user: { uuid: res.user_info.user_uuid, email: input?.email, country_code: res.user_info.country_code, country_name: res.user_info.country_name }, tab: 5, status: 'loading' },
      });
    }
    else if (res.status === 'reviewNeeded') {
      history.push({
        pathname: "/auth/register",
        state: { user: { uuid: res.user_info.user_uuid, email: input?.email, country_code: res.user_info.country_code, country_name: res.user_info.country_name }, tab: 5, status: 'reviewNeeded' },
      });
    } 
    else {
      history.push({
        pathname: "/auth/register",
        state: { user: { uuid: res.user_info.user_uuid, email: input?.email, country_code: res.user_info.country_code, country_name: res.user_info.country_name }, tab: 5, status: 'fail' },
      });
    }
  }



  const handleSubmit = () => {
    dispatch(MetamapStatusVerification(input?.email, onSuccessStatus));

  }

  const onSubmit = () => {
    const onFail = (error) => {
      let err = { error };
      if (
        err?.error?.response?.data?.message &&
        err?.error?.response?.data?.message === "Invalid credentials"
      ) {
        setErrorMessage("Incorrect login details. Please try again!");
      } else if (err?.error?.response?.data?.message?.message === "Duplicate session detected, kindly terminate previous session."){
        setLoginAccessError(err?.error?.response?.data?.message?.meta)
        loginAccessModalRef.current.open()
      } 
      else if (err?.error?.response?.data?.message) {
        setErrorMessage(err?.error?.response?.data?.message);
      } else {
        setErrorMessage(err.error.message);

      }
      setDisplayError(true);
    };
    setDisplayError(false);
    dispatch(loginUserAction(input, onIncompleteDetails, onFail));
  };

  return (
    <OnboardingLayout>
      <div className="w-full max-w-md">
        <div className="flex flex-row ">
          <h3 className="mt-6 glade-heading-three glade-black mx-auto">
            Sign in to your account
          </h3>
        </div>
        <div className="relative mt-6 space-y-6">
          {/* {displayError && (
            <div className="pt-3">
              <Button.DangerBackground
                title={errorMessage}
                type={"button"}
                className={"py-3"}
                disabled={true}
              />
            </div>
          )} */}
          <TextInput
            label={"Email"}
            id={"email"}
            name={"email"}
            type={"email"}
            value={input.email}
            onChange={onChange}
            placeholder={"example@email.com"}
            autoComplete={"email"}
            className={"appearance-none"}
          />

          <TextInput
            rightSection={
              passwordField === "password" ? (
                <BsEye
                  className="self-center rounded-sm absolute right-5 cursor-pointer"
                  onClick={() => togglePasswordField("text")}
                />
              ) : (
                <BsEyeSlash
                  className="self-center rounded-sm absolute right-5 cursor-pointer"
                  onClick={() => togglePasswordField("password")}
                />
              )
            }
            label={"Password"}
            id={"password"}
            name={"password"}
            type={passwordField}
            value={input.password}
            onChange={onChange}
            placeholder={"********"}
            autoComplete={"password"}
            className={"appearance-none relative"}
          />

          <p className="mt-2 glade-normal-text-two flex justify-between">
            <a
              href="/auth/reset-password"
              className="glade-normal-text-two glade-blue"
            >
              Forgot your password?
            </a>
            <a
              href="/team/login"
              className="glade-normal-text-two glade-blue"
            >
              Business Team?
            </a>
          </p>

          <div className="pt-5 pb-5 text-center">
            <Button.Blue
              title={"Sign In"}
              type={"submit"}
              className={"py-3 mx-auto px-8"}
              onClick={onSubmit}
              disabled={
                user.isLoading || !input?.email || !input?.password
              }
              loading={user.isLoading}
            />
          </div>

          <div className="mt-4 glade-normal-text-two flex justify-between">
            <span className="mr-1 glade-black">
              Don’t have an account?
            </span>
            <a
              href="/auth/register"
              className="glade-normal-text-two glade-blue"
            >
              Create Account
            </a>
          </div>

        </div>
          <LoginAccessModal ref={loginAccessModalRef} input={input} errorMessage={loginAccessError}
        logoutSuccessModalRef={logoutSuccessModalRef}
      />
      <LogoutSuccessModal ref={logoutSuccessModalRef} errorMessage={loginAccessError}/>

      </div>
    </OnboardingLayout>
  );
}
