import {
  LOADING_HOME,
  RELOAD_HOME,
  GET_BUSINESS_INFLOW,
  GET_BUSINESS_OUTFLOW,
  GET_BUSINESS_INFLOW_OUTFLOW,
  GET_PERSONAL_INFLOW,
  GET_PERSONAL_OUTFLOW,
  GET_PERSONAL_INFLOW_OUTFLOW,
  GET_CARDS,
  GET_BUSINESS_TRANSACTIONS,
  GET_PERSONAL_TRANSACTIONS,
  GET_STATES_BY_COUNTRYID,
  GET_LGAS_BY_STATEID,
  ONE_BUSINESS_DETAILS_SUCCESS,
  LOADING_REQUEST_REVOKE_SESSION,
  LOADING_REVOKE_SESSION,
  REQUEST_REVOKE_SESSION_SUCCESS,
  REQUEST_REVOKE_SESSION_FAILURE,
 REVOKE_SESSION_SUCCESS,
  REVOKE_SESSION_FAILURE,
} from "./types";

const initialState = {
  isLoading:false,
  shouldReload: false,
  loadingRequestRevoke:false,
  loadingRevokeSession:false,
  businessInflow: {},
  businessOutflow: {},
  businessInflowOutflow: {},
  personalInflow: {},
  personalOutflow: {},
  personalInflowOutflow: {},
  cards: [],
  businessTransactions: [],
  personalTransactions: [],
  statesByCountryId: [],
  oneBusinessAccount: {},
  requestRevoke: {},
  revokeSession: {}
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_HOME:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case RELOAD_HOME:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case GET_BUSINESS_INFLOW:
      return {
        ...state,
        businessInflow: action.payLoad.data.data,
      };

    case GET_BUSINESS_OUTFLOW:
      return {
        ...state,
        businessOutflow: action.payLoad.data.data,
      };

    case GET_BUSINESS_INFLOW_OUTFLOW:
      return {
        ...state,
        businessInflowOutflow: action.payLoad.data.data,
      };

    case GET_PERSONAL_INFLOW:
      return {
        ...state,
        personalInflow: action.payLoad.data.data,
      };

    case GET_PERSONAL_OUTFLOW:
      return {
        ...state,
        personalOutflow: action.payLoad.data.data,
      };

    case GET_PERSONAL_INFLOW_OUTFLOW:
      return {
        ...state,
        personalInflowOutflow: action.payLoad.data.data,
      };

    case GET_CARDS:
      return {
        ...state,
        cards: action.payLoad.data.data,
      };

    case GET_BUSINESS_TRANSACTIONS:
      return {
        ...state,
        businessTransactions: action.payLoad.data.data,
      };

    case GET_PERSONAL_TRANSACTIONS:
      return {
        ...state,
        personalTransactions: action.payLoad.data.data,
      };
    
      case GET_STATES_BY_COUNTRYID:
      return {
        ...state,
        statesByCountryId: action.payLoad.data,
      };
    
      case GET_LGAS_BY_STATEID:
      return {
        ...state,
        lgasByStateId: action.payLoad.data,
      };

      case ONE_BUSINESS_DETAILS_SUCCESS:
        return {
          ...state,
          oneBusinessAccount: action.payLoad.data,
        };

        case LOADING_REQUEST_REVOKE_SESSION:
          return {
            ...state,
            loadingRequestRevoke: action.payLoad,
          };

        case  REQUEST_REVOKE_SESSION_SUCCESS:
        return {
          ...state,
          loadingRequestRevoke: false,
          requestRevoke: action?.payLoad?.data,
         
        };
      case  REQUEST_REVOKE_SESSION_FAILURE:
        return {
          ...state,
          loadingRequestRevoke: false,
        };

        case LOADING_REVOKE_SESSION:
          return {
            ...state,
            loadingRevokeSession: action.payLoad,
          };

        case REVOKE_SESSION_SUCCESS:
        return {
          ...state,
          loadingRevokeSession: false,
         revokeSession: action?.payLoad?.data.data,
         
        };
      case REVOKE_SESSION_FAILURE:
        return {
          ...state,
          loadingRevokeSession: false,
        };

    default:
      return state;
  }
}
