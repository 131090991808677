import React, { useState, useEffect } from "react";
import PlainModal from "../../components/modals/PlainModal";

import { IoCheckmarkCircle } from "react-icons/io5";
import { RiCloseCircleFill } from "react-icons/ri";
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/buttons/Button";

import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";
import {
  CheckUserTagAvailability,
  createUserTag,
} from "../../redux/auth/authActions";
import { getUserDetails } from "../../redux/settings/settingsActions";

export default React.forwardRef(function CreateUserTag(props, ref) {
  const dispatch = useDispatch();
  const user = useUser();
 
  // Ref Handler
  const plainModalRef = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open: () => plainModalRef.current.open(),
    close: () => plainModalRef.current.close(),
    toggle: () => plainModalRef.current.toggle(),
  }));

  const [input, setInput] = useState({
    user_tag: "",
  });

  const [userTagAvailability, setUserTagAvailability] = useState(""); 
  const lettersOrNumbersRegex =  /^(?=.*[a-zA-Z])[a-zA-Z0-9]*$/;

  const onSuccess = (res ) => {
      setUserTagAvailability("This user tag is currently available.");
    
  };


  const onFailure = ( error) => {
    let usertag_unavailable = error?.error?.response?.data?.message;
  
     if(usertag_unavailable === "This user tag is currently unavailable, try another one") {
      setUserTagAvailability(
       usertag_unavailable
      );
    }else{
        setUserTagAvailability('')
    }
  };

  const onChange = (e) => {
    
    setInput({ ...input, [e.target.name]: e.target.value });
   
   
  };

 useEffect(() =>{
  if (input?.user_tag?.length > 5) {
    dispatch(
      CheckUserTagAvailability({ user_tag: input?.user_tag }, onSuccess, onFailure)
    );
  }
 }, [input?.user_tag])

  const disabled =
  input?.user_tag?.length < 6 ||
   input?.user_tag?.length > 8 ||
    !lettersOrNumbersRegex.test(input?.user_tag) ||
    userTagAvailability.includes("unavailable");
;


    const callBack = () => {
      dispatch(getUserDetails())
      plainModalRef.current.close()
      props.CreateTagSuccessModalRef.current.open()
    }

    const handleCloseModal = () => {
      setInput({ user_tag: "" });
    };

  const onSubmit = () => {
 
    dispatch(createUserTag(input, () => callBack()));
  };

  return (
    <PlainModal
      ref={plainModalRef}
      onClose={handleCloseModal}
      primaryButton={<React.Fragment></React.Fragment>}
      modalTitle={"Create User Tag"}
      modelContentSection={
        <div className="flex flex-col">
          <div className="flex flex-col p-6  text-left ">
            <TextInput
              label={"User Tag"}
              id={"user_tag"}
              name={"user_tag"}
              type={"text"}
              value={input?.user_tag}
              onChange={onChange}
              placeholder={"Enter Your user tag"}
              autoComplete={"user_tag"}
              className={"appearance-none"}
            />

            {userTagAvailability && input?.user_tag?.length > 5 && (
              <p
                className={` pt-2 ${
                  userTagAvailability.includes("unavailable")
                    ? "glade-form-fail"
                    : "glade-form-mark"
                }`}
              >
                {userTagAvailability}
              </p>
            )}

          
              <div className="pt-5">
                <p className="glade-grey-dark flex items-center gap-2">
                  {input?.user_tag?.length >= 6 && input?.user_tag?.length <= 8? (
                    <IoCheckmarkCircle className="glade-form-mark" />
                  ) : (
                    <RiCloseCircleFill className="glade-form-fail" />
                  )}{" "}
                Must be between 6 to 8 characters{" "}
                </p>
              
                <p className="glade-grey-dark flex items-center gap-2 pt-2">
                  {lettersOrNumbersRegex.test(input?.user_tag) ? (
                    <IoCheckmarkCircle className="glade-form-mark" />
                  ) : (
                    <RiCloseCircleFill className="glade-form-fail" />
                  )}
                  May contain only alphabets or a mix with numbers{" "}
                </p>
              </div>
         
          </div>

          <div className="flex justify-center items-center pb-5">
            <Button.Blue
              title={"Continue"}
              disabled={disabled}
              loading={user.isLoading}
              onClick={() => onSubmit()}
            />
          </div>

        </div>
      }
    />
  );
});
