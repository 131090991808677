
    import React from "react";
    import PlainModal from "../../components/modals/PlainModal";
    import { IoCheckmark } from "react-icons/io5";
    
    
    export default React.forwardRef(function LogoutSuccessModal(props, ref) {
      // Ref Handler
      const plainModalRef = React.useRef();
      React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
      }));
    

    
      return (
        <PlainModal
          ref={plainModalRef}
          primaryButton={<React.Fragment></React.Fragment>}
          modalTitleSection={""}
          modelContentSection={
            <div className="flex flex-col">
              <div className="py-6 px-6">
                <div className="my-3 flex justify-center">
                  <IoCheckmark
                    className="bg-green-100 rounded-full p-2 "
                    color={"#5CDB5C"}
                    size={"4em"}
                  />
                </div>
                <div className="my-9">
                  <div>
                    <h2 className="glade-black my-3  pb-2 font-bold">Successful</h2>
                  </div>
                  <div>
                    <p className="glade-normal-text-two l">
                    You have successfully logged out of {props.errorMessage?.platform}
                    </p>
                  </div>
                </div>
                
              </div>
             
            </div>
          }
        />
      );
    });
    