import React from "react";

/**
 * Render a placeholder loading skeleton for texts
 * @param {string} width
 * @param {string} className
 * @returns {node}
 */
export const TextSkeleton = ({ className = "", width }) => {
  return (
    <div
      style={{width: width ?? "100%"}}
      className={
        className+" glade-subtitle-two glade-black w-3/5 bg-gray-300 animate-pulse transform rounded-md h-6"
      }
    />
  );
};
