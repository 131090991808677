import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardPlainWithButton, CardPlainWithButtonSection } from "../../components/cards/CardPlainWithButton";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import useBusiness from "../../hooks/useBusiness";
import useSettings from '../../hooks/useSettings';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { getComplianceCountries, getComplianceStates, getComplianceLga } from '../../redux/settings/settingsActions';
import { getBusinessCategories, submitRegisteredBusiness } from '../../redux/business/businessActions';
import { isArray } from "../../helpers/helper";
import { validateNairaInputObject } from "../../helpers/validator";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import errorHandler from "../../handlers/errorHandler";
import NumericInput from "../../components/inputs/NumericInput";


export default function CreateBusiness() {

  const history = useHistory();
  const dispatch = useDispatch();
  const business = useBusiness();
  const settings = useSettings();

  const [input, setInput] = useState({
    business_category: "",
    business_name: "",
    business_email: "",
    business_description: "",
    business_address: "",
    business_website: "",
    business_country: '',
    business_state: '',
    business_lga: '',
    contact_phone: ''
  });
  const [inputError, setInputError] = useState({
    business_category: false,
    business_name: false,
    business_email: false,
    business_description: false,
    business_address: false,
    business_website: false,
    business_country: false,
    business_state: false,
    business_lga: false,
    contact_phone: false
  })
  useEffect(() => {
    dispatch(getBusinessCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    //Naira Business Account
    let country = settings.complianceCountries?.find(country => country.country_id?.toString() === input.business_country);
    let state = settings.complianceStates?.find(state => state.state_id?.toString() === input.business_state);
    let lga = settings.complianceLga?.find(lga => lga.city_id?.toString() === input.business_lga);
    let validator = validateNairaInputObject(input, setInputError);
    if (!validator) {
      dispatch(submitRegisteredBusiness({
        ...input,
        country: country?.country_name,
        state: state?.state_name,
        lga: lga?.city_name,
      }, () => { history.push('/dashboard/home'); dispatch(getPersonalAndBusinessAccounts()) }));
    } else {
      errorHandler({ response: { data: { message: 'Invalid Field(s)' } } }, true)
    }
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputError({ ...inputError, [e.target.name]: false })
  };

  useEffect(() => {
    (!input.business_country) && dispatch(getComplianceCountries());
    (input.business_country) && dispatch(getComplianceStates({ country_id: input.business_country }));
    (input.business_state) && dispatch(getComplianceLga({ state_id: input.business_state }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.business_country, input.business_state]);

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Home"} path={"/dashboard/home"} />
        </div>
        <CardPlainWithButton>
          <CardPlainWithButtonSection
            title={"Add Business Account"}
            loading={business.isSubmittingBusiness}
            buttonTitle={"Create Account"}
            buttonOnClick={() => onSubmit()}
            buttonDisabled={business.isSubmittingBusiness}>
            <div className="my-6 px-14">
              <div className="space-y-6">

                <SelectInput
                  label={'Select Business Industry'}
                  id={'business_category'}
                  name={'business_category'}
                  value={input.business_category ?? ''}
                  onChange={onChange}
                  autoComplete={'business_category'}
                  className={'appearance-none'}
                  errorState={inputError?.business_category}>
                  <option value={''} disabled={true}>Select Industry</option>
                  {isArray(business?.businessCategories) && business?.businessCategories.map((item, key) => {
                    return (
                      <option key={key} value={item}>{item}</option>
                    );
                  })}
                </SelectInput>

                <TextInput
                  label={'Business Name'}
                  id={'business_name'}
                  name={'business_name'}
                  type={'text'}
                  value={input.business_name}
                  onChange={onChange}
                  placeholder={'Enter registered business name'}
                  autoComplete={'business_name'}
                  className={'appearance-none'}
                  errorState={inputError?.business_name}
                />

                <TextInput
                  label={'Business Email'}
                  id={'business_email'}
                  name={'business_email'}
                  type={'text'}
                  value={input.business_email}
                  onChange={onChange}
                  placeholder={'Enter registered business email'}
                  autoComplete={'business_email'}
                  className={'appearance-none'}
                  errorState={inputError?.business_email}
                />

                <NumericInput
                  label={'Business phone number'}
                  id={'contact_phone'}
                  name={'contact_phone'}
                  type={'number'}
                  value={input.contact_phone}
                  onChange={onChange}
                  placeholder={'Enter business phone number'}
                  autoComplete={'contact_phone'}
                  className={'appearance-none'}
                  errorState={inputError?.contact_phone}
                />

                <TextAreaInput
                  label={'Business Description'}
                  id={'business_description'}
                  name={'business_description'}
                  rows={3}
                  type={'text'}
                  value={input.business_description}
                  onChange={onChange}
                  placeholder={'What does your business do?'}
                  autoComplete={'business_description'}
                  className={'appearance-none'}
                  errorState={inputError?.business_description}
                />

                <TextInput
                  label={'Business Address'}
                  id={'business_address'}
                  name={'business_address'}
                  type={'text'}
                  value={input.business_address}
                  onChange={onChange}
                  placeholder={'Enter business address'}
                  autoComplete={'business_address'}
                  className={'appearance-none'}
                  errorState={inputError?.business_address}
                />

                <SelectInput
                  label={'Country'}
                  id={"business_country"}
                  name={'business_country'}
                  value={input.business_country ?? ''}
                  onChange={onChange}
                  autoComplete={"business_country"}
                  className={"appearance-none"}
                  errorState={inputError?.business_country}
                >
                  <option value={''} disabled={true}>Select A Country</option>
                  {isArray(settings.complianceCountries) && settings.complianceCountries?.map((country, index) => (
                    <option key={index} value={country.country_id}>{country.country_name}</option>
                  ))}
                </SelectInput>

                <SelectInput
                  label={'State'}
                  id={"business_state"}
                  name={'business_state'}
                  value={input.business_state ?? ''}
                  onChange={onChange}
                  autoComplete={"business_state"}
                  className={"appearance-none"}
                  errorState={inputError?.business_state}
                >
                  <option value={''} disabled={true}>Select A State</option>
                  {isArray(settings.complianceStates) && settings.complianceStates?.map((state, index) => (
                    <option key={index} value={state.state_id}>{state.state_name}</option>
                  ))}
                </SelectInput>

                <SelectInput
                  label={'Local Government Area'}
                  id={"business_lga"}
                  name={'business_lga'}
                  value={input.business_lga ?? ''}
                  onChange={onChange}
                  autoComplete={"business_lga"}
                  className={"appearance-none"}
                  errorState={inputError?.business_lga}
                >
                  <option value={''} disabled={true}>Select A Local Government Area</option>
                  {isArray(settings.complianceLga) && settings.complianceLga?.map((lga, index) => (
                    <option key={index} value={lga.city_id}>{lga.city_name}</option>
                  ))}
                </SelectInput>

                <TextInput
                  label={'Business Website'}
                  id={'business_website'}
                  name={'business_website'}
                  type={'text'}
                  value={input.business_website}
                  onChange={onChange}
                  placeholder={'Enter business website'}
                  autoComplete={'business_website'}
                  className={'appearance-none'}
                  errorState={inputError?.business_website}
                />

              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  );
}