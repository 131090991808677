import React, { useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaFontAwesomeFlag } from 'react-icons/fa';
import { SideNavContext } from '../../context/SideNavContext';
import useScreenSize from '../../hooks/useScreenSize';

export default function SidebarLink({ path, name, icon, linkClassName, linkStyle, listClassName, listStyle, onClick }) {

    const { isMobile, size } = useScreenSize();


    // Check if component is hovered
    const [isHovered, setIsHovered] = React.useState(false);

    // Check if link is the current page
    const [current, setCurrent] = React.useState(false);
    const pageLink = window.location.href;

    const { setNavCollapsed, closeNav } = useContext(SideNavContext);

    useEffect(() => {
        if (size.innerWidth < 720) {
            closeNav()
        }
    }, [])

    useEffect(() => {
        let currentPath = window.location.href.split("/").slice(-1).pop();
        let selectedPath = path ? path.split("/").slice(-1).pop() : '';
        currentPath === selectedPath ? setCurrent(true) : setCurrent(false);
    }, [path, pageLink]);

    const toCollapseSidebar = () => {
        if (isMobile) {
            setNavCollapsed(open => !open);
        }
    };

    return (
        <li className={"items-center " + listClassName} style={listStyle} onClick={toCollapseSidebar}>
            <Link
                className={"glade-normal-text-two py-3 block " + linkClassName}
                style={linkStyle}
                to={path}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                {!current ?
                    <span className="flex items-center">
                        <span className={"glade-normal-text-two inline-flex"} style={{ color: isHovered ? '#FFD2BC' : '#717485' }}>{icon ? React.cloneElement(icon, { color: isHovered ? '#FFD2BC' : '#717485' }) : <FaFontAwesomeFlag />}</span>
                        <span className={"glade-normal-text-two inline-flex ml-3"} style={{ color: isHovered ? '#FFD2BC' : '#717485' }}>{name}</span>
                    </span>
                    :
                    <span className="flex items-center">
                        <span className={"glade-normal-text-two inline-flex glade-sidelink-hover"}>{icon ? React.cloneElement(icon) : <FaFontAwesomeFlag />}</span>
                        <span className={"glade-normal-text-two inline-flex glade-sidelink-hover ml-3"}>{name}</span>
                    </span>
                }
            </Link>
        </li>
    );
}