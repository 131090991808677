import React, { useContext } from 'react';
import TransferInternationalForm from './TransferInternationalForm';
import TransferScreen from './TransferScreen';
import TransferDetailsReview from './TransferDetailsReview';
import { TransferFormContext } from '../../context/TransferFormContext';
import DisplayBeneficiaries from './DisplayBeneficiaries';
import ForeignLocalTransfer from './ForeignLocalTransfer';
import ConfirmTransaction from './ConfirmTransaction';
import SelectTransferType from './SelectTransferType';
import GladeInternalTransfer from './GladeInternalTransfer';

const FundTransferLocal = () => {
  const { formStage } = useContext(TransferFormContext);
  return (
    <TransferScreen>
      {formStage === 'international'
        ? <TransferInternationalForm />
        :
        formStage === 'review' ? <TransferDetailsReview />
          : formStage === 'beneficiaries' ? <DisplayBeneficiaries />
            : formStage === 'local' ? <ForeignLocalTransfer />
              : formStage === 'confirmation' ? <ConfirmTransaction />
                : formStage === 'select-transfer' ? <SelectTransferType />
                  : formStage === 'glade' ? <GladeInternalTransfer />
                    : null
      }
    </TransferScreen>
  );
};

export default FundTransferLocal;




