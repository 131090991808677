import React, { Fragment, useEffect, useRef } from "react";
import useUser from "../../hooks/useUser";
import HomeBusiness from "./HomeBusiness";
import HomePersonal from "./HomePersonal";
import CreateUserPinModal from "./CreateUserPinModal";
import HomeSubmitCompliance from "./HomeSubmitCompliance";
import { useHistory } from "react-router-dom";

const Home = () => {
  const CreateUserPinModalRef = useRef();
  const user = useUser();
  const history = useHistory();

  useEffect(() => {
    user.has_passcode === false && CreateUserPinModalRef.current.open();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {user.hasCompliance ? (
        user.isBusiness ? (
          <HomeBusiness />
        ) : (
          <HomePersonal />
        )
      ) : (
        <HomeSubmitCompliance
          onClick={() => {
            user?.isBusiness
              ? history.push("/dashboard/home/business-compliance")
              : history.push("/dashboard/home/personal-compliance");
          }}
        />
      )}
      <CreateUserPinModal ref={CreateUserPinModalRef} />
    </Fragment>
  );
};

export default Home;
