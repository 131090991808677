import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import { getAirtime } from "../../redux/airtime/airtimeActions";
import useAirtime from "../../hooks/useAirtime";
import {
  simpleDate,
  statusEnum,
  isArray,
  isEmpty,
  pagination,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import Button from "../../components/buttons/Button";
import SearchInput from "../../components/inputs/SearchInput";
import Sieve from "../../components/filter/Sieve";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import { IoIosArrowForward } from "react-icons/io";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import useTeam from "../../hooks/useTeam";

export default function Airtime() {
  const history = useHistory();
  const dispatch = useDispatch();
  const airtime = useAirtime();
  const { permissions, team_user } = useTeam();

  React.useEffect(() => {
    dispatch(getAirtime());
    // eslint-disable-next-line
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(airtime.airtimeHistory ?? [], 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, airtime.airtimeIndex]);

  // Search Function And Control
  const onSearch = (search) => {
    const filteredData = airtime?.airtimeHistory?.filter((item) => {
      return (
        item.bill_reference.toLowerCase().includes(search.toLowerCase()) ||
        item.txn_ref.toLowerCase().includes(search.toLowerCase())
      );
    });
    setTableData(pagination(filteredData ?? [], 10, currentPage));
  };

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getAirtime({
        date_from: data.date_from ?? data.date,
        date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
      })
    );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getAirtime());
  };

  const renderAirtimeTable = (data) => {
    const onClickOfRow = (item) => {
      history.push({
        pathname: !permissions.includes("airtime")
          ? "/dashboard/airtime/detail"
          : "/teams/airtime/detail",
        state: {
          ...item,
          receipient_or_creditor_institution: item?.bill_name,
          receipient_or_creditor: item?.bill_reference,
          value: item?.amount_charged,
        },
      });
    };
    return data.map((item, key) => {
      return (
        <CardTableBodyRow key={key} className="cursor-pointer">
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <CircleIndicator
              type={statusEnum[item?.status]}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            />
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two">
              {item?.bill_reference}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two">{item?.bill_name}</span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span className="glade-normal-text-two">
              <CopyTextButton text={item?.txn_ref} />
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two">
              {item?.amount_charged}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell
            onClick={() => onClickOfRow(item)}
            className="cursor-pointer"
          >
            <span className="glade-normal-text-two">
              {simpleDate(item?.created_at)}
            </span>
          </CardTableBodyCell>
          <CardTableBodyCell>
            <span></span>
          </CardTableBodyCell>
        </CardTableBodyRow>
      );
    });
  };

  const renderAirtimeDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-row justify-between p-1 border-b"
          onClick={() =>
            history.push({
              pathname: "/dashboard/airtime/airtime-detail",
              state: item,
            })
          }
        >
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <CircleIndicator type={statusEnum[item?.status]} />
            </div>
            <div className="flex flex-col space-y-1 py-1">
              <div>
                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                  NGN{" "}
                  {Number(
                    item?.actual_value ?? item?.amount_charged
                  )?.toLocaleString()}
                </span>
              </div>
              <div>
                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                  {item?.bill_reference}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3">
      <CardTableHeaderComplex
        title={`Showing ${tableData.totalItems} records`}
        filter={
          <Sieve
            types={["date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={airtime.isLoading}
            loading={airtime.isLoading}
          />
        }
        searchBar={
          <SearchInput
            disabled={airtime.isLoading}
            onSearch={onSearch}
            placeholder={"Phone Number or Transaction Reference"}
          />
        }
        rightButton={
          <div>
            {!team_user?.employee?.is_teams && (
              <Button.Blue
                title={"Buy Airtime"}
                onClick={() =>
                  history.push(
                    !permissions.includes("airtime")
                      ? "/dashboard/airtime/new-airtime"
                      : "/teams/airtime/new-airtime"
                  )
                }
              />
            )}
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={airtime.isLoading}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={airtime.isLoading}
                    loading={airtime.isLoading}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <div>
                    {!team_user?.employee?.is_teams &&
                      <span
                        onClick={() =>
                          history.push(
                            !permissions.includes("airtime")
                              ? "/dashboard/airtime/new-airtime"
                              : "/teams/airtime/new-airtime"
                          )
                        }
                        className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                      >
                        Buy Airtime
                      </span>
                    }
                  </div>
                }
                inactiveChild={
                  <div>
                    {!team_user?.employee?.is_teams && (
                      <span className="glade-normal-text-two glade-black p-4">
                        Buy Airtime
                      </span>
                    )}
                  </div>
                }
              />
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || airtime.isLoading ? (
        <React.Fragment>
          <div className="hidden md:block">
            <EmptyTable
              column={[
                "",
                "Status",
                "Phone Number",
                "Biller",
                "Transaction Reference",
                "Amount",
                "Date",
                "",
              ]}
              loading={airtime.isLoading}
            />
          </div>

          <div className="block md:hidden">
            <EmptyDesk row={[1, 2]} loading={airtime.isLoading} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="hidden md:block">
            <CardTableBody
              column={[
                "",
                "Status",
                "Phone Number",
                "Biller",
                "Transaction Reference",
                "Amount",
                "Date",
                "",
              ]}
            >
              {isArray(tableData.data) && renderAirtimeTable(tableData.data)}
            </CardTableBody>
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderAirtimeDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={airtime.isLoading}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
    </div>
  );
}
