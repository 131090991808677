import React, { useContext, useEffect, useState } from 'react';
import { ImSpinner2 } from 'react-icons/im';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';
import { TransferFormContext } from '../../context/TransferFormContext';
import useFundTransfer from '../../hooks/useFundTransfer';
import { downloadFundTransferReceiptAsPDF } from '../fund-transfer/DownloadFundTransferReceiptAsPDF';
import TransferScreen from './TransferScreen';

const ConfirmTransaction = ({onClosePage=()=>{}}) => {
  const { setFormStage, receiptInfo, setActiveStepAmount } = useContext(TransferFormContext);
  const { isLoading, transactionStatus } = useFundTransfer();

  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    let intervalCall = setInterval(() => {
      if (timeLeft > 0 && timeLeft) {
        if(!isLoading){
        setTimeLeft(timeLeft - 1);}
      } else {
        setFormStage('select-transfer');
        setActiveStepAmount(true);
        onClosePage();
      };
    }, 1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [timeLeft, isLoading]);

  return (
    <TransferScreen>
      {!isLoading ? (
        <div className='flex flex-col items-center px-6 py-12 w-full bg-white h-full'>
          <div>
            {transactionStatus ?
              (
                  <div className='flex flex-col justify-center items-center mb-6 mt-6'>
                    <IoIosCheckmarkCircleOutline className='mt-6 mb-4' color='#00D220' size={100} />
                    <span className='glade-heading-three glade-home-dark'>Transfer Successful</span>
                    <span>Redirecting in {timeLeft} seconds</span>
                  </div>
              ) : (
                <div className='flex flex-col justify-center items-center mb-6 mt-6'>
                  <IoIosCloseCircleOutline className='mt-6 mb-4' color='#EA0202' size={100} />
                  <span className='glade-heading-three glade-home-dark'>Transfer Failed</span>
                  <span>Redirecting in {timeLeft} seconds</span>
                </div>
              )}
            <button className='w-full p-2 rounded glade-button-blue mt-4 mb-4' onClick={() => { setFormStage('select-transfer'); setActiveStepAmount(true); onClosePage(); }}>Close</button>
            {transactionStatus && <button className='w-full p-2 rounded glade-blue border glade-border-blue' onClick={() => downloadFundTransferReceiptAsPDF(receiptInfo)}>Download Transaction receipt</button>}
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center px-6 py-12 w-full bg-white h-full'>
          <div>
            <div className='mt-6'>
              <span className='glade-heading-three glade-home-dark'>Completing Transfer</span>
            </div>
            <div className='flex justify-center'><ImSpinner2 className='mt-6 spinner text-center' size={70} color='#0B7AE0' /></div>
          </div>
        </div>
      )}
    </TransferScreen>
  );
};

export default ConfirmTransaction;
