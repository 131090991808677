import {
  GET_COUNTRIES,
  GET_STATES,
  GET_LGAS
} from "./types";

const initialState = {
  getCountries: {},
  getStates: {},
  getLgas: {}
}

export default function countriesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        getCountries: action.payLoad.data.data
      }
    case GET_STATES:
      return {
        ...state,
        getStates: action.payLoad.data.data
      }
      case GET_LGAS:
        return {
          ...state,
          getLgas: action.payLoad.data.data
        }
    default:
      return state
  }
}