import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "../../../assets/icons/Icons";
import Button from "../../../components/buttons/Button";
import FileInput from "../../../components/inputs/FileInput";
import SelectInput from "../../../components/inputs/SelectInput";
import { isArray, isEmptyArray } from "../../../helpers/helper";
import useSettings from "../../../hooks/useSettings";
import useUser from "../../../hooks/useUser";
import { getMetamapIdentityTypes } from "../../../redux/settings/settingsActions";
import IDView from "./IDView";
import { BACK_ID_SAMPLE, FRONT_ID_SAMPLE } from "../../../assets/images/images";

const IdUpload = React.forwardRef((props, forwardedRef) => {
  const settings = useSettings();
  const dispatch = useDispatch();
  const user = useUser();
  const { finishedCallback, input, setInput } = props;
  const [showFrontSampleDocument, setShowFrontSampleDocument] =
    React.useState(false);
  const [showBackSampleDocument, setShowBackSampleDocument] =
    React.useState(false);
  const [isIDUpload, setIsIDUpload] = useState(true);
  const [idUploadField, setIdUploadField] = useState(false);

  React.useEffect(() => {
    dispatch(getMetamapIdentityTypes({ country_code: "NGA" }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const selectedType = e.target.value;
    setIsIDUpload(selectedType !== "nin-slip" && selectedType !== "passport");
    setInput({ ...input, [e.target.name]: selectedType });
  };

  const handleContinue = () => {
    finishedCallback();
  };

  const disabled =
    !input?.id_type || isEmptyArray(input?.id_card_front) || !input?.id_card_front || (isIDUpload && (isEmptyArray(input?.id_card_back) || !input?.id_card_back) )

  return (
    <div ref={forwardedRef}>
      {!idUploadField && 
        <IDView setIdUploadField={setIdUploadField} />
      }
      {idUploadField && 
        <div className="w-full max-w-md">
        <div className="flex flex-col items-center">
          <h3 className="flex mt-6 glade-heading-three glade-black">
            You're almost done!
          </h3>
          <p className="flex mt-2 glade-subtitle-two glade-grey-dark justify-center items-center text-center">
            Select your ID type and upload for verification.
          </p>
        </div>
        <div className="mt-6 space-y-6">
          <SelectInput
            label={"ID Type"}
            id={"id_type"}
            name={"id_type"}
            value={input?.id_type}
            onChange={onChange}
            autoComplete={"id_type"}
            className={"appearance-none"}
          >
            <option value={""} disabled={true}>
              Select ID
            </option>
            {isArray(settings.metamapIdentityTypes) &&
              settings.metamapIdentityTypes?.map((type, index) => (
                <option key={index} value={type}>
                  {/* {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')} */}
                  {type
                    .split("-")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </option>
              ))}
          </SelectInput>

          <FileInput
            label={
              <div className="flex justify-between">
                <span>{(input?.id_type !== "nin-slip" && input?.id_type !== "passport") ? "Front view ID upload" : "ID Upload"}</span>
                <button
                  className="glade-small-text-two glade-blue"
                  onClick={() => setShowFrontSampleDocument(true)}
                >
                  View Sample Document
                </button>
              </div>
            }
            id={"id_card_front"}
            name={"id_card_front"}
            multiple={false}
            accept=".png, .jpeg, .jpg"
            initialValue={input?.id_card_front?.length !== 0 ? input?.id_card_front : false}
            maximumSize={3}
            returnInput={(value) =>
              setInput({ ...input, id_card_front: value[0] })
            }
          />
          <span className="glade-small-text-one glade-grey-cool block">
            Supported file types:JPG, PNG & JPEG. Max file size allowed is 3MB.
          </span>

          {(input?.id_type !== "nin-slip" && input?.id_type !== "passport") && (
            <>
              <FileInput
                label={
                  <div className="flex justify-between">
                    <span>Back view ID upload</span>
                    <button
                      className="glade-small-text-two glade-blue"
                      onClick={() => setShowBackSampleDocument(true)}
                    >
                      View Sample Document
                    </button>
                  </div>
                }
                id={"id_card_back"}
                name={"id_card_back"}
                multiple={false}
                accept=".png, .jpeg, .jpg"
                initialValue={input?.id_card_back?.length !== 0 ? input?.id_card_back : false}
                maximumSize={3}
                returnInput={(value) =>
                  setInput({ ...input, id_card_back: value[0] })
                }
              />
              <span className="glade-small-text-one glade-grey-cool block">
                Supported file types: JPG, PNG & JPEG. Max file size allowed is
                3MB.
              </span>
            </>
          )}
          <div className="pt-3 flex justify-center">
            <Button.Blue
              title={"Continue"}
              type={"button"}
              className={"py-3"}
              onClick={handleContinue}
              disabled={disabled}
              loading={user.isLoading}
            />
          </div>
        </div>
        {showFrontSampleDocument && (
          <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-32 flex flex-col justify-center">
            <div className="flex justify-center items-center">
              <div className="self-center bg-white rounded md:w-1/3 p-5 h-full">
                <div className="text-center">
                  {input.id_type === "passport" ? null : (
                    <span>{isIDUpload ? "Front View" : "Card View"}</span>
                  )}
                  <button
                    onClick={() => setShowFrontSampleDocument(false)}
                    className="float-right"
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="mx-auto mb-4">
                  {input.id_type === "passport" ? (
                    <img
                      src={FRONT_ID_SAMPLE}
                      alt="ID"
                      className="object-scale-down mx-auto rounded-[8px]"
                    />
                  ) : (
                    <img
                      src={FRONT_ID_SAMPLE}
                      alt="ID"
                      className="object-scale-down mx-auto rounded-[8px]"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {showBackSampleDocument && (
          <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-32 flex flex-col justify-center">
            <div className="flex justify-center items-center">
              <div className="self-center bg-white rounded md:w-1/3 p-5 h-full">
                <div className="text-center mb-4">
                  <span>Back View</span>
                  <button
                    onClick={() => setShowBackSampleDocument(false)}
                    className="float-right"
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="mx-auto">
                  <img
                    src={BACK_ID_SAMPLE}
                    alt="ID"
                    className="object-scale-down mx-auto rounded-[8px]"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      }
    </div>
      
  );
});

export default IdUpload;
