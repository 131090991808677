import { Fragment } from "react";
import {
  autoEllipses,
  formatDate,
  isArray,
  isEmpty,
} from "../../helpers/helper";
import EmptyCard from "../../components/empty-states/EmptyCard";
import useHome from "../../hooks/useHome";
import useTeam from "../../hooks/useTeam";

const RecentTransactionCard = ({ transactions }) => {
  const home = useHome();
  const { permissions, team_user } = useTeam();

  const renderTransaction = (data) => {
    if (!data || !isArray(data)) {
      return null;
    }
    if (
      permissions?.includes("transactions page") ||
      !team_user?.employee?.is_teams
    ) {
      return data.map((item, key) => {
        return (
          <div
            key={key}
            className={`grid grid-cols-3 gap-3 items-center ${
              key !== 0 ? "border-t" : ""
            } pt-2`}
          >
            <div className="flex flex-col justify-start space-y-3">
              <span className="glade-subtitle-three glade-black-light">
                {autoEllipses(item.receipient_or_creditor ?? "", "200", "90")}
              </span>
              <span className="glade-subtitle-two glade-grey-light">
                {formatDate(item.created_at)}
              </span>
            </div>
            <div className="flex flex-row justify-start">
              <h6 className="glade-subtitle-two glade-grey-light truncate">
                {item.narration}
              </h6>
            </div>
            <div className="flex flex-row justify-end">
              <h4
                className={`${
                  item.txn_type === "debit"
                    ? "glade-home-dark"
                    : "glade-form-mark"
                } glade-subtitle-one`}
              >
                {item.txn_type === "debit" ? "-" : "+"} {item.currency}{" "}
                {Number(
                  item?.actual_value ?? item?.value ?? 0
                )?.toLocaleString()}
              </h4>
            </div>
          </div>
        );
      });
    }else{
        return (
        <div className="">
            <EmptyCard
              title=""
              subtitle="You do not have any transactions "
              loading={home.isLoading}
            />
          </div>)
    }
  };

  return (
    <div className="flex flex-col space-y-2 p-4">
      {isEmpty(transactions) || home.isLoading ? (
        <Fragment>
          <div className="">
            <EmptyCard
              title=""
              subtitle="You do not have any transactions "
              loading={home.isLoading}
            />
          </div>
        </Fragment>
      ) : (
        <div className="flex flex-col space-y-6 p-4">
          {renderTransaction(transactions)}
        </div>
      )}
    </div>
  );
};

export default RecentTransactionCard;
