import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import {
  toTitleCase,
  simpleDate,
  isEmptyArray,
  currencyNameEnum,
  filterAfricanCurrencies,
} from "../../helpers/helper";
import useInvoice from "../../hooks/useInvoice";
import useUser from "../../hooks/useUser";
import {
  getInvoiceCurrencies,
  getInvoiceCustomersOnWhiteList,
  getInvoiceCounter,
} from "../../redux/invoice/invoiceActions";
import TextInput from "../../components/inputs/TextInput";
import DateInput from "../../components/inputs/DateInput";
import NumericInput from "../../components/inputs/NumericInput";
import SelectInput from "../../components/inputs/SelectInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import GLADE_LOGO from "../../components/logos/GladeLogo";
import CreateInvoiceItems from "./CreateInvoiceItems";
import AddNewCustomerModal from "./AddNewCustomerModal";
import { createInvoiceValidation } from "../../validation/invoice/invoiceValidation";
import NumericWithDecimalsInput from "../../components/inputs/NumericWithDecimalsInput";
import { useEffect } from "react";
import { useState } from "react";
import useHome from "../../hooks/useHome";
import useTeam from "../../hooks/useTeam";

export default function CreateInvoice() {
  // Ref Handler
  const createInvoiceItemsRef = React.useRef();
  const addNewCustomerModalRef = React.useRef();

  // Create Invoice
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const invoice = useInvoice();
  const user = useUser();
  const { permissions, team_user, business } = useTeam();
  const home = useHome();
  const [input, setInput] = useState({
    invoice_id: invoice?.invoiceCounter?.invoice_no ?? "",
    customer: "",
    currency_id: "",
    currency_name: "",
    vat_value: 0,
    discount_type: "percentage",
    discount_value: 0,
    shipping_cost: 0,
    due_date: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .substring(0, 10),
    note: "",
    charge_user: false,
    fund_wallet: true,
  });
  const [availablePaymentCurrencies, setAvaiablePaymentCurrencies] = useState(
    []
  );

  const currencies =
    user.selectedBusinessOrPersonalAccount?.accounts?.length > 0
      ? user.selectedBusinessOrPersonalAccount?.accounts
      : [];

  useEffect(() => {
    dispatch(getInvoiceCounter());
    dispatch(getInvoiceCurrencies());
    dispatch(getInvoiceCustomersOnWhiteList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.shouldReload]);

  useEffect(() => {
    const currencies =
      user.selectedBusinessOrPersonalAccount?.accounts?.length > 0
        ? user.selectedBusinessOrPersonalAccount?.accounts
        : [];

    const availableCurrencies = invoice?.invoiceCurrencies.filter((item1) => {
      return currencies.some((item2) => {
        return (
          item1.currency === item2.currency && item2.account_status === "active"
        );
      });
    });

    setAvaiablePaymentCurrencies(availableCurrencies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.invoiceCurrencies]);

  useEffect(() => {
    if (location.state?.createInvoiceInput) {
      setInput({ ...input, ...location.state.createInvoiceInput });
      let items = {};
      location.state.createInvoiceInput?.items?.map((item, key) => {
        return (items[key] = item);
      });
      createInvoiceItemsRef?.current?.replaceInput(items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    let data = {
      invoice_id: input.invoice_id,
      currency: input.currency_name,
      customer_id: input.customer,
      date_due: input.due_date,
      discount_type: input.discount_type,
      discount: input.discount_value,
      shipping: Number(String(input.shipping_cost).replaceAll(",", "")),
      vat: input.vat_value,
      note: input.note,
      charge_user: input.charge_user,
      fund_wallet: input.fund_wallet,
      items: createInvoiceItemsRef.current.getInput(),
      calculatedInvoiceSubTotal: calculatedInvoice.subtotal,
      calculatedInvoiceDiscount: calculatedInvoice.discount,
      calculatedInvoiceTotal: calculatedInvoice.total,
    };
    if (createInvoiceValidation(data)) {
      history.replace({
        pathname: !permissions?.includes("invoicing")
          ? "/dashboard/invoice/preview-invoice"
          : "/teams/invoice/preview-invoice",
        state: {
          ...data,
          createInvoiceInput: {
            ...input,
            items: createInvoiceItemsRef.current.getInput(),
          },
        },
      });
    }
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    if (e.target.name === "shipping_cost") {
      setInput({
        ...input,
        [e.target.name]: Number(e.target.value).toLocaleString("en-US"),
      });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const onSelectOfCurrency = (e) => {
    let name = e.target.value;
    setInput({
      ...input,
      currency_id: name,
      currency_name: name,
    });
  };

  // Calculate Invoice
  const [shouldCalculate, toggleShouldCalculate] = React.useState(false);
  const [calculatedInvoice, setCalculatedInvoice] = React.useState({});
  useEffect(() => {
    let invoiceItems = createInvoiceItemsRef?.current?.getInput();
    let subtotal = isEmptyArray(invoiceItems)
      ? 0
      : invoiceItems
          .map((item) => item.qty * item.cost)
          .reduce((acc, item) => acc + item);
    let discount =
      input.discount_type === "percentage"
        ? subtotal * (input.discount_value / 100)
        : input.discount_value;
    let total =
      subtotal * 1 +
      (input.vat_value / 100) * (subtotal - discount) +
      String(input.shipping_cost) * 1 -
      discount * 1;
    setCalculatedInvoice({ subtotal, discount, total });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, shouldCalculate]);

  return (
    <div className="p-3 bg-glade-gray-50">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs
            title={"Invoice"}
            path={
              !permissions?.includes("invoicing")
                ? "/dashboard/invoices"
                : "/teams/invoices"
            }
          />
        </div>

        <CardPlainWithButton type={"dual"}>
          <CardPlainWithButtonSection
            title={"Invoice Details"}
            type={"dual"}
            loading={invoice.isLoading}
            buttonDisabled={invoice.isLoading}
            buttonSection={<React.Fragment></React.Fragment>}
          >
            <div className="mt-6 px-14">
              <div className="space-y-3">
                <AddNewCustomerModal ref={addNewCustomerModalRef} />
                <SelectInput
                  label={
                    <div className="flex flex-row justify-between">
                      <span>Customer</span>
                      <span
                        className="glade-blue cursor-pointer"
                        onClick={() => addNewCustomerModalRef.current.open()}
                      >
                        + New Customer
                      </span>
                    </div>
                  }
                  id={"customer"}
                  name={"customer"}
                  value={input.customer ?? ""}
                  onChange={onChange}
                  autoComplete={"customer"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select A Customer
                  </option>
                  {invoice.whitelistedInvoiceCustomers?.customers?.map(
                    (customer, index) => (
                      <option key={index} value={customer.id}>
                        {toTitleCase(customer.user_name ?? "")}
                      </option>
                    )
                  )}
                </SelectInput>

                <TextInput
                  label={"Invoice ID"}
                  id={"invoice_id"}
                  name={"invoice_id"}
                  type={"text"}
                  value={input.invoice_id ?? ""}
                  onChange={onChange}
                  disabled={true}
                  placeholder={
                    "An invoice number will be generated automatically"
                  }
                  autoComplete={"invoice_id"}
                  className={"appearance-none"}
                />
                <SelectInput
                  label={"Select Currency"}
                  id={"currency_id"}
                  name={"currency_id"}
                  value={input.currency_id ?? ""}
                  onChange={onSelectOfCurrency}
                  autoComplete={"currency_id"}
                  className={"appearance-none"}
                >
                  <option value={""} disabled={true}>
                    Select A Currency
                  </option>
                  {filterAfricanCurrencies(user)?.length > 0
                    ? filterAfricanCurrencies(user)?.map((account, key) => (
                        <option value={`${account?.currency}`} key={key}>
                          {`${
                            currencyNameEnum[account?.currency] ??
                            account?.currency
                          }`}
                        </option>
                      ))
                    : null}
                  {team_user?.employee?.is_teams
                    ? filterAfricanCurrencies(
                        team_user?.business?.accounts,
                        true
                      )?.map((account, key) => (
                        <option value={`${account?.currency}`} key={key}>
                          {`${
                            currencyNameEnum[account?.currency] ??
                            account?.currency
                          }`}
                        </option>
                      ))
                    : null}
                </SelectInput>

                <NumericWithDecimalsInput
                  label={"VAT (%)"}
                  id={"vat_value"}
                  name={"vat_value"}
                  type={"number"}
                  value={input.vat_value ?? 0}
                  onChange={onChange}
                  placeholder={"Enter a VAT percentage"}
                  autoComplete={"vat"}
                  className={"appearance-none"}
                />

                <div className="space-y-1 col-span-6 sm:col-span-3">
                  <label
                    htmlFor="discount"
                    className="block glade-small-text-two glade-black"
                  >
                    Discount
                  </label>
                  <div className="flex flex-row">
                    <div className="w-1/3 mr-3">
                      <SelectInput
                        label={""}
                        id={"discount_type"}
                        name={"discount_type"}
                        value={input.discount_type ?? ""}
                        onChange={onChange}
                        autoComplete={"discount_type"}
                        className={"appearance-none"}
                      >
                        <option value={"percentage"}>Percentage</option>
                        <option value={"amount"}>Amount</option>
                      </SelectInput>
                    </div>
                    <div className="w-2/3">
                      <NumericWithDecimalsInput
                        label={""}
                        id={"discount_value"}
                        name={"discount_value"}
                        type={"number"}
                        value={input.discount_value ?? 0}
                        onChange={onChange}
                        placeholder={"Enter a discount"}
                        autoComplete={"discount_value"}
                        className={"appearance-none"}
                      />
                    </div>
                  </div>
                </div>

                <NumericInput
                  label={"Shipping"}
                  id={"shipping_cost"}
                  name={"shipping_cost"}
                  type={"number"}
                  value={input.shipping_cost ?? 0}
                  onChange={onChange}
                  placeholder={"Enter Shipping"}
                  autoComplete={"shipping_cost"}
                  className={"appearance-none"}
                />

                <DateInput
                  label={"Due Date"}
                  id={"due_date"}
                  name={"due_date"}
                  type={"date"}
                  value={input.due_date ?? ""}
                  min={new Date().toISOString().substring(0, 10)}
                  onChange={onChange}
                  placeholder={"Enter Shipping"}
                  autoComplete={"due_date"}
                  className={"appearance-none"}
                />

                <TextInput
                  label={"Note"}
                  id={"note"}
                  name={"note"}
                  type={"text"}
                  value={input.note ?? ""}
                  onChange={onChange}
                  placeholder={"Enter description (optional)"}
                  autoComplete={"note"}
                  className={"appearance-none"}
                />

                <CheckboxInput
                  label={"Charge user?"}
                  id={"charge_user"}
                  name={"charge_user"}
                  type={"checkbox"}
                  checked={input.charge_user}
                  onChange={(e) =>
                    setInput({ ...input, [e.target.name]: e.target.checked })
                  }
                  placeholder={"Customer should pay for charges (optional)"}
                  autoComplete={"charge_user"}
                  className={"appearance-none"}
                />
              </div>
            </div>
          </CardPlainWithButtonSection>
          <CardPlainWithButtonSection
            titleSection={<React.Fragment></React.Fragment>}
            type={"dual"}
            loading={invoice.isLoading}
            buttonDisabled={invoice.isLoading}
            buttonSection={
              <div className="flex flex-row space-x-4 mt-6">
                <Button.Blue
                  title={"Preview Invoice"}
                  className="whitespace-nowrap"
                  onClick={() => onSubmit()}
                  disabled={invoice.isLoading}
                />
              </div>
            }
          >
            <div className="mt-6 px-2">
              <div className="space-y-6">
                <div className="flex flex-row justify-between">
                  <GLADE_LOGO alternate={true} />
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-one glade-black">
                      Invoice:{" "}
                      <span className="glade-normal-text-two">
                        {input.invoice_id}
                      </span>
                    </h4>
                    <h4 className="glade-normal-text-one glade-black">
                      Due on:{" "}
                      <span className="glade-normal-text-two">
                        {simpleDate(input.due_date)}
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="flex flex-row justify-between pb-8 border-b-2">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-two glade-ash">From</h4>
                    <h4 className="glade-normal-text-one glade-black">
                      {!team_user?.employee?.is_teams
                        ? user?.selectedBusinessOrPersonalAccount?.business_name
                        : business?.business_name}
                    </h4>
                    <h4 className="glade-normal-text-two glade-black">
                      {!team_user?.employee?.is_teams
                        ? user?.selectedBusinessOrPersonalAccount
                            ?.contact_phone ??
                          user?.selectedBusinessOrPersonalAccount
                            ?.business_email
                        : business?.business_email}
                    </h4>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-two glade-ash">To</h4>
                    <h4 className="glade-normal-text-one glade-black">
                      {input.customer
                        ? invoice.whitelistedInvoiceCustomers?.customers?.find(
                            (c) => c.id.toString() === input.customer
                          )?.user_name
                        : ""}
                    </h4>
                    <h4 className="glade-normal-text-two glade-black">
                      {input.customer
                        ? invoice.whitelistedInvoiceCustomers?.customers?.find(
                            (c) => c.id.toString() === input.customer
                          )?.user_email
                        : ""}
                    </h4>
                  </div>
                </div>

                <CreateInvoiceItems
                  ref={createInvoiceItemsRef}
                  currency={input.currency_name}
                  calculate={() =>
                    toggleShouldCalculate({ shouldCalculate: !shouldCalculate })
                  }
                />

                <div className="flex flex-row justify-between pb-8 border-b-2">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-one glade-black">
                      Subtotal
                    </h4>
                    <h4 className="glade-normal-text-two glade-ash">
                      Discount
                    </h4>
                    <h4 className="glade-normal-text-two glade-ash">VAT</h4>
                    <h4 className="glade-normal-text-two glade-ash">
                      Shipping
                    </h4>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="h-full glade-normal-text-one glade-black text-right">
                      {input.currency_name +
                        " " +
                        calculatedInvoice?.subtotal?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </h4>
                    <h4 className="h-full glade-normal-text-two glade-ash text-right">
                      {calculatedInvoice.discount
                        ? "- " +
                          input.currency_name +
                          " " +
                          calculatedInvoice.discount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : null}
                    </h4>
                    <h4 className="h-full glade-normal-text-two glade-ash text-right">
                      {input.vat_value
                        ? "+ " +
                          input.currency_name +
                          " " +
                          (
                            (input.vat_value / 100) *
                            (calculatedInvoice.subtotal -
                              calculatedInvoice.discount)
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : null}
                    </h4>
                    <h4 className="h-full glade-normal-text-two glade-ash text-right">
                      {input.shipping_cost
                        ? "+ " +
                          input.currency_name +
                          " " +
                          Number(
                            input.shipping_cost.replaceAll(",", "")
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : null}
                    </h4>
                  </div>
                </div>

                <div className="flex flex-row justify-between pb-8 border-b-2">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-one glade-black">Total</h4>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-one glade-black">
                      {input.currency_name +
                        " " +
                        calculatedInvoice?.total?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </h4>
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <h4 className="glade-normal-text-two glade-ash">Notes</h4>
                  </div>
                  <div className="flex flex-col pt-3">
                    <h4 className="glade-normal-text-two glade-black">
                      {input.note}
                    </h4>
                  </div>
                </div>

                {input.currency_name === "NGN" && (
                  <div className="flex flex-col">
                    <div className="flex flex-col pt-3 space-y-2">
                      <h1 className="font-semibold">Account 1</h1>
                      <h6 className="glade-normal-text-two glade-off-black">
                        Account Name:{" "}
                        <span className="glade-normal-text-two glade-blue">
                          {toTitleCase(
                            !team_user?.employee?.is_teams
                              ? home?.oneBusinessAccount?.naira_accounts
                                  ?.accounts[0]?.account_name
                              : team_user?.ngn_accounts[0]?.account_name
                          )}
                        </span>
                      </h6>
                      <h6 className="glade-normal-text-two glade-off-black">
                        Account Number:{" "}
                        <span className="glade-normal-text-two glade-blue">
                          {toTitleCase(
                            !team_user?.employee?.is_teams
                              ? home?.oneBusinessAccount?.naira_accounts
                                  ?.accounts[0]?.account_number
                              : team_user?.ngn_accounts[0]?.account_number
                          )}
                        </span>
                      </h6>
                      <h6 className="glade-normal-text-two glade-off-black">
                        Bank Name:{" "}
                        <span className="glade-normal-text-two glade-blue">
                          {toTitleCase(
                            !team_user?.employee?.is_teams
                              ? home?.oneBusinessAccount?.naira_accounts
                                  ?.accounts[0]?.account_provider
                              : team_user?.ngn_accounts[0]?.bank_name
                          )}
                        </span>
                      </h6>
                    </div>

                    <div className="flex flex-col pt-3 space-y-2">
                      <h1 className="font-semibold">Account 2</h1>
                      <h6 className="glade-normal-text-two glade-off-black">
                        Account Name:{" "}
                        <span className="glade-normal-text-two glade-blue">
                          {toTitleCase(
                            !team_user?.employee?.is_teams
                              ? home?.oneBusinessAccount?.naira_accounts
                                  ?.accounts[1]?.account_name
                              : team_user?.ngn_accounts[1]?.account_name
                          )}
                        </span>
                      </h6>
                      <h6 className="glade-normal-text-two glade-off-black">
                        Account Number:{" "}
                        <span className="glade-normal-text-two glade-blue">
                          {toTitleCase(
                            !team_user?.employee?.is_teams
                              ? home?.oneBusinessAccount?.naira_accounts
                                  ?.accounts[1]?.account_number
                              : team_user?.ngn_accounts[1]?.account_number
                          )}
                        </span>
                      </h6>
                      <h6 className="glade-normal-text-two glade-off-black">
                        Bank Name:{" "}
                        <span className="glade-normal-text-two glade-blue">
                          {toTitleCase(
                            !team_user?.employee?.is_teams
                              ? home?.oneBusinessAccount?.naira_accounts
                                  ?.accounts[1]?.account_provider
                              : team_user?.ngn_accounts[1]?.bank_name
                          )}
                        </span>
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  );
}
