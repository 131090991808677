import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";
import {
  CardTableBody,
  CardTableBodyRow,
  CardTableBodyCell,
} from "../../components/cards/CardTableBody";
import { CardTableFooter } from "../../components/cards/CardTableFooter";
import {
  simpleDate,
  statusEnum,
  isArray,
  isEmpty,
  pagination,
  arrayEquals,
} from "../../helpers/helper";
import EmptyTable from "../../components/empty-states/EmptyTable";
import EmptyDesk from "../../components/empty-states/EmptyDesk";
import Button from "../../components/buttons/Button";
import Sieve from "../../components/filter/Sieve";
import { IoIosArrowForward } from "react-icons/io";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { FiMoreVertical } from "react-icons/fi";
import TeamDeleteModal from "./TeamDeleteModal";
import {
  getEmployeeTeamList,
  deleteEmployeeTeamList,
  toggleActivation,
} from "../../redux/business/businessActions";
import useBusiness from "../../hooks/useBusiness";
import { PopoverMain } from "../../components/popovers/Popover";
import TeamActivationToggleModal from "./TeamActivationToggleModal";
import EmptyCard from "../../components/empty-states/EmptyCard";

export default function Teams() {
  const TeamDeleteModalRef = useRef();
  const ToggleModalRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const business = useBusiness();
  const { teamList, isLoading, deleteTeamMember } = business;
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  const [search, setSearch] = useState();

  // const defaultFilterParams = {
  //   search: "",
  //   page: currentPage,
  //   // date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
  //   // date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
  // };

  // const filterInstance = usePaginationFilterParams({
  //   initialFilters: defaultFilterParams,
  // });

  // const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  // const { date_from, date_to, search, page } = filters;

  // const [filterValue, setFilterValue] = React.useState(filters);

  // useEffect(() => {
  //   setCurrentPage(1);
  //   setFilterValue(filters);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [date_from, date_to, search]);

  useEffect(() => {
    dispatch(
      getEmployeeTeamList({
        params: {
          ...(search
            ? { search }
            : {
                // date_from,
                // date_to,
                // paginate: 1,
                // current_page: page,
                // per_page: 10,
              }),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // date_from,
    // date_to,
    // page,
    // per_page,
    search,
    deleteTeamMember,
  ]);

  useEffect(() => {
    // handleChange({ ...filterValue, page: currentPage });
    setSelectAllCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  React.useEffect(() => {
    setTableData(pagination(teamList, 10, currentPage));
    setSelectedCheckbox([]);
    // eslint-disable-next-line
  }, [currentPage, teamList]);

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getEmployeeTeamList({
        params: {
          // ...filterValue,9
          date_from: data.date_from ?? data.date,
          date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
        },
      })
    );
  };

  const updateSelectedCheckbox = (event, item) => {
    setSelectedItem(item?.id);
    if (event.target.checked) {
      setSelectedCheckbox((prevSelectedItems) => [
        ...prevSelectedItems,
        item?.id,
      ]);
      const oldList = [...selectedCheckbox];
      oldList.push(item?.id);
      oldList.sort(function (a, b) {
        return b - a;
      });

      let list_ids = teamList
        ?.map((team) => team?.id)
        .sort(function (a, b) {
          return b - a;
        });

      if (arrayEquals(list_ids, oldList)) {
        setSelectAllCheckbox(true);
      } else {
        setSelectAllCheckbox(false);
      }
    } else {
      setSelectedCheckbox((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item?.id)
      );
      setSelectAllCheckbox(false);
    }
  };

  const handleSelectAllCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedCheckbox(teamList?.map((team) => team?.id));
      setSelectAllCheckbox(true);
    } else {
      setSelectedCheckbox([]);
      setSelectAllCheckbox(false);
    }
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(getEmployeeTeamList());
  };
  const toggleActivationHandler = (data) => {
    dispatch(
      toggleActivation(
        data?.id,
        data?.status === "deactivated" ? "activate" : "deactivate",
        () => dispatch(getEmployeeTeamList())
      )
    );
  };
  const deleteTeamList = () => {
    let payload = selectedCheckbox.toString();
    dispatch(deleteEmployeeTeamList(payload));
  };

  const renderTeamsTable = (data) => {
    const onClickOfRow = (item) => {
      setSelectedItem(item);
      history.push(`/dashboard/team/details/${item?.id}`);
    };

    return data.map((item, key) => {
      return (
        <>
          <CardTableBodyRow key={key}>
            <CardTableBodyCell>
              <span></span>
            </CardTableBodyCell>
            <CardTableBodyCell className="cursor-pointer">
              <span>
                <CheckboxInput
                  label={""}
                  name={item?.id}
                  type={"checkbox"}
                  checked={selectedCheckbox.includes(item?.id)}
                  onChange={(e) => updateSelectedCheckbox(e, item)}
                  placeholder={""}
                  autoComplete={""}
                  className={"appearance-none"}
                />
              </span>
            </CardTableBodyCell>

            <CardTableBodyCell>
              <span className="glade-normal-text-two">
                <BasicIndicator
                  type={statusEnum[item?.status]}
                  title={item?.status}
                  onClick={() => onClickOfRow(item)}
                />
              </span>
            </CardTableBodyCell>
            <CardTableBodyCell
              onClick={() => onClickOfRow(item)}
              className="cursor-pointer"
            >
              <span className="glade-normal-text-two">{item?.name}</span>
            </CardTableBodyCell>
            <CardTableBodyCell
              onClick={() => onClickOfRow(item)}
              className="cursor-pointer"
            >
              <span className="glade-normal-text-two">{item?.email}</span>
            </CardTableBodyCell>
            <CardTableBodyCell
              onClick={() => onClickOfRow(item)}
              className="cursor-pointer"
            >
              <span className="glade-normal-text-two whitespace-nowrap">
                {simpleDate(item?.date)}
              </span>
            </CardTableBodyCell>
            <CardTableBodyCell>
              <PopoverMain
                button={
                  <div type="button" className={"py-2 glade-ash"}>
                    <div className="inline-flex items-center justify-items-center text-center text-xl">
                      <FiMoreVertical
                        color="#151515"
                        className="mx-auto cursor-pointer"
                      />
                    </div>
                  </div>
                }
                popoverClassName="right-6 relative"
              >
                <ul className="px-2 py-5 bg-white shadow rounded-md w-56">
                  <li
                    className="p-3 glade-normal-text-two hover:bg-gray-200 glade-black cursor-pointer"
                    onClick={() =>
                      history.push(`/dashboard/team/edit/${item?.id}`)
                    }
                  >
                    Edit Permissions
                  </li>

                  <li
                    className="p-3 glade-normal-text-two hover:bg-gray-200 glade-black cursor-pointer"
                    onClick={() => ToggleModalRef.current.open(item)}
                  >
                    {(item?.status === "active" || item?.status === "pending") ? "Deactivate" : "Activate"} User
                  </li>
                </ul>
              </PopoverMain>
            </CardTableBodyCell>
            <CardTableBodyCell>
              <span></span>
            </CardTableBodyCell>
          </CardTableBodyRow>
        </>
      );
    });
  };

  const renderTeamsDesk = (data) => {
    return data.map((item, key) => {
      return (
        <div key={key} className="flex flex-row justify-between p-1 border-b">
          <div className="flex flex-row space-x-3">
            <div className="flex items-center">
              <span>
                <CheckboxInput
                  label={""}
                  name={item?.id}
                  type={"checkbox"}
                  checked={selectedCheckbox.includes(item?.id)}
                  onChange={(e) => updateSelectedCheckbox(e, item)}
                  placeholder={""}
                  autoComplete={""}
                  className={"appearance-none"}
                />
              </span>
            </div>
            <div>
              <span className="glade-normal-text-two">
                <BasicIndicator
                  type={statusEnum[item?.status]}
                  title={item?.status}
                />
              </span>
            </div>
            <div className="cursor-pointer">
              <span className="glade-normal-text-two">{item?.name}</span>
            </div>
            <div className="cursor-pointer">
              <span className="glade-normal-text-two">{item?.email}</span>
            </div>
            <div className="cursor-pointer">
              <span className="glade-normal-text-two whitespace-nowrap">
                {simpleDate(item?.date)}
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <IoIosArrowForward />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="p-3 mt-8">
      <CardTableHeaderComplex
        title={
          <div className="flex flex-row justify-around gap-x-9 ">
            <div>
              <p>Showing {tableData.totalItems} records</p>
            </div>
          </div>
        }
        filter={
          <Sieve
            types={["date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={isLoading}
            loading={isLoading}
          />
        }
        rightButton={
          !isEmpty(tableData.data) && (
            <div className="flex flex-row space-x-3">
              <div>
                <Button.Blue
                  title={"Add Team"}
                  onClick={() => history.push("/dashboard/team/add")}
                />
              </div>

              <div>
                <Button.Danger2
                  title={"Delete"}
                  disabled={selectedCheckbox?.length < 1}
                  onClick={() => {
                    TeamDeleteModalRef.current.open(selectedItem);
                  }}
                />
              </div>
            </div>
          )
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={isLoading}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={isLoading}
                    loading={isLoading}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <span
                    onClick={() => history.push("/dashboard/team/add")}
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Add Team
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Add Team
                  </span>
                }
              />
              <KebabDropdownItem
                activeChild={
                  <span
                    onClick={() =>
                      TeamDeleteModalRef.current.open(selectedItem)
                    }
                    className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                  >
                    Delete
                  </span>
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    Delete
                  </span>
                }
              />
            </KebabDropdown>
          </div>
        }
      />

      {isEmpty(tableData.data) || isLoading ? (
        <React.Fragment>
          <div className="hidden md:block border border-t-0">
            <EmptyTable
              title={"No Team Member"}
              subtitle="You do not have any team member added"
              column={["", "Status", "Name", "Email", "Date", "Actions", ""]}
              loading={isLoading}
              button={
                <div className="flex justify-center mt-3">
                  <Button.Blue
                    title={"Create Team"}
                    onClick={() => history.push("/dashboard/team/add")}
                  />
                </div>
              }
            />
          </div>

          <div className="block md:hidden border border-t-0">
            <EmptyDesk row={[1, 2]} loading={isLoading} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="hidden md:block">
            {!isArray(tableData?.data) && !tableData?.length > 0? 
            <div className="w-full flex flex-col items-center justify-center">
              {" "}
              <EmptyCard
                title="No team Member"
                subtitle="You do not have any team member added "
                loading={isLoading}
              />
              <Button.Blue title={"Create Team"} onClick={() => history.push("/dashboard/team/add")} />
            </div>
            :
            <CardTableBody
              column={[
                "",
                <CheckboxInput
                  label={""}
                  name={"all"}
                  type={"checkbox"}
                  checked={selectAllCheckbox}
                  onChange={handleSelectAllCheckbox}
                  placeholder={""}
                  autoComplete={""}
                  className={"appearance-none"}
                />,
                "Status",
                "Name",
                "Email",
                "Date",
                "Actions",
                "",
              ]}
            >
              {isArray(tableData.data) && renderTeamsTable(tableData.data)}
            </CardTableBody>}
          </div>

          <div className="block md:hidden">
            <div className="bg-white px-6">
              {isArray(tableData.data) && renderTeamsDesk(tableData.data)}
            </div>
          </div>
        </React.Fragment>
      )}

      <CardTableFooter
        empty={isEmpty(tableData.data)}
        loading={isLoading}
        pageNumber={currentPage}
        totalPages={tableData.totalPages}
        prevOnClick={() => setCurrentPage(tableData.previousPage)}
        nextOnClick={() => setCurrentPage(tableData.nextPage)}
        thereIsPreviousPage={tableData.thereIsPreviousPage}
        thereIsNextPage={tableData.thereIsNextPage}
      />
      <TeamDeleteModal
        ref={TeamDeleteModalRef}
        deleteTeamList={deleteTeamList}
      />
      <TeamActivationToggleModal
        ref={ToggleModalRef}
        toggleActivationHandler={toggleActivationHandler}
      />
    </div>
  );
}
