import React, { useEffect, useState } from "react";
import TextInput from "../../inputs/TextInput";
import ReceiverFlag from "../../../components/transfer/ReceiverFlag";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import useUser from "../../../hooks/useUser";
import { isArray } from "@craco/craco/lib/utils";
import AllCurrencyLists from "../../../pages/payment-inflow/AllCurrencyLists";
import useTeam from "../../../hooks/useTeam";

const CurrencySelectionList = ({ onSelectionChange, defaultValue, title }) => {
  const user = useUser();
  const {team_user} = useTeam();
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [currenciesArray, setCurrenciesArray] = useState([]);
  const [defaultCurrencyArray, setDefaultCurrencyArray] = useState([]);
  const [input, setInput] = useState({
    currency: defaultValue,
    filter: "",
  });
  const [selectedCurrency, setSelectedCurrency] = useState("all");

  useEffect(() => {
    let existingCurrencies = isArray(
      user?.selectedBusinessOrPersonalAccount?.accounts
    )
      ? user?.selectedBusinessOrPersonalAccount?.accounts?.map((account) =>
          account?.currency?.toLowerCase()
        )
      : team_user?.employee?.is_teams
      ? team_user?.business?.accounts?.map((account) =>
          account?.currency?.toLowerCase()
        )
      : ["ngn"];

    setCurrenciesArray(existingCurrencies);
    setDefaultCurrencyArray(existingCurrencies);
  }, [searchDropDown]);

  return (
    <div>
      <div className=" mt-9 pl-10 w-5/12 lg:w-3/12">
        {!searchDropDown ? (
          <button
            className="flex items-center rounded p-2 border justify-between relative w-full"
            onClick={() => setSearchDropDown(!searchDropDown)}
          >
            {!input?.currency ? (
              <div className="glade-grey-dark">{title}</div>
            ) : (
              <div className="flex gap-3 glade-grey-dark items-center">
                {input?.currency.toUpperCase() !== "ALL" && (
                  <ReceiverFlag flagCode={input.currency.slice(0, -1)} />
                )}
                <div>
                  {input?.currency.toUpperCase() === "ALL"
                    ? "All Currencies"
                    : input?.currency.toUpperCase()}
                </div>
              </div>
            )}
            <RiArrowDropDownLine
              size={"28px"}
              className="cursor-pointer"
              color="#000000"
            />
          </button>
        ) : (
          <div>
            <div className="items-center relative">
              <TextInput
                value={input?.filter}
                placeholder={"Search Currency"}
                onChange={(e) => {
                  setInput({ ...input, filter: e.target.value });
                  let filteredArray = [];
                  if (e.target.value) {
                    currenciesArray?.forEach((currency) => {
                      if (
                        currency
                          ?.toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      ) {
                        filteredArray?.push(currency);
                      }
                    });
                    setCurrenciesArray(filteredArray);
                  } else {
                    setCurrenciesArray(defaultCurrencyArray);
                  }
                }}
              />
              <IoIosClose
                size={30}
                className="top-1 right-2 absolute"
                onClick={() => {
                  setInput({ ...input, filter: "" });
                  setCurrenciesArray(defaultCurrencyArray);
                  setSearchDropDown(!searchDropDown);
                }}
              />
            </div>
            <div>
              <div className="flex flex-col gap-2 p-2 top-2 bg-white glade-grey-dark border max-h-72 overflow-y-auto cursor-pointer">
                <option
                  value="all"
                  onClick={() => {
                    setInput({
                      ...input,
                      currency: "all",
                    });
                    setSelectedCurrency("all");
                    onSelectionChange("all");
                    setSearchDropDown(!searchDropDown);
                  }}
                >
                  {title}
                </option>

                {currenciesArray.map((field, index) => (
                  <div key={index}>
                    {
                      <div
                        className="flex gap-3 cursor-pointer items-center"
                        key={`bank_${index}`}
                        onClick={() => {
                          setInput({
                            ...input,
                            currency: field?.toUpperCase(),
                          });
                          setSelectedCurrency(field?.toUpperCase());
                          onSelectionChange(field);
                          setSearchDropDown(!searchDropDown);
                        }}
                      >
                        {input.currency.toUpperCase() !== "ALL" && (
                          <ReceiverFlag flagCode={field?.slice(0, -1)} />
                        )}
                        <div>{field?.toUpperCase()}</div>
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="">
        <AllCurrencyLists selectedCurrency={selectedCurrency} />
      </div>
    </div>
  );
};

export default CurrencySelectionList;
