import React, { useEffect } from "react";
import Button from "../../components/buttons/Button";
import { IoIosAdd } from "react-icons/io";
import useUser from "../../hooks/useUser";
import useBalances from "../../hooks/useBalances";
import { getCurrencyName } from "../../assets/icons/BalancesFlagIcons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllWalletBalances, getAllIFAccountCreatedAccount } from "../../redux/balances/balancesActions";
import { TextSkeleton } from "../../components/skeletons";
import {
  getPersonalAndBusinessAccounts,
  toggleSelectedCurrency,
} from "../../redux/auth/authActions";
import ReceiverFlag from "../../components/transfer/ReceiverFlag";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useTeam from "../../hooks/useTeam";

const Balances = () => {
  const user = useUser();
  const {business, team_user} = useTeam();
  const { balancesData, isLoadingBalances, isLoadingAllIFAccount, allIFAccountData } = useBalances();

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(getPersonalAndBusinessAccounts());
    if (user?.isBusiness) {
      dispatch(getAllWalletBalances());
      dispatch(getAllIFAccountCreatedAccount())
    }
  }, []);
  return (
    <div className="p-3">
      <div className="flex justify-between item-center border-b pb-6 pt-6">
        {(user?.isBusiness || business?.business_name) && (
          <div>
            <h2 className="glade-heading-two glade-home-dark">
              <span className="font-bold">
                {isLoadingBalances ? (
                  <TextSkeleton />
                ) : (
                  balancesData?.balance ?? balancesData?.data?.balance
                )}
              </span>
            </h2>
            <span className="text-glade-gray-400">
              Your total balance in {balancesData?.currency ?? balancesData?.data?.balance}
            </span>
          </div>
        )}
        <div className="flex">
          <p>
            {!team_user?.employee?.is_teams &&
            <Button.Blue
              title={"Send Money"}
              onClick={() => !location.pathname.includes('teams') ? history.push({ pathname: '/dashboard/fund-transfer', state: { showModal: true } }) : history.push({ pathname: '/teams/fund-transfer', state: { showModal: true } })}
            />}
          </p>
        </div>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-8 pt-6">
        {/* // Business/Personal accounts currency list */}
        {!user?.selectedBusinessOrPersonalAccount?.hasOwnProperty("currency") &&
          user.selectedBusinessOrPersonalAccount?.accounts?.length > 0
          ? user.selectedBusinessOrPersonalAccount?.accounts.map(
            (account, index) => (
              <div
                className="border p-6 h-60 flex flex-col justify-center rounded-md"
                key={index}
              >
                <div>
                  <ReceiverFlag flagCode={account?.currency?.slice(0, -1)} />
                </div>
                <h6 className="text-glade-gray-400 mt-4">
                  {getCurrencyName(account?.currency)}
                </h6>
                <p className="glade-off-black glade-heading-three mb-3">
                  {Number(account?.balance).toLocaleString("en-us")}{" "}
                  {account?.currency}
                </p>
                <button className="text-left">
                  {account.account_status === "active" && (
                    <p
                      className="glade-blue"
                      onClick={() =>
                        dispatch(
                          toggleSelectedCurrency(
                            user?.selectedBusinessOrPersonalAccount?.accounts[
                            index
                            ],
                            user?.selectedBusinessOrPersonalAccount
                          )
                        ) &
                        history.push(location?.pathname?.includes("teams") ? "/teams/account-details" : "/dashboard/account-details", {
                          index: index,
                        })
                      }
                    >
                      View Account Details
                    </p>
                  )}
                  {account.account_status === "pending" && (
                    <p className="glade-yellow">Pending</p>
                  )}
                </button>
              </div>
            )
          )
          : null}
          {/* Team Member Currency List Start*/}
          {(business?.accounts?.length > 0)
          ? business?.accounts.map(
            (account, index) => (
              <div
                className="border p-6 h-60 flex flex-col justify-center rounded-md"
                key={index}
              >
                <div>
                  <ReceiverFlag flagCode={account?.currency?.slice(0, -1)} />
                </div>
                <h6 className="text-glade-gray-400 mt-4">
                  {getCurrencyName(account?.currency)}
                </h6>
                <p className="glade-off-black glade-heading-three mb-3">
                  {Number(account?.balance).toLocaleString("en-us")}{" "}
                  {account?.currency}
                </p>
                <button className="text-left">
                  {account.account_status === "active" && (
                    <p
                      className="glade-blue"
                      onClick={() =>
                        
                        history.push("/teams/account-details", {
                          index: index,
                        })
                      }
                    >
                      View Account Details
                    </p>
                  )}
                  {account.account_status === "pending" && (
                    <p className="glade-yellow">Pending</p>
                  )}
                </button>
              </div>
            )
          )
          : null}
          {/* Team Member Currency List End*/}

        {isLoadingAllIFAccount ?  null :
          allIFAccountData?.length > 0 &&
          allIFAccountData.map(
            (account, index) => (
              <div
                className="border p-6 h-60 flex flex-col justify-center rounded-md"
                key={index}
              >
                <div>
                  <ReceiverFlag flagCode={account?.currency?.slice(0, -1)} />
                </div>
                <h6 className="text-glade-gray-400 mt-4">
                  {getCurrencyName(account?.currency)}
                </h6>

                <button className="text-left">
                  <p
                    className="glade-blue"
                    onClick={() =>
                      history.push("/dashboard/account-details", {
                        index: index,
                        currency: account?.currency
                      })
                    }
                  >
                    View Account Details
                  </p>
                </button>
              </div>
            )
          )
        }

        {user?.isBusiness && !location?.pathname?.includes("teams") && (
          <div className="border h-60 rounded-md p-6 flex flex-col items-center justify-center">

            <Link
              to={"/dashboard/add-currency"}
              className="glade-blue cursor-pointer"
            > <span className="glade-sidelink-hover">
                <IoIosAdd className="glade-bg-extra-light-blue p-2 w-10 h-10 rounded-full" />
              </span>
              Add a new currency
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Balances;