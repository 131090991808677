import React, { useContext, useState, Fragment, useEffect, useLayoutEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import NavbarDashboard from "../components/navbars/NavbarDashboard";
import FooterDashboard from "../components/footers/FooterDashboard";

// Pages
import Airtime from "../pages/airtime/Airtime";
import AirtimeDetails from "../pages/airtime/AirtimeDetails";
import NewAirtime from "../pages/airtime/NewAirtime";
import PayBills from "../pages/pay-bills/PayBills";
import PayBillsDetail from "../pages/pay-bills/PayBillsDetail";
import NewBill from "../pages/pay-bills/NewBill";
import Customers from "../pages/customers/Customers";
import CustomerDetail from "../pages/customers/CustomerDetail";
import CustomerDetailTransaction from "../pages/customers/CustomerDetailTransaction";
import FundAccount from "../pages/fund-account/FundAccount";
import FundTransfer from "../pages/fund-transfer/FundTransfer";
import FundTransferDetail from "../pages/fund-transfer/FundTransferDetail";
import FundTransferGlade from "../pages/fund-transfer-new/FundTransferGlade";
import FundTransferLocal from "../pages/fund-transfer-new/FundTransferLocal";
import FundTransferInternational from "../pages/fund-transfer-new/FundTransferInternational";
import TransferDetailsReview from "../pages/fund-transfer-new/TransferDetailsReview";
import FundTransferBulkManual from "../pages/fund-transfer/FundTransferBulkManual";
import FundTransferBulkFileUpload from "../pages/fund-transfer/FundTransferBulkFileUpload";
import FundTransferBulkFileUploadReview from "../pages/fund-transfer/FundTransferBulkFileUploadReview";
import Home from "../pages/home/Home";
import Invoice from "../pages/invoice/Invoice";
import CreateInvoice from "../pages/invoice/CreateInvoice";
import EditInvoice from "../pages/invoice/EditInvoice";
import InvoiceDetails from "../pages/invoice/InvoiceDetails";
import InvoicePreview from "../pages/invoice/InvoicePreview";
import PaymentInflow from "../pages/payment-inflow/PaymentInflow";
import PaymentInflowDetail from "../pages/payment-inflow/PaymentInflowDetail";
import PaymentPages from "../pages/payment-pages/PaymentPages";
import PaymentPageDetail from "../pages/payment-pages/PaymentPageDetail";
import PaymentPageCreatePage from "../pages/payment-pages/PaymentPageCreatePage";
import PaymentPageEditPage from "../pages/payment-pages/PaymentPageEditPage";
import PaymentPageCreateTicket from "../pages/payment-pages/PaymentPageCreateTicket";
import PaymentPageEditTicket from "../pages/payment-pages/PaymentPageEditTicket";
import Payday from "../pages/payroll/Payday";
import PaydayDetail from "../pages/payroll/PaydayDetail";
import PayrollStaff from "../pages/payroll/PayrollStaff";
import PayrollStaffCreate from "../pages/payroll/PayrollStaffCreate";
import PayrollStaffCreateBulkManual from "../pages/payroll/PayrollStaffCreateBulkManual";
import PayrollStaffCreateBulkFileUpload from "../pages/payroll/PayrollStaffCreateBulkFileUpload";
import PayrollStaffCreateBulkFileUploadReview from "../pages/payroll/PayrollStaffCreateBulkFileUploadReview";
import PayrollStaffEdit from "../pages/payroll/PayrollStaffEdit";
import Pos from "../pages/pos/Pos";
import PosDetail from "../pages/pos/PosDetail";
import PosDetailTransaction from "../pages/pos/PosDetailTransaction";
import RequestForPos from "../pages/pos/RequestForPos";
import Settings from "../pages/settings/Settings";
import SettingsProfile from "../pages/settings/SettingsProfile";
import SettingsSettlement from "../pages/settings/SettingsSettlement";
import SettingsSettlementAddBank from "../pages/settings/SettingsSettlementAddBank";
import SettingsApiAndWebhooks from "../pages/settings/SettingsApiAndWebhooks";
import SettingsManageServices from "../pages/settings/SettingsManageServices";
import SettingsManageServicesBAA from "../pages/settings/SettingsManageServicesBAA";
import SettingsBusinessCompliance from "../pages/settings/business-compliance/SettingsBusinessCompliance";
import Settlements from "../pages/settlements/Settlements";
import SettlementDetail from "../pages/settlements/SettlementDetail";
import SettlementTransactionDetail from "../pages/settlements/SettlementTransactionDetail";
import Store from "../pages/store/Store";
import TransactionSplit from "../pages/transaction-split/TransactionSplit";
import TransactionSplitDetail from "../pages/transaction-split/TransactionSplitDetail";
import Transactions from "../pages/transactions/Transactions";
import TransactionDetail from "../pages/transactions/TransactionDetail";
import NewSplitAccount from "../pages/transaction-split/NewSplitAccount";
import EditSplitAccount from "../pages/transaction-split/EditSplitAccount";
import CreateBusiness from "../pages/old-business/CreateBusiness";
import RegisterBusiness from "../pages/business/RegisterBusiness";
import RegisterBusinessName from "../pages/business/RegisterBusinessName";
import RegisterBusinessCompany from "../pages/business/RegisterBusinessCompany";
import RegisterBusinessNew from "../pages/business/RegisterBusinessNew";
import VirtualCardDetail from "../pages/virtual-cards/VirtualCardDetail";
import ExampleDashboard from "../pages/examples/ExampleDashboard";
import { motion } from 'framer-motion/dist/framer-motion';
import styled from "styled-components";
// User Authorization Handler
import InactiveMonitor from "../components/activity-monitor/InactiveMonitor";
import AccountMonitor from "../components/activity-monitor/AccountMonitor";
import { setAuthFromCache } from "../redux/auth/authActions";
import useUser from "../hooks/useUser";
import AccountLimits from "../pages/settings/AccountLimits";
import Sidebar from "../components/sidebars/Sidebar";
import { SideNavContext } from "../context/SideNavContext";
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import AccountActivationModal from "../components/modals/AccountActivationModal";
import Loans from "../pages/loans/Loans";
import Balances from "../pages/balances/Balances";
import { AddCurrency } from "../pages/addCurrency/AddCurrency";
import AccountDetails from "../pages/balances/AccountDetails";
import OldSettingsCompliance from "../pages/settings/OldBusinessCompliance";
import HomePersonalCompliance from "../pages/home/HomePersonalCompliance";
import PayrollStaffDetails from "../pages/payroll/PayrollStaffDetails";
import TeamDetails from "../pages/teams/TeamDetails";
import Teams from "../pages/teams/Teams";
import AddTeam from "../pages/teams/AddandEditTeam";
import Notification from "../pages/notifications/Notification";
import NotificationDetails from "../pages/notifications/NotificationDetails";
import VirtualCards from "../pages/virtual-cards/VirtualCards";
import VirtualCardCreateLayout from "../pages/virtual-cards/VirtualCardCreateLayout";
import VirtualCardPersonalizedModal from "../pages/virtual-cards/VirtualCardPersonalizedModal";
import ReportTransaction from "../pages/transactions/ReportTransactions";
import RecurringPage from "../pages/recurring payment/RecurringPage";
import CreateRecurring from "../pages/recurring payment/CreateRecurring";

export default function DashboardLayout() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const [openActivationModal, setActivationModal] = useState(false);

  const requireCompliance = (to, from, next) => {
    if (to.meta.auth) {
      if (to.meta.compliance === 'approved') {
        setActivationModal(false);
        next();
      } else {
        next.redirect('/dashboard/home');
        setActivationModal(true);

      }
    } else {
      setActivationModal(false);
      next();
    }
  };

  // Restore user details from cache
  useLayoutEffect(() => {
    dispatch(setAuthFromCache());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect to login page if user is not logged in
  useEffect(() => {
    if (!user.token) {
      history.push("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.token]);

  const { navCollapsed } = useContext(SideNavContext);

  return (
    <Fragment>
      {/* Monitor user activity */}
      <InactiveMonitor onLogout={() => history.push("/auth/logout")} duration={300000} />
      <AccountMonitor />

      {/* Sidebar */}
      <AppWindow navCollapsed={navCollapsed} isMobile={true}>
        {user?.token && <Sidebar />}
        <MainSC>
          {/* Navbar */}
          {user?.token && <NavbarDashboard />}

          {/* Body */}
          <section className="w-full">
            <GuardProvider guards={[requireCompliance]} >
              <Switch>
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/airtime" component={Airtime} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/airtime/airtime-detail" component={AirtimeDetails} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/airtime/new-airtime" component={NewAirtime} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/airtime/repeat-airtime" component={NewAirtime} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/business/create-business" component={CreateBusiness} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/business/register-business" component={RegisterBusiness} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/business/register-business/business-name" component={RegisterBusinessName} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/business/register-business/select-type" component={RegisterBusinessNew} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/business/register-business/business-company" component={RegisterBusinessCompany} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/cards" component={VirtualCards} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/virtual-cards/detail" component={VirtualCardDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/virtual-cards/create" component={VirtualCardCreateLayout} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/personalized-card" component={VirtualCardPersonalizedModal} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/balances" component={Balances} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/account-details" component={AccountDetails} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/add-currency" component={AddCurrency} />
                {/*
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/charge-backs" component={ChargeBacks} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliancegit }} exact path="/dashboard/charge-back-detail" component={ChargeBackDetails} />
                 */}
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/customers" component={Customers} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/customers/customer-detail/:email" component={CustomerDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/customers/customer-detail/transaction-detail" component={CustomerDetailTransaction} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-account" component={FundAccount} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer" component={FundTransfer} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/:transaction_type/detail" component={FundTransferDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/report-transfer" component={ReportTransaction} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer/bulk-transfer/manual" component={FundTransferBulkManual} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer/bulk-transfer/upload" component={FundTransferBulkFileUpload} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer/bulk-transfer/review" component={FundTransferBulkFileUploadReview} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer/single-transfer/glade" component={FundTransferGlade} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer/single-transfer/local" component={FundTransferLocal} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer/single-transfer/international" component={FundTransferInternational} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/fund-transfer/single-transfer/international/review" component={TransferDetailsReview} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/recurring-payment/:type" component={RecurringPage} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/recurring-payment/create/:recurring_type" component={CreateRecurring} />
                <GuardedRoute meta={{auth: user?.auth, name: 'home'}} exact path="/dashboard/home" component={Home} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/invoices" component={Invoice} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/invoice/create-invoice" component={CreateInvoice} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/invoice/edit-invoice" component={EditInvoice} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/invoice/invoice-detail" component={InvoiceDetails} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/invoice/preview-invoice" component={InvoicePreview} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/loans" component={Loans} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pay-bills/new-bill" component={NewBill} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pay-bills/repeat-bill" component={NewBill} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pay-bills" component={PayBills} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pay-bills/pay-bill-detail" component={PayBillsDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-inflow" component={PaymentInflow} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-inflow-detail" component={PaymentInflowDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-pages" component={PaymentPages} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-page-detail" component={PaymentPageDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-pages/create/page" component={PaymentPageCreatePage} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-pages/edit/page" component={PaymentPageEditPage} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-pages/create/ticket" component={PaymentPageCreateTicket} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payment-pages/edit/ticket" component={PaymentPageEditTicket} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/payday" component={Payday} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/payday-detail" component={PaydayDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/staff" component={PayrollStaff} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/staff/create/single" component={PayrollStaffCreate} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/staff/create/bulk/manual" component={PayrollStaffCreateBulkManual} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/staff/create/bulk/upload" component={PayrollStaffCreateBulkFileUpload} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/staff/create/bulk/review" component={PayrollStaffCreateBulkFileUploadReview} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/staff/edit" component={PayrollStaffEdit} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/payroll/staff/details" component={PayrollStaffDetails} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pos" component={Pos} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pos/pos-detail" component={PosDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pos/pos-detail/transaction-detail" component={PosDetailTransaction} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/pos/request-pos" component={RequestForPos} />
                <GuardedRoute meta={{ compliance: user.compliance }} exact path="/dashboard/settings" component={Settings} />
                <GuardedRoute meta={{ compliance: user.compliance }} exact path="/dashboard/settings/profile" component={SettingsProfile} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settings/settlement" component={SettingsSettlement} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settings/settlement/add-bank" component={SettingsSettlementAddBank} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settings/api-and-webhooks" component={SettingsApiAndWebhooks} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settings/manage-services" component={SettingsManageServices} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settings/account-limits" component={AccountLimits} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settings/manage-services/become-an-agent" component={SettingsManageServicesBAA} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settings/manage-services/request-pos" component={RequestForPos} />
                <GuardedRoute meta={{ compliance: user.compliance }} exact path="/dashboard/settings/compliance" component={OldSettingsCompliance} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settlements" component={Settlements} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settlements/settlement-detail" component={SettlementDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/settlements/settlement-detail/transaction-detail" component={SettlementTransactionDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/store" component={Store} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/transaction-split" component={TransactionSplit} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/transaction-split/transaction-split-detail" component={TransactionSplitDetail} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/transaction-split/new-split-account" component={NewSplitAccount} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/transaction-split/edit-split-account" component={EditSplitAccount} />
                <GuardedRoute exact path="/dashboard/transactions" component={Transactions} meta={{ auth: user?.token, compliance: user.compliance }} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/transactions/transaction-detail" component={TransactionDetail} />
                <GuardedRoute meta={{ auth: false, compliance: user.compliance }} exact path="/dashboard/home/business-compliance" component={SettingsBusinessCompliance} />
                <GuardedRoute meta={{ auth: false, compliance: user.compliance }} exact path="/dashboard/home/personal-compliance" component={HomePersonalCompliance} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/teams" component={Teams} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/team/details/:id" component={TeamDetails} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/team/add" component={AddTeam} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/team/edit/:id" component={AddTeam} />

                <GuardedRoute exact path="/dashboard/notifications" component={Notification} meta={{ auth: user?.token, compliance: user.compliance }} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/notifications/notification-detail" component={NotificationDetails} />
                <GuardedRoute meta={{ auth: user?.token, compliance: user.compliance }} exact path="/dashboard/example" component={ExampleDashboard} />
                <Redirect from="/dashboard" to="/dashboard/home" />
              </Switch>
            </GuardProvider>
          </section>

          {/* Account Actvation Modal */}
          {
            openActivationModal &&
            <AccountActivationModal
              openActivationModal={openActivationModal}
              setActivationModal={setActivationModal}
              complianceStatus={user?.compliance}
              isBusiness={user.isBusiness}
            />}

          {/* Footer */}
          <FooterDashboard />
        </MainSC>
      </AppWindow>
    </Fragment>
  );
}

const MainSC = styled(motion.main)`
  width: 100%;
  min-height: 112vh;
  overflow-y: hidden;
  background: #fff;
  padding: 1rem;
  
  &:hover {
    overflow-y: auto;
  }
`;

const AppWindow = styled(motion.div)`
  overflow: hidden;
  display: grid;
  grid-template-columns: ${({ navCollapsed }) => navCollapsed ? 0 : "18.75rem"} 4fr;

  @media screen and (max-width: 770px) {
    grid-template-columns: ${({ navCollapsed }) => navCollapsed ? 0 : "100%"} 4fr
  }
`;