import React from "react";
import { useState } from "react";
import { VirtualCardTemplate } from "../../assets/icons/Icons";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/inputs/TextInput";
import { useDispatch } from "react-redux";
// import useVirtualCards from "../../hooks/useVirtualCards";
import { editVirtualCard } from "../../redux/virtual-cards/virtualCardActions";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { BsCircleFill } from "react-icons/bs";

function EditNairaCard() {
  // const [toggleLimit, setToggleLimit] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  // const virtualCards = useVirtualCards();
  const location = useLocation();
  const [input, setInput] = useState({
    card_title: location?.state?.card_title,
    card_type: location?.state?.card_type,
    card_spend_limit: location?.state?.account_limit?.maximum_available_balance,
    card_color: location?.state?.design_code,
  });

  // const handleToggleLimit = () => {
  //   setToggleLimit(!toggleLimit);
  // };

  const onSubmit = () => {
    dispatch(
      editVirtualCard(
        input,
        {
          reference: location?.state?.reference,
        },
        () => {
          history.goBack();
        }
      )
    );
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  return (
    <div className="p-7">
      <div className="w-7/12">
        <TextInput
          label={"Card Name"}
          id={"card_title"}
          name={"card_title"}
          type={"text"}
          value={input?.card_title}
          onChange={onChange}
          className={"appearance-none"}
        />
      </div>

      <div className="mx-auto mt-6">
        <p className="mb-2 text-sm ">Card Color</p>
        <div className="mx-auto  flex gap-5">
          <div>
            {/* <VirtualCard color={input?.card_color} /> */}
            <VirtualCardTemplate
              card_type={input?.card_type}
              color={input?.card_color}
              title={input?.card_title}
            />
          </div>
          <div className="mt-4 flex justify-center items-center space-x-6">
            <BsCircleFill
              className="p-2"
              size={60}
              color={"#2C6DEA"}
              style={{
                border: input?.card_color === "#2C6DEA" ? "2px solid #2C6DEA" : "",
                borderRadius: "50%",
              }}
              onClick={() => setInput({...input , card_color: "#2C6DEA"})}
            />
            <BsCircleFill
              size={60}
              className="p-2"
              color={"#303030"}
              style={{
                border: input?.card_color === "#303030" ? "2px solid #303030" : "",
                borderRadius: "50%",
              }}
              onClick={() => setInput({...input , card_color: "#303030"})}
            />
            <BsCircleFill
              className="p-2"
              size={60}
              color={"#FF6F00"}
              style={{
                border: input?.card_color === "#FF6F00" ? "2px solid #FF6F00" : "",
                borderRadius: "50%",
              }}
              onClick={() => setInput({...input , card_color: "#FF6F00"})}
            />
          </div>
        </div>
      </div>
      {/* CODE BELOW SHOULDD BE ACTIVATED FOR SPENDING LIMIT */}
      {/* <div className="w-7/12 pt-5">
        <div
          className="flex gap-3 p-3 cursor-pointer"
          onClick={() => handleToggleLimit()}
        >
          <span className=" flex text-sm whitespace-nowrap">
            Spending Limit
          </span>
          <div className={`${toggleLimit ? "pl-80" : ""}`}>
            <div
              className={`rounded-full flex w-10 h-5 transition-all duration-500  ${
                toggleLimit === false ? " bg-gray-600 " : "bg-blue-500"
              } `}
            >
              <span
                className={`h-5 w-5 bg-white rounded-full transition-all duration-500 shadow-lg ${
                  toggleLimit ? "ml-5 w-6/12" : ""
                }`}
              ></span>
            </div>
          </div>
        </div>

        {toggleLimit && (
          <NumericInput
            leftSection={
              <span className="self-center border border-gray-300 rounded-sm p-2">
                NGN
              </span>
            }
            name={"card_spend_limit"}
            type={"number"}
            min={"0"}
            value={input?.card_spend_limit ?? ""}
            onChange={onChange}
            autoComplete={"card_spend_limit"}
            className={"appearance-none"}
          />
        )}
      </div> */}

      <div className="pt-8">
        <Button.Blue
          title={"Save Changes"}
          type={"button"}
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
}

export default EditNairaCard;
