import React from 'react';
import {FaChevronRight} from 'react-icons/fa';

export default function LinkWithIconRight({title, style, className, onClick=()=>{}, icon}) {
    return (
        <button 
            type="button" 
            style={style}
            onClick={()=>onClick()}
            className={"py-2 "+className}>
            <div className="flex items-center">
                <span className="ml-3 glade-normal-text-two glade-blue">
                    {title??'Title'}
                </span>
                <span className="ml-3 glade-blue">
                    {icon??<FaChevronRight />}
                </span>
            </div>
        </button>
    )
}
