import React from 'react';

export default function InactiveMonitor({onLogout=()=>{}, duration}) {

    const inactivityTime = () => {
        let time;

        window.addEventListener('load', resetTimer, true);
        const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        events.forEach(function(event) {
            document.addEventListener(event, resetTimer, true);
        });

        function logout() {
            if (process.env.REACT_APP_APP_ENV !== 'development') {
                onLogout();
            }
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, duration??6000);
        }
    };

    React.useLayoutEffect(() => {
        inactivityTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (null);
}
