const _sessionName = process.env.REACT_APP_SLUG+'_session';

const persistLastLogin = (payLoad, sessionName = _sessionName) => {
    return localStorage.setItem(sessionName, JSON.stringify(payLoad));
}

const retrievePersistedLastLogin = async (sessionName = _sessionName) => {
    return JSON.parse(await localStorage.getItem(sessionName));
}

const updatePersistedLastLogin = async (payLoad, sessionName = _sessionName) => {
    let currentSession = JSON.parse(await localStorage.getItem(sessionName));
    if (!currentSession?.data?.data || !payLoad?.data?.data){ console.error('Unable to update persisted data'); return false; }
    return localStorage.setItem(sessionName, JSON.stringify({
        ...currentSession,
        data:{
            ...currentSession.data,
            data:{
                ...currentSession.data.data,
                ...payLoad.data.data
            }
        }
    }));
}

const destroyLastLogin = (sessionName = _sessionName) => {
    return localStorage.removeItem(sessionName);
}

export {persistLastLogin, retrievePersistedLastLogin, updatePersistedLastLogin, destroyLastLogin};