import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardPlainWithButton, CardPlainWithButtonSection } from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import useFundTransfer from '../../hooks/useFundTransfer';
import { createSettlementAccount, updateSettlementAccount } from '../../redux/settings/settingsActions';
import { getBanks, resolveAccountDetails } from '../../redux/fund-transfer/fundTransferActions';
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';

export default function SettingsSettlementAddBank() {

  const history = useHistory();
  const dispatch = useDispatch();
  const fundTransfer = useFundTransfer();
  const [input, setInput] = React.useState({
    currency_id: '',
    currency_name: '',
    bank_code: '',
    bank_name: '',
    account_number: '',
    account_name: '',
    set_as_default: false,
  });

  React.useEffect(() => {

    // Get Banks
    dispatch(getBanks({ mode: 'internal' }));

    // Set input with previous data for edits
    if (history.location?.state?.account_number) {
      setInput({
        currency_id: history.location?.state?.currency ?? '',
        currency_name: history.location?.state?.currency ?? '',
        bank_code: history.location?.state?.bank_code ?? '',
        bank_name: history.location?.state?.bank_name ?? '',
        account_number: history.location?.state?.account_number ?? '',
        account_name: history.location?.state?.account_name ?? '',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (input.account_number.length === 10 && input.bank_code) {
      dispatch(
        resolveAccountDetails(
          { account_number: input.account_number, bank_code: input.bank_code, mode: 'external_local' },
          (resolveAccountDetails) => setInput({ ...input, account_name: resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id })
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.account_number, input.bank_code]);

  const onSubmit = () => {
    history.location?.state?.account_number ?
      dispatch(updateSettlementAccount({
        currency: input.currency_name,
        account_number: input.account_number,
        bank_code: input.bank_code,
        status: input.set_as_default, // This indicates if this will be the default account or not
        account_name: input.account_name,
        bank_name: input.bank_name,
        id: history.location?.state?.id,
      })) :
      dispatch(createSettlementAccount({
        currency: input.currency_name,
        account_number: input.account_number,
        bank_code: input.bank_code,
        status: input.set_as_default, // This indicates if this will be the default account or not
        account_name: input.account_name,
        bank_name: input.bank_name,
      }))

  }

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  const onSelectOfBank = (e) => {
    let data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data'));
    setInput({ ...input, bank_code: data.bank_code, bank_name: data.bank_name });
  }

  const onSelectOfCurrency = (e) => {
    let data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data'));
    setInput({ ...input, currency_id: data.currency_id, currency_name: data.currency });
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">

        <div className="mb-6">
          <BreadCrumbs title={'Settlement'} path={'/dashboard/settings?tab=1'} />
        </div>

        <CardPlainWithButton>
          <CardPlainWithButtonSection
            title={history.location?.state?.account_number ? 'Update Bank' : 'Add Bank'}
            loading={fundTransfer.isLoading}
            buttonTitle={history.location?.state?.account_number ? 'Update Bank' : 'Add Bank'}
            buttonOnClick={() => onSubmit()}
            buttonDisabled={fundTransfer.isLoading}>
            <div className="mt-6 px-14">
              <div className="space-y-6">

                <SelectInput
                  label={'Select Currency'}
                  id={'currency_id'}
                  name={'currency_id'}
                  value={input.currency_id ?? ''}
                  onChange={onSelectOfCurrency}
                  autoComplete={'currency_id'}
                  className={'appearance-none'}>
                  <option value={''} disabled={true}>Select Currency</option>
                  {[{ currency: 'NGN', currency_id: 1 }].map((item, key) => {
                    return (
                      <option key={key} value={item.currency_id} data={JSON.stringify(item)}>{item.currency}</option>
                    );
                  })}
                </SelectInput>

                <SelectInput
                  label={'Select Bank'}
                  id={'bank_code'}
                  name={'bank_code'}
                  value={input.bank_code ?? ''}
                  onChange={onSelectOfBank}
                  autoComplete={'bank_code'}
                  className={'appearance-none'}>
                  <option value={''} disabled={true}>Select A Bank</option>
                  {fundTransfer.banks && fundTransfer.banks.map((item, key) => {
                    return (
                      <option key={key} value={item.bank_code} data={JSON.stringify(item)}>{item.bank_name}</option>
                    );
                  })}
                </SelectInput>

                <NumericInput
                  label={'Account Number'}
                  id={'account_number'}
                  name={'account_number'}
                  type={'number'}
                  value={input?.account_number ?? ''}
                  onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value.substring(0, 10) })}
                  placeholder={'Enter account number'}
                  autoComplete={'account_number'}
                  className={'appearance-none'}
                />

                <TextInput
                  label={'Account Name'}
                  id={'account_name'}
                  name={'account_name'}
                  type={'text'}
                  disabled={true}
                  value={input.account_name ?? ''}
                  onChange={onChange}
                  placeholder={''}
                  autoComplete={''}
                  className={'appearance-none'}
                />

                <CheckboxInput
                  label={'Set as default'}
                  id={'set_as_default'}
                  name={'set_as_default'}
                  type={'checkbox'}
                  checked={input.set_as_default}
                  onChange={(e) => setInput({ ...input, [e.target.name]: e.target.checked })}
                  autoComplete={''}
                  className={'appearance-none'}
                />

              </div>
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>
    </div>
  )
}
