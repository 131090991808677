export const GET_NOTIFICATIONS_LOADING = 'GET_NOTIFICATIONS_LOADING';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const MARK_NOTIFICATION_LOADING = 'MARK_NOTIFICATION_LOADING';
export const MARK_NOTIFICATION_SUCCESS = 'MARK_NOTIFICATION_SUCCESS';
export const MARK_NOTIFICATION_FAILURE = 'MARK_NOTIFICATION_FAILURE';

export const CLEAR_NOTIFICATIONS_LOADING = 'CLEAR_NOTIFICATIONS_LOADING';
export const CLEAR_NOTIFICATIONS_SUCCESS = 'CLEAR_NOTIFICATIONS_SUCCESS';
export const CLEAR_NOTIFICATIONS_FAILURE = 'CLEAR_NOTIFICATIONS_FAILURE';

export const GET_IN_APP_NOTIFICATIONS_LOADING = 'GET_IN_APP_NOTIFICATIONS_LOADING';
export const GET_IN_APP_NOTIFICATIONS_SUCCESS = 'GET_IN_APP_NOTIFICATIONS_SUCCESS';
export const GET_IN_APP_NOTIFICATIONS_FAILURE = 'GET_IN_APP_NOTIFICATIONS_FAILURE';