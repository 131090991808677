import React from "react";
import { useDispatch } from "react-redux";
import useBusiness from "../../hooks/useBusiness";
import { getBusinessTypeCacFee } from "../../redux/business/businessActions";
import Button from "../../components/buttons/Button";
import { Notice } from "../../assets/icons/Icons";

export default function FeePayment({ onClick, input, setInput }) {
  const dispatch = useDispatch();
  const business = useBusiness();

  React.useEffect(() => {
    input?.business_category?.length > 0 && dispatch(getBusinessTypeCacFee(input.business_category.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.business_category]);

  const handleSubmit = () => {
    onClick(input.business_category)
  }
  return (
    <div>
      <h2 className="text-lg font-semibold text-center text-glade-black-800 mb-2">Confirmation</h2>
      <p className="text-sm text-glade-black-800 text-center">The service fee will be deducted from your NGN balance</p>
      <div className="bg-white mt-8">
        <div className="flex items-center justify-between mt-3 mb-10 text-sm">
          <div className="text-glade-gray-500">Service fee</div>
          <div className="text-glade-black-800">NGN {business?.businessCacTypeFee && Number(business?.businessCacTypeFee).toLocaleString("en-us")}</div>
        </div>
        <div className="mb-10  flex bg-gray-200 rounded-lg text-gray dark:bg-gray-700 p-3">
          <span className="mt-2">
            <Notice />
          </span>
          <p className="glade-blue text-sm">
            By clicking ‘Pay’ you confirm that all the information provided is correct.
          </p>
        </div>

        <div className="flex items-center justify-center">
          <Button.Blue title={`Pay NGN ${business?.businessCacTypeFee && Number(business?.businessCacTypeFee).toLocaleString("en-us")}`} onClick={handleSubmit} disabled={business.isSubmittingBusiness} loading={business.isSubmittingBusiness} />
        </div>
      </div>


    </div>
  );
}


