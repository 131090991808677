import React from "react";
import { Grommet, Locate, SafeLine } from "../../../assets/icons/Icons";
import Button from "../../buttons/Button";
import { WALLET_CUR } from "../../../assets/images/images";

const GetStarted = ({ next }) => {
  return (
    <div className="lg:w-1/3 md:w-1/2 w-full  flex flex-col items-center ">
      <div className="mt-6 text-center">
        <div className="glade-heading-three glade-home-dark">
          Get started with Multi-Currency Account
        </div>
        <div className="mt-2 glade-grey-dark">
          Select a currency you wish to add
        </div>
        <div className="mt-10 justify-center flex items-center">
          <img src={WALLET_CUR} alt={"money transfer"} />
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex gap-5 flex-row items-start mt-8">
          <SafeLine color={"#1634A1"} />
          <div>
            <p className="glade-heading-five glade-home-dark">
              Open multi-currency accounts
            </p>
            <p className="glade-grey-dark">
              Open domestic and foreign currency accounts to receive, hold money
              in different currencies; USD, EUR, GBP, and NGN.
            </p>
          </div>
        </div>
        <div className="flex gap-5 flex-row items-start mt-5">
          <Grommet color={"#1634A1"} />
          <div>
            <p className="glade-heading-five glade-home-dark">
              Avoid fees and high exchange rates
            </p>
            <p className="glade-grey-dark">
              Send and receive payments to suppliers, contractors, agencies,
              employees and freelancers around the world in local currencies.
            </p>
          </div>
        </div>
        <div className="flex gap-5 flex-row items-start mt-5">
          <Locate color={"#1634A1"} />
          <div>
            <p className="glade-heading-five glade-home-dark">
              Transfer to cards, e-wallets, and bank accounts.
            </p>
            <p className="glade-grey-dark">
              Send multiple payments at once to cards, e-wallets, and bank
              accounts.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full mt-8 flex justify-center">
        <Button.Blue
          title={"Lets Go"}
          type={"button"}
          className={"py-3"}
          onClick={() => next()}
        />
      </div>
    </div>
  );
};

export default GetStarted;
