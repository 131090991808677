import {
    LOADING_SETTLEMENTS,
    LOADING_SETTLEMENT_TRANSACTIONS,
    RELOAD_SETTLEMENTS,
    GET_ALL_SETTLEMENTS,
    GET_SETTLED_SETTLEMENTS,
    GET_UNSETTLED_SETTLEMENTS,
    GET_SETTLEMENT_TRANSACTIONS,
    CREATE_SETTLEMENT_ACCOUNT,
    EDIT_SETTLEMENT_ACCOUNT,
    GET_SETTLEMENT_ACCOUNTS
} from "./types";

const initialState = {
    isLoading:false,
    isLoadingTransactions:false,
    shouldReload:false,
    settlementIndex:[],
    settledSettlementIndex:[],
    unsettledSettlementIndex:[],
    settlementTransactions:[],
    createSettlementAccount:{},
    updateSettlementAccount:{},
    settlementAccounts:[]
}

export default function settlementReducer (state=initialState, action) {
    switch (action.type) {

        case LOADING_SETTLEMENTS:
            return {
                ...state,
                isLoading: action.payLoad,
            };

        case LOADING_SETTLEMENT_TRANSACTIONS:
            return {
                ...state,
                isLoadingTransactions: action.payLoad,
            };

        case RELOAD_SETTLEMENTS:
            return {
                ...state,
                shouldReload: !state.shouldReload,
            };
        
        case GET_ALL_SETTLEMENTS:
            return {
                ...state,
                settlementIndex: action.payLoad.data.data,
            };

        case GET_SETTLED_SETTLEMENTS:
            return {
                ...state,
                settledSettlementIndex: action.payLoad.data.data,
            };

        case GET_UNSETTLED_SETTLEMENTS:
            return {
                ...state,
                unsettledSettlementIndex: action.payLoad.data.data,
            };

        case GET_SETTLEMENT_TRANSACTIONS:
            return {
                ...state,
                settlementTransactions: action.payLoad.data.data,
            };

        case CREATE_SETTLEMENT_ACCOUNT:
            return {
                ...state,
                createSettlementAccount: action.payLoad.data.data,
            };

        case EDIT_SETTLEMENT_ACCOUNT:
            return {
                ...state,
                updateSettlementAccount: action.payLoad.data.data,
            };

        case GET_SETTLEMENT_ACCOUNTS:
            return {
                ...state,
                settlementAccounts: action.payLoad.data.data,
            };

        default:
            return state;
    }
}