import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { FormWizard, FormWizardSection } from '../../components/form/FormWizard';
import IndividualInput from '../../components/inputs/IndividualInput';
import useUser from "../../hooks/useUser";
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/buttons/Button';
import { initPasswordResetAction, completePasswordResetAction, resendEmailVerificationAction } from "../../redux/auth/authActions";

// Analytics
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { CloseIcon } from '../../assets/icons/Icons';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { IoCheckmark } from 'react-icons/io5';
import { RiCloseFill } from 'react-icons/ri';
import { isEmail } from '../../helpers/helper';
import OnboardingLayout from './OnboardingLayout';

export default function RestPassword() {

  // Analytics
  if (process.env.REACT_APP_APP_ENV === 'production') {
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true });
    mixpanel.track('Reset Password Page');
  }

  const formWizardRef = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();

  const [input, setInput] = React.useState({
    user_uuid: user?.verifyUserEmail?.user_uuid,
    email: "",
    new_password: "",
    confirm_password: "",
    verification_code: "",
  });
  const [inputError, setInputError] = useState({
    verification_code: false
  });
  const [secondsLeft, setsecondsLeft] = useState(59);
  const [minutesLeft, setMinutesLeft] = useState(4);
  const [hideTime, setHideTime] = useState(false);
  const [otpIssues, setOtpIssues] = useState(false);
  const [passwordField, setPasswordField] = useState('password');




  useEffect(() => {
    let intervalCall = setInterval(() => {

      if (minutesLeft === 0 && secondsLeft === 0) {
        setHideTime(true);
      } else if (minutesLeft >= 0 && secondsLeft >= 0) {
        if (secondsLeft > 0) setsecondsLeft(secondsLeft - 1);
        if (secondsLeft === 0 && minutesLeft > 0) {
          setMinutesLeft(minutesLeft - 1);
          setsecondsLeft(59)
        }
      }

    }, 1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);


  useEffect(() => {
    if (user.token) {
      return history.push('/dashboard/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updatePassword = () => {
    if (input?.new_password && input?.new_password === input?.confirm_password) {
      dispatch(completePasswordResetAction(input, () => history.push('/auth/login')));
    } else {
    }
  };

  const resendEmailVerification = () => {
    dispatch(resendEmailVerificationAction(input, () => { }));
  };

  const verifyEmail = () => {
    formWizardRef.current.next();
  };

  const forgotPassword = () => {
    dispatch(initPasswordResetAction(input, () => formWizardRef.current.next()));
  };


  const togglePasswordField = (text) => {
    setPasswordField(text);
  }

  const onChange = (e) => {
    if (e.target.name === 'email') {
      if (isEmail(e.target.value)) {
        setInputError({
          ...inputError, [e.target.name]: false
        });
      } else {
        setInputError({
          ...inputError, [e.target.name]: true
        });
      }
      setInput({ ...input, [e.target.name]: e.target.value });
    } else if (e.target.name === 'confirm_password') {
      if (e.target.value !== input.new_password) {
        setInputError({
          ...inputError, [e.target.name]: true
        })
      } else {
        setInputError({
          ...inputError, [e.target.name]: false
        })
      }
      setInput({ ...input, [e.target.name]: e.target.value });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };


  // eslint-disable-next-line no-useless-escape
  const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const oneNumberRegex = /\d/;
  const oneCapitalLetter = /[A-Z]/;

  return (
    <OnboardingLayout>
      <div className="w-full max-w-md">
        <div className="w-full flex items-center justify-center">
          <FormWizard ref={formWizardRef}>

            {/* Forgot password Section */}
            <FormWizardSection position={0}>
              <div className="w-full max-w-md">
                <div className="flex flex-col justify-center">
                  <h3 className="flex justify-center text-center mt-6 glade-heading-three glade-black">Forgot your password?</h3>
                  <p className="flex justify-center text-center mt-2 glade-subtitle-two glade-grey-dark">Enter your email address below to begin the reset process.</p>
                </div>
                <div className="mt-6 space-y-6">
                  <div>
                    <TextInput
                      label={'Email'}
                      id={'email'}
                      name={'email'}
                      type={'email'}
                      value={input.email}
                      onChange={onChange}
                      placeholder={'example@email.com'}
                      autoComplete={'email'}
                      className={`appearance-none`}
                      errorState={inputError.email}
                    />
                    {inputError.email && <p className="glade-form-fail">Enter a valid email</p>}
                  </div>
                  <div className="pt-3 flex justify-center">
                    <Button.Blue
                      title={'Submit'}
                      type={'submit'}
                      className={'py-3'}
                      onClick={forgotPassword}
                      disabled={user.isLoading || !isEmail(input.email) || !input.email}
                      loading={user.isLoading}
                    />
                  </div>
                </div>
              </div>
            </FormWizardSection>

            {/* OTP Section */}
            <FormWizardSection position={1}>
              <div className="w-full max-w-md">
                <div className="flex flex-col justify-center">
                  <h3 className="flex justify-center text-center mt-6 glade-heading-three glade-black">Code verification</h3>
                  <p className="flex justify-center text-center mt-2 glade-subtitle-two glade-black">If {input.email} exists, you will receive a verification code. Enter the code to reset your password.</p>
                </div>
                {inputError?.verification_code && <div className="pt-3 flex justify-center w-full">
                  <Button.DangerBackground
                    title={'Invalid verification code'}
                    type={'button'}
                    className={'py-3 glade-bg-danger w-full'}
                    disabled={true}
                  />
                </div>}
                <div className="mt-6 space-y-6">

                  <div className="mt-1 flex justify-center w-full">
                    <IndividualInput
                      label={''}
                      onChange={(value) => {
                        setInputError({ ...inputError, verification_code: false });
                        setInput({ ...input, verification_code: value })
                      }}
                    />
                  </div>
                  <div className="pt-3 flex justify-center items-center">
                    <Button.Blue
                      title={'Submit'}
                      type={'button'}
                      className={'py-3'}
                      onClick={verifyEmail}
                      disabled={user.isLoading || input?.verification_code?.length !== 6}
                      loading={user.isLoading}
                    />
                  </div>
                  {!hideTime &&
                    <p className='mt-3 glade-normal-text-two glade-blue text-center'>This code will expire in {`${minutesLeft}`} mins {`${secondsLeft < 10 ? ' 0' : ' '}${secondsLeft}`} secs</p>
                  }

                  <div className='flex items-center justify-between'>
                    <p className="mt-3 flex justify-center glade-normal-text-two">
                      <span className="mr-1 glade-black">Didn’t receive code? </span>
                      <span className="glade-normal-text-two glade-blue cursor-pointer" onClick={resendEmailVerification}>Resend Code</span>
                    </p>
                    <span className='glade-normal-text-two glade-blue cursor-pointer mt-3' onClick={() => setOtpIssues(true)}> Still having issues?</span>
                  </div>
                </div>
              </div>
            </FormWizardSection>

            {/* Reset Password Section */}
            <FormWizardSection position={2}>
              <div className="w-full max-w-md">
                <div className="flex flex-col justify-center">
                  <h3 className="flex justify-center text-center mt-6 glade-heading-three glade-black">Enter your new password</h3>
                </div>
                <div className="mt-6 space-y-6 relative">
                  <div>
                    <TextInput
                      rightSection={passwordField === 'password' ? <BsEye className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('text')} /> : <BsEyeSlash className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('password')} />}
                      label={'Password'}
                      id={'new_password'}
                      name={'new_password'}
                      type={passwordField}
                      value={input.new_password}
                      onChange={onChange}
                      placeholder={'********'}
                      autoComplete={'new_password'}
                      className={'appearance-none relative'}
                    />

                    {input?.new_password &&
                      <div className='flex gap-2 pt-3 pb-2'>
                        <div className={`rounded-full h-1 w-3/12 ${input.new_password.length > 7 ? 'glade-bg-green-light' : input.new_password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                        <div className={`rounded-full h-1 w-3/12 ${input.new_password.length > 7 ? 'glade-bg-green-light' : input.new_password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                        <div className={`rounded-full h-1 w-3/12 ${input.new_password.length > 7 ? 'glade-bg-green-light' : input.new_password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                        <div className={`rounded-full h-1 w-3/12 ${input.new_password.length > 7 ? 'glade-bg-green-light' : input.new_password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                        <div className={`rounded-full h-1 w-3/12 ${input.new_password.length > 7 ? 'glade-bg-green-light' : input.new_password.length > 4 ? 'glade-bg-yellow' : 'glade-bg-grey'}`}></div>
                      </div>
                    }
                    {input?.new_password &&
                      <div>
                        <p className='glade-grey-dark flex items-center gap-2'>{input.new_password.length > 7 ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Password should be 8 or more characters </p>
                        <p className='glade-grey-dark flex items-center gap-2'>{oneCapitalLetter.test(input.new_password) ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Have at least one upper case letter</p>
                        <p className='glade-grey-dark flex items-center gap-2'>{oneNumberRegex.test(input.new_password) ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Have at least one number	</p>
                        <p className='glade-grey-dark flex items-center gap-2'>{specialCharacterRegex.test(input.new_password) ? <IoCheckmark className='glade-form-mark' /> : <RiCloseFill className='glade-form-fail' />} Have at least one special character {`(!@#$%^&*()_+)`}</p>

                      </div>}
                  </div>

                  <div>
                    <TextInput
                      rightSection={passwordField === 'password' ? <BsEye className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('text')} /> : <BsEyeSlash className='self-center rounded-sm absolute right-5 cursor-pointer' onClick={() => togglePasswordField('password')} />}
                      label={'Confirm Password'}
                      id={'confirm_password'}
                      name={'confirm_password'}
                      type={passwordField}
                      value={input.confirm_password}
                      onChange={onChange}
                      placeholder={'********'}
                      autoComplete={'confirm_password'}
                      className={'appearance-none relative'}
                      errorState={inputError.confirm_password}
                    />
                    <span className='glade-small-text-one glade-grey-cool'>Enter same password as before to confirm again</span>

                    {inputError.confirm_password &&
                      <p className='glade-form-fail'>
                        Password doesn’t match
                      </p>}
                  </div>


                  <div className="pt-3 flex justify-center">
                    <Button.Blue
                      title={'Submit'}
                      type={'submit'}
                      className={'py-3'}
                      onClick={updatePassword}
                      disabled={user.isLoading || !input?.new_password || input?.new_password !== input?.confirm_password}
                      loading={user.isLoading}
                    />
                  </div>
                  <p className="mt-2 glade-normal-text-two text-center">
                    <span className="mr-1 glade-black">Remember your password?</span>
                    <a href="/auth/login" className="glade-normal-text-two glade-blue">Sign In</a>
                  </p>
                </div>
              </div>
            </FormWizardSection>
          </FormWizard>
        </div>
      </div>
      {otpIssues && (
        <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-32 flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <div className="self-center bg-white rounded md:w-1/3 py-5 h-full">
              <div className="mb-4 border-b w-full flex items-center justify-between">
                <span className='glade-heading-five pl-2 pb-3'>Issues receiving OTP?</span>
                <button onClick={() => setOtpIssues(false)} className="float-right pr-5 pb-3">
                  <CloseIcon />
                </button>
              </div>
              <div className="mx-auto mb-4 flex flex-col space-y-4 p-3 glade-heading-five">
                <p>If you didn’t receive an OTP in your inbox, one of these must have happened</p>
                <p>1. You entered a wrong email</p>
                <p>2. Your OTP is in your spam folder</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </OnboardingLayout>
  )
}
