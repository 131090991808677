import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import {getBeneficiaries, getUsertagBeneficiaries} from "../../redux/fund-transfer/fundTransferActions";
import { HeadlessRadioGroup } from "../../components/radios/HeadlessRadioGroup";
import useFundTransfer from "../../hooks/useFundTransfer";
import { TransferFormContext } from '../../context/TransferFormContext';

export default React.forwardRef(function SelectBeneficiaryModal(props,ref) {

    const dispatch = useDispatch();
    const fundTransfer = useFundTransfer();
  const {  gladeTransferType , formType} = useContext(TransferFormContext);


    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    React.useEffect(() => {
        if(formType === "glade" && gladeTransferType === "usertag"){
            dispatch(getUsertagBeneficiaries())
          }else{
        dispatch(getBeneficiaries({ mode: props?.mode??"external" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
          }
    }, []);

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Select Beneficiary'}
            modelContentSection={
                <HeadlessRadioGroup
                    data={fundTransfer.fundTransferBeneficiaries}
                    preInput={props.beneficiary}
                    returnInput={(value)=>{ props.onSelectOfBeneficiary(value); plainModalRef.current.close(); }}
                />
            }
        />
    )
})
