export const LOADING_RECURRING_LIST = "LOADING_RECURRING_LIST";
export const SUCCESS_RECURRING_LIST = "SUCCESS_RECURRING_LIST";
export const FAILURE_RECURRING_LIST = "FAILURE_RECURRING_LIST";

export const LOADING_RECURRING_EDIT = "LOADING_RECURRING_EDIT";
export const SUCCESS_RECURRING_EDIT = "SUCCESS_RECURRING_EDIT";
export const FAILURE_RECURRING_EDIT = "FAILURE_RECURRING_EDIT";

export const LOADING_CREATE_RECURRING = "LOADING_CREATE_RECURRING";
export const SUCCESS_CREATE_RECURRING = "SUCCESS_CREATE_RECURRING";
export const FAILURE_CREATE_RECURRING = "FAILURE_CREATE_RECURRING";
