import React, { Fragment, useEffect, useState, } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../../components/breadcrumbs/BreadCrumbs";
import useSettings from "../../../hooks/useSettings";
import {
  getComplianceIdentityTypes,
} from "../../../redux/settings/settingsActions";
import NoticeModal from '../../../components/modals/NoticeModal';
import { FormStateIndicator } from "./FormStateIndicator";
import { BusinessInformation } from "./BusinessInformation";
import { OwnerInformation } from "./OwnerInformation";
import AddOwnerInformation from "./AddOwnerInformation";
import { BusinessDocument } from "./BusinessDocument";
import { isEmptyObject } from "../../../helpers/helper";
import BusinessCountry from "./BusinessCountry"

export default function SettingsBusinessCompliance() {

  const NoticeModalRef = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentFormState, setCurrentFormState] = useState("business information")
  const [ownerFormType, setOwnerFormType] = useState("");
  const [ownerForm, setOwnerForm] = useState({});
  const [input, setInput] = useState({
    business_type: "",
    industry: "",
    business_description: "",
    secondary_phone: "",
    business_address: "",
    business_lga: "",
    business_state: "",
    business_proof_of_address: {},
    tin: "",
    rc_number: "",
    bn_number: "",
    tin_certificate: {},
    business_name_certificate: {},
    certificate_of_incorporation: {},
    business_directors_document: {},
    scuml: {},
    cac_it_form: {},
    compliance_country: "",
    compliance_state: "",
    secondary_phone_code: "",
    country_code: "",
    id_number: "",
    company_website: "",
    secondary_address: "",
    postal_code: "",
    have_political_foreign_office: "",
    other_supporting_documents: {},
    hear_about_us_others: "",
    hear_about_us: "",
    reg_number: ""
  });

  const [ownerList, setOwnerList] = useState({});
  const [directorList, setDirectorList] = useState([]);
  const [trusteeList, setTrusteeList] = useState([]);
  const [proceed, setProceed] = useState(false);


  useEffect(() => {
    if (ownerFormType === 'owner') {
      setOwnerForm({
        type: 'owner', data: [
          { name: 'id_card_type', type: 'select', label: 'ID Type', errorText: "Select an ID Type" },
          { name: 'id_card_number', type: 'text', label: 'ID Number', placeholder: 'Enter ID Number', errorText: 'Enter a valaid ID Number' },
          { name: 'id_card', type: 'file', label: 'ID Upload', showSupport: true, supportText: 'Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.' },
          { name: 'address', type: 'text', label: 'Residential Address', placeholder: 'Enter Residential Address', errorText: "Residential Address is Invalid" },]
      })
    } else if (ownerFormType === 'director' || ownerFormType === 'trustee') {
      setOwnerForm({
        type: ownerFormType, data: [
          { name: 'address', type: 'text', label: 'Residential Address', placeholder: 'Enter Residential Address', errorText: "Residential Address is Invalid" },
          { name: 'bvn', type: 'number', label: 'BVN', placeholder: 'Enter BVN', errorText: "BVN value is invalid" },
          { name: 'id_card_type', type: 'select', label: 'ID Type', errorText: "Select an ID Type" },
          { name: 'id_card_number', type: 'text', label: 'ID Number', placeholder: 'Enter ID Number', errorText: 'Enter a valaid ID Number' },
          { name: 'id_card', type: 'file', label: 'ID Upload', showSupport: true, supportText: 'Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.' },
        ]
      })
    }
  }, [ownerFormType])

  useEffect(() => {
    dispatch(getComplianceIdentityTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proceedToSubmitCompliance = () => {
    setProceed(!proceed);
  }

  return (
    <>
      {proceed ?
        <Fragment>
          <div className="px-3 py-0">
            <div className="mb-9 md:hidden">
              <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
            </div>
            <div className={`${input.country_code.toLowerCase() !== 'nga' ? 'lg:w-5/12' : 'lg:w-7/12'} mx-auto border border-gray-150 py-8 rounded-md`}>
              {
                currentFormState !== "owner information form" &&
                <div className="px-12">
                  <FormStateIndicator formStep={currentFormState} input={input} />
                </div>
              }
              {currentFormState === "business information" && <BusinessInformation setFormLevel={setCurrentFormState} input={input} setInput={setInput} back={proceedToSubmitCompliance} />}
              {currentFormState === "owner information" && <OwnerInformation setFormLevel={setCurrentFormState} businessType={input?.business_type} setOwnerFormType={setOwnerFormType} ownerList={ownerList} setOwnerList={setOwnerList} directorList={directorList} setDirectorList={setDirectorList} trusteeList={trusteeList} setTrusteeList={setTrusteeList} />}
              {currentFormState === "owner information form" && !isEmptyObject(ownerForm) && <AddOwnerInformation formFields={ownerForm} setFormLevel={setCurrentFormState} setOwnerList={setOwnerList} directorList={directorList} trusteeList={trusteeList} setDirectorList={setDirectorList} setTrusteeList={setTrusteeList} input={input} />}
              {currentFormState === "business documents" && <BusinessDocument setFormLevel={setCurrentFormState} input={input} setInput={setInput} />}

            </div>
          </div>



          <NoticeModal ref={NoticeModalRef} onOpen={() => { }} onClose={() => history.go('/dashboard/settings?tab=0')} />
        </Fragment> :
        <BusinessCountry onClick={proceedToSubmitCompliance} input={input} setInput={setInput} />
      }

    </>
  );
}
