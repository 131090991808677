import React from 'react';
import { useDispatch } from 'react-redux';
import useFundTransfer from '../../hooks/useFundTransfer';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import {getBanks, resolveAccountDetails, rememberBulkFundTransferList} from "../../redux/fund-transfer/fundTransferActions";
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import NumericInput from '../../components/inputs/NumericInput';

export default React.forwardRef(function FundTransferBulkFileUploadReviewModal(props,ref) {

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
        setStateAndOpen: (value) => { setInput({...input,...value}); plainModalRef.current.open(); },
    }));

    const dispatch = useDispatch();
    const fundTransfer = useFundTransfer();

    const [input, setInput] = React.useState({
        id: '',
        bank_code: "",
        bank_name: "",
        account_number: "",
        account_name: "",
        amount: "",
        note: "",
    });

    React.useEffect(() => {
        dispatch(getBanks({ mode: "internal" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (input.account_number.length === 10 && input.bank_code) {
            dispatch(
                resolveAccountDetails(
                  {account_number:input.account_number,bank_code:input.bank_code,mode:'external_local'},
                  (resolveAccountDetails)=>setInput({...input,account_name:resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id})
                )
            );
        } else {
            setInput({...input,account_name:''});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input.account_number,input.bank_code]);

    const onSubmit = () => {
        let newBulkTransferList = fundTransfer.bulkTransferList.filter((item) => item.id !== input.id);
        newBulkTransferList.push(input);
        dispatch(rememberBulkFundTransferList(newBulkTransferList));
        plainModalRef.current.close();
    }

    const onChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    const onSelectOfBank = (e) => {
        let data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data'));
        setInput({...input, bank_code:data.bank_code, bank_name:data.bank_name});
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Edit Transfer Details'}
            modelContentSection={
                <div className="flex flex-col space-y-6">
                    <div className="flex flex-col px-9 pt-9 text-left space-y-3">

                        <SelectInput 
                            label={'Select Bank'}
                            id={'bank_code'}
                            name={'bank_code'}
                            value={input.bank_code ?? ''}
                            onChange={onSelectOfBank}
                            autoComplete={'bank_code'}
                            className={'appearance-none'}>
                            <option value={''} disabled={true}>Select A Bank</option>
                            {fundTransfer.banks && fundTransfer.banks.map((item, key)=>{
                                return (
                                    <option key={key} value={item.bank_code} data={JSON.stringify(item)}>{item.bank_name}</option>
                                );
                            })}
                        </SelectInput>

                        <NumericInput 
                            label={'Account Number'}
                            id={'account_number'}
                            name={'account_number'}
                            type={'number'}
                            value={input.account_number}
                            onChange={(e)=>setInput({...input,[e.target.name]:e.target.value.substring(0,10)})}
                            placeholder={'Enter account number'}
                            autoComplete={'account_number'}
                            className={'appearance-none'}
                        />

                        <TextInput 
                            label={'Account Name'}
                            id={'account_name'}
                            name={'account_name'}
                            disabled={true}
                            type={'text'}
                            value={input.account_name}
                            onChange={onChange}
                            placeholder={''}
                            autoComplete={'account_name'}
                            className={'appearance-none'}
                        />

                        <NumericInput 
                            label={'Enter Amount'}
                            id={'amount'}
                            name={'amount'}
                            type={'number'}
                            value={input.amount}
                            onChange={onChange}
                            placeholder={'Enter amount'}
                            autoComplete={'amount'}
                            className={'appearance-none'}
                        />

                        <TextInput 
                            label={'Note'}
                            id={'note'}
                            name={'note'}
                            type={'text'}
                            value={input.note}
                            onChange={onChange}
                            placeholder={'Enter description (optional)'}
                            autoComplete={'note'}
                            className={'appearance-none'}
                        />
                    </div>

                    <div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
                        <div>
                            <Button.Blue onClick={()=>plainModalRef.current.close()} className="glade-button-off-white glade-grey-dark" title="Cancel" />
                        </div>
                        <div>
                            <Button.Blue onClick={()=>onSubmit()} title="Save" />
                        </div>
                    </div>
                </div>
            }
        />
    )
})
