import React from "react";
import { RiLoader5Fill } from "react-icons/ri";

const Button = ({ children }) => <>{children}</>;

Button.Blue = ({
  title,
  type = "button",
  style,
  className,
  disabled,
  loading,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={"glade-normal-text-three glade-button-blue py-2 px-4 flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap " + className}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}

Button.Danger2 = ({
  title,
  type = "button",
  style,
  className,
  disabled,
  loading,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={"glade-normal-text-three glade-button-red py-2 px-4 flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap " + className}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}

Button.Green = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={className+" glade-normal-text-three glade-button-green  py-2 px-4 flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap"}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}

Button.Danger = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={"glade-normal-text-three bg-white hover:bg-red-200 glade-red  py-2 px-4 flex justify-center items-center text-center rounded-lg whitespace-nowrap " + className}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}
Button.DangerBackground = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={"glade-normal-text-three bg-red-200 glade-red  py-2 px-4 flex justify-center items-center text-center rounded-lg whitespace-nowrap "+ className}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}
Button.Success = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={className+" glade-normal-text-three bg-white hover:bg-green-200 glade-green  py-2 px-4 flex justify-center items-center text-center rounded-lg whitespace-nowrap"}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}

Button.Transparent = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={" glade-normal-text-three glade-button-off-white  py-2 px-4 flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap " + className}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}

Button.Base = ({
  title,
  type = "button",
  style,
  className,
  disabled,
  loading,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={className+" glade-normal-text-three  py-2 px-4 flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap"}>
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
}

export default Button;
