// Application types
export const LOADING_HOME = 'LOADING_HOME';
export const RELOAD_HOME = 'RELOAD_HOME';

export const GET_BUSINESS_INFLOW = 'GET_BUSINESS_INFLOW';
export const GET_BUSINESS_OUTFLOW = 'GET_BUSINESS_OUTFLOW';
export const GET_BUSINESS_INFLOW_OUTFLOW = 'GET_BUSINESS_INFLOW_OUTFLOW';
export const GET_PERSONAL_INFLOW = 'GET_PERSONAL_INFLOW';
export const GET_PERSONAL_OUTFLOW = 'GET_PERSONAL_OUTFLOW';
export const GET_PERSONAL_INFLOW_OUTFLOW = 'GET_PERSONAL_INFLOW_OUTFLOW';
export const GET_CARDS = 'GET_CARDS';
export const GET_BUSINESS_TRANSACTIONS = 'GET_BUSINESS_TRANSACTIONS';
export const GET_PERSONAL_TRANSACTIONS = 'GET_PERSONAL_TRANSACTIONS';

export const ONE_BUSINESS_DETAILS_SUCCESS = 'ONE_BUSINESS_DETAILS_SUCCESS';
export const ONE_BUSINESS_DETAILS_LOADING = 'ONE_BUSINESS_DETAILS_LOADING';
export const ONE_BUSINESS_DETAILS_FAIL = 'ONE_BUSINESS_DETAILS_FAIL';

export const GET_STATES_BY_COUNTRYID = 'GET_STATES_BY_COUNTRYID';
export const GET_LGAS_BY_STATEID = 'GET_LGAS_BY_STATEID';

export const LOADING_REQUEST_REVOKE_SESSION = 'LOADING_REQUEST_REVOKE_SESSION';
export const REQUEST_REVOKE_SESSION_SUCCESS = 'REQUEST_REVOKE_SESSION_SUCCESS';
export const REQUEST_REVOKE_SESSION_FAILURE = 'REQUEST_REVOKE_SESSION_FAILURE'

export const LOADING_REVOKE_SESSION = 'LOADING_REVOKE_SESSION';
export const REVOKE_SESSION_SUCCESS = 'REVOKE_SESSION_SUCCESS';
export const REVOKE_SESSION_FAILURE = 'REVOKE_SESSION_FAILURE'

