import React from 'react';

export default function TextIndicator({type, title, className, style={}, onClick}) {
    return (
        <div onClick={onClick}>
            {type === 'success'?
                <span 
                    className={className+" inline-flex justify-start capitalize glade-normal-text-one glade-green"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
            {type === 'warning'?
                <span 
                    className={className+" inline-flex justify-start capitalize glade-normal-text-one glade-yellow"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
            {type === 'neutral'?
                <span 
                    className={className+" inline-flex justify-start capitalize glade-normal-text-one glade-black"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
            {type === 'danger'?
                <span 
                    className={className+" inline-flex justify-start capitalize glade-normal-text-one glade-red"}
                    style={style}>
                    {title??'Title'}
                </span>
            :null}
        </div>
    )
}
