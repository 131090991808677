import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_FUND_TRANSFER,
  LOADING_RESOLVE_DETAILS,
  LOADING_FUND_TRANSFER_BENEFICIARIES,
  GET_ALL_FUND_TRANSFER,
  GET_FUND_TRANSFER_MODE,
  GET_FUND_TRANSFER_BENEFICIARIES,
  GET_USER_TAG_BENEFICIARIES,
  LOADING_USER_TAG_BENEFICIARIES,
  RESOLVE_ACCOUNT_DETAILS,
  GET_BANKS,
  GET_COUNTRIES,
  FUND_TRANSFER_SUCCESS,
  FUND_TRANSFER_FAILURE,
  GET_INTERNATIONAL_COUNTRIES,
  GET_INTERNATIONAL_TRANSFER_METHODS,
  GET_INTERNATIONAL_TRANSFER_BANKS,
  GET_INTERNATIONAL_TRANSFER_BANKS_BRANCHES,
  GET_INTERNATIONAL_TRANSFER_RATE,
  GET_LOCAL_TRANSFER_RATE,
  MAKE_INTERNAL_TRANSFER,
  MAKE_EXTERNAL_TRANSFER,
  MAKE_EXTERNAL_BULK_TRANSFER,
  MAKE_INTERNATIONAL_TRANSFER,
  SEARCH_FUND_TRANSFER,
  REMEMBER_BULK_FUND_TRANSFER_LIST,
  GET_INTERNATIONAL_TRANSFER_RATE_FAIL,
  UPDATE_TRANSFER_STATUS
} from "./types";

/**
 * Get fund transfer history
 * @param {object} payLoad {"start":"", "end":"", "status":""}
 * @returns {void}
 */
export const getFundTransfer = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_FUND_TRANSFER, payLoad: true });
    Axios.get(`transfers/history`, { params: payLoad })
      .then((res) => {
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        dispatch({ type: GET_ALL_FUND_TRANSFER, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        dispatch({ type: GET_ALL_FUND_TRANSFER, payLoad: { data: { data: [] } } });
        // errorHandler(error, true);
      });
  };
};


/**
 * Get fund transfer modes
 * @param {object} payLoad {"mode":""}
 * @returns {void}
 */
export const getTransferModes = (payLoad) => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/` + payLoad.mode)
      .then((res) => {
        dispatch({ type: GET_FUND_TRANSFER_MODE, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get beneficiaries
 * @param {object} payLoad {"mode":""}
 * @returns {void}
 */
export const getBeneficiaries = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_FUND_TRANSFER_BENEFICIARIES });
    Axios.get(`transfers/lookup/beneficiaries/${payLoad.mode}`)
      .then((res) => {
        dispatch({ type: GET_FUND_TRANSFER_BENEFICIARIES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};


export const getUsertagBeneficiaries = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_USER_TAG_BENEFICIARIES });
    Axios.get(`transfers/lookup/user-tag-beneficiaries`)
      .then((res) => {
        dispatch({ type: GET_USER_TAG_BENEFICIARIES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};
/**
 * Resolve account Details0
 * @param {object} payLoad {"account_number" : "", "bank_code" : "", "mode" : ""}
 * @returns {void}
 */
export const resolveAccountDetails = (payLoad, callBack = () => { }, onFail = () => { }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_RESOLVE_DETAILS, payLoad: true });
    Axios.post(`transfers/lookup/account`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_RESOLVE_DETAILS, payLoad: false });
        dispatch({ type: RESOLVE_ACCOUNT_DETAILS, payLoad: res });
        successHandler(res);
        callBack(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_RESOLVE_DETAILS, payLoad: false });
        onFail();
        errorHandler(error, true);
      });
  };
};


export const checkUserTag = (payLoad, callBack = () => { }, onFail = () => { }) => {
  return () => {
    Axios.post(`auth/check-user-tag`, payLoad)
      .then((res) => {
        successHandler(res);
        callBack();
      })
      .catch((error) => {
        onFail();
        errorHandler(error, false);
      });
  };
};

export const resolveAccountDetailsWithUserTag = (payLoad, callBack = () => { },onFailure= () => { }) =>  {
  return (dispatch) => {
    dispatch({ type: LOADING_RESOLVE_DETAILS, payLoad: true });
    Axios.post(`transfers/lookup/user-tag`, payLoad)
      .then((res) => {
        dispatch({ type: LOADING_RESOLVE_DETAILS, payLoad: false });
        dispatch({ type: RESOLVE_ACCOUNT_DETAILS, payLoad: res });
        successHandler(res);
        callBack(res.data.data);
       
      })
      .catch((error) => {
        dispatch({ type: LOADING_RESOLVE_DETAILS, payLoad: false });
        onFailure(error.response);
        errorHandler(error, false);
      });
  };
};

/**
 * Get banks
 * @param {object} payLoad {"mode":""}
 * @returns {void}
 */
export const getBanks = (payLoad) => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/banks/` + payLoad.mode)
      .then((res) => {
        dispatch({ type: GET_BANKS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

export const getBanksForTransfer = (params, callback) => {
  return dispatch => {
    Axios.get(`/transfers/lookup/international/${params}/account/banks`)
      .then(res => {
        dispatch({ type: GET_BANKS, payLoad: res });
        successHandler(res);
        callback(res.data.data);
      })
      .catch(error => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get countries
 * @returns {void}
 */
export const getCountries = () => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/countries`)
      .then((res) => {
        dispatch({ type: GET_COUNTRIES, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get international countries
 * @returns {void}
 */
export const getInternationalCountries = (callBack = () => { }) => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/international/countries`)
      .then((res) => {
        dispatch({ type: GET_INTERNATIONAL_COUNTRIES, payLoad: res });
        successHandler(res);
        callBack(res.data.data);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get international transfer methods
 * @params payLoad {"country_code":""}
 * @returns {void}
 */
export const getInternationalTransferMethods = (payLoad, callBack = () => { }) => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/international/${payLoad.country_code}/methods`)
      .then((res) => {
        dispatch({ type: GET_INTERNATIONAL_TRANSFER_METHODS, payLoad: res });
        successHandler(res);
        callBack(res.data.data);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
};

export const getCountriesAndStates = (callBack) => {
  return (dispatch) => {
    Axios.get(`/transfers/countries-states`)
      .then((res) => {
        callBack(res.data.data);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  }
}

/**
 * Get international transfer banks
 * @params payLoad {"country_code":"","method_code":""}
 * @returns {void}
 */
export const getInternationalTransferBanks = (payLoad, callback = () => { }) => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/international/${payLoad.country_code}/${payLoad.method_code}/banks`)
      .then((res) => {
        dispatch({ type: GET_INTERNATIONAL_TRANSFER_BANKS, payLoad: res });
        successHandler(res);
        callback(res.data.data);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Get international transfer bank branches
 * @params payLoad {"country_code":"","method_code":""}
 * @returns {void}
 */
export const getInternationalTransferBankBranches = (payLoad, callback = () => { }) => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/international/${payLoad.country_code}/${payLoad.bank_id}/branches`, payLoad)
      .then((res) => {
        dispatch({ type: GET_INTERNATIONAL_TRANSFER_BANKS_BRANCHES, payLoad: res });
        successHandler(res, false);
        callback(res.data.data);
      })
      .catch((error) => {
        errorHandler(error, false);
      });
  };
};

/**
 * Get international transfer rate
 * @param {object} payLoad {"amount" : "", "source_currency" : "", "destination_currency" : ""}
 * @returns {void}
 */
export const getInternationalTransferRate = ({ amount, source_currency, destination_currency, destination_country, transfer_method }, callback = () => { }) => {
  return (dispatch) => {
    Axios.get(`transfers/lookup/international/rate?destination_currency=${destination_currency}&amount=${amount}&source_currency=${source_currency}&destination_country=${destination_country}&transfer_method=${transfer_method}`)
      .then((res) => {
        dispatch({ type: GET_INTERNATIONAL_TRANSFER_RATE, payLoad: res });
        successHandler(res);
        callback(res.data.data);
      })
      .catch((error) => {
        dispatch({ type: GET_INTERNATIONAL_TRANSFER_RATE_FAIL, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const getLocalTransferRate = ({ amount, source_currency }, callback = () => { }) => {
  return dispatch => {
    Axios
      .get(`transfers/lookup/local/rate?source_currency=${source_currency}&amount=${amount}`)
      .then(res => {
        dispatch({ type: GET_LOCAL_TRANSFER_RATE, payLoad: res });
        callback();
      });
  };
};

// repeat Transaction

export const repeatTransfer = ( payLoad, onSuccess = () => {},
onFailure = () => {}) => {
return (dispatch) => {
  dispatch({ type: LOADING_FUND_TRANSFER, payLoad: true });
  Axios.post(`/transactions/repeat/${payLoad.txn_ref}`, {passcode:payLoad.passcode})
    .then((res) => {
  dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
  dispatch({ type: FUND_TRANSFER_SUCCESS, payLoad: res });
      successHandler(res);
      onSuccess(res)
    })
    
    .catch((error) => {
      dispatch({ type: UPDATE_TRANSFER_STATUS, payLoad: false})
      dispatch({ type:FUND_TRANSFER_FAILURE });
      errorHandler(error, false);
    onFailure()
    });
};
};

/**
 * Make an internal transfer (Glade To Glade)
 * @param {object} payLoad {"account_number" : "", "amount" : "", "save_beneficiary" : true, "beneficiary_email":["example@mail.com","example1@mail.com"],
}
 * @returns {void}
 */
export const makeInternalTransfer = (payLoad, callBack = () => { }, onFail = () => { }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_FUND_TRANSFER, payLoad: true });
    Axios.post(`transfers/internal`, payLoad)
      .then((res) => {
        dispatch({ type: MAKE_INTERNAL_TRANSFER, payLoad: res });
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        successHandler(res);
        callBack(res);
      })
      .catch((error) => {
        errorHandler(error, true);
        onFail();
        dispatch({ type: UPDATE_TRANSFER_STATUS, payLoad: false})
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
      });
  };
};

export const makeInternalTransferWithUserTag = (payLoad, callBack = () => { }, onFail = () => { }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_FUND_TRANSFER, payLoad: true });
    Axios.post(`transfers/internal/user-tag`, payLoad)
      .then((res) => {
        dispatch({ type: MAKE_INTERNAL_TRANSFER, payLoad: res });
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        successHandler(res);
        callBack(res);
      })
      .catch((error) => {
        errorHandler(error, true);
        onFail();
        dispatch({ type: UPDATE_TRANSFER_STATUS, payLoad: false})
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
      });
  };
};
/**
 * Make an external transfer (Glade To Local Banks)
 * @param {object} payLoad {
    "beneficiary_account_name": "",
    "beneficiary_bank": "",
    "beneficiary_bank_name": "",
    "beneficiary_account_number": "",
    "narration": "",
    "amount": "",
    "save_beneficiary": 1,
    "beneficiary_email":["example@mail.com","example1@mail.com"],
    "order_ref" : "",
 * }
 * @returns {void}
 */
export const makeExternalTransfer = (payLoad, callBack, onFail) => {
  return (dispatch) => {
    dispatch({ type: LOADING_FUND_TRANSFER, payLoad: true });
    Axios.post(`transfers/external`, payLoad)
      .then((res) => {
        dispatch({ type: MAKE_EXTERNAL_TRANSFER, payLoad: res });
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        successHandler(res, false);
        callBack(res);
      })
      .catch((error) => {
        dispatch({ type: UPDATE_TRANSFER_STATUS, payLoad: false})
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        errorHandler(error, true);
        onFail();
      });
  };
};

/**
 * Make an external bulk transfer (Glade To Local Banks)
 * @param {object} payLoad { "data": [{
    "narration": "",
    "orderRef": "",
    "amount": 1000,
    "sender_name": "",
    "bankcode": "",
    "accountnumber": ""
 * }]}
 * @returns {void}
 */
export const makeExternalBulkTransfer = (payLoad, callBack = () => { }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_FUND_TRANSFER, payLoad: true });
    Axios.post(`transfers/external/bulk`, payLoad)
      .then((res) => {
        dispatch({ type: MAKE_EXTERNAL_BULK_TRANSFER, payLoad: res });
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        successHandler(res, true);
        callBack();
      })
      .catch((error) => {
        dispatch({ type: UPDATE_TRANSFER_STATUS, payLoad: false})
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        errorHandler(error, true);
      });
  };
};

/**
 * Make an international transfer (Glade To International Banks)
 * @param {object} payLoad {
    "amount" : 1000,
    "beneficiary_name" : "",
    "beneficiary_bank" : "",
    "beneficiary_account_number" : "",
    "narration" : ""
 * }
 * @returns {void}
 * 
 */
export const makeInternationalTransfer = (payLoad, callBack = () => { }, onFail = () => { }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_FUND_TRANSFER, payLoad: true });
    Axios.post(`transfers/international`, payLoad)
      .then((res) => {
        dispatch({ type: MAKE_INTERNATIONAL_TRANSFER, payLoad: res });
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        successHandler(res, false);
        callBack(res);
      })
      .catch((error) => {
        dispatch({ type: UPDATE_TRANSFER_STATUS, payLoad: false})
        dispatch({ type: LOADING_FUND_TRANSFER, payLoad: false });
        onFail();
        errorHandler(error, true);
      });
  };
};

/**
 * Search fund transfer history
 * @param {object} payLoad {"txn_ref":""}
 * @returns {void}
 */
export const searchFundTransfer = (payLoad) => {
  return (dispatch) => {
    Axios.get(`transfers/search/${payLoad.txn_ref}`)
      .then((res) => {
        dispatch({ type: SEARCH_FUND_TRANSFER, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  };
};

/**
 * Remember bulk fund transfer list
 * @param {object} payLoad
 * @returns {void}
 */
export const rememberBulkFundTransferList = (payLoad, callBack = () => { }) => {
  return (dispatch) => {
    dispatch({ type: REMEMBER_BULK_FUND_TRANSFER_LIST, payLoad: { data: { data: payLoad } } });
    successHandler({ message: 'Bulk list processed', code: 200, status: true, data: payLoad });
    callBack();
  };
};