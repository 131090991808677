import React from 'react';

export function CardPlainSection({children, type='single', title}) {
    return (
        <div className={
            type==='single'? "col-start-3 col-span-4 flex flex-col":
            type==='dual'? "flex flex-col":
            null}>
            <div className="bg-white py-4 overflow-hidden rounded-sm shadow-lg">
                <div className="flex justify-center py-2 border-b-2 border-solid border-gray-200">
                    <h4 className="glade-subtitle-one glade-black">{title??'Title'}</h4>
                </div>
                <div className="px-6 my-3"> 
                    {children}
                </div>
            </div>
        </div>
    )
}

export function CardPlain({children, type='single'}) {
    return (
        <div className="flex flex-col">
            <div className={
                type==='single'? "grid grid-cols-8 gap-4":
                type==='dual'? "grid grid-cols-2 gap-4":
                null}>
                {children}
            </div>
        </div>
    )
}
