import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";

import {
  CONVERT_RATE_FAILURE,
  CONVERT_RATE_LOADING,
  CONVERT_CURRENCY_SUCCESS,
  CONVERT_RATE_SUCCESS,
  CONVERT_CURRENCY_LOADING,
  CONVERT_CURRENCY_FAILURE,
} from "./types";

export const getConvertRate = (query) => {
  return (dispatch) => {
    dispatch({ type: CONVERT_RATE_LOADING });
    Axios.get(`/businesses/multicurrency/charge-rates?source_currency=${query.source_currency}&destination_currency=${query.destination_currency}${query.action ? '&action=conversion' : ''}`)
      .then((res) => {
        dispatch({ type: CONVERT_RATE_SUCCESS, payLoad: res.data });
      })
      .catch((error) => {
        dispatch({ type: CONVERT_RATE_FAILURE });
        errorHandler(error);
      });
  };
};
export const convertCurrency = (query, showSuccessModal) => {
  return (dispatch) => {
    dispatch({ type: CONVERT_CURRENCY_LOADING, payLoad: true });
    Axios.post(`/multicurrency/convert-currency`, query)
      .then((res) => {
        // dispatch({ type: CONVERSION_CHARGE_SUCCESS, payLoad: res.data});
        dispatch({ type: CONVERT_CURRENCY_SUCCESS, payLoad: res });
        showSuccessModal(true);
        successHandler(res);
      })
      .catch((error) => {
        showSuccessModal(false);
        dispatch({ type: CONVERT_CURRENCY_FAILURE });
        errorHandler(error, true);
      });
  };
};
