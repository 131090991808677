import React from "react";
import { useState } from "react";
import Button from "../../buttons/Button";
import { useEffect } from "react";
import FileInput from "../../inputs/FileInput";
import { addWalletAccountsValidation } from "../../../validation/add-currency/AddCurrencyValidation";

const CreateWalletCurrencyForm = ({
  handleInput,
  incomingInput,
  onProceed,
}) => {
  const [input, setInput] = useState({
    ...incomingInput,
  });
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (
      !input.business_registration?.length
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [input]);
  const checkValidity = () => {
    if (addWalletAccountsValidation(input)) {
      handleInput(input);
      onProceed();
    }
  };
  return (
    <div className="w-10/12 sm:w-8/12 lg:w-4/12 flex flex-col items-center px-8 mt-6">
      <div className="w-full ">
        <div className="text-center mb-12">
          <h3 className="glade-heading-three glade-home-dark ">
            Supporting documents
          </h3>
          <p className="text-sm glade-gray-dark">
            Please upload the necessary documents
          </p>
        </div>
        <div className="mt-5">
          <div>
            <FileInput
              label={"Business Registration"}
              id={"business_registration"}
              name={"business_registration"}
              multiple={false}
              accept=".png, .jpeg, .pdf, .jpg"
              maximumSize={3}
              returnInput={(value) =>
                setInput({ ...input, business_registration: value })
              }
            />
            <span className="glade-small-text-one glade-grey-cool">
              Supported file types: PDF, PNG & JPEG. Max file size allowed is
              3MB.
            </span>
            <div className="mt-5">
              <FileInput
                label={"Operating Licence (Optional)"}
                id={"operating_licence"}
                name={"operating_licence"}
                multiple={false}
                accept=".png, .jpeg, .pdf, .jpg"
                maximumSize={3}
                returnInput={(value) =>
                  setInput({ ...input, operating_licence: value })
                }
              />
              <span className="glade-small-text-one glade-grey-cool">
                Supported file types: PDF, PNG & JPEG. Max file size allowed is
                3MB.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Button.Blue
          title={"Confirm"}
          type={"button"}
          className={"py-3 mt-10"}
          disabled={disableButton}
          onClick={checkValidity}
        />
      </div>
    </div>
  );
};

export default CreateWalletCurrencyForm;
