import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CardPlainWithButton, CardPlainWithButtonSection } from '../../components/cards/CardPlainWithButton';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import Button from '../../components/buttons/Button';
import { isEmpty } from '../../helpers/helper';
import { getBanks, makeExternalBulkTransfer, resolveAccountDetails } from '../../redux/fund-transfer/fundTransferActions';
import { CgClose } from 'react-icons/cg';
import useFundTransfer from '../../hooks/useFundTransfer';
import useUser from "../../hooks/useUser";
import TextInput from '../../components/inputs/TextInput';
import NumericInput from '../../components/inputs/NumericInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import SelectBeneficiaryModal from './SelectBeneficiaryModal';
import ConfirmPinModal from './ConfirmPinModal';
import EmptyCard from '../../components/empty-states/EmptyCard';
import { makeExternalBulkTransferValidation, makeExternalTransferValidation } from "../../validation/fund-transfer/fundTransferValidation";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import useTeam from '../../hooks/useTeam';
export default function FundTransferBulkManual() {

  const selectBeneficiaryModalRef = React.useRef();
  const confirmPinModalRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const fundTransfer = useFundTransfer();
  const user = useUser();
  const {permissions} = useTeam();

  const [input, setInput] = React.useState({
    recipients: [],
    bank_code: '',
    bank_name: '',
    account_number: '',
    account_name: '',
    amount: '',
    note: '',
    save_beneficiary: false,
    show_save_beneficiary: true,
    beneficiary: {},
    session_id: ""
  });

  React.useEffect(() => {
    dispatch(getBanks({ mode: 'internal' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (input.account_number.length === 10 && input.bank_code) {
      dispatch(
        resolveAccountDetails(
          { account_number: input.account_number, bank_code: input.bank_code, mode: 'external_local' },
          (resolveAccountDetails) => setInput({ ...input, account_name: resolveAccountDetails.account_name, session_id: resolveAccountDetails?.session_id?? "" })
        )
      );
    } else {
      setInput({ ...input, account_name: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.account_number, input.bank_code]);

  const onSubmitWithPin = () => {
    if (makeExternalBulkTransferValidation(input.recipients)) {
      confirmPinModalRef.current.open();
    }
  }

  const onSubmit = () => {
    dispatch(makeExternalBulkTransfer(input.recipients, () => { 
      history.goBack(); 
      dispatch(getPersonalAndBusinessAccounts()); }));
  }

  const onAddAccount = () => {
    if (isEmpty(input.bank_code) || isEmpty(input.account_number) || isEmpty(input.amount)) {
      alert('Please fill all the required fields');
      return;
    }

    setInput({
      ...input,
      recipients: [
        ...input.recipients,
        {
          // required
          narration: input.note,
          orderRef: input.account_number + "-" + new Date().getTime(),
          amount: input.amount.replaceAll(',', ''),
          sender_name: input.account_name,
          bankcode: input.bank_code,
          accountnumber: input.account_number,
          account_number: input.account_number,
          // not required
          bank_name: input.bank_name,
          id: Math.random(),
          session_id: input?.session_id
        }
      ],
      bank_code: '',
      bank_name: '',
      account_number: '',
      account_name: '',
      amount: '',
      note: '',
      session_id: ""
    });
  }

  const onRemoveAccount = (value) => {
    let recipients = input.recipients.filter(item => item.id !== value.id);
    setInput({ ...input, recipients: recipients });
  }

  const onChange = (e) => {
    if (e.target.name === 'amount') {
      setInput({ ...input, [e.target.name]: Number(e.target.value).toLocaleString('en-US') });
    } else {

      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const onSelectOfBank = (e) => {
    let data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data'));
    setInput({ ...input, bank_code: data.bank_code, bank_name: data.bank_name });
  }

  const onSelectOfBeneficiary = (value) => {
    setInput({
      ...input,
      beneficiary: value,
      account_name: value.account_name,
      accountnumber: value.account_number,
      account_number: value.account_number,
      bank_code: value.bank_code,
      show_save_beneficiary: false,
    });
  };

  const renderRecipients = (data) => {
    if (isEmpty(data)) {
      return (
        <EmptyCard title={'No Recipients'} subtitle={'You have not added any recipients'} />
      )
    }

    return data.map((item, key) => {
      return (
        <div key={key} className="flex flex-row justify-between border-2 my-2 p-3">
          <h4 className="glade-normal-text-two glade-black">
            {item.sender_name + ' ' + item.account_number + ' ' + item.bank_name + ' NGN ' + item.amount}
          </h4>
          <button className="px-2 glade-normal-text-two glade-black outline-none" onClick={() => onRemoveAccount(item)}>
            <CgClose />
          </button>
        </div>
      );
    });
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">

        <div className="mb-6">
          <BreadCrumbs title={'Fund Transfer'} path={!permissions?.includes("fund transfer") ? '/dashboard/fund-transfer' : "/teams/fund-transfer"} />
        </div>

        <CardPlainWithButton type={'dual'}>
          <CardPlainWithButtonSection
            title={'Add Account'}
            type={'dual'}
            loading={fundTransfer.isLoading}
            buttonSection={
              <div className="flex flex-row space-x-4 mt-6">
                <Button.Blue
                  title={"Add Account"}
                  className="whitespace-nowrap"
                  onClick={onAddAccount}
                  disabled={fundTransfer.isLoading || !user.hasCompliance || !makeExternalTransferValidation(input, true)}
                />
                <Button.Blue
                  title={"Continue"}
                  onClick={onSubmitWithPin}
                  disabled={fundTransfer.isLoading || !user.hasCompliance || !makeExternalBulkTransferValidation(input.recipients, true)}
                />
              </div>
            }>
            <div className="mt-6 px-14">
              <div className="space-y-6">

                <SelectInput
                  label={'Select Bank'}
                  id={'bank_code'}
                  name={'bank_code'}
                  value={input.bank_code ?? ''}
                  onChange={onSelectOfBank}
                  autoComplete={'bank_code'}
                  className={'appearance-none'}>
                  <option value={''} disabled={true}>Select A Bank</option>
                  {fundTransfer.banks && fundTransfer.banks.map((item, key) => {
                    return (
                      <option key={key} value={item.bank_code} data={JSON.stringify(item)}>{item.bank_name}</option>
                    );
                  })}
                </SelectInput>

                <NumericInput
                  label={
                    <div className="flex flex-row justify-between">
                      <span>Account Number</span>
                      <span onClick={() => selectBeneficiaryModalRef?.current?.open()} className="glade-blue cursor-pointer">Select Beneficiary</span>
                    </div>
                  }
                  id={'account_number'}
                  name={'account_number'}
                  type={'number'}
                  value={input.account_number}
                  onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value.substring(0, 10) })}
                  placeholder={'Enter account number'}
                  autoComplete={'account_number'}
                  className={'appearance-none'}
                />
                <SelectBeneficiaryModal ref={selectBeneficiaryModalRef} beneficiary={''} onSelectOfBeneficiary={onSelectOfBeneficiary} />

                <TextInput
                  label={'Account Name'}
                  id={'account_name'}
                  name={'account_name'}
                  disabled={true}
                  type={'text'}
                  value={input.account_name}
                  onChange={onChange}
                  placeholder={''}
                  autoComplete={'account_name'}
                  className={'appearance-none'}
                />

                <NumericInput
                  label={'Enter Amount'}
                  id={'amount'}
                  name={'amount'}
                  type={'number'}
                  value={input.amount}
                  onChange={onChange}
                  placeholder={'Enter amount'}
                  autoComplete={'amount'}
                  className={'appearance-none'}
                />

                <TextInput
                  label={'Note'}
                  id={'note'}
                  name={'note'}
                  type={'text'}
                  value={input.note}
                  onChange={onChange}
                  placeholder={'Enter description (optional)'}
                  autoComplete={'note'}
                  className={'appearance-none'}
                />

                {input.show_save_beneficiary &&
                  <CheckboxInput
                    label={'Save as beneficiary'}
                    id={'save_beneficiary'}
                    name={'save_beneficiary'}
                    type={'checkbox'}
                    checked={input.save_beneficiary}
                    onChange={(e) => setInput({ ...input, [e.target.name]: e.target.checked })}
                    placeholder={''}
                    autoComplete={'save_beneficiary'}
                    className={'appearance-none'}
                  />}

              </div>
            </div>
          </CardPlainWithButtonSection>
          <CardPlainWithButtonSection
            className={'order-first md:order-last'}
            title={'Recipients'}
            type={'dual'}
            loading={fundTransfer.isLoading}
            buttonDisabled={fundTransfer.isLoading}
            buttonSection={<React.Fragment></React.Fragment>}>
            <div className="mt-6" style={{ minHeight: '26.8rem' }}>
              {renderRecipients(input.recipients)}
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>
      </div>

      <ConfirmPinModal ref={confirmPinModalRef} onSuccess={onSubmit} onFailure={() => { }} />
    </div>
  )
}
