import {
  LOADING_CHARGE_BACKS,
  GET_ALL_CHARGE_BACKS,
  GET_CHARGE_BACKS_DETAILS,
  BLACKLIST_CHARGE_BACK,
  ACCEPT_CHARGE_BACK,
  DECLINE_CHARGE_BACK,
} from "./types";

const initialState = {
  isLoading:false,
  chargeBackIndex: [],
  chargeBackDetail: {},
  blacklistChargeBack:{},
  acceptChargeBack:{},
  declineChargeBack:{},
};

export default function chargeBackReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_CHARGE_BACKS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case GET_ALL_CHARGE_BACKS:
      return {
        ...state,
        chargeBackIndex: action.payLoad.data.data,
      };

    case GET_CHARGE_BACKS_DETAILS:
      return {
        ...state,
        chargeBackDetail: action.payLoad.data.data,
      };

    case BLACKLIST_CHARGE_BACK:
      return {
        ...state,
        blacklistChargeBack: action.payLoad.data.data,
      };

    case ACCEPT_CHARGE_BACK:
      return {
        ...state,
        acceptChargeBack: action.payLoad.data.data,
      };

    case DECLINE_CHARGE_BACK:
      return {
        ...state,
        declineChargeBack: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
