import React, { useState } from "react";
import OnboardingLayout from "./OnboardingLayout";
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";
import { requestRevokeSession, revokeSessions } from "../../redux/home/homeActions";
import IndividualInput from "../../components/inputs/IndividualInput";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/buttons/Button";

function OtploginAccess() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const location = useLocation();
  const [displayError, setDisplayError] = useState(false);

  const [input, setInput] = useState({
    email: location?.state?.email,
    token: "",
  });


  const verifyEmail = () => {
    dispatch(
      revokeSessions(
        input,
        () => history.push("/auth/login"),
        () => setDisplayError(true)
      )
    );
  };

  const resendEmailVerification = () => {
    dispatch(requestRevokeSession({email:input.email}));
  };

  return (
    <OnboardingLayout>
      <div className="w-full max-w-md">
        <div className="flex flex-col justify-center items-center">
          <h3 className="flex mt-6 mb-2 glade-heading-three items-center justify-center">
            Verification Code
          </h3>
          <p className="flex mt-2 glade-subtitle-two glade-grey-dark">
            A 6- digit code has been sent to {input?.email}.
          </p>
          <p className="glade-grey-dark">
            {" "}
            Enter the code to continue your log in process.
          </p>
        </div>
        {displayError && (
          <div className="pt-3">
            <Button.DangerBackground
              title={"Invalid verification code"}
              type={"button"}
              className={"py-3 w-full"}
              disabled={true}
            />
          </div>
        )}
        <div className="mt-6 space-y-6">
          <div className="mt-1">
            <IndividualInput
              label={""}
              type={"text"}
              onChange={(value) => {
                setDisplayError(false);
                setInput({ ...input, token: value });
              }}
            />
          </div>
          <div className="pt-3 flex justify-center">
            <Button.Blue
              title={"Submit"}
              type={"button"}
              className={"py-3 pr-3 pl-3"}
              onClick={verifyEmail}
              disabled={user.isLoading || input?.token?.length !== 6}
              loading={user.isLoading}
            />
          </div>

          <div className="flex justify-center items-center">
            <p className="flex glade-normal-text-two">
              <span className="mr-1 glade-black">Didn't receive code?</span>
              <span
                className="glade-normal-text-two glade-blue cursor-pointer"
                onClick={()=> resendEmailVerification()}
              >
                Resend Code
              </span>
            </p>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
}

export default OtploginAccess;
