import styled from "styled-components";

const ReceiverFlag = ({ flagCode = "ng", className, size }) => {

  return (
    <img src={`https://flagsapi.com/${flagCode.toUpperCase()}/flat/64.png`} className={`rounded-md ${className}`} width={size?? '40px'} height={size ?? '40px'} alt="flag" />
  );
};

export default ReceiverFlag;

const SCFlag = styled.img`
    border-radius: 8px;
    height: 25px;
    width: 30px;
    object-fit: cover;
`;