import React from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { simpleDate } from "../../helpers/helper";

export default function TransactionDetail() {
  const location = useLocation();

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs
            title={"Transactions"}
            path={"/dashboard/transactions"}
          />
        </div>

        <div>
          <div className="flex flex-col bg-white rounded-sm p-3 my-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
              <h3 className="glade-heading-four glade-black">
                Transaction Details
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-4 py-3">
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Transaction Date
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {simpleDate(
                    location?.state?.txn_date,
                    "numeric",
                    "short",
                    "2-digit"
                  )}
                </h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  NGN{" "}
                  {Number(
                    location?.state?.actual_value ?? location?.state?.value
                  )?.toLocaleString()}
                </h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Transaction ID
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {location?.state?.txn_ref}
                </h6>
              </div>
             {location?.state?.session_id && <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Session ID</h5>
                <h6 className="glade-normal-text-three glade-black py-1">{location?.state?.session_id}</h6>
              </div>}
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Balance After
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {location?.state?.balance_after}
                </h6>
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Details
                </h5>
                <h6 className="glade-normal-text-three glade-black py-1">
                  {location?.state?.narration}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
