import {
  LOADING_SETTINGS,
  LOADING_COMPLIANCE,
  RELOAD_SETTINGS,

  CREATE_PASS_CODE,
  RESET_PASS_CODE,
  UPDATE_PASS_CODE,
  COMPLETE_PASS_CODE,
  GET_USER_DETAILS,
  RESET_PASSWORD,
  UPDATE_RESET_PASSWORD,
  COMPLETE_RESET_PASSWORD,
  GET_BUSINESS_DETAILS,
  UPDATE_PERSONAL_PROFILE,
  UPDATE_BUSINESS_PROFILE,

  GET_COMPLIANCE_COUNTRIES,
  GET_COMPLIANCE_STATES,
  GET_COMPLIANCE_LGA,
  GET_COMPLIANCE_PERSONAL_DOCUMENT_TYPES,
  GET_COMPLIANCE_BUSINESS_DOCUMENT_TYPES,
  GET_COMPLIANCE_IDENTITY_TYPES,
  GET_METAMAP_IDENTITY_TYPES,
  GET_PERSONAL_COMPLIANCES,
  GET_RECENT_PERSONAL_COMPLIANCE,
  GET_BUSINESS_COMPLIANCES,
  GET_RECENT_BUSINESS_COMPLIANCE,
  GET_SUBMITTED_PERSONAL_COMPLIANCE_DETAILS,
  GET_SUBMITTED_BUSINESS_COMPLIANCE_DETAILS,
  SUBMIT_PERSONAL_COMPLIANCE,
  SUBMIT_BUSINESS_COMPLIANCE,
  SUBMIT_BUSINESS_INFORMATION,
  SUBMIT_BUSINESS_DOCUMENTS,
  SEND_USER_INVITES,
  VERIFY_USER_INVITES,
  UPDATE_USER_INVITES,
  GET_USER_INVITES,
  RESEND_USER_INVITES,
  DELETE_USER_INVITES,

  GET_SETTLEMENT_ACCOUNTS,
  CREATE_SETTLEMENT_ACCOUNT,
  UPDATE_SETTLEMENT_ACCOUNT,
  DELETE_SETTLEMENT_ACCOUNT,
  GET_SUPPLIED_ACCOUNT_NUMBER_DETAILS,

  GET_ROLES,
  CREATE_ROLES,
  GET_PERMISSIONS,
  UPDATE_ROLE,
  DELETE_ROLE,

  GET_DASHBOARD_FEATURES,
  GET_PAYMENT_METHODS,
  UPDATE_DASHBOARD_FEATURES,
  UPDATE_PAYMENT_METHODS,

  GET_STATUS_OF_AGENT_REQUEST,
  SUBMIT_AGENT_REQUEST,

  GET_MERCHANT_CREDENTIALS,
  GET_MERCHANT_WEBHOOK,
  CREATE_OR_UPDATE_MERCHANT_WEBHOOK,
  GET_DEVELOPER_WEB_PAGE_LINK,
  GUARANTOR_FORM_SUCCESS,

  GET_REFERRAL_DETAILS,
  GET_REFERRAL_REWARDS_BY_YEAR,
  GET_REFERRAL_YEARS,
  GENERATE_NEW_KEY,
  GET_COMPLIANCE_STATES_NEW,
  GET_COMPLIANCE_COUNTRIES_NEW,
  LOADING_DASHBOARD_FEATURES,
  DASHBOARD_FEATURES_FAILURE
} from "./types";

const initialState = {
  guarantorFormData: '',
  isLoading: false,
  isLoadingCompliance: false,
  isLoadingDashboardFeatures: false,
  shouldReload: false,
  createPassCode: {},
  resetPassCode: {},
  updatePassCode: {},
  completePassCode: {},
  userDetails: {},
  resetPassword: {},
  updateResetPassword: {},
  completeResetPassword: {},
  businessDetails: {},
  updatePersonalProfile: {},
  updateBusinessProfile: {},
  complianceCountries: [],
  complianceStates: [],
  complianceLga: [],
  compliancePersonalDocumentTypes: [],
  complianceBusinessDocumentTypes: [],
  complianceIdentityTypes: [],
  metamapIdentityTypes: [],
  personalCompliance: {},
  personalRecentCompliance: {},
  businessCompliance: {},
  businessRecentCompliance: {},
  submittedPersonalComplianceDetail: {},
  submittedBusinessComplianceDetail: {},
  submitPersonalCompliance: {},
  submitBusinessCompliance: {},
  submitBusinessInformation: {},
  submitBusinessDocuments: {},
  sendInvite: {},
  verifySentInvites: {},
  updateSentInvite: {},
  userInvites: [],
  resendInvite: {},
  deleteInvite: {},
  settlementAccounts: [],
  createSettlementAccount: {},
  updateSettlementAccount: {},
  deleteSettlementAccount: {},
  suppliedAccountDetails: {},
  roles: [],
  createRole: {},
  permissions: [],
  updateRole: {},
  deleteRole: {},
  dashboardFeatures: {},
  paymentMethods: {},
  updateDashboardFeatures: {},
  updatePaymentMethods: {},
  agentRequestStatus: {},
  submitAgentRequest: {},
  merchantCredentials: {},
  merchantWebhook: {},
  createOrUpdateMerchantWebhook: {},
  developerWebPageLink: {},
  generateNewKey: {},
  referralDetails: {}
};

export default function fundTransferReducer(state = initialState, action) {
  switch (action.type) {
    case GUARANTOR_FORM_SUCCESS:
      return {
        ...state, guarantorFormData: action.payLoad
      };
    case LOADING_SETTINGS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOADING_COMPLIANCE:
      return {
        ...state,
        isLoadingCompliance: action.payLoad,
      };

    case RELOAD_SETTINGS:
      return {
        ...state,
        shouldReload: !state.shouldReload,
      };

    case CREATE_PASS_CODE:
      return {
        ...state,
        createPassCode: action.payLoad.data.data,
      };

    case RESET_PASS_CODE:
      return {
        ...state,
        resetPassCode: action.payLoad.data.data,
      };

    case UPDATE_PASS_CODE:
      return {
        ...state,
        updatePassCode: action.payLoad.data.data,
      };

    case COMPLETE_PASS_CODE:
      return {
        ...state,
        completePassCode: action.payLoad.data.data,
      };

    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payLoad.data.data,
      };

    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.payLoad.data.data,
      };

    case UPDATE_RESET_PASSWORD:
      return {
        ...state,
        updateResetPassword: action.payLoad.data.data,
      };

    case COMPLETE_RESET_PASSWORD:
      return {
        ...state,
        completeResetPassword: action.payLoad.data.data,
      };

    case GET_BUSINESS_DETAILS:
      return {
        ...state,
        selectedBusinessOrPersonalAccount: {
          ...action.payLoad.data.data.account,
          ...action.payLoad.data.data.business,
        },
        businessDetails: action.payLoad.data.data
      };

    case UPDATE_PERSONAL_PROFILE:
      return {
        ...state,
        updatePersonalProfile: action.payLoad.data.data,
      };

    case UPDATE_BUSINESS_PROFILE:
      return {
        ...state,
        updateBusinessProfile: action.payLoad.data.data,
      };

    case GET_COMPLIANCE_COUNTRIES:
      return {
        ...state,
        complianceCountries: action.payLoad.data.data,
      };
      case GET_COMPLIANCE_COUNTRIES_NEW:
        return {
          ...state,
          complianceCountriesNew: action.payLoad.data.data,
        };

    case GET_COMPLIANCE_STATES:
      return {
        ...state,
        complianceStates: action.payLoad.data.data,
      };
      case GET_COMPLIANCE_STATES_NEW:
                return {
                    ...state,
                    complianceStatesNew: action.payLoad.data.data,
                };

    case GET_COMPLIANCE_LGA:
      return {
        ...state,
        complianceLga: action.payLoad.data.data,
      };

    case GET_COMPLIANCE_PERSONAL_DOCUMENT_TYPES:
      return {
        ...state,
        compliancePersonalDocumentTypes: action.payLoad.data.data,
      };

    case GET_COMPLIANCE_BUSINESS_DOCUMENT_TYPES:
      return {
        ...state,
        complianceBusinessDocumentTypes: action.payLoad.data.data,
      };

    case GET_COMPLIANCE_IDENTITY_TYPES:
      return {
        ...state,
        complianceIdentityTypes: action.payLoad.data.data,
      };

      case GET_METAMAP_IDENTITY_TYPES:
        return {
          ...state,
          metamapIdentityTypes: action.payLoad.data.data,
        };

    case GET_PERSONAL_COMPLIANCES:
      return {
        ...state,
        personalCompliance: action.payLoad.data.data,
      };

    case GET_RECENT_PERSONAL_COMPLIANCE:
      return {
        ...state,
        personalRecentCompliance: action.payLoad.data.data,
      };

    case GET_BUSINESS_COMPLIANCES:
      return {
        ...state,
        businessCompliance: action.payLoad.data.data,
      };

    case GET_RECENT_BUSINESS_COMPLIANCE:
      return {
        ...state,
        businessRecentCompliance: action.payLoad.data.data,
      };

    case GET_SUBMITTED_PERSONAL_COMPLIANCE_DETAILS:
      return {
        ...state,
        submittedPersonalComplianceDetail: action.payLoad.data.data,
      };

    case GET_SUBMITTED_BUSINESS_COMPLIANCE_DETAILS:
      return {
        ...state,
        submittedBusinessComplianceDetail: action.payLoad.data.data,
      };

    case SUBMIT_PERSONAL_COMPLIANCE:
      return {
        ...state,
        submitPersonalCompliance: action.payLoad.data.data,
      };

    case SUBMIT_BUSINESS_COMPLIANCE:
      return {
        ...state,
        submitBusinessCompliance: action.payLoad.data.data,
      };
    case SUBMIT_BUSINESS_INFORMATION:
      return {
        ...state,
        submitBusinessInformation: action.payLoad.data.data,
      };
    case SUBMIT_BUSINESS_DOCUMENTS:
      return {
        ...state,
        submitBusinessDocuments: action.payLoad.data.data,
      };
    case SEND_USER_INVITES:
      return {
        ...state,
        sendInvite: action.payLoad.data.data,
      };

    case VERIFY_USER_INVITES:
      return {
        ...state,
        verifySentInvites: action.payLoad.data.data,
      };

    case UPDATE_USER_INVITES:
      return {
        ...state,
        updateSentInvite: action.payLoad.data.data,
      };

    case GET_USER_INVITES:
      return {
        ...state,
        userInvites: action.payLoad.data.data,
      };

    case RESEND_USER_INVITES:
      return {
        ...state,
        resendInvite: action.payLoad.data.data,
      };

    case DELETE_USER_INVITES:
      return {
        ...state,
        deleteInvite: action.payLoad.data.data,
      };

    case GET_SETTLEMENT_ACCOUNTS:
      return {
        ...state,
        settlementAccounts: action.payLoad.data.data,
      };

    case CREATE_SETTLEMENT_ACCOUNT:
      return {
        ...state,
        createSettlementAccount: action.payLoad.data.data,
      };

    case UPDATE_SETTLEMENT_ACCOUNT:
      return {
        ...state,
        updateSettlementAccount: action.payLoad.data.data,
      };

    case DELETE_SETTLEMENT_ACCOUNT:
      return {
        ...state,
        deleteSettlementAccount: action.payLoad.data.data,
      };

    case GET_SUPPLIED_ACCOUNT_NUMBER_DETAILS:
      return {
        ...state,
        suppliedAccountDetails: action.payLoad.data.data,
      };

    case GET_ROLES:
      return {
        ...state,
        roles: action.payLoad.data.data,
      };

    case CREATE_ROLES:
      return {
        ...state,
        createRole: action.payLoad.data.data,
      };

    case GET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payLoad.data.data,
      };

    case UPDATE_ROLE:
      return {
        ...state,
        updateRole: action.payLoad.data.data,
      };

    case DELETE_ROLE:
      return {
        ...state,
        deleteRole: action.payLoad.data.data,
      };
    case LOADING_DASHBOARD_FEATURES:
      return {
        ...state,
        dashboardFeatures: {},
        isLoadingDashboardFeatures: true,
      }
    case GET_DASHBOARD_FEATURES:
      return {
        ...state,
        dashboardFeatures: action.payLoad.data.data,
        isLoadingDashboardFeatures: false
      };
    case DASHBOARD_FEATURES_FAILURE:
      return {
        ...state,
        dashboardFeatures: {},
        isLoadingDashboardFeatures: false,
      }
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payLoad.data.data,
      };

    case UPDATE_DASHBOARD_FEATURES:
      return {
        ...state,
        updateDashboardFeatures: action.payLoad.data.data,
      };

    case UPDATE_PAYMENT_METHODS:
      return {
        ...state,
        updatePaymentMethods: action.payLoad.data.data,
      };

    case GET_STATUS_OF_AGENT_REQUEST:
      return {
        ...state,
        agentRequestStatus: action.payLoad.data.data,
      };

    case SUBMIT_AGENT_REQUEST:
      return {
        ...state,
        submitAgentRequest: action.payLoad.data.data,
      };

    case GET_MERCHANT_CREDENTIALS:
      return {
        ...state,
        merchantCredentials: action.payLoad.data.data,
      };

    case GET_MERCHANT_WEBHOOK:
      return {
        ...state,
        merchantWebhook: action.payLoad.data.data,
      };

    case CREATE_OR_UPDATE_MERCHANT_WEBHOOK:
      return {
        ...state,
        createOrUpdateMerchantWebhook: action.payLoad.data.data,
      };

    case GET_DEVELOPER_WEB_PAGE_LINK:
      return {
        ...state,
        developerWebPageLink: action.payLoad.data.data,
      };

    case GENERATE_NEW_KEY:
      return {
        ...state,
        generateNewKey: action.payLoad.data
      }

    case GET_REFERRAL_DETAILS:
      return {
        ...state,
        referralDetails: action.payLoad.data.data,
      };

    case GET_REFERRAL_REWARDS_BY_YEAR:
      return {
        ...state,
        referralRewardsBreakdown: action.payLoad.data.data,
      };

    case GET_REFERRAL_YEARS:
      return {
        ...state,
        referralYears: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
