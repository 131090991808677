import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";

import Button from "../../components/buttons/Button";
import { simpleDate } from "../../helpers/helper";
import SearchInput from "../../components/inputs/SearchInput";
import Sieve from "../../components/filter/Sieve";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import { PaginatedTable } from "../../components/table/Table";
import { getRecurringList } from "../../redux/recurringTransfer/recurringTransferActions";
import useRecurring from "../../hooks/useRecurring";
import { useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import EditModal from "./EditModal";
import PauseorDeleteModal from "./PauseorDeleteModal";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function RecurringPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const popOptionsRef = useRef();
  const editModalRef = useRef();
  const pauseOrDeleteModalRef = useRef();

  const { recurringList, isLoadingRecurringList } = useRecurring();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState();
  useEffect(() => {
    let tab = location?.pathname?.split("/")[3];
    if (tab === "single") {
      setSelectedType({
        recurringType: "single",
        name: "Transfer",
        base: "transfers",
      });
    } else if (tab === "bulk") {
      setSelectedType({
        recurringType: "bulk",
        name: "Bulk Transfer",
        base: "transfers",
      });
    } else if (tab === "bills") {
      setSelectedType({
        recurringType: "bills",
        name: "Bills",
        base: "bills",
      });
    } else {
      setSelectedType({
        recurringType: "airtime",
        name: "Airtime",
        base: "bills",
      });
    }
    selectedType?.name &&
      dispatch(
        getRecurringList(
          { current_page: currentPage, per_page: 10, paginate: 1 },
          { ...selectedType }
        )
      );
  }, [selectedType?.name, currentPage]);
  useEffect(() => {}, []);

  // Search Function And Control

  const onSearch = (search) => {
    dispatch(
      getRecurringList(
        {
          currency: selectedType,
          search: search,
          current_page: currentPage,
          per_page: 10,
          paginate: 1,
        },
        { ...selectedType }
      )
    );
  };

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      getRecurringList(
        {
          status: data.status,
          start_date:
            data.date_from ??
            new Date(new Date().setFullYear(new Date().getFullYear() - 1))
              .toISOString()
              .substring(0, 10),
          end_date: data.date_to ?? new Date().toISOString().substring(0, 10),
          current_page: currentPage,
          per_page: 10,
          paginate: 1,
        },
        { ...selectedType }
      )
    );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(
      getRecurringList(
        {
          currency: selectedType,
          per_page: 10,
          current_page: currentPage,
          paginate: 1,
        },
        { ...selectedType }
      )
    );
  };

  const columns = [
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span className="glade-normal-text-two cursor-pointer capitalize">
          <span
            className={`py-1 px-4 rounded-md ${
              value === "active"
                ? "bg-green-100 text-green-500"
                : value === "paused"
                ? "bg-yellow-100 text-yellow-500"
                : "bg-red-100 text-red-500"
            }`}
          >
            {value}
          </span>
        </span>
      ),
    },
    {
      Header:
        selectedType?.recurringType === "bulk" ? "Group Name" : "Recipient",
      accessor: "beneficiary_account_name",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two cursor-pointer">
          {value ??
            row?.original?.receipient_data ??
            row?.original?.group_name ??
            "N/A"}
        </span>
      ),
    },
    {
      Header: "Frequency",
      accessor: "frequency",
      Cell: ({ value }) => (
        <span className="glade-normal-text-two cursor-pointer">
          {value ?? "N/A"}
        </span>
      ),
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two cursor-pointer">
          {!simpleDate(value, "numeric", "short", "2-digit")
            ? "N/A"
            : simpleDate(value, "numeric", "short", "2-digit")}
        </span>
      ),
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two cursor-pointer">
          {!simpleDate(value, "numeric", "short", "2-digit")
            ? "N/A"
            : simpleDate(value, "numeric", "short", "2-digit")}
        </span>
      ),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two cursor-pointer">
          NGN {Number(value).toLocaleString()}
        </span>
      ),
    },
    {
      Header: "",
      accessor: "options",
      Cell: ({ _value, row }) => (
        <span className="glade-normal-text-two">
          <PopoverMain
            button={
              <span ref={popOptionsRef}>
                <BsThreeDotsVertical />
              </span>
            }
            popoverClassName="absolute right-3 cursor-pointer z-50"
          >
            <div className="flex flex-col relative gap-3">
              <div className="w-20 bg-white shadow rounded-sm ">
                <div className="p-2 pr4">
                  <PopoverButton
                    type={"button"}
                    onClick={() =>
                      editModalRef.current.open({
                        ...row?.original,
                        selectedType,
                      })
                    }
                    className={"text-left pr-4 cursor-pointer"}
                  >
                    Edit
                  </PopoverButton>
                </div>
                <div className="p-2">
                  <PopoverButton
                    type={"button"}
                    onClick={() =>
                      pauseOrDeleteModalRef.current.open({
                        ...row?.original,
                        action:
                          row?.original?.status === "active"
                            ? "Pause"
                            : "Continue",
                        selectedType,
                      })
                    }
                    className={"text-left pr-4 cursor-pointer"}
                  >
                    {row?.original?.status === "active" ? "Pause" : "Continue"}
                  </PopoverButton>
                </div>
                <div className="p-2">
                  <PopoverButton
                    type={"button"}
                    onClick={() =>
                      pauseOrDeleteModalRef.current.open({
                        ...row?.original,
                        action: "Delete",
                        selectedType,
                      })
                    }
                    className={"text-left pr-4 cursor-pointer glade-red"}
                  >
                    Delete
                  </PopoverButton>
                </div>
              </div>
            </div>
          </PopoverMain>
        </span>
      ),
    },
  ];
  return (
    <div className="px-3 mt-6 py-0">
      <div className="flex gap-4 mb-2 mt-4">
        <Link to="/dashboard/recurring-payment/single">
          <button
            className={`${
              selectedType?.name === "Transfer"
                ? "py-1 glade-subtitle-one glade-blue border-b-2 border-blue-800 "
                : "py-1 glade-subtitle-one glade-blue border-b-2 border-transparent "
            }`}
          >
            &nbsp;{"Transfer"}&nbsp;
          </button>
        </Link>
        <Link to="/dashboard/recurring-payment/bulk">
          <button
            className={`${
              selectedType?.name === "Bulk Transfer"
                ? "py-1 glade-subtitle-one glade-blue border-b-2 border-blue-800 "
                : "py-1 glade-subtitle-one glade-blue border-b-2 border-transparent "
            }`}
          >
            Bulk Transfer
          </button>
        </Link>
        <Link to="/dashboard/recurring-payment/bills">
          <button
            className={`${
              selectedType?.name === "Bills"
                ? "py-1 glade-subtitle-one glade-blue border-b-2 border-blue-800 "
                : "py-1 glade-subtitle-one glade-blue border-b-2 border-transparent "
            }`}
          >
            Bills
          </button>
        </Link>
        <Link to="/dashboard/recurring-payment/airtime">
          <button
            className={`${
              selectedType?.name === "Airtime"
                ? "py-1 glade-subtitle-one glade-blue border-b-2 border-blue-800 "
                : "py-1 glade-subtitle-one glade-blue border-b-2 border-transparent "
            }`}
          >
            Airtime
          </button>
        </Link>
      </div>
      <CardTableHeaderComplex
        title={`Showing ${recurringList?.total ?? 0} records`}
        filter={
          <Sieve
            types={["recurring status", "date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={isLoadingRecurringList}
            loading={isLoadingRecurringList}
          />
        }
        searchBar={
          <SearchInput
            disabled={isLoadingRecurringList}
            onSearch={onSearch}
            placeholder={"Search name"}
          />
        }
        rightButton={
          <div>
            <Button.Blue
              onClick={() =>
                history.push({
                  pathname: `/dashboard/recurring-payment/create/${selectedType?.recurringType}`,
                  state: selectedType,
                })
              }
              className="glade-button-blue"
              title={`+ New ${selectedType?.name}`}
              // disabled={true}
            />
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={isLoadingRecurringList}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["recurring status", "date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={isLoadingRecurringList}
                    loading={isLoadingRecurringList}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              {isLoadingRecurringList ? null : (
                <KebabDropdownItem
                  activeChild={
                    <span
                      onClick={() =>
                        history.push({
                          pathname: `/dashboard/recurring-payment/create/${selectedType?.recurringType}`,
                          state: selectedType,
                        })
                      }
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      {`+ New ${selectedType?.name}`}
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      {`+ New ${selectedType?.name}`}
                    </span>
                  }
                />
              )}
            </KebabDropdown>
          </div>
        }
      />

      <PaginatedTable
        columns={columns}
        data={
          typeof recurringList === "object" &&
          Array.isArray(recurringList?.data)
            ? recurringList?.data
            : []
        }
        empty_message="No Transaction"
        empty_sub_message="No recurring transactions to display "
        current_page={currentPage}
        loading={isLoadingRecurringList}
        setCurrentPage={setCurrentPage}
        totalPages={recurringList?.total}
      />
      <EditModal ref={editModalRef} />
      <PauseorDeleteModal ref={pauseOrDeleteModalRef} />
    </div>
  );
}
