import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  ADD_BUSINESS_CURRENCY_FAILURE,
  CURRENCY_LOADING,
  ADD_BUSINESS_CURRENCY_SUCCESS,
  GET_AVAILABLE_CURRENCIES_FAILURE,
  GET_AVAILABLE_CURRENCIES_SUCCESS,
  ADD_BUSINESS_WALLET_CURRENCY_SUCCESS,
  ADD_BUSINESS_WALLET_CURRENCY_FAILURE,
  GET_BUSINESS_CURRENCY_INFO_SUCCESS,
  GET_BUSINESS_CURRENCY_INFO_FAILURE,
  ADD_CURRENCY_USD_GBP_EUR_SUCCESS,
  ADD_CURRENCY_USD_GBP_EUR_FAILURE,
  GET_COUNTRIES,
  ADD_CURRENCY_WAITLIST_SUCCESS,
  ADD_CURRENCY_WAITLIST_FAILURE
} from "./types";
import {} from "./types";

export const businessAddCurrency = (formdata, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch({ type: CURRENCY_LOADING });
    Axios.post(`/businesses/multicurrency/add-currency`, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: ADD_BUSINESS_CURRENCY_SUCCESS, payLoad: res });
        successHandler(res);
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: ADD_BUSINESS_CURRENCY_FAILURE, payLoad: false });
        errorHandler(error, true);
        onFailure();
      });
  };
};

export const businessAddWalletCurrency = (formdata, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch({ type: CURRENCY_LOADING });
    Axios.post(`/businesses/multicurrency-wallet/add-wallet`, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        dispatch({ type: ADD_BUSINESS_WALLET_CURRENCY_SUCCESS, payLoad: res });
        successHandler(res);
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: ADD_BUSINESS_WALLET_CURRENCY_FAILURE,
          payLoad: false,
        });
        errorHandler(error, true);
        onFailure();
      });
  };
};

export const getAvailableCurrencies = () => {
  return (dispatch) => {
    // dispatch({ type: CURRENCY_LOADING });
    Axios.get(`/multicurrency/currencies`)
      .then((res) => {
        dispatch({ type: GET_AVAILABLE_CURRENCIES_SUCCESS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: GET_AVAILABLE_CURRENCIES_FAILURE, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const getBusinessCurrencyInfo = () => {
  return (dispatch) => {
    // dispatch({ type: CURRENCY_LOADING });
    Axios.get(`/multi-wallet/get-compliance`)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_CURRENCY_INFO_SUCCESS, payLoad: res });
        successHandler(res);
      })
      .catch((error) => {
        dispatch({ type: GET_BUSINESS_CURRENCY_INFO_FAILURE, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const AddCurrencyUSD_GBP_EUR = (formdata, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch({ type: CURRENCY_LOADING });
    Axios.post(`multi-wallet/request-account`, formdata,
      // {
      // headers: { "Content-Type": "multipart/form-data" },
      // }
    )
      .then((res) => {
        dispatch({ type: ADD_CURRENCY_USD_GBP_EUR_SUCCESS, payLoad: res });
        successHandler(res);
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: ADD_CURRENCY_USD_GBP_EUR_FAILURE,
          payLoad: false,
        });
        errorHandler(error, true);
        onFailure();
      });
  };
};

/**
 * Get Countries
 * 
 * @returns {void}
*/
export const getCountries = (callBack=()=> {}) => {
  return (dispatch) => {
    Axios.get(`foreign-account/countries`)
      .then((res) => {
        successHandler(res);
        callBack(res.data);
      })
      .catch((error) => {
        errorHandler(error, true);
      });
  }
}

/**
 * Join Waiting List
 * 
 * @param {object} payLoad {
    "first_name": "John",
    "last_name": "Doe",
    "business_email": "john.doe@example.com",
    "business_name": "Example Inc.",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "industry": "Technology",
    "country": "United States",
    "reason_for_account": "To expand business internationally.",
    "volume_processed": "0 - 10000 USD"
 }
 * @param {func} callback
 * @returns {void}
 */
 export const submitWaitingListMultiCurrency = (payLoad, onSuccess, onFailure) => {
  return (dispatch) => {
      dispatch({ type: CURRENCY_LOADING, payLoad:true});
      Axios.post(`/multi-wallet-list/join`,payLoad)
      .then((res) => {
          dispatch({ type: CURRENCY_LOADING, payLoad:false});
          dispatch({ type: ADD_CURRENCY_WAITLIST_SUCCESS, payLoad:res});
          successHandler(res, true);
          onSuccess();
      })
        .catch((error) => {
          dispatch({ type: CURRENCY_LOADING, payLoad:false});
          dispatch({ type: ADD_CURRENCY_WAITLIST_FAILURE, payLoad:false});
          errorHandler(error, true);
          onFailure();
      });
  }
}
