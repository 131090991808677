import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {
  getPosSales,
  getPosRevenue,
  getPosStates,
  getPosLga,
  requestPersonalPos,
  requestBusinessPos,
} from "../../redux/pos/posActions";
import usePos from "../../hooks/usePos";
import useUser from "../../hooks/useUser";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import SelectInput from "../../components/inputs/SelectInput";
import ImageInput from "../../components/inputs/ImageInput";
import posImage from "../../assets/images/pos_sample.svg";
import { CircleCheckIcon, CloseIcon } from "../../assets/icons/Icons";
import useTeam from "../../hooks/useTeam";

export default function RequestForPos() {
  const history = useHistory();
  const dispatch = useDispatch();
  const pos = usePos();
  const user = useUser();
  const {permissions} = useTeam();
  
  const [input, setInput] = React.useState({
    quantity: 1,
    revenue_id: "",
    sales_id: "",
    delivery_address: "",
    state_id: "",
    lga_id: "",
    pos_agreement: [],
  });
  const [successful, setSuccessFul] = useState(false);
  const [sampleImage, setSampleImage] = useState(false);
  const [timer, setTimer] = useState(20);

  useEffect(() => {
    dispatch(getPosSales());
    dispatch(getPosRevenue());
    dispatch(getPosStates());
    setInput({ ...input, purpose: user.isBusiness ? "business" : "personal" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    input.state_id && dispatch(getPosLga({ state_id: input.state_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.state_id]);

  useEffect(() => {
    if (successful) {
      timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
      if (timer <= 0) {
        setSuccessFul(false);
        history.push(!permissions?.includes("pos") ? "/dashboard/pos?tab=1" : "/teams/pos?tab=1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successful, timer]);

  const onSubmit = () => {
    let requestData = {
      quantity: input.quantity,
      revenue_id: input.revenue_id,
      sales_id: input.sales_id,
      delivery_address: input.delivery_address,
      state_id: input.state_id,
      lga_id: input.lga_id,
      pos_agreement: input.pos_agreement[0],
    };
    if (!user.isBusiness) {
      dispatch(requestPersonalPos(requestData, () => setSuccessFul(true)));
    }

    if (user.isBusiness) {
      dispatch(requestBusinessPos(requestData, () => setSuccessFul(true)));
    }
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const validateMyInput = () => {
    if (!input.sales_id.length) {
      return true;
    } else if (!input.revenue_id.length) {
      return true;
    } else if (!input.delivery_address.length) {
      return true;
    } else if (!input.lga_id.length) {
      return true;
    } else if (!input.state_id.length) {
      return true;
    } else if (!input.pos_agreement.length) {
      return true;
    } 
    else if (input?.pos_agreement && input?.pos_agreement?.length) {
          const fsize = input?.pos_agreement[0] && input?.pos_agreement[0]?.size;
          const file = Math.round((fsize / 1024));
          // The size of the file in kb.
          if (file > 3072) {
           return true
          }
  }
    else {
      return false;
    }
  };

  return (
    <>
      <div className="p-3 relative">
        <div className="flex flex-col">
          <div className="mb-6">
            <BreadCrumbs title={"POS"} path={!permissions?.includes("pos") ? "/dashboard/pos" : "/teams/pos"} />
          </div>

          <CardPlainWithButton>
            <CardPlainWithButtonSection
              title={"Request POS"}
              loading={pos.isLoading}
              buttonTitle={user.isBusiness ? "Request POS" : "Get a POS"}
              buttonOnClick={() => onSubmit()}
              buttonDisabled={pos.isLoading || validateMyInput()}
            >
              <div className="mt-6 px-14">
                <div className="space-y-6">
                  <SelectInput
                    label={"What is your average monthly Revenue?"}
                    id={"revenue_id"}
                    name={"revenue_id"}
                    value={input.revenue_id}
                    onChange={onChange}
                    autoComplete={"revenue_id"}
                    className={"appearance-none rounded-md"}
                  >
                    <option value={""} disabled={true}>
                      Select Revenue Range
                    </option>
                    {pos.posRevenue?.map((revenue, index) => (
                      <option key={index} value={revenue.revenue_id}>
                        {revenue.revenue_amount}
                      </option>
                    ))}
                  </SelectInput>

                  <SelectInput
                    label={"How many sales do you make per day?"}
                    id={"sales_id"}
                    name={"sales_id"}
                    value={input.sales_id}
                    onChange={onChange}
                    autoComplete={"sales_id"}
                    className={"appearance-none rounded-md"}
                  >
                    <option value={""} disabled={true}>
                      Select Sales Range
                    </option>
                    {pos.posSales?.map((sale, index) => (
                      <option key={index} value={sale.sales_id}>
                        {sale.sales_range}
                      </option>
                    ))}
                  </SelectInput>

                  {user.isBusiness && (
                    <SelectInput
                      label={"How many POS devices do you need?"}
                      id={"quantity"}
                      name={"quantity"}
                      value={input.quantity}
                      onChange={onChange}
                      autoComplete={"quantity"}
                      className={"appearance-none rounded-md"}
                    >
                      {Array(10)
                        .fill(0)
                        .map((x, key) => {
                          return (
                            <option key={key} value={key + 1}>
                              {key + 1}
                            </option>
                          );
                        })}
                    </SelectInput>
                  )}

                  <TextAreaInput
                    label={"What address should we deliver the POS to?"}
                    id={"delivery_address"}
                    name={"delivery_address"}
                    rows={3}
                    type={"text"}
                    value={input.delivery_address}
                    onChange={onChange}
                    placeholder={"Enter address"}
                    autoComplete={"delivery_address"}
                    className={"appearance-none rounded-md"}
                  />

                  <SelectInput
                    label={"State"}
                    id={"state_id"}
                    name={"state_id"}
                    value={input.state_id}
                    onChange={onChange}
                    autoComplete={"state_id"}
                    className={"appearance-none rounded-md"}
                  >
                    <option value={""} disabled={true}>
                      Select State
                    </option>
                    {pos.posStates?.map((state, index) => (
                      <option key={index} value={state.state_id}>
                        {state.state_name}
                      </option>
                    ))}
                  </SelectInput>

                  <SelectInput
                    label={"LGA"}
                    id={"lga_id"}
                    name={"lga_id"}
                    value={input.lga_id}
                    onChange={onChange}
                    autoComplete={"lga_id"}
                    className={"appearance-none rounded-md"}
                  >
                    <option value={""} disabled={true}>
                      Select LGA
                    </option>
                    {pos.posLga?.map((lga, index) => (
                      <option key={index} value={lga.city_id}>
                        {lga.city_name}
                      </option>
                    ))}
                  </SelectInput>

                  <div>
                    <ImageInput
                      label={
                        <div className="flex justify-between">
                          <span>Selfie Image with ID</span>
                          <button className="glade-small-text-two glade-blue" onClick={()=> setSampleImage(true)}>
                            View Sample
                          </button>
                        </div>
                        }
                      id={"pos_agreement"}
                      name={"pos_agreement"}
                      multiple={false}
                      maximumSize={3}
                      accept=".pdf, .png, .jpeg, .jpg"
                      placeholder="+ Choose File"
                      returnInput={(value) =>
                        setInput({ ...input, pos_agreement: value })
                      }
                    />
                    <span className="mt-3">Supported file types: PDF, PNG &  JPEG. Max file size allowed is 3MB.</span>
                  </div>
                </div>
              </div>
            </CardPlainWithButtonSection>
          </CardPlainWithButton>
        </div>
      </div>
      {successful && (
        <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-32 flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <div className="self-center bg-white rounded md:w-1/3 p-5 h-full">
              <div className="text-right mb-12">
                <button onClick={() => history.push(!permissions?.includes("pos") ? "/dashboard/pos?tab=1" : "/teams/pos?tab=1")}>
                  <CloseIcon />
                </button>
              </div>
              <div className="mx-auto w-20">
                <CircleCheckIcon />
              </div>
              <div className="text-center mt-8">
                <h4 className="font-bold">POS Request successful</h4>
                <p className="text-glade-gray-400">
                  Your POS request has been submitted and is under review. You
                  will receive feedback in a few hours.{" "}
                </p>
                <p className="mt-8 text-glade-gray-400">
                  This modal will automatically close in{" "}
                  <span className="font-bold">{timer}</span> seconds
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {sampleImage &&(
        <div className="h-full bg-glade-trans-400 fixed top-0 w-full left-0 z-50 px-5 md:px-32 py-32 flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <div className="self-center bg-white rounded md:w-1/3 p-5 h-full">
              <div className="text-center mb-4">
                <span>Sample Image</span>
                <button onClick={() => setSampleImage(false)} className="float-right">
                  <CloseIcon />
                </button>
              </div>
              <div className="mx-auto mb-4">
                <img src={posImage} alt="Sample" className="object-scale-down mx-auto rounded-[8px]" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
