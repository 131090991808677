import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { RECORDING_BUTTON, RECORD_BUTTON } from "../../../assets/images/images";
import Button from "../../../components/buttons/Button";
import { useDispatch } from "react-redux";
import { MetamapStatusVerification, signupVerificationID } from "../../../redux/auth/authActions";
import useUser from "../../../hooks/useUser";
import { CloseIcon } from "../../../assets/icons/Icons";


const VideoRecorder = ({ input, finishedCallback, setIdUploadResponse, setCaptureVideo }) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [progress, setProgress] = useState(0);
  const [videoChunks, setVideoChunks] = useState([]);
  const dispatch = useDispatch();
  const user = useUser();

  useEffect(() => {
    if (progress === 5) {
      handleStopCaptureClick();
    }
    if (recording) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording, progress]);

  useEffect(() => {
    if (user?.isLoadingVerificationID) {
      setIdUploadResponse('loading')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isLoadingVerificationID])

  useEffect(() => {
    if (user?.isLoadingMetaMapStatus) {
      setIdUploadResponse('loading')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isLoadingMetaMapStatus])

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setVideoChunks((prev) => prev.concat(data));
      }
    },
    [setVideoChunks]
  );


  // Function to handle video recording
  const handleStartCaptureClick = useCallback(() => {
    setRecording(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef?.current?.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setRecording, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef?.current?.stop();
    setRecording(false);
  }, [mediaRecorderRef, setRecording]);


  function arrayBufferToBase64(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    const binaryArray = Array.from(uint8Array);
    const base64String = btoa(binaryArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));

    return base64String;
  }

  const videoConstraints = {
    facingMode: "user"
  };

  const onSuccessStatus = (res) => {
    if (res.status === 'verified') {
      setIdUploadResponse('success')
    } else if (res.status === 'pending') {
      setIdUploadResponse('loading')
    } else if (res.status === 'reviewNeeded') {
      setIdUploadResponse('reviewNeeded')
    } else {
      setIdUploadResponse('fail')
    }
  }

  const onFailure = () => {
    setIdUploadResponse('fail')
  }

  const onSuccess = () => {
    dispatch(MetamapStatusVerification(input?.email, onSuccessStatus, onFailure))
  }

  // Function to handle sending the recorded video to API
  const handleSend = useCallback(async () => {

    if (videoChunks.length) {
      // Create a single Blob from recorded video chunks
      const fullVideo = new Blob(videoChunks, { type: "video/webm" });
      const videoBuffer = new Uint8Array(await fullVideo.arrayBuffer());
      const formData = new FormData();
      formData.append("video", fullVideo);
      let payLoad = {
        email: input?.email,
        country_code: input?.country_code,
        document_type: input?.id_type,
        id_card_front: input?.id_card_front,
        id_card_back: input?.id_card_back,
        liveness_video: arrayBufferToBase64(videoBuffer)
      }
      finishedCallback();
      // Send the video to the API
      dispatch(signupVerificationID(payLoad, onSuccess, onFailure));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoChunks, input])

  return (
    <div className="text-white flex flex-col justify-center items-center">

      <div className="w-full">
        <h3 className="mt-8 glade-heading-two-bold text-center">Video Verification</h3>
        <button onClick={() => setCaptureVideo(false)} className="font-bold float-right -mt-8 mr-8">
          <CloseIcon />
        </button>
      </div>
      <div>
        {/* Add webcam component */}
        <Webcam
          audio={false}
          ref={webcamRef}
          mirrored={true}
          className={`rounded-full ${videoChunks.length > 0 ? 'glade-border-blue' : 'border-dashed border-white'} border-4 mt-28`}
          videoConstraints={videoConstraints}
          style={{
            width: "343px",
            height: "400px",
            objectFit: "cover"
          }}
        />
        {videoChunks.length > 0 &&
          <div
            style={{
              position: "absolute",
              top: "42%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "white",
              borderRadius: "50%",
            }}
          >
            <BsFillCheckCircleFill color="#2C6DEA" size={70} />
          </div>
        }
      </div>

      <p className="text-center mt-4">Keep your face  within the frame</p>

      {/* Show progress */}
      <div className="w-80 h-4 glade-bg-rectangle-gray rounded-full mt-4"
      // style={{ width: '80%' }}
      >
        <div
          className="h-full bg-blue-500 rounded-full"
          style={{ width: `${progress * 20}%` }}
        ></div>
      </div>

      {/* Buttons to start/stop recording and send video */}
      <div className="mt-4">
        {!recording && !(videoChunks.length > 0) &&
          <img src={RECORD_BUTTON} alt='record button' className="cursor-pointer" onClick={handleStartCaptureClick} />
        }
        {
          recording &&
          <img src={RECORDING_BUTTON} alt='recording button' className="cursor-pointer" onClick={handleStopCaptureClick} />
        }
      </div>
      {videoChunks.length > 0 && (
        <>
          <div className="pt-3 flex justify-center w-full">
            <Button.Blue
              title={'Submit Video'}
              type={'button'}
              className={'py-3 w-2/3 md:w-1/3 lg:w-1/6'}
              onClick={handleSend}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default VideoRecorder;
