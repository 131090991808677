import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import {
  LOADING_BILLS,
  RELOAD_BILLS,
  GET_ALL_BILLS,
  GET_ALL_BILL_TYPES,
  GET_BILLERS,
  GET_BILLER_ITEMS,
  RESOLVE_METER_DETAILS,
  RESOLVE_INTERNET_ACCOUNT_DETAILS,
  RESOLVE_CABLE_TV_DETAILS,
  BUY_AIRTIME,
  BUY_ELECTRICITY,
  BUY_CABLE_TV,
  BUY_INTERNET,
  GET_BILLS_BENEFACTRICES
} from "./types";

/**
 * Get bills
 * @returns {void}
 */
export const getBills = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BILLS, payLoad: true });
    Axios.get(`/bills/history`,{params:payLoad})
    .then((res) => {
    dispatch({ type: LOADING_BILLS, payLoad: false });
      dispatch({ type: GET_ALL_BILLS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Get bill types
 * @returns {void}
 */
 export const getAllBillTypes = () => {
  return (dispatch) => {
    Axios.get(`/bills/lookup/all`)
    .then((res) => {
      dispatch({ type: GET_ALL_BILL_TYPES, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get billers
 * 
 * @returns {void}
 */
export const getBillers = () => {
  return (dispatch) => {
    Axios.get(`/bills/lookup/billers`)
    .then((res) => {
      dispatch({ type: GET_BILLERS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Get biller items
 * 
 * @param {object} payLoad {biller_code:''}
 * @returns {void}
 */
export const getBillerItems = (payLoad) => {
  return (dispatch) => {
    Axios.get(`bills/lookup/billers/${payLoad.biller_code}/items`)
    .then((res) => {
      dispatch({ type: GET_BILLER_ITEMS, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Resolve Meter Details
 * 
 * @param {payLoad} payLoad {meter_number:'', paycode:''}
 * @returns {void}
 */
export const resolveMeterDetails = (payLoad, callBack=()=>{}, loading=()=>{}) => {
  loading(true);
  return (dispatch) => {
    Axios.get(`bills/lookup/meter/${payLoad.meter_number}/${payLoad.paycode}`)
    .then((res) => {
      loading(false);
      dispatch({ type: RESOLVE_METER_DETAILS, payLoad: res });
      successHandler(res);
      callBack(res.data.data);
    })
    .catch((error) => {
      loading(false);
      errorHandler(error, true);
    });
  };
};

/**
 * Resolve Internet Account Details
 * 
 * @param {object} payLoad {account_number:'', paycode:''}
 * @returns {void}
 */
export const resolveInternetAccountDetails = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    Axios.get(`bills/lookup/internet/${payLoad.account_number}/${payLoad.paycode}`)
    .then((res) => {
      dispatch({ type: RESOLVE_INTERNET_ACCOUNT_DETAILS, payLoad: res });
      successHandler(res);
      callBack(res.data.data);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};

/**
 * Resolve Cable TV Details
 * 
 * @param {object} payLoad {card_iuc_number:'', paycode:'', amount:''}
 * @returns {void}
 */
export const resolveCableTvDetails = (payLoad, callBack=()=>{}, loading=()=>{}) => {
  loading(true);
  return (dispatch) => {
    Axios.get(`bills/lookup/cable-tv/${payLoad.card_iuc_number}/${payLoad.paycode}/${payLoad.amount}`)
    .then((res) => {
      loading(false);
      dispatch({ type: RESOLVE_CABLE_TV_DETAILS, payLoad: res });
      successHandler(res);
      callBack(res.data.data);
    })
    .catch((error) => {
      loading(false);
      errorHandler(error, true);
    });
  };
};

/**
 * Buy Airtime
 * 
 * @param {object} payLoad {
    "paycode": "cHwBhPniYesycir",
    "reference": "0908007996",
    "amount": "200",
    "save_beneficiary": 1
 }
 * @returns {void}
 */
export const buyAirtime = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BILLS, payLoad: true });
    Axios.post(`bills/airtime`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      dispatch({ type: RELOAD_BILLS});
      dispatch({ type: BUY_AIRTIME, payLoad: res });
      successHandler(res, true);
    })
    .catch((error) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Buy Electricity
 * 
 * @param {object} payLoad {
    "paycode": "Bf5dYhtjUYhnjdk",
    "reference": "62130898844",
    "amount": "2000",
    "save_beneficiary": 1
 }
 * @returns {void}
 */
export const buyElectricity = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BILLS, payLoad: true });
    Axios.post(`bills/electricity`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      dispatch({ type: RELOAD_BILLS});
      dispatch({ type: BUY_ELECTRICITY, payLoad: res });
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Buy Cable TV
 * 
 * @param {object} payLoad {
    "paycode": "kzAJKN9A4VyL6BB",
    "card_iuc_number": "0908007996",
    "amount": "200",
    "save_beneficiary": 1
 }
 * @returns {void}
 */
export const buyCableTv = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BILLS, payLoad: true });
    Axios.post(`bills/cable-tv`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      dispatch({ type: RELOAD_BILLS});
      dispatch({ type: BUY_CABLE_TV, payLoad: res });
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Buy Internet
 * 
 * @param {object} payLoad {
    "paycode": "d52ebe1fb001772735e9640bf8b2cb",
    "reference": "62130898844",
    "amount": "2000",
    "save_beneficiary": 1
 }
 * @returns {void}
 */
export const buyInternet = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BILLS, payLoad: true });
    Axios.post(`bills/internet`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      dispatch({ type: RELOAD_BILLS});
      dispatch({ type: BUY_INTERNET, payLoad: res });
      successHandler(res, true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_BILLS, payLoad: false });
      errorHandler(error, true);
    });
  };
};

/**
 * Get Beneficiaries
 * 
 * @param {object} payLoad {type:''}
 * @returns {void}
 */
export const getBeneficiaries = (payLoad) => {
  return (dispatch) => {
    Axios.get(`bills/${payLoad.type}/beneficiaries`,{params:payLoad})
    .then((res) => {
      dispatch({ type: GET_BILLS_BENEFACTRICES, payLoad: res });
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  };
};