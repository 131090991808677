import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { 
  LOADING_INVOICE,
  RELOAD_INVOICE,
  GET_INVOICE,
  GET_INVOICE_BY_ID,
  GET_INVOICE_COUNTER,
  CREATE_INVOICE,
  CREATE_INVOICE_DRAFT,
  UPDATE_INVOICE,
  UPDATE_INVOICE_STATUS,
  DELETE_INVOICE,
  GET_INVOICE_CURRENCIES,
  SEND_INVOICE_REMINDER,
  CREATE_INVOICE_CUSTOMER,
  GET_INVOICE_CUSTOMER_WHITELISTED,
  GET_INVOICE_CUSTOMER_BLACKLISTED
} from "./types";

/**
 * Get all invoice
 * @returns {void}
 */
 export const getInvoice = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.get(`/invoice/history`,{params:payLoad})
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: GET_INVOICE, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get an invoice by id
 * @param {object} payLoad {id:'1}
 * @returns {void}
 */
export const getInvoiceById = (payLoad) => {
  return (dispatch) => {
    Axios.get(`/invoice/${payLoad.id}`)
    .then((res) => {
      dispatch({ type: GET_INVOICE_BY_ID, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get invoice counter
 * @returns {void}
 */
export const getInvoiceCounter = () => {
  return (dispatch) => {
    Axios.get(`/invoice/counter`)
    .then((res) => {
      dispatch({ type: GET_INVOICE_COUNTER, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Create an invoice
 * @params {object} payLoad {
    "customer_id": 3,
    "address": "no 50 jakanda road",
    "invoice_no": "0000001",
    "currency": "NGN",
    "date_due": "2021-12-09",
    "shipping": 1000,
    "discount": "10",
    "discount_type": "percentage",
    "vat": 2,
    "note": "",
    "charge_user": 1,
    "fund_wallet": 1,
    "items": [
      {
          "description": "core i12 laptop",
          "cost": 300000,
          "qty": 2,
          "total":600000,
          "id": 4
      },
      {
          "description": "delivery charges",
          "cost": 7000,
          "qty": 1,
          "total":70000,
          "id": 5
      }
    ]
 }
 * @returns {void}
 */
export const createInvoice = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.post(`/invoice`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: RELOAD_INVOICE });
      dispatch({ type: CREATE_INVOICE, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Create an invoice draft
 * @param {object} payLoad {
    "user_name": "Odiri Udele",
    "email": "odiriudele@gmail.com",
    "phone": "2348062550416",
    "address": "no 50 jakanda road",
    "invoice_no": "0000034",
    "currency": "NGN",
    "due_date": "2021-06-09",
    "vat": 2,
    "note": "",
    "charge_user": 1,
    "fund_wallet": 1,
    "items": [
      {
          "description": "core i12 laptop",
          "price": 300000,
          "qty": 2,
          "id": 4
      },
      {
          "description": "delivery charges",
          "price": 7000,
          "qty": 1,
          "id": 5
      }
    ],
    "is_draft" : 1
 }
 * @returns {void}
 */
export const createInvoiceDraft = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.post(`/invoice/draft/create`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: RELOAD_INVOICE });
      dispatch({ type: CREATE_INVOICE_DRAFT, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Update invoice
 * 
 * @param {object} payLoad {
    "customer_id": 3,
    "address": "no 50 jakanda road",
    "invoice_no": "0000010",
    "currency": "NGN",
    "date_due": "2021-12-09",
    "shipping": 2000,
    "discount": "10",
    "discount_type": "percentage",
    "vat": 2,
    "note": "",
    "charge_user": 1,
    "fund_wallet": 1,
    "items": [
        {
            "description": "core i12 laptop",
            "cost": 300000,
            "qty": 2,
            "total":600000,
            "id" : 11
        }
    ]
 }
 * @returns void
 */
export const updateInvoice = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.put(`/invoice/${payLoad.id}`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: RELOAD_INVOICE });
      dispatch({ type: UPDATE_INVOICE, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Update an invoice status
 * @param {object} payLoad {"id":'4', "status":'paid'}
 * @returns {void}
 */
export const updateInvoiceStatus = (payLoad, callBack) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.put(`/invoice/${payLoad.id}/status`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: RELOAD_INVOICE });
      dispatch({ type: UPDATE_INVOICE_STATUS, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Delete an invoice
 * @param {object} payLoad {id:'4}
 * @returns {void}
 */
export const deleteInvoiceAction = (payLoad, callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.delete(`/invoice/${payLoad.id}`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: RELOAD_INVOICE });
      dispatch({ type: DELETE_INVOICE, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get invoice currencies
 * @returns {void}
 */
export const getInvoiceCurrencies = () => {
  return (dispatch) => {
    Axios.get(`/invoice/lookup/currency`)
    .then((res) => {
      dispatch({ type: GET_INVOICE_CURRENCIES, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Send invoice reminder
 * @param {object} payLoad {"id" : "1"}
 * @returns {void}
 */
export const sendInvoiceReminder = (payLoad,callBack=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.get(`invoice/${payLoad.id}/reminder`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: RELOAD_INVOICE });
      dispatch({ type: SEND_INVOICE_REMINDER, payLoad:res});
      successHandler(res,true);
      callBack();
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Create an invoice customer
 * @param {object} payLoad {"id" : "1"}
 * @returns {void}
 */
export const createInvoiceCustomer = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICE, payLoad:true});
    Axios.post(`businesses/customers`,payLoad)
    .then((res) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      dispatch({ type: RELOAD_INVOICE });
      dispatch({ type: CREATE_INVOICE_CUSTOMER, payLoad:res});
      successHandler(res,true);
    })
    .catch((error) => {
      dispatch({ type: LOADING_INVOICE, payLoad:false});
      errorHandler(error, true);
    });
  }
}

/**
 * Get all invoice customers on whitelist
 * @returns {void}
 */
export const getInvoiceCustomersOnWhiteList = () => {
  return (dispatch) => {
    Axios.get(`/businesses/customers?status=whitelisted`)
    .then((res) => {
      dispatch({ type: GET_INVOICE_CUSTOMER_WHITELISTED, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}

/**
 * Get all invoice customers on blacklist
 * @returns {void}
 */
export const getInvoiceCustomersOnBlackList = () => {
  return (dispatch) => {
    Axios.get(`/businesses/customers?status=blacklisted`)
    .then((res) => {
      dispatch({ type: GET_INVOICE_CUSTOMER_BLACKLISTED, payLoad:res});
      successHandler(res);
    })
    .catch((error) => {
      errorHandler(error, true);
    });
  }
}