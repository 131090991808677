import { Bank } from "../../assets/icons/Icons";

const SelectRecurringTransferType = ({ handleType }) => {
  return (
    <div>
      <p className="mt-12 glade-heading-two glade-home-dark">
        <span className="font-bold">Transfer Type</span>
      </p>
      <p className="glade-grey-dark mt-1">Choose a transfer type to begin</p>
      <div className="flex gap-12 py-12">
        <div
          className="flex flex-col items-center gap-3 border cursor-pointer rounded-md p-6"
          onClick={() => handleType("internal")}
        >
          <Bank color={"#2C6DEA"} />
          <p className="glade-heading-three glade-home-dark">Glade</p>
          <p>
            Send money to another <br /> Glade account
          </p>
        </div>
        <div
          className="flex flex-col items-center gap-2 border cursor-pointer rounded-md p-6"
          onClick={() => handleType("external")}
        >
          <Bank color={"#2C6DEA"} />
          <p className="glade-heading-three glade-home-dark">Local</p>
          <p>
            Send money to another <br /> Glade account
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectRecurringTransferType;
