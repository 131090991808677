import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { CardTableHeaderPlain } from '../../components/cards/CardTableHeader';
import { CardTableBody, CardTableBodyRow, CardTableBodyCell } from '../../components/cards/CardTableBody';
import { CardTableFooter } from '../../components/cards/CardTableFooter';
import Button from '../../components/buttons/Button';
import PipedButton from '../../components/buttons/PipedButton';
import useSettings from '../../hooks/useSettings';
import { getSettlementAccounts, deleteSettlementAccount } from '../../redux/settings/settingsActions';
import {simpleDate, isArray, isEmpty, pagination} from '../../helpers/helper';
import EmptyTable from '../../components/empty-states/EmptyTable';
import EmptyDesk from "../../components/empty-states/EmptyDesk";

export default function SettingsSettlement() {

    const history = useHistory();
    const dispatch = useDispatch();
    const settings = useSettings();
    React.useEffect(() => {
        dispatch(getSettlementAccounts());
        // eslint-disable-next-line 
    }, [settings.shouldReload]);

    // Pagination Function And Control
    const [currentPage, setCurrentPage] = React.useState(1);
    const [tableData, setTableData] = React.useState([]);
    React.useEffect(() => {
        setTableData(pagination(settings.settlementAccounts, 10, currentPage));
    }, [currentPage, settings.settlementAccounts]);

    // Edit a settlement
    const editSettlement = (item) => {
        history.push({pathname:"/dashboard/settings/settlement/add-bank", state:item})
    }

    // Delete a settlement
    const deleteSettlement = (item) => {
        dispatch(deleteSettlementAccount({id:item.id}));
    }

    const renderSettingsSettlementTable = (data) => {
        return data.map((item,key)=>{
            return (
                <CardTableBodyRow key={key}>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.bank_name}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.account_number}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{item.account_name}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-one">{item.currency}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span className="glade-normal-text-two">{simpleDate(item.created_at)}</span>
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <PipedButton 
                            primaryButtonTitle="Edit" 
                            primaryButtonOnClick={()=>editSettlement(item)}
                            secondaryButtonTitle="Delete" 
                            secondaryButtonOnClick={()=>deleteSettlement(item)}
                            secondaryButtonClassName="glade-red" 
                        />
                    </CardTableBodyCell>
                    <CardTableBodyCell>
                        <span></span>
                    </CardTableBodyCell>
                </CardTableBodyRow>
            )
        });
    }

    const renderSettingsSettlementDesk = (data) => {
        return data.map((item, key) => {
            return (
                <div key={key} className="flex flex-row justify-between p-1 border-b">
                    <div className="flex flex-row space-x-3">
                        <div className="flex flex-col space-y-1 py-1">
                            <div>
                                <span className="glade-subtitle-one glade-black whitespace-nowrap">
                                    {item.account_number}
                                </span>
                            </div>
                            <div>
                                <span className="glade-normal-text-two glade-grey-cool whitespace-nowrap">
                                    {item.bank_name}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <PipedButton 
                            primaryButtonTitle="Edit" 
                            primaryButtonOnClick={()=>editSettlement(item)}
                            secondaryButtonTitle="Delete" 
                            secondaryButtonOnClick={()=>deleteSettlement(item)}
                            secondaryButtonClassName="glade-red" 
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="px-3 py-0">
            <div className="mb-9 md:hidden">
                <BreadCrumbs title={"Settings"} path={"/dashboard/settings"} />
            </div>

            <CardTableHeaderPlain 
                title={'Bank Accounts'}
                rightButton={<Button.Blue onClick={()=>history.push("/dashboard/settings/settlement/add-bank")} title="+ Add Bank Account" />}
            />

            {isEmpty(tableData.data) || settings.isLoading?
                <React.Fragment>
                    <div className="hidden md:block">
                        <EmptyTable column={['','Bank Name','Account Number','Account name','Currency','Type',' ','']} />
                    </div>

                    <div className="block md:hidden">
                        <EmptyDesk row={[1,2]} loading={settings.isLoading} />
                    </div>
                </React.Fragment>
            :
                <React.Fragment>
                    <div className="hidden md:block">
                        <CardTableBody column={['','Bank Name','Account Number','Account name','Currency','Type',' ','']}>
                            {isArray(tableData.data) && renderSettingsSettlementTable(tableData.data)}
                        </CardTableBody>
                    </div>

                    <div className="block md:hidden">
                        <div className="bg-white px-6">
                            {isArray(tableData.data) && renderSettingsSettlementDesk(tableData.data)}
                        </div>
                    </div>
                </React.Fragment>
            }

            <CardTableFooter 
                empty={isEmpty(tableData.data)}
                loading={settings.isLoading}
                pageNumber={currentPage} 
                totalPages={tableData.totalPages}
                prevOnClick={()=>setCurrentPage(tableData.previousPage)}
                nextOnClick={()=>setCurrentPage(tableData.nextPage)}
                thereIsPreviousPage={tableData.thereIsPreviousPage}
                thereIsNextPage={tableData.thereIsNextPage}
            />
        </div>
    )
}
