import React from 'react';
import { useDispatch } from 'react-redux';
import PlainModal from '../../components/modals/PlainModal';
import Button from '../../components/buttons/Button';
import useUser from "../../hooks/useUser";
import { updatePassword } from '../../redux/settings/settingsActions';
import TextInput from '../../components/inputs/TextInput';

export default React.forwardRef(function ChangeUserPasswordModal(props,ref) {

    const dispatch = useDispatch();
    const user = useUser();

    // Ref Handler
    const plainModalRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
        open: () => plainModalRef.current.open(),
        close: () => plainModalRef.current.close(),
        toggle: () => plainModalRef.current.toggle(),
    }));

    const [input, setInput] = React.useState({
        old_password:'',
        new_password:'',
        confirm_password:''
    });

    const changePassword = () => {
        dispatch(updatePassword({
            user_uuid:user.userInfo.uuid,
            old_password:input.old_password,
            new_password:input.new_password,
        }));
        plainModalRef.current.close();
    }

    const onChange = (e) => {
        setInput({...input,[e.target.name]:e.target.value});
    }

    return (
        <PlainModal 
            ref={plainModalRef}
            primaryButton={<React.Fragment></React.Fragment>}
            modalTitle={'Change Password'}
            modelContentSection={
                <div className="flex flex-col space-y-9">

                    <div className="flex flex-col px-9 pt-9 text-left space-y-9">
                        <TextInput 
                            label={'Old Password'}
                            id={'old_password'}
                            name={'old_password'}
                            type={'password'}
                            value={input.old_password ?? ''}
                            onChange={onChange}
                            placeholder={'Enter old password'}
                            autoComplete={'old_password'}
                            className={'appearance-none'}
                        />

                        <TextInput 
                            label={'New Password'}
                            id={'new_password'}
                            name={'new_password'}
                            type={'password'}
                            value={input.new_password ?? ''}
                            onChange={onChange}
                            placeholder={'Enter new password'}
                            autoComplete={'new_password'}
                            className={'appearance-none'}
                        />

                        <TextInput 
                            label={'Confirm Password'}
                            id={'confirm_password'}
                            name={'confirm_password'}
                            type={'password'}
                            value={input.confirm_password ?? ''}
                            onChange={onChange}
                            placeholder={'Confirm password'}
                            autoComplete={'confirm_password'}
                            className={'appearance-none'}
                        />
                    </div>

                    <div className="flex flex-row justify-between px-3 py-3 border-t-2 border-gray-200">
                        <div>
                            <Button.Blue onClick={()=>plainModalRef.current.close()} className="glade-button-off-white glade-grey-dark" title="Cancel" />
                        </div>
                        <div>
                            <Button.Blue onClick={()=>changePassword()} title="Change Password" />
                        </div>
                    </div>
                </div>
            }
        />
    )
})
