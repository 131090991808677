import React, { useState } from "react";
import FundAccountManualModal from "./FundAccountManualModal";
import CopyTextButton from '../../components/buttons/CopyTextButton';
import useUser from "../../hooks/useUser";
import FundAccountModal from "./FundAccountModal";
import Modal from "../../components/modals/Modal";
import { useHistory } from "react-router-dom";

const FundAccount = () => {

  const FundAccountModalRef = React.useRef();
  const [showFundModal, setShowFundModal] = useState(true);
  const { accountInfo, businessInfo, isBusiness, userInfo } = useUser();
  const history = useHistory();
  return (
    <div className="grid grid-cols-2 gap-4">
      <Modal open={showFundModal} onClose={() => {setShowFundModal(false);history.goBack();}}>
        <FundAccountModal onClose={() => {
          setShowFundModal(false);
          history.goBack(); 
        }} />
      </Modal >
    </div>
  );
};

export default FundAccount;
