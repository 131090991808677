import React, { Fragment, useLayoutEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logoutUserLocallyAction } from '../../redux/auth/unAuthActions';

export default function Logout() {

    const dispatch = useDispatch();
    const [loggedOut, setLoggedOut] = useState(false);
    useLayoutEffect(() => {
        dispatch(logoutUserLocallyAction(() => setLoggedOut(true)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
          {loggedOut ? <Redirect to="/auth/login" push /> : null}
        </Fragment>
    )
}
