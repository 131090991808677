import { useSelector } from "react-redux";

const useConvert = () => {
    const convert = useSelector((state) => state.convert);

    const {
        isLoadingRate,
        isLoadingConversion,
        rateData,
        rateDetails
    } = convert

    return {
        isLoadingRate,
        isLoadingConversion,
        rateData,
        rateDetails
    };
};

export default useConvert