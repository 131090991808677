import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import SelectInput from '../../../components/inputs/SelectInput';
import Button from '../../../components/buttons/Button';
import {
  getApprovedCountries
} from "../../../redux/countries/countriesActions"

const BusinessCountry = ({ onClick, input, setInput }) => {
  const dispatch = useDispatch();

  const [listOfCountries, setListOfCountries] = useState([]);

  const selectHandler = (e) => {
    const country = listOfCountries.find(obj => obj.country_id === Number(e.target.value));
    setInput({
      ...input,
      compliance_country: e.target.value,
      secondary_phone_code: country?.dial_code,
      country_code: country?.country_code,
      country_name: country?.country_name

    })
  }

  useEffect(() => {
    listOfCountries?.length < 1 && dispatch(getApprovedCountries({ status: 1 }, (res) => setListOfCountries(res.data.data))) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfCountries])


  return (
    <Container>
      <div className='md:w-3/5 md:mx-auto div-height flex flex-col items-center justify-center '>
        <h3 className='my-3 glade-subtitle-three'>What country is your business registered in?</h3>
        <div className=' w-5/6 lg:w-3/5'>
        <SelectInput
          label={''}
          id={'compliance_country'}
          name={'compliance_country'}
          value={input.compliance_country ?? ''}
          onChange={selectHandler}
          autoComplete={'compliance_country'}
          className={`appearance-none rounded-md`}
        >
          <option value={""} disabled={true}>Select Country</option>
          {listOfCountries?.map((c, index) => {
            return (
              <option key={index} value={c.country_id}>{c.country_name}</option>
            )
          }
          )}
          </SelectInput>
          </div>
        <div className=' w-5/6 lg:w-3/5 flex justify-center'>
          <Button.Blue
            onClick={onClick}
            title={"Proceed"}
            className={"my-6 py-2"}
            disabled={!input.compliance_country}
          />
        </div>
      </div>

    </Container>
  )
}

export default BusinessCountry

const Container = styled.div`
  .div-height{
  min-height: 60vh;
  }
`