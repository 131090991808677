import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardTableHeaderComplex } from "../../components/cards/CardTableHeader";

import TextIndicator from "../../components/indicators/TextIndicator";
import Button from "../../components/buttons/Button";
import useUser from "../../hooks/useUser";
import useVirtualCards from "../../hooks/useVirtualCards";
import { filterVirtualCards } from "../../redux/virtual-cards/virtualCardActions";
import {
  statusEnum,
  simpleDate,
} from "../../helpers/helper";
import SearchInput from "../../components/inputs/SearchInput";
import Sieve from "../../components/filter/Sieve";
import CopyTextButton from "../../components/buttons/CopyTextButton";
import {
  KebabDropdown,
  KebabDropdownItem,
} from "../../components/dropdowns/KebabDropdown";
import { PaginatedTable } from "../../components/table/Table";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function VirtualCardList({
  selectedCard,
  setSelectedCard,
  currentPage,
  setCurrentPage,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const virtualCards = useVirtualCards();
  const { virtualCardFilterResults } = useVirtualCards();

  // Search Function And Control
  const onSearch = (search) => {
    dispatch(
      filterVirtualCards({
        currency: selectedCard,
        search: search,
        current_page: currentPage,
        per_page: 10,
        paginate: 1,
      })
    );
  };

  // Filter Function And Control
  const onFilter = (data) => {
    dispatch(
      filterVirtualCards({
        currency: selectedCard,
        status: data.card_status,
        date_from:
          data.date_from ??
          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            .toISOString()
            .substring(0, 10),
        date_to: data.date_to ?? new Date().toISOString().substring(0, 10),
        current_page: currentPage,
        per_page: 10,
        current_page: currentPage,
        paginate: 1,
      })
    );
  };

  // Reset Filter Function And Control
  const onReset = () => {
    dispatch(
      filterVirtualCards({
        currency: selectedCard,
        per_page: 10,
        current_page: currentPage,
        paginate: 1,
      })
    );
  };

  const columns = [
    {
      Header: "Status",
      accessor: "freezed",
      Cell: ({ value, row }) => (
        <Link to={`/dashboard/virtual-cards/detail?reference=${row?.original?.reference}`}>
          <span className="glade-normal-text-two">
            <TextIndicator
              type={statusEnum[value ? false : true]}
              title={value ? "Frozen" : "Active"}
            />
          </span>
        </Link>
      ),
    },
    {
      Header: "Name",
      accessor: "fullname",
      Cell: ({ value, row }) => (
        <Link to={`/dashboard/virtual-cards/detail?reference=${row?.original?.reference}`}>
          <span className="glade-normal-text-two">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "card_number",
      Header: "Card Number",
      Cell: ({ value }) => (
        <span>
          <CopyTextButton maskAsCardNumber={true} text={value ?? ""} />
        </span>
      ),
    },
    {
      accessor: "created_at",
      Header: "Date Created",
      Cell: ({ value, row }) => (
        <Link to={`/dashboard/virtual-cards/detail?reference=${row?.original?.reference}`}>
          <span className="glade-normal-text-two">
            {value &&
              simpleDate(
                row?.original?.created_at,
                "numeric",
                "short",
                "2-digit"
              )}
          </span>
        </Link>
      ),
    },
  ];
  return (
    <div className="px-3 py-0">
      <div className="flex gap-4 mb-2 mt-4">
        <button
          className={`${
            selectedCard === ""
              ? "py-1 glade-subtitle-one glade-blue border-b-2 border-blue-800 "
              : "py-1 glade-subtitle-one glade-blue border-b-2 border-transparent "
          }`}
          onClick={() => setSelectedCard("")}
        >
          &nbsp;{"All"}&nbsp;
        </button>
        <button
          className={`${
            selectedCard === "USD"
              ? "py-1 glade-subtitle-one glade-blue border-b-2 border-blue-800 "
              : "py-1 glade-subtitle-one glade-blue border-b-2 border-transparent "
          }`}
          onClick={() => setSelectedCard("USD")}
        >
          Dollar Card
        </button>
        <button
          className={`${
            selectedCard === "NGN"
              ? "py-1 glade-subtitle-one glade-blue border-b-2 border-blue-800 "
              : "py-1 glade-subtitle-one glade-blue border-b-2 border-transparent "
          }`}
          onClick={() => setSelectedCard("NGN")}
        >
          Naira Card
        </button>
      </div>
      <CardTableHeaderComplex
        title={`Showing ${virtualCardFilterResults?.total} records`}
        filter={
          <Sieve
            types={["card status", "date"]}
            onFilter={(value) => onFilter(value)}
            onReset={() => onReset()}
            disabled={virtualCards.isLoading}
            loading={virtualCards.isLoading}
          />
        }
        searchBar={
          <SearchInput
            disabled={virtualCards.isLoading}
            onSearch={onSearch}
            placeholder={"Search Card name"}
          />
        }
        rightButton={
          <div>
            <Button.Blue
              onClick={() =>
                history.push({
                  pathname: "/dashboard/virtual-cards/create",
                  state: { currency: "NGN" },
                })
              }
              className="glade-button-blue"
              title={"+ New Virtual Card"}
              // disabled={true}
            />
          </div>
        }
        kebabMenuButton={
          <div>
            <KebabDropdown className="z-10" disabled={virtualCards.isLoading}>
              <KebabDropdownItem
                activeChild={
                  <Sieve
                    button={
                      <div className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer">
                        {" "}
                        Filter{" "}
                      </div>
                    }
                    types={["card status", "date"]}
                    onFilter={(value) => onFilter(value)}
                    onReset={() => onReset()}
                    disabled={virtualCards.isLoading}
                    loading={virtualCards.isLoading}
                  />
                }
                inactiveChild={
                  <span className="glade-normal-text-two glade-black p-4">
                    {" "}
                    Filter{" "}
                  </span>
                }
              />
              {virtualCards.isLoading || !user.hasCompliance ? null : (
                <KebabDropdownItem
                  activeChild={
                    <span
                      onClick={() =>
                        history.push({
                          pathname: "/dashboard/virtual-cards/create",
                          state: { currency: "NGN" },
                        })
                      }
                      className="glade-normal-text-two glade-black p-4 glade-bg-grey rounded-md cursor-pointer"
                    >
                      New Virtual Card
                    </span>
                  }
                  inactiveChild={
                    <span className="glade-normal-text-two glade-black p-4">
                      New Virtual Card
                    </span>
                  }
                />
              )}
            </KebabDropdown>
          </div>
        }
      />

      <PaginatedTable
        columns={columns}
        data={
          typeof virtualCardFilterResults === "object" &&
          Array.isArray(virtualCardFilterResults?.data)
            ? virtualCardFilterResults?.data
            : []
        }
        empty_message="No Virtual cards to display"
        empty_sub_message=""
        current_page={currentPage}
        loading={virtualCards?.isLoading}
        setCurrentPage={setCurrentPage}
        totalPages={virtualCardFilterResults?.total}
      />
    </div>
  );
}
