import { createContext, useState } from "react";
import { useHistory } from "react-router-dom";

const TransferFormContextType = {
  transferForm: {},
  setTransferForm: () => { },
  showForm: false,
  setShowForm: () => { },
  formStage: '',
  setFormStage: () => { },
  setSelectedOption: () => { },
  selectedOption: '',
  availableBalance: '',
  setAvailableBalance: () => { },
  balanceAfterTransfer: 0,
  setBalanceAfterTransfer: () => { },
  transferCharge: 0,
  formType: '',
  setFormType: () => { },
  setTransferCharge: () => { },
  setActiveCurrency: () => { },
  activeCurrency: '',
  accountTypes: [],
  transactionFailed: false,
  setTransactionFailed: () => { },
  closeForm: () => { },
  openForm: () => { },
  localFormCurrency: '',
  setLocalFormCurrency: () => { },
  currencies: {},
  setCurrencies: () => { },
  isNGNTransfer: false,
  setIsNGNTransfer: () => { },
  transferMethod: {},
  setTransferMethod: () => { },
  transferProvider: {},
  setTransferProvider: () => { },
  outsideAfrica: [],
  transferBranches: {},
  setTransferBranches: () => { },
  receiptInfo: {},
  setReceiptInfo: () => { },
  activeStepAmount: true,
  setActiveStepAmount: () => { },
  repeatTransaction:false,
  setRepeatTransaction: () => {},
  repeatTransferDetails:{},
  setRepeatTransferDetails:() => {},
};

export const TransferFormContext = createContext(TransferFormContextType);

const TransferFormContextProvider = ({ children }) => {
  const [transferForm, setTransferForm] = useState({
    beneficiary_account_type: 'corporate'
  });
  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState('');
  const [formStage, setFormStage] = useState('select-transfer');
  const [gladeTransferType, setGladeTransferType] = useState('bank')
  const [transferMethod, setTransferMethod] = useState({});
  const [transferBranches, setTransferBranches] = useState({});
  const [transferProvider, setTransferProvider] = useState({});
  const [currencies, setCurrencies] = useState();
  const [isNGNTransfer, setIsNGNTransfer] = useState(false);
  const [localFormCurrency, setLocalFormCurrency] = useState('');
  const [availableBalance, setAvailableBalance] = useState(0);
  const [transferCharge, setTransferCharge] = useState(0);
  const [transactionFailed, setTransactionFailed] = useState(false);
  const [balanceAfterTransfer, setBalanceAfterTransfer] = useState(0);
  const [receiptInfo, setReceiptInfo] = useState({});
  const [activeStepAmount, setActiveStepAmount] = useState(true);
  const [repeatTransaction, setRepeatTransaction] = useState(false);
  const [repeatTransferDetails, setRepeatTransferDetails] = useState({});
  const accountTypes = ['corporate', 'individual'];
  const outsideAfrica = ["EU", "US", "GB"];
  // const location = useLocation();
  const history = useHistory();

  function closeForm() {
    document.body.style.overflowY = 'auto';
    setFormType('');
    setTransferForm({});
    setActiveStepAmount(true);
    // setFormStage('select-transfer');
    setShowForm(false);
  }

  function openForm(type) {
    document.body.style.overflowY = 'hidden';
    setFormStage('select-transfer');
    setActiveStepAmount(true);
    
    setShowForm(true);
    // Form to display if type is supplied
    if(type){
      setFormStage(type);
      setFormType(type);
    }
  }

  const value = {
    transferForm,
    setTransferForm,
    showForm,
    setShowForm,
    formStage,
    setFormStage,
    availableBalance,
    setAvailableBalance,
    transferCharge,
    setTransferCharge,
    balanceAfterTransfer,
    setBalanceAfterTransfer,
    formType,
    setFormType,
    accountTypes,
    transactionFailed,
    setTransactionFailed,
    localFormCurrency,
    setLocalFormCurrency,
    openForm,
    closeForm,
    currencies,
    setCurrencies,
    isNGNTransfer,
    setIsNGNTransfer,
    transferMethod,
    setTransferMethod,
    transferProvider,
    setTransferProvider,
    outsideAfrica,
    setTransferBranches,
    transferBranches,
    receiptInfo,
    setReceiptInfo,
    activeStepAmount,
    setActiveStepAmount,
    gladeTransferType,
    setGladeTransferType,
    repeatTransaction,
    setRepeatTransaction,
    repeatTransferDetails,
  setRepeatTransferDetails
  };

  return (
    <TransferFormContext.Provider value={value}>
      {children}
    </TransferFormContext.Provider>
  );
};

export default TransferFormContextProvider;