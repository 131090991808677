import React, { useEffect, useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const SuccessModal = ({ onClose, title, showDownloadButton, downloadAction }) => {
    const [timeLeft, setTimeLeft] = useState(10);

    useEffect(() => {
        let intervalCall = setInterval(() => {
            if (timeLeft > 0 && timeLeft) {
                setTimeLeft(timeLeft - 1);
            } else {
                onClose();
            };
        }, 1000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, [timeLeft]);

    return (
        <div className="md:w-3/12 w-full">
            <div className='flex flex-col justify-center items-center mb-6 mt-6'>
                <IoIosCheckmarkCircleOutline className='mt-6 mb-4' color='#00D220' size={100} />
                <span className='glade-heading-three glade-home-dark'>{title}</span>
                <span>Redirecting in <span id="countdowntimer"></span>{timeLeft} seconds</span>
            </div>
            <button className='w-full p-2 rounded glade-button-blue mt-4 mb-4' onClick={() => onClose()}>Close</button>
            {showDownloadButton && <button className='w-full p-2 rounded glade-blue border glade-border-blue' onClick={() => downloadAction()}>Download Transaction receipt</button>}
        </div>
    );
};

export default SuccessModal;