import { GrFormClose } from "react-icons/gr";
import BasicModal from "../../components/modals/BasicModal"
import TextInput from "../../components/inputs/TextInput";
import { useState } from "react";
import SelectInput from "../../components/inputs/SelectInput";
import Button from "../../components/buttons/Button";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { salaryAdvancementRequest } from "../../redux/payroll/payrollActions";
import NumericInput from "../../components/inputs/NumericInput";

const SalaryAdvancementModal = ({ showAdvancementModal, setShowAdvancementModal }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState({});
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [status, setStatus] = useState('');

  const onChange = (e) => {
    if(e.target.name === "amount"){
      let value = Number(e.target.value).toLocaleString('en-US')
      setInput({ ...input, [e.target.name]: value }) 
    }else{
      setInput({ ...input, [e.target.name]: e.target.value })
    }
  }
  const validateInputs = () => {
    if (!input.amount
      || !Number(input?.amount?.replaceAll(',', '')) || !input?.reason || !input?.reason.trim()
      || !input?.repayment_period || !input?.description
      || !input?.description.trim()) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <BasicModal
        visibility={showAdvancementModal}
        onClose={() => { setShowAdvancementModal(false) }}
        primaryButton={true} // Pass true to or valid component to avoid default display
        secondaryButton={true} // Pass true to or valid component to avoid default display
        modalTitleSection={
          <div>
            {!isRequestSubmitted ?
              <div className='capitalize pb-2 border-b flex justify-between items-center '>
                {<p className="w-11/12">Payroll Advance Request</p>}
                <GrFormClose color='#9CA3AF' className="cursor-pointer" onClick={() => setShowAdvancementModal(false)} />
              </div>
              :
              <p></p>
            }
          </div>
        }
        modelContentSection={
          <div>
            {!isRequestSubmitted ?
              <div className="mt-6">
                <div className="mb-4">
                  <NumericInput
                    leftSection={<span className="self-center glade-grey-dark border border-r-0 border-gray-300 rounded-sm p-2">NGN</span>}
                    label={"Enter Amount"}
                    id={"amount"}
                    name={"amount"}
                    type={"number"}
                    value={input?.amount ?? "0.00" }
                    onChange={onChange}
                    placeholder={"Enter an amount"}
                    autoComplete={"amount"}
                    className={"appearance-none border-l-0"}
                  />
                </div>

                <div className="mb-4">
                  <TextInput
                    label={"Reason for Request"}
                    id={"reason"}
                    name={"reason"}
                    type={"text"}
                    value={input.reason}
                    onChange={onChange}
                    placeholder={"Enter Reason for Request"}
                    autoComplete={"reason"}
                    className={"appearance-none "}
                  />
                </div>
                <div className="mb-4">
                  <SelectInput
                    label={"Repayment Period"}
                    id={"repayment_period"}
                    name={"repayment_period"}
                    value={input.repayment_period ?? ""}
                    onChange={onChange}
                  >
                    <option>Select Repayment Period</option>
                    <option value={7}>1 Week</option>
                    <option value={14}>2 Weeks</option>
                    <option value={21}>3 Weeks</option>
                    <option value={28}>4 Weeks</option>
                  </SelectInput>
                </div>

                <div className="mb-6">
                  <TextInput
                    label={'Business Description'}
                    name="description"
                    id="description"
                    placeholder={"Enter Business Description"}
                    onChange={onChange}
                    value={input?.description}
                  />
                </div>
                <div className="mt-6 border-t flex justify-between">
                  <Button.Transparent
                    title=" Cancel "
                    className={"mt-6"}
                    onClick={() => { setShowAdvancementModal(false) }}
                  />
                  <Button.Blue
                    title={" Request "}
                    className={"mt-6"}
                    disabled={validateInputs()}
                    onClick={() => dispatch(salaryAdvancementRequest({...input, amount: input?.amount?.replaceAll(',', '')}, (stat) => stat === 'success' ? setIsRequestSubmitted(true) & setStatus(stat) : setStatus(stat)))}
                    loading={status === 'loading'}
                  />
                </div>
              </div>
              :
              <div className="flex flex-col items-center">
                <div className='p-3 mt-4 glade-bg-yellow-opacity rounded-full mb-4'>
                  <HiOutlineDotsCircleHorizontal size={70} color='#F9C900' />
                </div>
                <p className='pb-2 mt-4 glade-heading-three font-bold mt-4 mb-2'>Request Pending</p>
                <p className='px-6 text-center mb-12'>
                  Your request has been submitted and is currently being reviewed
                </p>
                <Button.Blue
                  title={"Continue"}
                  className={"mb-4"}
                  onClick={() => { setShowAdvancementModal(false) }}
                />
              </div>}
          </div>
        }

      />
    </div>
  )
}

export default SalaryAdvancementModal;