import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {
  CardPlainWithButton,
  CardPlainWithButtonSection,
} from "../../components/cards/CardPlainWithButton";
import useBills from "../../hooks/useBills";
import useUser from "../../hooks/useUser";
import {
  getAllBillTypes,
  buyElectricity,
  buyCableTv,
  buyInternet,
  resolveMeterDetails,
  resolveInternetAccountDetails,
  resolveCableTvDetails,
} from "../../redux/bills/billsActions";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import NumericInput from "../../components/inputs/NumericInput";
// import CheckboxInput from "../../components/inputs/CheckboxInput";
import ConfirmPinModal from "./ConfirmPinModal";
import {
  buyElectricityValidation,
  buyInternetValidation,
  buyCableTvValidation,
} from "../../validation/bills/billsValidation";
import { getPersonalAndBusinessAccounts } from "../../redux/auth/authActions";
import ErrorMessage from "../../validation/fund-transfer/ErrorMessage";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { repeatTransfer } from "../../redux/fund-transfer/fundTransferActions";
import useTeam from "../../hooks/useTeam";

export default function NewBill() {
  const confirmPinModalRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const bills = useBills();
  const user = useUser();
  const location = useLocation();
  const {permissions} = useTeam();

  const isRepeatBill = location?.pathname?.includes("/repeat");

  React.useEffect(() => {
    dispatch(getAllBillTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [billerItems, setBillerItems] = React.useState([]);
  const [billers, setBillers] = React.useState([]);
  const [input, setInput] = React.useState({
    category_id: "",
    category_code: "",
    biller_id: "",
    biller_reference: "",
    biller_item_paycode: "",
    biller_item_keycode: "",
    biller_item_base_amount: 0,
    amount: 0,
    save_beneficiary: false,
    meter_number: "",
    phone_number: "",
    card_number: "",
    customer_name: "",
    currentBalance: user.selectedBusinessOrPersonalAccount.balance,
    txn_ref: location?.state?.txn_ref,
  });

  React.useEffect(() => {
    if (
      input?.category_code === "electricity" &&
      input?.meter_number?.length === 11 &&
      input?.biller_item_paycode
    ) {
      dispatch(
        resolveMeterDetails(
          {
            meter_number: input.meter_number,
            paycode: input.biller_item_paycode,
          },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              customer_name: resolveAccountDetails?.custName ?? "",
            }),
          (loading) => setLoading(loading)
        )
      );
    } else if (
      input?.category_code === "internet" &&
      input?.phone_number?.length === 11 &&
      input?.biller_item_paycode
    ) {
      dispatch(
        resolveInternetAccountDetails(
          {
            account_number: input?.phone_number,
            paycode: input?.biller_item_paycode,
          },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              customer_name:
                resolveAccountDetails.customer_name ??
                resolveAccountDetails.custName,
            })
        )
      );
    } else if (
      input?.category_code === "tv" &&
      input?.card_number?.length === 10 &&
      input?.biller_item_paycode &&
      input?.amount > 0
    ) {
      dispatch(
        resolveCableTvDetails(
          {
            card_iuc_number: input.card_number,
            paycode: input.biller_item_paycode,
            amount: input.amount,
          },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              customer_name: resolveAccountDetails.custName,
            }),
          (loading) => setLoading(loading)
        )
      );
    } else {
      setInput({ ...input, customer_name: "" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    input?.category_code,
    input?.card_number,
    input?.meter_number,
    input?.phone_number,
    input?.biller_item_paycode,
    location?.state,
    isRepeatBill,
  ]);

  const validateBills = (input) => {
    if (
      input.category_code === "electricity" &&
      buyElectricityValidation(input, true)
    ) {
      return true;
    }
    if (
      input.category_code === "internet" &&
      buyInternetValidation(input, true)
    ) {
      return true;
    }
    if (input.category_code === "tv" && buyCableTvValidation(input, true)) {
      return true;
    }
    return false;
  };

  const onSubmitWithPin = () => {
    if (
      input.category_code === "electricity" &&
      buyElectricityValidation(input)
    ) {
      confirmPinModalRef.current.open();
    }

    if (input.category_code === "internet" && buyInternetValidation(input)) {
      confirmPinModalRef.current.open();
    }

    if (input.category_code === "tv" && buyCableTvValidation(input)) {
      confirmPinModalRef.current.open();
    }
  };

  const onSubmit = (pin_code) => {
    if (isRepeatBill === true) {
      dispatch(
        repeatTransfer(
          { txn_ref: location?.state?.txn_ref, passcode: pin_code },
          () => {
            history.goBack();
            dispatch(getPersonalAndBusinessAccounts());
          }
        )
      );
    } else {
      if (input.category_code === "electricity") {
        dispatch(
          buyElectricity(
            {
              paycode: input?.biller_item_paycode,
              reference: input?.meter_number,
              amount: String(input?.amount)?.replaceAll(",", ""),
              save_beneficiary: input?.save_beneficiary,
            },
            () => {
              history.goBack();
              dispatch(getPersonalAndBusinessAccounts());
            }
          )
        );
      }

      if (input.category_code === "internet") {
        dispatch(
          buyInternet(
            {
              paycode: input.biller_item_paycode,
              reference: input.phone_number,
              amount: input.amount.replaceAll(",", ""),
              save_beneficiary: input.save_beneficiary,
            },
            () => {
              history.goBack();
              dispatch(getPersonalAndBusinessAccounts());
            }
          )
        );
      }

      if (input.category_code === "tv") {
        dispatch(
          buyCableTv(
            {
              paycode: input.biller_item_paycode,
              card_iuc_number: input.card_number,
              amount: input.amount,
              save_beneficiary: input.save_beneficiary,
            },
            () => {
              history.goBack();
              dispatch(getPersonalAndBusinessAccounts());
            }
          )
        );
      }
    }
    confirmPinModalRef.current.close();
  };

  const onChange = (e) => {
    if (e.target.name === "amount") {
      setInput({
        ...input,
        [e.target.name]: Number(e.target.value).toLocaleString("en-US"),
      });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (isRepeatBill && location?.state) {
      setInput({
        ...input,
        category_id: location?.state?.category_id,
        category_code: location?.state?.category,
       
      });

      let billers = bills?.billTypes?.bills?.filter(
        (item) => item.category_code === location?.state?.category
      );
      setBillers(billers ?? []);
      setBillerItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRepeatBill, location?.state]);

  useEffect(() => {
    if (isRepeatBill && location?.state && input?.category_id) {
      // onSelectOfBiller({target: {value:`${location?.state?.bill_id}-${location?.state?.bill_reference}`}, });
      setInput({
        ...input,
        biller_id: location?.state?.bill_id,
        biller_reference: location?.state?.reference,
       
      });

      let billerItems = bills?.billTypes?.items?.filter(
        (item) =>
          item.bill_id.toString() === location?.state?.bill_id.toString()
      );
      setBillerItems(billerItems ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRepeatBill, location?.state, input?.category_id, billers]);

  useEffect(() => {
    if (isRepeatBill && location?.state && billerItems && input?.biller_id) {
      let billItem = billerItems.find(
        (item) =>
          item?.bill_id.toString() === location?.state?.bill_id.toString()
      );

      setInput({
        ...input,
        biller_item_paycode: billItem?.paycode,
        biller_item_keycode: billItem?.keycode,
        biller_item_base_amount: parseInt(billItem?.base_amount),
        meter_number: location?.state?.bill_reference,
        phone_number: location?.state?.bill_reference,
        card_number: location?.state?.bill_reference,
        amount: Number(location?.state?.amount_charged).toLocaleString("en-US"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRepeatBill, location?.state, billerItems, input?.biller_id]);



  const onSelectOfBillerItem = (e) => {
    let data = JSON.parse(
      e.target[e.target.selectedIndex].getAttribute("data")
    );

    setInput({
      ...input,
      biller_item_paycode: data.paycode,
      biller_item_keycode: data.keycode,
      biller_item_base_amount: parseInt(data.base_amount),
      amount: parseInt(data.base_amount),
    });

    if (input.category_code === "internet") {
      dispatch(
        resolveInternetAccountDetails(
          { account_number: "000000000000", paycode: data.paycode, amount: 0 },
          (resolveAccountDetails) =>
            setInput({
              ...input,
              biller_item_paycode: data.paycode,
              biller_item_keycode: data.keycode,
              customer_name: resolveAccountDetails.custName,
              biller_item_base_amount: parseInt(
                resolveAccountDetails.amount ?? data.base_amount
              ),
              amount: parseInt(
                resolveAccountDetails.amount ?? data.base_amount
              ),
            })
        )
      );
    }
  };

  const onSelectOfBiller = (e) => {
    let data = JSON.parse(
      e.target[e.target.selectedIndex].getAttribute("data")
    );

    setInput({
      ...input,
      biller_id: data.id,
      biller_reference: data.reference,
      biller_item_paycode: "",
      amount: 0,
      biller_item_base_amount: 0,
    });

    let billerItems = bills?.billTypes?.items?.filter(
      (item) => item.bill_id.toString() === data.id.toString()
    );
    setBillerItems(billerItems ?? []);
  };

  const onSelectOfCategory = (e) => {
    let data = JSON.parse(
      e.target[e.target.selectedIndex].getAttribute("data")
    );

    setInput({
      ...input,
      category_id: data.id,
      category_code: data.category_code,
      biller_id: "",
      biller_item_paycode: "",
      amount: 0,
      biller_item_base_amount: 0,
    });

    let billers = bills?.billTypes?.bills?.filter(
      (item) => item.category_id.toString() === data.id.toString()
    );
    setBillers(billers ?? []);
    setBillerItems([]);
  };

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs
            title={"Bills"}
            path={
              !permissions?.includes("bill payment")
                ? "/dashboard/pay-bills"
                : "/teams/pay-bills"
            }
          />
        </div>

        <CardPlainWithButton type={"single"}>
          <CardPlainWithButtonSection
            type={"single"}
            title={"Pay Bills"}
            loading={bills.isLoading}
            buttonTitle={"Pay Bills"}
            buttonOnClick={onSubmitWithPin}
            buttonDisabled={
              bills.isLoading ||
              !user.hasCompliance ||
              !input.category_code ||
              !validateBills(input) ||
              (input.category_code === "electricity" &&
                input.amount > 0 &&
                input.amount < 1000)
            }
          >
            <div className="flex flex-col space-y-6">
              <SelectInput
                label={"Select Category"}
                id={"category"}
                name={"category"}
                value={input.category_id}
                onChange={onSelectOfCategory}
                autoComplete={"category"}
                disabled={isRepeatBill ? true : false}
                className={"appearance-none"}
              >
                <option value={""} disabled={true}>
                  Select A Category
                </option>
                {bills?.billTypes?.categories?.map((item, key) => {
                  return (
                    <option
                      key={key}
                      data={JSON.stringify(item)}
                      value={item.id}
                    >
                      {item.category_title}
                    </option>
                  );
                })}
              </SelectInput>

              <SelectInput
                label={"Select Biller"}
                id={"biller"}
                name={"biller"}
                value={
                  isRepeatBill
                    ? `${input.biller_id}-${input.biller_reference}`
                    : input.biller_id
                }
                onChange={onSelectOfBiller}
                autoComplete={"biller"}
                disabled={isRepeatBill ? true : false}
                className={"appearance-none"}
              >
                <option value={""} disabled={true}>
                  Select A Biller
                </option>
                {billers?.map((item, key) => {
                  return (
                    <option
                      key={key}
                      data={JSON.stringify(item)}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </SelectInput>

              <SelectInput
                label={"Select Bill"}
                id={"biller_item"}
                name={"biller_item"}
                value={input.biller_item_paycode}
                onChange={onSelectOfBillerItem}
                autoComplete={"biller_item"}
                disabled={isRepeatBill ? true : false}
                className={"appearance-none"}
              >
                <option value={""} disabled={true}>
                  Select A Bill
                </option>
                {billerItems?.map((item, key) => {
                  return (
                    <option
                      key={key}
                      data={JSON.stringify(item)}
                      value={item.paycode}
                    >
                      {item.title + " " + item.keycode}
                    </option>
                  );
                })}
              </SelectInput>

              {input.category_code === "electricity" && (
                <React.Fragment>
                  <TextInput
                    label={"Meter Number"}
                    id={"meter_number"}
                    name={"meter_number"}
                    type={"tel"}
                    value={input.meter_number}
                    onChange={onChange}
                    placeholder={"Enter meter number"}
                    autoComplete={"meter_number"}
                    disabled={isRepeatBill ? true : false}
                    className={"appearance-none"}
                  />

                  <TextInput
                    label={"Customer Name"}
                    id={"customer_name"}
                    name={"customer_name"}
                    disabled={true}
                    type={"text"}
                    value={input.customer_name}
                    onChange={() => {}}
                    placeholder={loading ? "please wait ..." : ""}
                    autoComplete={"customer_name"}
                    className={"appearance-none"}
                  />

                  <NumericInput
                    leftSection={
                      <span className="self-center border border-gray-300 rounded-sm p-2">
                        NGN
                      </span>
                    }
                    label={"Amount"}
                    id={"amount"}
                    name={"amount"}
                    type={"number"}
                    min={"1000"}
                    value={input.amount}
                    onChange={onChange}
                    placeholder={"Enter amount"}
                    autoComplete={"amount"}
                    className={"appearance-none"}
                  />
                  {input.amount > 0 && input.amount < 1000 && (
                    <ErrorMessage msg={"Minimum amount is NGN1000"} />
                  )}
                </React.Fragment>
              )}

              {input.category_code === "internet" && (
                <React.Fragment>
                  <TextInput
                    label={"Phone Number"}
                    id={"phone_number"}
                    name={"phone_number"}
                    type={"tel"}
                    value={input.phone_number}
                    onChange={onChange}
                    placeholder={"Enter phone number"}
                    disabled={isRepeatBill ? true : false}
                    autoComplete={"phone_number"}
                    className={"appearance-none"}
                  />

                  <TextInput
                    label={"Customer Name"}
                    id={"customer_name"}
                    name={"customer_name"}
                    disabled={true}
                    type={"text"}
                    value={input.customer_name}
                    onChange={() => {}}
                    placeholder={loading ? "please wait ..." : ""}
                    autoComplete={"customer_name"}
                    className={"appearance-none"}
                  />

                  <NumericInput
                    label={"Amount"}
                    id={"amount"}
                    name={"amount"}
                    type={"number"}
                    min={"0"}
                    value={input.amount}
                    onChange={onChange}
                    placeholder={"Enter amount"}
                    autoComplete={"amount"}
                    className={"appearance-none"}
                  />
                </React.Fragment>
              )}

              {input.category_code === "tv" && (
                <React.Fragment>
                  <TextInput
                    label={"Card Number"}
                    id={"card_number"}
                    name={"card_number"}
                    type={"tel"}
                    value={input.card_number}
                    onChange={onChange}
                    placeholder={"Enter card number"}
                    disabled={isRepeatBill ? true : false}
                    autoComplete={"card_number"}
                    className={"appearance-none"}
                  />

                  <TextInput
                    label={"Customer Name"}
                    id={"customer_name"}
                    name={"customer_name"}
                    disabled={true}
                    type={"text"}
                    value={input.customer_name}
                    onChange={() => {}}
                    placeholder={loading ? "please wait ..." : ""}
                    autoComplete={"customer_name"}
                    className={"appearance-none"}
                  />

                  <NumericInput
                    label={"Amount"}
                    id={"amount"}
                    name={"amount"}
                    type={"number"}
                    min={"0"}
                    value={input.biller_item_base_amount}
                    onChange={() => {}}
                    disabled={true}
                    placeholder={"Enter amount"}
                    autoComplete={"amount"}
                    className={"appearance-none"}
                  />
                </React.Fragment>
              )}

              {/* <CheckboxInput 
                                label={'Save as beneficiary'}
                                id={'save_beneficiary'}
                                name={'save_beneficiary'}
                                type={'checkbox'}
                                checked={input.save_beneficiary}
                                onChange={(e)=>setInput({...input,[e.target.name]:e.target.checked})}
                                placeholder={''}
                                autoComplete={'save_beneficiary'}
                                className={'appearance-none'}
                            /> */}
            </div>
          </CardPlainWithButtonSection>
        </CardPlainWithButton>

        <ConfirmPinModal
          ref={confirmPinModalRef}
          onSuccess={onSubmit}
          onFailure={() => {}}
        />
      </div>
    </div>
  );
}
