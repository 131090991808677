import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { SIGNUP_ICON, LOGIN_ICON, ORG_ICON } from "../../assets/images/images";
import OnboardingLayout from "./OnboardingLayout";

export default function GettingStarted() {

      const [selectedOption, setSelectedOption] = useState(null);

      const handleOptionSelect = (option) => {
        if (selectedOption === option && option !== "login") {
            setSelectedOption(null);
          } else {
            setSelectedOption(option);
          }
        };

    return (
    <OnboardingLayout>

        <div className='w-full bg-white py-14'>
            <div className=' mx-auto text-center flex flex-col justify-center items-center max-w-md'>
                <div className='text-center text-glade-black-700 text-xl font-bold pb-8'>
                    <h3>Welcome, what do you want to do?</h3>
                </div>
                <div className='text-left'>
                    <div className={`border cursor-pointer ${selectedOption === "signup" ? 'border-blue-500' : 'border-gray-100'} rounded mb-10 py-4 px-6`} onClick={() => handleOptionSelect("signup")}>
                    <div className='flex gap-2 '>
                        <div><img src={SIGNUP_ICON} alt="icon" /></div>
                        <div className='leading-5'>
                            <p className='font-medium'>Open an account</p>
                            <p className='text-glade-gray-500 text-sm'>All-in-one business finance solution</p>
                            </div>
                    </div>
                    {selectedOption === "signup" && (
                    <ul className='text-xs list-disc list-inside marker-blue p-2 leading-5'>
                            <li className=''>Multi-currency Accounts</li>
                            <li>Global Transfers</li>
                            <li>Borderless Cards</li>
                            <li>Receive Online Payments</li>
                            <li>Expense management with budgets, receipts, and claims</li>
                            </ul>
                            )}
                    </div>

                    <div className={`flex gap-2 border cursor-pointer ${selectedOption === "login" ? 'border-blue-500' : 'border-gray-100'} rounded mb-10 py-4 px-6`} onClick={() => handleOptionSelect("login")} >
                        <div><img src={LOGIN_ICON} alt="icon" /></div>
                        <div className='leading-5'>
                            <p className='font-medium'>Sign into an existing account</p>
                            <p className='text-glade-gray-500 text-sm'>Log in to your account</p>
                        </div>
                    </div>
                    <div className={`border cursor-pointer ${selectedOption === "compliance" ? 'border-blue-500' : 'border-gray-100'} rounded mb-10 py-4 px-6`} onClick={() => handleOptionSelect("compliance")}>
                    <div className='flex gap-2 '>
                        <div><img src={ORG_ICON} alt="icon" /></div>
                        <div className='leading-5'>
                            <p className='font-medium'>Incorporate your company</p>
                            <p className='text-glade-gray-500 text-sm'>Submit your company details and we’ll handle the rest</p>
                        </div>
                    </div>
                    {selectedOption === "compliance" && (
                    <ul className='text-xs list-disc list-inside marker-blue p-2 leading-5'>
                            <li className=''>Sole Proprietorship </li>
                            <li>Limited Liability Company</li>
                            <li>Limited Liability Partnership</li>
                            <li>Non-governmental Organization</li>
                            </ul>
)}
                            </div>
                </div>
                {selectedOption === "signup" || selectedOption === "compliance"? (
          <Link to ="/auth/register">
            <button type='button' className='inline-block py-2 text-center rounded-lg text-white bg-glade-blue w-24'>Proceed</button>
          </Link>
        ) : selectedOption === "login"? (
          <Link to ="/auth/login">
            <button type='button' className='inline-block py-2 text-center rounded-lg text-white bg-glade-blue w-24'>Proceed</button>
          </Link>
       
        ) : (
          <button type='button' className='inline-block py-2 text-center rounded text-glade-gray-300 bg-glade-gray w-28'>Proceed</button>
        )}
        
      </div>
    </div>
    </OnboardingLayout>
  );
}