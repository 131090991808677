import { useSelector } from 'react-redux';

const useSettings = () => {

  const settings = useSelector(state => state.settings);

    let {
        isLoading,
        isLoadingCompliance,
        isLoadingDashboardFeatures,
        shouldReload,
        createPassCode,
        resetPassCode,
        updatePassCode,
        completePassCode,
        userDetails,
        resetPassword,
        updateResetPassword,
        completeResetPassword,
        businessDetails,
        updatePersonalProfile,
        updateBusinessProfile,
        complianceCountries,
        complianceStates,
        complianceStatesNew,
        agentGuarantorTemplate,
        complianceLga,
        compliancePersonalDocumentTypes,
        complianceBusinessDocumentTypes,
        complianceIdentityTypes,
        metamapIdentityTypes,
        personalCompliance,
        personalRecentCompliance,
        businessCompliance,
        businessRecentCompliance,
        submittedPersonalComplianceDetail,
        submittedBusinessComplianceDetail,
        submitPersonalCompliance,
        submitBusinessCompliance,
        submitBusinessInformation,
        sendInvite,
        verifySentInvites,
        updateSentInvite,
        userInvites,
        resendInvite,
        deleteInvite,
        settlementAccounts,
        createSettlementAccount,
        updateSettlementAccount,
        deleteSettlementAccount,
        suppliedAccountDetails,
        roles,
        createRole,
        permissions,
        updateRole,
        deleteRole,
        dashboardFeatures,
        paymentMethods,
        updateDashboardFeatures,
        updatePaymentMethods,
        agentRequestStatus,
        submitAgentRequest,
        merchantCredentials,
        merchantWebhook,
        createOrUpdateMerchantWebhook,
        developerWebPageLink,
        guarantorFormData,
        referralDetails,
        referralRewardsBreakdown,
        referralYears,
        complianceCountriesNew

    } = settings;

    return {
        isLoading,
        isLoadingCompliance,
        isLoadingDashboardFeatures,
        shouldReload,
        createPassCode,
        resetPassCode,
        updatePassCode,
        completePassCode,
        userDetails,
        resetPassword,
        updateResetPassword,
        completeResetPassword,
        businessDetails,
        updatePersonalProfile,
        updateBusinessProfile,
        complianceCountries,
        complianceStates,
        complianceStatesNew,
        agentGuarantorTemplate,
        complianceLga,
        compliancePersonalDocumentTypes,
        complianceBusinessDocumentTypes,
        complianceIdentityTypes,
        metamapIdentityTypes,
        personalCompliance,
        personalRecentCompliance,
        businessCompliance,
        businessRecentCompliance,
        submittedPersonalComplianceDetail,
        submittedBusinessComplianceDetail,
        submitPersonalCompliance,
        submitBusinessCompliance,
        submitBusinessInformation,
        sendInvite,
        verifySentInvites,
        updateSentInvite,
        userInvites,
        resendInvite,
        deleteInvite,
        settlementAccounts,
        createSettlementAccount,
        updateSettlementAccount,
        deleteSettlementAccount,
        suppliedAccountDetails,
        roles,
        createRole,
        permissions,
        updateRole,
        deleteRole,
        dashboardFeatures,
        paymentMethods,
        updateDashboardFeatures,
        updatePaymentMethods,
        agentRequestStatus,
        submitAgentRequest,
        merchantCredentials,
        merchantWebhook,
        createOrUpdateMerchantWebhook,
        developerWebPageLink,
        guarantorFormData,
        referralDetails,
        referralRewardsBreakdown,
        referralYears,
        complianceCountriesNew

    }
};

export default useSettings;
